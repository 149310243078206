/*
Utility actions to be used generatlly across app

*/


import * as actions_database from './actions_database';
import * as actions_groups from './actions_groups';
import * as actions_projects from './actions_projects';
import * as actions_undo from './actions_undo';
import * as Constants from '../../constants/constants';
import * as database_utils from '../../utils/database_utils'



// create item
export const create_item_with_undo = (project_id, item_type, new_uid, data_to_set, group_id=null, set_undo=true) => {
    // console.log('UU AC create_item_with_undo',project_id,  item_type, new_uid, data_to_set)
    // console.log('---AC create_item_with_undo - Data To Set:', data_to_set)
    return dispatch => {
        // console.log('XXXX db_path')

        // new_uid
        // data_to_set

        // event.stopPropagation();
        // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+new_uid

        let undo_dispatch_data = {type:'X'}
        if (set_undo){
            undo_dispatch_data = {
                type:'ADD_TO_UNDO_HISTORY',
                undo_function_name:'delete_items_with_undo',
                undo_function_args:{
                    item_data:{
                        [new_uid]:{item_type:item_type}
                    },
                    project_id:project_id,
                    set_undo:false,
                }, 
                redo_function_name:'create_item_with_undo',
                redo_function_args:{
                    project_id:project_id,
                    item_type:item_type,
                    new_uid:new_uid,
                    data_to_set:data_to_set,
                    group_id:group_id,
                    set_undo:false,
                },    
            }
        }


        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, new_uid);

        actions_database.set_db_data(db_path, data_to_set, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "CREATE_STORY_ITEM", 
                item_type:item_type,
                new_uid:new_uid,
                item_data:data_to_set,
            }),
            
            // link new item to project
            // dispatch(actions_projects.link_to_project(project_object, project_id, item_type, [new_uid]))

        )
        .then (
            dispatch(actions_groups.add_items_to_group(group_id, [new_uid]))
        )

        .then (
            dispatch( undo_dispatch_data )
        )
        // not good to have to pass in project object... is there a better way?

    }
};
export const create_item = (project_id, item_type, new_uid, data_to_set,) => {
    // console.log('UU AC create_item',project_id,  item_type, new_uid, data_to_set)
    // console.log('---AC create_item - Data To Set:', data_to_set)
    return dispatch => {
        // console.log('XXXX db_path')

        // new_uid
        // data_to_set

        // event.stopPropagation();
        // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+new_uid
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, new_uid);

        actions_database.set_db_data(db_path, data_to_set, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "CREATE_STORY_ITEM", 
                item_type:item_type,
                new_uid:new_uid,
                item_data:data_to_set,
            }),
            
            // link new item to project
            // dispatch(actions_projects.link_to_project(project_object, project_id, item_type, [new_uid]))

        )

        // not good to have to pass in project object... is there a better way?

    }
};


// delete item

// export const delete_item = (event, item_type, item_id) => {
//     console.log('AC delete_item', item_type,item_id )
//     return dispatch => {
//         console.log('AC delete_itemCC ', item_type,item_id )

//         event.stopPropagation();

//         // event.stopPropagation();
//         let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id
//         actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
//         .then (
//             dispatch( { 
//                 type: "DELETE_ITEM", 
//                 item_type:item_type,
//                 item_id:item_id,
//             }),
//         )
//     }

// };



export const delete_items_with_undo = (item_data, project_id, set_undo=true) => {
    // this is an alternate delete item which can deal with array sel

    // console.log('AC delete_item B', item_data )
    return dispatch => {

        // event.stopPropagation();

        Object.keys(item_data).map((item_id) => {
            let item_type = item_data[item_id]['item_type']
            let data_to_set = item_data[item_id];

            // TODO  I need to find a way to do this outside a loop
            //  how do I make actions_database.remove_db_data work on multiple items/paths?
            let undo_dispatch_data = {type:'X'}
            if (set_undo){
                undo_dispatch_data = {
                    type:'ADD_TO_UNDO_HISTORY',
                    undo_function_name:'create_item_with_undo',
                    undo_function_args:{
                        project_id:project_id,
                        item_type:item_type,
                        new_uid:item_id,
                        data_to_set:data_to_set,
                        set_undo:false,
                    },
                    redo_function_name:'delete_items_with_undo',
                    redo_function_args:{
                        item_data:{
                            [item_id]:{item_type:item_type}
                        },
                        project_id:project_id,
                        set_undo:false,
                    }, 
                }
            }
            // console.log('undo_dispatch_data', undo_dispatch_data)
            // event.stopPropagation();
            // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id
            let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id);

            actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
            .then (
                dispatch( { 
                    type: "DELETE_ITEM", 
                    item_type:item_type,
                    item_id:item_id,
                }),
                dispatch({  
                    type: "SELECT_ITEM_C", 
                    data:{},
                })
            )
            .then (
                dispatch( undo_dispatch_data)
            )
        })
        //TODO Turn this back on - only turned off for testing
        // dispatch({  
        //     type: "SELECT_ITEM_C", 
        //     data:null,
        // })
    }
};

export const delete_items = (item_data, project_id) => {
    // this is an alternate delete item which can deal with array sel

    // console.log('AC delete_item X item_data', item_data )
    // console.log('AC delete_item X project_id', project_id )
    return dispatch => {

        // event.stopPropagation();


        Object.keys(item_data).map((item_id) => {
            let item_type = item_data[item_id]['item_type']
            // event.stopPropagation();
            // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id
            let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id);

            actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
            .then (
                dispatch( { 
                    type: "DELETE_ITEM", 
                    item_type:item_type,
                    item_id:item_id,
                }),
                dispatch({  
                    type: "SELECT_ITEM_C", 
                    data:{},
                })
            )
        })
        //TODO Turn this back on - only turned off for testing
        // dispatch({  
        //     type: "SELECT_ITEM_C", 
        //     data:null,
        // })
    }
};




export const link_items = (item_data, item_type, item_id, to_link_type, to_link_ids, project_id) => {
    // console.log('AC link_item',item_data, item_type, item_id, to_link_type, to_link_ids)
    return dispatch => {


        // TODO need to test if to_link_type is a key of item_data['links']. if not create it.
        let all_link_ids = [];
        if (item_data.hasOwnProperty('links')){
            if (item_data['links'].hasOwnProperty(to_link_type)){
                all_link_ids = [...item_data['links'][to_link_type]];
            }
        }
        // let all_link_ids = item_data['links'][to_link_type];
        // console.log('all_link_ids', all_link_ids)
        // let new_link_id_array = [];
        // loop through all links to add and add them to array if they dont exist
        for(var i=0; i < to_link_ids.length; ++i) {
            if (!all_link_ids.includes(to_link_ids[i])){
                    // console.log('link_item - adding this one!', to_link_ids[i])

                    all_link_ids.push(to_link_ids[i])
            }
        }

        // console.log('all_link_ids', all_link_ids)

        // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id+'/links/'+to_link_type
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id) +'/links/'+to_link_type;
        actions_database.set_db_data(db_path, all_link_ids, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "LINK_ITEMS", 
                item_type:item_type,
                item_id:item_id,
                to_link_type:to_link_type,
                to_link_ids:all_link_ids,
            }),
        )
    }

};




export const un_link_items = (item_data, item_type, item_id, to_unlink_type, to_unlink_ids, project_id) => {
    // console.log('AC un_link_items',item_data, item_type, item_id, to_unlink_type, to_unlink_ids)
    return dispatch => {


        // TODO need to test if to_link_type is a key of item_data['links']. if not create it.
        let all_link_ids = [];
        if (item_data.hasOwnProperty('links')){
            if (item_data['links'].hasOwnProperty(to_unlink_type)){
                all_link_ids = [...item_data['links'][to_unlink_type]];
            }
        }
        // let all_link_ids = item_data['links'][to_unlink_type];
        // console.log('all_link_ids', all_link_ids)
        all_link_ids = all_link_ids.filter(item => !to_unlink_ids.includes(item))


        // console.log('all_link_ids B', all_link_ids)

        // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id+'/links/'+to_unlink_type
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id) +'/links/'+to_unlink_type;
        actions_database.set_db_data(db_path, all_link_ids, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "LINK_ITEMS", 
                item_type:item_type,
                item_id:item_id,
                to_link_type:to_unlink_type,
                to_link_ids:all_link_ids,
            }),
        )
    }

};


