
/////////////////////// Viewport Action Creators  /////////////////////////////
///////////////////////////////////////////////////////////////////////////////
/*
to do:
- do selection_b on mouse up
- set DB on mouse up (if fail, revert to stored pos in dragging_b data)
- once i hace valide sel

*/
import * as actions_database from './actions_database';
import * as actions_projects from './actions_projects';
import * as database_utils from '../../utils/database_utils'

import * as Constants from '../../constants/constants';

export const mouse_down_vp_xform = (event, item_type, item_id, item, ui_data) => {
    // console.log('AC mouse_down_vp_xform', event, item_type, item_id, item, ui_data)
    // console.log('AC mouse_down_vp_xform CONSTANTS', Constants.VP_XFORM_POS_TYPES)

    return dispatch => {
        // event.stopPropagation();
        
        let current_sel = ui_data.control.selected;
        let dragging_data = null;
        let clicked_data = null;


        if (!item.hasOwnProperty('size')){
            clicked_data = {
                [item_id]:{item_type:item_type, 
                    custom_data:{
                        mouse_down_pos:[item.pos[0], item.pos[1]],
                    },
                }
            }
        }
        else{
            clicked_data = {
                [item_id]:{item_type:item_type, 
                    custom_data:{
                        mouse_down_pos:[item.pos[0], item.pos[1]],
                        mouse_down_size:[item.size[0], item.size[1]],
                    },
                }
            }
        }

        // if clicked is scale handle then don't add to dragging ( i think this'll make sense)
        // when it comes to selection i'll strip off _scale and be left with item, so it should behave as if i'd
        // clicked elsewhere on item (i think ???)

        // NO. trying something else. if type is scale handle then don't include sel


        if (item_type.endsWith('_scale')){
            dragging_data = {...clicked_data};
        }
        else{

            if (current_sel){
                if (item_id in current_sel){

                    // build dragging data from sel, including pos
                    // doing this here, to make sure pos is current at time of mouse down
                    // dragging_data = {};
                    // Object.keys(current_sel).map((item_id) => {
                    //     dragging_data[item_id] = {
                    //         item_type:item_type, 
                    //         custom_data:{
                    //             mouse_down_pos:[item.pos[0], item.pos[1]],
                    //             mouse_down_size:[item.size[0], item.size[1]],
                    //         },
                    //     }
                    // })
                    dragging_data = {...current_sel}
                }
            }
        }

        // if nothing filled in yet, then drag clicked
        if(!dragging_data){
            dragging_data = {...clicked_data};
        }

        dispatch({  
            type: "SET_DRAGGING_B", 
            data:dragging_data,
        }) 
        dispatch({  
            type: "SET_CLICKED", 
            data:clicked_data,
        })
    }

};

export const mouse_move_vp_xform = (event, ui_data) => {
    return dispatch => {

        let dragging_data = ui_data.control.dragging_b;
        let clicked_data = ui_data.control.clicked;
        let begin_drag = ui_data.control.begin_drag;
        let local_drag_offset = ui_data.control.local_drag_offset;
        // console.log('AC mouse_move_vp_xform', dragging_data)

        // if is_dragging, loop through all in dragging and apply xform offset
        //( will need to case for scaling, but do that later )
        if (begin_drag) {

            Object.keys(dragging_data).map((item_id) => {
                // console.log('AC mouse_move_vp_xform item_id', item_id)

                // let item_id = dragging;
                let item_type = dragging_data[item_id].item_type;

                // test item_type against constants o switch behaviour
                if (Constants.VP_XFORM_POS_TYPES.includes(item_type)){
                    // console.log('AC mouse_move_vp_xform in here!', )
                    let mouse_down_pos = dragging_data[item_id].custom_data.mouse_down_pos;
                    let pos = [
                        Math.round(mouse_down_pos[0] +  local_drag_offset[0]), 
                        Math.round(mouse_down_pos[1] +  local_drag_offset[1])
                    ]
                    dispatch({ type: "SET_VP_XFORM_POS", 
                            item_id:item_id, 
                            item_type:item_type, 
                            pos:pos,
                    })
                }
                if (Constants.VP_XFORM_SCALE_TYPES.includes(item_type)){
                    let mouse_down_size = clicked_data[item_id].custom_data.mouse_down_size;
                    let size = [
                        Math.max(100.0, Math.round(mouse_down_size[0] +  local_drag_offset[0])), 
                        Math.max(100.0, Math.round(mouse_down_size[1] +  local_drag_offset[1]))
                    ]
                    // this will remove _scale from item_type
                    let item_type_stripped = item_type.slice(0, -6);
                    dispatch({ type: "SET_VP_XFORM_SIZE", 
                            item_id:item_id, 
                            item_type:item_type_stripped, 
                            size:size,
                    })
                }
    
            })
            // Object.keys(clicked_data).map((item_id) => {

            //     let item_type = clicked_data[item_id].item_type;
            //     if (Constants.VP_XFORM_SCALE_TYPES.includes(item_type)){
            //         console.log('this is where i should add scaling code')
            //         let mouse_down_size = clicked_data[item_id].custom_data.mouse_down_size;
            //         let size = [
            //             Math.round(mouse_down_size[0] +  local_drag_offset[0]), 
            //             Math.round(mouse_down_size[1] +  local_drag_offset[1])
            //         ]
            //         // this will remove _scale from item_type
            //         let item_type_stripped = item_type.slice(0, -6);
            //         dispatch({ type: "SET_VP_XFORM_SIZE", 
            //                 item_id:item_id, 
            //                 item_type:item_type_stripped, 
            //                 size:size,
            //         })
            //     }
            // })
        }
    }
}


export const mouse_up_vp_xform = (event, ui_data) => {
    // console.log('AC mouse_up_vp_xform', ui_data)

    return dispatch => {

        let dragging_data = ui_data.control.dragging_b;
        let begin_drag = ui_data.control.begin_drag;
        let local_drag_offset = ui_data.control.local_drag_offset;
        let hovering = ui_data.control.hovering;


        let current_sel = ui_data.control.selected || {};
        let clicked_data = ui_data.control.clicked;
        let clicked_id = null;
        if (clicked_data){
            clicked_id = Object.keys(clicked_data)[0]
        }

        let new_sel_data = null;
        // if i didn't brgin drag, then register as a click, and do select
        if (!begin_drag) {

            if (event.getModifierState("Shift") || event.getModifierState("Control")){
                // console.log('xxxx', clicked_id, current_sel)
                // if in sel, then deselect
                if (clicked_id in current_sel){
                    new_sel_data = {...current_sel}
                    delete new_sel_data[clicked_id]; 
                }
                else{
                    new_sel_data = Object.assign(clicked_data, current_sel);

                }
                // console.log('AC mouse_up_vp_xform SHIFT held',)
            }
            else if (event.getModifierState("Alt")){
                new_sel_data = {...current_sel}
                if (clicked_id in new_sel_data){
                    delete new_sel_data[clicked_id]; 
                }

            }
            else {
                // console.log('AC mouse_up_vp_xform dragging_data', dragging_data)
                new_sel_data = {...clicked_data}
                // console.log('AC mouse_up_vp_xform new_sel_data', new_sel_data)

            }
            
            dispatch({  
                type: "SELECT_ITEM_C", 
                data:new_sel_data,
            })
        }
        else{
            // IF I dragged something then need to commit the change

            // console.log('AC mouse_up_vp_xform do commit pos to DB here')
            Object.keys(dragging_data).map((item_id) => {
                
                let item_type = dragging_data[item_id].item_type;

                // skipping if card is dragging over other card. If so i'm setting pos in acion_cards.mouse_up_flow_vp. Is that smart. not sure.
                if (item_type === 'card' && hovering[0] === 'card'){
                    console.log('mouse_up_vp_xform hovering ovef card so skipping');
                    return;
                }
                if (item_type === 'card' && hovering[0] === 'breadcrumb'){
                    console.log('mouse_up_vp_xform hovering ovef breadcrumb so skipping');
                    return;
                }


                

                if (Constants.VP_XFORM_POS_TYPES.includes(item_type)){
                    // console.log('AC mouse_move_vp_xform in here!', )
                    let mouse_down_pos = dragging_data[item_id].custom_data.mouse_down_pos;
                    let pos = [
                        Math.round(mouse_down_pos[0] +  local_drag_offset[0]), 
                        Math.round(mouse_down_pos[1] +  local_drag_offset[1])
                    ]
                    dispatch({ type: "SET_VP_XFORM_POS", 
                            item_id:item_id, 
                            item_type:item_type, 
                            pos:pos,
                    })
                    // let db_path = Constants.DB_ROOT+'/'+item_type+'_data/'+item_id+'/pos'
                    let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, ui_data.current.current_project, item_type, item_id) +'/pos' ;

                    actions_database.set_db_data(db_path, pos, window.SKIP_DATABASE)

                }
                if (Constants.VP_XFORM_SCALE_TYPES.includes(item_type)){
                    let mouse_down_size = clicked_data[item_id].custom_data.mouse_down_size;
                    let size = [
                        Math.max(100.0, Math.round(mouse_down_size[0] +  local_drag_offset[0])), 
                        Math.max(100.0, Math.round(mouse_down_size[1] +  local_drag_offset[1]))
                    ]
                    // this will remove _scale from item_type (ie 6 characters)
                    let item_type_stripped = item_type.slice(0, -6);
                    dispatch({ type: "SET_VP_XFORM_SIZE", 
                            item_id:item_id, 
                            item_type:item_type_stripped, 
                            size:size,
                    })
                    // let db_path = Constants.DB_ROOT+'/'+item_type_stripped+'_data/'+item_id+'/size'
                    let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, ui_data.current.current_project, item_type_stripped, item_id) +'/size' ;

                    actions_database.set_db_data(db_path, size, window.SKIP_DATABASE)
                }
            })



            // Object.keys(dragging_data).map((item_id) => {
            //     // console.log('AC mouse_up_vp_xform dragging loop', item_id)

            //     let item_type = dragging_data[item_id].item_type;
            //     let mouse_down_pos = dragging_data[item_id].custom_data.mouse_down_pos;
            //     let pos = [
            //         Math.round(mouse_down_pos[0] +  local_drag_offset[0]), 
            //         Math.round(mouse_down_pos[1] +  local_drag_offset[1])
            //     ]

            //     let db_path = Constants.DB_ROOT+'/'+item_type+'_data/'+item_id+'/pos'
            //     actions_database.set_db_data(db_path, pos, window.SKIP_DATABASE)  
            // })
 

        }
        dispatch({  
            type: "SET_DRAGGING_B", 
            data:null,
        })
        dispatch({  
            type: "SET_CLICKED", 
            data:null,
        })
    }
}





// experimenting with geneic viewport objects / transforms
/*
try storing richer data in selection, as well as storing sel keyed by id for faster retrieval
should be fine because all ids will be unique, plus prefixed by type
eg.
selection={
    item_id:{item_type:'card', item_data:{}, custom_data:{}},
    item_id:{item_type:'card', item_data:{}, custom_data:{}},
}

for vp xforms, add mouse_down_pos/size as extra data keys.
eg.
    item_id:{item_type:'card', item_data:{}, 
    custom_data:{mouse_down_pos:[0,0], mouse_down_size=[1,1]}},


Then on mouse move, once i've triggered my is dragging threshold, i should add data to 
a dragging key. Can copy that directly from selection (NO THIS IS WRONG)
eg.
dragging={
    item_id:{item_type:'card', item_data:{}, custom_data:{}},
    item_id:{item_type:'card', item_data:{}, custom_data:{}},
}

or maybe i dont even need that? Can i just have a key of is_dragging, and look into selection for items?
NO. because i dont want to have to select things to drag them.
so....
If i'm dragging a selected item, move all selected items. else, just drag whats under my mouse

so..
adding to selection only happens on mouse up (if i haven't begun dragging)

so dragging should be set on mouse down, based on the conditions above(re selection)

then when mouse moves
loop through all items in dragging. 
possibly test item_type against whitelist,
 (should i have constant to define item_types that are vp_xforms?)
then add local mouse offset to custom_data.mouse_down_pos 
and set via generic SET_VP_XFORM_POS resucer action.

then on mouse up run something like this: mouse_up_flow_vp
to set value in DB
(also in mouse up if is_dragging == false, then set copy all dragging data into selection)





TIDY UP OF ABOVE:

mouse_down_vp_xform:
    pass given item to function, 
    if item is in selection then add all selection to dragging.
    else, just add given item to dragging.
    possibly test item_type against whitelist,
    (should i have constant to define item_types that are vp_xforms?)

    dragging data should look like this:
    dragging={
        item_id:{item_type:'card', item_data:{}, custom_data:{}},
        item_id:{item_type:'card', item_data:{}, custom_data:{}},
    }

    for vp xforms, add mouse_down_pos/size as extra data keys.
    eg.
        item_id:{item_type:'card', item_data:{}, 
        custom_data:{mouse_down_pos:[0,0], mouse_down_size=[1,1]}},


mouse_move_vp_xform:

    loop through all items in dragging. 
    
    then add local mouse offset to custom_data.mouse_down_pos 
    and set via generic SET_VP_XFORM_POS resucer action.

mouse_up_vp_xform:
    
    if is_dragging was never triggered, then that means I've clicked on the spot,
    and want to make a selection. 
    (need more detail here. eg if shift is down, add selection etc...)
    selection data should look like this:
    selection={
        item_id:{item_type:'card', item_data:{}, custom_data:{}},
        item_id:{item_type:'card', item_data:{}, custom_data:{}},
    }
    this is also where i would push data to Database.
    to do that run something like this: mouse_up_flow_vp

*/

// // not sure if I'll need mouse down - might be able to use state?
// export const mouse_down_vp_xform = (event, item_data_array) => {
//     // generic action for mouse down on a viewport xform.
//     /*
    
    
//     // example of array is :
//     [
//         [item_type, item_id, item_data],
//         [item_type, item_id, item_data],
//     ]
//     The idea is that i can have an array of selected nodes, then store orig pos and size 
//     for each in an array in ui_data.mouse_down_xform_array (or something like that)
//     or better yet, add that data to my dragging key.

//     rather than dealing with array i could combine type and id into a single key, then lookup 
//     directly into the data
//     eg.
//     vp_dragging:{
//         item_type+'__'+item_id:{
//             mouse_down_pos:[0,0],
//             mouse_down_size:[1,1],
//         },
//         item_type+'__'+item_id:{
//             mouse_down_pos:[0,0],
//             mouse_down_size:[1,1],
//         },
//     }

//     then, when applying offset
//     */ 
//     event.stopPropagation();
        
//     let orig_pos = card_item_data['pos']

//     return { type: "SET_VP_XFORM_MOUSE_DOWN", 
//         item_type: item_type,
//         item_id: item_id,
//         pos:
//     }

// }

// export const mouse_drag_vp_xform_pos = (event, item_type, item_id, drag_offset) => {
//     //AAA console.log('AC mouse_drag_card', event, ui_data )
  
//     let dragging = ui_data.control.dragging;
//     let begin_drag = ui_data.control.begin_drag;
//     let local_drag_offset = ui_data.control.local_drag_offset;
//     let local_orig_pos = ui_data.control.local_orig_pos;

//     if (begin_drag) {
//         let pos = [
//             Math.round(local_orig_pos[0] +  local_drag_offset[0]), 
//             Math.round(local_orig_pos[1] +  local_drag_offset[1])
//         ]
//         return { 
//             type: "SET_VP_XFORM_POS", 
//             item_type: item_type,
//             item_id: item_id,
//             drag_offset:drag_offset,
//         }
//     }
//     else{
//         return {type: "x"};
//     }
// }
