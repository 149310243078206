/*
actions for handling attrs
*/


import * as actions_database from './actions_database';
import * as actions_cache from './actions_cache';
import * as Constants from '../../constants/constants';
import * as database_utils from '../../utils/database_utils'
import * as attribute_utils from '../../utils/attribute_utils'



// create item
export const add_attr = ( item_type, item_id, attr_id, attr_val, attr_parent, project_id) => {
    console.log('AC add_attr', item_type, item_id, attr_id, attr_val, attr_parent, project_id)
    return dispatch => {

        if (!attr_parent){
            attr_parent = 'attrs'
        }

        // data_to_set = {
        //     'hello':'hi there'
        // }

        let data_to_set = attr_val;
        // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id+'/'+attr_parent+'/'+attr_id
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id) +'/'+attr_parent+'/'+attr_id

        actions_database.set_db_data(db_path, data_to_set, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "ADD_ATTRIBUTE", 
                item_type:item_type,
                item_id:item_id,
                attr_id:attr_id,
                attr_val:data_to_set,
                attr_parent:attr_parent,
            }),
            
        )
        // not good to have to pass in project object... is there a better way?

    }
};


export const delete_attr = (event, item_data) => {
    // this is an alternate delete item which can deal with array sel

    console.log('AC delete_attr', item_data )
    return dispatch => {

    //     event.stopPropagation();


    //     Object.keys(item_data).map((item_id) => {
    //         let item_type = item_data[item_id]['item_type']
    //         // event.stopPropagation();
    //         let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id
    //         actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
    //         .then (
    //             dispatch( { 
    //                 type: "DELETE_ATTRIBUTE", 
    //                 item_type:item_type,
    //                 item_id:item_id,
    //             }),
    //         )
    //     })

    }
};




// create item
export const set_attr_by_path = (attr_val, item, item_type, item_id, attr_path, project_id, time, timeline) => {
    console.log('AC set_attr_by_path', attr_val, item, item_type, item_id, attr_path, project_id, time, timeline)
    return dispatch => {


        // let data_to_set = attr_val;

        // // this bit is to work with keyframes
        // let cur_val = item[attr_parent][attr_id]
        // // test if cur_val is object
        // if (typeof cur_val === 'object' && cur_val !== null ){
        //     if (cur_val.hasOwnProperty('_keys')){
        //         console.log('XXXX got keys cur_val', cur_val)
        //         console.log('XXXX got time', time)
        //         console.log('XXXX got timeline', timeline)
        //         cur_val['_keys'][timeline][time] = attr_val;
        //         data_to_set = cur_val;
        //     }

        // }

        console.log('AC set_attr_by_path item_id', item_id,)
        console.log('AC set_attr_by_path attr_path', attr_path,)


        let data_to_set = attr_val;
        let cur_val = attribute_utils.get_attr_value_from_path(item, attr_path);
        // let cur_val = item[attr_path]

        if (typeof cur_val === 'object' && cur_val !== null ){
            if (cur_val.hasOwnProperty('_keys')){
                console.log('XXXX got keys cur_val', cur_val)
                console.log('XXXX got time', time)
                console.log('XXXX got timeline', timeline)
                cur_val['_keys'][timeline][time] = attr_val;
                data_to_set = cur_val;
            }

        }
        // let cur_val_data = attribute_utils.get_current_value_from_attr_path( item, attr_path, time, timeline)
        // let data_to_set = cur_val_data.value;

        // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id+'/'+attr_parent+'/'+attr_id
        let db_path_item = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id);
        console.log('set_attr_by_path db_path_item', db_path_item)
        let db_attr_path = database_utils.get_database_path_from_attr_path(attr_path);
        console.log('set_attr_by_path db_attr_path', db_attr_path)

        let db_path = db_path_item + '/'+  db_attr_path

        actions_database.set_db_data(db_path, data_to_set, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "SET_ATTRIBUTE_BY_PATH", 
                item_type:item_type,
                item_id:item_id,
                attr_path:attr_path,
                attr_val:data_to_set,
            }),
            
        )
        .then (
            dispatch(  
            // let items_and_ids = [[this.props.item, this.props.item_id]]
            actions_cache.update_keyframes_cache([[item, item_id, item_type]], timeline)
            ),
        )
        // not good to have to pass in project object... is there a better way?

    }
};



// create item
export const set_attr = (item, item_type, item_id, attr_id, attr_val, attr_parent, project_id, time, timeline) => {
    console.log('AC set_attr', item, item_type, item_id, attr_id, attr_val, attr_parent, project_id, time, timeline)
    return dispatch => {

        let cur_val;
        if (attr_parent){
            cur_val = item[attr_parent][attr_id];
        }
        else{
            cur_val = item[attr_id];
            // attr_parent = 'attrs'
        }
        let data_to_set = attr_val;

        // this bit is to work with keyframes
        // test if cur_val is object
        if (typeof cur_val === 'object' && cur_val !== null ){
            if (cur_val.hasOwnProperty('_keys')){
                console.log('XXXX got keys cur_val', cur_val)
                console.log('XXXX got time', time)
                console.log('XXXX got timeline', timeline)
                cur_val['_keys'][timeline][time] = attr_val;
                data_to_set = cur_val;
            }

        }


        // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id+'/'+attr_parent+'/'+attr_id
        let db_path;
        if (attr_parent){
            db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id) +'/'+attr_parent+'/'+attr_id;
        }
        else{
            db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id) +'/'+attr_id;
        }


        actions_database.set_db_data(db_path, data_to_set, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "SET_ATTRIBUTE", 
                item_type:item_type,
                item_id:item_id,
                attr_id:attr_id,
                attr_val:data_to_set,
                attr_parent:attr_parent,
            }),
            
        )
        
        // not good to have to pass in project object... is there a better way?

    }
};


export const add_to_timeline = (item, item_type, item_id, attr_id,  attr_parent, project_id, time, timeline) => {
    console.log('AC add_to_timeline', item, item_type, item_id, attr_id,  attr_parent, project_id, time, timeline)


    return dispatch => {

        if (!attr_parent){
            attr_parent = 'attrs'
        }

        // see if attr has keys and what current value is. Will set to that
        let cur_val_data = attribute_utils.get_current_value_from_attr( item, attr_parent, attr_id, time, timeline)
        if (cur_val_data.is_keyed){
            console.log('Already keys on this attr! skipping', item_id, attr_id)
            return;
        }
        // let data_to_set = cur_val_data.value;

        let data_to_set = {'_keys':{[timeline]:{[time]:cur_val_data.value}}}


        // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id+'/'+attr_parent+'/'+attr_id
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id) +'/'+attr_parent+'/'+attr_id

        actions_database.set_db_data(db_path, data_to_set, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "SET_ATTRIBUTE", 
                item_type:item_type,
                item_id:item_id,
                attr_id:attr_id,
                attr_val:data_to_set,
                attr_parent:attr_parent,
            }),
            
        )
        .then (
            dispatch(  
            // let items_and_ids = [[this.props.item, this.props.item_id]]
            actions_cache.update_keyframes_cache([[item, item_id, item_type]], timeline)
            ),
        )
        // not good to have to pass in project object... is there a better way?

    }
};

export const remove_from_timeline = (item, item_type, item_id, attr_id,  attr_parent, project_id, time, timeline) => {
    console.log('AC remove_from_timeline', item, item_type, item_id, attr_id,  attr_parent, project_id, time, timeline)


    return dispatch => {

        if (!attr_parent){
            attr_parent = 'attrs'
        }

        // see if attr has keys and what current value is. Will set to that
        let cur_val_data = attribute_utils.get_current_value_from_attr( item, attr_parent, attr_id, time, timeline)
        if (!cur_val_data.is_keyed){
            console.log('No keys on this attr! skipping', item_id, attr_id)
            return;
        }
        let data_to_set = cur_val_data.value;
        // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id+'/'+attr_parent+'/'+attr_id
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id) +'/'+attr_parent+'/'+attr_id

        actions_database.set_db_data(db_path, data_to_set, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "SET_ATTRIBUTE", 
                item_type:item_type,
                item_id:item_id,
                attr_id:attr_id,
                attr_val:data_to_set,
                attr_parent:attr_parent,
            }),
            
        )
        .then (
            dispatch(  
            // let items_and_ids = [[this.props.item, this.props.item_id]]
            actions_cache.update_keyframes_cache([[item, item_id, item_type]], timeline)
            ),
        )
        // not good to have to pass in project object... is there a better way?

    }
};








export const add_key = (item, item_type, item_id, attr_id, attr_parent, project_id, time, timeline) => {
    console.log('AC add_key', item, item_type, item_id, attr_id, attr_parent, project_id, time, timeline)
    return dispatch => {

        if (!attr_parent){
            attr_parent = 'attrs'
        }

        let cur_val_data = attribute_utils.get_current_value_from_attr( item, attr_parent, attr_id, time, timeline)

        let data_to_set = null;

        // this bit is to work with keyframes
        let cur_val = item[attr_parent][attr_id]
        // test if cur_val is object
        if (typeof cur_val === 'object' && cur_val !== null ){
            if (cur_val.hasOwnProperty('_keys')){
                console.log('add_key got keys cur_val', cur_val)
                console.log('add_key got time', time)
                console.log('add_key got timeline', timeline)
                cur_val['_keys'][timeline][time] = cur_val_data.value;
                data_to_set = cur_val;
            }

        }

        if (!data_to_set === null){

            // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id+'/'+attr_parent+'/'+attr_id
            let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id) +'/'+attr_parent+'/'+attr_id

            actions_database.set_db_data(db_path, data_to_set, window.SKIP_DATABASE)    
            .then (
                dispatch( { 
                    type: "SET_ATTRIBUTE", 
                    item_type:item_type,
                    item_id:item_id,
                    attr_id:attr_id,
                    attr_val:data_to_set,
                    attr_parent:attr_parent,
                }),
                
            )
        }
        
    }
};

export const delete_key = (item, item_type, item_id, attr_id, attr_parent, project_id, time, timeline) => {
    console.log('AC delete_key', item, item_type, item_id, attr_id, attr_parent, project_id, time, timeline)
    return dispatch => {

        if (!attr_parent){
            attr_parent = 'attrs'
        }

        let data_to_set = null;

        // this bit is to work with keyframes
        let cur_val = item[attr_parent][attr_id]
        // test if cur_val is object
        if (typeof cur_val === 'object' && cur_val !== null ){
            if (cur_val.hasOwnProperty('_keys')){
                console.log('AC delete_key got keys cur_val', cur_val)
                console.log('AC delete_key got time', time)
                console.log('AC delete_key got timeline', timeline)
                if (cur_val['_keys'].hasOwnProperty(timeline)){
                    if (cur_val['_keys'][timeline].hasOwnProperty(time)){
                        delete cur_val['_keys'][timeline][time]
                        data_to_set = cur_val;
                    }
                }
            }
        }

        if (!data_to_set === null){

            // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id+'/'+attr_parent+'/'+attr_id
            let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id) +'/'+attr_parent+'/'+attr_id

            actions_database.set_db_data(db_path, data_to_set, window.SKIP_DATABASE)    
            .then (
                dispatch( { 
                    type: "SET_ATTRIBUTE", 
                    item_type:item_type,
                    item_id:item_id,
                    attr_id:attr_id,
                    attr_val:data_to_set,
                    attr_parent:attr_parent,
                }),
                
            )
        }
        
    }
};
