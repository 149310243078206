const templates = {
    project:{
        name:'new project',
        description:'',
        created_by:'',
        creation_date:'',
        owner:'',
        parent_item:'', // eg, this might be place to link to a 'series'
        links:{},
    },
    user:{
        // name:'Bob Geldoff',
        email:'homer.simpson@gmail.com',
        // icon:'homer.jpg',
        links:{
            project:[],
        },
        show_guides:true,

    },
    card:{
        pos:[0,0],
        size:[294, 196],
        item_parent:'topLevel',

        title:'',
        synopsis:'',
        links: {},
        // time:{
        //     'story_percentage':{
        //         start_end_time:[0,10],
        //         row:1,
        //     }
        // },
    },
    region:{
        name:'',
        pos:[0,0],
        size:[1000, 800],
        item_parent:'topLevel',
        // colour:'rgba(50, 200, 100, .2)',
        color:[50, 100, 200],
        opacity:.3,
        icon:'far fa-sticky-note',
        magnetic:false,
    },
    document:{
        title:'',
        synopsis:'',
        text:'',
    },
    flow_viewport: {
        pos:[0,0],
        scale:1.0000,
    },
    timeline: {
        pos:[0,0],
        scale:1.0000,
        start_time:0,
        end_time:100,
        current_time:0,
    },
    character:{
        name:'',
        age:'',
        icon:'fas fa-user',
        image:'',
        description:'',
        itemSize:'large',
        dob:'',
        links: {},
    },
    prop:{
        name:'',
        icon:"fas fa-wrench",
        description:'',
        links: {},
        image:'',
    },
    location:{
        name:'',
        icon:"fas fa-archway",
        description:'',
        links: {},
        image:'',
        pos:[0,0],
        item_parent:'topLevel',
    },
    event:{
        name:'',
        icon:"far fa-calendar-alt",
        description:'',
        links: {},
        image:'',
        color:[255,120,203],
        opacity:.5,
        time:{
            '_time_progress':{
                start_end_time:[12,12],
                // row:1,
            }
        },
    },
    group:{
        name:'',
        type:'',
        items:[],
        iconSize:'small',
        color:[52,69,99],
        opacity:0.2,
        collapsed:false,
    },
}

export default templates