import React, { Component } from 'react';
import { connect} from 'react-redux';
import './WritingFilterOptions.css';

import Checkbox from '../../inputs/Checkbox';
import SelectMenu from '../../inputs/SelectMenu';
import ThreadPickerItem from '../thread_picker/ThreadPickerItem'



class WritingFilterOptions extends Component {
    // constructor(props) {
    //     super(props);
    //     this.handleUserIconClick = this.handleUserIconClick.bind(this);
    //     this.handleLogOutUserClick = this.handleLogOutUserClick.bind(this);
    //     this.fileSelectHandler = this.fileSelectHandler.bind(this);
    //     this.handleThumbClick = this.handleThumbClick.bind(this);
    // }


    // handleUserIconClick() {
    //     console.log('handleUserIconClick')

    // }

    // handleLogOutUserClick(e) {
    // }

    populateAllThreads() {

        var result = Object.keys(this.props.thread_data).map((col_uid) => {
            // console.log('AA', col_uid)
            if (col_uid !== 'cur_dragging'){
                // console.log('BB', col_uid)

                let thread_name = this.props.thread_data[col_uid]['name']
                let thread_color = this.props.thread_data[col_uid]['color']
                return(
                    
                    <ThreadPickerItem key={col_uid} thread_id={col_uid} thread_color={thread_color} thread_name={thread_name}/>

                
                ); 
            }
        })
        return  result;
    }

    populateMenu() {
        let return_list = [];

        if (this.props.ui_data.writing_panel_settings.show_item_type === 'card'){
            let menu_items = [
                <Checkbox  key='a'
                    dataSource={"ui_data.writing_panel_settings.show_all_cards"} 
                    dataTarget={"ui_data.writing_panel_settings.show_all_cards"} 
                    label='show all cards'
                    dark={true}
                />
            ]
            let thread_pickers = this.populateAllThreads();
            return_list = menu_items.concat(thread_pickers);
        }

        return return_list;

    }


    render(){
        let experimental = this.props.ui_data.settings.experimental_mode;
        let show_item_type = this.props.ui_data.writing_panel_settings.show_item_type;

        return (

            <div
                className='WritingFilterOptionsDiv'
            >
                <SelectMenu  
                    item_list={['card', 'character', 'prop', 'location', 'event']}
                    dataSource={"ui_data.writing_panel_settings.show_item_type"} 
                    dataTarget={"ui_data.writing_panel_settings.show_item_type"} 
                    label='item type'
                    label_color='black'
                />
                
                <Checkbox  
                    dataSource={"ui_data.writing_panel_settings.only_show_with_doc"} 
                    dataTarget={"ui_data.writing_panel_settings.only_show_with_doc"} 
                    label='hide items without documents'
                    dark={true}
                />
                {experimental 
                    ?   this.populateMenu()
                    :   show_item_type === 'card' &&
                        <Checkbox  
                            dataSource={"ui_data.writing_panel_settings.hide_unconnected_cards"} 
                            dataTarget={"ui_data.writing_panel_settings.hide_unconnected_cards"} 
                            label='hide items not connected by thread'
                            dark={true}
                        />
                }
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
      ui_data:state.ui_data,
      user_data:state.user_data,
      context_data:state.context_data,
      thread_data:state.thread_data
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        // open_user_ui: () => dispatch(actionCreator_ui.open_user_ui()),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WritingFilterOptions);
