/*
This should be similar to reducer viewport but with a small amount of special casing for things like scaling non uniformly
*/

import get_mouse_pos_in_viewport from '../../utils/get_mouse_pos_in_viewport'
import get_time_from_timeslider from '../../utils/get_time_from_timeslider'


// const initialState = Object.assign({}, 
//     // initial_ui, 
//     // initial_timelines, 
//     // initial_flow_viewports, 
// );



const reducer_timeline = (state, newState, action) => {
    // const newState = {...state};
    
    var ui_data = action.ui_data || null;
    // //AAA console.log('UI DATA', ui_data)


    if(action.type === 'MOUSE_DOWN_TL'){

        // //AAA console.log('RD MOUSE_DOWN_VP', action, )
    
        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data = {...state.ui_data}

        newState.ui_data.control.local_down_pos = action.local_down_pos;
        newState.ui_data.control.local_current_pos = action.local_current_pos;
        newState.ui_data.control.local_drag_offset = action.local_drag_offset;
        newState.ui_data.control.local_orig_pos = action.local_orig_pos;

        
    }

    if(action.type === 'SET_TL_POS'){
        // //AAA console.log('RD SET_VP_POS', action)

        newState[action.vp_type +'_data'][action.vp_id] = {...state[action.vp_type +'_data'][action.vp_id]};
        newState[action.vp_type +'_data'] = {...state[action.vp_type +'_data']};

        newState[action.vp_type +'_data'][action.vp_id]['pos'] = action.pos;
    }

    if(action.type === 'ON_MOUSE_MOVE_TL'){

        let timeline_id = action.tl_id
        if (state.ui_data.control.dragging[0] === 'timeline' && state.ui_data.control.dragging[1] === timeline_id){

            // //AAA console.log('ON_VP_MOUSE_MOVE HERE, ui_data ', ui_data)

            let cur_time = get_time_from_timeslider(state, timeline_id, true, true)
        
            newState.timeline_data[timeline_id] = {...state.timeline_data[timeline_id]}
            newState.timeline_data[timeline_id]['current_time'] = cur_time
        
            // //AAA console.log('ON_MOUSE_MOVE_TL HERE, cur_time ', cur_time)

            
        }
       
    }


        
    if(action.type === 'SET_ZOOM_TL'){
        // console.log("RD SET_ZOOM_TL", action);

        newState[action.vp_type +'_data'][action.vp_id] = {...state[action.vp_type +'_data'][action.vp_id]};
        newState[action.vp_type +'_data'] = {...state[action.vp_type +'_data']};
        
        newState[action.vp_type +'_data'][action.vp_id]['scale'] = action.scale;

        
        // newState[action.vp_type +'_data'][action.vp_id]['pos'] = action.pos;

        // this is a hack to take rows(y ) out of the equation
        // let orig_pos = state[action.vp_type +'_data'][action.vp_id]['pos'];
        // newState[action.vp_type +'_data'][action.vp_id]['pos'] = [action.pos[0], orig_pos[1]];
        newState[action.vp_type +'_data'][action.vp_id]['pos'] = [action.pos[0], action.pos[1]];

    }

    if(action.type === 'SET_TIMELINE_TIME'){

        // console.log('RD SET_TIMELINE_TIME', action, )
    
        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data = {...state.ui_data}

        newState.timeline_data[action.timeline_id] = {...state.timeline_data[action.timeline_id]};
        newState.timeline_data = {...state.timeline_data};

        newState.timeline_data[action.timeline_id]['current_time'] = action.time;
        // newState.ui_data.local_orig_time = action.time
        newState.ui_data.control.local_drag_offset = [0,0];
        
    }

    if(action.type === 'SET_CLICKED_TL'){
        // console.log('RD SET_CLICKED', action, )

        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data = {...state.ui_data}

        newState.ui_data.control.clicked = action.data;

    }

    if(action.type === 'CREATE_NEW_TIMELINE'){
        // action.event.stopPropagation();
        console.log('RD CREATE_NEW_TIMELINE', action)


        newState['timeline_data'] = {...state['timeline_data']}

        // copy data from template
        // let new_vp_data = {...state['flow_viewport_data']['__template__']};
        // newState['flow_viewport_data'][action.card_id] = new_vp_data;
        newState['timeline_data'][action.uid] = action.data;


    }


    return newState;

};
export default reducer_timeline;