import React, { Component } from 'react';
import './ProjectCard.css';
// import jQuery;
import { connect} from 'react-redux';

// import InputField from '../../inputs/InputField';

// import * as actionCreator_cards from '../../../../store/actions/actions_cards';
// import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
// import * as actionCreator_timeline from '../../../../store/actions/actions_timeline';
// import * as actionCreator_time_cards from '../../../../store/actions/actions_time_cards';
import * as actionCreator_projects from '../../../../store/actions/actions_projects';
import * as actionCreator_ui from '../../../../store/actions/actions_ui';

class ProjectCardEmpty extends Component {
    constructor(props) {
        super(props);
        this.handleMouseClick = this.handleMouseClick.bind(this);
      }
    
      handleMouseClick(event) {
        // console.log('handleMouseClick',)
        // the order of these is important!
        let user_id = this.props.context_data.user;

        // this.props.create_new_project( this.props.project_data, user_id);
        this.props.new_proj_modal();
    };




    render() {
        return (
            <div
                onClick={this.handleMouseClick}
                className= "ProjectCardEmpty"
                >
                    Create New Project...

            </div>
        );
    };
}

const mapStateToProps = (state, props) => {
    return {
        ui_data:state.ui_data,
        context_data:state.context_data,
        project_data:state.project_data,
        // user_data:
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // create_new_project: ( project_data, user_id) => dispatch(actionCreator_projects.create_new_project( project_data, user_id)),
        new_proj_modal: () => dispatch(actionCreator_ui.open_modal_window('new_project', true)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(ProjectCardEmpty);
