import React, {Component} from 'react';
import { connect} from 'react-redux';

import './FilePicker.css';

import Image from '../objects/image/Image';
import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'

import * as actions_storage from "../../../store/actions/actions_storage";
import * as actions_users from "../../../store/actions/actions_users";
import * as storage_utils from '../../../utils/storage_utils';
import * as Constants from '../../../constants/constants';

class FilePicker extends Component {

    constructor(state) {
        super(state);
        this.state = {
            picture:null,
            pictureUrl:null,
            uploadProgress:-1,
            uploadTriggered:false,
        }
        
        // console.log('this.props', this.props)
    }


    componentDidUpdate(){
        // console.log('componentDidUpdate doUpload');
        if (this.props.doUpload){
            if (!this.state.uploadTriggered){
                if (!this.state.picture){
                    // console.log('no image picked!')
                    this.props.nothing_picked_callback();
                    return;
                }
                // console.log('through tests doUpload');
                // settting this to ensure the upload only happens once
                this.setState({uploadTriggered:true})
                this.upload_file()
            }
        }
    }
    render() {
        // let image_data = null;

        let image_data = ['icon', 'far fa-question-circle']
        // let dimensions = [64,64]
        if (this.props.item_data){
            image_data = this.props.item_data;
            // if (this.props.item_data.image){

            //     image_data = this.props.item_data.image
            //     // dimensions = [128,128]
            // }
            // else if (this.props.item_data.icon){
            //     image_data = ['icon', this.props.item_data.icon]
            // }
        }

        // console.log('FilePicker - image_data', image_data)


        // let pick_text = <div className='PickImageText'> pick image... </div>
        let pick_text = <div className='PickImageText'> </div>
        let fp_style = {}
        if (this.state.pictureUrl){
            // console.log('FilePicker - pictureUrl', this.state.pictureUrl)
            image_data = [this.state.uploadProgress, this.state.pictureUrl, ];
            pick_text = null;
            fp_style={borderStyle:'solid', }
        }
        // else if (this.props.placeholder_image_data){
        //     image_data = this.props.placeholder_image_data;
            
        // }

        return (
            
            <div className='FilePickerDiv' style={fp_style}>
                <input 
                    type='file' 
                    onChange={(event) => {this.displayPicture(event);}}
                    ref={fileInput => this.fileInput = fileInput}
                    style={{display:'none'}}
                />
                <Image 
                    image_data = {image_data}
                    onClick={() => this.fileInput.click()}
                    width='160'
                    height='160'
                />
                {pick_text}
            </div>
        )
    }

    displayPicture(event,) {
        // console.log('LKLK displayPicture', )

        if (!event.target.files.length){
            console.log('nothing selected!!!')
            return;
        }
        let reader = new FileReader();
        let file = event.target.files[0];

        if (file.size > Constants.UPLOAD_FILE_BYTE_LIMIT){
        // if (file.size > 3194304){
            let limit_size_text = storage_utils.humanFileSize(Constants.UPLOAD_FILE_BYTE_LIMIT)
            let size_text = storage_utils.humanFileSize(file.size)

            alert('File size is above '+limit_size_text+' limit!\nTry using a smaller file or re-saving this as a smaller jpg\n\n( '+ file.name+' : '+ size_text +' )');
            return
        }

        // TODO this is hackey
        // putting blocker in here to stop people uploading when their data is above a certain limit
        let user = this.props.user_data[this.props.current_user_id]
        // console.log('LKLK storage_used', user.storage_used)
        // console.log('LKLK file.size', file.size)
        // console.log('LKLK USER_STORAGE_BYTE_LIMIT', Constants.USER_STORAGE_BYTE_LIMIT)
        if (user.hasOwnProperty('storage_used')){
            if (user.storage_used + file.size > Constants.USER_STORAGE_BYTE_LIMIT){
            // if (user.storage_used > 0){

                let maximum_size_text = storage_utils.humanFileSize(Constants.USER_STORAGE_BYTE_LIMIT)
                let size_text = storage_utils.humanFileSize(user.storage_used)
                let file_size_text = storage_utils.humanFileSize(file.size)

                alert('Cannot pick this file as it will cause you to exceed your maximum storage limit of '+ maximum_size_text+ '! \n\nFunctionality to delete items from storage has not been developed yet! Sorry. Contact Avi if you need to have space cleared\n\n( current size: '+ size_text + ', file size: '+ file_size_text +')' );
                return
            }
        }


        reader.onloadend = () => {
            // console.log('reader.result', reader.result)
            this.setState({
                picture:file,
                pictureUrl: reader.result,
                uploadTriggered:false,
            })
            if (this.props.dataTarget){
                let data_to_set = ['local', reader.result];
                this.props.set_url_to_store_path(data_to_set, this.props.dataTarget, this.props.current_project_id)
            }

            if (this.props.file_change_callback){
                this.props.file_change_callback(file, reader.result)
            }


        }
        reader.readAsDataURL(file);
    }

    
    upload_file(){
        // console.log('IN upload_file this.state.picture', this.state.picture)

        this.props.upload_file_to_storage(
            this.state.picture, 
            this.props.storage_path,
            this.props.current_user_id,
            this.props.current_project_id,
            this.complete_cb.bind(this), 
            this.progress_cb.bind(this)
        );
    }
    progress_cb(progress) {

        // NOTE: this system will only allow for 1 thing uploading at a time (which is consistent with current UI)
        // In the future, may want to switch to an array and test if array is empty
        this.props.set_is_uploading(true)

        // this will run to update progress state, regardless of whether callback is passed in props
        // console.log('qqqq- CB prog ', progress)
        this.setState({uploadProgress:progress})

        if (this.props.dataTarget){
            let data_to_set = [progress, this.state.pictureUrl];
            this.props.set_url_to_store_path(data_to_set, this.props.dataTarget, this.props.current_project_id)
            // this.props.set_url_to_store_path(reader.result, 'my_items.test_this')
        }

        // if there is suplied progress callback run here
        if (this.props.progress_callback){
            this.props.progress_callback(progress);
        }
    }

    complete_cb(url, file_name, file_size) {
        // console.log('qqqq- CB complete ', url, file_name, file_size)
        this.setState({pictureUrl:url, uploadProgress:100})

        // NOTE: this system will only allow for 1 thing uploading at a time (which is consistent with current UI)
        // In the future, may want to switch to an array and test if array is empty
        this.props.set_is_uploading(false)

        if (this.props.complete_callback){
            this.props.complete_callback(url);
        }
        if (this.props.dataTarget){
            // if i pass in a store path, then set the final url to that redux store path
            this.props.set_url_to_store_path(url, this.props.dataTarget, this.props.current_project_id)
        }

        this.props.set_user_storage_used(this.props.current_user_id, file_size)

    }


}


const mapStateToProps = (state, props) => {
    let to_return = {
        current_user_id:state.context_data.user,
        user_data:state.user_data,
        current_project_id:state.ui_data.current.current_project,
    }
    if (props.dataSource){
        // console.log('FilePicker - HEREHEREHER', props.dataSource)
        // console.log('FilePicker - HEREHEREHER 2 ',  get_data_obj_from_string(state, props.dataSource))
        to_return['item_data'] = get_data_obj_from_string(state, props.dataSource)

    }
 
    return to_return;

}


const mapDispatchToProps = (dispatch, props) => {
    return {
        upload_file_to_storage: (file, storage_path, user_id, project_id, complete_callback, progress_callback) => dispatch(actions_storage.upload_file_to_storage_b(file, storage_path, user_id, project_id, complete_callback, progress_callback)),
        // set_url_to_store_path: (url, dataTarget) => dispatch({type:'SET_URL_TO_STORE_PATH', val_to_set:url, dataTarget:dataTarget}),
        // set_url_to_store_path: (url, dataTarget) => dispatch({type:'SET_INPUT_VAL', val_to_set:url, dataTarget:dataTarget}),
        set_url_to_store_path:(url, dataTarget, project_id) => dispatch(actions_storage.set_store_and_db_val(url, dataTarget, project_id)),
        set_user_storage_used:(user_uid, value) => dispatch(actions_users.set_user_storage_used(user_uid, value)),
        
        set_is_uploading: (val) => dispatch({type:'SET_UPLOADING', is_uploading:val}),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(FilePicker);


