import React, { Component } from 'react';
import './AddAttributeWindow.css';
import { connect} from 'react-redux';
import InputField from '../ui_elements/inputs/InputField';
import Button from '../ui_elements/inputs/Button';
import List from '../ui_elements/inputs/List';
import SelectMenu from '../ui_elements/inputs/SelectMenu';
import Checkbox from '../ui_elements/inputs/Checkbox';
import Attribute from '../ui_elements/inputs/Attribute';
import ItemContainer from '../ui_elements/objects/item_container/ItemContainer';
import StoryItemEmpty from '../ui_elements/objects/story_item/StoryItemEmpty';
import Frame from '../ui_elements/objects/general/Frame';
import new_uid from '../../utils/new_uid'


import * as actionCreator_ui from '../../store/actions/actions_ui';
import * as actionCreator_attributes from '../../store/actions/actions_attributes';
import * as actionCreator_inputs from '../../store/actions/actions_inputs';



class AddAttributeWindow extends Component {
    constructor(props) {
        super(props);
        this.handleClickAddAttr = this.handleClickAddAttr.bind(this);
        this.handleAttrListChange = this.handleAttrListChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        // this.handleChangeNewAttrTypeSelect = this.handleChangeNewAttrTypeSelect.bind(this);
        this.state = {
            aaa:'bbb', 
            my_test_thing:'her it is my test thing',
            new_attr_decimal_places:0,
            new_attr_name:'new_attr',
            AttrListElement:['__new_attr'],
            new_attr_min:0,
            new_attr_max:100,
        };

    }

    handleClickAddAttr(event) {
        // this.props.link_items(this.props.item_data, item_type, item_id, to_link_type, to_link_ids);
        // console.log('\n -- handleClickAddAttr this.props.modal_input_data', this.props.modal_input_data)
        // let item_data = this.props[this.props.modal_input_data[1]['item_type'] + '_data'][this.props.modal_input_data[1]['item_id']]
        // this.props.modal_link_item(item_data, this.props.modal_input_data[1]['item_type'], this.props.modal_input_data[1]['item_id'], this.props.picked_items, this.props.modal_input_data[0], this.props.current_project_id)
        let item_type = this.props.modal_input_data['item_type']
        let item_id = this.props.modal_input_data['item_id']
  
        let list_sel = this.state['AttrListElement'];

        // THIS IS HACK for now until i have a better way of saying new attr or picked from list
        if (list_sel.length){

            for (let index = 0; index < list_sel.length; index++) {
                let attr_id = list_sel[index];

                if (attr_id === '__new_attr'){
                    // this will be my code for adding new attr 
                    // make new dict obj of attr data then add it
                    let new_attr_uid = 'attr_'+new_uid();
                    let new_attr_obj = {
                        type:this.state.new_attr_type,
                        name:this.state.new_attr_name,
                        default:this.state.new_attr_default,
                        parent:'attrs',
                    }
                    if (this.state.new_attr_type === 'number'){
                        new_attr_obj['default'] = parseFloat(parseFloat(this.state.new_attr_default).toFixed(this.state.new_attr_decimal_places));
                        new_attr_obj['decimal_places'] = parseInt(this.state.new_attr_decimal_places);
                    }
                    if (this.state.new_attr_type === 'slider'){
                        new_attr_obj['min'] = parseFloat(parseFloat(this.state.new_attr_min).toFixed(this.state.new_attr_decimal_places));
                        new_attr_obj['max'] = parseFloat(parseFloat(this.state.new_attr_max).toFixed(this.state.new_attr_decimal_places));
                    }

                    
                    // console.log('handleClickAddAttr new_attr_uid', new_attr_uid)
                    // console.log('handleClickAddAttr new_attr_obj', new_attr_obj)
                    let dataTarget = 'attribute_data.'+new_attr_uid;
                    this.props.set_val_at_target( dataTarget, new_attr_obj, this.props.current_project_id)
                    this.props.add_attr(item_type, item_id, new_attr_uid, this.state.new_attr_default, 'attrs', this.props.current_project_id)

                }
                else{
                    let default_val = this.props.attribute_data[attr_id]['default']
                    // console.log('handleClickAddAttr about to add attr_id', item_type, item_id, attr_id, default_val)
                    this.props.add_attr(item_type, item_id, attr_id, default_val, 'attrs', this.props.current_project_id)
                }

            }
        }


        this.props.close_modal();
    }

    handleAttrListChange(value, list_id){
        // console.log('handleListChange value', value)
        // console.log('handleListChange list_id', list_id)
        this.setState({[list_id]:value})

    }
    handleInputChange(e){
        // console.log('handleInputChange e', e)
        // console.log('handleInputChange list_id', attr_id)

        let value = e.target.value;
        let attr_id = this.props.attr_id;
        this.setState({[attr_id]:value})

    }


    getAttributeList(){

        let attrs_to_list = [['* New Attribute', '__new_attr']];
        // let item = this.props[this.props.modal_input_data['item_type'] + '_data'][this.props.modal_input_data['item_id']]
        let item = this.props.modal_input_data['item'];

        let existing_attrs = [];
        if (item){
            if (item.hasOwnProperty('attrs')){
                for (let index = 0; index < Object.keys(item['attrs']).length; index++) {
                    existing_attrs.push( Object.keys(item['attrs'])[index]);
                }
            }
        }
        // console.log('getAttributeList existing_attrs', existing_attrs)

        for (let index = 0; index < Object.keys(this.props.attribute_data).length; index++) {
            let attr_id=Object.keys(this.props.attribute_data)[index]
            let attr_name = this.props.attribute_data[attr_id]['name']
            if (!existing_attrs.includes(attr_id)){
                attrs_to_list.push([attr_name, attr_id]);
            }
           
        }
        return attrs_to_list;
    }


    populateNewAttributes(){

        // let new_ui_to_add = [];
        let attr_elements = [];

        let list_sel = [];
        // let do_new_attr = false;
        if (this.state['AttrListElement']){
            list_sel = this.state['AttrListElement'];
        }

        for (let index = 0; index < list_sel.length; index++) {
            let attr_id = list_sel[index];

            if (attr_id === '__new_attr'){
                // do_new_attr = true;
                continue;
            }
            // const attr_id = Object.keys(this.props.attribute_data)[value];
            let attr_name = this.props.attribute_data[attr_id]['name'];
            let attr_type = this.props.attribute_data[attr_id]['type'];
            let attr_default = this.props.attribute_data[attr_id]['default'];
            let attr_val = this.state[attr_id]

            let item_list = [];
            if (attr_type === 'list'){
                item_list = this.props.attribute_data[attr_id]['list'];
                if (typeof item_list === 'object' && item_list !== null)
                {
                    if (item_list['source'] === 'all_by_type'){
                        let by_type_data = this.props[item_list['type']+'_data']
                        item_list = [['', null]];
                        for (let index = 0; index < Object.keys(by_type_data).length; index++) {
                            const item_id = Object.keys(by_type_data)[index];
                            item_list.push([by_type_data[item_id]['name'], item_id])
                        }
                    }
                }
            }            
            attr_elements.push(

                <Frame label={'Attribute Preview: (' + attr_type + ')'}>
                    {/* <div>{'attribute type: '+ attr_type}</div> */}

                    {/* // <div style={{backgroundColor:'blue'}} > {attr_name + ' - ' + attr_type} </div> */}
                    <Attribute 
                        label={attr_name}
                        inputValue={attr_val}
                        inputType={attr_type}
                        // item={this.props.character_data[this.props.item_id]}
                        // item_id={this.props.item_id}
                        // item_type={this.props.item_type}
                        attr_id={attr_name}
                        attr_parent='attrs'
                        defaultValue={attr_default}
                        // current_project={this.props.current_project_id}
                        // time={this.props.timeline_data[timeline].current_time}
                        // timeline={timeline}
                        // attr_path='age'
                        item_list={item_list}
                        onChangeFunction = {this.handleInputChange}
                        // function_args = {func_args}
                        is_keyable={false}
                    />
                </Frame>
            )
            
        }




        // temporarily commented this out unil i support multiple selections in list imput
        // console.log('attr ids', Object.keys(this.props.attribute_data))
        // for (let index = 0; index < Object.keys(this.props.attribute_data).length; index++) {
        //     const attr_id = Object.keys(this.props.attribute_data)[index];
        //     let attr_name = this.props.attribute_data[attr_id]['name']
        //     let attr_type = this.props.attribute_data[attr_id]['type']
        //     // console.log('attr_name', attr_name)
        //     // console.log('attr_type', attr_type)
        //     attr_elements.push(
        //         <div style={{backgroundColor:'blue'}} > {attr_name + ' - ' + attr_type} </div>
        //     )
            
        // }
        // if (do_new_attr){
        //     let new_attr_settings = new_attr_settings();
        //     attr_elements = new_attr_settings.concat(attr_elements) 
        // }
        if (attr_elements.length){
            return attr_elements;
            // return <Frame label='Attribute Preview:'>
            //     {attr_elements}
            // </Frame>

        }


    }

    // handleChangeNewAttrTypeSelect(e){
    //     console.log('handleChangeNewAttrTypeSelect', e.target.value)
    //     this.setState({new_attr_type:e.target.value})
    // }
    new_attr_settings(){

        let ui_to_add = [];
        let settings_ui_to_add = [];

        let list_sel = [];
        // let do_new_attr = false;
        if (this.state['AttrListElement']){
            list_sel = this.state['AttrListElement'];
        }
        if (!list_sel.includes('__new_attr')){
            return null;
        }

        settings_ui_to_add.push(

                <SelectMenu
                    item_list={['list', 'number', 'slider', 'text']}
                    label='attr type'
                    // onChangeFunction={this.handleChangeNewAttrTypeSelect}
                    onChangeFunction={(e) => {this.setState({new_attr_type:e.target.value})}}

                />,
                <InputField 
                    //dataSource={"card_data." + item_id + ".time."+current_timeline+".start_end_time.0"}
                    //dataTarget={"card_data." + item_id + ".time."+current_timeline+".start_end_time.0"}
                    inputType='text' 
                    style={{position:"relative"}} 
                    // id='AAA' 
                    label='attr name' 
                    onChangeFunction={(e) => {this.setState({new_attr_name:e.target.value})}}
                />,
                <InputField 
                    //dataSource={"card_data." + item_id + ".time."+current_timeline+".start_end_time.0"}
                    //dataTarget={"card_data." + item_id + ".time."+current_timeline+".start_end_time.0"}
                    inputType={this.state.new_attr_type}
                    style={{position:"relative"}} 
                    decimal_places={this.state.new_attr_decimal_places}
                    label='default val' 
                    onChangeFunction={(e) => {this.setState({new_attr_default:e.target.value})}}
                />,
        ) 
        if (['number', 'slider'].includes(this.state.new_attr_type)){
            settings_ui_to_add.push(
                <InputField 
                    inputType='number' 
                    decimal_places={0}
                    defaultValue={0}
                    style={{position:"relative"}} 
                    label='decimals' 
                    onChangeFunction={(e) => {this.setState({new_attr_decimal_places:e.target.value})}}
                />,
            )
        }
        if (this.state.new_attr_type === 'slider'){
            settings_ui_to_add.push(
                <InputField 
                    inputType='number' 
                    decimal_places={this.state.new_attr_decimal_places}
                    defaultValue={0}
                    style={{position:"relative"}} 
                    label='min' 
                    onChangeFunction={(e) => {this.setState({new_attr_min:e.target.value})}}
                />,
                <InputField 
                    inputType='number' 
                    decimal_places={this.state.new_attr_decimal_places}
                    defaultValue={100}
                    style={{position:"relative"}} 
                    label='max' 
                    onChangeFunction={(e) => {this.setState({new_attr_max:e.target.value})}}
                />,
            )
        }


        ui_to_add.push(
            <Frame label='Attribute Settings:'>
                {settings_ui_to_add}
            </Frame>
        )

        // if (['text', 'number'].includes(this.state.new_attr_type))
        // {
            ui_to_add.push(
                <Frame label='Attribute Preview:'>
                    <Attribute 
                        label={this.state.new_attr_name}
                        // inputValue={attr_val}
                        inputType={this.state.new_attr_type}
                        attr_id={this.state.new_attr_name}
                        attr_parent='attrs'
                        // item_list={item_list}
                        onChangeFunction = {this.handleInputChange}
                        decimal_places={this.state.new_attr_decimal_places}
                        defaultValue={this.state.new_attr_default}
                        inputPlaceholder={this.state.new_attr_default}
                        is_keyable={false}
                        min={this.state.new_attr_min}
                        max={this.state.new_attr_max}
                    />
                </Frame>
            )
        // }
        return ui_to_add;

    }

    render() {

        // let createWinStyle = null
        // let createWinStyleBG = null
        // // let windowWidth = 600;
        // // let windowHeight = 800;

        // let item_data = this.props.item_data;
        // let item_type = this.props.item_type;
        // let item_id = this.props.item_id;
    

        return (
            <div className = 'linkItemWindow'>
                {/* <Checkbox lable='new attribute'/> */}
                <div className='LeftSectionDiv'>
                    
                    {/* <l className='AddLabel'> {'Create New ' + this.props.modal_input_data[0]} </l> */}
                    <List
                        onChangeFunction={this.handleAttrListChange}
                        list_id = 'AttrListElement'
                        style={{position:'relative'}}
                        // item_list={Object.keys(this.props.attribute_data)}
                        item_list={this.getAttributeList()}
                        label='the things List'
                        defaultValue={['__new_attr']}
                    />
                </div>
                <div className='RightSectionDiv'>
                    {this.new_attr_settings()}
                    {this.populateNewAttributes()}
                </div>
                <Button 
                        onClickFunction={this.handleClickAddAttr} 
                        //onClickFunction={this.test_on_click} 
                        label='Add Attribute'
                        style={{position:'absolute', height:"45px", width:"140px", right:"10px",bottom:"4px"}}
                    />
            </div>
        )
    };
};
const mapStateToProps = (state, props) => {
    return {
        // modal_data:state.ui_data.modal_data,
        // modal_input_data:state.ui_data.openModalWindow[props.modalName],
        picked_items:state.ui_data.control.picked_items,
        character_data:state.character_data,
        attribute_data:state.attribute_data,
        card_data:state.card_data,
        prop_data:state.prop_data,
        event_data:state.event_data,
        location_data:state.location_data,
        current_project_id:state.ui_data.current.current_project,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // link_items: (item_data, item_type, item_id, to_link_type, to_link_ids) => dispatch(actionCreator_story_items.link_items(item_data, item_type, item_id, to_link_type, to_link_ids)),
        modal_link_item:(item_data, item_type, item_id, picked_items, to_link_type, project_id) => dispatch(actionCreator_ui.modal_link_item(item_data, item_type, item_id, picked_items, to_link_type, project_id)),
        add_attr: ( item_type, item_id, attr_id, attr_val, attr_parent, project_id) => dispatch(actionCreator_attributes.add_attr( item_type, item_id, attr_id, attr_val, attr_parent, project_id)),
        close_modal: () => dispatch({type:'CLOSE_MODAL_WINDOW', modal_name:'add_attrs'}),
        set_val_at_target: ( dataTarget, val_to_set, project_id) => dispatch(actionCreator_inputs.set_val_at_target(dataTarget, val_to_set, project_id)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps )(AddAttributeWindow);
