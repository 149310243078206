import React, { Component } from 'react';
import { connect} from 'react-redux';
import './UserItem.css';

import * as actionCreator_ui from '../../../../store/actions/actions_ui';

{/* <img id="UserIcon"  onClick={this.handleUserIconClick}   src={require('../../images/homer_icon_512.jpg')} /> */}

class UserItem extends Component {
    // constructor(props) {
    //     super(props);
    //     // this.handleUserIconClick = this.handleUserIconClick.bind(this);
    // }


    render(){
        let user_uid = this.props.context_data.user;
        // console.log('user_uid', user_uid)
        // let user_icon_url = this.props.user_data[user_uid].icon;
        let user_icon_url = null;
        if (this.props.user_data[user_uid])
        {
            user_icon_url = this.props.user_data[user_uid].image;
        }
        let icon_element = <div id="UserIcon_noImage_small" className='fas fa-user'/>
        if (user_icon_url){
            icon_element =  <img id="UserIcon" src={user_icon_url} />
        }
        let element_id = this.props.element_id || 'UserSettingsIcon';
        return (
            <div className="UserItem" id={element_id}
                // onClick={this.handleUserIconClick}
                onClick={this.props.onClick}
                style={this.props.style}
            >
                {/* <div className="UserItemIcon"> */}
                    {/* <i class="fas fa-user"></i> */}


                    {/* <i id='UserIcon' className={this.props.icon}></i> */}
                    {/* <img id="UserIcon" src={user_icon_url} /> */}
                    {icon_element}

                {/* </div> */}
                {/* <div className="UserItemLabel"> {this.props.name} </div> */}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      ui_data:state.ui_data,
      user_data:state.user_data,
      context_data:state.context_data,
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserItem);
