

const reducer_projects = (state, newState, action) => {


    if(action.type === 'SET_CURRENT_PROJECT'){
        // console.log('\n\nRD SET_CURRENT_PROJECT', action)

        // clear existing state  - don;t want it hanging over from prev selected proj
        // newState.thread_data = {};
        // let imported_thread_init = initial_threads_function();
        // console.log('  SCP initial_threads func', imported_thread_init)
        // console.log('  SCP initial_threads', initial_threads['thread_data'])
        // console.log('  SCP initial_threads_json', initial_threads_json)

        // let init_thread_deep_copy = utils.deepCopyFunction(initial_threads)
        // console.log('  SCP init_thread_deep_copy', init_thread_deep_copy)


        // this is so strange- initial_threads is bing mutated - so even though im importing from source file
        // the values have been chaged to whatever is current - eg i have connection data which doesnt exist in 
        // original data. Perhaps I should be using json to store init data rather than const?
        // newState.thread_data = {...initial_threads['thread_data']};

        // using the data generated by function is working but seems clumbsy
        // newState.thread_data = imported_thread_init;

        // trying importing data not in const
        // SO Weird - this is failing too in the same way as initial_threads. Its being mutated somehow


        

        newState.ui_data.current = {...state.ui_data.current};
        newState.ui_data = {...state.ui_data};
        newState.ui_data.current.current_project = action.project_id;
 
        newState.context_data.project = {...state.context_data.project};
        newState.context_data = {...state.context_data};
        // let proj = state.project_data[action.project_id];
        // if (proj){
        //     proj['uid'] = action.project_id;
        // }
        // newState.context_data.project = proj;
        // newState.context_data.project = state.project_data[action.project_id];
        newState.context_data.project = action.project_id;

        // // This was racing with my fetch from DB-- stamping over the top... i think
        // // commented it out and the app SEEMS to work ok... bit of a worry
        // let imported_thread_init = initial_threads_function();
        // console.log('initial_threads', imported_thread_init)
        // newState.thread_data = imported_thread_init;


        // // set current viewport to project one
        // console.log('AM HERE!!!!', )

        // if (state.flow_viewport_data.hasOwnProperty('project_' + action.project_id))
        // {
        //     console.log('AM HERE!!!!', 'project_' + action.project_id, state.flow_viewport_data, )
        //     // newState.ui_data.current.current_flow_viewport = 'project_' + action.project_id;
        //     newState.ui_data.open_viewport['flow_viewport'] = 'project_' + action.project_id;
        //     newState.ui_data.current.current_viewport = ['flow_viewport',  'project_' + action.project_id];
        // }
        // else{
        //     console.log('AM HERE!!!!', 'fallvback' )

        //     // newState.ui_data.current.current_flow_viewport = 'topLevel';
        //     newState.ui_data.open_viewport['flow_viewport'] = 'topLevel';
        //     newState.ui_data.current.current_viewport = ['flow_viewport',  'topLevel'];
        // }


        // Trying to fix bug where current VP isn't being set to new VP when creating a new proj
        // if (state.flow_viewport_data.hasOwnProperty('project_' + action.project_id))
        // {
            // console.log('XAXXAX AM HERE!!!!', 'project_' + action.project_id, state.flow_viewport_data, )
            // newState.ui_data.current.current_flow_viewport = 'project_' + action.project_id;
            newState.ui_data.open_viewport['flow_viewport'] = 'project_' + action.project_id;
            newState.ui_data.current.current_viewport = ['flow_viewport',  'project_' + action.project_id];
        // }

    }
    if(action.type === 'NEW_PROJECT'){
        // console.log('RD NEW_PROJECT', action)
        newState.project_data = {...state.project_data};
        newState.project_data[action.new_uid] = action.project_data;

        // Handling links in a differen reducer now

            // REMOVING THIS TO REPLACE user_data with context
            // newState.user_data[action.user_id].links = {...state.user_data[action.user_id].links};
            // newState.user_data[action.user_id] = {...state.user_data[action.user_id]};
            // newState.user_data = {...state.user_data};

            // let proj_links = [...state.user_data[action.user_id].links.project];


            // newState.context_data.user.links = {...state.context_data.user.links};
            // newState.context_data.user = {...state.context_data.user};
            // newState.context_data = {...state.context_data};

            // let proj_links = [...state.context_data.user.links.project];

            // console.log('proj_links', proj_links);
            // console.log('action.new_uid', action.new_uid);
            // proj_links.push(action.new_uid);

            // newState.context_data.user.links.project =  proj_links;
            // newState.user_data[action.user_id].links.project =  proj_links;

            // // create project viewports
            // THIS SHOULD BE DONE IN ACTION CREATOR
            // newState.flow_viewport_data['project_' + action.new_uid] = {pos:[0,0]};

    }

    if(action.type === 'SET_PROJECT_DATA'){
        // console.log('RD SET_PROJECT_DATA', action)
        newState.project_data = {...state.project_data};
        newState.project_data[action.uid] = action.project_data;
    }
    if(action.type === 'SET_PROJECTS'){
        // console.log('RD SET_PROJECTS', action)
        newState.projects = {...state.projects};
        newState.projects[action.uid] = action.projects;
    }

    if(action.type === 'SET_PROJECT_LAST_OPENED_DATE'){
        // console.log('RD SET_PROJECT_LAST_OPENED_DATE', action)
        newState.projects[action.uid] = {...state.projects[action.uid]};
        newState.projects = {...state.projects};


        newState.projects[action.uid]['opened_dates'] = action.date;
    }



    if(action.type === 'LINK_ITEMS_OBJ'){
        // console.log('RD LINK_ITEMS_OBJ', action)

        if (state[action.item_type + '_data'][action.item_id].hasOwnProperty('links')){
            if (state[action.item_type + '_data'][action.item_id]['links'].hasOwnProperty(action.to_link_type)){
                newState[action.item_type + '_data'][action.item_id]['links'][action.to_link_type] = {...state[action.item_type + '_data'][action.item_id]['links'][action.to_link_type]};
            }
            newState[action.item_type + '_data'][action.item_id]['links'] = {...state[action.item_type + '_data'][action.item_id]['links']};
        }
        else{
            // if links doesn't exist i'll make it here so i can add the to_link_type key to it later
            newState[action.item_type + '_data'][action.item_id]['links'] = {};
        }

        newState[action.item_type + '_data'][action.item_id] = {...state[action.item_type + '_data'][action.item_id]};
        newState[action.item_type + '_data'] = {...state[action.item_type + '_data']};

        
        newState[action.item_type + '_data'][action.item_id]['links'][action.to_link_type] = action.to_link_ids;
        console.log('RD action.to_link_ids', action.to_link_ids)
        // newState['card_data'][action.item_id]['links']['character'] = ['uid1'];
    }

    // if(action.type === 'LINK_THREAD_TO_PROJ'){
    //     console.log('RD LINK_THREAD', action)

    //     if (state['project_data'][action.project_id].hasOwnProperty('thread')){
    //         if (state['project_data'][action.project_id]['thread'].hasOwnProperty(action.thread_id)){
    //             newState['project_data'][action.project_id]['thread'][action.thread_id]['connections'] = {...state['project_data'][action.project_id]['thread'][action.thread_id]['connections']}
    //             newState['project_data'][action.project_id]['thread'][action.thread_id] = {...state['project_data'][action.project_id]['thread'][action.thread_id]}
    //             newState['project_data'][action.project_id]['thread'] = {...state['project_data'][action.project_id]['thread']}
    //         }
    //         else{
    //             newState['project_data'][action.project_id]['thread'][action.thread_id] ={'connections':{}};
    //         }
    //     }
    //     else{
    //         newState['project_data'][action.project_id]['thread'] = {[action.thread_id]:{'connections':{}}};
    //     }

    //     newState['project_data'][action.project_id]['thread'][action.thread_id]['connections'][action.connection_id] = true;


    // }





    if(action.type === 'SET_TYPE_ROOT_DATA'){
        // console.log('RD SET_TYPE_ROOT_DATA', action)
        // console.log('RD SET_TYPE_ROOT_DATA data_type', action.data_type)
        // console.log('RD SET_TYPE_ROOT_DATA data', action.data)

        newState[action.data_type + '_data'] = {...state[action.data_type + '_data']};
        let use_init_data = action.use_init_data;
        if (use_init_data === undefined ) use_init_data= false;

        // let use_init_data = true;

        if (use_init_data){
            Object.assign(newState[action.data_type + '_data'], action.data);

        }
        else{
            newState[action.data_type + '_data'] = {};
            newState[action.data_type + '_data'] = {...action.data};
    
            // newState[action.data_type + '_data'] = {...action.data};
        }


        // TODO MIGHT BE BETTER IF THIS WORKED BY MERGING DATA RATHER THAN REPLACING - OR hAVE OPTION.
        // THIS WOULD ALLOW FOR WORKING WITH INIT VALUES - Not blowing them away
         

        // Object.assign(target, source1, soure2

        // hacky as hell thing to get rid of connections because some of mutibility issue I don't understand
        // TODO - THIS still isnt workign!!!!
        // database is being updated but redux is not. if i reload page it gets threads back from redux
        // if (action.data_type == 'thread'){
        //     for (let i = 0; i < Object.keys(newState[action.data_type + '_data']).length; i++) {
        //         console.log('DEL THREAD in loop', i)
        //         let thread_id =  Object.keys(newState[action.data_type + '_data'])[i];
        //         console.log('DEL THREAD in loop key', thread_id)
        //         if (newState[action.data_type + '_data'][thread_id].hasOwnProperty('connections')){
        //             newState[action.data_type + '_data'][thread_id]['connections'] = {};
        //             console.log('DEL THREAD in loop  -- - - did it!!!', newState[action.data_type + '_data'][thread_id]['connections'])

        //         }
        //     }

        // }
    }





    return newState;

};
export default reducer_projects;