

import new_uid from '../../utils/new_uid';
import templates from '../templates/templates';
import * as actions_database from './actions_database';
import * as actions_storage from './actions_storage';
import * as Constants from '../../constants/constants';

// import * as firebase from "firebase";
// import firebase from 'firebase/app';
// import 'firebase/auth'


export const set_current_user = ( user_id) => {
    // this probably wants fleshing out to hold more data.
    // might be better taking user object and then picking the useful bits out
    // console.log('AC set_current_user', user_id)

    // event.stopPropagation();

    return dispatch => {
        // event.stopPropagation();
        // let db_path = 'story_app_data/ui_data/current_thread_colour'

        // actions_database.set_db_data(db_path, thread_id, window.SKIP_DATABASE)    
        // .then (
            dispatch( { 
                type: "SET_CURRENT_USER", 
                user_id:user_id,
            })
        // )
    }
}


export const create_new_user = (uid, email ) => {
    // console.log('AC create_new_user',  uid, email)

    return dispatch => {
        // let uid = 'newProject_'+new_uid();
        let db_path = Constants.DB_USER_DATA+'/'+uid;

        //get card data from template and update pos and parent
        let user_data = {...templates['user']};
        user_data.email = email;

        // console.log('user_data', user_data)
        // database.ref(db_path).set(card_data)
        actions_database.set_db_data(db_path, user_data, window.SKIP_DATABASE)    
        .then (
            dispatch( { type: "NEW_USER", 
                new_uid:uid, 
                user_data:user_data,
            }),
        )
    }


}

export const delete_user = (thread_id, connection_id) => {
    // console.log('AC delete_user', thread_id, connection_id )
    // return dispatch => {
    //     // event.stopPropagation();
    //     let db_path = 'story_app_data/thread_data/'+thread_id+'/connections/'+connection_id

    //     actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
    //     .then (
    //         dispatch( { 
    //             type: "DELETE_THREAD", 
    //             thread_id:thread_id,
    //             connection_id:connection_id,
    //         }),
    //     )
    // }
}




//TODO Flesh this out
export const link_project_to_user = (context, user_data, project_id, ) => {
    // console.log('AC link_project_to_user', context, user_data, project_id)
    return dispatch => {

        let user_id = context.user;
        

        // console.log('AC link_to_project B',)

        // TODO need to test if to_link_type is a key of item_data['links']. if not create it.
        let all_link_ids = [];
        if (user_data[user_id].hasOwnProperty('links')){
            if (user_data[user_id].links.hasOwnProperty('project')){
                    all_link_ids = [...user_data[user_id].links.project];
            }
        }
        // console.log('all_link_ids PRE', all_link_ids)

        // add project id to list of link_ids
        if (!all_link_ids.includes(project_id)){
                // console.log('link_item - adding this one!', project_id)

                all_link_ids.push(project_id)
            }

        // console.log('all_link_ids POST', all_link_ids)
        // console.log('all_link_ids POST, constext pro', context.user.links.project)

        let db_path = Constants.DB_USER_DATA+'/'+user_id+'/links/project'
        actions_database.set_db_data(db_path, all_link_ids, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "LINK_ITEMS", 
                item_type:'user',
                item_id:user_id,
                to_link_type:'project',
                to_link_ids:all_link_ids,
            }),
        )
    }

};



// export const link_project_to_user = (project_object, project_id, to_link_type, to_link_ids) => {
//     console.log('AC link_project_to_user', project_object, project_id, to_link_type, to_link_ids)
//     return dispatch => {

//         console.log('AC link_to_project B',)

//         // TODO need to test if to_link_type is a key of item_data['links']. if not create it.
//         let all_link_ids = [];
//         if (project_object['links'].hasOwnProperty(to_link_type)){
//             all_link_ids = [...project_object['links'][to_link_type]];
//         }

//         // let all_link_ids = item_data['links'][to_link_type];
//         console.log('all_link_ids', all_link_ids)
//         // let new_link_id_array = [];
//         // loop through all links to add and add them to array if they dont exist
//         for(var i=0; i < to_link_ids.length; ++i) {
//             if (!all_link_ids.includes(to_link_ids[i])){
//                     console.log('link_item - adding this one!', to_link_ids[i])

//                     all_link_ids.push(to_link_ids[i])
//             }
//         }

//         console.log('all_link_ids', all_link_ids)

//         let db_path = 'story_app_data/project_data/'+project_id+'/links/'+to_link_type
//         actions_database.set_db_data(db_path, all_link_ids, window.SKIP_DATABASE)    
//         .then (
//             dispatch( { 
//                 type: "LINK_ITEMS", 
//                 item_type:'project',
//                 item_id:project_id,
//                 to_link_type:to_link_type,
//                 to_link_ids:all_link_ids,
//             }),
//         )
//     }

// };

export const set_user_icon_url = (url, call_back_input) => {
    // console.log('AC set_user_icon_url',  url, call_back_input)

    return dispatch => {
        let user_uid = call_back_input.user_uid
        let db_path = Constants.DB_USER_DATA+'/'+user_uid+'/icon_url';

        actions_database.set_db_data(db_path, url, window.SKIP_DATABASE)    
        .then (
            dispatch( { type: "SET_USER_ICON_URL", 
                user_uid:user_uid, 
                icon_url:url,
            }),
        )
    }


}
export const pick_user_icon = (file, user_uid) => {
    // console.log('AC pick_user_icon', file, user_uid)

    
    return dispatch => {

        actions_storage.upload_file_to_storage_b(file, 'user_icon', user_uid, null, set_user_icon_url, {user_uid:user_uid})
        .then(
            console.log('then do this')
        )

    }


}


export const set_user_guide = (user_uid, guide, value) => {
    // console.log('AC set_user_guide_',  user_uid, guide, value)

    return dispatch => {
        let db_path = Constants.DB_USER_DATA+'/'+user_uid+'/guides/'+guide;

        actions_database.set_db_data(db_path, value, window.SKIP_DATABASE)    
        .then (
            dispatch( { type: "SET_USER_GUIDE", 
                user_uid:user_uid, 
                guide:guide,
                value:value,
            }),
        )
    }
}


export const set_user_guide_b = (guide, value) => {
    // this is a variation of set_user_guide, but I'll get the user_id from dispatch

    return (dispatch, getState) => {
        let user_uid = getState().context_data.user;
        // console.log('AC set_user_guide_b', user_uid, guide, value)

        let db_path = Constants.DB_USER_DATA+'/'+user_uid+'/guides/'+guide;

        actions_database.set_db_data(db_path, value, window.SKIP_DATABASE)    
        .then (
            dispatch( { type: "SET_USER_GUIDE", 
                user_uid:user_uid, 
                guide:guide,
                value:value,
            }),
        )
    }
}


export const reset_user_guides = (user_uid) => {
    // console.log('AC set_user_guide_',  user_uid)

    return dispatch => {
        let db_path = Constants.DB_USER_DATA+'/'+user_uid+'/guides/';

        actions_database.set_db_data(db_path, [], window.SKIP_DATABASE)    
        .then (
            dispatch( { type: "RESET_USER_GUIDES", 
                user_uid:user_uid, 
            }),
        )
    }


}


export const add_user_usage_date = ( user_id, user) => {
    // console.log('AC add_user_usage_date', user_id, user)

    return dispatch => {

        let usage_dates_array = [];
        if (user.hasOwnProperty('usage_dates') ){
            usage_dates_array = [...user.usage_dates];
        }

        let date = new Date().toUTCString();

        // this is a hack to get around not handling async well enough.
        if (usage_dates_array.includes(date)){
            // console.log('AC add_user_usage_date - already existst!', date)
        }
        else{
            usage_dates_array.unshift(date);
        }

        let db_path = Constants.DB_USER_DATA+'/'+user_id+'/usage_dates/';

        actions_database.set_db_data(db_path, usage_dates_array, window.SKIP_DATABASE) 
        .then (
            dispatch( { 
                type: "SET_USER_USAGE_DATE", 
                uid:user_id,
                date: usage_dates_array,
            })
        )
    }
}


export const set_user_storage_used = (user_uid, value) => {
    // this is a variation of set_user_guide, but I'll get the user_id from dispatch
    console.log('AC set_user_storage_used', user_uid, value)

    return (dispatch, getState) => {
        let user = getState().user_data[user_uid];

        let storage_used = 0
        if (user.hasOwnProperty('storage_used')){
            storage_used = user.storage_used
        }
        storage_used += value

        // console.log('AC set_user_storage_used user', user)

        let db_path = Constants.DB_USER_DATA+'/'+user_uid+'/storage_used';

        actions_database.set_db_data(db_path, storage_used, window.SKIP_DATABASE)    
        .then (
            dispatch( { type: "SET_USER_STORAGE_USED", 
                user_uid:user_uid, 
                value:storage_used,
            }),
        )
    }
}


export const set_experimental_mode = (user_uid, mode) => {
    // console.log('AC set_experimental_mode',  user_uid)

    return dispatch => {
        let db_path = Constants.DB_USER_DATA+'/'+user_uid+'/experimental_mode/';

        actions_database.set_db_data(db_path, mode, window.SKIP_DATABASE)    
        .then (
            dispatch({ type: 'SET_EXPERIMENTAL_MODE', mode: mode })
            )
        
    }


}


export const apply_for_beta = (user_id, email, data) => {
    // console.log('AC apply_for_beta', user_id, email, data);
  
    if (!email){
      return( { 
        type: "SET_AUTH_UI_MESSAGE", 
        message:'No email entered!',
      })
    }
    
  
    return dispatch => {
      // let uid = 'newProject_'+new_uid();
      let db_path = Constants.DB_USER_DATA+'/'+user_id+'/beta/';
      let beta_data = data
  
      let date = new Date().toUTCString();
      beta_data['application_date'] = 'xxxx'
      beta_data['application_approved'] = false
  
    //   console.log('beta_data', beta_data)
      // database.ref(db_path).set(card_data)
      actions_database.set_db_data(db_path, beta_data, window.SKIP_DATABASE)    
      .then (
          dispatch( { type: "SET_BETA_DATA", 
              user_uid:user_id, 
              user_data:beta_data,
          }),
      )
    }
  
  };