/*

NOTE: This file was copied from an old backup. I don't know much about it. 
Have commented out router stuff that doesnt work any more

*/
import React, { Component } from 'react';
// import {Redirect} from 'react-router-dom';
import { Route, BrowserRouter as Router, Switch, Link, } from 'react-router-dom';

import './LoginPage.css';
import { connect} from 'react-redux';

// import firebase from "firebase";
import firebase from "firebase/compat/app";

import InputField from '../components/ui_elements/inputs/InputField';
// import List from '../components/ui_elements/inputs/List';
import Button from '../components/ui_elements/inputs/Button';
import SelectMenu from '../components/ui_elements/inputs/SelectMenu';


import * as actionCreator_ui from '../store/actions/actions_ui';
import * as actionCreator_auth from '../store/actions/actions_auth';
import * as actionCreator_users from '../store/actions/actions_users';
import bg_image from '../images/landing_page_bg_01.jpg';


class BetaFormPage extends Component {

      
    constructor(props) {
        super(props);
        this.handleClickApply = this.handleClickApply.bind(this);
        this.handleClickBack = this.handleClickBack.bind(this);
        // this.state={redirect:null}
    }

    handleChange = e => {
        console.log('BetaFormPage handleChange e.target.value', e.target.value)
        console.log('BetaFormPage handleChange e.target.id', e.target.id)
        this.setState({
         [e.target.id]:e.target.value
       })}

    handleClickApply(event) {
        this.props.apply_for_beta(this.state.email, this.state)
        // console.log('BetaFormPage handleClickApply state', this.state)

    }
    handleClickBack(event) {
        // this.setState({redirect:'home'})
    }

    render() {

        // if (this.state.redirect === 'home'){
        //     return (
        //         <Redirect to="/" />
        //     )
        // }

        let cur_user = firebase.auth().currentUser;
        console.log('LoginPage -cur_user', cur_user)
    
        // if (cur_user){
        //     return (
        //         <Redirect to="/app" />
        //     )
        // }

        // if (this.state.redirect === 'login'){
        //     return (
        //         <Redirect to="/login" />
        //     )
        // }

        let auth_ui_message = this.props.ui_data.auth_ui_message || null;


        let auth_message_element = null;
        if (auth_ui_message) {
            auth_message_element = <p style={{color:"red"}}> {auth_ui_message} </p>;
        }

  
        let profession_list = [
            '',
            'author',
            'screenwriter',
            'journalist',
            'student',
            'hobbyist',
            'other...',
        ]
        return (
            <div className="LoginPageDiv">
                <img className="LoginPageImg" src={bg_image} alt="logo" />

                <div className='LoginTitle'>StoryCraft.</div>

                <form  className="LoginPageForm">
                    <div className='LoginHeading'>Apply for Beta Access</div>

                    {auth_message_element}

                    <InputField 
                        inputType='text' 
                        // style={{display: "block"}} 
                        id='firstName' 
                        label='* first name'
                        do_set_db_data='false'
                        onChangeFunction={this.handleChange}
                        inputValue={this.state.firstName}
                    />
                    <InputField 
                        inputType='text' 
                        // style={{display: "block"}} 
                        id='lastName' 
                        label='* last name'
                        do_set_db_data='false'
                        onChangeFunction={this.handleChange}
                        inputValue={this.state.lastName}
                    />
                    <InputField 
                        inputType='email' 
                        // style={{display: "block"}} 
                        id='email' 
                        label='* email' 
                        do_set_db_data='false'
                        onChangeFunction={this.handleChange}
                        inputValue={this.state.email}
                    />
                    <SelectMenu
                        item_list={['', 'Male', 'Female', 'Other']}
                        label='Gender'
                        // style={{display: "block"}} 
                        id='gender' 
                        do_set_db_data='false'
                        onChangeFunction={this.handleChange}
                        inputValue={this.state.gender}
                    />
                    <InputField
                        inputType='date' 
                        // style={{display: "block"}} 
                        id='dateOfBirth' 
                        label='dateOfBirth' 
                        do_set_db_data='false'
                        onChangeFunction={this.handleChange}
                        inputValue={this.state.dateOfBirth}
                    />

                    <SelectMenu  
                        item_list={profession_list}
                        // dataSource={"ui_data.writing_panel_settings.show_item_type"} 
                        // dataTarget={"ui_data.writing_panel_settings.show_item_type"} 
                        label='profession'
                        id='professionSelect'
                        label_color='white'
                        onChangeFunction={this.handleChange}
                    />
                                      
                    {this.state.professionSelect === 'other...' &&
                        <InputField 
                            inputType='text' 
                            // style={{display: "block"}} 
                            id='otherProfession' 
                            label='other'
                            do_set_db_data='false'
                            onChangeFunction={this.handleChange}
                            value={this.state.profession}
                        />

                    }


                    <InputField 
                        inputType='textArea' 
                        style={{display: "block",}} 
                        id='howDidYouHearAbout' 
                        label='how did you hear about StoryCraft?'
                        do_set_db_data='false'
                        onChangeFunction={this.handleChange}
                        InputValue={this.state.howDidYouHearAbout}
                    /> 


                    <Button 
                        onClickFunction={this.handleClickApply} 
                        label='Apply'   
                        style={{width:"120px", bottom:"20px", right:"20px", position:"absolute"}}
                    />
                    <Button 
                        onClickFunction={this.handleClickBack} 
                        label='Back' 
                        style={{width:"120px", bottom:"20px", left:"20px", position:"absolute"}}
                    />

    

                </form>
            </div>
        )
    };

};


const mapStateToProps = (state, props) => {
    return {
        // modal_input_data:state.ui_data.openModalWindow[props.modalName],
        // modal_data:state.ui_data.modal_data,
        ui_data:state.ui_data,
        // context_data:state.context_data,

        // character_data:state.character_data,
        // card_data:state.card_data,
        // prop_data:state.prop_data,
        // location_data:state.location_data,

        // picked_items:state.ui_data.control.picked_items,
        // project_data:state.project_data,


    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        apply_for_beta: (email, data) => dispatch(actionCreator_users.apply_for_beta(email, data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps )(BetaFormPage);

