import React, { Component } from 'react';
import './WritingPanel.css';
import { connect} from 'react-redux';
// import IndexCard from '../ui_elements/objects/card/IndexCard';
// import IndexCard from '../ui_elements/objects/card/IndexCard';
import WritingCard from '../ui_elements/objects/card/WritingCard';
import '../ui_elements/objects/card/WritingCard.css';

import ThreadPicker from '../ui_elements/objects/thread_picker/ThreadPicker';
import '../ui_elements/inputs/InputField.css';
import StoryItem from '../ui_elements/objects/story_item/StoryItem';
import StoryItemEmpty from '../ui_elements/objects/story_item/StoryItemEmpty';
import InputField from '../ui_elements/inputs/InputField';
import TextEditor from '../ui_elements/inputs/TextEditor';
import QuillComponent from '../ui_elements/quill/QuillComponent';

import * as Constants from '../../constants/constants';

// import * as actionCreator_storyItem from '../../store/actions/actions_story_items';
import * as actionCreator_documents from '../../store/actions/actions_documents';
import * as actionCreator_ui from '../../store/actions/actions_ui';

import get_cards_by_thread from '../../utils/get_cards_by_thread';
// import get_cards_by_thread_by_parent from '../../utils/get_cards_by_thread_by_parent';
import * as actions_database from '../../store/actions/actions_database';

import * as get_cards_by_thread_by_parent from '../../utils/get_cards_by_thread_by_parent';





class WritingPanel extends Component {
    constructor(props) {
        super(props);
        this.handleNewDoc = this.handleNewDoc.bind(this);
        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.recently_loaded = [];
        this.column_width = 165;
    }

    handleFilterClick() {
        // console.log('handleFilterClick')


        let panel_name = 'writing_filter'
        let element_id = 'writingFilterOptions'
        // let element_id = 'BannerLabel'
        this.props.open_foldout_panel(panel_name, element_id);

        // this.props.load_project_data_from_db(['newProject_03718501195347166'], 'test4/project_data')
        // this.props.crappy_test()

        // this.props.testButtonDB();
    }

    handleNewDoc(event) {

        // let cur_sel = this.props.ui_data.control.selected

        // let cur_sel_item_id = null;
        // let cur_sel_item_type = null;
        if (this.props.ui_data.control.selected){
            let cur_sel_item_id= Object.keys(this.props.ui_data.control.selected)[0]
            let item_type = this.props.ui_data.control.selected[cur_sel_item_id]['item_type']
            // if (cur_item_id){
            //     cur_item_type= this.props.ui_data.control.selected[cur_item_id].item_type;
            //     sel_item_data = this.props[cur_item_type + '_data'][cur_item_id];
            // }
            let item_data= this.props[item_type+'_data'][cur_sel_item_id]
            this.props.create_and_link_document(item_data, item_type, cur_sel_item_id, this.props.ui_data.current.current_project);

        }


    }

    // populateAllCards() {
    //     // CAN LATER USE THE FLOW VP TO SCOPE WHICH CARDS ARE POPULATED

    //     // blocked this - need to add card links to project first!!
    //     // let card_arr = this.props.project_data[this.props.ui_data.current.current_project].links.card;
    //     let project_id = this.props.ui_data.current.current_project;
    //     let project_object = this.props.project_data[project_id];
    //     let project_cards = [];
    //     if (project_object.hasOwnProperty('links')){
    //         if(project_object.links.hasOwnProperty('card')){
    //             project_cards = project_object.links.card;
    //         } 
    //     }

    //     // hack to test thread filter
    //     let cur_thread_id = this.props.ui_data.current.current_thread_colour;
    //     let current_vp = this.props.ui_data.current.current_viewport;

    //     // project_cards = get_cards_by_thread(cur_thread_id, this.props.thread_data, this.props.card_data)
    //     project_cards = get_cards_by_thread_by_parent.get_cards_by_thread_by_parent(cur_thread_id, current_vp[1], this.props.thread_data, this.props.card_data)
    //     console.log('\n get_cards_by_thread_by_parent project_cards!!! ', project_cards)



    //     let test_data = get_cards_by_thread_by_parent.recursive_build_card_tree_data(cur_thread_id, current_vp[1], this.props.thread_data, this.props.card_data, {})
    //     // console.log('\n recursive_build_card_tree_data test_data!!! ', test_data)
    //     // console.log('test_data.ownPropertyKeys()', test_data.keys() )

    //     let cards_to_add = [];
    //     // var result = Object.keys(this.props.card_data).map((card) => {
    //     for (let i = 0; i < project_cards.length; i++) {

    //         let card_list = project_cards[i];
    //         for (let ii = 0; ii < card_list.length; ii++) {

    //             let card = card_list[ii];

    //             let card_expanded_state = false;
    //             if (this.props.ui_data.tree_view_data.hasOwnProperty('writing_panel')){
    //                 if (this.props.ui_data.tree_view_data['writing_panel']['expanded'].hasOwnProperty(card)){
    //                     card_expanded_state = this.props.ui_data.tree_view_data['writing_panel']['expanded'][card]
    //                 }
    //             }

    //             cards_to_add.push(
    //                 //<div key={card} className='cardDiv' style={{position:'reative'}}>
    
    //                     <WritingCard 
    //                         key={card}
    //                         text='' 
    //                         title={this.props.card_data[card]['title']} 
    //                         synopsis={this.props.card_data[card]['synopsis']} 
    //                         item_id={card}
    //                         item_type='card'
    //                         child_count={2}
    //                         tree_name='writing_panel'
    //                         card_expanded_state ={card_expanded_state}
    //                     />
    //                 //</div>
    //             )
    //         }
    //         // insert break object unless its following the last chain
    //         if (i !== project_cards.length-1){
    //             // console.log('this.props.thread_data', this.props.thread_data)
    //             // console.log('cur_thread_id ',cur_thread_id)
    //             // console.log('cur_thread_id sssda',this.props.thread_data[cur_thread_id])
    //             let thread_col = null;
    //             if ('color' in this.props.thread_data[cur_thread_id]){
    //                 thread_col = this.props.thread_data[cur_thread_id]['color'];
    //             }
                
    //             cards_to_add.push(
    //                 <div className='card_break' style={{borderColor:thread_col}}/>
    //             )
    //         }

    //     }
                
    //     return cards_to_add;
    // }



 

    recursive_build_tree_cards(card_tree_data, card_parent, tree_view_data, card_data, depth, only_with_docs=true){

        let cards_to_add = [];
        let project_cards = card_tree_data[card_parent]
        // console.log('\n recursive_build_tree_cards card_tree_data', card_tree_data)
        
        // can get 
        // depth ++;
        for (let i = 0; i < project_cards.length; i++) {
            
            for (let ii = 0; ii < project_cards[i].length; ii++) {
                let card = project_cards[i][ii];

                let card_children_threads = card_tree_data['card_'+card];
                let child_card_count = 0;
                for (let i = 0; i < card_children_threads.length; i++) {
                    for (let ii = 0; ii < card_children_threads[i].length; ii++) {
                        child_card_count ++;
                    }
                }

                if (card_data[card].hasOwnProperty('item_parent')){
    
                    // console.log('\n recursive_build_tree_cards card', card)

                    let card_expanded_state = false;
                    if (tree_view_data.hasOwnProperty('writing_panel')){
                        if (tree_view_data['writing_panel']['expanded'].hasOwnProperty(card)){
                            card_expanded_state = tree_view_data['writing_panel']['expanded'][card]
                        }
                    }
    
                    if (only_with_docs){
                        let do_skip = true;
                        if (card_data[card].hasOwnProperty('links')){
                            if (card_data[card]['links'].hasOwnProperty('document')){
                                do_skip=false;
                            }
                        }
                        if (do_skip){
                            console.log('skipping this. no doc found', card)
                            continue;
                        } 
                    }

                    let child_cards_ret = this.recursive_build_tree_cards(card_tree_data, 'card_'+card, tree_view_data, card_data, depth+1, only_with_docs);
                    if (child_cards_ret[0].length && card_expanded_state){
                        depth = child_cards_ret[1]
                    }
                    cards_to_add.push(
                        <WritingCard 
                            key={card}
                            text='' 
                            title={card_data[card]['title']} 
                            synopsis={card_data[card]['synopsis']} 
                            item_id={card}
                            item_type='card'
                            child_count={child_card_count}
                            tree_name='writing_panel'
                            card_expanded_state ={card_expanded_state}
                            // child_cards = {this.recursive_build_tree_cards(card_tree_data, 'card_'+card, tree_view_data, card_data, depth, only_with_docs)}
                            child_cards = {child_cards_ret[0]}
                        />
                    )
                }
            
            }
            // insert break object unless its following the last chain
            if (i !== project_cards.length-1){
                // console.log('this.props.thread_data', this.props.thread_data)
                // console.log('cur_thread_id ',cur_thread_id)
                // console.log('cur_thread_id sssda',this.props.thread_data[cur_thread_id])
                let cur_thread_id = this.props.ui_data.current.current_thread_colour;

                let thread_col = null;
                if ('color' in this.props.thread_data[cur_thread_id]){
                    thread_col = this.props.thread_data[cur_thread_id]['color'];
                }
                
                cards_to_add.push(
                    <div key={i} className='card_break' style={{borderColor:thread_col}}/>
                )
            }
            else {
                cards_to_add.push(
                    <div key={i} className='tree_view_spacer'/>
                )
                
            }
        }
        
        return [cards_to_add, depth];
    }
        

    populateAllCards_B() {


        let show_item_type = this.props.ui_data.writing_panel_settings.show_item_type;

        if (show_item_type != 'card'){
            return [];
        }


        // hack to test thread filter
        let cur_thread_id = this.props.ui_data.current.current_thread_colour;
        // let current_vp = this.props.ui_data.current.current_viewport;
        let tree_view_data = this.props.ui_data.tree_view_data;

        let cur_proj = this.props.ui_data.current.current_project;

        // THis is a bit temp-y. Changing logic based on if in experimental mode
        let experimental_mode = this.props.ui_data.settings.experimental_mode
        if (experimental_mode){
            if (this.props.ui_data.writing_panel_settings.show_all_cards){
                cur_thread_id = null;
            }
        }
        else{
            if (!this.props.ui_data.writing_panel_settings.hide_unconnected_cards){
                cur_thread_id = null;
            }
        }



        let card_tree_data = get_cards_by_thread_by_parent.recursive_build_card_tree_data(cur_thread_id, 'project_'+cur_proj, this.props.thread_data, this.props.card_data, {})
        // console.log('\n\npopulateAllCards_B recursive_build_card_tree_data card_tree_data!!! ', card_tree_data)
        // console.log('test_data.ownPropertyKeys()', test_data.keys() )

        let only_with_docs = false;
        if (this.props.ui_data.writing_panel_settings.hasOwnProperty('only_show_with_doc')){
            if (this.props.ui_data.writing_panel_settings.only_show_with_doc){
                only_with_docs=true;
            }
        }
        
        let card_ret = this.recursive_build_tree_cards(card_tree_data, 'project_'+cur_proj, tree_view_data, this.props.card_data, 0, only_with_docs)
        let depth = card_ret[1]
        // console.log('populateAllCards_B card_ret', card_ret);
        // console.log('populateAllCards_B depth', depth);

        this.column_width = 165 + (14 * depth) + 'px';

        return card_ret[0];

    }


    populateStoryItems() {

        // let cur_proj = this.props.ui_data.current.current_project;

        // if (this.props.ui_data.writing_panel_settings.show_all_cards){
        //     cur_thread_id = null;
        // }
        // let show_item_type = this.props.ui_data.writing_panel_settings.show_item_type;
        let item_type = this.props.ui_data.writing_panel_settings.show_item_type;
        let item_data = null;
        // let item_type = null;
        if (item_type === 'card'){
            return [];
        }

        let only_with_docs = false;
        if (this.props.ui_data.writing_panel_settings.hasOwnProperty('only_show_with_doc')){
            if (this.props.ui_data.writing_panel_settings.only_show_with_doc){
                only_with_docs=true;
            }
        }

        if (item_type === 'character'){
            // item_type = 'character';
            item_data = this.props.character_data;
        }
        if (item_type === 'prop'){
            // item_type = 'prop';
            item_data = this.props.prop_data;
        }
        if (item_type === 'location'){
            // item_type = 'location';
            item_data = this.props.location_data;
        }
        if (item_type === 'event'){
            // item_type = 'event';
            item_data = this.props.event_data;
        }
        if (item_type === 'document'){
            // item_type = 'document';
            item_data = this.props.document_data;
        }

        let all_characters_to_add =[];
        for (let index = 0; index < Object.keys(item_data).length; index++) {
            const item_id = Object.keys(item_data)[index];

            if (only_with_docs){
                let do_skip = true;
                if (item_data[item_id].hasOwnProperty('links')){
                    if (item_data[item_id]['links'].hasOwnProperty('document')){
                        do_skip=false;
                    }
                }
                if (do_skip){
                    console.log('skipping this. no doc found', item_id)
                    continue;
                } 
            }
            
            all_characters_to_add.push(
                <StoryItem 
                    key={item_id}
                    itemSize='medium'
                    item_type={item_type}
                    item_id={item_id}
                    // mode={this.props.mode}

                />
            )
           
        }
        return all_characters_to_add;
    }
    


    document_element() {
        let documents = this.props.current_document
        let my_textB = "document_data." + this.props.current_document +".text"
        let cur_item_id = null;
        let cur_item_type = null;
        // reworked the bolow to take new selected data
        if (this.props.ui_data.control.selected){
            cur_item_id= Object.keys(this.props.ui_data.control.selected)[0]
            if (cur_item_id){
                cur_item_type= this.props.ui_data.control.selected[cur_item_id].item_type;
            }
        }
        if (!cur_item_id){
            return(
                <div 
                    //onClick={this.handleNewDoc}
                    className='NoDocument'
                >
                    Nothing Selected
                </div>
            )
        }
        if (!documents) {
            // if (cur_item_type === 'card') {
                return(
                    <div 
                        onClick={this.handleNewDoc}
                        className='EmptyDocument'
                    >
                        Create New Document
                    </div>
                )
            // }
            // else{
            //     return(
            //         <div 
            //             //onClick={this.handleNewDoc}
            //             className='NoDocument'
            //         >
            //             No Document Selected
            //         </div>
            //     )
            // }
        }
        else {
            if (!this.recently_loaded.includes(this.props.current_document)){
                // console.log('recently_loaded', this.recently_loaded)
                if (!(this.props.current_document in this.props.document_data)){
                    this.recently_loaded.push(this.props.current_document);
                    this.props.load_project_document_from_db( [this.props.current_document]);
                } 
            }
            if (Constants.USE_QUILL_EDITOR){
                return (<QuillComponent dataTarget={my_textB}/>)
            }
            else{
                return (<TextEditor  dataTarget={my_textB} dataSource={my_textB}  />)
            }

        }
    }
    render() {
        //let my_text = this.props.document_data['uid1']['text']
        // let card_id_for_doc = current_document
        // let my_textB = "document_data." + this.props.current_document +".text"
        return (
            <div id="MyWritingPanel">
                {/* <textarea rows="4" cols="200" id="TextEd" defaultValue={my_text}/> */}
                {/* <TextEditor 
                    dataTarget={my_textB}
                    dataSource={my_textB}
                
                /> */}
                {/* <div style={{left:this.column_width}} className='DocumentDiv'> */}
                <div className='DocumentDiv'>
                    {this.document_element()}
                </div>
                <div id='writingFilterOptions'  className='filterOptions'onClick={this.handleFilterClick}>
                    view options...
                </div>
                <div style={{width:this.column_width}} className='CardsColumn'> 


                    {this.populateAllCards_B()}
                    {this.populateStoryItems()}
                </div>
            </div>
            
        );
    }
}


const mapStateToProps = (state) => {
    return {
        project_data:state.project_data,
        ui_data:state.ui_data,
        card_data:state.card_data,
        character_data:state.character_data,
        prop_data:state.prop_data,
        location_data:state.location_data,
        event_data:state.event_data,
        document_data:state.document_data,
        current_document:state.ui_data.current.current_document,
        // current_sel:state.ui_data.current_sel,
        thread_data:state.thread_data,
   }
}

const mapDispatchToProps = (dispatch) => {
    return {
        create_and_link_document: (item_data, item_type, item_id, project_id) => dispatch(actionCreator_documents.create_and_link_document(item_data, item_type, item_id, project_id)),
        load_project_document_from_db: (document_ids) => dispatch(actions_database.load_project_document_from_db(document_ids)),
        open_foldout_panel: (panel_name, element_id) => dispatch(actionCreator_ui.open_foldout_panel(panel_name, element_id)),



    }
};
export default connect(mapStateToProps, mapDispatchToProps )(WritingPanel);

