import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import FoldoutPanel from '../ui_elements/objects/foldout_panel/FoldoutPanel';
import guide_popup_template from '../../store/templates/guide_popup_template';


import * as actionCreator_inputs from '../../store/actions/actions_inputs';
import * as actionCreator_users from '../../store/actions/actions_users';
import * as actions_database from '../../store/actions/actions_database';


import './Guide_popups.css'

/*
INFO:
Guides will run in order they appear in the guide_popup_template
scoped by whatever screen you;re on
if the guide template has the key 'trigger', it will only show if that
'trigger guide' is present. (a trigger guide is made the same as any other guide
    using set_user_guide)
if the template has a 'blocking' key it means the guide after the one with the 
trigger won't open until the trigger one has been completed.
guide data can be added using set_user_guide, any data will be merged (assigned)
with the template data, and overwite that data where keys are common.
The trigger guide data will merge in second overriding both sets of data.



*/


export default function Guide_popups(props) {
    const dispatch =useDispatch()

    let user_id = props.user_id;
    // let project_id = props.user_id;
    let user = useSelector( state => state.user_data[user_id]);
    let ui_mode = useSelector( state => state.ui_data.settings.mode);
    let openModalWindow = useSelector( state => state.ui_data.openModalWindow);
    let foldout_panel = useSelector( state => state.ui_data.foldout_panel);
    let cur_project = useSelector( state => state.ui_data.current.current_project);
    let mode_panel_expanded = useSelector( state => state.ui_data.settings.mode_panel_expanded);


    let cur_screen = null;
    if (!cur_project) {cur_screen = 'projects'}
    else (cur_screen = ui_mode)

    // let project_id = useSelector( state => state.user_data[user_id])

    // console.log('guide_popups cur_screen', cur_screen)
    // console.log('guide_popups user_id', user_id)
    // console.log('guide_popups user', user)
    if (!user){
        return null;
    }
    // if (!user.hasOwnProperty('guides')){
    //     return null;
    // }
    if (user.hasOwnProperty('show_guides') && !user.show_guides){
        // console.log('guide_popups A', )

        return null;
    }
    for (let index = 0; index < Object.keys(openModalWindow).length; index++) {
        const element = Object.keys(openModalWindow)[index];
        if (openModalWindow[element]) {
            // console.log('guide_popups B',)

            return null;
        }
    }
    if (foldout_panel) {
        // console.log('guide_popups C',)
        return null;
    }
    


    let guide = null;
    let guide_to_display = null;
    let guide_data = null;
    // console.log('guide_popups guide_popup_template', guide_popup_template)

    if (guide_popup_template.hasOwnProperty(cur_screen)){
        for (let index = 0; index < guide_popup_template[cur_screen].length; index++) {

            let guide_name = guide_popup_template[cur_screen][index]['name'];

            // if (user.hasOwnProperty('guides')){
                // console.log('guide_popups XXXX', cur_screen)

                if (!user.hasOwnProperty('guides') || !user.guides.hasOwnProperty(guide_name) || user.guides[guide_name] !== true){
                    // if user has guides property, then see if it has data beyond true or false

                        guide = guide_name;
                        guide_data = guide_popup_template[cur_screen][index];
                        // console.log('guide_popups guide_data A ', guide_data)

                        // test if guide value is object, if so then merge its keys with guide_data
                        // NOTE if guide is using a trigger and trigger guide has data
                        // it will be applied last, possibly overrding propertie set here
                        if (user.hasOwnProperty('guides') && user.guides.hasOwnProperty(guide_name)){
                            if (typeof user.guides[guide_name] === 'object' && typeof user.guides[guide_name] !== null)
                            {
                                Object.assign(guide_data, user.guides[guide_name]);
                                // console.log('Guide_popups - user.guides[guide_name]', user.guides[guide_name])
                                // console.log('Guide_popups - custom guide data', guide_data)
                            }
                        } 

                        // test if current guide is looking for a trigger by lookign for trigger key
                        // eg. when i create a new card, i make a trigger guide entry. Then i can
                        // use the data for that for element id for foldout gui
                        if (guide_data && guide_data.hasOwnProperty('trigger')){
                            let trigger_guide = guide_data['trigger']

                            let blocking = false
                            if (guide_data.hasOwnProperty('blocking')){
                                blocking = guide_data['blocking']
                            }

                            // console.log('guide_popups guide_data AA ', guide_data)
                            if (user.hasOwnProperty('guides') && user.guides.hasOwnProperty(guide_data['trigger']))
                            {
                                // console.log('guide_popups guide_data trigger_guide ', trigger_guide)

                                // if trigger guide has data then merge it with guide data
                                if (typeof user.guides[trigger_guide] === 'object' && typeof user.guides[trigger_guide] !== null)
                                {
                                    Object.assign(guide_data, user.guides[trigger_guide]);
                                    // console.log('Guide_popups - user.guides[guide_name]', user.guides[guide_name])
                                    // console.log('Guide_popups - custom guide data', guide_data)
                                }
                            }
                            else{
                                // if trigger not found then continue in loop
                                if (blocking){
                                    guide_data=null 
                                    guide=null 
                                }
                                else{
                                    continue
                                }
                            }
                        }

                        // console.log('guide_popups guide_data B', guide_data)
                        break;
                }
            // }
        } 
    }

    function handleSubmit(e) {
        // let guide = 'getting_started';
        let value = true;
        dispatch(
            actionCreator_users.set_user_guide(user_id, guide, value)
        );
    }

     
    function newlineText(text) {
        return text.split('\n').map(str => <p>{str}</p>);
    }

    if (guide){

        guide_to_display = <FoldoutPanel 
            element_id={guide_data.element_id}
            panel_width={guide_data.panel_placement[0]} 
            panel_height={guide_data.panel_placement[1]} 
            panel_side={guide_data.panel_placement[2]} 
            panel_offset={guide_data.panel_placement[3]} 
            show_arrow={true}
            dark_bg={false}
            modal={false}
            // clickOutsideFunction={e => handleSubmit(e)}
            clickInsideFunction={handleSubmit}
            do_hover={true}
            dark_mode={true}
            panel_style={{borderRadius:'25px'}}
            test_update={mode_panel_expanded}
        >
            <div key={guide + '_div'} className='GuidePopupText'>   
                {newlineText(guide_data.text)}
                {/* <input type='button' onClick={e => handleSubmit(e)}/> */}
            </div>
        </FoldoutPanel>
    }
    return (

        <>
            {guide_to_display}
        </>
    )
}
