import React, { Component } from 'react';
import { connect} from 'react-redux';
import './WritingFilterOptions.css';

// import Checkbox from '../../inputs/Checkbox';
// import SelectMenu from '../../inputs/SelectMenu';
// import ThreadPickerItem from '../thread_picker/ThreadPickerItem'

import * as actionCreator_cards from '../../../../store/actions/actions_cards';
import * as actionCreator_time_cards from '../../../../store/actions/actions_time_cards';
import * as actionCreator_time_events from '../../../../store/actions/actions_time_events';
import * as actionCreator_regions from '../../../../store/actions/actions_regions';
// import * as actionCreator_viewports from '../../store/actions/actions_viewports';
import * as actionCreator_ui from '../../../../store/actions/actions_ui';



class AddItemsTimeline extends Component {
    constructor(props) {
        super(props);

        this.handleClickCreateCard = this.handleClickCreateCard.bind(this);
        this.handleClickCreateEvent = this.handleClickCreateEvent.bind(this);
    }


    handleClickCreateCard(event) {
        console.log('in handleClickCreateCard')
        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];
        // this.props.create_card_at_centre_of_vp(event, project_id, this.props.ui_data, this.props.viewport_data);
        this.props.create_card_at_current_time(event, project_id, this.props.ui_data, this.props.viewport_data, this.props.timeline_data);
        this.props.close_all_foldout_panels();

    }
    handleClickCreateEvent(event) {
        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];
        // this.props.create_region_at_centre_of_vp(event, project_id, this.props.ui_data, this.props.viewport_data);
        // alert('add event in centre of timleine')
        this.props.create_event_at_current_time(event, project_id, this.props.ui_data, this.props.timeline_data);
        this.props.close_all_foldout_panels();


        

    }


    render(){

        return (

            <div
                className='AddItemsFlowDiv'
            >
                <div 
                    className='FoldoutListItem'
                    // style={{'backgroundColor': 'rgb(238, 225, 169)',}}
                    onClick={this.handleClickCreateCard}> 
                    Add New Card
                </div>
                <div 
                    className='FoldoutListItem'
                    // style={{'backgroundColor': 'rgb(100, 190, 225)'}}
                    onClick={this.handleClickCreateEvent}> 
                    Add New Event
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    let current_vp = state.ui_data.current.current_viewport;
    let current_tl = state.ui_data.current.current_timeline;

    return {
      ui_data:state.ui_data,
      viewport_data:state[current_vp[0]+'_data'],
      timeline_data:state.timeline_data,
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        // open_user_ui: () => dispatch(actionCreator_ui.open_user_ui()),
        // create_card_at_centre_of_vp: (event, project_id, ui_data, viewport_data) => dispatch(actionCreator_cards.create_card_at_centre_of_vp(event, project_id, ui_data, viewport_data)),
        create_card_at_current_time: (event, project_id, ui_data, viewport_data, timeline_data) => dispatch(actionCreator_time_cards.create_card_at_current_time(event, project_id, ui_data, viewport_data, timeline_data)),
        create_event_at_current_time: (event, project_id, ui_data, timeline_data) => dispatch(actionCreator_time_events.create_event_at_current_time(event, project_id, ui_data, timeline_data)),
        close_all_foldout_panels: () => dispatch(actionCreator_ui.close_all_foldout_panels()),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItemsTimeline);
