
import * as utils from '../../utils/misc_utils'

const reducer_viewport = (state, newState, action) => {
    // const newState = {...state};
    if(action.type === 'SET_FLOW_VIEWPORT_DATA'){
        console.log('RD SET_FLOW_VIEWPORT_DATA', action)
        newState.flow_viewport_data = {...state.flow_viewport_data};
        newState.flow_viewport_data[action.uid] = action.data;
    }

    if(action.type === 'MOUSE_DOWN_VP'){
        // //AAA console.log('RD MOUSE_DOWN_VP', action, )

        newState.ui_data.current.current_viewport_b = {...state.ui_data.current.current_viewport_b}
        newState.ui_data.current = {...state.ui_data.current}
        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data = {...state.ui_data}

        newState.ui_data.control.local_down_pos = action.local_down_pos;
        newState.ui_data.control.local_current_pos = action.local_current_pos;
        newState.ui_data.control.local_drag_offset = action.local_drag_offset;
        newState.ui_data.control.local_orig_pos = action.local_orig_pos;


        newState.ui_data.current.current_viewport_b['pos'] = action.vp_pos;
        newState.ui_data.current.current_viewport_b['scale'] = action.vp_scale;



    }


    if(action.type === 'SET_VP_POS'){
        // //AAA console.log('RD SET_VP_POS', action)

        newState[action.vp_type +'_data'][action.vp_id] = {...state[action.vp_type +'_data'][action.vp_id]};
        newState[action.vp_type +'_data'] = {...state[action.vp_type +'_data']};

        newState[action.vp_type +'_data'][action.vp_id]['pos'] = action.pos;
    }

    if(action.type === 'SET_LOCAL_VALS'){
        // //AAA console.log('RD SET_LOCAL_VALS', action)

        newState.ui_data.current = {...state.ui_data.current};
        newState.ui_data = {...state.ui_data};

        newState.ui_data.control.local_current_pos = action.local_current_pos
        newState.ui_data.control.local_drag_offset = action.local_drag_offset
    }
    

    
    if(action.type === 'SET_ZOOM_VP'){
        // //AAA console.log("RD SET_ZOOM_VP", action);

        newState[action.vp_type +'_data'][action.vp_id] = {...state[action.vp_type +'_data'][action.vp_id]};
        newState[action.vp_type +'_data'] = {...state[action.vp_type +'_data']};
        
        newState[action.vp_type +'_data'][action.vp_id]['scale'] = action.scale;
        newState[action.vp_type +'_data'][action.vp_id]['pos'] = action.pos;

    }

    if(action.type === 'SET_CURRENT_VP'){
        // action.event.stopPropagation();
        // console.log('RD SET_CURRENT_VP', action)


        newState.ui_data.open_viewport = {...state.ui_data.open_viewport};
        newState.ui_data.current = {...state.ui_data.current};
        newState.ui_data = {...state.ui_data};

        // set current vp and open vp (open is to store which was open when switching between modes)
        newState.ui_data.open_viewport[action.vp_type] = action.vp_id;
        newState.ui_data.current.current_viewport = [action.vp_type,  action.vp_id];

        let pos = [0,0];
        let scale = 1;
        // test if pos exists yet. I think when loading project it may not yet
        if (state[action.vp_type + '_data'][action.vp_id])
        {
            pos = [
                state[action.vp_type + '_data'][action.vp_id]['pos'][0],
                state[action.vp_type + '_data'][action.vp_id]['pos'][1],
            ]
            scale = state[action.vp_type + '_data'][action.vp_id]['scale']
        }

        // TODO dont want this after all. Clean this up
        newState.ui_data.current.current_viewport_b = {
            type:action.vp_type,  
            id:action.vp_id,
            pos:pos,
            scale:scale,
        }

    }



    if(action.type === 'MAKE_NEW_VP'){
        // action.event.stopPropagation();
        //AAA console.log('RD MAKE_NEW_VP', action)


        newState['flow_viewport_data'] = {...state['flow_viewport_data']}

        // copy data from template
        // let new_vp_data = {...state['flow_viewport_data']['__template__']};
        // newState['flow_viewport_data'][action.card_id] = new_vp_data;
        newState['flow_viewport_data'][action.card_id] = action.data;


    }

    if(action.type === 'CREATE_NEW_FLOW_VP'){
        // action.event.stopPropagation();
        console.log('RD CREATE_NEW_FLOW_VP', action)


        newState['flow_viewport_data'] = {...state['flow_viewport_data']}

        // copy data from template
        // let new_vp_data = {...state['flow_viewport_data']['__template__']};
        // newState['flow_viewport_data'][action.card_id] = new_vp_data;
        newState['flow_viewport_data'][action.uid] = action.data;


    }



    // WILL ADD VIEWPORT XFORM STUFF HERE (may want to split into new reducer) 

    if(action.type === 'SET_DRAGGING_B'){
        // console.log('RD SET_DRAGGING_B', action, )

        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data = {...state.ui_data}

        // newState.ui_data.control.dragging_b = action.data;


        // Going to add some more logic to store initialp pos and size.
        // doing it in reducer because i have access to state. I don't want to have to pass 
        // such full on args into my actions just for this

        let data_to_set = {};
        if (action.data){
            Object.keys(action.data).map((item_id) => {
                // get pos
                let item = null;
                let item_type = action.data[item_id].item_type;

                if (item_type.endsWith('_scale')){
                    let item_type_stripped = item_type.slice(0, -6);
                    item = state[item_type_stripped+'_data'][item_id];
                }
                else if (item_type.endsWith('_key')){
                    let item_type_stripped = item_type.slice(0, -4);
                    let item_id_actual = action.data[item_id]['custom_data']['item_id']
                    item = state[item_type_stripped+'_data'][item_id_actual];

                    data_to_set[item_id] = {
                        custom:action.data[item_id]['custom_data'],
                    }

                }
                else{
                    item = state[item_type+'_data'][item_id];
                }

                if (item_type === 'flow_viewport'){

                    // item = state[item_type+'_data'][item_id];
                    data_to_set[item_id] = {
                        item_type:item_type, 
                        custom_data:{
                            mouse_down_pos:[item.pos[0], item.pos[1]],
                            mouse_down_scale:item.scale,
                        },
                    }
                }
                if (item_type === 'card' || item_type === 'region' || item_type === 'card_scale' || item_type === 'region_scale'){
                    // THI IS A CRAP HACK - should put a better way to itendifiy when doing a scale
                    // maybe an extra key lick 'handle':'scale_bottomRight'
                    

                    data_to_set[item_id] = {
                        item_type:item_type, 
                        custom_data:{
                            mouse_down_pos:[item.pos[0], item.pos[1]],
                            mouse_down_size:[item.size[0], item.size[1]],
                        },
                    }
                }
                if (item_type === 'location'){
                    // THI IS A CRAP HACK - should put a better way to itendifiy when doing a scale
                    // maybe an extra key lick 'handle':'scale_bottomRight'

                    data_to_set[item_id] = {
                        item_type:item_type, 
                        custom_data:{
                            mouse_down_pos:[item.pos[0], item.pos[1]],
                        },
                    }
                }
                if (item_type === 'location'){
                    // THI IS A CRAP HACK - should put a better way to itendifiy when doing a scale
                    // maybe an extra key lick 'handle':'scale_bottomRight'

                    data_to_set[item_id] = {
                        item_type:item_type, 
                        custom_data:{
                            mouse_down_pos:[item.pos[0], item.pos[1]],
                        },
                    }
                }

            })
        }
        else{
            data_to_set = null;
        }
        newState.ui_data.control.dragging_b = data_to_set;


    }
    if(action.type === 'SET_CLICKED'){
        // console.log('RD SET_CLICKED', action, )
        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data = {...state.ui_data}

        newState.ui_data.control.clicked = action.data;

    }

    if(action.type === 'SET_VP_XFORM_POS'){
        // console.log('RD SET_VP_XFORM_POS', action)

        newState[action.item_type+'_data'][action.item_id] = {...state[action.item_type+'_data'][action.item_id]};
        newState[action.item_type+'_data'] = {...state[action.item_type+'_data']};
        
        newState[action.item_type+'_data'][action.item_id]['pos'] = action.pos;

    }
    if(action.type === 'SET_VP_XFORM_SIZE'){
        // console.log('RD SET_VP_XFORM_SIZE', action)

        newState[action.item_type+'_data'][action.item_id] = {...state[action.item_type+'_data'][action.item_id]};
        newState[action.item_type+'_data'] = {...state[action.item_type+'_data']};
        
        newState[action.item_type+'_data'][action.item_id]['size'] = action.size;

    }


    if(action.type === 'SET_SEL_BBOX'){
        // console.log('RD SET_SEL_BBOX', action)

        newState.ui_data.control = {...state.ui_data.control};
        newState.ui_data = {...state.ui_data};
        newState.ui_data.control.select_bbox = action.bbox;

    }

    return newState;

};
export default reducer_viewport;