const get_time_from_timeslider = (mouse_pos, ui_data, tl_data, rounding=true, force_min_max=true) => {
    /*
    Simple function to get mouse position in viewport co-ords.
    Probably a smarter way of ding this -eg pass in event and panel div id or something?
    ATM this requires a lot of input.
    */

    // let mouseCurPos = ui_data.control.mouse_current_pos;
    let main_panel_left = ui_data.layout.main_panel_left;
    let main_panel_right = ui_data.layout.main_panel_right;
    let time_control_margin = ui_data.layout.time_control_margin;

    let start_time = tl_data['start_time'];
    let end_time = tl_data['end_time'];
    let time_duration = end_time - start_time;

    // get percentage of distance along timeline
    //first get dist from start of timeline
    let sel_dist =  mouse_pos[0] - time_control_margin - main_panel_left;

    // get timeline tl_width
    let tl_width = main_panel_right - main_panel_left - time_control_margin - time_control_margin;

    let ratio = (sel_dist / tl_width);

    let sel_time = start_time + (time_duration * ratio)
    if (force_min_max){
        sel_time = Math.min(100, Math.max(0, sel_time));
    }
    if (rounding){
        sel_time = Math.round(sel_time) ;
    }
    
    return sel_time;

}
export default get_time_from_timeslider;
