
const initial_ui  ={
    ui_data:{

        // current_user:null,
        // ui layout
        layout:{
            is_loading: true,
            is_uploading: false,

            banner_height:40,
            main_panel_left:128,
            main_panel_top:40,
            // more info about main_panel to use as convenience (these are solved in ui reducer)
            main_panel_right:500,
            main_panel_bottom:500,
            // main_panel_dimensions:[100, 100],
    
            attribute_panel_width:300,
            attribute_panel_expanded_width:300,
            attribute_panel_width_mouse_down:300, 

            // timeline
            time_control_margin:50,
            timeline_row_height:64,

            // window size
            window_dimensions:[100, 100],
        },
        settings:{

            // ui modes
            mode: "flow",
            attribute_panel_expanded:true,
            mode_panel_expanded:true,
            show_time_controls:false, 
            show_testing_panel:false, 
            experimental_mode:false,
        },

        control:{
            // Mouse events, coordinates are based on the whole app window
            mouse_down_pos:[0,0],
            mouse_current_pos:[0,0],
            mouse_drag_offset:[0,0],

            // these coordinates change depending on the context. usually for things like viewports
            local_down_pos:[0,0],
            local_current_pos:[0,0],
            local_drag_offset:[0,0],
            local_orig_pos:[0,0],
            local_orig_start_end_time:[0,0],

            local_timeline_row:0,

            dragging:[null, null],
            hovering:[null, null],
            begin_drag:false, // this is true once we have moved the mouse past a given threashold
    
            dragging_b:null,
    
            selected: {},
            selected_attrs: {},
            selected_keys: {},
            select_bbox:null,
            // modifier_key:{},
            set_hotkey_signal:null,
            focus:null,
            clicked:{},
            picked_items:[],

        },
        // these are used to see what is being dragged, and what we're hovering over
        // dragging:[null, null],
        // hovering:[null, null],
        // begin_drag:false, // this is true once we have moved the mouse past a given threashold

        // dragging_b:null,

        // selected:null,
        // select_bbox:null,
        // modifier_key:{},
        // clicked:{},
        // picked_items:[],


        current:{
            current_project:null,
            current_document:null,
            current_viewport:['flow_viewport', 'topLevel'],
            current_viewport_b:{},

            // this is blocked in for now, may need to go to this so i can store current for each mode (so if i switch modes the vp doesn't reset)
            current_flow_viewport:'topLevel',
            current_map_viewport:'topLevel',
    
            // current_timeline: 'story_percentage',
            current_timeline: '_time_progress',
            current_thread_colour: 'col_uid1',
        },

 




        // selections and currents
        // current_sel:[null, null],


        // not sure if this is the best way to do this. might be better to use selections, but as arrays. for the moment this will do
        // am i better with sel history or attr_panel_history?
        sel_history:[],
        // sel_history_b:[],
        sel_history_index:0,


        open_viewport:{
            flow_viewport:'topLevel',
            map_viewport:'topLevel',
        },



        // current_time: '0',
        
        // Model windows
        openModalWindow:{},
        modal_data:{},

        //User panel
        open_user_panel:false,
        
        //Floating Panels
        foldout_panel:null,


        writing_panel_settings:{
            show_all_cards:false,
            hide_unconnected_cards:false,
            show_item_type:'card',
        },

        // tree controls
        tree_view_data:{},
        // cache_data:{},

    }
}


export default initial_ui