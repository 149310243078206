/*
*/

// import { databaseRef } from "../../config/firebase";
import { database } from "../../config/firebase";
import * as Constants from '../../constants/constants';
import _ from 'lodash';


// not sure i need this yet... thinking is i'll use it to reset redux data to init.
// this is so projects dont interfere with each other  in session
import initial_ui from '../initial/initial_ui';
import initial_timelines from '../initial/initial_timelines';
import initial_flow_viewports from '../initial/initial_flow_viewports';
import initial_map_viewports from '../initial/initial_map_viewports';
import initial_characters from '../initial/initial_characters';
import initial_props from '../initial/initial_props';
import initial_locations from '../initial/initial_locations';
import initial_events from '../initial/initial_events';
import initial_documents from '../initial/initial_documents';
import initial_cards from '../initial/initial_cards';
import initial_threads from '../initial/initial_threads';
import initial_projects from '../initial/initial_projects';
import initial_users from '../initial/initial_users';
import initial_context from '../initial/initial_context';
import initial_regions from '../initial/initial_regions';
import initial_attributes from '../initial/initial_attributes';
import initial_groups from '../initial/initial_groups';

import * as utils from '../../utils/misc_utils'

// import * as firebase from "firebase";
// import firebase from 'firebase/app';
import firebase from "firebase/compat/app";

import 'firebase/auth';

// export const test_action = (event) => {
//   console.log('test_action', event);

//   let db_path = 'story_app_test/another/and/anotherr'
//   let db_data = {
//     something: true,
//     another: 45,
//     someName: 'matilda'
//   }
//   console.log('db', db_path, db_data);

//   database.ref(db_path).set(db_data)

//   return {
//     type: "X"
//   };
// };



// adding a skip arg here to to skip lookup into DB. This is working towards being able to swith 
// to a local mode for dev where i can just work with initial state but not read or write to DB
// NOTE: this may be dumb, as there is probably a better way of handling if online or off

var timeoutId;


// export function copy_current_state_to_db(data) {
//   // this is only useful for my testing. would be very bad to do in production!
//   return dispatch => {
//     let db_path = 'story_app_data'
//     set_db_data(db_path, data, false, 0)

//     dispatch({
//       type: "",
//     })
//   }
// }


export const set_db_data = (db_path, data, skip = false, delay = 0) => {
  // console.log('set_db_data', db_path, data, skip);
  if (skip) {
    var promise1 = new Promise(function (resolve, reject) {
      resolve('Success!');
    });
    return promise1;
  }
  else {

    // var timeoutId;
    if (delay) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(function () {
        // Runs 1 second (1000 ms) after the last change    
        // console.log('set_db_data in setTimeout!');
        return database.ref(db_path).set(data);
      }, delay)
      var promise1 = new Promise(function (resolve, reject) {
        resolve('Success!');
      });
      return promise1;
    }
    else {
      return database.ref(db_path).set(data);
    }
  }
};





// Delete DB entry
export const remove_db_data = (db_path, skip = false, delay = 0) => {
  // console.log('remove_db_data', db_path, skip);
  if (skip) {
    var promise1 = new Promise(function (resolve, reject) {
      resolve('Success!');
    });
    return promise1;
  }
  else {

    // var timeoutId;
    if (delay) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(function () {
        // Runs 1 second (1000 ms) after the last change    
        // console.log('remove_db_data in setTimeout!');
        return database.ref(db_path).remove();
      }, delay)
      var promise1 = new Promise(function (resolve, reject) {
        resolve('Success!');
      });
      return promise1;
    }
    else {
      return database.ref(db_path).remove();
    }
  }
};



// Push DB entry
export const push_db_data = (db_path, data, skip = false, delay = 0) => {
  // console.log('push_db_data', db_path, data, skip);
  if (skip) {
    var promise1 = new Promise(function (resolve, reject) {
      resolve('Success!');
    });
    return promise1;
  }
  else {

    // var timeoutId;
    if (delay) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(function () {
        // Runs 1 second (1000 ms) after the last change    
        // console.log('push_db_data in setTimeout!');
        return database.ref(db_path).push(data);
      }, delay)
      var promise1 = new Promise(function (resolve, reject) {
        resolve('Success!');
      });
      return promise1;
    }
    else {
      return database.ref(db_path).push(data);
    }
  }
};









export function load_projects_from_db(project_ids, db_path) {
  // console.log('AC load_projects_from_db - project_ids', project_ids)
  // let db_path = 'story_app_data/project_data/'
  // console.log('AC load_projects_from_db - db_path', db_path)

  return dispatch => {
    dispatch({ type: 'SET_LOADING', is_loading: true })
    // first get 
    for (let i = 0; i < project_ids.length; i++) {
      try {
        // console.log('here - ', db_path + '/' + project_ids[i])
        database.ref(db_path + '/' + project_ids[i]).once('value', function (snap) {
          let snap_val = snap.val();
          if (!snap_val) {
            console.log('SOMETHING HAS GONE WRONG HERE! NO DATA FOUND FOR THIS PROJ', project_ids[i]);
          }
          else{
            if (!snap_val.hasOwnProperty('links')) {
              snap_val.links = {};
            }
            // don't know if i need this?
            if (!snap_val.hasOwnProperty('thread')) {
              snap_val.thread = {};
            } 
            // console.log('AC load_projects_from_db - project_id', project_ids[i])
            // console.log('AC load_projects_from_db - snap_val', snap_val)
  
            dispatch({ type: 'SET_PROJECTS', uid: project_ids[i], projects: snap_val })
  
            if (i === project_ids.length - 1) {
              dispatch({ type: 'SET_LOADING', is_loading: false });
            }
          }
        })
          .catch(error => {
            console.log('ERROR', error)
            console.log('ERROR message', error.message)
            // this.errorMessage = 'Error - ' + error.message
          })
      }
      catch (error) {
        console.log('ERROR', error)
        // this.errorMessage = 'Error - ' + error.message
        console.log('ERROR message', error.message)

      }
    }
    // dispatch({ type: 'SET_LOADING', is_loading:false});
  }
}


export function load_user_data_from_db(user_id, db_path) {

  // let db_path = 'story_app_data/user_data/'
  return dispatch => {
    dispatch({ type: 'SET_LOADING', is_loading: true })
    // first get 
    try {
      database.ref(db_path + '/' + user_id).once('value', function (snap) {
        let snap_val = snap.val();

        if (!snap_val) {
          console.log('I GOT NO RETURN FROM USER DB QUERY!')
          firebase.auth().signOut().then(function() {
            console.log('Signed Out');
          }, function(error) {
            console.error('Sign Out Error', error);
          });
          return;
        }
        else {

          // check if user is approved for beta. NOt sure this is the best place to query this, but washaving async problems querying in Home component so will try here
          database.ref(Constants.DB_BETA_APPROVED).once('value', function (snap_b) {
            let snap_val_beta_appr = snap_b.val();

            if (!snap_val_beta_appr) {
              // console.log('I GOT NO RETURN FROM USER DB QUERY for beta approved!')
              dispatch({ type: 'SET_BETA_APPROVED', beta_approved: false})
              return;
            }
            else {
              // console.log('load_user_data_from_db snap_val_beta_appr', snap_val_beta_appr)
              // console.log('load_user_data_from_db snap_val', snap_val)
              if (snap_val_beta_appr.includes(snap_val.email)){
                // console.log('load_user_data_from_db found!!!!')
                dispatch({ type: 'SET_BETA_APPROVED', beta_approved: true})

              }
              else {
                dispatch({ type: 'SET_BETA_APPROVED', beta_approved: false})
              }
            }
          })




          // firebase doesn't store empty data, so i'll reuild links.project if its not there
          if (!snap_val.hasOwnProperty('links')) {
            snap_val.links = {};
          }
          if (!snap_val.links.hasOwnProperty('project')) {
            snap_val.links.project = [];
          }
          dispatch({ type: 'SET_USER_DATA', uid: user_id, user_data: snap_val })

          dispatch({
            type: "SET_CURRENT_USER",
            user_id: user_id,
          })

          let project_ids = [];
          if (snap_val.hasOwnProperty('links')) {
            if (snap_val.links.hasOwnProperty('project')) {
              project_ids = snap_val.links.project;

              // console.log('load_user_data_from_db project_ids', project_ids);

              // dispatch(load_projects_from_db(project_ids, Constants.DB_PROJECT_DATA))
              let proj_db_path = Constants.DB_ROOT + '/projects'
              dispatch(load_projects_from_db(project_ids, proj_db_path))
            }
          }
          if (snap_val.hasOwnProperty('experimental_mode')) {
            let experimental_mode = snap_val.experimental_mode
            // console.log('load_user_data_from_db experimental_mode', experimental_mode);

            dispatch({ type: 'SET_EXPERIMENTAL_MODE', mode: experimental_mode });
          }

          dispatch({ type: 'SET_LOADING', is_loading: false });
        }
      })
        .catch(error => {
          console.log('ERROR', error)
          console.log('ERROR.message', error.message)
        })
    }
    catch (error) {
      console.log('ERROR', error)
      console.log('ERROR.message', error.message)
    }
  }
}












// export function load_project_items_from_db(project, project_id) {
//   console.log('AC load_project_items_from_db', project, project_id)


//   // let db_path =  'story_app_data/'
//   let db_path = Constants.DB_ROOT + '/';
//   return dispatch => {
//     dispatch({ type: 'SET_LOADING', is_loading: true })
//     //loop through links keys and retrieve data 1 at a time
//     let data_types = Object.keys(project.links)

//     //loop through key types
//     for (let i = 0; i < data_types.length; i++) {
//       let link_ids = project.links[data_types[i]];

//       // loop through all links, load them and then set store data
//       for (let ii = 0; ii < link_ids.length; ii++) {

//         console.log('getting all items', data_types[i], link_ids[ii])


//         database.ref(db_path + data_types[i] + '_data/' + link_ids[ii]).once('value', function (snap) {
//           let snap_val = snap.val();
//           if (snap_val) {
//             dispatch({ type: 'SET_' + data_types[i].toUpperCase() + '_DATA', uid: link_ids[ii], data: snap_val })
//           }
//           // this is my CRAPPY way of ensuring i'm at end of loop. Probably smartr way of doing this with promises
//           if (i === data_types.length - 1 && ii === link_ids.length - 1) {
//             dispatch({ type: 'SET_LOADING', is_loading: false });

//             // this is really ugly nesting this like this.
//             dispatch({
//               type: "SET_CURRENT_PROJECT",
//               project_id: project_id,
//             })

//             // temp hack to test loading docs
//             console.log('about to load doc, newDoc_07272187839173221')
//             // dispatch(load_project_document_from_db(project, project_id, ['newDoc_07272187839173221']))
//             dispatch(load_project_document_from_db(['newDoc_07272187839173221']))

//           }

//         })
//           .catch(error => {
//             console.log('ERROR', error)
//             console.log('ERROR.message', error.message)
//           })
//       }
//     }
//   }
// }









// export function load_project_thread_from_db(project, project_id) {
//   console.log('AC load_project_thread_from_db', project, project_id)


//   // let db_path = 'story_app_data/'
//   let db_path = Constants.DB_ROOT + '/';
//   return dispatch => {
//     dispatch({ type: 'SET_LOADING', is_loading: true })


//     //if project has no thread key then return out of function
//     if (!project.hasOwnProperty('thread')) {
//       console.log('NO Thread data found')
//       return;
//     }


//     let thread_ids = Object.keys(project.thread)

//     //loop through thread ids
//     for (let i = 0; i < thread_ids.length; i++) {
//       console.log('in thread loop i', i)
//       console.log('in thread loop thr id ', thread_ids[i])
//       let connection_ids = Object.keys(project.thread[thread_ids[i]]['connections']);
//       console.log('in thread loop connection_ids', connection_ids)

//       // loop through all connection ids, load them and then set store data
//       for (let ii = 0; ii < connection_ids.length; ii++) {

//         // console.log('getting thread connection data', thread_ids[i], connection_ids[ii])


//         database.ref(db_path + 'thread_data/' + thread_ids[i] + '/connections/' + connection_ids[ii]).once('value', function (snap) {
//           let snap_val = snap.val();
//           // console.log('val from DB ', snap_val)
//           if (snap_val) {
//             dispatch({
//               type: 'SET_THREAD_DATA',
//               thread_uid: thread_ids[i],
//               connection_uid: connection_ids[ii],
//               data: snap_val
//             })
//           }
//           // this is my CRAPPY way of ensuring i'm at end of loop. Probably smartr way of doing this with promises
//           if (i === thread_ids.length - 1 && ii === connection_ids.length - 1) {
//             dispatch({ type: 'SET_LOADING', is_loading: false });

//             // // this is really ugly nesting this like this.
//             // dispatch( { 
//             //   type: "SET_CURRENT_PROJECT", 
//             //   project_id:project_id,
//             // })
//           }

//         })
//           .catch(error => {
//             console.log('ERROR', error)
//             console.log('ERROR.message', error.message)
//           })
//       }
//     }
//   }
// }






export function load_project_document_from_db(document_ids) {
  console.log('AC load_project_document_from_db', document_ids)


  let db_path = Constants.DB_ROOT + '/'
  return dispatch => {
    dispatch({ type: 'SET_LOADING', is_loading: true })
    //loop through links keys and retrieve data 1 at a time


    // loop through all links, load them and then set store data
    for (let ii = 0; ii < document_ids.length; ii++) {

      console.log('getting all items', 'document', document_ids[ii])


      database.ref(db_path + 'document_data/' + document_ids[ii]).once('value', function (snap) {
        let snap_val = snap.val();
        if (snap_val) {
          dispatch({ type: 'SET_DOCUMENT_DATA', uid: document_ids[ii], data: snap_val })
        }
        // this is my CRAPPY way of ensuring i'm at end of loop. Probably smartr way of doing this with promises
        if (ii === document_ids.length - 1) {
          dispatch({ type: 'SET_LOADING', is_loading: false });
        }
      })
        .catch(error => {
          console.log('ERROR', error)
          console.log('ERROR.message', error.message)
        })

    }
  }
}






export function reset_redux_data() {
  // console.log('AC reset_redux_data',)


  return dispatch => {
    //loop through data types and rest to their init vals (usually empty objects)

    // console.log('init_thread func', init_thread)
    // console.log('initial_threads impo', initial_threads)


    let data_types_and_inits = [
      ['ui', initial_ui],
      ['timeline', initial_timelines],
      ['flow_viewport', initial_flow_viewports],
      ['map_viewport', initial_map_viewports],
      ['character', initial_characters],
      ['prop', initial_props],
      ['location', initial_locations],
      ['event', initial_events],
      ['document', initial_documents],
      ['card', initial_cards],
      ['thread', initial_threads],
      ['region', initial_regions],
      ['attribute', initial_attributes],
      ['group', initial_groups],
    ]

    // I don't know if i need to do this but will do it anyway- so i don't mutate these imported objects
    let data_types_and_inits_deep_copy = utils.deepCopyFunction(data_types_and_inits)

    //loop through key types
    for (let i = 0; i < data_types_and_inits.length; i++) {
      let data_type = data_types_and_inits_deep_copy[i][0];
      let init_data = data_types_and_inits_deep_copy[i][1][data_type + '_data']
      // console.log('\n\n\ninit_data', data_type, init_data)
      dispatch({ type: 'SET_TYPE_ROOT_DATA', data_type: data_type, data: init_data });

      // when i reset ui data i also reset the window dimension vals - which would only get set again with resize
      // so i set those vals back to whtat they should be after re-init
      dispatch({ type: 'SET_WINDOW_DIMENSIONS',  width:window.innerWidth, height:window.innerHeight});

      //default is_loading is true, so need to turn off here
      dispatch({ type: 'SET_LOADING', is_loading: false });
    }



  }
}


// export function crappy_proj_thread_data_test() {
//   console.log('AC crappy_proj_thread_data_test',)
//   return dispatch => {
//     let db_path = 'test4/project_data'
//     let proj_id = 'newProject_03718501195347166'
//     database.ref(db_path + '/' + proj_id).once('value', function (snap) {
//       let snap_val = snap.val();
//       console.log('\n\n\n\n Value for newProject_03718501195347166', snap_val)
//     })
//   }
// }









export function load_project_items_from_db_B( project_id) {
  // console.log('\n\n\nXXXXXXXX AC load_project_items_from_db_B', project_id)


  // let db_path =  'story_app_data/'
  let db_path = Constants.DB_ROOT + '/project_data/'+ project_id + '/';
  // console.log('xxxx AC load_project_items_from_db_B db_path', db_path)
  let items_done = [];

  return dispatch => {
    dispatch({ type: 'SET_LOADING', is_loading: true })
    //loop through links keys and retrieve data 1 at a time
    // let data_types = ['card', 'flow_viewport']
    let data_types = Constants.ITEM_TYPES_TO_LOAD_FROM_DB
    

    //loop through key types
    for (let i = 0; i < data_types.length; i++) {
      // let link_ids = project.links[data_types[i]];
      // console.log('working on this data type now:',  data_types[i])
      database.ref(db_path + data_types[i] + '_data').once('value', function (snap) {
        let snap_val = snap.val();
        // console.log('\n\n AC load_project_items_from_db_B snap_val', snap_val)

        if (snap_val) {
          //special case thread cos i have extra data in redux which is not in db - this is arguable crap!
          if (data_types[i] === 'thread'){
            let init_thread_deep_copy = utils.deepCopyFunction(initial_threads)
            // console.log('init_thread_deep_copy', init_thread_deep_copy);
            // snap_val['cur_dragging'] = [null, null, 'end'];
            // snap_val = Object.assign(snap_val, init_thread_deep_copy['thread_data'])
            snap_val = _.merge(snap_val, init_thread_deep_copy['thread_data'])
            // console.log(' - - - snap_val', snap_val);

          }
          let use_init_data = Constants.USE_INIT_DATA;
          dispatch({ type: 'SET_TYPE_ROOT_DATA', data_type: data_types[i], data: snap_val, use_init_data:use_init_data });
          items_done.push(data_types[i])
        }
        // this is my CRAPPY way of ensuring i'm at end of loop. Probably smartr way of doing this with promises
        if (i === data_types.length - 1) {
          dispatch({ type: 'SET_LOADING', is_loading: false });

          // this is really ugly nesting this like this.
          dispatch({
            type: "SET_CURRENT_PROJECT",
            project_id: project_id,
          })
        }
      })
      .catch(error => {
        console.log('ERROR', error)
        console.log('ERROR.message', error.message)
      })
    }
    // if (items_done)
    dispatch( { type: "CACHE_THREAD_CONNECTIONS"})
  }
}





export function load_project_thread_from_db_B(project, project_id) {
  // console.log('AC load_project_thread_from_db_B', project, project_id)


  // let db_path = 'story_app_data/'
  let db_path = Constants.DB_ROOT + '/project_data/' + project_id + '/';
  return dispatch => {
    dispatch({ type: 'SET_LOADING', is_loading: true })


    //if project has no thread key then return out of function
    if (!project.hasOwnProperty('thread_data')) {
      console.log('NO Thread data found')
      return;
    }


    // let thread_ids = Object.keys(project.thread)

    // New logic following refactor for nesting uner project
    let thread_ids = Object.keys(project.thread_data)

    //loop through thread ids
    for (let i = 0; i < thread_ids.length; i++) {
      // console.log('in thread loop i', i)
      // console.log('in thread loop thr id ', thread_ids[i])
      let connection_ids = Object.keys(project.thread_data[thread_ids[i]]['connections']);
      // console.log('in thread loop connection_ids', connection_ids)

      // loop through all connection ids, load them and then set store data
      for (let ii = 0; ii < connection_ids.length; ii++) {

        // console.log('getting thread connection data', thread_ids[i], connection_ids[ii])


        database.ref(db_path + 'thread_data/' + thread_ids[i] + '/connections/' + connection_ids[ii]).once('value', function (snap) {
          let snap_val = snap.val();
          // console.log('val from DB ', snap_val)
          if (snap_val) {
            dispatch({
              type: 'SET_THREAD_DATA',
              thread_uid: thread_ids[i],
              connection_uid: connection_ids[ii],
              data: snap_val
            })
          }
          // this is my CRAPPY way of ensuring i'm at end of loop. Probably smartr way of doing this with promises
          if (i === thread_ids.length - 1 && ii === connection_ids.length - 1) {
            dispatch({ type: 'SET_LOADING', is_loading: false });

            // // this is really ugly nesting this like this.
            // dispatch( { 
            //   type: "SET_CURRENT_PROJECT", 
            //   project_id:project_id,
            // })
          }

        })
          .catch(error => {
            console.log('ERROR', error)
            console.log('ERROR.message', error.message)
          })
      }
    }
  }
}






// export function load_beta_approved_list_from_db() {

//   // let db_path = 'story_app_data/user_data/'
//   return dispatch => {
//     dispatch({ type: 'SET_LOADING', is_loading: true })
//     // first get 
//     try {
//       database.ref(Constants.DB_BETA_APPROVED).once('value', function (snap) {
//         let snap_val = snap.val();

//         if (!snap_val) {
//           console.log('load_beta_approved_list_from_db I GOT NO RETURN FROM USER DB QUERY!')
//           // firebase.auth().signOut().then(function() {
//           //   console.log('Signed Out');
//           // }, function(error) {
//           //   console.error('Sign Out Error', error);
//           // });
//           return;
//         }
//         else {

//           // firebase doesn't store empty data, so i'll reuild links.project if its not there
//           if (!snap_val.hasOwnProperty('links')) {
//             snap_val.links = {};
//           }
//           if (!snap_val.links.hasOwnProperty('project')) {
//             snap_val.links.project = [];
//           }
//           dispatch({ type: 'SET_USER_DATA', uid: user_id, user_data: snap_val })

//           dispatch({
//             type: "SET_CURRENT_USER",
//             user_id: user_id,
//           })

//           let project_ids = [];
//           if (snap_val.hasOwnProperty('links')) {
//             if (snap_val.links.hasOwnProperty('project')) {
//               project_ids = snap_val.links.project;

//               console.log('load_user_data_from_db project_ids', project_ids);

//               // dispatch(load_projects_from_db(project_ids, Constants.DB_PROJECT_DATA))
//               let proj_db_path = Constants.DB_ROOT + '/projects'
//               dispatch(load_projects_from_db(project_ids, proj_db_path))
//             }
//           }


//           dispatch({ type: 'SET_LOADING', is_loading: false });
//         }
//       })
//         .catch(error => {
//           console.log('ERROR', error)
//           console.log('ERROR.message', error.message)
//         })
//     }
//     catch (error) {
//       console.log('ERROR', error)
//       console.log('ERROR.message', error.message)
//     }
//   }
// }

