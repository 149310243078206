import React from 'react';
import './Thumbnail.css';
import { connect} from 'react-redux';
import Image from '../objects/image/Image';

import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'

import new_uid from '../../../utils/new_uid'


let IMAGES_PATH = '../../../images/'
// dont know why this doesn't work?!
const Thumbnail = (props) => {

    // let itemSizeStyle = {
    //     width: '160px',
    //     height:'160px',
    //     objectFit: 'cover',
    //     fontSize: '144px',
    // };

    // if (props.itemSize === 'large'){

    //     itemSizeStyle = {
    //         margin: '7px',
    //         width: '140px',
    //         padding: '11px 0 0 0'

    //     };

    // }  
    // if (props.itemSize === 'medium'){

    //     itemSizeStyle = {
    //         objectFit: 'cover',

    //     };
    // }  

    // let iconType = null;
    // let thumb_element = <div>shouldnt see this</div>
    // if (props.myDefaultValue.image){
    //     console.log('THHTHTHHHUMB props.myDefaultValue.image', props.myDefaultValue.image)
    //     // thumb_element = <img className='Thumbnail' style={itemSizeStyle} src={require(IMAGES_PATH + props.myDefaultValue.image)} />;
    //     thumb_element = <img className='Thumbnail' style={itemSizeStyle} src={require('../../../images/' + props.myDefaultValue.image)} />;
    // }
    // else if (props.myDefaultValue.icon){
    //     itemSizeStyle = {
    //         width:'64px',
    //         height:'64px',
    //         objectFit: 'cover',
    //         fontSize: '56px',
    //     };
    //     thumb_element = <i style={itemSizeStyle} className={props.myDefaultValue.icon}></i>
    // }

    // // use this syntax to set defaults for vals
    // // var myDefaultValue = props.myDefaultValue || 'hello';
    // // var myDefaultValue = props.myDefaultValue;
    // let rand_id  = new_uid() + '_thumbnail';

    // return (
    //     <div 
    //         style={Object.assign({}, props.style, itemSizeStyle)}
    //         className="ThumbnailDiv"
    //         //onClick={props.onClick.bind(this, this )}
    //         id={rand_id} 
    //         //myValToSet= {props.myValToSet}
    //     >
                
    //         {thumb_element}
                
            
    //     </div>
    // )
    let onClick = props.onClick || null;

    let image_data = ['icon', 'far fa-question-circle']
    let dimensions = [64,64]
    if (props.item_data){
        if (props.item_data.image){
            image_data = props.item_data.image
            dimensions = [128,128]
        }
        else if (props.item_data.icon){
            image_data = ['icon', props.item_data.icon]
        }
    }

    return (
        <div style={{width:dimensions[0]}}   className="ThumbnailDiv">
            <Image 
                image_data = {image_data}
                //onClick={props.onClick.bind(this, this )}
                onClick={onClick}

                width={dimensions[0]}
                height={dimensions[1]}
            />
        </div>
    )
}
// }


const mapStateToProps = (state, props) => {
    if (props.dataSource === undefined){
        return {}
    }
    return {
        item_data:get_data_obj_from_string(state, props.dataSource)

    }
}


const mapDispatchToProps = (dispatch, props) => {
    return {
        // onClick:  (entity, event) => dispatch({type:'ON_CLICK', event:event, entity:entity, valToSet:props.valToSet, dataTarget:props.dataTarget}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps )(Thumbnail);
