import React, { Component } from 'react';

import { connect} from 'react-redux';
import './TimeEvent.css';



import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
import * as actionCreator_timeline from '../../../../store/actions/actions_timelines';
import * as actionCreator_time_events from '../../../../store/actions/actions_time_events';




class TimeEvent extends Component {

    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        // this.handleMouseWheel = this.handleMouseWheel.bind(this);
        // this.handleMouseMove = this.handleMouseMove.bind(this);

      }
    
      handleMouseDown(event) {


        // console.log('in TimeEvent handleMouseDown')
        this.props.onMouseDown(event, 'timeEvent', this.props.item_id,);
        this.props.onMouseDown_TL(event, this.props.ui_data, this.props.timeline_data,);
        this.props.onMouseDown_timeline_event(event, this.props.ui_data, this.props.item_id, this.props.event_data[this.props.item_id] );


    }


    render() {
 
        // cursor will not be parented to vp so it needs to be offset and scaled by the right amounts
        // let FlowPanelStyle = {
        //     backgroundPositionX: pos_x,
        //     backgroundPositionY: pos_y,
        //     backgroundSize:  [30 * viewport_scale +'px ' + 30 * viewport_scale +'px',],
        //     cursor:cursor_style,
        // }


        // let current_time_left = this.props.viewport_pos[0] + this.props.current_time * this.props.viewport_scale * 100;




        let line_thickness= 3;
        let height = 24;
        let row = this.props.row || 0;
        let top_val = row*height + 24; // the 24 here is because this ui is 24 units offset for some reason
        let current_time_left = this.props.timeline_data.pos[0] + this.props.start_time * this.props.timeline_data.scale * 100 - line_thickness * .5;
        // //AAA console.log(' **** current_time_left', current_time_left)
        // let color_str =  'rgb('+this.props.color[0]+', '+this.props.color[1]+', '+this.props.color[2]+')'
        let rgb = this.props.event_data[this.props.item_id].color;
        let opacity = this.props.event_data[this.props.item_id].opacity;

        let color_str =  'rgba('+rgb[0]+', '+rgb[1]+', '+rgb[2]+', '+ opacity+')'
        let label_stye ={backgroundColor:color_str, };

        let sel_class = '';
        if (this.props.ui_data.control.selected){
            if ( Object.keys(this.props.ui_data.control.selected).includes(this.props.item_id)) {
                sel_class = 'eventSelected';
                label_stye['borderColor'] = 'white';
                label_stye['boxShadow'] = '2px 6px 8px 0px rgba(0, 0, 0, .1)';
            }
        }

        let locked = false;
        let lock_icon = null;
        if (this.props.event_data[this.props.item_id].hasOwnProperty('locked')){
            locked = this.props.event_data[this.props.item_id].locked;
        }
        if (locked){
            lock_icon =  <i id='eventLockIcon' className="fas fa-lock"/>;
        }

        return (

            <div className='timeEventDiv'
                item_type='timeEvent'
                item_id={this.props.item_id}
                style={{left:current_time_left+'px', top:top_val, width:line_thickness, backgroundColor:color_str}}
                // onMouseDown={this.handleMouseDown}
            >
                <div
                    // className={'time_event_label ' + sel_class}
                    className='time_event_label '
                    style={label_stye}
                    onMouseDown={this.handleMouseDown}
                    item_type='timeEvent'
                    item_id={this.props.item_id}
                >
                    {this.props.name}
                    {lock_icon}
                </div>
                


            </div>

        );
    };
}

const mapStateToProps = (state) => {
    let current_tl = state.ui_data.current.current_timeline;
    return {

        ui_data:state.ui_data,

        timeline_data:state.timeline_data[current_tl],
        current_tl:current_tl,
        event_data:state.event_data,

   }
}



const mapDispatchToProps = (dispatch) => {
    return {
        onMouseDown_TL: (event, ui_data, timeline_data) => dispatch(actionCreator_timeline.mouse_down_timeline(event, ui_data, timeline_data)),
        onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        onMouseDown_timeline_event: (event, ui_data, event_id, event_item_data) => dispatch(actionCreator_time_events.mouse_down_time_event(event, ui_data, event_id, event_item_data)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps )(TimeEvent);

