import React, { Component } from 'react';
import { connect} from 'react-redux';
import './AttrEdStoryItemOptions.css';


import * as actionCreator_ui from '../../../../store/actions/actions_ui';
import * as actionCreator_story_items from '../../../../store/actions/actions_story_items';



class AttrEdStoryItemOptions extends Component {
    constructor(props) {
        super(props);
        this.handleSelectItem = this.handleSelectItem.bind(this);
        this.handleGoToItem = this.handleGoToItem.bind(this);
        this.handleUnlinkItem = this.handleUnlinkItem.bind(this);
    //     this.handleLogOutUserClick = this.handleLogOutUserClick.bind(this);
    //     this.fileSelectHandler = this.fileSelectHandler.bind(this);
    //     this.handleThumbClick = this.handleThumbClick.bind(this);
    }


    handleSelectItem(event) {
        // console.log('handleSelectItem')
        let data = {[this.props.item_id]:{item_type:this.props.item_type}}
        this.props.select_item(event, data)
        this.props.close_all_foldout_panels();
    }

    handleGoToItem(event) {
        // console.log('handleGoToItem')
        let data = {[this.props.item_id]:{item_type:this.props.item_type}}
        this.props.select_item(event, data)
        this.props.set_mode(this.props.item_type, this.props.ui_data)
        this.props.close_all_foldout_panels();

    }
    handleUnlinkItem(event) {
        // console.log('handleUnlinkItem')

        let cur_item_id = null;
        let cur_item_type =null;
        let cur_item = null;
        if (this.props.ui_data.control.selected){
            cur_item_id= Object.keys(this.props.ui_data.control.selected)[0]
            if (cur_item_id){
                cur_item_type= this.props.ui_data.control.selected[cur_item_id].item_type;
                cur_item = this.props[cur_item_type + '_data'][cur_item_id];
            }
        }
        let cur_proj_id = this.props.ui_data.current.current_project;

        this.props.un_link_items(cur_item, cur_item_type, cur_item_id, this.props.item_type, [this.props.item_id], cur_proj_id) 
        this.props.close_all_foldout_panels();
    }




    render(){

        return (

            <div
                className='AttrEdStoryItemOptions'
            >
                <p className='AttrEdSubMenuButtons' onClick={this.handleSelectItem}>select item</p>
                <p className='AttrEdSubMenuButtons' onClick={this.handleGoToItem}>go to item</p>
                <p className='AttrEdSubMenuButtons' onClick={this.handleUnlinkItem}>unlink item</p>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
      ui_data:state.ui_data,
    //   item:state[props.item_type + '_data'][props.item_id],
      character_data:state.character_data,
      prop_data:state.prop_data,
      location_data:state.location_data,
      event_data:state.event_data,
      card_data:state.card_data,
    //   context_data:state.context_data,
    //   thread_data:state.thread_data
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        select_item: (event, data) => dispatch(actionCreator_ui.select_item_c(event, data)),
        set_mode: (mode, ui_data) => dispatch(actionCreator_ui.mode_panel_set_mode(mode, ui_data)),
        un_link_items: (item_data, item_type, item_id, to_unlink_type, to_unlink_ids, project_id) => dispatch(actionCreator_story_items.un_link_items(item_data, item_type, item_id, to_unlink_type, to_unlink_ids, project_id)),
        close_all_foldout_panels: () => dispatch(actionCreator_ui.close_all_foldout_panels()),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AttrEdStoryItemOptions);
