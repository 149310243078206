/*
- returns a dict with all needed data for populating regions and other UI relted to regions

- all region Ids with useful info

*/


const get_populate_region_data = (region_data, item_parent, vp_bbox) => {
    // console.log('get_populate_region_data region_data', region_data); 

    // CAN LATER USE THE FLOW VP TO SCOPE WHICH CARDS ARE POPULATED
    let region_ret_data = {}

    // loop through to find all regions which should be visible given item_parent key


    // if (!project_object){
    //     return;
    // }
    // let project_regions = []
    // if (project_object.hasOwnProperty('links')){
    //     if (project_object.links.hasOwnProperty('region')){
    //         project_regions = project_object.links.region
    //     }
    // };

    let project_regions = Object.keys(region_data);

    // Object.keys(region_data).map((region_uid) => {
    project_regions.map((region_uid) => {
        {/* //AAA console.log('region', region); */}
        // if (region_uid === '__template__')   return null ;
        if (!region_data.hasOwnProperty(region_uid)){
            // console.log('WARNING PROJECT CARD LINKS LOOK BAD -proj ', project_object, )
            // console.log('WARNING PROJECT CARD LINKS LOOK BAD - region id', region_uid, )
            return null;
        }
        if (!region_data[region_uid].hasOwnProperty('item_parent'))
        {
            return null;
        }
        if (region_data[region_uid]['item_parent'] !== item_parent){
            return null;
        }

        // test for culling
        // add vp bounding box arg to this function. Also, make a function for is_in_bbox
        
        if (
            (region_data[region_uid]['pos'][0]+region_data[region_uid]['size'][0]) < vp_bbox[0] || 
            (region_data[region_uid]['pos'][1]+region_data[region_uid]['size'][1]) < vp_bbox[1] || 
            region_data[region_uid]['pos'][0] > vp_bbox[2] || 
            region_data[region_uid]['pos'][1] > vp_bbox[3]
            ){
            return;
        }

        return region_ret_data[region_uid] = {
            name:region_data[region_uid]['name'],
            pos: region_data[region_uid]['pos'],
            size: region_data[region_uid]['size'],
            color: region_data[region_uid]['color'],
            opacity: region_data[region_uid]['opacity'],
            child_items:[],
        }

    })

    // // collect children for each displayed item - this will drive counter badge/icon
    // // Object.keys(card_data).map((card_uid) => {
    // project_cards.map((card_uid) => {
    //         {/* //AAA console.log('card', card); */}
    //     // if (card_uid === '__template__')   return null ;
    //     if (!card_data.hasOwnProperty(card_uid)){
    //         return null;
    //     }

    //     let card_parent = card_data[card_uid]['item_parent']
    //     // //AAA console.log('card_parent', card_uid, card_parent)
    //     if (card_parent.startsWith('card_')){
    //         card_parent = card_parent.substring(5)
    //         // //AAA console.log('card_parentBBB', String(card_parent))
    //     }
    //     let keys_list = Object.keys(card_ret_data)
    //     // //AAA console.log('keysss',  card_parent, keys_list)
    //     // if (card_parent in keys_list){
    //     if (card_ret_data.hasOwnProperty(card_parent)){
    //         // //AAA console.log('in here card_ret_data[card_parent]', card_ret_data[card_parent])
    //         card_ret_data[card_parent].child_items.push(card_uid)
    //     }

    // })

    // need to build some kind of data (list probably) for full path for breadcrumbs UI
    // some kind of recursive thing? or maybe looping through all is easier?
    // perhaps if recursive it should be its own funciton (would be super cheap)

    return region_ret_data

    

    // return  populateAllCards;
}

export default get_populate_region_data;