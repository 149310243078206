
const initial_map_viewports  ={
    map_viewport_data:{
        'topLevel': {
            scale:1.0000,
            pos:[0,0],
        },
        'location_uid1': {
            pos:[0,0],
            scale:1.0000,
        },
    },
}

export default initial_map_viewports