import React, { Component } from 'react';
import './MapItem.css';
// import jQuery;
import { connect} from 'react-redux';
// import InputField from '../../inputs/InputField';

import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
import * as actionCreator_viewports from '../../../../store/actions/actions_viewports';
import * as actionCreator_viewport_xforms from '../../../../store/actions/actions_viewport_xforms';


class MapItem extends Component {

    constructor(props) {
        super(props);
        this.handleMapItemMouseDown = this.handleMapItemMouseDown.bind(this);
    }
 
    handleMapItemMouseDown(event) {

        event.stopPropagation();

        // the order of these is important!
        this.props.onMouseDown(event, 'location', this.props.item_id,);
        this.props.onMouseDown_VP(event, this.props.ui_data, this.props.viewport_data);
        this.props.mouse_down_vp_xform(event, this.props.item_type, this.props.item_id,  this.props.location_data[this.props.item_id], this.props.ui_data);

    }


    render() {
        
        let apply_hover_class = '';
        let color_array = [0,0,0,.25];
        let opacity = 1.0;
        let vp_scale = this.props.viewport_data.scale;
        let style_scale = (1 + 1 / vp_scale) * .5;
        let style = {};
        let pos_style = {
            left:this.props.location_data[this.props.item_id]['pos'][0],
            top:this.props.location_data[this.props.item_id]['pos'][1],
            transform: 'scale('+ style_scale + ')',
        }
        let dragging_class_suffix = '';
        let dragging_class = '';
        // if (this.props.location_data[this.props.item_id].color){
        //     color_array[0] = this.props.location_data[this.props.item_id].color[0];
        //     color_array[1] = this.props.location_data[this.props.item_id].color[1];
        //     color_array[2] = this.props.location_data[this.props.item_id].color[2];
        // }

        // style['backgroundColor']='rgb('+color_array[0].toString() + ',' + color_array[1].toString() + ',' + color_array[2].toString() +')';


        // if selected, change style
        if (this.props.ui_data.control.selected){
            if (this.props.item_id in this.props.ui_data.control.selected){
                style['boxShadow'] = '0px 0px 0px 2px rgba(255, 255, 255, 1)';
                apply_hover_class = 'ApplyHover-map';
            }
        }

          // if dragging, change style
          if (this.props.ui_data.control.begin_drag){

            if (this.props.ui_data.control.dragging_b){
                if (this.props.item_id in this.props.ui_data.control.dragging_b){
                    style['pointerEvents'] = 'none';
                    dragging_class_suffix = '-dragging';
                    dragging_class = 'mapItemDragging';
                }
            }
        }      

        // test if in selection bbox
        let sel_bbox = this.props.ui_data.control.select_bbox;
        if (sel_bbox){
            let item_bbox = [
                this.props.location_data[this.props.item_id]['pos'][0],
                this.props.location_data[this.props.item_id]['pos'][1],
                this.props.location_data[this.props.item_id]['pos'][0],
                this.props.location_data[this.props.item_id]['pos'][1],
            ]
            if (
                sel_bbox[0] <= item_bbox[2] &&
                sel_bbox[1] <= item_bbox[3] &&
                sel_bbox[2] >= item_bbox[0] &&
                sel_bbox[3] >= item_bbox[1]
                ){
                    style['boxShadow'] = '0px 0px 0px 4px rgba(255, 255, 255, .5)';
            }
        }

        // let image_div = null;
        // if (this.props.location_data[this.props.item_id].image){
        //     image_div = <img className='TestImage' style={{pointerEvents:'none', 'opacity':opacity}} 
        //         src={this.props.location_data[this.props.item_id].image}
        //     />
        //     style['backgroundColor']='none';
        //     style['opacity']=1.0;

        // }

        // let user_uid = this.props.context_data.user.uid;
        // console.log('user_uid', user_uid)
        // let user_icon_url = this.props.user_data[user_uid].icon;
        let location_icon_url = null;
        if (this.props.location_data[this.props.item_id])
        {
            location_icon_url = this.props.location_data[this.props.item_id].image;
        }
        let icon_element = <div id="MapIcon_noImage_small" className={this.props.location_data[this.props.item_id].icon}/>
        if (location_icon_url){
            icon_element =  <img id="UserIcon" src={location_icon_url} />
        }


        return (
            <div className="MapItemDiv"
                item_id={this.props.item_id}
                item_type='location'
                style={pos_style}
                onMouseDown={this.handleMapItemMouseDown}
                onTouchStart={this.handleMapItemMouseDown}
                >
                <div className={'MapPinShadow'+dragging_class_suffix}/>
                <div className={'MapPinAmbOcc'+dragging_class_suffix}/>

                <div className={'MapPinLine ' + dragging_class}/>
                <div className={'MapImageCutter  ' + dragging_class} style={style}>
                    {/* {image_div} */}
                    {icon_element}
                </div>
                {/* {this.props.children} */}
                <div className={'MapItemLabel  ' + dragging_class}>
                    {this.props.location_data[this.props.item_id].name}
                </div>
                
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    let current_vp = state.ui_data.current.current_viewport;
    return {
        location_data:state.location_data,
        viewport_data:state[current_vp[0]+'_data'][current_vp[1]],
        ui_data:state.ui_data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        onMouseDown_VP: (event, ui_data, viewport_data) => dispatch(actionCreator_viewports.mouse_down_viewport(event, ui_data, viewport_data)),
        mouse_down_vp_xform: (event, item_type, item_id, item, ui_data) => dispatch(actionCreator_viewport_xforms.mouse_down_vp_xform(event, item_type, item_id, item, ui_data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(MapItem);

