
const initial_context  ={
    context_data:{
        project: null,
        user:{
            uid:null,
            email:null,
        },
        selected:null,
        beta_approved:null,
    }
}


export default initial_context