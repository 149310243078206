
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator_auth from '../store/actions/actions_auth';
import { Link, useNavigate } from 'react-router-dom';

import './LoginPage.css';


import InputField from '../components/ui_elements/inputs/InputField';
import Button from '../components/ui_elements/inputs/Button';

import bg_image from '../images/landing_page_bg_01.jpg';

// ...later


export default function SignUpPageF() {

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [passwordConfirm, setPasswordConfirm] = useState()
    const [loading, setLoading] = useState()
    const { currentUser} = useAuth()
    // const history = useHistory()
    const navigate = useNavigate()

    const dispatch =useDispatch()

    let auth_ui_message = useSelector( state => state.ui_data.auth_ui_message)

    function handleSubmit(e) {
        dispatch(actionCreator_auth.sign_up_user(email, password, passwordConfirm))
    }

    // this will only run once when page loads
    useEffect(() => {
        dispatch(actionCreator_auth.set_auth_message(null))
        }, []);

    let auth_message_element = null;
    if (auth_ui_message) {
        auth_message_element = <p className='LoginMesssageError'> {auth_ui_message} </p>;
    }

    if (currentUser){
        console.log('got currentUser. pushing history now')
        navigate("/app")
    }

    return (
        <div className="LoginPageDiv">
                <img className="LoginPageImg" src={bg_image} alt="logo" />

                <div className='LoginTitle'>
                    <Link className='LoginTitleLink' to="/">StoryCraft.</Link>
                </div>
                <form  className="LoginPageForm">
                    <div className='LoginHeading'>Sign up for your account</div>
                    {currentUser && currentUser.email}
                    {auth_message_element}

                    <InputField 
                        inputType='email' 
                        style={{display: "block"}} 
                        id='email' 
                        label='email' 
                        do_set_db_data='false'
                        onChangeFunction={e => setEmail(e.target.value)}
                        value={email}
                        />

                    
                    <InputField 
                        inputType='password' 
                        style={{display: "block"}} 
                        id='password' 
                        label='password'
                        do_set_db_data='false'
                        onChangeFunction={e => setPassword(e.target.value)}
                        value={password}
                        
                        
                        />
                    <InputField 
                        inputType='password' 
                        style={{display: "block"}} 
                        id='passwordConfirm' 
                        label='confirm password'
                        do_set_db_data='false'
                        onChangeFunction={e => setPasswordConfirm(e.target.value)}
                        value={passwordConfirm}
                        
                        
                        />
                    <Button 
                        onClickFunction={handleSubmit}
                        label='Sign Up' 
                        style={{width:"140px", top:"20px", margin:'auto'}}
                    />
                    <div style={{display:'block', padding:'10px'}}>
                        <p className='LoginText'> {"Have an account already?"}</p>
                        {/* <p className='LoginLink'
                            onClick={handleClickLogin}
                        >  Log In Now</p> */}
                        <Link to="/login" className='LoginLink'> Log In Now</Link>

                    </div>
                </form>
            </div>
    )
}
