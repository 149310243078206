import React, { Component } from 'react';
import { connect} from 'react-redux';
import './WritingFilterOptions.css';

import Checkbox from '../../inputs/Checkbox';
import SelectMenu from '../../inputs/SelectMenu';
import ThreadPickerItem from '../thread_picker/ThreadPickerItem'



class ThreadPickerList extends Component {

    populateAllThreads() {

        var result = Object.keys(this.props.thread_data).map((col_uid) => {
            // console.log('AA', col_uid)
            if (col_uid !== 'cur_dragging'){
                // console.log('BB', col_uid)

                let thread_name = this.props.thread_data[col_uid]['name']
                let thread_color = this.props.thread_data[col_uid]['color']
                return(
                    
                    <ThreadPickerItem  close_foldout_on_click={true} key={col_uid} thread_id={col_uid} thread_color={thread_color} thread_name={thread_name}/>

                
                ); 
            }
        })
        return  result;
    }

    render(){

        return (

            <div
                className='ThreadPickerListDiv'
            >
                {this.populateAllThreads()}
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
    //   ui_data:state.ui_data,
    //   user_data:state.user_data,
    //   context_data:state.context_data,
      thread_data:state.thread_data
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        // open_user_ui: () => dispatch(actionCreator_ui.open_user_ui()),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ThreadPickerList);
