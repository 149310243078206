
// /// SELECTORS!!! ///
// export const get_card_data_from_id = (card_state, cardId) => card_state[cardId];

// // export const get_card_data_from_id = (state, cardId) => {
// //     if (state.card_data.hasOwnProperty(cardId)){
// //         return state.card_data.cardId;
// //     }
// //     else{
// //         //AAA console.log('no card data found for this id', cardId)
// //     }
// // }
import * as card_utils from '../../utils/card_utils'


const reducer_cards = (state, newState, action) => {

    // with current setup I don't want to copy state - because I'm already recieving a copy from the root reducer
    // const newState = {...state};


    if(action.type === 'SET_CARD_DATA'){
        // console.log('RD SET_CARD_DATA', action)
        newState.card_data = {...state.card_data};
        newState.card_data[action.uid] = action.data;
    }


    if(action.type === 'SET_THREAD_DATA'){
        // console.log('RD SET_THREAD_DATA', action)
        newState.thread_data[action.thread_uid]['connections'] = {...state.thread_data[action.thread_uid]['connections']};
        newState.thread_data[action.thread_uid] = {...state.thread_data[action.thread_uid]};
        newState.thread_data = {...state.thread_data};

        newState.thread_data[action.thread_uid]['connections'][action.connection_uid] = action.data;
    }


    if(action.type === 'NEW_THREAD'){
        //AAA console.log('RD NEW_THREAD', action)


        newState['thread_data'][action.col_id]['connections'] = {...state['thread_data'][action.col_id]['connections']};
        newState['thread_data'][action.col_id] = {...state['thread_data'][action.col_id]};
        newState['thread_data'] = {...state['thread_data']};


        let newThreadData = [['card',  action.item_id], ['mouse', null]]

        let uid = action.new_uid;
        newState.thread_data[action.col_id]['connections'][uid] = newThreadData;
        newState.thread_data.cur_dragging = [action.col_id, uid, 'end'];

        //TO DO: move thread dragging over to main one in ui_data
        // newState['ui_data'] = {...state['ui_data']};
        // newState.ui_data.control.dragging = [action.col_id, uid, 'end'];


    }



    /////////////////////////////////////////////////////////////////////////////////////////////////////
 
    if(action.type === 'CARD_MOUSE_DOWN'){
        //AAA console.log('RD CARD_MOUSE_DOWN', action)

        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data = {...state.ui_data}

        newState.ui_data.control.local_orig_pos = action.pos;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////

    if(action.type === 'SET_CARD_POS'){
        //AAA console.log('RD SET_CARD_POS', action)

        newState.card_data[action.card_id] = {...state.card_data[action.card_id]};
        newState.card_data = {...state.card_data};
        
        newState.card_data[action.card_id]['pos'] = action.pos;
    }
    
    if(action.type === 'SET_THREAD_TARGET'){
        // console.log('RD SET_THREAD_TARGET', action)

        // crappy immutable copy - should investigate redux immutable (or whatever it is)
        // console.log('RD state.thread_data[action.thread_colour_id][connections]', state.thread_data[action.thread_colour_id]['connections'])

        newState.thread_data[action.thread_colour_id]['connections'][action.thread_id][action.start_or_end_index] = {...state.thread_data[action.thread_colour_id]['connections'][action.thread_id][action.start_or_end_index] }
        newState.thread_data[action.thread_colour_id]['connections'][action.thread_id] = {...state.thread_data[action.thread_colour_id]['connections'][action.thread_id] }
        newState.thread_data[action.thread_colour_id]['connections'] = {...state.thread_data[action.thread_colour_id]['connections'] }
        newState.thread_data[action.thread_colour_id] = {...state.thread_data[action.thread_colour_id]}
        newState.thread_data = {...state.thread_data}

        newState.thread_data[action.thread_colour_id]['connections'][action.thread_id][action.start_or_end_index] = action.target_data;
    }

    if(action.type === 'SET_THREAD_CUR_DRAGGING'){
        //AAA console.log('RD SET_THREAD_CUR_DRAGGING', action)

        newState.thread_data = {...state.thread_data};
        let data_to_set =  [action.col_id, action.connection_id, action.start_or_end];
        if (action.extra_data){
            data_to_set.push(action.extra_data)
        }
        newState.thread_data.cur_dragging = data_to_set;
    }

    if(action.type === 'SET_CARD_PARENT'){
        //AAA console.log('RD SET_CARD_PARENT', action)

        newState.card_data[action.card_id] = {...state.card_data[action.card_id]};
        newState.card_data = {...state.card_data};

        newState.card_data[action.card_id]['item_parent'] = action.item_parent;
    }


    if(action.type === 'DELETE_THREAD'){
        console.log('RD DELETE_THREAD', action)

        newState['thread_data'][action.thread_id]['connections'] = {...state['thread_data'][action.thread_id]['connections']};
        newState['thread_data'][action.thread_id] = {...state['thread_data'][action.thread_id]};
        newState['thread_data'] = {...state['thread_data']};

        delete newState.thread_data[action.thread_id]['connections'][action.connection_id]; 
    }


    // if(action.type === 'CACHE_THREAD_CONNECTIONS'){
    //     console.log('RD CACHE_THREAD_CONNECTIONS', action)

    //     newState.ui_data = {...state.ui_data};
    //     newState.ui_data.cache_data = {...state.ui_data.cache_data};

    //     // if (newState.ui_data.cache_data.hasOwnProperty(action.key)){
    //     //     newState.ui_data.cache_data[action.key] = {...state.ui_data.cache_data.action.key};
    //     // }
    //     let thread_connection_data = card_utils.get_thread_connected_cards( state.thread_data, null )
    //     newState.ui_data.cache_data['thread_connection_data'] = thread_connection_data;
    // }



    return newState;

};
export default reducer_cards;