
/////////////////////// Viewport Action Creators  /////////////////////////////
///////////////////////////////////////////////////////////////////////////////
/*
to do:
- do selection_b on mouse up
- set DB on mouse up (if fail, revert to stored pos in dragging_b data)
- once i hace valide sel

*/
import * as actions_database from './actions_database';
import * as database_utils from '../../utils/database_utils'
import * as Constants from '../../constants/constants';
import get_time_from_timeslider from '../../utils/get_time_from_timeslider'
import * as misc_utils from '../../utils/misc_utils'
import clog from '../../utils/clog'
import * as attribute_utils from '../../utils/attribute_utils'

export const mouse_down_tc_keys = ( ui_data, cache_data, item_type, item_id, attr_id, attr_parent, key_time) => {
    console.log('AC mouse_down_tc_keys', ui_data, cache_data, item_type, item_id, attr_id, attr_parent, key_time)
    // console.log('AC mouse_down_vp_xform CONSTANTS', Constants.VP_XFORM_POS_TYPES)

    return dispatch => {
        // event.stopPropagation();
        
        let current_sel_keys = ui_data.control.selected_keys;
        let project_id = ui_data.current.current_project;
        let timeline = ui_data.current.current_timeline;
        let dragging_data = null;
        let clicked_data = {};

        // let key_id = key_time + ' ' + attr_id + ' ' + item_id
        let key_id = item_id+'-'+timeline+'-'+attr_id+'-'+key_time;
        let keys = cache_data['key_data'][timeline][item_id][attr_parent][attr_id];
        clicked_data = {
            [key_id]:{item_type:item_type+'_key', 
                custom_data:{
                    key_time:key_time,
                    keys:keys,
                    attr_id:attr_id,
                    attr_parent:attr_parent,
                    item_id:item_id,
                    item_type:item_type,
                    project_id:project_id,
                    timeline:timeline,
                },
            }
        }

        if (current_sel_keys){
            if (key_id in current_sel_keys){
                dragging_data = {...current_sel_keys}
            }
        }
        

        // if nothing filled in yet, then drag clicked
        if(!dragging_data){
            dragging_data = {...clicked_data};
        }

        dispatch({  
            type: "SET_DRAGGING_B", 
            data:dragging_data,
        }) 
        dispatch({  
            type: "SET_CLICKED", 
            data:clicked_data,
        })

    }

};

export const mouse_move_tc_keys = (event, ui_data, tl_data, cache_data) => {
    return dispatch => {
        let mousePos = [event.clientX, event.clientY];
        console.log('mouse_move_tc_keys mousePos', mousePos)
        let mouse_down_pos = ui_data.control.mouse_down_pos;

        let mouse_down_time = get_time_from_timeslider(mouse_down_pos, ui_data, tl_data, true, true)
        let time = get_time_from_timeslider(mousePos, ui_data, tl_data, true, true)
        let time_offset = Number(time) - Number(mouse_down_time);
        clog('time_offset', time_offset, 1)

        let dragging_data = ui_data.control.dragging_b;
        console.log('mouse_move_tc_keys dragging_data', dragging_data)

        let clicked_data = ui_data.control.clicked;
        let begin_drag = ui_data.control.begin_drag;
        let local_drag_offset = ui_data.control.local_drag_offset;
        // console.log('AC mouse_move_vp_xform', dragging_data)

        // if is_dragging, loop through all in dragging and apply xform offset
        //( will need to case for scaling, but do that later )
        let key_cache_data = misc_utils.deepCopyFunction(cache_data['key_data']);
        let new_key_data = {}

        // collect all items and attrs to update 
        let key_data_for_set_attr = {};
        if (begin_drag) {

            Object.keys(dragging_data).map((drag_id) => {
                // console.log('AC mouse_move_vp_xform item_id', item_id)

                // let item_id = dragging;
                let custom_data = dragging_data[drag_id]['custom']
                let item_id = dragging_data[drag_id]['custom']['item_id']
                let item_type = dragging_data[drag_id]['custom']['item_type']
                let timeline = dragging_data[drag_id]['custom']['timeline']
                let attr_parent = dragging_data[drag_id]['custom']['attr_parent']
                let attr_id = dragging_data[drag_id]['custom']['attr_id']
                let key_time = dragging_data[drag_id]['custom']['key_time']
                let keys = dragging_data[drag_id]['custom']['keys']
                let project_id = dragging_data[drag_id]['custom']['project_id']
                
                clog('custom data', custom_data, 1, ['pink', null])
                // clog('custom drag', dragging_data[item_id], 1, ['purple', null])
                clog('custom item type', dragging_data[drag_id]['custom']['item_type'], 1, ['purple', null])
                clog('custom item id', dragging_data[drag_id]['custom']['item_id'], 1, ['purple', null])
                clog('custom item key_time', dragging_data[drag_id]['custom']['key_time'], 1, ['purple', null])
                clog('custom item time', time, 1, ['purple', null])

                // if (!key_data_for_set_attr.hasOwnProperty(timeline)){
                //     key_data_for_set_attr[timeline] = {};
                // }
                // if (!key_data_for_set_attr[timeline].hasOwnProperty(item_id)){
                //     key_data_for_set_attr[timeline][item_id] = {};
                // }
                // if (!key_data_for_set_attr[timeline][item_id].hasOwnProperty(attr_parent)){
                //     key_data_for_set_attr[timeline][item_id][attr_parent] = {};
                // }
                // if (!key_data_for_set_attr[timeline][item_id][attr_parent].hasOwnProperty(attr_id)){
                //     key_data_for_set_attr[timeline][item_id][attr_parent][attr_id] = keys;
                // }


                // key_data_for_set_attr[item_id].push([attr_parent, attr_id])
                // get keyframe data from cache_data
                // let keys_data = {...keys}
                // let keys_data = key_data_for_set_attr[timeline][item_id][attr_parent][attr_id]

                // // let keys = key_cache_data[timeline][item_id][attr_parent][attr_id];
                // // let key_val = keys[key_time];
                // delete keys_data[key_time];
                // clog(' keys time', Number(key_time)+time_offset, 1, ['orange', null])

                // keys_data[Number(key_time)+time_offset] = key_val;

                // keys_data[time] = key_val;
                // new_key_data
                key_cache_data[timeline][item_id][attr_parent][attr_id][key_time][0] = Number(key_time)+time_offset;

                // clog(' keys_data', keys_data, 1, ['orange', null])
                // // 

                // TODO this will need revision if i support multiple timelines
                // let data_to_set = {_keys:{[timeline]:keys_data}};

                // // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id+'/'+attr_parent+'/'+attr_id
                // let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id) +'/'+attr_parent+'/'+attr_id

                // actions_database.set_db_data(db_path, data_to_set, window.SKIP_DATABASE) 
                // dispatch( { 
                //     type: "SET_ATTRIBUTE", 
                //     item_type:item_type,
                //     item_id:item_id,
                //     attr_id:attr_id,
                //     attr_val:data_to_set,
                //     attr_parent:attr_parent,
                // })
            })
            
        }

        // for (let index = 0; index < Object.keys(key_data_for_set_attr).length; index++) {
        //     let item_id =  Object.keys(key_data_for_set_attr)[index];
            
        //     key_data_for_set_attr[item_id].push([attr_parent, attr_id])

        // }
        // }
        // clog(' key_cache_data', key_cache_data, 1, ['pink', null])
        clog(' key_cache_data', key_cache_data, 1, ['orange', null])

        dispatch({
            type:'SET_CACHE_DATA',
            key:'key_data',
            data:key_cache_data,
        
        })    
        
    }
}


export const mouse_up_tc_keys = (event, ui_data, cache_data) => {
    // console.log('AC mouse_up_tc_keys', ui_data, cache_data)

    return dispatch => {

        // let dragging_data = ui_data.control.dragging_b;
        let begin_drag = ui_data.control.begin_drag;
        let dragging_data = ui_data.control.dragging_b;
        let dragging = ui_data.control.dragging;

        // let current_sel = ui_data.control.selected_keys || {};
        let clicked_data = ui_data.control.clicked;
        // let clicked_id = null;
        let clicked_type = '';
        if (clicked_data){
            let clicked_item = clicked_data[Object.keys(clicked_data)[0]]
            
            // clicked_type = clicked_data[Object.keys(clicked_data)[0]]
            if (clicked_item){

                if (clicked_item.hasOwnProperty('item_type')){
                    clicked_type = clicked_item['item_type']
                }
                // console.log('ooooooooooooooo,  clicked_type',  clicked_type)
            } 

        }
        // if clicking on something that's not a key, 
        // and not using a modifier, then clear key sel
        if (!clicked_type.endsWith('_key') ){
            // console.log('ooooooooooooooo 2 clicked_type', clicked_type)
            if (!event.getModifierState("Shift") && !event.getModifierState("Control") && !event.getModifierState("Alt")){
                dispatch({  
                    type: "SELECT_KEY", 
                    data:{},
                    select_mode:'replace',
                })
            }
        }
            


        // let new_sel_data = null;
        // if i didn't brgin drag, then register as a click, and do select
        let select_mode = 'replace';
        if (!begin_drag) {

            if (event.getModifierState("Shift") || event.getModifierState("Control")){
                select_mode = 'add';

            }
            else if (event.getModifierState("Alt")){
                select_mode = 'remove';
            }

            
            dispatch({  
                type: "SELECT_KEY", 
                data:clicked_data,
                select_mode:select_mode,
            })
        }
        else{
            // if begin drag is true then need to commit new values

            // this is so i don't repeat attrs for each key
            // when done i'll add to this list
            let done_list = [];
            let items_and_ids = [];
            // IF I dragged something then need to commit the change
            if (dragging[0] === 'timeControl_keys'){

                Object.keys(dragging_data).map((drag_id) => {
                    // console.log('AC mouse_move_vp_xform item_id', item_id)
                    
                    // let item_id = dragging;
                    // let custom_data = dragging_data[drag_id]['custom']
                    let item_id = dragging_data[drag_id]['custom']['item_id']
                    let item_type = dragging_data[drag_id]['custom']['item_type']
                    let timeline = dragging_data[drag_id]['custom']['timeline']
                    let attr_parent = dragging_data[drag_id]['custom']['attr_parent']
                    let attr_id = dragging_data[drag_id]['custom']['attr_id']
                    // let key_time = dragging_data[drag_id]['custom']['key_time']
                    // let keys = dragging_data[drag_id]['custom']['keys']
                    let project_id = dragging_data[drag_id]['custom']['project_id']
                    
                    // clog('custom data', custom_data, 1, ['pink', null])
                    // // clog('custom drag', dragging_data[item_id], 1, ['purple', null])
                    // clog('custom item type', dragging_data[drag_id]['custom']['item_type'], 1, ['purple', null])
                    // clog('custom item id', dragging_data[drag_id]['custom']['item_id'], 1, ['purple', null])
                    // clog('custom item key_time', dragging_data[drag_id]['custom']['key_time'], 1, ['purple', null])
                    // clog('custom item time', time, 1, ['purple', null])
                    
                    // get new times from cached data
                    let key_string = timeline+'-'+item_id+'-'+attr_parent+'-'+attr_id;
                    if (!done_list.includes(key_string)){
                        let keys_data ={};
                        let cache_key_data = cache_data['key_data'][timeline][item_id][attr_parent][attr_id];
                        let item = cache_data['key_data'][timeline][item_id]['item'];
                        items_and_ids.push([item, item_id]);
                        console.log('PPPP cache_key_data', cache_key_data)
                        for (let index = 0; index < Object.keys(cache_key_data).length; index++) {
                            const key_time = Object.keys(cache_key_data)[index];
                            const key_array = cache_key_data[key_time];
                            keys_data[key_array[0]] = key_array[1];
                        }
                        console.log('PPPP keys_data', keys_data)
                        
                        done_list.push(key_string)
                        console.log('PPPP done_list', done_list)
                        
                        // TODO this will need revision if i support multiple timelines
                        let data_to_set = {_keys:{[timeline]:keys_data}};
                        console.log('PPPP data_to_set', data_to_set)
                        
                        // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id+'/'+attr_parent+'/'+attr_id
                        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id) +'/'+attr_parent+'/'+attr_id
                        
                        actions_database.set_db_data(db_path, data_to_set, window.SKIP_DATABASE) 
                        dispatch( { 
                            type: "SET_ATTRIBUTE", 
                            item_type:item_type,
                            item_id:item_id,
                            attr_id:attr_id,
                            attr_val:data_to_set,
                            attr_parent:attr_parent,
                        })
                    }
                    
                    
                    
                })

                // // Goind to hardcode timeline here,cos i'm over it. can fix later
                // let timeline = '_time_progess';
                // let key_data = attribute_utils.get_key_data_from_items(items_and_ids, timeline)
                // console.log('PPPP update_keyframes_cache - key_data', key_data)

                // dispatch({
                //     type:'SET_CACHE_DATA',
                //     key:'key_data',
                //     data:key_data,
                
                // })    
            }
        }
        dispatch({  
            type: "SET_DRAGGING_B", 
            data:null,
        })
        dispatch({  
            type: "SET_CLICKED", 
            data:{},
        })
    }
}

