import React, { Component } from 'react';
import './ProjectCard.css';
// import jQuery;
import { connect} from 'react-redux';

import InputField from '../../inputs/InputField';

// import * as actionCreator_cards from '../../../../store/actions/actions_cards';
// import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
// import * as actionCreator_timeline from '../../../../store/actions/actions_timeline';
// import * as actionCreator_time_cards from '../../../../store/actions/actions_time_cards';

import * as actionCreator_projects from '../../../../store/actions/actions_projects';

class ProjectCard extends Component {
    constructor(props) {
        super(props);
        this.handleMouseClick = this.handleMouseClick.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleOptionsClick = this.handleOptionsClick.bind(this);
        this.state = {
            is_hovering:false,
        }
      }
    
    handleMouseClick(event) {
        // this.props.set_current_project(this.props.project_data[this.props.project_id], this.props.project_id);
        this.props.set_current_project( this.props.project_id, this.props.project_data);
    };

    handleMouseOver() {
        this.setState({is_hovering:true})
    };

    handleMouseLeave() {
        this.setState({is_hovering:false})
    };

    handleOptionsClick(event) {
        event.stopPropagation();
        alert(
            '\n\nThis feature is not working yet.\nIn the future, it might bring up options for:\n\
            - share project\n\
            - archive project\n\
            - delete project'            
        )
    }



    render() {
        let options_style = {display:'none'};
        if (this.state.is_hovering){
            options_style = {display:'block'};
        }

        let project_id = this.props.project_id;
        return (
            <div
                item_id={project_id}
                item_type='projectCard'
                onClick={this.handleMouseClick}
                className= "ProjectCard"
                onMouseOver={this.handleMouseOver}
                onMouseLeave={this.handleMouseLeave}
                >
                    <InputField 
                        className='ProjectCardTitle'
                        inputPlaceholder='Project Name...'
                        // dataSource={"project_data." + project_id + ".name"}
                        // dataTarget={"project_data." + project_id + ".name"}
                        dataSource={"projects." + project_id + ".name"}
                        dataTarget={"projects." + project_id + ".name"}
                        inputType='textFieldCard' 
                        //={titleStyle} 
                        id='nameField' 
                        label='' 
                        spellCheck={true}
                        // style={}

                    />
                    <i className="fas fa-ellipsis-h" 
                        id='projOptionsButtonX' 
                        item_type='' 
                        style={options_style}
                        onClick={this.handleOptionsClick}

                    />

            </div>
        );
    };
}

const mapStateToProps = (state, props) => {
    return {
        ui_data:state.ui_data,
        project_data:state.projects[props.project_id],
        // project_data:state.projects,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // set_current_project: (project, project_id) => dispatch(actionCreator_projects.set_current_project(project, project_id)),
        set_current_project: (project_id, project_data) => dispatch(actionCreator_projects.set_current_project(project_id, project_data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(ProjectCard);
