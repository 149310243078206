///////////////////////////////////////////////////////////////////////////////
import * as Constants from '../../constants/constants';


import new_uid from '../../utils/new_uid';
import * as database_utils from '../../utils/database_utils'


import get_mouse_pos_in_viewport from '../../utils/get_mouse_pos_in_viewport'


import templates from '../templates/templates';

import * as actions_database from './actions_database';




export const create_location_at_centre_of_vp = (event,  project_id, ui_data, viewport_data) => {
    // console.log('hey, hey hey')
    console.log('AC create_location_at_centre_of_vp', event,  project_id, ui_data, viewport_data)

    return dispatch => {
        // let hovering = ui_data.control.hovering;
        let current_viewport = ui_data.current.current_viewport;
        // let local_current_pos = ui_data.control.local_current_pos;

        // if (hovering[0] === 'viewport') {
            
            let default_location_dimensions = [0, 0]
            let item_parent = current_viewport[1];

            // what are these numbers? I assume they relate to the offset of the pin location to the 
            // top left corner

            let viewportPos = viewport_data[item_parent].pos;
            let orig_scale = viewport_data[item_parent].scale;
    
            // let pos = [local_current_pos[0] - 165, local_current_pos[1] - 45,] ;

            let centre_pos = [
                ui_data.layout.main_panel_left  +  (.5 * ( ui_data.layout.main_panel_right - ui_data.layout.main_panel_left)),
                ui_data.layout.main_panel_top  +  (.5 * ( ui_data.layout.main_panel_bottom - ui_data.layout.main_panel_top)),
            ];
            let vp_mouse_pos = get_mouse_pos_in_viewport(
                [ui_data.layout.main_panel_left, ui_data.layout.main_panel_top], 
                viewportPos,
                orig_scale,
                centre_pos
                )

            let pos = [
                Math.round(vp_mouse_pos[0] - (.5*default_location_dimensions[0])),
                Math.round(vp_mouse_pos[1] - (.5*default_location_dimensions[1])),
            ]

            let uid = 'newLocation_'+new_uid();

            // let db_path = Constants.DB_ROOT+'/location_data/'+uid;
            // NOTE: refactoring for project based DB structure
            // let db_path = Constants.DB_ROOT+'/project_data/'+ project_id + '/location_data/'+uid;
            let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'location', uid);

            //get location data from template and update pos and parent
            let location_data = {...templates['location']};
            location_data['pos']=pos;
            location_data['item_parent']=item_parent;
            location_data['creation_date'] = new Date().toUTCString();

            console.log('location_data', location_data)
            // database.ref(db_path).set(location_data)
            actions_database.set_db_data(db_path, location_data, window.SKIP_DATABASE)    
            .then (
                dispatch( { type: "SET_LOCATION_DATA", 
                    uid:uid, 
                    data:location_data,
                }),
            )
            .then (
                dispatch( { 
                    type: "SELECT_ITEM_C", 
                    data: {[uid]:{item_type:'location'}},
                    select_mode: 'replace',
                })
            )
            // link new item to project
            // dispatch(actions_projects.link_to_project(project_object, project_id, 'location', [uid]))

        // }
        // else{
        //     return {type: ""};
        // }
    }


}
