

const reducer_timeline_events = (state, newState, action) => {

    if(action.type === 'TIMELINE_EVENT_MOUSE_DOWN'){
        // console.log('RD TIMELINE_EVENT_MOUSE_DOWN', action);

        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data = {...state.ui_data}

        newState.ui_data.control.local_orig_start_end_time = action.start_end_time;
        // newState.ui_data.control.local_timeline_row = action.timeline_row;

    }

    if(action.type === 'SET_EVENT_TIME'){
    //    console.log('RD SET_EVENT_TIME', action);

        newState.event_data[action.event_id]['time'][action.current_timeline] = {...state.event_data[action.event_id]['time'][action.current_timeline]};
        newState.event_data[action.event_id]['time'] = {...state.event_data[action.event_id]['time']};
        newState.event_data[action.event_id] = {...state.event_data[action.event_id]};
        newState.event_data = {...state.event_data};

        newState.event_data[action.event_id]['time'][action.current_timeline]['start_end_time'] = action.start_end_time;
        // newState.event_data[action.event_id]['time'][action.current_timeline]['row'] = action.timeline_row;
    }  


    return newState;

};
export default reducer_timeline_events;