import React, { Component } from 'react';
import './StoryItem.css';
import { connect} from 'react-redux';
import * as actionCreator_ui from '../../../../store/actions/actions_ui';
import * as actionCreator_documents from '../../../../store/actions/actions_documents';
import * as actionCreator_cache from '../../../../store/actions/actions_cache';

import Image from '../image/Image';

const StoryItem = (props) => {

    if (!props.item){
        return null
    }
    let itemSizeStyle = {};
    let itemSelected =  {};
    let itemSizeStyle_icon =  {};
    let itemSizeStyle_label =  {};
    let posStyle = {}
    let itemStyle = props.itemStyle || {};

    let image_size = [40, 40];
    let image_data = ['icon', 'fas fa-user']
    let label_color = null;
    if (props.item.hasOwnProperty('color')){
        let item_color = props.item.color;
        // item_color.push(props.item.opacity);

        label_color = 'rgba('+item_color[0] +', '+item_color[1] +', '+item_color[2] +', '+ props.item.opacity +')';
    }

    if (props.hasOwnProperty('pos')){
        posStyle['left'] = props.pos[0];
        posStyle['top'] = props.pos[1];
    }

    if (props.itemSize === 'large'){

        itemSizeStyle = {
            // margin: '8px',
            width: '175px',
            padding: '14px 0 0 0',
            minHheight: '148px',

        };
        image_size = [128, 128];
        itemSizeStyle_icon = {
            width:'128px',
            height:'128px',
            fontSize: '88px',
            borderRadius: '8px',

        };
        itemSizeStyle_label = {
            fontSize: '18px',
            maxWidth: '200px',
            marginTop:'7px',
            marginBottom:'7px',
            backgroundColor:label_color,
        };  
    }  
    if (props.itemSize === 'medium'){

        itemSizeStyle = {
            // margin: '6px',
            width: '105px',
            padding: '10px 3px 3px 3px',
            // padding: '10px 2px',
            minHeight: '100px',

        };
        itemSizeStyle_icon = {
            width:'80px',
            height:'80px',
            fontSize: '56px',
            borderRadius: '5px',


        };
        image_size = [80, 80];
        itemSizeStyle_label = {
            fontSize: '14px',
            maxWidth: '152px',
            // marginTop:'5px',
            // marginBottom:'5px',
            backgroundColor:label_color,
        };  
    }  
    
    let is_sel = false;
    if (props.ui_data.control.selected){
        if (props.ui_data.control.selected.hasOwnProperty(props.item_id)) {
            // console.log('avi 1')
            if (props.ui_data.control.selected[props.item_id]['item_type'] === props.item_type){
                // console.log('avi 2')
                is_sel = true;
            }
        }
    }
    // if (props.current_sel[0] === props.item_type) {
    //     if (props.current_sel[1] ===  props.item_id) {

    if (is_sel){
            // //AAA console.log('IS SEL!!!!')
            itemSelected = {
                // outlineStyle: 'solid',
                // borderRadius: '6px',
                // outlineColor: 'white',
                // outlineWidth: '1px',
                // backgroundColor: 'rgba(255, 255, 255, .3)',
                backgroundColor: 'rgba(10, 147, 255, 0.1)',
                // backgroundColor: 'rgba(255, 140, 0, .15)',
                // boxShadow: '0px 0px 0px 2px rgba(255, 255, 255, 1)',
                // boxShadow: '0px 0px 0px 2px rgba(255, 140, 0, 1)',

                
            }
        // }
    }

    if (props.mode === 'pick'){
        let picked_items = props.ui_data.control.picked_items;

        for (let i = 0; i < picked_items.length; i++) { 
            if (picked_items[i][0] === props.item_type && picked_items[i][1] === props.item_id) {
                // if found, then remove this index's item from array and break loop
                itemSelected = {
                    borderRadius: '6px',
                    backgroundColor: 'rgba(210, 147, 255, 0.1)',
                    boxShadow: '0px 0px 0px 2px rgba(255, 255, 255, 1)',

                    
                }
            }

        }
    }


    if (!props.item){
        console.log('ITEM IS UNDEFINED, returning null ', props.item)
        return(null)
    }

    image_data = ['icon', props.item.icon]

    // then test for image
    // should always have image kay, but for legacy I'll test for it
    if (('image' in props.item)){
        if (props.item.image){
            image_data = props.item.image
        }
    }

    
    // const select_function = () => {props.select_item(props.item_type, props.item_id)}
    let item_data = {[props.item_id]:{item_type:props.item_type}}
    const select_function = (event) => {
        props.select_item_c(event, item_data);

        // if (props.mode !== 'link'){
            let linked_doc = null;
            if (props.item.hasOwnProperty('links')){
                if (props.item.links.hasOwnProperty('document')){
                    if (props.item.links.document){
                        linked_doc = props.item.links.document[0];
                    }
                }
            }
            props.set_current_document(linked_doc);
        // }

            // set keyframe cache
            // Currently this workd for single item, I need to figure out how 
            // to support multple selections - where to trigger that?
            let items_and_ids = [[props.item, props.item_id, props.item_type]]
            let timeline = props.ui_data.current.current_timeline;
            props.update_keyframes_cache(items_and_ids, timeline)
    
    }
    const pick_function = () => {props.pick_item_toggle(props.ui_data, props.item_type, props.item_id)}

    const attr_editor_function = () => {
        let extra_data = {
            item_data:props.item,
            item_type:props.item_type,
            item_id:props.item_id,
        }
        props.open_foldout_panel('attr_ed_story_item', props.mode+'-'+props.item_type+'-'+props.item_id, extra_data);
    }




    let onClickFunction = select_function;
    if (props.mode === 'pick'){
        onClickFunction = pick_function 
    }
    if (props.mode === 'attr_editor'){
        onClickFunction = attr_editor_function 
    }




    let doc_icon = [];
    if (props.item.hasOwnProperty('links')){
        if (props.item.links.hasOwnProperty('document')){
            doc_icon = [
                <i style={{}} id='StoryItem-DocumentIconShad' className="fas fa-file-alt"></i>,
                <i style={{}} id='StoryItem-DocumentIcon' className="fas fa-file-alt"></i>
            ]
        }
    }


    return (
        <div className={"StoryItem"}
            id={props.mode + '-'+props.item_type + '-' +props.item_id }
            key={props.key}
            style={Object.assign({}, itemSizeStyle, itemSelected, posStyle, itemStyle)}
            onClick={onClickFunction}
        >
            <div className={"StoryItemIcon"}
            style={itemSizeStyle_icon}
            

            >

                {/* {thumb_element} */}
                <Image width={image_size[0]} height={image_size[1]}  image_data={image_data}/>

                    {/* <i className={props.itemIcon}></i> */}
                    {/* <i className={props.item.icon}></i> */}
                    {/* <img src={require('../../images/jerry.jpg')} /> */}
                </div>
                <div className={"StoryItemLabel"}
                style={itemSizeStyle_label}
                //> {props.itemName} 
                > {props.item.name} 
                {doc_icon}
                {/* <i id='StoryItem-DocumentIconShad' className="fas fa-file-alt"></i> */}

            </div>

        </div>
    )
}

// const test = (props) => {
//     return (
//         [<i style={{}} id='StoryItem-DocumentIconShad' className="fas fa-file-alt"></i>,
//         <i style={{}} id='StoryItem-DocumentIcon' className="fas fa-file-alt"></i>]
//     )
// }

// src={require('../images/jerry.jpg')}
const mapStateToProps = (state, props) => {
    // if (props.item_type === undefined){
    //     return {
    //         current_sel:state.ui_data.current_sel,
    //     }
    // }
    // //AAA console.log('ITEM PROPS', props)
    // //AAA console.log('ITEM TYPE', props.item_type + '_data')
    // //AAA console.log('ITEM ID', props.item_id )
    // //AAA console.log('ITEM NAME', props.name )

    return {
        item:state[props.item_type + '_data'][props.item_id],
        // itemName:state[props.item_type + '_data'][props.item_id]['name'],
        // itemIcon:state[props.item_type + '_data'][props.item_id]['icon'],
        // itemImage:state[props.item_type + '_data'][props.item_id]['image'],
        // current_sel:state.ui_data.current_sel,
        ui_data:state.ui_data,

    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        select_item_c: (event, data) => dispatch(actionCreator_ui.select_item_c(event, data)),
        pick_item_toggle:(ui_data, item_type, item_id) => dispatch(actionCreator_ui.pick_item_toggle(ui_data, item_type, item_id)),
        set_current_document: (doc_id) => dispatch(actionCreator_documents.set_current_document(doc_id)),
        open_foldout_panel: (panel_name, element_id, extra_data) => dispatch(actionCreator_ui.open_foldout_panel(panel_name, element_id, extra_data)),
        update_keyframes_cache: (items_and_ids, timeline) => dispatch(actionCreator_cache.update_keyframes_cache(items_and_ids, timeline)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps )(StoryItem);

