/*
to do :
get all child items bboxes. 
take snapshot of those on mouse down, then use those vals to detect if mouse over a cell
rather than mouse over events and dummy copy of flex box
this will also give the advantage of being able to detect if in empty space in flex
at end of a row (in the middle or after last item) 
It will also enable box selection. yay

write function to return item name when mouse moving through stored bboxes 
- this will be equivelent to mouseover.


- selection bbox
    make container that's bigger than flex so you can start to draw a 
    seletion without moving an item
    Test item bboxes
    Add modifier behavior (same as VP)

- allow for multiple flex containers
    drag an drop between flex containers

*/


import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";

import './ItemGroupContainer.css';
import UberFlexContainer from './UberFlexContainer';
import StoryItem from '../story_item/StoryItem';
import InputField from '../../inputs/InputField';


import * as actionCreator_groups from '../../../../store/actions/actions_groups';
import * as actionCreator_ui from '../../../../store/actions/actions_ui';
// import * as actionCreator_inputs from '../../../../store/actions/actions_inputs';



export default function ItemGroupContainer(props) {


    // const reorder_items_function = props.reorder_items_function || null;
    // const copy_to_group_function = props.copy_to_group_function || null;
    // const move_to_group_function = props.move_to_group_function || null;
    // const select_items_function = props.select_items_function || null;
    const children = props.children || [];
    const name = props.name || ' Group ';
    const group_id = props.group_id;
    const item_width = props.item_width;
    const item_height = props.item_height;
    const item_size = props.item_size || 'medium'
    const item_type = props.item_type || null
    const group_items = props.group_items || []
    const all_group = props.all_group || false

    const dispatch =useDispatch()

    let item_data = useSelector( state => state[item_type + '_data'])
    let group = useSelector( state => state.group_data[group_id])
    let selected = useSelector( state => state.ui_data.control.selected)
    
    const [hoveringOverHeader, setHoveringOverHeader] = useState(false)


    let selected_class = ''
    let name_field_disable = 'GroupNameField-disable'
    let name_placeholder = ''
    if (group_id in selected){
        selected_class = 'item-group-container-parent-selected'
        name_field_disable = ''
        name_placeholder = 'Enter Group Name'

    }

    if (all_group){
        name_field_disable = 'GroupNameField-disable'
        name_placeholder = 'All Group Name'
        name_placeholder = 'All ' + props.item_type[0].toUpperCase() + props.item_type.slice(1) + 's'

    }

    let group_collapsed = false
    let group_name = ''
    if (group) {
        group_collapsed = group.collapsed
        group_name = group.name

    }

    let item_parent_collapsed_class = ''
    let collapsed_arrow_class = 'item-group-container-arrow-down'

    if (group_collapsed){
        item_parent_collapsed_class = 'item-group-container-parent-collapsed';
        collapsed_arrow_class = 'item-group-container-arrow-right'

    }

    let header_style = {}
    let top_div_style = {}

    let group_color = [22, 36, 61]
    let group_opacity = 0.1
    let hover_add = 30
    if(group){
        if (group.hasOwnProperty('color')){
            if(group.color){
                // header_style.backgroundColor= 'rgba('+group.color[0]+','+group.color[1]+','+group.color[2]+', .8)'
                // top_div_style.backgroundColor= 'rgba('+group.color[0]+','+group.color[1]+','+group.color[2]+', .1)'
                group_color = group.color
            }
        }
        if (group.hasOwnProperty('opacity')){
            if(group.opacity){
                group_opacity = group.opacity
            }
        }
    }
    if (hoveringOverHeader){
        group_color = [group_color[0]+hover_add, group_color[1]+hover_add,group_color[2]+hover_add,]
    }

    header_style.backgroundColor=  'rgba('+group_color[0]+','+group_color[1]+','+group_color[2]+','+ group_opacity+')'
    top_div_style.backgroundColor= 'rgba('+group_color[0]+','+group_color[1]+','+group_color[2]+','+ group_opacity*.2+')'


    // function handleSubmit(e) {
    //     dispatch(actionCreator_auth.sign_in_user(email, password))
    // }

    // this will only run once when page loads
    // useEffect(() => {
    //     dispatch(actionCreator_auth.set_auth_message(null))
    //   }, []);





    const enter_header = (e) => {
        // console.log('EEE enter_header', e)
        setHoveringOverHeader(true)
    }
    const leave_header = (e) => {
        // console.log('EEE leave_header', e)
        setHoveringOverHeader(false)
    }

    const select_group = (e) => {
        // console.log('EEE select_group', group_id, e)
        e.stopPropagation()

        let item_data = {[group_id]:{item_type:'group'}}
        dispatch(actionCreator_ui.select_item_c(e, item_data))

    }

    const toggle_collapse = (e) => {
        // console.log('EEE toggle_collapse', group_id)
        e.stopPropagation();
        dispatch(actionCreator_groups.toggle_group_collapsed(group_id))
    }




    const reorder_items_function = (group_items) => {
        // console.log('EEE reorder_items_function', group_items)
        dispatch(actionCreator_groups.set_group_item_list(group_id, group_items))

    }

    const select_items_function = (item_list) => {
        // console.log('EEE select_items_function item_list', item_list)
        // build date for sel ({uid}:{item_type:item_type})
        let data_for_sel = {}
        for (let item_id of item_list){
            data_for_sel[item_id] = {item_type:item_type}
        }
        dispatch(actionCreator_ui.select_items(data_for_sel))
    }

    const move_to_group_function = (source_flex_id, target_flex_id, item_list) => {
        // console.log('EEE  move_to_group_function source_flex_id, target_flex_id,', source_flex_id, target_flex_id,)
        // console.log('EEE move_to_group_function item_list', item_list)
        dispatch(actionCreator_groups.add_items_to_group(target_flex_id, item_list))
        dispatch(actionCreator_groups.remove_items_from_group(source_flex_id, item_list))

    }
    const copy_to_group_function = (source_flex_id, target_flex_id, item_list) => {
        // console.log('EEE copy_to_group_function source_flex_id, target_flex_id,', source_flex_id, target_flex_id,)
        // console.log('EEE copy_to_group_function item_list', item_list)
        dispatch(actionCreator_groups.add_items_to_group(target_flex_id, item_list))
    }

    const add_item_function = (group_id) => {
        // console.log('EEE add_item_function')


        dispatch(actionCreator_ui.open_modal_window('create_'+item_type, {item_type:item_type, group_id:group_id}))
        // dispatch(actionCreator_groups.add_items_to_group(flex_id))
    }



    const populate_group_items = () => {
        let item_array = []
        if (props.group_items)
        for (const item_id of group_items) {
            item_array.push(
                <div id={item_id} key={item_id}>
                    {/* {item_id} */}
                    <StoryItem 
                        key={props.item_type + '-' +item_id}
                        itemSize={item_size}
                        item_type={item_type}
                        item_id={item_id}
                        // mode={this.props.mode}
                    
                    />
                </div>
            )
        }
        // console.log('populate_group_items item_array', item_array)

        return item_array
            
    }


    return (
        <div style={top_div_style} className={'item-group-container-parent ' + item_parent_collapsed_class + ' ' + selected_class}>

            <div 
                style={header_style}
                className="item-group-container-header"
                onClick={select_group}
                onMouseEnter={enter_header}
                onMouseLeave={leave_header}
                id={group_id +'-group-header-div'}
                
            > 
                {/* <div className="item-group-container-header-bg"/> */}
                <div className={'item-group-container-arrow ' + collapsed_arrow_class}
                    onClick={toggle_collapse}
                />

                <InputField 
                    id={group_id+'_nameField'}
                    className={"GroupNameField " + name_field_disable}
                    dataSource={"group_data." + group_id + ".name"}
                    dataTarget={"group_data." + group_id + ".name"}
                    inputType='text' 
                    key={'groupNameField'+group_id}
                    inputPlaceholder={name_placeholder}
                />
                {group_collapsed && (
                    <div id={group_id} className={'item-group-container-collapsed-target uber-flex-drop-target'}/>
                )}

                {/* <div className='item-group-container-label'>
                    {name} 
                </div> */}
            </div>

            {!group_collapsed && (
                <UberFlexContainer 
                    id={group_id}
                    name={group_name}
                    reorder_items_function={reorder_items_function}
                    select_items_function={select_items_function}
                    copy_to_group_function={copy_to_group_function}
                    move_to_group_function={move_to_group_function}
                    add_item_function={add_item_function}
                    // element_list = {elementList}
                    item_width={item_width}
                    item_height={item_height}
                    // drop_target_class='item-group-container-parent'
                >
                    {populate_group_items()}
                </UberFlexContainer>
            )}

            
        </div>
    )

}
