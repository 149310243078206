import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
// import App from './components/app/App';
import AppRoot from './pages/AppRoot';
import registerServiceWorker from './registerServiceWorker';

import { Provider } from 'react-redux';

import reducer_root from './store/reducers/reducer_root';



import { createStore, compose, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';

import * as Constants from './constants/constants';

import { createRoot } from 'react-dom/client';



let composeEnhancer;
if (Constants.DEV_MODE && Constants.DISABLE_REDUX_DEVTOOLS_ON_RELEASE){
    composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}
else{
    composeEnhancer =  compose;
}

const store = createStore(
    reducer_root,
    composeEnhancer(applyMiddleware(thunk)),
);

export default store;


/*
// ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
ReactDOM.render(<Provider store={store}><React.StrictMode><AppRoot /></React.StrictMode></Provider>, document.getElementById('root'));
registerServiceWorker();
*/

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Provider store={store}><React.StrictMode><AppRoot /></React.StrictMode></Provider>);