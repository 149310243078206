
const initial_timelines  ={
    timeline_data:{
        "minutes": {
            units:'minutes',
            current_time:0,
        },
        "pages": {
            units:'pages',
            current_time:0,
        },
        "story_percentage": {
            units:'percentage',
            current_time:0,
            start_time:0,
            end_time:100,
            pos:[100,600],
            scale:1,
        },
        "chronology": {
            units:'time',
            current_time:0,
            start_time:'',
            end_time:'',
        },
        "_time_progress": {
            units:'percent',
            current_time:0,
            start_time:0,
            end_time:100,
            pos:[100,600],
            scale:1,
        },
    },
}


export default initial_timelines