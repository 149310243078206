
/*
The start of an undo system- god knows there will be a much smarter way of doing this

*/
import * as actions_story_items from './actions_story_items';

export const add_to_undo_history = (undo_function_name, undo_function_args, redo_function_name, redo_function_args) => {
    /*

    */
    console.log('AC add_to_undo_history', undo_function_name, undo_function_args, redo_function_name, redo_function_args)

    return dispatch => {
        dispatch({
            type:'ADD_TO_UNDO_HISTORY',
            undo_function_name:undo_function_name,
            undo_function_args:undo_function_args, 
            redo_function_name:redo_function_name,
            redo_function_args:redo_function_args,
        })
    }
};



export const do_undo_redo = (mode, undo_data) => {
    /*

    */
    console.log('AC do_undo', undo_data);


    
    let undo_index = undo_data.index;
    if (mode==='redo') --undo_index;

    if (undo_index < 0){
        console.log('do_undo_redo- cant go less than zero!')
        return {type:'X'};
    }
    let function_name = undo_data.history[undo_index][mode+'_function_name']
    let function_args = undo_data.history[undo_index][mode+'_function_args']
    
    console.log('AC do_undo  function_name, function_args', function_name, function_args );

    return dispatch => {


        if (function_name === 'delete_items_with_undo')
        {
            dispatch(
                actions_story_items.delete_items_with_undo(
                    function_args['item_data'],
                    function_args['project_id'],
                    false
                    )
            )
        }
        if (function_name === 'create_item_with_undo')
        {
            dispatch(
                actions_story_items.create_item_with_undo(
                    function_args['project_id'],
                    function_args['item_type'],
                    function_args['new_uid'],
                    function_args['data_to_set'],
                    false)
            )
        }
        if (mode === 'undo'){
            dispatch({type:'INCRIMENT_UNDO_INDEX'})
        }
        if (mode === 'redo'){
            dispatch({type:'DECRIMENT_UNDO_INDEX'})
        }
    }
};
