import React from 'react';
import './Button.css';
import { connect} from 'react-redux';

// import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'
import new_uid from '../../../utils/new_uid'
import * as actionCreator_inputs from '../../../store/actions/actions_inputs';

function Test (props, e) {

    // console.log('AXAX my button test, props', props)
    // console.log('AXAX my button test e', e)
    e.stopPropagation();

    let onClickCmd =null;
    if (props.onClickFunction){
        // console.log('props.onClickFunction', props.label, props.onClickFunction)
        onClickCmd = props.onClickFunction;
    }
    else {
        onClickCmd = props.onClick;
    }
    onClickCmd(props.dataTarget, props.current_project_id)
}

const Button = (props) => {
    // use this syntax to set defaults for vals
    // var myDefaultValue = props.myDefaultValue || 'hello';
    let rand_id  = 'button_'+new_uid;

    let onClickCmd =null;
    if (props.onClickFunction){
        // console.log('props.onClickFunction', props.label, props.onClickFunction)
        onClickCmd = props.onClickFunction;
    }
    else {
        onClickCmd = props.onClick;
    }

    if (props.buttonType === 'closeButton'){
        return (
            <div style={props.style} className="CloseButtonDiv">
                <div 
                    onClick={onClickCmd.bind(this, props.dataTarget, props.current_project_id)}

                    id={rand_id} 
                    className="CloseButton"
                    //myValToSet= {props.myValToSet}
                    //style={props.style}
                    >
                        <i className="fas fa-times"></i>
                    </div>
                
            </div>
        )
    }
    if (props.buttonType === 'overlayButton'){
        return (
            <div style={props.style} className="OverlayButtonDiv"
                    // onClick={onClickCmd.bind(this,  props.dataTarget, props.current_project_id)}
                    onClick={Test.bind(this,  props)}

                    id={rand_id} 
                    //myValToSet= {props.myValToSet}
                    //style={props.style}
                    >
                    <i className={props.icon}></i>
                    {props.label}

            </div>
        )
    }
    else {
        // this will use  a provided function over the direct setting of state if specified
        let onClickCmd =null;
        if (props.onClickFunction){
            // //AAA console.log('props.onClickFunction', props.onClickFunction)
            onClickCmd = props.onClickFunction;
        }
        else {
           onClickCmd = props.onClick;
        }

        // function onClickCmd = (){

        // }
        let buttonClassName = props.className || "Button";
        return (
            // <div style={props.style} className={buttonClassName}>
                <input 
                    onClick={onClickCmd.bind(this,  props.dataTarget, props.current_project_id)}
                    // onClick={Test.bind(this,  props)}
                    // onMouseOver={props.onMouseOver()}
                    id={rand_id} 
                    className={buttonClassName} 
                    type='button' 
                    value={props.label}
                    //myValToSet= {props.myValToSet}
                    style={props.style}
                />
            // </div>
        )
    }      
}
// }


const mapStateToProps = (state) => {
    return {
        current_project_id:state.ui_data.current.current_project,
    }
}


const mapDispatchToProps = (dispatch, props) => {
    // This takes the first token of a dataTarget and converts it to 
    // an all caps suffix to be used in each reducer. This is to make sure 
    // it works with data in that state slice.
    // then it strips the first token away because in the scope of the 
    // reducer it's not required (won't work)

    // if (!props.dataTarget){
    //     return{
    //         onClick:  (entity, event) => //AAA console.log('no data target!')
    //     }
    // }
    // let splitKeys = props.dataTarget.split(".");
    // let action_suffix = splitKeys[0].toUpperCase() 
    // splitKeys.splice(0, 1);
    // let new_data_target = splitKeys.join(".");


    // return {
    //     onClick:  (event) => dispatch({type:'ON_CLICK__'+action_suffix, event:event, valToSet:props.valToSet, dataTarget:new_data_target}),
    // }
    let do_set_db_data = true;
    if (props.do_set_db_data === 'false'){
        do_set_db_data = false;
    }

    if (!props.dataTarget){
        return{
            onClick:  (entity, event) => console.log('no data target!')
        }
    }
    return {
        // onClick:  (event) => dispatch({type:'ON_CLICK', event:event, valToSet:props.valToSet, dataTarget:props.dataTarget}),
        onClick: (dataTarget, current_project_id, event) => dispatch(actionCreator_inputs.on_click(event, dataTarget, props.valToSet, current_project_id, do_set_db_data)),
    }




};

export default connect(mapStateToProps, mapDispatchToProps )(Button);
