const initial_cards = {
    card_data:{
    //     'uid1':{
    //         pos:[60,50],
    //         size:[294, 196],
    //         item_parent:'topLevel',

    //         time:{
    //             'story_percentage':{
    //                 start_end_time:[5,10],
    //                 row:1,
    //             }
    //         },

    //         title:'chapter1',
    //         synopsis:"Jerry and his girlfriend get rejected from the Soup Nazi's kitchen when they're caught kissing in line. ... Kramer, who befriends the Soup Nazi, gets a new Armoire exactly like the one that was stolen from him.",
    //         links: {
    //             character:['uid1', 'uid2', 'uid3'],
    //             prop:['uid1', 'uid2', 'uid3'],
    //             location:['uid2', 'uid3'],
    //             tag:['uid2', 'uid3'],
    //             document:['uid1']
    //         },
            


    //     },
    //     'uid2':{
    //         pos:[80,320],
    //         size:[294, 196],
    //         item_parent:'topLevel',

    //         time:{
    //             'story_percentage':{
    //                 start_end_time:[10,20],
    //                 row:2,
    //             }
    //         },

    //         title:'Chapter 2',
    //         synopsis:'sfsdfdsfssdf',
    //         links: {
    //             character:['uid3'],
    //             prop:['uid2', 'uid3'],
    //             location:['uid2', 'uid3'],
    //             tag:['uid2', 'uid3'],
    //             document:['uid2']
    //         }


    //     },
    //     'uid3':{
    //         pos:[60,620],
    //         size:[294, 196],
    //         item_parent:'topLevel',

    //         time:{
    //             'story_percentage':{
    //                 start_end_time:[21,30],
    //                 row:3,
    //             }
    //         },

    //         title:'chapter3',
    //         synopsis:'Its a TRAP',
    //         links: {
    //             character:[],
    //             prop:['uid3'],
    //             location:['uid2', 'uid3'],
    //             tag:['uid2', 'uid3'],
    //             document:[],
    //         }
    //     },
    //     'uid4':{
    //         pos:[500,550],
    //         size:[294, 196],

    //         title:'nested',
    //         synopsis:'fgdfgfdgdffP',
    //         item_parent:'card_uid1',

    //         time:{
    //             'story_percentage':{
    //                 start_end_time:[30,34],
    //                 row:2,
    //             }
    //         },


    //         links: {
    //             character:[],
    //             prop:['uid3'],
    //             location:['uid2', 'uid3'],
    //             linked_tag:['uid2', 'uid3'],
    //             document:[],
    //         }
    //     },
    //     'uid5':{
    //         pos:[65,600],
    //         size:[294, 196],

    //         title:'The End',
    //         synopsis:'end of our journey',
    //         item_parent:'card_uid1',
    //         links: {
    //             character:[],
    //             prop:['uid3'],
    //             location:['uid2', 'uid3'],
    //             tag:['uid2', 'uid3'],
    //             document:[],
    //         },
    //     }
    },
};


export default initial_cards