

import new_uid from '../../utils/new_uid';
import templates from '../templates/templates';
import example_project_template from '../templates/example_project_template';
import * as actions_database from './actions_database';
import * as actions_users from './actions_users';
import * as Constants from '../../constants/constants';
import * as database_utils from '../../utils/database_utils'




// export const set_current_project = (project, project_id) => {
//     console.log('AC set_current_project',project, project_id)


export const set_current_project = ( project_id, project_data=null) => {
    // console.log('AC set_current_project', project_id, project_data)

    // event.stopPropagation();

    return dispatch => {
        // event.stopPropagation();
        // let db_path = 'story_app_data/ui_data/current_thread_colour'

        // actions_database.set_db_data(db_path, thread_id, window.SKIP_DATABASE)    
        // .then (

        // going to pull prject data again here in case something has changed since it was in memory 
       //  (as happened with thread - pretty sure this was a bug in not writing project thread link in store. 
       // but still probably good form to pull again for safety)
       // NOTE - TODO: this isn't actually working because load_project_data_from_db is synchronous with  load_project_items_from_db
        //    so i can't rely on it happening first - guess i need a prmise or thunk or something
        // let db_path = Constants.DB_PROJECT_DATA


        if (project_id) {
            // dispatch(actions_database.load_project_data_from_db([project_id], db_path));
            dispatch(actions_database.load_project_items_from_db_B(project_id));
            // dispatch(actions_database.load_project_items_from_db(project, project_id));
            // dispatch(actions_database.load_project_thread_from_db(project, project_id));

            // dispatch(actions_database.load_project_thread_from_db_B(project, project_id));


            dispatch( { type: "SET_CURRENT_VP", 
                vp_type:'flow_viewport', 
                vp_id:'project_'+project_id,
            })

            if (project_data){
                // console.log('set_current_project - project_data', project_data )
                dispatch(set_project_last_opened(project_id, project_data));
            }


        }
        else{
            // if setting current to null then don't do database stuff above
            dispatch( { 
                type: "SET_CURRENT_PROJECT", 
                project_id:null,
            });
            dispatch(actions_database.reset_redux_data());
           
        }

            
        // )
    }
}


// export const create_new_project_old = ( context, user_data, project_name) => {
//     console.log('AC create_new_project', context, user_data, project_name)

//     return dispatch => {
//         let uid = 'newProject_'+new_uid();
//         let db_path = Constants.DB_ROOT + '/project_data/'+uid;
//         let user_id = context.user;
//         //get card data from template and update pos and parent
//         let project_data = {...templates['project']};
//         console.log('AA project_data', project_data)
//         project_data['name'] = project_name;
//         project_data['users'] = {[user_id]:true};

//         console.log('project_data', project_data)
//         // database.ref(db_path).set(card_data)
//         actions_database.set_db_data(db_path, project_data, window.SKIP_DATABASE)    
//         .then (
//             dispatch( { type: "NEW_PROJECT", 
//                 new_uid:uid, 
//                 project_data:project_data,
//                 user_id:user_id,
//             }),
//             dispatch( { 
//                 type: "SET_CURRENT_PROJECT", 
//                 project_id:uid,
//             }),
//             // link new project to user
//             //TODO: flesh this out
//             dispatch(actions_users.link_project_to_user(context, user_data, uid)),

//             // Hmmmm this is a problem... i don't have project_oject yet... how do i make sure i can wait and get it?
//             //  or if i switch from arrays to objects for links i wont need to provide project object to link
//                 // i think this is the right way to go.

//             // ACTUALLY... for the moment i should be able to feed an empty object as the proj because i' not adding to existing list.
//             // I ended up making a custom version of the create vp action native to projects to avoid circ dependendcy
//             dispatch(
//                 create_flow_viewport_project(uid)
//             )
//         )
//     }


// }


export const delete_project = (thread_id, connection_id) => {
    console.log('AC delete_project', thread_id, connection_id )
    // return dispatch => {
    //     // event.stopPropagation();
    //     let db_path = 'story_app_data/thread_data/'+thread_id+'/connections/'+connection_id

    //     actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
    //     .then (
    //         dispatch( { 
    //             type: "DELETE_THREAD", 
    //             thread_id:thread_id,
    //             connection_id:connection_id,
    //         }),
    //     )
    // }
}





// export const link_to_project = (project_object, project_id, to_link_type, to_link_ids) => {
//     console.log('AC link_to_project', project_object, project_id, to_link_type, to_link_ids)
//     return dispatch => {

//         console.log('AC link_to_project B',)

//         // TODO need to test if to_link_type is a key of item_data['links']. if not create it.
//         let all_link_ids = [];
//         if (project_object.hasOwnProperty('links')){
//             if (project_object.links.hasOwnProperty(to_link_type)){
//                 console.log('   ---- about to spread this: ', project_object['links'][to_link_type])
//                 all_link_ids = [...project_object['links'][to_link_type]];
//             } 
//         }

//         // let all_link_ids = item_data['links'][to_link_type];
//         console.log('all_link_ids', all_link_ids)
//         // let new_link_id_array = [];
//         // loop through all links to add and add them to array if they dont exist
//         for(var i=0; i < to_link_ids.length; ++i) {
//             if (!all_link_ids.includes(to_link_ids[i])){
//                     console.log('link_item - adding this one!', to_link_ids[i])

//                     all_link_ids.push(to_link_ids[i])
//             }
//         }

//         console.log('all_link_ids', all_link_ids)

//         let db_path = Constants.DB_PROJECT_DATA + '/'+project_id+'/links/'+to_link_type
//         actions_database.set_db_data(db_path, all_link_ids, window.SKIP_DATABASE)    
//         .then (
//             dispatch( { 
//                 type: "LINK_ITEMS", 
//                 item_type:'project',
//                 item_id:project_id,
//                 to_link_type:to_link_type,
//                 to_link_ids:all_link_ids,
//             }),
//         )
//     }

// };


// export const link_to_project_b = (context, to_link_type, to_link_ids) => {
//     console.log('AC link_to_project', context, to_link_type, to_link_ids)
//     return dispatch => {

//         console.log('AC link_to_project B',)

//         // TODO need to test if to_link_type is a key of item_data['links']. if not create it.
//         let all_link_ids = [];

//         if (context.project.hasOwnProperty('links')){
//             if (context.project.links.hasOwnProperty(to_link_type)){
//                 all_link_ids = [...context.project.links[to_link_type]];
//             }
//         }

//         // let all_link_ids = item_data['links'][to_link_type];
//         console.log('all_link_ids', all_link_ids)
//         // let new_link_id_array = [];
//         // loop through all links to add and add them to array if they dont exist
//         for(var i=0; i < to_link_ids.length; ++i) {
//             if (!all_link_ids.includes(to_link_ids[i])){
//                     console.log('link_item - adding this one!', to_link_ids[i])

//                     all_link_ids.push(to_link_ids[i])
//             }
//         }

//         console.log('all_link_ids', all_link_ids)

//         let project_id = context.project.uid
//         let db_path = Constants.DB_PROJECT_DATA + '/'+project_id+'/links/'+to_link_type
//         actions_database.set_db_data(db_path, all_link_ids, window.SKIP_DATABASE)    
//         .then (
//             dispatch( { 
//                 type: "LINK_ITEMS", 
//                 item_type:'project',
//                 item_id:project_id,
//                 to_link_type:to_link_type,
//                 to_link_ids:all_link_ids,
//             }),
//         )
//     }

// };





// export const link_thread_connection_to_project = (project_object, project_id, thread_id, con_id) => {
//     console.log('AC link_to_project', project_object, project_id, thread_id, con_id)
//     return dispatch => {


//         // TODO need to test if to_link_type is a key of item_data['links']. if not create it.
//         // let thread_data = {};

//         // // if (project_object.hasOwnProperty('thread')){
//         // //     if (project_object.thread.hasOwnProperty(thread_id)){
//         // //         thread_data = [...project_object.thread[thread_id]];
//         // //     } 
//         // // }

//         // // can i just construct data and then just set it?
//         // thread_data = {
//         //     [thread_id]:{
//         //         [con_id]: true,
//         //     }
//         // }

        
//         let db_path = Constants.DB_PROJECT_DATA + '/'+project_id+'/thread/'+thread_id+'/'+con_id;
//         actions_database.set_db_data(db_path, true, window.SKIP_DATABASE)    

//         // // let all_link_ids = item_data['links'][to_link_type];
//         // console.log('all_link_ids', all_link_ids)
//         // // let new_link_id_array = [];
//         // // loop through all links to add and add them to array if they dont exist
//         // for(var i=0; i < to_link_ids.length; ++i) {
//         //     if (!all_link_ids.includes(to_link_ids[i])){
//         //             console.log('link_item - adding this one!', to_link_ids[i])

//         //             all_link_ids.push(to_link_ids[i])
//         //     }
//         // }

//         // console.log('all_link_ids', all_link_ids)

//         // let db_path = 'story_app_data/project_data/'+project_id+'/links/'+to_link_type
//         // actions_database.set_db_data(db_path, all_link_ids, window.SKIP_DATABASE)    
//         // .then (
//         //     dispatch( { 
//         //         type: "LINK_ITEMS", 
//         //         item_type:'project',
//         //         item_id:project_id,
//         //         to_link_type:to_link_type,
//         //         to_link_ids:all_link_ids,
//         //     }),
//         // )
//     }

// };



/**
 * A Modified version of create_flow_viewport
 * The main reason was to avoid a circular dependency - eg, cant import from viewport because its is importing from proj
 * Is simpler because it can derive all inupts from project_id
 * 
 * Creates a new flow viewport - adds to DB and kicks off MAKE_NEW_VP reducer
 * @param {string} project_id - project uid

 */
export const create_flow_viewport_project = (project_id) => {

    console.log('AC create_flow_viewport_project', project_id )
    return dispatch => {
        let uid = 'project_'+project_id
        // let db_path = Constants.DB_ROOT + '/flow_viewport_data/'+uid
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'flow_viewport', uid);


        let flow_viewport_data = {...templates['flow_viewport']};

        actions_database.set_db_data(db_path, flow_viewport_data, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "CREATE_NEW_FLOW_VP", 
                uid:uid,
                data:flow_viewport_data,
            }),

            // link new item to project
            // dispatch(link_to_project({}, project_id, 'flow_viewport', [uid])),

            // dispatch( { type: "SET_CURRENT_VP", 
            //     vp_type:'flow_viewport', 
            //     vp_id:uid,
            // }),
        )

    }
};

export const create_timeline_project = (project_id) => {

    console.log('AC create_timeline_project', project_id )
    return dispatch => {
        // let uid = 'project_'+project_id
        let uid = '_time_progress'
        // let db_path = Constants.DB_ROOT + '/flow_viewport_data/'+uid
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'timeline', uid);


        let timeline_data = {...templates['timeline']};

        actions_database.set_db_data(db_path, timeline_data, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "CREATE_NEW_TIMELINE", 
                uid:uid,
                data:timeline_data,
            }),
        )

    }
};




export const set_project_last_opened = ( project_id, project_data) => {
    // console.log('AC set_project_last_opened', project_id)

    return dispatch => {

        let opened_dates_array = [];
        if (project_data.hasOwnProperty('opened_dates') ){
            opened_dates_array = [...project_data.opened_dates];
        }

        let date = new Date().toUTCString();
        opened_dates_array.unshift(date);

        let db_path = Constants.DB_ROOT + '/projects/'+project_id+'/opened_dates';
        actions_database.set_db_data(db_path, opened_dates_array, window.SKIP_DATABASE) 
        .then (
            dispatch( { 
                type: "SET_PROJECT_LAST_OPENED_DATE", 
                uid:project_id,
                date: opened_dates_array,
            })
        )
    }
}


export const create_example_projectX = ( user_id) => {
    // console.log('AC create_example_project', user_id)
    // console.log('AC create_example_projectXX', user_id)


    return dispatch => {
        console.log('AC create_example_project B', user_id)
        dispatch( { type: "X", 
            })
    }
}



export const create_example_project = ( user_id, user_data=null) => {
    // console.log('AC create_example_project', user_id)
    // console.log('AC create_example_project user_data', user_data)

    return dispatch => {
        console.log('AC create_example_project B', user_id)

        let uid = 'exampleProject_'+user_id;

        // test if user already has an example project. if so, skip
        let all_link_ids = [];
        // if (user_data){
        //     if (user_data[user_id].hasOwnProperty('links')){
        //         if (user_data[user_id].links.hasOwnProperty('project')){
        //                 all_link_ids = [...user_data[user_id].links.project];
        //         }
        //     }
        // }
        // if (all_link_ids.includes(uid)){
        //     console.log('Example project already exists for this user!');
        //     return {};
        // }


        let db_path_data = Constants.DB_ROOT + '/project_data/'+uid;

        let project_data = {...example_project_template};

        // re link to new proj id
        for (let index = 0; index < Object.keys(project_data['card_data']).length; index++) {
            const item_id = Object.keys(project_data['card_data'])[index];
            if  (project_data['card_data'][item_id]['item_parent'] === 'project_'+project_data.uid){
                project_data['card_data'][item_id]['item_parent'] = 'project_'+uid
            }
        }

        for (let index = 0; index < Object.keys(project_data['region_data']).length; index++) {
            const item_id = Object.keys(project_data['region_data'])[index];
            if  (project_data['region_data'][item_id]['item_parent'] === 'project_'+project_data.uid){
                project_data['region_data'][item_id]['item_parent'] = 'project_'+uid
            }
        }

        // make copy of viewports named to match new proj
        for (let index = 0; index < Object.keys(project_data['flow_viewport_data']).length; index++) {
            const item_id = Object.keys(project_data['flow_viewport_data'])[index];
            if  (item_id === 'project_'+project_data.uid){
                let vp_data = {...project_data['flow_viewport_data'][item_id]}
                project_data['flow_viewport_data']['project_'+uid] = vp_data;
            }
        }


        project_data['users'] = {[user_id]:true};
        project_data['created_by'] = user_id;
        project_data['creation_date'] = new Date().toUTCString();

        console.log('create_example_project project_data', project_data)


        // THIS IS A BIT HACKY BUT POLPULATING SIMPLE DATA FOR projects/uid (not project_data/uid)
        let db_path = Constants.DB_ROOT + '/projects/'+uid;
        let project_data_simple = {...templates['project']};
        project_data_simple['name'] = 'Example Project';
        project_data_simple['users'] = {[user_id]:true};
        project_data_simple['created_by'] = user_id;
        project_data_simple['creation_date'] = new Date().toUTCString();

        console.log('project_data_simple', project_data_simple)



        actions_database.set_db_data(db_path, project_data_simple, window.SKIP_DATABASE) 
        .then (
            actions_database.set_db_data(db_path_data, project_data, window.SKIP_DATABASE)    
            .then (
                dispatch( { type: "NEW_PROJECT", 
                    new_uid:uid, 
                    project_data:project_data,
                    user_id:user_id,
                })
            )
        )

        // link new project to user
        //TODO: flesh this out
        // dispatch(actions_users.link_project_to_user(context, user_data, uid)),

        // WIP 
        // taking this out of actions_users

        // This is not so pretty - if user data provided, then find linked projects aray and add to it
        
        
        if (user_data){
            if (user_data[user_id].hasOwnProperty('links')){
                if (user_data[user_id].links.hasOwnProperty('project')){
                        all_link_ids = [...user_data[user_id].links.project];
                }
            }
            console.log('all_link_ids PRE', all_link_ids)

            // add project id to list of link_ids
            if (!all_link_ids.includes(uid)){
                console.log('link_item - adding this one!', uid)

                all_link_ids.push(uid)
            }
            else{
                console.log('Example project already exists for this user!');
                return {};
            }
        }
        else{
            all_link_ids = [uid];
        }
        console.log('create_example_project - all_link_ids', all_link_ids);
        db_path = Constants.DB_USER_DATA+'/'+user_id+'/links/project'
        actions_database.set_db_data(db_path, all_link_ids, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "LINK_ITEMS", 
                item_type:'user',
                item_id:user_id,
                to_link_type:'project',
                to_link_ids:all_link_ids,
            }),
        )
    }

}





export const create_new_project = ( context, user_data, project_name) => {
    // console.log('AC create_new_project', context, user_data, project_name)

    return dispatch => {
        let uid = 'newProject_'+new_uid();
        let db_path = Constants.DB_ROOT + '/projects/'+uid;
        let db_path_data = Constants.DB_ROOT + '/project_data/'+uid;

        let user_id = context.user;
        //get card data from template and update pos and parent
        let project_data = {...templates['project']};
        console.log('AA project_data', project_data)
        project_data['name'] = project_name;
        project_data['users'] = {[user_id]:true};
        project_data['created_by'] = user_id;
        project_data['creation_date'] = new Date().toUTCString();

        console.log('project_data', project_data)



        actions_database.set_db_data(db_path, project_data, window.SKIP_DATABASE)    
        // .then (
        //     dispatch( { type: "NEW_PROJECT", 
        //         new_uid:uid, 
        //         project_data:project_data,
        //         user_id:user_id,
        //     }),

        // )
        .then (
            actions_database.set_db_data(db_path_data, project_data, window.SKIP_DATABASE)    
            .then (
                dispatch( { type: "NEW_PROJECT", 
                    new_uid:uid, 
                    project_data:project_data,
                    user_id:user_id,
                }),
                // this is new. don't know why i need this now - it used to work ok?
                dispatch( { type: "SET_PROJECTS", 
                    uid:uid, 
                    projects:project_data,
                }),
                dispatch( { 
                    type: "SET_CURRENT_PROJECT", 
                    project_id:uid,
                }),
                // link new project to user
                //TODO: flesh this out
                dispatch(actions_users.link_project_to_user(context, user_data, uid)),

                // Hmmmm this is a problem... i don't have project_oject yet... how do i make sure i can wait and get it?
                //  or if i switch from arrays to objects for links i wont need to provide project object to link
                    // i think this is the right way to go.

                // ACTUALLY... for the moment i should be able to feed an empty object as the proj because i' not adding to existing list.
                // I ended up making a custom version of the create vp action native to projects to avoid circ dependendcy
                dispatch(
                    create_flow_viewport_project(uid)
                ),
                dispatch(
                    create_timeline_project(uid)
                )
            )
        )
    }
}

