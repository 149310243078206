/*
Groups are collections of items
*/


import * as actions_database from './actions_database';
import * as Constants from '../../constants/constants';
import * as database_utils from '../../utils/database_utils'
import templates from '../templates/templates';
import new_uid from '../../utils/new_uid';

export const toggle_group_collapsed = (group_id) => {
    // console.log('---EEE AC set_group_item_list', group_id )

    return (dispatch, getState) => {

        let project_id = getState().context_data.project;
        let group = getState().group_data[group_id];

        let group_collapsed
        if (group) group_collapsed = group.collapsed
        else group_collapsed= false


        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'group', group_id) +'/collapsed';
        actions_database.set_db_data(db_path, !group_collapsed, window.SKIP_DATABASE)    
        
        dispatch( { 
            type: "SET_GROUP_COLLAPSED", 
            group_id:group_id,
            collapsed:!group_collapsed,
        })

    }
}


export const set_group_item_list = (group_id, item_list) => {
    // console.log('---EEE AC set_group_item_list', group_id, item_list )

    return (dispatch, getState) => {

        let project_id = getState().context_data.project;
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'group', group_id) +'/items';
        actions_database.set_db_data(db_path, item_list, window.SKIP_DATABASE)    
        
        dispatch( { 
            type: "SET_GROUP_ITEM_LIST", 
            group_id:group_id,
            item_list:item_list,
        })

    }
}

export const add_items_to_group = (group_id, item_list) => {
    // console.log('---EEE AC add_items_to_group', group_id, item_list )
    if (group_id.startsWith('__ALL__')){
        return {type:'X'}
    }
    return (dispatch, getState) => {


        let group_items = getState().group_data[group_id].items || [];
        let project_id = getState().context_data.project;

        // console.log('---EEE AC add_items_to_group - group_items', group_items )
        // console.log('---EEE AC add_items_to_group - project_id', project_id )


        let new_item_list = [...group_items]
        for (const item_id of item_list) {
            if (!new_item_list.includes(item_id)){
                new_item_list.push(item_id) 
            }
        }

        // let db_path = Constants.DB_ROOT + '/card_data/'+dragging[1]+'/time/'+current_timeline+'/'
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'group', group_id) +'/items';

        actions_database.set_db_data(db_path, new_item_list, window.SKIP_DATABASE)    
        
        dispatch( { 
            type: "SET_GROUP_ITEM_LIST", 
            group_id:group_id,
            item_list:new_item_list,
        })
    }
}
export const remove_items_from_group = (group_id, item_list) => {
    // console.log('---EEE AC remove_items_from_group', group_id, item_list )

    return (dispatch, getState) => {

        let group_items = getState().group_data[group_id].items || [];
        let project_id = getState().context_data.project;

        // console.log('---EEE AC remove_items_from_group - group_items', group_items )
        // console.log('---EEE AC remove_items_from_group - project_id', project_id )

        let new_item_list = [...group_items]
        for (const item_id of item_list) {
            if (new_item_list.includes(item_id)){

                let index_to_remove = new_item_list.indexOf(item_id)
                new_item_list.splice(index_to_remove, 1)
            }
        }
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'group', group_id) +'/items';
        actions_database.set_db_data(db_path, new_item_list, window.SKIP_DATABASE)    
        dispatch( { 
            type: "SET_GROUP_ITEM_LIST", 
            group_id:group_id,
            item_list:new_item_list,
        })
    }
}



// create item
export const create_group_with_undo = (project_id, group_item_type, item_list, set_undo=true) => {
    // console.log('UU AC create_group_with_undo',project_id)
    return dispatch => {


        // let undo_dispatch_data = {type:'X'}
        // if (set_undo){
        //     undo_dispatch_data = {
        //         type:'ADD_TO_UNDO_HISTORY',
        //         undo_function_name:'delete_items_with_undo',
        //         undo_function_args:{
        //             item_data:{
        //                 [new_uid]:{item_type:item_type}
        //             },
        //             project_id:project_id,
        //             set_undo:false,
        //         }, 
        //         redo_function_name:'create_item_with_undo',
        //         redo_function_args:{
        //             project_id:project_id,
        //             item_type:item_type,
        //             new_uid:new_uid,
        //             data_to_set:data_to_set,
        //             set_undo:false,
        //         },    
        //     }
        // }
        let uid = 'newGroup_' + new_uid();

        let group_data = {...templates['group']};
        group_data['type'] = group_item_type
        if (item_list){
            group_data['items'] = item_list
        }

        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'group', uid);

        actions_database.set_db_data(db_path, group_data, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "CREATE_GROUP", 
                new_uid:uid,
                group_data:group_data,
            }),
            
        )

        .then (
            dispatch( { 
                type: "SELECT_ITEM_C", 
                data:  {[uid]:{item_type:'group'}},
                select_mode: 'replace',
            })
        )
        .then (
            dispatch( { 
                type: "SET_FOCUS", 
                element_id:uid + '_nameField',
            })
        )
        // .then (
        //     dispatch( undo_dispatch_data )
        // )

    }
};

// export const delete_items_with_undo = (item_data, project_id, set_undo=true) => {
//     // this is an alternate delete item which can deal with array sel

//     console.log('AC delete_item B', item_data )
//     return dispatch => {

//         // event.stopPropagation();

//         Object.keys(item_data).map((item_id) => {
//             let item_type = item_data[item_id]['item_type']
//             let data_to_set = item_data[item_id];

//             // TODO  I need to find a way to do this outside a loop
//             //  how do I make actions_database.remove_db_data work on multiple items/paths?
//             let undo_dispatch_data = {type:'X'}
//             if (set_undo){
//                 undo_dispatch_data = {
//                     type:'ADD_TO_UNDO_HISTORY',
//                     undo_function_name:'create_item_with_undo',
//                     undo_function_args:{
//                         project_id:project_id,
//                         item_type:item_type,
//                         new_uid:item_id,
//                         data_to_set:data_to_set,
//                         set_undo:false,
//                     },
//                     redo_function_name:'delete_items_with_undo',
//                     redo_function_args:{
//                         item_data:{
//                             [item_id]:{item_type:item_type}
//                         },
//                         project_id:project_id,
//                         set_undo:false,
//                     }, 
//                 }
//             }
//             console.log('undo_dispatch_data', undo_dispatch_data)
//             // event.stopPropagation();
//             // let db_path = Constants.DB_ROOT +'/'+item_type+'_data/'+item_id
//             let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, item_type, item_id);

//             actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
//             .then (
//                 dispatch( { 
//                     type: "DELETE_ITEM", 
//                     item_type:item_type,
//                     item_id:item_id,
//                 }),
//                 dispatch({  
//                     type: "SELECT_ITEM_C", 
//                     data:{},
//                 })
//             )
//             .then (
//                 dispatch( undo_dispatch_data)
//             )
//         })
//         //TODO Turn this back on - only turned off for testing
//         // dispatch({  
//         //     type: "SELECT_ITEM_C", 
//         //     data:null,
//         // })
//     }
// };
