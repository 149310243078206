import React, { Component } from 'react';
import './Region.css';
// import jQuery;
import { connect} from 'react-redux';
// import InputField from '../../inputs/InputField';

import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
import * as actionCreator_viewports from '../../../../store/actions/actions_viewports';
import * as actionCreator_viewport_xforms from '../../../../store/actions/actions_viewport_xforms';
import * as actionCreator_inputs from '../../../../store/actions/actions_inputs';


class Region extends Component {

    constructor(props) {
        super(props);
        this.handleRegionMouseDown = this.handleRegionMouseDown.bind(this);
        this.handleRegionMouseDown_scale = this.handleRegionMouseDown_scale.bind(this);
        this.handleMagnetToggle = this.handleMagnetToggle.bind(this);
    }
 
    handleRegionMouseDown(event) {
        // console.log('\n\n  handleRegionMouseDown event ', event)
        // console.log('\n\n  handleRegionMouseDown event done \n\n')

        event.stopPropagation();

        // the order of these is important!
        this.props.onMouseDown(event, 'region', this.props.item_id,);
        this.props.onMouseDown_VP(event, this.props.ui_data, this.props.viewport_data);
        this.props.mouse_down_vp_xform(event, this.props.item_type, this.props.item_id,  this.props.region_data[this.props.item_id], this.props.ui_data);

    }

    handleRegionMouseDown_scale(event) {

        event.stopPropagation();

        // the order of these is important!
        this.props.onMouseDown(event, 'region_scale', this.props.item_id,);
        this.props.onMouseDown_VP(event, this.props.ui_data, this.props.viewport_data);
        this.props.mouse_down_vp_xform(event, this.props.item_type+'_scale', this.props.item_id,  this.props.region_data[this.props.item_id], this.props.ui_data);
    }

    handleMagnetToggle(event){
        event.stopPropagation();

        let project_id = this.props.ui_data.current.current_project;
        let dataTarget = "region_data." + this.props.item_id + '.magnetic';
        let magnetic_val_to_set = true;
        if(this.props.region_data[this.props.item_id].hasOwnProperty('magnetic'))
        {
            // console.log('handleMagnetToggle HERE!!!!')
            magnetic_val_to_set = !this.props.region_data[this.props.item_id].magnetic;
        }
        // console.log('handleMagnetToggle this.props.item_id', this.props.item_id)
        // console.log('handleMagnetToggle project_id', project_id)
        this.props.set_val_at_target( dataTarget, magnetic_val_to_set, project_id)
    }

    magnet_switch(){
        return [
            <i id='regionMagnet' className="fas fa-magnet"></i>,
            <i id='regionMagnetShadow' className="fas fa-magnet"></i>
        ]
    }
    render() {
        
        let apply_hover_class = '';
        let color_array = [0,0,0,.25];
        let opacity = 1.0;
        let style = {
            left:this.props.region_data[this.props.item_id]['pos'][0],
            top:this.props.region_data[this.props.item_id]['pos'][1],
            width:this.props.region_data[this.props.item_id]['size'][0],
            height:this.props.region_data[this.props.item_id]['size'][1],
        }
        // let style = {
        //     left:this.props.region_data[this.props.item_id]['pos'][0] + '.px',
        //     top:this.props.region_data[this.props.item_id]['pos'][1] + '.px',
        //     width:this.props.region_data[this.props.item_id]['size'][0] + '.px',
        //     height:this.props.region_data[this.props.item_id]['size'][1] + '.px',
        // }

        // if (this.props.region_data[this.props.item_id].colour){
        //     style['backgroundColor']=this.props.region_data[this.props.item_id].colour;
        // }
        if (this.props.region_data[this.props.item_id].color){
            color_array[0] = this.props.region_data[this.props.item_id].color[0];
            color_array[1] = this.props.region_data[this.props.item_id].color[1];
            color_array[2] = this.props.region_data[this.props.item_id].color[2];
        }
        if (this.props.region_data[this.props.item_id].opacity){
            color_array[3] = this.props.region_data[this.props.item_id].opacity;
            opacity = this.props.region_data[this.props.item_id].opacity;
        }
        // style['backgroundColor']='rgba('+color_array[0].toString() + ',' + color_array[1].toString() + ',' + color_array[2].toString() + ',' + color_array[3].toString() +')'
        style['backgroundColor']='rgba('+color_array[0].toString() + ',' + color_array[1].toString() + ',' + color_array[2].toString() + ',' + opacity +')';
        // style['opacity']=opacity;



        // if selected, change style
        if (this.props.ui_data.control.selected){
            if (this.props.item_id in this.props.ui_data.control.selected){
                style['boxShadow'] = '0px 0px 0px 4px rgba(255, 255, 255, 1)';
                apply_hover_class = 'ApplyHover';
            }
        }

          // if dragging, change style
          if (this.props.ui_data.control.dragging_b){
            if (this.props.item_id in this.props.ui_data.control.dragging_b){
                style['pointerEvents'] = 'none';
            }
        }      

        // test if in selection bbox
        let sel_bbox = this.props.ui_data.control.select_bbox;
        if (sel_bbox){
            let item_bbox = [
                this.props.region_data[this.props.item_id]['pos'][0],
                this.props.region_data[this.props.item_id]['pos'][1],
                this.props.region_data[this.props.item_id]['pos'][0] + this.props.region_data[this.props.item_id]['size'][0],
                this.props.region_data[this.props.item_id]['pos'][1] + this.props.region_data[this.props.item_id]['size'][1],
            ]
            if (
                sel_bbox[0] <= item_bbox[2] &&
                sel_bbox[1] <= item_bbox[3] &&
                sel_bbox[2] >= item_bbox[0] &&
                sel_bbox[3] >= item_bbox[1]
                ){
                    style['boxShadow'] = '0px 0px 0px 4px rgba(255, 255, 255, .5)';
            }
        }

        let image_div = null;
        if (this.props.region_data[this.props.item_id].image){
            image_div = <img alt='' className='TestImage' style={{pointerEvents:'none', 'opacity':opacity}} 
                src={this.props.region_data[this.props.item_id].image}
            />
            style['backgroundColor']='none';
            style['opacity']=1.0;

        }

        // let magnet_on_class = '';
        // let magnet_style = {};



        let scale_compensate = .4;
        // let header_scale = Math.round((((1.0 / this.props.viewport_data.scale) - 1) * scale_compensate) + 1);
        let header_scale = (((1.0 / this.props.viewport_data.scale) - 1) * scale_compensate) + 1;

        let top_div_style = {
            height: header_scale * 50,
            fontSize: header_scale * 50,
        };

        let magnet_style = {
            fontSize: header_scale * 30,
            width:header_scale *50,
            height:header_scale *50,
            top:header_scale *2,
            right:header_scale *4,
            padding:header_scale *10,

        }
        if (this.props.region_data[this.props.item_id].magnetic){
            // magnet_on_class = 'regionMagnetOn';
            magnet_style['color'] = 'rgba(255, 255, 255, .5)';
            magnet_style['opacity'] = 1;
        }

        let scale_style = {
            right:header_scale *-9,
            borderLeft:  String(Math.round(header_scale *20)) + 'px solid transparent',
            borderRight:  String(Math.round(header_scale *20)) + 'px solid transparent',
            borderBottom:  String(Math.round(header_scale *20)) + 'px solid white',
        }
        return (
            <div className="RegionDiv"
                item_id={this.props.item_id}
                item_type='region'
                style={style}
            >
                {/* <img className='TestImage' style={{pointerEvents:'none'}} 
                    src='https://firebasestorage.googleapis.com/v0/b/avi-firebase-project.appspot.com/o/croc01_2.png?alt=media&token=39096094-e489-4baf-b6c3-60a6a23d4e6e'
                />, */}
                {image_div}
                {this.props.children}
                <div className={"RegionTopDiv " + apply_hover_class}
                     onMouseDown={this.handleRegionMouseDown}
                     onTouchStart={this.handleRegionMouseDown}
                     style={top_div_style}
                     //onPointerStart={this.handleRegionMouseDown}

                >
                    {/* {this.magnet_switch} */}
                    {/* <i id='regionMagnetShadow' className="fas fa-magnet fa-flip-vertical"></i> */}

                    {this.props.region_data[this.props.item_id].name}
                </div>
                <i id='regionMagnet' className="fas fa-magnet fa-rotate-270 "
                        style={magnet_style}
                        onClick={this.handleMagnetToggle}
                    />
                <div item_type='region_scale' 
                    onMouseDown={this.handleRegionMouseDown_scale} 
                    onTouchStart={this.handleRegionMouseDown_scale}
                    className='RegionScaleHandle' 
                    style={scale_style}
                />

            </div>
        );
    };
}

const mapStateToProps = (state) => {
    let current_vp = state.ui_data.current.current_viewport;
    return {
        // dragging:state.ui_data.control.dragging,
        // hovering:state.ui_data.control.hovering,
        // begin_drag:state.ui_data.control.begin_drag,
        region_data:state.region_data,
        // thread_data:state.thread_data,
        viewport_data:state[current_vp[0]+'_data'][current_vp[1]],
        ui_data:state.ui_data,
        // current_vp:state.ui_data.current.current_viewport,
        // current_thread_colour:state.ui_data.current.current_thread_colour,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        onMouseDown_VP: (event, ui_data, viewport_data) => dispatch(actionCreator_viewports.mouse_down_viewport(event, ui_data, viewport_data)),
        mouse_down_vp_xform: (event, item_type, item_id, item, ui_data) => dispatch(actionCreator_viewport_xforms.mouse_down_vp_xform(event, item_type, item_id, item, ui_data)),
        set_val_at_target: ( dataTarget, val_to_set, project_id) => dispatch(actionCreator_inputs.set_val_at_target(dataTarget, val_to_set, project_id)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps )(Region);

