import React from 'react';
import './ProgressBar.css';

const ProgressBar = (props) => {
   
    let progress_percent = props.progress +'%' || '0%';
    let style = props.style || {};
    return (

        <div style={style} className='ProgressBarOuterDiv'>
            <div style={{width:progress_percent}} className='ProgressBarInnerDiv'/>
            {/* <p className='ProgressBarText' > test text </p> */}
        </div>
    )
}


export default ProgressBar;