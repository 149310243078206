
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator_auth from '../store/actions/actions_auth';
import { Link } from 'react-router-dom';

import UserSettings from '../components/modal_panels/UserSettings';
import './LoginPage.css';


// import InputField from '../components/ui_elements/inputs/InputField';
// import Button from '../components/ui_elements/inputs/Button';


import bg_image from '../images/landing_page_bg_01.jpg';

// ...later


export default function UserSettingsPage() {

    // const [email, setEmail] = useState()
    // const [password, setPassword] = useState()
    // const [passwordConfirm, setPasswordConfirm] = useState()
    // const [loading, setLoading] = useState()
    // const { currentUser} = useAuth()
    // const history = useHistory()

    // const dispatch =useDispatch()

    // let auth_ui_message = useSelector( state => state.ui_data.auth_ui_message)

    // function handleSubmit(e) {
    //     dispatch(actionCreator_auth.sign_in_user(email, password))
    // }

    // // this will only run once when page loads
    // useEffect(() => {
    //     dispatch(actionCreator_auth.set_auth_message(null))
    //   }, []);

    // let auth_message_element = null;
    // if (auth_ui_message) {
    //     auth_message_element = <p className='LoginMesssageError'> {auth_ui_message} </p>;
    // }

    // if (currentUser){
    //     console.log('got currentUser. pushing history now')
    //     history.push("/app")
    // }



    return (
        <div className="LoginPageDiv">
            <img className="LoginPageImg" src={bg_image} alt="logo" />
            <form style={{width:'40%', left:'30%', top:'10%', }} className="LoginPageForm">

                <UserSettings item_type='user' extra_data={{}} modal_input_data={{}} />
            </form> 
        </div>
    )
}
