

import React, { Component } from 'react';
import './ViewportHud.css';
import { connect} from 'react-redux';
import Button from '../inputs/Button';
import ThreadPicker from '../objects/thread_picker/ThreadPicker';

import * as actionCreator_viewports from '../../../store/actions/actions_viewports';
import * as actionCreator_story_items from '../../../store/actions/actions_story_items';
import * as viewport_utils from '../../../utils/viewport_utils';
import * as actionCreator_ui from '../../../store/actions/actions_ui';

// var TIMELINE_TO_USE = 'story_percentage';

class StoryItemsHud extends Component {
    constructor(props) {
        super(props);
        this.handleClickDelete = this.handleClickDelete.bind(this);
        this.handleAddItemClick = this.handleAddItemClick.bind(this);

        // this.onKeyDown = this.onKeyDown.bind(this);
        this.state = {
            hovering:null,
        }
    }
    
    // onKeyDown(event){
    //     let key_down = event.key;
    //     console.log('onKeyDown XXXX', key_down)

    //     if (key_down === 'f'){
    //         this.handleClickZoomFit();
    //     }
    //     // this.props.setModifierKey(key_down, true)
        
    //   }
    handleAddItemClick(event) {
        let panel_name = 'add_item_flow'
        let element_id = 'add_item_flow'
        this.props.open_foldout_panel(panel_name, element_id);
    }
    handleHover(which) {
        // console.log('hovering over buttton', which);
        this.setState({hovering:which})
    }

    handleClickDelete() {
        
        let current_sel = this.props.ui_data.control.selected;
        if (current_sel){
            // this.props.onClickDelete_b(current_sel, this.props.ui_data.current.current_project);

            // generate item_data to work with new delete_items_with_undo function
            //  this wants to be the actual item data to populate undo function args
            let item_data_for_undo = {}
            for (let index = 0; index < Object.keys(current_sel).length; index++) {
                let item_id = Object.keys(current_sel)[index];
                // console.log('delete_items_with_undo loop item_id', item_id)
                // console.log('delete_items_with_undo loop this.props.item_data[item_id]', this.props.item_data[item_id])
                item_data_for_undo[item_id] = {...this.props.item_data[item_id]};
                item_data_for_undo[item_id]['item_type'] = current_sel[item_id]['item_type']
            }
            // console.log('about to run this delete_items_with_undo - this.props.item_data', this.props.item_data)
            // console.log('about to run this delete_items_with_undo - item_data_for_undo', item_data_for_undo)
            this.props.delete_items_with_undo(item_data_for_undo, this.props.ui_data.current.current_project);


        }
        else{
            console.log('NOTHING TO DELETE')
        }
    }

    render() {

        let enable_pointer_style = {pointerEvents:'all'};
        if (this.props.ui_data.control.begin_drag){
            enable_pointer_style = {};
        }

        let div_bottom = 0;
        if (this.props.ui_data.settings.show_time_controls){
            div_bottom = 120;
        }

        let top_row_offset = 50;
        if (this.props.ui_data.settings.attribute_panel_expanded){
            top_row_offset = 0;
        }
        
        let transition_style = "right 0.2s";
        if (this.props.ui_data.control.dragging[0] === 'handle'){
            transition_style = "";
        }


        return (
            <div style={{bottom:div_bottom}} className='ViewportHudDiv'
                // onKeyDown={this.props.onKeyDown}
            >

                <div
                    style={{display:'flex', position:'absolute', height:"80px", right:"0px", top:"34px"}}
                    onMouseOver={() => this.handleHover('trash')} 
                    onMouseLeave={() => this.handleHover(null)} 
                >
                    {this.state.hovering === 'trash' && 
                        <p className='buttonHoverLabel' 
                            style={{ top:"10px", right:"45px"}}
                        >Delete Item</p>
                    }
                    <Button
                        buttonType='overlayButton'
                        dataTarget=''
                        valToSet={false}
                        onClickFunction={this.handleClickDelete} 
                        label='' 
                        icon='fas fa-trash'
                        id='cscsc' 
                        style={Object.assign({}, enable_pointer_style, {height:"80px", width:"80px", transition:transition_style, right:top_row_offset +"px",})}
                    />
                </div>


                {/* <div
                    style={{display:'flex', fontSize:"70px", position:'absolute', height:"80px", right:"20px", bottom:"30px"}}
                    onMouseOver={() => this.handleHover('add_item')} 
                    onMouseLeave={() => this.handleHover(null)} 
                >
                    {this.state.hovering === 'add_item' && 
                        <p className='buttonHoverLabel' style={{right:"110px", top:"-10px"}}>Add New Item</p>
                    }

                    <i 
                        id='add_item_flow' 
                        className={'OverlayButtonDiv fas fa-plus-circle'} 
                        style={Object.assign({}, enable_pointer_style, {fontSize:"70px", height:"70px", width:"100px", right:"0px"})} 
                        onClick={this.handleAddItemClick} 
                    />
                </div> */}
               
            </div>
        );
    };
}
const mapStateToProps = (state, props) => {
    // let current_vp = state.ui_data.current.current_viewport;
    return {
        // current_sel:state.ui_data.current_sel,
        // current_viewport:state.ui_data.current.current_viewport,

        // character_data:state.character_data,
        // prop_data:state.prop_data,
        // location_data:state.location_data,
        // event_data:state.event_data,
        item_data:state[props.item_type + '_data'],

        ui_data:state.ui_data,
        // viewport_data:state[current_vp[0] + '_data'][current_vp[1]],

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onClickDelete_b: (item_data, project_id ) => dispatch(actionCreator_story_items.delete_items(item_data, project_id)),
        delete_items_with_undo: (item_data, project_id ) => dispatch(actionCreator_story_items.delete_items_with_undo(item_data, project_id, true)),
        open_foldout_panel: (panel_name, element_id) => dispatch(actionCreator_ui.open_foldout_panel(panel_name, element_id)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps )(StoryItemsHud);

