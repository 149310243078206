/*
*/

import {storage} from "../../config/firebase";
import * as actions_database from './actions_database';
import * as Constants from '../../constants/constants';
import * as database_utils from '../../utils/database_utils'
import * as storage_utils from '../../utils/storage_utils'

export function upload_file_to_storage(file, storage_path, complete_callback, progress_callback) {
  // console.log('BB upload_file_to_storage file B', file);
  // console.log('upload_file_to_storage storage_path', storage_path);

  // add global root to starage path
  storage_path = Constants.STORE_ROOT + '/' + storage_path;

  return dispatch =>  {
    // Create a root reference
    var storageRef = storage().ref();
    // console.log('storageRef', storageRef)

    var metadata = {
      'customMetadata': {
        origFileName: file.name,
      }
    };

    var uploadTask = storageRef.child(storage_path).put(file, metadata)
      
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');

        // console.log('progress_callback', progress_callback)
        progress_callback(progress)

        switch (snapshot.state) {
          case storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      }, function(error) {

      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;

        case 'storage/canceled':
          // User canceled the upload
          break;


        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, function() {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL()
      .then((downloadURL) => {
          console.log(`Successfully uploaded file and got download link - ${downloadURL}`);

          complete_callback(downloadURL)

       })
    
      .catch(error => {
          // Use to signal error if something goes wrong.
          console.log(`Failed to upload file and get link - ${error}`);
      //  }
       })
      }
    )
  }
}




export function upload_file_to_storage_b(file, storage_path, user_id, project_id, complete_callback, progress_callback) {
  /*
  revised version of upload_file_to_storage, now organising storage by user and project. That will make it easier to track usage

  */
  // console.log('BB upload_file_to_storage file B', file);
  // console.log('upload_file_to_storage storage_path', storage_path);

  // add global root to starage path

  // storage_path = Constants.STORE_ROOT + '/' + storage_path;
  storage_path = storage_utils.get_storage_path(Constants.STORE_ROOT, project_id, user_id, storage_path);

  return dispatch =>  {
    // Create a root reference
    var storageRef = storage().ref();
    console.log('storageRef', storageRef)

    var metadata = {
      'customMetadata': {
        origFileName: file.name,
      }
    };

    var uploadTask = storageRef.child(storage_path).put(file, metadata)
      
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');

        console.log('progress_callback', progress_callback)
        progress_callback(progress)

        switch (snapshot.state) {
          case storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      }, function(error) {

      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;

        case 'storage/canceled':
          // User canceled the upload
          break;


        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, function() {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL()
      .then((downloadURL) => {
          console.log(`Successfully uploaded file and got download link - ${downloadURL}`);

          complete_callback(downloadURL, file.name, file.size)

       })
    
      .catch(error => {
          // Use to signal error if something goes wrong.
          console.log(`Failed to upload file and get link - ${error}`);
      //  }
       })
      }
    )
  }
}




export const set_store_and_db_val = (val_to_set, dataTarget, project_id) => {

  return dispatch => {
      // console.log('AC set_store_and_db_val', val_to_set, dataTarget)
      // console.log('AC set_store_and_db_val project_id',project_id)
      // event.stopPropagation();
      // let val_to_set = event.target.value


      //convert js path to db  path
      // let db_root = 'story_app_data'
      // let db_path = Constants.DB_ROOT + '/' + dataTarget.replace(/\./g, '/');
      let db_path = '';
      if (!project_id){
        // console.log('  X  db_path ')
        db_path = Constants.DB_ROOT + '/' + dataTarget.replace(/\./g, '/');
      }
      else{
        // console.log('  Y  db_path ')

        db_path = database_utils.get_database_path_from_data_target(dataTarget, Constants.DB_ROOT, project_id)
      }
      // console.log('set_store_and_db_val db_path', db_path)

      actions_database.set_db_data(db_path, val_to_set, window.SKIP_DATABASE, 0)    
      .then (
          dispatch( { 
              type: "SET_INPUT_VAL", 
              dataTarget:dataTarget,
              val_to_set:val_to_set,
          }),
      )
          // This is where i would put in error catching (not quite sure how this works yet)
          // .catch (

          // )

      // return { type: "SET_INPUT_VAL", 
      //     dataTarget:dataTarget,
      //     val_to_set:val_to_set,
      // }
  }
}







////////////////////////////////
// TESTING ///

export function test_get_user_storage_size(user_id,) {
  /*
  revised version of upload_file_to_storage, now organising storage by user and project. That will make it easier to track usage

  */
  // console.log('BB test_get_user_storage_size user_id', user_id);
  // console.log('test_get_user_storage_size storage_path', storage_path);

  // add global root to starage path
  return dispatch =>  {

    // storage_path = Constants.STORE_ROOT + '/' + storage_path;
    let storage_path = Constants.STORE_ROOT +'/users/'+ user_id
    var storageRef = storage().ref();
    var listRef = storageRef.child(storage_path);

    let item_data = {}
    // list_items_by_ref_recursive(listRef, item_data)
    // .then(
    //   console.log('test_get_user_storage_size - item_data', item_data)
    // )

    console.log('test_get_user_storage_size - START')

    list_items_by_ref_recursive_async(listRef, item_data)
    .then(function(result) {
    //   // here you can use the result of promiseB
      console.log('test_get_user_storage_size - return_data', result)

    })
    .then(
      console.log('test_get_user_storage_size - END')

    )

    // let list= list_items_by_ref_recursive(listRef, item_data)
    // console.log('test_get_user_storage_size - return_data', list)


  }

  /*
  return dispatch =>  {
    // Create a root reference
    console.log('storage_path', storage_path)

    var storageRef = storage().ref();
    console.log('storageRef', storageRef)

    // var forestRef = storageRef.child(storage_path+'/character_0031374881318060766');
    // forestRef.getMetadata()
    // .then((metadata) => {
    //   console.log('metadata', metadata)
    //   // Metadata now contains the metadata for 'images/forest.jpg'
    // })
    // .catch((error) => {
    //   // Uh-oh, an error occurred!
    //   console.log('error', error)

    // });
    ///*
    var listRef = storageRef.child(storage_path);
    console.log('listRef', listRef)

    // console.log('listAll result:', listRef.listAll())
    listRef.listAll()
      .then((res) => {
        console.log('res', res)
        res.prefixes.forEach((folderRef) => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
          console.log('folder (prefix) found - ', folderRef)

        });
        res.items.forEach((itemRef) => {
          // All the items under listRef.
          console.log('itemRef found - ', itemRef)

          itemRef.getMetadata()
          .then((metadata) => {
            console.log('meta found - ', metadata.name, metadata.size)
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            console.log('meta error - ', error)

          });

        });
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });
      
  }
  */
}

// function  list_items_by_ref_recursive(ref, list_result) {
//   /*
//     HOw do i get this to work in async way, so i can return when finished?
//   */

//   console.log('list_items_by_ref_recursive- ref', ref)
//   ref.listAll()
//     .then((res) => {
//       // console.log('res', res)
//       res.prefixes.forEach((folderRef) => {
//         // All the prefixes under listRef.
//         // You may call listAll() recursively on them.
//         // console.log('folder (prefix) found - ', folderRef)
//         list_result = list_items_by_ref_recursive(folderRef, list_result)
        

//       });
//       res.items.forEach((itemRef) => {
//         // All the items under listRef.
//         // console.log('itemRef found - ', itemRef)

//         itemRef.getMetadata()
//         .then((metadata) => {
//           // console.log('meta found - ', metadata.name, metadata.size)
//           list_result[metadata.name] = metadata.size
//         })
//         .catch((error) => {
//           // Uh-oh, an error occurred!
//           console.log('meta error - ', error)

//         });

//       });
//     }).catch((error) => {
//       // Uh-oh, an error occurred!
//     });
//     console.log('list_result', list_result)
//     return list_result
//     //*/
// }

// async function  list_items_by_ref_recursive_async(ref, list_result) {
//   /*
//     HOw do i get this to work in async way, so i can return when finished?
//   */

//   console.log('list_items_by_ref_recursive- ref', ref)
//   ref.listAll()
//     .then((res) => {
//       // console.log('res', res)
//       res.prefixes.forEach((folderRef) => {
//         // All the prefixes under listRef.
//         // You may call listAll() recursively on them.
//         // console.log('folder (prefix) found - ', folderRef)
//         // list_result = list_items_by_ref_recursive_async(folderRef, list_result)
//         list_items_by_ref_recursive_async(folderRef, list_result)
//         .then(function(result) {
//             console.log('in recursive - return_data', result)
//             list_result = result
//         })

//       });
//       res.items.forEach((itemRef) => {
//         // All the items under listRef.
//         // console.log('itemRef found - ', itemRef)

//         itemRef.getMetadata()
//         .then((metadata) => {
//           // console.log('meta found - ', metadata.name, metadata.size)
//           console.log('in recursive - metadata.name', metadata.name)
//           list_result[metadata.name] = metadata.size
//         })
//         .catch((error) => {
//           // Uh-oh, an error occurred!
//           console.log('meta error - ', error)
//         });

//       });
//     }).catch((error) => {
//       // Uh-oh, an error occurred!
//     });

//   console.log('list_result', list_result)
//   return list_result
// }

async function  list_items_by_ref_recursive_async(ref, list_result) {
  /*
    HOw do i get this to work in async way, so i can return when finished?
    I'm trying to put awaits everwhere and making every function async, even my crappy loops
    nothing seems to work
  */

  // console.log('list_items_by_ref_recursive- ref', ref)
  var res = await ref.listAll()
  // console.log('res', res)
  res.prefixes.forEach( async (folderRef) => {
    list_result = await list_items_by_ref_recursive_async(folderRef, list_result)
    // console.log('in recursive - return_data', list_result)

  });
  res.items.forEach( async (itemRef) => {
    var metadata = await itemRef.getMetadata()
    // console.log('in recursive - metadata.name', metadata.name)
    list_result[metadata.name] = metadata.size
  });


  // console.log('list_result', list_result)
  return list_result
}