
/////////////////////// Inputs Action Creators  /////////////////////////////
///////////////////////////////////////////////////////////////////////////////
/*
TO DO:

test with DB set ON
integrate timer (timeout?) syste to avoid send too much to db

*/


import { database } from "../../config/firebase";

import * as actions_database from './actions_database';
import * as Constants from '../../constants/constants';
import * as database_utils from '../../utils/database_utils'

// var SKIP_DATABASE = true;

export const on_input_change = (event, dataTarget, project_id, do_set_db_data=true) => {

    return dispatch => {

        // console.log('NOW AC on_input_change event', event)
        // console.log('NOW AC on_input_change dataTarget',  dataTarget)

        // console.log('AC on_input_change do_set_db_data', do_set_db_data)
        event.stopPropagation();
        let val_to_set = event.target.value
        let type_to_set = event.target.type

        // make sure numbers are float (not string)
        if (type_to_set === 'number'){
            val_to_set = parseFloat(val_to_set);
            if (isNaN(val_to_set)){
                console.log('AA  IN HERE')
                val_to_set = null;
                // return;
            }
        }

        // customise db set delay based on input type
        // eg, text area or field should have delay so only push data after a second of stopping typing (dont need to update foe each letter)
        let db_set_delay = 0;
        if (['textarea', 'text', 'number'].includes(type_to_set)){
            // console.log('***** iN HERE NOW', type_to_set)
            db_set_delay = 1000;
        }



        //convert js path to db  path
        // let db_root = Constants.DB_ROOT
        // let db_path = db_root + '/' + dataTarget.replace(/\./g, '/');

        if (do_set_db_data === true){
            let db_path = database_utils.get_database_path_from_data_target(dataTarget, Constants.DB_ROOT, project_id)
            // let dataTarget_b = dataTarget.replace('projects.', 'project_data.');
            // let db_path_b = database_utils.get_database_path_from_data_target(dataTarget_b, Constants.DB_ROOT, project_id)

            // console.log(' ttt  db_path', db_path);
            // console.log(' ttt db_path_b', db_path_b);
            // actions_database.set_db_data(db_path_b, val_to_set, window.SKIP_DATABASE, db_set_delay) 
            actions_database.set_db_data(db_path, val_to_set, window.SKIP_DATABASE, db_set_delay)    
            .then (
                dispatch( { 
                    type: "SET_INPUT_VAL", 
                    dataTarget:dataTarget,
                    val_to_set:val_to_set,
                })
            )

            // actions_database.set_db_data(db_path_b, val_to_set, window.SKIP_DATABASE, db_set_delay)
            

        }
        else{
            dispatch( { 
                type: "SET_INPUT_VAL", 
                dataTarget:dataTarget,
                val_to_set:val_to_set,
            })
        }

        // if (do_set_db_data === true){

        //     // TODO: this is a very ugly hack to keep project name in sync with projects and project_data
        //     if (dataTarget.startsWith('projects.')){
        //         let dataTarget_b = dataTarget.replace('projects.', 'project_data.');
        //         let db_path_b = database_utils.get_database_path_from_data_target(dataTarget_b, Constants.DB_ROOT, project_id)
        //         console.log('\n\ setting project_data to match projects', db_path_b)
        //         actions_database.set_db_data(db_path_b, val_to_set, window.SKIP_DATABASE, 2000) 
        //         console.log(' ttt  dataTarget', dataTarget);
        //         console.log(' ttt dataTarget_b', dataTarget_b);
        //     }
        // }



            // This is where i would put in error catching (not quite sure how this works yet)
            // .catch (

            // )

        // return { type: "SET_INPUT_VAL", 
        //     dataTarget:dataTarget,
        //     val_to_set:val_to_set,
        // }



        
    }
}



export const on_click = (event, dataTarget, val_to_set, project_id, do_set_db_data=true) => {
    return dispatch => {
        // console.log('AC on_click do_set_db_data', do_set_db_data)

        event.stopPropagation();


        //convert js path to db  path
        // let db_root = Constants.DB_ROOT
        // let db_path = db_root + '/' + dataTarget.replace(/\./g, '/');

        // console.log('db_path', db_path)

        
        if (do_set_db_data === true){
            let db_path = database_utils.get_database_path_from_data_target(dataTarget, Constants.DB_ROOT, project_id)

            actions_database.set_db_data(db_path, val_to_set, window.SKIP_DATABASE)    
            .then (
                dispatch( { 
                    type: "SET_INPUT_VAL", 
                    dataTarget:dataTarget,
                    val_to_set:val_to_set,
                }),
            )
        }
        else{
            dispatch( { 
                type: "SET_INPUT_VAL", 
                dataTarget:dataTarget,
                val_to_set:val_to_set,
            })
        }
    }
}





export const set_val_at_target = (dataTarget, val_to_set, project_id, do_set_db_data=true) => {
    return dispatch => {
        // console.log('AC on_click do_set_db_data', do_set_db_data)

        if (do_set_db_data === true){
            let db_path = database_utils.get_database_path_from_data_target(dataTarget, Constants.DB_ROOT, project_id)

            actions_database.set_db_data(db_path, val_to_set, window.SKIP_DATABASE)    
            .then (
                dispatch( { 
                    type: "SET_INPUT_VAL", 
                    dataTarget:dataTarget,
                    val_to_set:val_to_set,
                }),
            )
        }
        else{
            dispatch( { 
                type: "SET_INPUT_VAL", 
                dataTarget:dataTarget,
                val_to_set:val_to_set,
            })
        }
    }
}
    





export const on_input_change_quill = (val_to_set, dataTarget, project_id, do_set_db_data=true) => {

    return dispatch => {

        // console.log('NOW AC on_input_change', dataTarget, project_id)
        // console.log('AC on_input_change do_set_db_data', do_set_db_data)
        // event.stopPropagation();
        // let val_to_set = event.target.value
        // let type_to_set = event.target.type

        // make sure numbers are float (not string)
        // if (type_to_set === 'number'){
        //     val_to_set = parseFloat(val_to_set);
        //     if (isNaN(val_to_set)){
        //         console.log('AA  IN HERE')
        //         val_to_set = null;
        //         // return;
        //     }
        // }

        // customise db set delay based on input type
        // eg, text area or field should have delay so only push data after a second of stopping typing (dont need to update foe each letter)
        // let db_set_delay = 0;
        // if (['textarea', 'text', 'number'].includes(type_to_set)){
        //     // console.log('***** iN HERE NOW', type_to_set)
        //     db_set_delay = 1000;
        // }

        let db_set_delay = 1000;


        //convert js path to db  path
        // let db_root = Constants.DB_ROOT
        // let db_path = db_root + '/' + dataTarget.replace(/\./g, '/');

        if (do_set_db_data === true){
            let db_path = database_utils.get_database_path_from_data_target(dataTarget, Constants.DB_ROOT, project_id)
            actions_database.set_db_data(db_path, val_to_set, window.SKIP_DATABASE, db_set_delay)    
            .then (
                dispatch( { 
                    type: "SET_INPUT_VAL", 
                    dataTarget:dataTarget,
                    val_to_set:val_to_set,
                }),
            )
        }
        else{
            dispatch( { 
                type: "SET_INPUT_VAL", 
                dataTarget:dataTarget,
                val_to_set:val_to_set,
            })
        }
            // This is where i would put in error catching (not quite sure how this works yet)
            // .catch (

            // )

        // return { type: "SET_INPUT_VAL", 
        //     dataTarget:dataTarget,
        //     val_to_set:val_to_set,
        // }
    }
}




// export const on_checkbox_click = (event, dataTarget, val_to_set, project_id, do_set_db_data=true) => {
//     return dispatch => {
//         // console.log('AC on_click do_set_db_data', do_set_db_data)

//         event.stopPropagation();


//         //convert js path to db  path
//         // let db_root = Constants.DB_ROOT
//         // let db_path = db_root + '/' + dataTarget.replace(/\./g, '/');

//         // console.log('db_path', db_path)

        
//         if (do_set_db_data === true){
//             let db_path = database_utils.get_database_path_from_data_target(dataTarget, Constants.DB_ROOT, project_id)

//             actions_database.set_db_data(db_path, val_to_set, window.SKIP_DATABASE)    
//             .then (
//                 dispatch( { 
//                     type: "SET_INPUT_VAL", 
//                     dataTarget:dataTarget,
//                     val_to_set:val_to_set,
//                 }),
//             )
//         }
//         else{
//             dispatch( { 
//                 type: "SET_INPUT_VAL", 
//                 dataTarget:dataTarget,
//                 val_to_set:val_to_set,
//             })
//         }
//     }

// }



export const on_input_focus = ( input_type, input_id) => {
    // console.log('AC on_input_focus ', input_type, input_id)

    return dispatch => {
        dispatch( { 
            type: "SET_INPUT_FOCUS", 
            input_type:input_type,
            input_id:input_id,
        })
    }
}

export const on_input_blur = () => {
    // console.log('AC on_input_blur ')

    return dispatch => {
        dispatch( { 
            type: "SET_INPUT_BLUR", 
        })
    }
}
