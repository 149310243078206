
/////////////////////// Timeline Action Creators  /////////////////////////////
///////////////////////////////////////////////////////////////////////////////
/*
This is propagated from actions_viewports and is mostly the same to start with - i expect it do drift appart
*/
import * as actions_database from './actions_database';
// import * as actions_projects from './actions_projects';
import * as Constants from '../../constants/constants';
import * as database_utils from '../../utils/database_utils'

import get_mouse_pos_in_viewport from '../../utils/get_mouse_pos_in_viewport'
import get_time_from_timeslider from '../../utils/get_time_from_timeslider'
import get_time_from_timeline from '../../utils/get_time_from_timeline'

import * as timeline_utils from '../../utils/timeline_utils';
import templates from '../templates/templates';

// mode panel on left actions 
export const mouse_down_timeline = (event, ui_data, tl_data) => {
    //AAA console.log('AC mouse_down_viewport', event, ui_data, tl_data )

    // let mousePos = [event.clientX, event.clientY];

    let mouse_down_pos =  [event.clientX, event.clientY];
    let main_panel_left = ui_data.layout.main_panel_left;
    let main_panel_top = ui_data.layout.main_panel_top;
    let local_orig_pos = tl_data.pos;
    let vp_scale = tl_data.scale;

    let vp_mouse_pos = get_mouse_pos_in_viewport(
        [main_panel_left, main_panel_top], 
        local_orig_pos,
        vp_scale,
        mouse_down_pos
        )

    return { type: "MOUSE_DOWN_TL", 
        local_down_pos:vp_mouse_pos, 
        local_current_pos:vp_mouse_pos,
        local_drag_offset:[0,0],
        local_orig_pos:local_orig_pos,
        // timeline_id:ui_data.current.current_timeline,
        // time:time, 
    };

};



export const mouse_drag_timeline = (event, ui_data, tl_data) => {
    //AAA console.log('AC mouse_drag_viewport', event, ui_data, tl_data )

 
    return dispatch => {

        // newState.mouse_current_pos = [action.event.pageX, action.event.pageY];
        let mouse_down_pos = [event.clientX, event.clientY];

        //    let mousePos = state.ui_data.control.mouse_current_pos;
        let main_panel_left = ui_data.layout.main_panel_left;
        let main_panel_top = ui_data.layout.main_panel_top;
        let mouse_drag_offset = ui_data.control.mouse_drag_offset;
        let local_orig_pos = ui_data.control.local_orig_pos;
        let local_down_pos = ui_data.control.local_down_pos;
        let vp_scale = tl_data.scale;

        let new_pos = [0,0];
        // if dragging is a viewport then set vp position, otherwise, update local values to be used by other things
        if (ui_data.control.dragging[0] === 'timeline' && ui_data.control.dragging[1] === ui_data.current.current_timeline){

            let new_pos_a = [
                Math.round(local_orig_pos[0] +  mouse_drag_offset[0]),
                Math.round(local_orig_pos[1] +  mouse_drag_offset[1]),
            ]

            new_pos = [
                // Math.round(Math.max(Math.min(new_pos_a[0], vp_scale*1000), vp_scale*-1000)),
                new_pos_a[0],
                Math.round(Math.max(Math.min(new_pos_a[1], 1500), 50)),
            ]

            dispatch(set_timeline_pos_and_scale(new_pos, vp_scale, ui_data.current.current_timeline, ui_data.current.current_project))

            // dispatch ({ type: "SET_TL_POS", 
            //     vp_type:'timeline', 
            //     vp_id:ui_data.current.current_timeline,
            //     pos:new_pos,
            // });

            // let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, ui_data.current.current_project, 'timeline', ui_data.current.current_timeline);
            // console.log('db_path - ', db_path);
            // let db_data = {pos:new_pos, scale:vp_scale};
            // let db_set_delay = 1000;
            // actions_database.set_db_data(db_path, db_data, window.SKIP_DATABASE, db_set_delay)  
        }

        else {
            let viewport_pos = tl_data.pos;
            let vp_scale = tl_data.scale;

            // IF NOT MOVING VP ASSUME I CARE ABOUT PUSHING AN OFFSET I CAN USE IN MYH OTHER SLICES
            // FIGURE OUT OFFSET IN VP SPACE:
            let vp_mouse_pos = get_mouse_pos_in_viewport(
                [main_panel_left, main_panel_top], 
                viewport_pos,
                vp_scale,
                mouse_down_pos
                )

            let vp_offset = [
                    vp_mouse_pos[0] - local_down_pos[0], 
                    (vp_mouse_pos[1] - local_down_pos[1])*vp_scale
                ]

            // newState.ui_data = {...state.ui_data}
            // newState.ui_data.control.local_current_pos = vp_mouse_pos
            // newState.ui_data.control.local_drag_offset = vp_offset

            dispatch ({ type: "SET_LOCAL_VALS", 
                local_current_pos:vp_mouse_pos,
                local_drag_offset:vp_offset,
            });
        }
    }
 }


 export const mouse_wheel_timeline = (event, ui_data, tl_data) => {
    // console.log('AC mouse_wheel_timeline', event, ui_data, tl_data )
    return dispatch => {

        event.stopPropagation();
        event.preventDefault();

        
        let mouseDownPos = [event.clientX, event.clientY];
        let viewportPos = tl_data.pos;
        let orig_scale = tl_data.scale;

        let vp_mouse_pos = get_mouse_pos_in_viewport(
            [ui_data.layout.main_panel_left, ui_data.layout.main_panel_top], 
            viewportPos,
            orig_scale,
            mouseDownPos
            )

        let wheelAmount = event.deltaY;
        // newState[action.vp_type +'_data'][action.vp_id]['scale'] =orig_scale

        let newScale = null; 
        if (wheelAmount < 0){
            newScale = orig_scale * 1.2;
            if (newScale > 5) newScale= 5;
        }
        else {
            newScale = orig_scale * .8;
            if (newScale < .04) newScale= .04;
        }
        let scaleDif = newScale - orig_scale;

        let newPosX = viewportPos[0] - (vp_mouse_pos[0]*scaleDif)
        // let newPosY = viewportPos[1] - (vp_mouse_pos[1]*scaleDif)
        let newPosY = viewportPos[1];

        
        // newState[action.vp_type +'_data'][action.vp_id]['scale'] = newScale
        // newState[action.vp_type +'_data'][action.vp_id]['pos'] = [newPosX, newPosY]

        dispatch(set_timeline_pos_and_scale([newPosX, newPosY], newScale, ui_data.current.current_timeline, ui_data.current.current_project))

        // dispatch ({ type: "SET_ZOOM_TL", 
        //     vp_type:'timeline', 
        //     vp_id:ui_data.current.current_timeline,
        //     scale:newScale,
        //     pos:[newPosX, newPosY],
        // });
        // let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, ui_data.current.current_project, 'timeline', ui_data.current.current_timeline);
        // console.log('db_path - ', db_path);
        // let db_data = {pos:[newPosX, newPosY], scale:newScale};
        // let db_set_delay = 1000;
        // actions_database.set_db_data(db_path, db_data, window.SKIP_DATABASE, db_set_delay)  

    }
}


export const zoom_timeline = (ui_data, tl_data, direction) => {
    // console.log('AC zoom_viewport', event, ui_data, tl_data, direction )

    return dispatch => {


    // //AAA console.log("WINDOW : ",window);
    // this.setState({height: window.innerHeight + 'px',width:window.innerWidth+'px'});

    // let mouseDownPos = [event.clientX, event.clientY];
    let viewportPos = tl_data.pos;
    let orig_scale = tl_data.scale;

    // let centre_pos = [
    //     window.innerHeight * .5, window.innerWidth * .5
    // ];

        // console.log('window.screenLeft', window.screenLeft);

        let centre_pos = [
            ui_data.layout.main_panel_left  +  (.5 * ( ui_data.layout.main_panel_right - ui_data.layout.main_panel_left)),
            ui_data.layout.main_panel_top  +  (.5 * ( ui_data.layout.main_panel_bottom - ui_data.layout.main_panel_top)),
        ];

        // console.log('centre_pos', centre_pos);
        // console.log('window', window);

        let vp_mouse_pos = get_mouse_pos_in_viewport(
            [ui_data.layout.main_panel_left, ui_data.layout.main_panel_top], 
            viewportPos,
            orig_scale,
            centre_pos
            )

        // let vp_mouse_pos = [
        //     Math.round(ui_data.main_panel_left  +  (.5 * ( ui_data.main_panel_right - ui_data.main_panel_left))),
        //     Math.round(ui_data.main_panel_top  +  (.5 * ( ui_data.main_panel_bottom - ui_data.main_panel_top))),
        // ]

        
        // let wheelAmount = action.event.deltaY;
        // newState[action.vp_type +'_data'][action.vp_id]['scale'] =orig_scale


        // let mouseDownPos = [event.clientX, event.clientY];


        // let vp_mouse_pos = get_mouse_pos_in_viewport(
        //     [ui_data.layout.main_panel_left, ui_data.layout.main_panel_top], 
        //     viewportPos,
        //     orig_scale,
        //     mouseDownPos
        //     )

        // let wheelAmount = event.deltaY;
        // newState[action.vp_type +'_data'][action.vp_id]['scale'] =orig_scale

        let newScale = null; 
        if (direction === 'in'){
            newScale = orig_scale * 1.2;
            if (newScale > 5) newScale= 5;
        }
        if (direction === 'out'){
            newScale = orig_scale * .8;
            if (newScale < .04) newScale= .04;
        }
        if (direction === 'reset'){
            newScale = 1.0;
        }
        let scaleDif = newScale - orig_scale;

        let newPosX = viewportPos[0] - (vp_mouse_pos[0]*scaleDif)
        // let newPosY = viewportPos[1] - (vp_mouse_pos[1]*scaleDif)
        let newPosY = viewportPos[1];

        // newState[action.vp_type +'_data'][action.vp_id]['scale'] = newScale
        // newState[action.vp_type +'_data'][action.vp_id]['pos'] = [newPosX, newPosY]
        dispatch(set_timeline_pos_and_scale([newPosX, newPosY], newScale, ui_data.current.current_timeline, ui_data.current.current_project))

        // return { type: "SET_ZOOM_TL", 
        //     vp_type:'timeline', 
        //     vp_id:ui_data.current.current_timeline,
        //     scale:newScale,
        //     pos:[newPosX, newPosY],
        // };
    }
}

// mode panel on left actions 
export const set_current_timeline = (vp_type, vp_id) => {
    //AAA console.log('AC set_current_viewport', event, ui_data, tl_data )

    // this reducer needs to be made
    return { type: "SET_CURRENT_TL", 
        vp_type:vp_type, 
        vp_id:vp_id,
    };

};


export const mouse_drag_time_cursor = (event,  ui_data, tl_data) => {
    // console.log('AC mouse_drag_time_contol', event, ui_data, tl_data )
    
    let mousePos = [event.clientX, event.clientY];
    let main_panel_left = ui_data.layout.main_panel_left;
    let main_panel_top = ui_data.layout.main_panel_top;
    let timeline_pos = tl_data.pos;
    let timeline_scale = tl_data.scale;


    let time = get_time_from_timeline(
        [main_panel_left, main_panel_top], 
        timeline_pos,
        timeline_scale,
        mousePos
        )

    

    return { type: "SET_TIMELINE_TIME", 
        timeline_id:ui_data.current.current_timeline,
        time:time, 
    };

};



export const set_timeline_pos_and_scale = (pos, scale, timeline, project_id) => {
    // console.log('AC set_timeline_pos_and_scale', pos, scale,)
    // console.log('AC set_timeline_pos_and_scale timeline', timeline)
    // console.log('AC set_timeline_pos_and_scale ', project_id)

    return dispatch => {
        // event.stopPropagation();

        dispatch( { type: "SET_ZOOM_TL", 
            vp_type:'timeline', 
            vp_id:timeline,
            scale:scale,
            pos:pos,
        })

        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'timeline', timeline);
        // console.log('db_path - ', db_path);

        let db_data = {pos:pos, scale:scale};

        let db_set_delay = 1000;
        // let db_set_delay = 0;
        actions_database.set_db_data(db_path, db_data, window.SKIP_DATABASE, db_set_delay)  
        
    }
}



export const fit_timeline = (ui_data, card_data) => {

    console.log('fit_timeline')

    return dispatch => {
        let item_array = [];

        let item_data_table = {
            card:card_data,
            // region:region_data,
        }
        let frame_types = ['card', ]
        let selected = ui_data.control.selected;
        // if nothing selected, then frame all. otherwise frame selected
        if (Object.keys(selected).length === 0){
            // loop through all framable types
            
            for (let i = 0; i < frame_types.length; i++) {
                let item_type = frame_types[i];
                let item_data = item_data_table[item_type];
                
                for (let index = 0; index < Object.keys(item_data).length; index++) {
                    const item_id = Object.keys(item_data)[index];
                    item_array.push(item_data[item_id])

                }
            }
        }

        else{
            for (let index = 0; index < Object.keys(selected).length; index++) {
                const item_id = Object.keys(selected)[index];
                let item_type = selected[item_id]['item_type']
                if (frame_types.includes(item_type)){
                    
                    let item_data = item_data_table[item_type];
                    item_array.push(item_data[item_id])
                }
                
            }
        }
        // console.log('handleClickZoomFit item_array B', item_array)


        let cur_timeline = ui_data.current.current_timeline;

        let bbox = timeline_utils.get_time_bbox_from_items(item_array, cur_timeline)
        // console.log('handleClickZoomFit bbox', bbox)


        // let vp_type = ui_data.current.current_viewport[0];
        // let vp_id = ui_data.current.current_viewport[1];
        let project_id = ui_data.current.current_project;

        let timeline_panel_width = ui_data.layout.main_panel_right -  ui_data.layout.main_panel_left;
        let timeline_panel_height = ui_data.layout.main_panel_bottom -  ui_data.layout.main_panel_top;
        let pos_and_scale = timeline_utils.get_frame_timeline_to_bbox_vals( bbox, timeline_panel_width, timeline_panel_height);

        // console.log('fit_timeline pos_and_scale', pos_and_scale);
        dispatch(set_timeline_pos_and_scale(pos_and_scale[0], pos_and_scale[1], cur_timeline, project_id))

    }
    // this.props.click_zoom(this.props.ui_data, this.props.viewport_data, 'reset');
}

export const create_timeline = (project_id, uid) => {

    // console.log('AC create_timeline', project_id, uid )
    return dispatch => {
        // let db_path = Constants.DB_ROOT+'/flow_viewport_data/'+uid
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'timeline', uid);

        let timeline_data = {...templates['timeline']};

        actions_database.set_db_data(db_path, timeline_data, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "CREATE_NEW_TIMELINE", 
                uid:uid,
                data:timeline_data,
            }),
        )
    }
};

