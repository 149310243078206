import React, {Component} from 'react';
import './Checkbox.css';
import { connect} from 'react-redux';

import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'
import new_uid from '../../../utils/new_uid'
import * as actionCreator_inputs from '../../../store/actions/actions_inputs';


class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            checked:false,
        }
    }

    
    handleClick(event) {
        event.stopPropagation();
        // console.log('doSomething')
        // this.setState({checked:!this.state.checked})
        // this.setState(prevState => ({
        //     checked: !prevState.checked
        //   }));

        
        // console.log('doSomething current state', this.state.checked)

        let cur_val = this.props.inputValue;
        let valToSet = !cur_val;

        this.props.onClick(this.props.dataTarget, valToSet, this.props.current_project_id, event)
        this.setState({checked:valToSet});

        if (this.props.onChangeFunction){
            this.props.onChangeFunction(valToSet)
        }
    }


    render() {
        let rand_id  = 'checkbox_'+new_uid;

        // let onClickCmd =null;

        // if (this.props.onClickFunction){
        //     // console.log('props.onClickFunction', props.label, props.onClickFunction)
        //     onClickCmd = this.props.onClickFunction;
        // }
        // else {
        //     onClickCmd = this.props.onClick;
        // }
        let dark_style_suffix = '';
        if (this.props.dark){
            dark_style_suffix = '-dark';
        }
        let labelToUse = null;
        if (this.props.label){  labelToUse = this.props.label + '  :   '}

        return (
            <div className='Checkbox_top_Div'  onClick={this.handleClick} >
                <p className={'checkBoxLabel'+dark_style_suffix}>{labelToUse}</p>
                <div 
                    // onClick={onClickCmd.bind(this, props.dataTarget, props.current_project_id)}
                   
                    id={rand_id} 
                    className={'CheckboxDiv'+dark_style_suffix}
                    //myValToSet= {props.myValToSet}
                    //style={props.style}
                    >
                    {this.props.inputValue &&  
                        <i className="fas fa-check CheckboxImage" />
                    }
                </div>
            </div>
        )
    }
        
}
// }


const mapStateToProps = (state, props) => {
    return {
        inputValue:get_data_obj_from_string(state, props.dataSource),
        current_project_id:state.ui_data.current.current_project,
    }
}


const mapDispatchToProps = (dispatch, props) => {

    let do_set_db_data = true;
    if (props.do_set_db_data === 'false'){
        do_set_db_data = false;
    }

    if (!props.dataTarget){
        return{
            onClick:  (entity, event) => console.log('no data target!')
        }
    }
    return {
        // onClick:  (event) => dispatch({type:'ON_CLICK', event:event, valToSet:props.valToSet, dataTarget:props.dataTarget}),
        // onClick: (dataTarget, current_project_id, event) => dispatch(actionCreator_inputs.on_checkbox_click(event, dataTarget, props.valToSet, current_project_id, do_set_db_data)),
        onClick: (dataTarget, valToSet, current_project_id, event) => dispatch(actionCreator_inputs.on_click(event, dataTarget, valToSet, current_project_id, do_set_db_data)),
    }




};

export default connect(mapStateToProps, mapDispatchToProps )(Checkbox);
