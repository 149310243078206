
export const get_current_value_from_attr_path = ( item, attr_path, cur_time, current_timeline) => {
    /*
        function to get value from attr, regardless of if it's static or keyed.

        This function is a reworking of get_current_value_from_attr, but instead of using attr_parent, it uses attr path.
            That way i can support item.age or item.attrs.age

    */
   console.log('get_current_value_from_attr_path item', item)
   console.log('get_current_value_from_attr_path attr_path', attr_path)
    let cur_value = null;
    let is_keyed = false;
    let has_attr = false;
    let on_key_time = null;

    let attr_value = get_attr_value_from_path(item, attr_path);
    console.log('get_current_value_from_attr_path attr_value', attr_value)

    if (attr_value){
        has_attr = true;

        if (typeof attr_value === 'object' && attr_value !== null){
            if (attr_value.hasOwnProperty('_keys')){
                if (attr_value['_keys'].hasOwnProperty(current_timeline)){
    
                    let key_data = attr_value['_keys'][current_timeline]
                    // char_key_data[uid] = key_data
                    // console.log('get_current_value_from_attr - key_data', key_data)
                    cur_value = get_current_value_from_keys(key_data, cur_time)
                    is_keyed = true;
    
                    if (key_data.hasOwnProperty(cur_time)){
                        on_key_time= true;
                    }
                }
            }
            else{
                console.log("WARNING - i shouldn't get an object type that doesnt have '_keys' key" )
            }
        }
        else{
            cur_value = attr_value;
        }
    }
    console.log('get_current_value_from_attr_path cur_value', cur_value)

    return {value:cur_value, has_attr:has_attr, is_keyed:is_keyed, on_key_time:on_key_time};

}








export const get_current_value_from_attr = ( item, attr_parent, attr_id, cur_time, current_timeline) => {
    /*
        function to get value from attr, regardless of if it's static or keyed.

        todo:
        not loving attr parent system. maybe i'd be better off with something like:
            '' or null, means parent directly under the item
            'attrs/some_other_parent/and_another'

        OR
        maybe do away with item parent, and have attr_id be 'location' or 'attrs/location'

    */
//    console.log('get_current_value_from_attr item', item)
    let cur_value = null;
    let is_keyed = false;
    let has_attr = false;
    let on_key_time = null;

    // this is to support not supplying parent. this means looking for params at the root of item
    let attr_parent_obj = item;
    if (attr_parent && item.hasOwnProperty(attr_parent)){
        attr_parent_obj = item[attr_parent]
    }
    if (attr_parent_obj.hasOwnProperty(attr_id)){
        if (attr_parent_obj[attr_id].hasOwnProperty('_keys')){
            if (attr_parent_obj[attr_id]['_keys'].hasOwnProperty(current_timeline)){

                let key_data = attr_parent_obj[attr_id]['_keys'][current_timeline]
                // char_key_data[uid] = key_data
                // console.log('get_current_value_from_attr - key_data', key_data)
                cur_value = get_current_value_from_keys(key_data, cur_time)
                is_keyed = true;

                if (key_data.hasOwnProperty(cur_time)){
                    on_key_time= true;
                }
            }
        }
        else{
            cur_value = attr_parent_obj[attr_id];
        }
        has_attr = true;
    }
    return {value:cur_value, has_attr:has_attr, is_keyed:is_keyed, on_key_time:on_key_time};

}

export const get_current_value_from_keys = ( key_data, current_time) => {
    /*
        get value from keyfame data, based on current time



    let mock_attr_data = {
        '_keys':{
            '_time_progress':{
                1:'location_018386371838711746', 30:'location_004536995067101768'
            }
        }
    }
    // console.log('mock_attr_data', mock_attr_data)

    let key_data = mock_attr_data['_keys'][current_timeline]
    */
   
    // console.log('key_data', key_data)
    let key_times = Object.keys(key_data).sort()
    // console.log('key_times', key_times)

    let current_value;

    if (current_time <= key_times[0]){
        current_value = key_data[key_times[0]]
    } 
    for (let i = 1; i < key_times.length; i++) {
        if (current_time >= key_times[i-1]  && current_time < key_times[i]){
            current_value = key_data[key_times[i-1]]
        }
        if (i == key_times.length-1){
            if (current_time >= key_times[i]){
                current_value = key_data[key_times[i]]
            } 
        }
    }
    if (key_times.length === 1){
        current_value = key_data[key_times[0]]
    }

    return current_value
}


// export const set_attr = ( item, attr_parent, attr_id, time, timeline) => {


// }



export const get_key_data_from_items = ( items_and_ids,  timeline) => {
    /*
        loop through items and al their attrs, and return a dict to be used in cache
        takes array of item, item_ids pairs.

    */
//    console.log('get_key_data_from_items items_and_ids', items_and_ids)


    let attr_parent = 'attrs';
    let return_data = {[timeline]:{}}
    for (let index = 0; index < items_and_ids.length; index++) {
        let item = items_and_ids[index][0];
        let item_id = items_and_ids[index][1];
        let item_type = items_and_ids[index][2];

        if (!return_data[timeline].hasOwnProperty(item_id)){
            return_data[timeline][item_id]={item:item, item_type:item_type};
        }
        
        if (!return_data[timeline][item_id].hasOwnProperty(attr_parent)){
            return_data[timeline][item_id][attr_parent]={};
        }

        // console.log('get_key_data_from_items item', item)
        // console.log('get_key_data_from_items item_id', item_id)
        // console.log('get_key_data_from_items item_type', item_type)
        // console.log('get_key_data_from_items attr_parent', attr_parent)
        // console.log('get_key_data_from_items item.attr_parent', item[attr_parent])
        // let item_key_data = null;

        if (item.hasOwnProperty(attr_parent)){
            // console.log('get_key_data_from_items -- ATTRS LIST', item[attr_parent])
            // loop through all attrs under parent
            for (let i = 0; i < Object.keys(item[attr_parent]).length; i++) {
                const attr_id = Object.keys(item[attr_parent])[i];
                // console.log('get_key_data_from_items -- attr_id', attr_id)

                // do i have keys
                if (item[attr_parent][attr_id].hasOwnProperty('_keys')){
                    if (item[attr_parent][attr_id]['_keys'].hasOwnProperty(timeline)){

                        let item_key_data = item[attr_parent][attr_id]['_keys'][timeline]

                        // convert key data to a differnt format i can use in cache data
                        // so i can store keytime seperately from object key
                        let new_key_data = {};
                        for (let ii = 0; ii < Object.keys(item_key_data).length; ii++) {
                            let key_time = Object.keys(item_key_data)[ii];
                            let key_val = item_key_data[key_time];
                            new_key_data[key_time] = [Number(key_time), key_val];
                        }

                        return_data[timeline][item_id][attr_parent][attr_id] = new_key_data
                    }
                }
            }
        }
    }
    // console.log('get_key_data_from_items -- return_data', return_data)

    return return_data;
    

}


export const get_attr_value_from_path = (item, attr_path) => {

    let splitKeys = attr_path.split(".");
    // //AAA console.log('get_data_obj_from_string splitKeys', splitKeys, );

    let return_obj = item;
    let data_found = true;
    splitKeys.map((key) => {
        // //AAA console.log('LOOOOOPING', key)
        if (key in return_obj){
            return_obj = return_obj[key];
        }
        else {
            // //AAA console.log('couldnt find data at this location', dataString);
            data_found = false;
            return null;
        };
    });

    if (data_found) {
        // //AAA console.log('returning', return_obj);
        return return_obj;
    }
    else {
        // //AAA console.log('couldnt find data at this location GAAIN', dataString);
        return null; 
    }
}


