// import * as firebase from "firebase";
// import firebase from 'firebase/app';
import firebase from 'firebase/compat/app';

import 'firebase/compat/database';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/app-check';

import { FirebaseConfig, DeploySwitchConfig } from "./keys";
const app = firebase.initializeApp(FirebaseConfig);
// console.log('AAA - DeploySwitchConfig', DeploySwitchConfig)
// console.log('AAA - DeploySwitchConfig.reCaptchaSiteKey', DeploySwitchConfig.reCaptchaSiteKey)

// App check
const appCheck = firebase.appCheck()
const reCaptchaSiteKey = DeploySwitchConfig.reCaptchaSiteKey;
// console.log('AAA - reCaptchaSiteKey', reCaptchaSiteKey)

// prod captcha code
// appCheck.activate('6LdrubQkAAAAAKV808R1NHQ8O3k1_oOWGfsQnvbv', true)

// dev captch code
// appCheck.activate('6LePJqApAAAAAMx-NYwR_LFh4KIRn2Ls-hLYWiFK', true)
appCheck.activate(reCaptchaSiteKey, true)

export const databaseRef = app.database().ref();
// export const todosRef = databaseRef.child("todos");
// export const ageRef = databaseRef.child("ageB");
export const database = app.database();
export const storage = firebase.storage;
export const auth = app.auth();