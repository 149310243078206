
const get_time_from_timeline = (main_panel_pos, timeline_pos, timeline_scale, mouse_pos, rounding=true, ) => {
    /*
    Simple function to get mouse position in viewport co-ords.
    Probably a smarter way of ding this -eg pass in event and panel div id or something?
    ATM this requires a lot of input.
    */
    let viewportPos_cursor_X = Math.round((mouse_pos[0] - main_panel_pos[0]  -  timeline_pos[0]) / timeline_scale);

    let time = viewportPos_cursor_X * .01; 
    if (rounding){
        time = Math.round(time);
    }
    return (time);
}
export default get_time_from_timeline;
