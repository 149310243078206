import React, { Component } from 'react';
import './LinkItemsWindow.css';
import { connect} from 'react-redux';
import InputField from '../ui_elements/inputs/InputField';
import Button from '../ui_elements/inputs/Button';
import ItemContainer from '../ui_elements/objects/item_container/ItemContainer';
import StoryItemEmpty from '../ui_elements/objects/story_item/StoryItemEmpty';


import * as actionCreator_ui from '../../store/actions/actions_ui';
import * as actionCreator_story_items from '../../store/actions/actions_story_items';



class LinkItemsWindow extends Component {
    constructor(props) {
        super(props);
        this.handleClickLink = this.handleClickLink.bind(this);

    }

    handleClickLink(event) {
        // this.props.link_items(this.props.item_data, item_type, item_id, to_link_type, to_link_ids);
        console.log('\n -- handleClickLink this.props.modal_input_data', this.props.modal_input_data)
        let item_data = this.props[this.props.modal_input_data[1]['item_type'] + '_data'][this.props.modal_input_data[1]['item_id']]
        this.props.modal_link_item(item_data, this.props.modal_input_data[1]['item_type'], this.props.modal_input_data[1]['item_id'], this.props.picked_items, this.props.modal_input_data[0], this.props.current_project_id)
            
    }

    render() {

        let createWinStyle = null
        let createWinStyleBG = null
        // let windowWidth = 600;
        // let windowHeight = 800;

        let item_data = this.props.item_data;
        let item_type = this.props.item_type;
        let item_id = this.props.item_id;
    

        return (
            <div className = 'linkItemWindow'>
                <div className='TopSectionDiv'>
                    <StoryItemEmpty 
                            item_type={this.props.modal_input_data[0]}
                            itemSize='medium'
                            mode='create'
                    />
                    <div className='AddLabel'> {'Create New ' + this.props.modal_input_data[0]} </div>
                </div>
                <div className='BottomSectionDiv'>
                    <ItemContainer 
                        //dataSource={item_type+'_data.' + item_id + '.links.character'}
                        containerLabel={'Link Existing '  + this.props.modal_input_data[0]}
                        item_type={this.props.modal_input_data[0]}
                        mode='pick'
                        itemSize='small'
                    />  
                </div>
                <Button 
                        onClickFunction={this.handleClickLink} 
                        //onClickFunction={this.test_on_click} 
                        label='Link'
                        style={{position:'absolute', height:"45px", width:"140px", right:"10px",bottom:"4px"}}
                    />
            </div>
        )
    };
};
const mapStateToProps = (state, props) => {
    return {
        // modal_data:state.ui_data.modal_data,
        // modal_input_data:state.ui_data.openModalWindow[props.modalName],
        picked_items:state.ui_data.control.picked_items,
        character_data:state.character_data,
        card_data:state.card_data,
        prop_data:state.prop_data,
        event_data:state.event_data,
        location_data:state.location_data,
        current_project_id:state.ui_data.current.current_project,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // link_items: (item_data, item_type, item_id, to_link_type, to_link_ids) => dispatch(actionCreator_story_items.link_items(item_data, item_type, item_id, to_link_type, to_link_ids)),
        modal_link_item:(item_data, item_type, item_id, picked_items, to_link_type, project_id) => dispatch(actionCreator_ui.modal_link_item(item_data, item_type, item_id, picked_items, to_link_type, project_id)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps )(LinkItemsWindow);
