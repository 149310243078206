export const compare_object_keys = (obj_a, obj_b) => {
    // console.log('compare_object_keys', obj_a, obj_b)
    var array1 = Object.keys(obj_a);
    var array2 = Object.keys(obj_b);
    array1.sort();
    array2.sort();

    if (arraysEqual(array1,array2)) {
      return true;
    }
    else{
      return false;
    }
}

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export const deepCopyFunction = (inObject) => {
    let outObject, value, key
  
    if (typeof inObject !== "object" || inObject === null) {
      return inObject // Return the value if inObject is not an object
    }
  
    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {}
  
    for (key in inObject) {
      value = inObject[key]
  
      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = deepCopyFunction(value)
    }
  
    return outObject
}

export const get_element_bbox = (element_id) => {
  let rect = null
  let elem =document.getElementById(element_id);
  if (elem){
    rect = elem.getBoundingClientRect();
  }

  return rect
}



export const get_distance = (posA, posB) => {
  let dx   = posA[0] - posB[0];         
  let dy   = posA[1] - posB[1];    
  var dist = Math.sqrt( dx*dx + dy*dy ); 
  return dist;
}

