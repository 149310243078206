import React, { Component } from 'react';
// import React from 'react';

import './ItemContainer.css';
import StoryItem from '../story_item/StoryItem';
import StoryItemEmpty from '../story_item/StoryItemEmpty';
import { connect} from 'react-redux';

import get_data_obj_from_string from '../../../../utils/get_data_obj_from_string'

class ItemContainer extends Component {
    constructor(props) {
        super(props);
        this.id_list = [];
        this.id_list_filtered = [];
        this.item_type = null;
        // // data source is specified then looked for linked data. else show all of this type
        // if (this.props.dataSource){
        //     if (this.props.linked_items_data){
        //         this.id_list = this.props.linked_items_data
        //         this.item_type = this.props.dataSource.split(".").slice(-1)[0];  // last token of path is item type
        //     };
        // }
        // else{
        //     this.id_list = Object.keys(this.props.item_data);
        //     this.item_type = this.props.item_type;
        // }
        // // ensure if id_list is not valid it is set as empty array
        // if (!this.id_list){
        //     this.id_list = [];
        // }
        // this.id_list_filtered = this.get_filtered_list(this.id_list, this.props.item_data)

    }

    // componentWillUpdate(){
    componentDidUpdate(){
        // console.log('>>>> ItemContainer componentWillReceiveProps')
        this.id_list = [];
        this.id_list_filtered = [];
        this.item_type = null;
        // let item_id = props.item_id || null;

        // data source is specified then looked for linked data. else show all of this type
        if (this.props.dataSource){
            if (this.props.linked_items_data){
                this.id_list = this.props.linked_items_data
                this.item_type = this.props.dataSource.split(".").slice(-1)[0];  // last token of path is item type
            };
        }
        else{
            this.id_list = Object.keys(this.props.item_data);
            this.item_type = this.props.item_type;
        }
        // ensure if id_list is not valid it is set as empty array
        if (!this.id_list){
            this.id_list = [];
        }
        this.id_list_filtered = this.get_filtered_list(this.id_list, this.props.item_data)

        
    }

    get_filtered_list(id_list, item_data){
    // This fuction filters the given id list by what's available in the state (provided by item_data)

        // console.log('>>>> ItemContainer componentWillReceiveProps')
        // this.id_list = [];
        let id_list_filtered = [];

        // ensure if id_list is not valid it is set as empty array
        if (id_list){
            //this will check ids against what's availbale in store. If they don't match, it means something's gotten out of whack
            // let id_list_filtered = [];
            let ids_in_state = Object.keys(item_data)
            for (let i = 0; i < id_list.length; i++){
                if ( ids_in_state.includes( this.id_list[i])){
                    // console.log('AAA', this.id_list[i])
                    id_list_filtered.push(this.id_list[i])
                }
                // else{
                //     console.log('ItemContainer - WARNGING- item not found store. this might mean things are out of whack', id_list[i])
                // }
            }
        }
        return id_list_filtered

    }
    render() {
    // const ItemContainer = (this.props) =>   {

        // data source is specified then looked for linked data. else show all of this type
        if (this.props.dataSource){
            if (this.props.linked_items_data){
                this.id_list = this.props.linked_items_data
                this.item_type = this.props.dataSource.split(".").slice(-1)[0];  // last token of path is item type
            };
        }
        else{
            this.id_list = Object.keys(this.props.item_data);
            this.item_type = this.props.item_type;
        }
        // ensure if id_list is not valid it is set as empty array
        if (!this.id_list){
            this.id_list = [];
        }
        this.id_list_filtered = this.get_filtered_list(this.id_list, this.props.item_data)
    

        if (this.props.containerType === 'large'){
            return (
                <div className="itemContainerLarge"> 
                    <div className="itemContainerLarge-label"> {this.props.containerLabel} </div>
                    <div className="itemContainer-flex" >
                    {
                        this.id_list_filtered.map((item) => {
                            return( <StoryItem 
                                key={this.item_type + '-' +item}
                                itemSize={this.props.itemSize}
                                item_type={this.item_type}
                                item_id={item}
                                mode={this.props.mode}
        
                            />); 
        
                            })
                        }
                        <StoryItemEmpty 
                            //key={'empty'}
                            item_type={this.props.item_type}
                            parent_data={this.props.parent_data}
                            itemSize={this.props.itemSize}
                            mode={this.props.mode}
                        />
                    </div>
                </div>
            );
        }
        else{
            return (
                <div>
                    <label className='itemContainer-label'>{this.props.containerLabel + ' :'}</label>
                    <div className="itemContainer"> 
                        <div className="itemContainer-flex" >
                        {
                            this.id_list_filtered.map((item) => {
                                return( <StoryItem 
                                    key={this.item_type + '-' +item}
                                    itemSize={this.props.itemSize}
                                    item_type={this.item_type}
                                    item_id={item}
                                    mode={this.props.mode}
            
                                />); 
            
                                })
                            }
                            <StoryItemEmpty 
                                item_type={this.props.item_type}
                                parent_data={this.props.parent_data}
                                itemSize={this.props.itemSize}
                                mode={this.props.mode}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }
 
}


const mapStateToProps = (state, props) => {

    // WHATS the thinking here again?
    // ok, i think its saying if a dataSource arg is passed then return linked_items_data with those items
    let return_data= {

        item_data:state[props.item_type + '_data'],
    }
    if (props.dataSource) {
        return_data['linked_items_data'] = get_data_obj_from_string(state, props.dataSource);
    }
    return return_data;

}
const mapDispatchToProps = (dispatch) => {
    return {
        // onAgeUp: () => dispatch({type:'AGE_UP'}),
        // onAgeDown: () => dispatch({type:'AGE_DOWN'}),
        // newCharacterButton: () => dispatch({type:'OPEN_CREATE_ITEM_PANEL'}),

    }
};

export default connect(mapStateToProps, mapDispatchToProps )(ItemContainer);
