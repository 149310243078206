import React, { Component } from 'react';
import './MapPanel.css';
import { connect} from 'react-redux';
// import ThreadPicker from '../ui_elements/inputs/ThreadPicker';
import '../ui_elements/inputs/InputField.css';
import StoryItem from '../ui_elements/objects/story_item/StoryItem';
import MapItem from '../ui_elements/objects/map_item/MapItem';
// import InputField from '../ui_elements/inputs/InputField';
// import IndexCard from '../ui_elements/objects/card/IndexCard';
// import CardThread from '../ui_elements/objects/path/CardThread';

import Viewport from '../ui_elements/objects/viewports/Viewport';
import Region from '../ui_elements/objects/region/Region';
import AddItemButton from '../ui_elements/objects/viewports/AddItemButton';

import MapHud from '../ui_elements/huds/MapHud';
// import TimeControl from '../ui_elements/objects/time/TimeControl';

import * as actionCreator_regions from '../../store/actions/actions_regions';
import * as actionCreator_cards from '../../store/actions/actions_cards';
import * as actionCreator_ui from '../../store/actions/actions_ui';
// import * as actionCreator_maps from '../../store/actions/actions_maps';
import get_populate_region_data from '../../utils/get_populate_region_data'
import get_populate_map_item_data from '../../utils/get_populate_map_item_data'
import * as attribute_utils from '../../utils/attribute_utils'


// var TIMELINE_TO_USE = 'story_percentage';
var VP_TO_USE = 'topLevel';

class MapPanel extends Component {
    constructor(props) {
        super(props);
        // this.handleClickCreateRegion = this.handleClickCreateRegion.bind(this);
        // this.handleClickCreateLocation = this.handleClickCreateLocation.bind(this);
        this.handleMapDoubleClick = this.handleMapDoubleClick.bind(this);
        this.handleMapMouseUp = this.handleMapMouseUp.bind(this);
    }
    // handleClickCreateRegion(event) {
    //     let project_id = this.props.ui_data.current.current_project;
    //     // let project_object = this.props.project_data[project_id];
    //     this.props.create_region_at_centre_of_vp(event, project_id, this.props.ui_data, this.props.viewport_data);
    // }

    // handleClickCreateLocation(event) {
    //     console.log('in handleClickCreateCard')
    //     let project_id = this.props.ui_data.current.current_project;
    //     // let project_object = this.props.project_data[project_id];
    //     this.props.create_location_at_centre_of_vp(event, project_id, this.props.ui_data, this.props.viewport_data);
    // }

    // populateAllImages(item_parent) {
    //     var result =   [
    //         //<img className='MapImage' style={{pointerEvents:'none'}} src={require('../../images/map.jpg')} />,
    //         <img className='MapImage' style={{pointerEvents:'none'}} 
    //             //src='https://firebasestorage.googleapis.com/v0/b/avi-firebase-project.appspot.com/o/croc01_2.png?alt=media&token=39096094-e489-4baf-b6c3-60a6a23d4e6e'
    //             src={require('../../images/map.jpg')}
    //         />,
    //     ]
    //     return  result;
    // }



    handleMapDoubleClick(event) {
        console.log('handleMapDoubleClick')
        // let project_id = this.props.ui_data.current.current_project;
        // // let project_object = this.props.project_data[project_id];
        // this.props.create_card_at_current_pos(event, project_id, this.props.ui_data);
    }

    handleMapMouseUp(event) {
        // console.log('handleMapMouseUp')

        // let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];
        // this.props.mouse_up_flow_vp(event, project_id, this.props.ui_data, this.props.viewport_data, this.props.card_data,  this.props.thread_data);
        
        // TODO - this is not quite working for some reason?
        // this.props.select_item_c(event, {});
    }

    populateAllLocations_old(item_parent) {
        // CAN LATER USE THE FLOW VP TO SCOPE WHICH CARDS ARE POPULATED
        var result =   [

            <div id='TEMP_placementDiv'
                style={{position:'absolute', left:'420px', top:'380px'}}>
                <StoryItem 
                    itemSize="small"
                    item_type="location"
                    item_id="uid3"
                />
            </div>,
            <div id='TEMP_placementDivB'
                style={{position:'absolute', left:'535px', top:'317px'}}>
                <StoryItem 
                    itemSize="small"
                    item_type="location"
                    item_id="uid2"
                />
            </div>,
            <div id='TEMP_placementDivC'
                style={{position:'absolute', left:'835px', top:'600px'}}>
                <StoryItem 
                    itemSize="small"
                    item_type="location"
                    item_id="uid1"
                />
            </div>
        ]
        
        return  result;
    }

    // TODO this is crappy that its duplicated from FlowPanel. Guess I should have this logic somewhere else
    populateAllRegions(item_parent) {

        
        let vp_bbox = this.get_vp_bbox(this.props.ui_data, this.props.viewport_data[this.props.current_viewport[1]], this.cull_bbox_buffer);

            
        // let vp_bbox = [];
        
        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];
        // let populate_region_data = get_populate_region_data(project_object, this.props.region_data, item_parent, vp_bbox)
        let populate_region_data = get_populate_region_data( this.props.region_data, item_parent, vp_bbox)
        if (!populate_region_data){
            return null;
        }
        var result = Object.keys(populate_region_data).map((region_uid) => {
            let child_count = populate_region_data[region_uid]['child_items'].length
            return(
                <Region 
                //<IndexCard
                    key={region_uid}
                    text='' 
                    name={populate_region_data[region_uid]['name']} 
                    pos={populate_region_data[region_uid]['pos']}
                    size={populate_region_data[region_uid]['size']}
                    color={populate_region_data[region_uid]['color']}
                    opacity={populate_region_data[region_uid]['opacity']}
                    item_id={region_uid}
                    item_type='region'
                    //child_count={child_count} 
                />
            ); 
        })
        return  result;
    }




    populateAllLocations(item_parent) {

        // TODO I'm repeating this call. Could do once higher up
        let vp_bbox = this.get_vp_bbox(this.props.ui_data, this.props.viewport_data[this.props.current_viewport[1]], this.cull_bbox_buffer);

        
        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];
        // let populate_location_data = get_populate_map_item_data(project_object, this.props.location_data, item_parent, vp_bbox)
        let populate_location_data = get_populate_map_item_data(this.props.location_data, item_parent, vp_bbox)
        if (!populate_location_data){
            return null;
        }
        // CAN LATER USE THE FLOW VP TO SCOPE WHICH CARDS ARE POPULATED
        // TODO: do i need to feed so much data in at this stage?? shouldn't the uid be enough and the index location will be self aware?
        var result = Object.keys(populate_location_data).map((location_uid) => {
            let child_count = populate_location_data[location_uid]['child_items'].length
            return(
                <MapItem 
                //<IndexCard
                    key={location_uid}
                    text='' 
                    name={populate_location_data[location_uid]['name']} 
                    description={populate_location_data[location_uid]['description']} 
                    pos={populate_location_data[location_uid]['pos']}
                    item_id={location_uid}
                    item_type='location'
                    child_count={child_count} 
                />
            ); 
        })
        return  result;
    }


    
    populateAllCharacters(item_parent) {
        let cur_time = this.props.current_time;
        let current_timeline = this.props.current_timeline;
        // let attr_id = 'location';
        let character_data = this.props.character_data;
        let char_elements = [];

        let transition_arg = '0.2s';
        if (this.props.ui_data.control.dragging_b)        {
            transition_arg = null;
        }
        
        let location_counter_data={};
        // let char_key_data = {};
        for (let i = 0; i < Object.keys(character_data).length; i++) {
            let uid = Object.keys(character_data)[i];
            let char_name = character_data[uid]['name'];         
            let cur_value = attribute_utils.get_current_value_from_attr( character_data[uid], 'attrs', 'location', cur_time, current_timeline).value
            // let cur_value = attribute_utils.get_current_value_from_attr_path( character_data[uid], 'attrs.location', cur_time, current_timeline).value
            
            // console.log('char_name', char_name)
            // console.log('cur_value', cur_value)
            if (!this.props.location_data.hasOwnProperty(cur_value)){
                continue;
            }

            let pos = [...this.props.location_data[cur_value].pos];
            if (!location_counter_data.hasOwnProperty(cur_value)){
                location_counter_data[cur_value] = 0;
            }
            // todo step out to a second loop here to only offset in y if 
            // multiple characters in same location

            pos[0] = pos[0] - 25;
            pos[1] = pos[1] + (location_counter_data[cur_value] * 50);
            // let loc_count = location_counter_data[cur_value];
            // pos[0] = pos[0] - 25 - Math.round(loc_count*50*.5) + (loc_count*50);
            // pos[1] = pos[1];

            location_counter_data[cur_value] = location_counter_data[cur_value]+1;

            char_elements.push(
                <StoryItem 
                    key={uid}
                    itemSize='small'
                    item_type='character'
                    item_id={uid}
                    itemStyle = {{'left':pos[0], 'top':pos[1], 'transition':transition_arg, 'position':'absolute'}}
                />
            );     
        }
        
        return char_elements;
    }
    
    

    populateTestCharacter(item_parent) {

        let cur_time = this.props.current_time;
        // console.log('cur_time', cur_time)

        let current_timeline = this.props.current_timeline;
        // console.log('current_timeline', current_timeline)
        // if (!current_timeline){
        //     return;
        // }

        let pos = [0,0];
        // for the moment I'll put mock data here. eventually I'd source this from the item itself
        let mock_attr_data = {
            '_keys':{
                '_time_progress':{
                    1:'location_018386371838711746', 30:'location_004536995067101768'
                }
            }
        }
        // console.log('mock_attr_data', mock_attr_data)

        let key_data = mock_attr_data['_keys'][current_timeline]
        let cur_value = attribute_utils.get_current_value_from_keys(key_data, cur_time)
        /*
        // console.log('key_data', key_data)
        let key_times = Object.keys(key_data).sort()
        // console.log('key_times', key_times)

        // let cur_loc = 'location_018386371838711746';
        let cur_loc;
        if (cur_time <= key_times[0]){
            cur_loc = key_data[key_times[0]]
        } 
        for (let i = 1; i < key_times.length; i++) {
            if (cur_time > key_times[i-1]  && cur_time <= key_times[i]){
                cur_loc = key_data[key_times[i-1]]
            }
            if (i == key_times.length-1){
                if (cur_time >= key_times[i]){
                    cur_loc = key_data[key_times[i]]
                } 
            }

        }
        */
        
        // console.log('cur_loc', cur_loc)
        pos = this.props.location_data[cur_value].pos;


        return(
            <div 
                className='testMapCharacter'
                style={{position:'absolute', left:pos[0], top:pos[1]}}
            />
        ); 
    }


// TODO This should not be duplicated from FlowPanel - should put somewhere better and use for both
    get_vp_bbox(ui_data, vp_data, buffer) {

        // console.log('get_vp_bbox ui_data', ui_data)
        // console.log('get_vp_bbox vp_data', vp_data)
        // console.log('get_vp_bbox buffer', buffer)
        // console.log('get_vp_bbox this.props.viewport_data', this.props.viewport_data)

        let main_panel_left = ui_data.layout.main_panel_left;
        let main_panel_right = ui_data.layout.main_panel_right;
        let main_panel_top = ui_data.layout.main_panel_top;
        let main_panel_bottom = ui_data.layout.main_panel_bottom;
        let vp_pos = vp_data['pos'];
        let vp_scale = vp_data['scale'];

        let vp_bbox = [
            (-1 * vp_pos[0] * (1.0 / vp_scale)) + (1.0 / vp_scale * buffer),
            (-1 * vp_pos[1] * (1.0 / vp_scale)) + (1.0 / vp_scale * buffer),
            (-1 * vp_pos[0] * (1.0 / vp_scale)) + ((1.0 / vp_scale) * (main_panel_right - main_panel_left)) - (1.0 / vp_scale * buffer),
            (-1 * vp_pos[1] * (1.0 / vp_scale)) + ((1.0 / vp_scale) * (main_panel_bottom - main_panel_top)) - (1.0 / vp_scale * buffer),
        ]
        return vp_bbox;
    }


    // CAN LATER USE THE FLOW VP TO SCOPE WHICH CARDS ARE POPULATED
    

     render() {
        
        //let my_text = this.props.document_data['uid1']['text']
        // let card_id_for_doc = current_document
        // let add_item_bottom_val = 40;
        // if (this.props.ui_data.settings.show_time_controls){
        //     add_item_bottom_val = 190;
        // }

        return (
            <div className="TimeLinePanel"
                onDoubleClick={this.handleMapDoubleClick}
                onMouseUp={this.handleMapMouseUp}
            >
                <Viewport
                    vp_type='map_viewport'
                    vp_id={VP_TO_USE}
                    
                    
                >
                    {/* {<Region item_type='region' item_id='r_uid1X'/>} */}
                    {/* {this.populateAllImages(VP_TO_USE) } */}
                    {this.populateAllRegions(this.props.current_viewport[1])}
                    {this.populateAllLocations(VP_TO_USE) }
                    {/* {this.populateTestCharacter(VP_TO_USE) } */}
                    {this.populateAllCharacters(VP_TO_USE) }
                    {/* {this.populateAllLocations_old(VP_TO_USE) } */}
                    {/* <MapItem/> */}


                </Viewport>
                <MapHud/>
                {/* <TimeControl/> */}

                {/* <AddItemButton show_time_controls={this.props.ui_data.settings.show_time_controls}>

                    <div 
                    style={{'backgroundColor': 'rgb(238, 225, 169)'}}
                    onClick={this.handleClickCreateCard} className='additem_test'> Create New Card</div>
                    <div 
                    style={{'backgroundColor': 'rgb(100, 190, 225)'}}
                    onClick={this.handleClickCreateRegion} className='additem_test'> Create New Backdrop</div>

                </AddItemButton> */}


            </div>
            
        );
    }
}

const mapStateToProps = (state) => {
    let current_vp = state.ui_data.current.current_viewport;
    let current_timeline = state.ui_data.current.current_timeline;
    return {
        location_data:state.location_data,
        character_data:state.character_data,
        ui_data:state.ui_data,
        current_timeline:state.ui_data.current.current_timeline,
        current_time:state.timeline_data[current_timeline].current_time,
        project_data:state.project_data,
        viewport_data:state[current_vp[0]+'_data'],
        current_viewport:state.ui_data.current.current_viewport,
        region_data:state.region_data,

   }
}

const mapDispatchToProps = (dispatch) => {
    return {
        select_item_c:(event, data) => dispatch(actionCreator_ui.select_item_c(event, data)),
        // mouse_up_flow_vp:(event, project_id, ui_data, viewport_data, card_data, thread_data) => dispatch(actionCreator_cards.mouse_up_flow_vp(event, project_id, ui_data, viewport_data, card_data, thread_data)),
        // create_location_at_centre_of_vp: (event, project_id, ui_data, viewport_data) => dispatch(actionCreator_maps.create_location_at_centre_of_vp(event, project_id, ui_data, viewport_data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(MapPanel);

