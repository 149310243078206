
const reducer_documents = (state, newState, action) => {


    if(action.type === 'NEW_DOCUMENT'){
        // console.log('RD NEW_DOCUMENT', action)


        newState['document_data'] = {...state['document_data']};

        newState.document_data[action.new_uid] = action.document_data;
    }
    
    if(action.type === 'SET_DOCUMENT_DATA'){
        //console.log('RD SET_DOCUMENT_DATA', action)
        newState['document_data'] = {...state['document_data']};
        newState.document_data[action.uid] = action.data;
    }


    if(action.type === 'DELETE_DOCUMENT'){
        // console.log('RD DELETE_DOCUMENT', action)

        newState['document_data'] = {...state['document_data']}

        delete newState.document_data[action.doc_id]; 
    }



    return newState;

};
export default reducer_documents;