// import React, { Component } from 'react';
import React from 'react';
import { connect} from 'react-redux';

import './ProjectContainer.css';
import ProjectCard from './ProjectCard';
import ProjectCardEmpty from './ProjectCardEmpty';

// import get_data_obj_from_string from '../../../../utils/get_data_obj_from_string'

// class ItemContainer extends Component {
// const get_projects = (user_id, user_data) => {
//     let id_list = Object.keys(user_data);

// }
const ProjectContainer = (props) =>   {

    // let id_list = Object.keys(props.project_data);
    let id_list = [];
    let user_id  = props.context_data.user;
    if (user_id){
        if (props.user_data.hasOwnProperty(user_id)){
            // console.log('ProjectContainer - user_id', user_id)
            if (props.user_data[user_id].hasOwnProperty('links')){
                if (props.user_data[user_id].links.hasOwnProperty('project')){
                    id_list = props.user_data[user_id]['links']['project']
                }
            }
            
            // console.log('ProjectContainer - id_list', id_list)

        }
    }

    return (
        <div className="projectContainer"> 
            <div className="projectContainerLarge-label"> Projects: </div>
            <div className="projectContainer-flex" >
            {
                
                id_list.map((id) => {
                    return( 
                            <ProjectCard key={id} project_id={id}/>
                        ); 
                    })
                }
                <ProjectCardEmpty/>
            </div>
        </div>
    );
}


const mapStateToProps = (state, props) => {

    return {
        project_data:state.project_data,
        ui_data:state.ui_data,
        user_data:state.user_data,
        context_data:state.context_data,
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
        // onAgeUp: () => dispatch({type:'AGE_UP'}),
        // onAgeDown: () => dispatch({type:'AGE_DOWN'}),
        // newCharacterButton: () => dispatch({type:'OPEN_CREATE_ITEM_PANEL'}),

    }
};

export default connect(mapStateToProps, mapDispatchToProps )(ProjectContainer);
