
/////////////////////// Cards Action Creators  /////////////////////////////
///////////////////////////////////////////////////////////////////////////////
import new_uid from '../../utils/new_uid';
import templates from '../templates/templates';
import * as Constants from '../../constants/constants';

import * as actions_database from './actions_database';
import * as actions_ui from './actions_ui';
import * as actions_story_items from './actions_story_items';
import * as database_utils from '../../utils/database_utils'

// var SKIP_DATABASE = true;
export const create_and_link_document = (item_data, item_type, item_id, project_id) => {
    //AAA console.log('AC create_card_at_current_pos', event, ui_data)

    return dispatch => {

        let uid = 'newDoc_'+new_uid();

        // let db_path = Constants.DB_ROOT+'/document_data/'+uid;
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'document', uid);


        //get card data from template and update pos and parent
        let doc_data = {...templates['document']};

        // console.log('doc_data', doc_data)
        // database.ref(db_path).set(card_data)
        actions_database.set_db_data(db_path, doc_data, window.SKIP_DATABASE)    
        .then (
            dispatch( { type: "NEW_DOCUMENT", 
                new_uid:uid, 
                document_data:doc_data,
            }),
        )
        // actions_story_items.link_items(item_data, item_type, item_id, 'document', [uid])
        dispatch(actions_story_items.link_items(item_data, item_type, item_id, 'document', [uid], project_id))
        dispatch(set_current_document(uid))

        dispatch( { 
            type: "SET_MODE", 
            mode:'writer',
        })

        dispatch( { 
            type: "SET_INPUT_VAL", 
            dataTarget:'ui_data.writing_panel_settings.show_item_type',
            val_to_set:item_type,
        })
        
    }
}
export const open_linked_document = (document_id, item_type=null) => {
    // console.log('AC open_linked_document', document_id)

    return dispatch => {

        dispatch(set_current_document(document_id))

        dispatch( { 
            type: "SET_MODE", 
            mode:'writer',
        })

        if (item_type){
            dispatch( { 
                type: "SET_INPUT_VAL", 
                dataTarget:'ui_data.writing_panel_settings.show_item_type',
                val_to_set:item_type,
            })
        }

    }
}



// TODO looks like i used delte thread as starting point for this and never did the work
export const delete_document = (thread_id, connection_id) => {
    // console.log('AC delete_thread', thread_id, connection_id )
    return dispatch => {
        // event.stopPropagation();
        let db_path = Constants.DB_ROOT+'/thread_data/'+thread_id+'/connections/'+connection_id

        actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "DELETE_THREAD", 
                thread_id:thread_id,
                connection_id:connection_id,
            }),
        )
    }
}


export const set_current_document = (document_id) => {
    // need to update action to action.payload etc...
    // console.log('AC set_current_document', document_id )
    return { type: "SET_CURRENT_DOCUMENT", document:document_id};
    // return dispatch => {
    //     console.log('AC set_current_document', document_id )

    //     // if (document_id){
    //     //     if (!(document_id in document_data)){
    //     //         dispatch(actions_database.load_project_document_from_db( [document_id]));
                
    //     //     } 
    //     // }
    //     dispatch( { 
    //         type: "SET_CURRENT_DOCUMENT", 
    //         document:document_id,
    //     })
    // }
};