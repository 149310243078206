import React, { Component } from 'react';
import './CreateNewCharacter.css';
import { connect} from 'react-redux';
import InputField from '../ui_elements/inputs/InputField';
import Button from '../ui_elements/inputs/Button';

import * as actionCreator_ui from '../../store/actions/actions_ui';

// var IMAGES_PATH = '../../images/';

class CreateNewProject extends Component {

    constructor(props) {
        super(props);
        this.handleClickCreate = this.handleClickCreate.bind(this);

    }

    handleClickCreate(event) {
        
        // let user_id = this.props.extra_data.user_id;
        let project_name = this.props.modal_data.new_project.name;
        // this.props.create_new_project( this.props.project_data, user_id, project_name);
        this.props.create_new_project(this.props.context_data, this.props.user_data, project_name);
        // this.props.modal_link_item(item_data, this.props.modal_input_data[1]['item_type'], this.props.modal_input_data[1]['item_id'], this.props.picked_items, this.props.modal_input_data[0], )
    
    
    }

    render() {

        let createWinStyle = null
        let createWinStyleBG = null
        // let windowWidth = 600;
        // let windowHeight = 800;

    

        return (
            <div className = 'CreateNewProject'>

                <InputField 
                    //dataSource=""+this.props.item_type+"_data.uid1.name"
                    dataSource={"ui_data.modal_data.new_project.name"}
                    dataTarget={"ui_data.modal_data.new_project.name"}
                    inputType='text' 
                    style={{width:"560px", top:"50px", position:'absolute'}} 
                    id='nameField' 
                    label='New Project' 
                    autoComplete="off"
                    do_set_db_data='false'
                />
                
                {/* <InputField 
                    //dataSource=""+this.props.item_type+"_data.uid1.description"
                    dataSource={"ui_data.modal_data.new_project.description"}
                    dataTarget={"ui_data.modal_data.new_project.description"}
                    inputType='textArea' 
                    style={{height:"300px", width:"330px", top:"130px", position:'absolute'}} 
                    id='AAA' 
                    label='Description' 
                /> */}
                <Button 
                        onClickFunction={this.handleClickCreate} 
                        //onClickFunction={this.test_on_click} 
                        label='Create Project'
                        style={{position:'absolute', height:"45px", width:"140px", right:"10px",bottom:"4px"}}
                    />


            </div>
        )
    };
};

const mapStateToProps = (state, props) => {
    return {
        // openModalWindow:state.ui_data.openModalWindow['create_'+props.item_type],
        modal_data:state.ui_data.modal_data,
        context_data:state.context_data,
        user_data:state.user_data,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // onModelCreateButton: (item_type) => dispatch({type:'ON_MODAL_CREATE_BUTTON', item_type:item_type}),
        // new_story_item_modal_create: (event, project_object, project_id, modal_data, item_type) => dispatch(actionCreator_ui.new_story_item_modal_create(event, project_object, project_id, modal_data, item_type)),
        create_new_project: (context, user_data, project_name) => dispatch(actionCreator_ui.new_project_modal_create(context, user_data, project_name)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps )(CreateNewProject);
// export default CreateNewCharacter;
