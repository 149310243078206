import React from 'react';
import './ColourSwatches.css';
import { connect} from 'react-redux';

// import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'
import new_uid from '../../../utils/new_uid'
import * as actionCreator_inputs from '../../../store/actions/actions_inputs';


const ColourSwatches = (props) => {
    // use this syntax to set defaults for vals
    // var myDefaultValue = props.myDefaultValue || 'hello';
    let rand_id  = 'button_'+new_uid;

    // let onClickCmd =null;
    // if (props.onClickFunction){
    //     // console.log('props.onClickFunction', props.label, props.onClickFunction)
    //     onClickCmd = props.onClickFunction;
    // }
    // else {
    //     onClickCmd = props.onClick;
    // }
    
    // // this will use  a provided function over the direct setting of state if specified
    // let onClickCmd =null;
    // if (props.onClickFunction){
    //     // //AAA console.log('props.onClickFunction', props.onClickFunction)
    //     onClickCmd = props.onClickFunction;
    // }
    // else {
    //     onClickCmd = props.onClick;
    // }

    // let color_list = [
    //     [255,0,0],
    //     [255,255,0],
    //     [0,255,0],
    //     [0,255,255],
    //     [0,0,255],
    //     [255,0,255],
    // ]
    let color_list = [
        [255,255,255],
        [97,189,79],
        [242,214,0],
        [255,159,26],
        [235,90,70],
        [195,119,224],
        [0,121,191],
        [0,194,224],
        [81,232,152],
        [255,120,203],
        [52,69,99],
        [179,186,197],
    ]

    let swatch_list = [];
    for (let index = 0; index < color_list.length; index++) {
        let rgb = color_list[index];
        // console.log('\n\n rgb', rgb)
        let col_string = 'rgb('+rgb[0] +','+rgb[1] +','+rgb[2] +')';
        // console.log('\n\n col_string', col_string)
        // console.log('\n\n props.dataTarget', props.dataTarget)


        swatch_list.push( <div key={index}
                style={{backgroundColor:col_string}} className='ColourSwatch'
                onClick={props.onColourSwitchClick.bind(this, props.dataTarget, rgb, props.current_project_id)}
            /> 
        )
        
    }
    return (
        // <div  className="ColourSwatchesContainerDiv">
        //     <div  className="ColourSwatchesContainerDiv-flex">
        //         {swatch_list}
        //     </div>
        // </div>

        <div>
            <label className='itemContainer-label'>{props.label + ' :'}</label>
            <div className="itemContainer"> 
                <div className="itemContainer-flex" >
                    {swatch_list}
                </div>
            </div>
        </div>


    )
}



// }


const mapStateToProps = (state) => {
    return {
        current_project_id:state.ui_data.current.current_project,
    }
}


const mapDispatchToProps = (dispatch, props) => {
    let do_set_db_data = true;
    if (props.do_set_db_data === 'false'){
        do_set_db_data = false;
    }

    if (!props.dataTarget){
        return{
            onColourSwitchClick:  (entity, event) => console.log('no data target!')
        }
    }
    return {
        // onClick:  (event) => dispatch({type:'ON_CLICK', event:event, valToSet:props.valToSet, dataTarget:props.dataTarget}),
        onColourSwitchClick: (dataTarget, valToSet, current_project_id, event) => dispatch(actionCreator_inputs.on_click(event, dataTarget, valToSet, current_project_id, do_set_db_data)),
    }

};

export default connect(mapStateToProps, mapDispatchToProps )(ColourSwatches);
