
const initial_threads  ={
    thread_data:{
        //THE FOLLOWING (cur_dragging) IS A TEMP HACK TO GET AROUND SLICE ISSUES
        cur_dragging:[null, null, 'end'],
        col_uid1:{
            name:'main plot',
            type:'plot',
            color:'red',
            connections: {},
            test:'xxxx',
            limit_connections:true,
        },
        col_uid2:{
            name:'sub plot',
            type:'story',
            color:'blue',
            connections:{
                // con_uid1:[['card', 'uid4'], ['card', 'uid5']],
            },
            
            limit_connections:true,
        }, 
        col_uid3:{
            name:'relationship',
            type:'relationship',
            color:'green',
            connections:{
                // con_uid1:[['card', 'uid1'], ['card', 'uid3']],
            },
            limit_connections:false,
        },   
        
        
    }
}



export default {...initial_threads}