

import React, { Component } from 'react';
import './ViewportHud.css';
import { connect} from 'react-redux';
import Button from '../inputs/Button';
import ThreadPicker from '../objects/thread_picker/ThreadPicker';

import * as actionCreator_viewports from '../../../store/actions/actions_viewports';
import * as actionCreator_story_items from '../../../store/actions/actions_story_items';
import * as viewport_utils from '../../../utils/viewport_utils';
import * as actionCreator_ui from '../../../store/actions/actions_ui';
import * as actions_users from '../../../store/actions/actions_users';

// var TIMELINE_TO_USE = 'story_percentage';

class ViewportHud extends Component {
    constructor(props) {
        super(props);
        this.handleClickDelete = this.handleClickDelete.bind(this);
        this.handleClickZoomIn = this.handleClickZoomIn.bind(this);
        this.handleClickZoomOut = this.handleClickZoomOut.bind(this);
        this.handleClickZoomFit = this.handleClickZoomFit.bind(this);
        this.handleHover = this.handleHover.bind(this);
        this.handleAddItemClick = this.handleAddItemClick.bind(this);

        // this.onKeyDown = this.onKeyDown.bind(this);
        this.state = {
            hovering:null,
        }
    }
    
    // onKeyDown(event){
    //     let key_down = event.key;
    //     console.log('onKeyDown XXXX', key_down)

    //     if (key_down === 'f'){
    //         this.handleClickZoomFit();
    //     }
    //     // this.props.setModifierKey(key_down, true)
        
    //   }
    handleAddItemClick(event) {
        let panel_name = 'add_item_flow'
        let element_id = 'add_item_flow'
        this.props.open_foldout_panel(panel_name, element_id);
        this.props.set_user_guide_b('add_items', true)

    }
    handleHover(which) {
        // console.log('hovering over buttton', which);
        this.setState({hovering:which})
    }

    handleClickDelete() {
        
        // TODO: This needs to switch to new sel array type
        // let current_sel = this.props.current_sel
        // event.stopPropagation();
        let current_sel = this.props.ui_data.control.selected;

        //AAA console.log('handleClickDelete. cursel', current_sel)
        // this.props.onMouseDown(event, 'card', this.props.item_id,);
        // this.props.onClickDelete(event, current_sel[0], current_sel[1]);
        // console.log('\n\n  handleClickDelete event ', event)
        // console.log('\n\n  handleClickDelete event done \n\n')

        if (current_sel){
            // console.log('event', event)
            this.props.onClickDelete_b(current_sel, this.props.ui_data.current.current_project);
        }
        else{
            console.log('NOTHING TO DELETE')
        }
    }

    handleClickZoomIn() {
        this.props.click_zoom(this.props.ui_data, this.props.viewport_data, 'in');
    }
    handleClickZoomOut() {
        this.props.click_zoom(this.props.ui_data, this.props.viewport_data, 'out');
    }
    handleClickZoomFit() {
        // console.log('handleClickZoomFit')
        this.props.fit_flow_viewport(this.props.ui_data, this.props.card_data, this.props.region_data, this.props.location_data);
    }
    render() {

        let enable_pointer_style = {pointerEvents:'all'};
        if (this.props.ui_data.control.begin_drag){
            enable_pointer_style = {};
        }

        let div_bottom = 0;
        if (this.props.ui_data.settings.show_time_controls){
            div_bottom = 120;
        }

        let top_row_offset = 50;
        if (this.props.ui_data.settings.attribute_panel_expanded){
            top_row_offset = 0;
        }
        
        let transition_style = "right 0.2s";
        if (this.props.ui_data.control.dragging[0] === 'handle'){
            transition_style = "";
        }
        let experimental_mode = this.props.ui_data.settings.experimental_mode

        return (
            <div style={{bottom:div_bottom}} className='ViewportHudDiv'
                // onKeyDown={this.props.onKeyDown}
            >
                {experimental_mode && 
                    <div
                        style={{display:'flex', transition:transition_style, position:'absolute', height:"80px", right:top_row_offset + 100+"px", top:"34px"}}
                        onMouseOver={() => this.handleHover('thread_picker')} 
                        onMouseLeave={() => this.handleHover(null)} 
                    >
                        {this.state.hovering === 'thread_picker' && 
                            <p className='buttonHoverLabel' 
                                style={{ top:"20px", right:"0px"}}
                            >Pick Thread Color</p>
                        }
                        <ThreadPicker
                            style={Object.assign({}, enable_pointer_style, {right:"0px",})}
                        />
                    </div>
                }



                <div
                    style={{display:'flex', position:'absolute', height:"80px", right:"0px", top:"34px"}}
                    onMouseOver={() => this.handleHover('trash')} 
                    onMouseLeave={() => this.handleHover(null)} 
                >
                    {this.state.hovering === 'trash' && 
                        <p className='buttonHoverLabel' 
                            style={{ top:"10px", right:top_row_offset +10 +"px"}}
                        >Delete Item</p>
                    }
                    <Button
                        buttonType='overlayButton'
                        dataTarget=''
                        valToSet={false}
                        onClickFunction={this.handleClickDelete} 
                        label='' 
                        icon='fas fa-trash'
                        id='cscsc' 
                        style={Object.assign({}, enable_pointer_style, {height:"80px", width:"80px", transition:transition_style, right:top_row_offset +"px",})}
                    />
                </div>


                <div
                    style={{display:'flex', position:'absolute', height:"80px", right:"0px", top:"190px"}}
                    onMouseOver={() => this.handleHover('zoom_in')} 
                    onMouseLeave={() => this.handleHover(null)} 
                >
                    {this.state.hovering === 'zoom_in' && 
                        <p className='buttonHoverLabel' >Zoom In</p>
                    }
                    <Button
                        buttonType='overlayButton'
                        dataTarget=''
                        valToSet={false}
                        onClickFunction={this.handleClickZoomIn} 

                        label='' 
                        icon='fas fa-plus'
                        //icon='fas fa-search-plus'
                        id='cscsc' 
                        style={Object.assign({}, enable_pointer_style, {cursor:'zoom-in', height:"80px", width:"80px", right:"0px",})}
                    />
                </div>

                <div
                    style={{display:'flex', position:'absolute', height:"80px", right:"0px", top:"250px"}}
                    onMouseOver={() => this.handleHover('zoom_fit')} 
                    onMouseLeave={() => this.handleHover(null)} 
                >
                    {this.state.hovering === 'zoom_fit' && 
                        <p className='buttonHoverLabel' >Zoom to Fit</p>
                    }

                    <Button
                        buttonType='overlayButton'
                        dataTarget='qqq.vvv'
                        valToSet={false}
                        label='' 
                        onClickFunction={this.handleClickZoomFit} 
                        // onMouseOver={() => this.handleHover('test')} 
                        //icon='fas fa-plus'
                        icon='fas fa-search'
                        id='cscsc' 
                        style={Object.assign({}, enable_pointer_style, {height:"80px", width:"80px", right:"0px"})}
                        // style={enable_pointer_style}
                    />
                </div>

                <div
                    style={{display:'flex', position:'absolute', height:"80px", right:"0px", top:"310px"}}
                    onMouseOver={() => this.handleHover('zoom_out')} 
                    onMouseLeave={() => this.handleHover(null)} 
                >
                    {this.state.hovering === 'zoom_out' && 
                        <p className='buttonHoverLabel' >Zoom Out</p>
                    }

                    <Button
                        buttonType='overlayButton'
                        dataTarget=''
                        valToSet={false}
                        label='' 
                        //icon='fas fa-search-minus'
                        onClickFunction={this.handleClickZoomOut} 

                        icon='fas fa-minus'
                        id='cscsc' 
                        style={Object.assign({}, enable_pointer_style, {cursor:'zoom-out', height:"80px", width:"80px", right:"0px"})}
                    />
                </div>
                <div
                    style={{display:'flex', fontSize:"70px", position:'absolute', height:"80px", right:"20px", bottom:"30px"}}
                    onMouseOver={() => this.handleHover('add_item')} 
                    onMouseLeave={() => this.handleHover(null)} 
                >
                    {this.state.hovering === 'add_item' && 
                        <p className='buttonHoverLabel' style={{right:"110px", top:"-10px"}}>Add New Item</p>
                    }

                    <i 
                        id='add_item_flow' 
                        className={'OverlayButtonDiv fas fa-plus-circle'} 
                        style={Object.assign({}, enable_pointer_style, {fontSize:"70px", height:"70px", width:"100px", right:"0px"})} 
                        onClick={this.handleAddItemClick} 
                    />
                </div>
               
            </div>
        );
    };
}
const mapStateToProps = (state) => {
    let current_vp = state.ui_data.current.current_viewport;
    return {
        // current_sel:state.ui_data.current_sel,
        current_viewport:state.ui_data.current.current_viewport,

        card_data:state.card_data,
        region_data:state.region_data,
        location_data:state.location_data,
        thread_data:state.thread_data,

        ui_data:state.ui_data,
        viewport_data:state[current_vp[0] + '_data'][current_vp[1]],

    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        click_zoom: (ui_data, viewport_data, direction) => dispatch(actionCreator_viewports.zoom_viewport(ui_data, viewport_data, direction)),
        fit_flow_viewport: (ui_data, card_data, region_data, location_data) => dispatch(actionCreator_viewports.fit_flow_viewport (ui_data, card_data, region_data, location_data)),
        onClickDelete_b: (item_data, project_id ) => dispatch(actionCreator_story_items.delete_items(item_data, project_id)),
        open_foldout_panel: (panel_name, element_id) => dispatch(actionCreator_ui.open_foldout_panel(panel_name, element_id)),
        set_user_guide_b: (guide, value) => dispatch(actions_users.set_user_guide_b(guide, value)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps )(ViewportHud);

