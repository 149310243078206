import React, { Component } from 'react';
import InputField from '../ui_elements/inputs/InputField';
// import InputField from '../ui_elements/inputs/InputField';
import Thumbnail from '../ui_elements/inputs/Thumbnail';
import ItemContainer from '../ui_elements/objects/item_container/ItemContainer';
import Button from '../ui_elements/inputs/Button';
import SelectMenu from '../ui_elements/inputs/SelectMenu';
import Attribute from '../ui_elements/inputs/Attribute';

import { connect} from 'react-redux';

import * as actionCreator_ui from '../../store/actions/actions_ui';
import * as actionCreator_documents from '../../store/actions/actions_documents';
import * as actionCreator_attributes from '../../store/actions/actions_attributes';
import clog from '../../utils/clog';


class ApCharacter extends Component {
    constructor(props) {
        super(props);
        this.handleThumbClick = this.handleThumbClick.bind(this);
        this.handleNewDoc = this.handleNewDoc.bind(this);
        this.handleOpenDoc = this.handleOpenDoc.bind(this);
        // this.handleAddLocationAttr = this.handleAddLocationAttr.bind(this);
        this.handleAddAttr = this.handleAddAttr.bind(this);
    }
    handleThumbClick (event){
        // console.log('ApRegion handleThumbClick',)
        this.props.open_modal_window('pick_image', {item_type:'character', item_id:this.props.item_id, 'avi':'test'})

    }
    handleNewDoc(event) {
        this.props.create_and_link_document(this.props.item_data, this.props.item_type, this.props.item_id, this.props.ui_data.current.current_project);
    }
    handleOpenDoc(event) {
        // this.props.link_items(this.props.item_data, this.props.item_type, this.props.item_id, 'character', ['uid2', 'uid1']);
        if (this.props.item_data.links.hasOwnProperty('document'))
        {
            if (this.props.item_data.links.document){
                let linked_doc = this.props.item_data.links.document[0];
                this.props.open_linked_document( linked_doc, this.props.item_type);

            }
        }
    }

    handleAddAttr(event) {
        // console.log('DO handleAddAttr')

        // TODO: need a system for dealing with multiple timelines

        let attr_id = 'location';
        let attr_parent = 'attrs';

        // todo here I should get the default value for the attr based on attr_data
        let attr_val = 0;
        // console.log('attr_val', attr_val)
        // console.log('item_type', this.props.item_type)
        // console.log('item_id', this.props.item_id)
        // console.log('ui_data', this.props.ui_data)
        // this.props.add_attr(this.props.item_type, this.props.item_id, attr_id, attr_val, attr_parent, this.props.ui_data.current.current_project)
    
        this.props.open_modal_window('add_attrs', {item:this.props.character_data[this.props.item_id], item_type:'character', item_id:this.props.item_id} )

    }
    
    populateAttrs(){
        /*
            // Loop through all attrs and add to attr ed
        */
        let attr_elements = [];

        let item = this.props.character_data[this.props.item_id];
        let attr_parent = 'attrs';
        let timeline=this.props.ui_data.current.current_timeline;

        let existing_attrs = [];
        if (item){
            if (item.hasOwnProperty(attr_parent)){
                for (let index = 0; index < Object.keys(item[attr_parent]).length; index++) {
                    existing_attrs.push( Object.keys(item[attr_parent])[index]);
                }
            }
        }

        for (let index = 0; index < existing_attrs.length; index++) {
            const attr_id = existing_attrs[index];
            // console.log('populateAttrs attr_id', attr_id)
            if (!this.props.attribute_data.hasOwnProperty(attr_id)){
                // console.log('%c\n\npopulateAttrs - SOMEThing has GONE WRONG. attr not found in attr data', 'background: #222; font-size: 10px; color: #bada55', attr_id);
                clog('populateAttrs - SOMEThing has GONE WRONG', attr_id, 0, ['red', 20])
                continue;
            }

            // const attr_id = Object.keys(this.props.attribute_data)[value];
            let attr_name = this.props.attribute_data[attr_id]['name']
            let attr_type = this.props.attribute_data[attr_id]['type']
            let attr_default = this.props.attribute_data[attr_id]['default']
            let attr_decimal_places; 
            if (this.props.attribute_data[attr_id].hasOwnProperty('decimal_places')){
                attr_decimal_places=this.props.attribute_data[attr_id]['decimal_places'];
            }
            let attr_min;
            let attr_max;
            if (this.props.attribute_data[attr_id].hasOwnProperty('min')){
                attr_min=this.props.attribute_data[attr_id]['min'];
            }
            if (this.props.attribute_data[attr_id].hasOwnProperty('max')){
                attr_max=this.props.attribute_data[attr_id]['max'];
            }

            // console.log('attr_name', attr_name)
            // console.log('attr_type', attr_type)
            let item_list = [];
            if (attr_type === 'list'){
                // console.log('x-x- attr_type', attr_type)
                item_list = this.props.attribute_data[attr_id]['list'];
                // test if object. if so derive list from it
                if (typeof item_list === 'object' && item_list !== null)
                {
                    // console.log('x-x- founf obj', item_list)

                    if (item_list['source'] === 'all_by_type'){
                        let by_type_data = this.props[item_list['type']+'_data']
                        // console.log('x-x- by_type_data', by_type_data)

                        // item_list = Object.keys(by_type_data);
                        item_list = [['', null]];
                        // item_list = [];
                        for (let index = 0; index < Object.keys(by_type_data).length; index++) {
                            const item_id = Object.keys(by_type_data)[index];
                            item_list.push([by_type_data[item_id]['name'], item_id])
                            
                        }
                        // console.log('x-x- item_list', item_list)

                    }
                }
            }
            // let item_list = ['aaa', 'bbb', 'ccc'];
            attr_elements.push(
                // <div style={{backgroundColor:'blue'}} > {attr_name + ' - ' + attr_type} </div>
                <Attribute 
                    label={attr_name}
                    inputValue={attr_default}
                    inputType={attr_type}
                    item={this.props.character_data[this.props.item_id]}
                    item_id={this.props.item_id}
                    item_type={this.props.item_type}
                    attr_id={attr_id}
                    attr_parent='attrs'
                    // attr_path={'attrs.'+attr_id}

                    current_project={this.props.ui_data.current.current_project}
                    time={this.props.timeline_data[timeline].current_time}
                    timeline={timeline}
                    // attr_path='age'
                    item_list={item_list}
                    decimal_places={attr_decimal_places}
                    // onChangeFunction = {this.changeSelectMenuFunction}
                    // function_args = {func_args}
                    min={attr_min}
                    max={attr_max}
                />
            )
            
        }
        return attr_elements;
    }










    render() {

        let item_id = this.props.item_id
        let linked_doc = null;
        //TODO this is hackey - i dont think i should do this - better to remove deleted items from history
        // so they cant turn up
        //
        if (!this.props.item_data){
            console.log('I DONT WANT TO GET HERE!')
            return (
                <div id="ap_character"> does not exist! </div>
            )
        }
        if (this.props.item_data.hasOwnProperty('links'))
        {
            if (this.props.item_data.links.hasOwnProperty('document'))
            {
                // console.log('DOC this.props.item_data.links.document', this.props.item_data.links.document)
                if (this.props.item_data.links.document){
                    linked_doc = this.props.item_data.links.document[0];
                }
            }
        }
        let button_to_add = null;
        if (this.props.ui_data.settings.mode !== 'writer')
        {
            button_to_add = <Button 
                onClickFunction={this.handleNewDoc} 
                label='Create New Document' 
                id='OKButtonb' 
            />
            if (linked_doc){
                button_to_add = <Button 
                    onClickFunction={this.handleOpenDoc} 
                    label='Go to Document' 
                    id='OKButtonb' 
                />
            }
        }


        return (
            <form>

                <Thumbnail 
                    dataSource={"character_data." + item_id}
                    //dataTarget={"prop_data." + item_id}
                    //inputType='text' 
                    //style={{position:"relative"}} 
                    //id='thumb' 
                    //label='thmmbb'
                    onClick={this.handleThumbClick} 
                />

                <InputField 
                    dataSource={"character_data." + item_id + ".name"}
                    dataTarget={"character_data." + item_id + ".name"}
                    inputType='text' 
                    style={{position:"relative"}} 
                    // id='nameField' 
                    label='Name' 
                />
                {/* <InputField 
                    dataSource={"character_data." + item_id + ".gender"}
                    dataTarget={"character_data." + item_id + ".gender"}
                    inputType='text' 
                    style={{position:"relative"}} 
                    // id='genderField' 
                    label='Gender' 
                /> */}
                <SelectMenu
                    dataSource={"character_data." + item_id + ".gender"}
                    dataTarget={"character_data." + item_id + ".gender"}
                    item_list={['', 'Male', 'Female', 'Other']}
                    label='Gender'
                    style={{position:"relative"}} 

                 />
                <InputField 
                    dataSource={"character_data." + item_id + ".age"}
                    dataTarget={"character_data." + item_id + ".age"}

                    inputType='number' 
                    style={{position:"relative"}} 
                    // id='ageField' 
                    label='Age' 
                />
                <InputField

                    dataSource={"character_data." + item_id + ".dob"}
                    dataTarget={"character_data." + item_id + ".dob"}
                    inputType='date' 
                    style={{position:"relative"}} 
                    // id='AageField' 
                    label='Date of Birth' 
                    />
                <InputField 
                    dataSource={"character_data." + item_id + ".description"}
                    dataTarget={"character_data." + item_id + ".description"}
                    inputType='textArea' 
                    style={{position:"relative"}} 
                    // id='AAA' 
                    label='Description' 
                />
                {button_to_add}
                <div className='Separator' />
                <ItemContainer 
                    dataSource={"character_data." + item_id + '.links.prop'}
                    containerLabel='Linked Props' 
                    parent_data={{item_type:'character', item_id:item_id}}
                    item_type='prop' 
                    itemSize='small'
                    // mode='link'
                    mode='attr_editor'
                />
                <div className='Separator' />

                {/* <Button 
                    onClickFunction={this.handleAddLocationAttr} 
                    label='Add Location attr - test button' 
                    id='OKButtonb' 
                /> */}
                {this.populateAttrs()}
                <div className='Separator' />

                <Button 
                    // style={{'backgroundColor':'red'}}
                    onClickFunction={this.handleAddAttr} 
                    label='+ Add New Attribute' 
                    id='AddAttrButton' 
                    className='ButtonDashed'
                />

            </form>
        
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ui_data:state.ui_data,
        character_data:state.character_data,
        location_data:state.location_data,
        prop_data:state.prop_data,
        event_data:state.event_data,
        timeline_data:state.timeline_data,
        attribute_data:state.attribute_data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        create_and_link_document: (item_data, item_type, item_id, project_id) => dispatch(actionCreator_documents.create_and_link_document(item_data, item_type, item_id, project_id)),
        open_linked_document: (document_id, item_type) => dispatch(actionCreator_documents.open_linked_document(document_id, item_type)),
        open_modal_window: (modal_name, input_data) => dispatch(actionCreator_ui.open_modal_window(modal_name, input_data)),
        // add_attr: ( item_type, item_id, attr_id, attr_val, attr_parent, project_id) => dispatch(actionCreator_attributes.add_attr( item_type, item_id, attr_id, attr_val, attr_parent, project_id)),
        set_attr: ( item, item_type, item_id, attr_id, attr_val, attr_parent, project_id, time, timeline) => dispatch(actionCreator_attributes.set_attr( item, item_type, item_id, attr_id, attr_val, attr_parent, project_id,  time, timeline)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps )(ApCharacter);
