import React, { Component } from 'react';
import './TimelinePanel.css';
import { connect} from 'react-redux';
// import ThreadPicker from '../ui_elements/inputs/ThreadPicker';
import '../ui_elements/inputs/InputField.css';
// import StoryItem from '../ui_elements/objects/story_item/StoryItem';
// import InputField from '../ui_elements/inputs/InputField';
// import IndexCard from '../ui_elements/objects/card/IndexCard';
// import CardThread from '../ui_elements/objects/path/CardThread';

import Viewport from '../ui_elements/objects/viewports/Viewport';
import Timeline from '../ui_elements/objects/viewports/Timeline';
import TimeCursor from '../ui_elements/objects/time/TimeCursor';


import TimeEvent from '../ui_elements/objects/time/TimeEvent';

import TimelineHud from '../ui_elements/huds/TimelineHud';
// import TimeControl from '../ui_elements/objects/time/TimeControl';

import TimelineCard from '../ui_elements/objects/card/TimelineCard';

import * as time_utils from '../../utils/time_utils'
import * as actionCreator_timeline from '../../store/actions/actions_timelines';


// var TIMELINE_TO_USE = 'story_percentage';
// var VP_TO_USE = 'card_uid1';

class TimelinePanel extends Component {
    constructor(props) {
        super(props);
        // this.handleFlowMouseDown = this.handleFlowMouseDown.bind(this);
        this.handleMouseWheel = this.handleMouseWheel.bind(this);
    }
    
    // handleFlowMouseDown(event) {
    //     this.props.onFlowMouseDown(event, 'viewport', 'topLevel');
    // }

    handleMouseWheel(event) {
        // console.log('HEY?')
        this.props.mouse_wheel_timeline(event, this.props.ui_data, this.props.timeline_data,);
    }

    populateAllTimeCards() {
        // CAN LATER USE THE FLOW VP TO SCOPE WHICH CARDS ARE POPULATED
        var result = Object.keys(this.props.card_data).map((card) => {
            // console.log('A here1', card)

            if (this.props.card_data[card].hasOwnProperty('time')){
                // console.log('A here2')

                if (this.props.card_data[card].time.hasOwnProperty(this.props.current_tl)){
                    // console.log('A here3', card.time)
                    return(
                        <TimelineCard 
                            key={card}
                            item_id={card}
                        />
                    )
                }
            }
        })
        return  result;
    }

    populateAllTimeEvents() {
        // console.log(' in new populateAllTimeEvents!');
        let event_componets_array = [];

        let tl_scale = this.props.timeline_data.scale;
        let label_width = 0.75;



        let event_start_end_row_data = time_utils.get_event_start_end_row_data( this.props.event_data, tl_scale,  this.props.current_tl, label_width)
        // console.log('populateAllTimeEvents event_start_end_row_data', event_start_end_row_data);

        for (let index = 0; index < Object.keys(event_start_end_row_data).length; index++) {
            let event_id = Object.keys(event_start_end_row_data)[index];
            let start_end_row = event_start_end_row_data[event_id];

            let event_name = this.props.event_data[event_id]['name']
            let event_color = this.props.event_data[event_id]['color']

            event_componets_array.push(

                <TimeEvent
                    key={index}
                    start_time={start_end_row[0]}
                    end_time={start_end_row[1]}
                    item_id={event_id}
                    name={event_name}
                    color={event_color}
                    row={start_end_row[2]}
                />
            )

        }
        return(event_componets_array)
    }

    populateAllTimeEventsX() {
        // console.log(' in new populateAllTimeEvents!');
        let event_componets_array = [];

        let tl_scale = this.props.timeline_data.scale;
        let label_width = 0.75;



        let event_start_end_row_data = time_utils.get_event_start_end_row_data( this.props.event_data, tl_scale,  this.props.current_tl, label_width)
        // console.log('populateAllTimeEvents event_start_end_row_data', event_start_end_row_data);

        let event_data = this.props.event_data;
        // for (let index = 0; index < Object.keys(event_data).length; index++) {
        //     let event_id = Object.keys(event_data)[index];
        for (let index = 0; index < Object.keys(event_start_end_row_data).length; index++) {
            let event_id = Object.keys(event_start_end_row_data)[index];

            let start_end = event_data[event_id]['time'][this.props.current_tl]['start_end_time'];
            let row =  event_start_end_row_data[event_id][2]
            let event_name = event_data[event_id]['name']
            let event_color = event_data[event_id]['color']

            event_componets_array.push(

                <TimeEvent
                    start_time={start_end[0]}
                    end_time={start_end[1]}
                    item_id={event_id}
                    name={event_name}
                    color={event_color}
                    row={row}
                />
            )

        }
        return(event_componets_array)
    }


   
    // CAN LATER USE THE FLOW VP TO SCOPE WHICH CARDS ARE POPULATED
    

     render() {
        
        //let my_text = this.props.document_data['uid1']['text']
        // let card_id_for_doc = current_document
        return (
            <div className="TimeLinePanel"
                onWheel={this.handleMouseWheel}
            >
                <Timeline
                    vp_type='timeline'
                    vp_id={this.props.current_tl}
                    
                >
                    {this.populateAllTimeCards()}
                   
                </Timeline>
                {/* <TimeEvent
                    start_time='5'
                    item_id='aaaa'
                    name='birthday'
                    color='hotpink'
                />
                <TimeEvent
                    start_time='2'
                    item_id='bbb'
                    name='something'
                    color='blue'
                /> */}
                {this.populateAllTimeEvents()}
                <TimeCursor/>
                <TimelineHud/>
                {/* <TimeControl/> */}
            </div>
            
        );
    }
}

const mapStateToProps = (state) => {
    let current_tl = state.ui_data.current.current_timeline;

    return {
        card_data:state.card_data,
        event_data:state.event_data,
        timeline_data:state.timeline_data[current_tl],
        ui_data:state.ui_data,
        current_tl:current_tl,
        // current_time:state.timeline_data[current_tl].current_time,
   }
}

const mapDispatchToProps = (dispatch) => {
    return {
        mouse_wheel_timeline: (event, ui_data, timeline_data) => dispatch(actionCreator_timeline.mouse_wheel_timeline(event, ui_data, timeline_data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(TimelinePanel);

