import React, { Component } from 'react';
import { connect} from 'react-redux';
// import './FoldoutPanel.css';

// import * as utils from '../../../../utils/misc_utils'
// import * as actionCreator_ui from '../../../../store/actions/actions_ui';

import UserPanel from '../user_item/UserPanel';
import FoldoutPanel from './FoldoutPanel';
import WritingFilterOptions from './WritingFilterOptions';
import AttrEdStoryItemOptions from './AttrEdStoryItemOptions';
import AttrEdKeyframeOptions from './AttrEdKeyframeOptions';
import ThreadPickerList from './ThreadPickerList';
import AddItemsFlow from './AddItemsFlow';
import AddItemsMap from './AddItemsMap';
import AddItemsTimeline from './AddItemsTimeline';

class FoldoutPanelGenerator extends Component {
    // constructor(props) {

    // }

    handleClickOutside() {
        // this.props.close_user_panel()
        this.props.close_all_foldout_panels()
        
    }
    render(){
        let panel_name = this.props.panel_name;
        let element_id = this.props.element_id;
        let extra_data = this.props.extra_data;
        // console.log('FoldoutPanelGenerator', panel_name, element_id, extra_data)
        let child_panel = null;
        let panel_width = this.props.panel_width || 150;
        let panel_height = this.props.panel_height || 200;
        let panel_side = this.props.panel_side || 'bottom';
        let panel_offset = this.props.panel_offset || 5;
        let show_arrow = this.props.show_arrow || false;
        let dark_bg = this.props.dark_bg || true;

        let experimental_mode = this.props.ui_data.settings.experimental_mode


        // making an array attr for these settings as it will be fewer lines of code if i have lots of these
        let panel_placement = [panel_width, panel_height, panel_side, panel_offset];

        if (panel_name === 'user_settings'){
            child_panel = <UserPanel />
            panel_placement = [240, null, 'bottom', -100]
        }
        if (panel_name === 'writing_filter'){
            // child_panel = <div className='testFoldoutChildDiv'> this is where my filter options would go</div>
            child_panel = <WritingFilterOptions/>
            panel_placement = [300, 150, 'right', 30]
            if (experimental_mode){
                panel_placement = [300, 300, 'right', 100]
            }
        }
        if (panel_name === 'attr_ed_story_item'){
            // child_panel = <div className='testFoldoutChildDiv'> this is where my filter options would go</div>
            // tokenise element_id to get the following. Than feed into AttrEdStoryItemOptions
            let item_type = element_id.split('-')[1];
            let item_id = element_id.split('-')[2];
            child_panel = <AttrEdStoryItemOptions item_type={item_type} item_id={item_id} />
            panel_placement = [110, 120, 'left', 0]
            show_arrow = true;
            dark_bg = false;
        }
        if (panel_name === 'attr_time_icon'){
            // child_panel = <div className='testFoldoutChildDiv'> this is where my filter options would go</div>
            // tokenise element_id to get the following. Than feed into AttrEdStoryItemOptions
            let item_type = element_id.split('-')[0];
            let item_id = element_id.split('-')[1];
            child_panel = <AttrEdKeyframeOptions item_type={item_type} item_id={item_id} extra_data={extra_data} />
            panel_placement = [210, 80, 'left', 0]
            show_arrow = true;
            dark_bg = false;
        }

        if (panel_name === 'flow_thread_picker_list'){
            child_panel = <ThreadPickerList/>
            panel_placement = [270, 120, 'bottom', 0]
            // show_arrow = true;
            // dark_bg = false;
        }

        if (panel_name === 'add_item_flow'){
            child_panel = <AddItemsFlow/>
            panel_placement = [220, 90, 'left', 0]
        }

        if (panel_name === 'add_item_map'){
            child_panel = <AddItemsMap/>
            // panel_placement = [220, 90, 'top', -70]
            panel_placement = [220, 90, 'left', 0]
        }
        if (panel_name === 'add_item_timeline'){
            child_panel = <AddItemsTimeline/>
            // panel_placement = [220, 90, 'top', -70]
            panel_placement = [220, 90, 'left', 0]
        }


        // console.log('FoldoutPanelGenerator dark_bg', dark_bg);

        return (
            <FoldoutPanel 
                panel_name={panel_name} 
                element_id={element_id}
                panel_width={panel_placement[0]} 
                panel_height={panel_placement[1]} 
                panel_side={panel_placement[2]} 
                panel_offset={panel_placement[3]} 
                show_arrow={show_arrow}
                dark_bg={dark_bg}
            >
                {child_panel}
            </FoldoutPanel>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ui_data:state.ui_data,
    //   user_data:state.user_data,
    //   context_data:state.context_data,
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        // close_all_foldout_panels: () => dispatch(actionCreator_ui.close_all_foldout_panels()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FoldoutPanelGenerator);
