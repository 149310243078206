const initial_characters  = {
    character_data:{
        // 'uid1':{
        //     name:'jerry',
        //     age:35,
        //     icon:"fas fa-user",
        //     image:"jerry.jpg",
        //     description:'The main character.\nA real funny guy.',
        //     itemSize:'large',
        //     dob:"1978-05-15",
        //     links: {
        //         prop:['uid2', 'uid3'],
        //     },
        // },
        // 'uid2':{
        //     name:'george',
        //     age:45,
        //     icon:"fas fa-user",
        //     description:'the sidekick',
        //     itemSize:'large',
        //     image:"george.jpg",
        //     links: {
        //         prop:['uid1', 'uid3'],
        //     },

        // },
        // 'uid3':{
        //     name:'elaine',
        //     age:25,
        //     icon:"fas fa-user",
        //     description:'the ex',
        //     image:"elaine.jpg",
        //     links: {
        //         prop:['uid1', 'uid1','uid1','uid3'],
        //     },
        // },
        // 'uid4':{
        //     name:'Kramer',
        //     age:35,
        //     icon:"fas fa-user",
        //     description:'The pod.',
        //     itemSize:'large',
        //     dob:"1978-05-15",
        //     image:null,
        //     links: {},
        // },
        // 'uid5':{
        //     name:'Newman',
        //     age:45,
        //     icon:"fas fa-user",
        //     description:'the fatman',
        //     itemSize:'large',
        //     image:null,
        //     links: {},


        // },
        // 'uid6':{
        //     name:'Frank',
        //     age:25,
        //     icon:"fas fa-user",
        //     description:'Blow out the candles!',
        //     image:"",
        //     links: {},

        // },
        // 'uid7':{
        //     name:'Putty',
        //     age:35,
        //     icon:"fas fa-user",
        //     description:'the boyfriend.',
        //     itemSize:'large',
        //     dob:"1978-05-15",
        //     image:"",
        //     links: {},

        // },

    },
};


export default initial_characters


