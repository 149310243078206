import React, { Component } from 'react';
import './CreateNewCharacter.css';
import { connect} from 'react-redux';
import InputField from '../ui_elements/inputs/InputField';
import SelectMenu from '../ui_elements/inputs/SelectMenu';
import Button from '../ui_elements/inputs/Button';
import FilePicker from '../ui_elements/inputs/FilePicker';
import ProgressBar from '../ui_elements/objects/general/ProgressBar';

import * as actionCreator_ui from '../../store/actions/actions_ui';
import new_uid from '../../utils/new_uid';

// var IMAGES_PATH = '../../images/';

class CreateNewCharacter extends Component {

    constructor(props) {
        super(props);
        this.handleClickCreate = this.handleClickCreate.bind(this);
        // this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);

        this.progress_cb = this.progress_cb.bind(this);
        this.complete_cb = this.complete_cb.bind(this);
        this.filechange_cb = this.filechange_cb.bind(this);
        // this.okClicked = this.props.okClicked || false;
        this.okClicked = false;
        // this.ok_clicked_data = this.props.ok_clicked_data || null;
        // this.extra_data = this.props.extra_data || null;

        this.uid = this.props.item_type + '_' + new_uid();


    }
    state = {
        pic_to_upload:null,
        pictureUrl:null,
        progress:100,
        doUpload:false,
        // uploadTriggered:false,
      }
    
    // handleClickCreate(event) {
    //     console.log('modal_data', this.props.modal_data) 
    //     let project_id = 'uid1';
    //     this.props.new_story_item_modal_create(event, this.props.project_object, project_id, this.props.modal_data, this.props.item_type);
    // }

    // getAlert() {
    //     alert('getAlert from Child');
    //   }

    handleFileChange(file, pic_url) {
    
        this.setState({pic_to_upload:file, pictureUrl:pic_url, progress:100})
      }

    // handleFileUpload() {
    //     this.setState({doUpload:true,})
    //   }
    
    progress_cb(progress) {
        // console.log('CB prog ', progress)
        this.props.upload_progress(progress)
        this.setState({progress:progress})

    }
    complete_cb(url) {
        // console.log('CB complete ', url)
        // let dataTarget = 'character_data.'+this.uid+'.image'
        // this.props.upload_complete(url)
        this.props.close_modal()

        // put thing in here to close ui (or close and let upload happen while app is active)
        // this.props.extra_data.cancel_function(event, "ui_data.openModalWindow."+this.props.extra_data.modal_name, null)
    }
    
    filechange_cb(file, url) {
        // console.log('CB filechange_cb ', file, url )
        this.props.file_change()
    }
    

    handleClickCreate(event){
        // console.log('CREATRE NEW CHAR handleClickCreate', this.props.extra_data);
        // console.log('CREATRE NEW CHAR state', this.state);
        // I should have everything i need to 
        // alert('CREATRE NEW CHAR', this.props.extra_data);

        if (!this.state.uploadTriggered){
            this.setState({doUpload:true,})

            // console.log('CREATRE NEW CHAR through tests doUpload');
            // settting this to ensure the upload only happens once
            this.setState({uploadTriggered:true})
        }
        
        let project_id = this.props.extra_data['project_id']
        // let project_object = this.props.extra_data['project_object']
        let modal_data = this.props.extra_data['modal_data']
        // let item_type = this.props.extra_data['item_type']
        let modal_input_data = this.props.extra_data['modal_input_data']
        let item_type = modal_input_data.item_type
        let group_id = modal_input_data.group_id

        // console.log('SS CreateNewCharacter modal_input_data', modal_input_data)
        // console.log('SS sCreateNewCharacter group_id', group_id)

 
        this.props.new_story_item_modal_create(this.uid, project_id, modal_data, item_type, group_id);
        // this.props.new_story_item_modal_create(this.uid, project_id, modal_data, item_type);

        if (this.state.pictureUrl === null){
            // console.log('DIDNT FIND PIC', this.state)
            // this.props.close_modal();
        }
        // this.props.extra_data.cancel_function(event, "ui_data.openModalWindow."+this.props.extra_data.modal_name, null)


    }
    // componentDidUpdate(){
    //     this.ok_clicked_data = this.props.ok_clicked_data;
    //     console.log('CREATRE NEW CHAR componentDidUpdate okClicked', this.okClicked);
    //     console.log('CREATRE NEW CHAR componentDidUpdate ok_clicked_data', this.ok_clicked_data);
    //     if (!this.okClicked){
    //         if (this.ok_clicked_data){
    //             if (!this.state.uploadTriggered){
    //                 this.setState({doUpload:true,})

    //                 console.log('CREATRE NEW CHAR through tests doUpload');
    //                 // settting this to ensure the upload only happens once
    //                 this.setState({uploadTriggered:true})
    //             }
    //             console.log('CLCIKED OK DATA', this.ok_clicked_data)
                
    //             let project_id = this.ok_clicked_data['project_id']
    //             let project_object = this.ok_clicked_data['project_object']
    //             let modal_data = this.ok_clicked_data['modal_data']
    //             let item_type = this.ok_clicked_data['item_type']
                

    //             console.log('CreateNewCharacter - componentDidUpdate - project_object', project_object)

    //             this.props.new_story_item_modal_create(this.uid, project_object, project_id, modal_data, item_type);
    //             this.okClicked = true;
    //         }
    //     }
    //     else{
    //         // If i've clicked i need to close modal. will do that by triggering cancel function which has been passed down
    //         // no idea if this will work or is stupid way of doing this.
    //         let args = this.ok_clicked_data.cancel_args;
    //         console.log('*** CLICKED NOW HERE - ', args)
    //         this.ok_clicked_data.cancel_function(args[0], args[1], args[2])
    //     }

    // }

    render() {
        let upload_hide_style = {};
        let upload_show_style = {display:'none'}
        if (this.state.doUpload){
            upload_hide_style = {display:'none'};
            upload_show_style = {}
        }

        return (
            <div className = 'CreateNewCharacter'>
                {/* <input type='hidden' value='something'/> */}

                <div className='TopRightArea' style={upload_hide_style}>
                    {/* <div id='imageDiv'> */}
                        {/* <img id="itemImage" src={require('"' + IMAGES_PATH + 'jerry.jpg"')} /> */}
                        {/* <img id="itemImage" src={require('../../images/jerry.jpg')} /> */}
                        <FilePicker 
                            doUpload={this.state.doUpload} 
                            //storage_path='test/aaa'
                            storage_path={'character_data/'+this.uid}
                            file_change_callback={this.filechange_cb}
                            progress_callback={this.progress_cb}
                            complete_callback={this.complete_cb}
                            nothing_picked_callback={this.props.close_modal}
                            //dataTarget='my_items.uid1.image'
                            dataTarget={'character_data.'+this.uid+'.image'}
                            placeholder_image_data = {['icon', 'fas fa-user']}
                        />
                    {/* </div> */}
                </div>
                <div className='TopLeftArea' style={upload_hide_style}>
                    <InputField 
                        //dataSource=""+this.props.item_type+"_data.uid1.name"
                        dataSource={"ui_data.modal_data.create_"+this.props.item_type+".name"}
                        dataTarget={"ui_data.modal_data.create_"+this.props.item_type+".name"}
                        inputType='text' 
                        //style={{width:"330px", top:"70px", position:'absolute'}} 
                        id='nameField' 
                        label='Name' 
                    />
                    {/* <InputField 
                        //dataSource=""+this.props.item_type+"_data.uid1.name"
                        dataSource={"ui_data.modal_data.create_"+this.props.item_type+".gender"}
                        dataTarget={"ui_data.modal_data.create_"+this.props.item_type+".gender"}
                        inputType='text' 
                        //style={{width:"330px", top:"70px", position:'absolute'}} 
                        id='genderField' 
                        label='Gender' 
                    /> */}
                    <SelectMenu
                        dataSource={"ui_data.modal_data.create_"+this.props.item_type+".gender"}
                        dataTarget={"ui_data.modal_data.create_"+this.props.item_type+".gender"}
                        item_list={['', 'Male', 'Female', 'Other']}
                        label='Gender'
                        style={{position:"relative"}} 
                    />
                    <InputField 
                        //dataSource=""+this.props.item_type+"_data.uid1.age"
                        dataSource={"ui_data.modal_data.create_"+this.props.item_type+".age"}
                        dataTarget={"ui_data.modal_data.create_"+this.props.item_type+".age"}

                        inputType='number' 
                        //style={{width:"330px", top:"150px", position:'absolute'}} 
                        id='ageField' 
                        label='Age' 
                    />
                    <InputField

                        //dataSource=""+this.props.item_type+"_data.uid1.dob"
                        dataSource={"ui_data.modal_data.create_"+this.props.item_type+".dob"}
                        dataTarget={"ui_data.modal_data.create_"+this.props.item_type+".dob"}
                        inputType='date' 
                        //style={{height:"100px", position:'absolute'}} 
                        id='AageField' 
                        label='Date of Birth' 
                    />
                </div>
                <div className='BottomArea' style={upload_hide_style}>
                    <InputField 
                        //dataSource=""+this.props.item_type+"_data.uid1.description"
                        dataSource={"ui_data.modal_data.create_"+this.props.item_type+".description"}
                        dataTarget={"ui_data.modal_data.create_"+this.props.item_type+".description"}
                        inputType='textArea' 
                        style={{height:"200px"}} 
                        id='AAA' 
                        label='Description' 
                    />

                </div>
                <Button 
                        onClickFunction={this.handleClickCreate} 
                        //onClickFunction={this.test_on_click} 
                        label='Create Character'
                        style={Object.assign({}, upload_hide_style, {position:'absolute', height:"45px", width:"140px", right:"10px",bottom:"4px"})}
                    />


                <div className='UploadArea' style={upload_show_style}>
                {/* <div className='UploadArea' > */}
                    Uploading ...
                    <ProgressBar progress={this.state.progress}/>
                </div>
            </div>
        )
    };
};

const mapStateToProps = (state, props) => {
    return {
        // openModalWindow:state.ui_data.openModalWindow['create_'+props.item_type],
        // is_uploading:state.ui_data.layout.is_uploading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // onModelCreateButton: (item_type) => dispatch({type:'ON_MODAL_CREATE_BUTTON', item_type:item_type}),
        new_story_item_modal_create: (uid, project_id, modal_data, item_type, group_id) => dispatch(actionCreator_ui.new_story_item_modal_create(uid, project_id, modal_data, item_type, group_id)),
        // upload_complete: (dataTarget, url) => dispatch({type:'SET_PIC_URL', dataTarget:dataTarget, value:url}),
        upload_progress: (progress) => dispatch({type:'SET_PROGRESS', value:progress}),
        file_change: () => dispatch({type:'SET_FILE_CHANGE', value:'hello'}),
        close_modal: () => dispatch({type:'CLOSE_MODAL_WINDOW', modal_name:'create_character'}),

    
    }
};

export default connect(mapStateToProps, mapDispatchToProps )(CreateNewCharacter);
// export default CreateNewCharacter;
