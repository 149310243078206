
/////////////////////// Timeline Action Creators  /////////////////////////////
///////////////////////////////////////////////////////////////////////////////
/*
*/

import * as actions_database from './actions_database';
import * as Constants from '../../constants/constants';
import * as database_utils from '../../utils/database_utils'
import get_mouse_pos_in_viewport from '../../utils/get_mouse_pos_in_viewport'
import new_uid from '../../utils/new_uid';
import templates from '../templates/templates';


export const create_event_at_current_time = (event,  project_id, ui_data,  timeline_data) => {
    // console.log('AC create_event_at_current_time', event,  project_id, ui_data, timeline_data)

    return dispatch => {
        let current_timeline = ui_data.current.current_timeline;

        let centre_pos = [
            ui_data.layout.main_panel_left  +  (.5 * ( ui_data.layout.main_panel_right - ui_data.layout.main_panel_left)),
            ui_data.layout.main_panel_top  +  (.5 * ( ui_data.layout.main_panel_bottom - ui_data.layout.main_panel_top)),
        ];


        // // THIS IS FOR TIMELINE

        let tl_Pos = timeline_data[current_timeline].pos;
        let tl_scale = timeline_data[current_timeline].scale;

    
        let tl_centre_pos = get_mouse_pos_in_viewport(
            [ui_data.layout.main_panel_left, ui_data.layout.main_panel_top], 
            tl_Pos,
            tl_scale,
            centre_pos
            )
        let new_start_end = [
            (tl_centre_pos[0] * .01) - 0,
            (tl_centre_pos[0] * .01) + 0,
        ]
        

        let uid = 'newCard_'+new_uid();


        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'event', uid);

        let event_data = {...templates['event']};
        event_data['time']={[current_timeline]:{start_end_time:new_start_end}}

        // console.log('event_data', event_data)
        actions_database.set_db_data(db_path, event_data, window.SKIP_DATABASE)    
        .then (
            dispatch( { type: "SET_EVENT_DATA", 
                uid:uid, 
                data:event_data,
            }),
        )
        .then (
            dispatch( { 
                type: "SELECT_ITEM_C", 
                data: {[uid]:{item_type:'event'}},
                select_mode: 'replace',
            })
        )

    }


}

export const mouse_down_time_event = (event, ui_data, event_id, event_item_data) => {
    // for when mouse is down on card - store orig position
    return dispatch => {
        // console.log('AC mouse_down_time_event',  ui_data, event_item_data);

        event.stopPropagation();
            
        let current_timeline = ui_data.current.current_timeline;
        let start_end_time = event_item_data['time'][current_timeline]['start_end_time']
        // let timeline_row = card_item_data['time'][current_timeline]['row']


        dispatch({ type: "TIMELINE_EVENT_MOUSE_DOWN", 
            start_end_time:start_end_time,
            //timeline_row:timeline_row,
        })

        let clicked_data = {
            [event_id]:{item_type:'event', 
                custom_data:{},
            }
        }
        dispatch({  
            type: "SELECT_ITEM_C", 
            data:clicked_data,
        })
    }
}


export const mouse_drag_time_event = (event,  ui_data, event_item_data) => {
    // console.log('AC mouse_drag_time_event', event, ui_data, tl_data )
    
    let mousePos = [event.clientX, event.clientY];
    
    let locked = false;
    if (event_item_data.hasOwnProperty('locked')){
        locked = event_item_data.locked;
    }
    if (locked){
        return { type: "X",}
    }

    // let time = get_time_from_timeslider(mousePos, ui_data, tl_data, true, true)
    
    
    // let mouse_drag_offset = ui_data.control.mouse_drag_offset;
    // let local_down_pos = ui_data.control.local_down_pos;
    // let cur_time = tl_data['current_time']
    // let new_time =  cur_time + Math.round(.1 * (local_down_pos[0] + mouse_drag_offset[0]))
    
    
    let dragging = ui_data.control.dragging;
    let local_drag_offset = ui_data.control.local_drag_offset;

    let current_timeline = ui_data.current.current_timeline;

    let local_orig_start_end_time = ui_data.control.local_orig_start_end_time;



    let start_end_time = [
        local_orig_start_end_time[0] + (.01 * local_drag_offset[0]), 
        local_orig_start_end_time[1] + (.01 *  local_drag_offset[0]),
    ]

    // console.log('time', time)
    
    return { type: "SET_EVENT_TIME", 
        event_id: dragging[1],
        current_timeline:current_timeline,
        // start_end_time:[time, time],
        start_end_time:start_end_time,
        
    }


};


export const mouse_up_time_event = (event, ui_data, event_item_data) => {
    // console.log('AC mouse_up_time_event', event, ui_data )
    return dispatch => {
        let dragging = ui_data.control.dragging;
        let current_timeline = ui_data.current.current_timeline;

        let start_end_time = event_item_data['time'][current_timeline]['start_end_time']
        //let timeline_row = event_item_data['time'][current_timeline]['row']


        // will filter in app before this is run
        // if (dragging[0] === 'timeCard'){

            // let db_path = Constants.DB_ROOT + '/event_data/'+dragging[1]+'/time/'+current_timeline+'/'
            let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, ui_data.current.current_project, 'event', dragging[1]) +'/time/'+current_timeline+'/';


            // database.ref(db_path).set(pos)
            let time_data = {
                start_end_time:start_end_time,
                //row:timeline_row,
            }

            actions_database.set_db_data(db_path, time_data, window.SKIP_DATABASE)    

    }
}
