
import initial_ui from '../initial/initial_ui';
import initial_timelines from '../initial/initial_timelines';
import initial_flow_viewports from '../initial/initial_flow_viewports';
import initial_map_viewports from '../initial/initial_map_viewports';
import initial_characters from '../initial/initial_characters';
import initial_props from '../initial/initial_props';
import initial_locations from '../initial/initial_locations';
import initial_events from '../initial/initial_events';
import initial_documents from '../initial/initial_documents';
import initial_cards from '../initial/initial_cards';
import initial_threads from '../initial/initial_threads';
import initial_projects from '../initial/initial_projects';
import initial_users from '../initial/initial_users';
import initial_context from '../initial/initial_context';
import initial_regions from '../initial/initial_regions';
import initial_attributes from '../initial/initial_attributes';
import initial_cache from '../initial/initial_cache';
import initial_undo from '../initial/initial_undo';
import initial_groups from '../initial/initial_groups';

// import reducer_test from './reducer_test'
import reducer_viewport from './reducer_viewport'
import reducer_cards from './reducer_cards'
import reducer_regions from './reducer_regions'
import reducer_inputs from './reducer_inputs';
import reducer_mouse from './reducer_mouse';
import reducer_timeline from './reducer_timeline';
import reducer_timeline_cards from './reducer_timeline_cards';
import reducer_timeline_events from './reducer_timeline_events';
import reducer_story_items from './reducer_story_items';
import reducer_documents from './reducer_documents';
import reducer_projects from './reducer_projects';
import reducer_users from './reducer_users';
import reducer_attributes from './reducer_attributes';
// import reducer_context from './reducer_context';
import reducer_ui from './reducer_ui'
import reducer_cache from './reducer_cache'
import reducer_undo from './reducer_undo'
import reducer_groups from './reducer_groups'

import * as utils from '../../utils/misc_utils'



const initialState = Object.assign({}, 
    initial_cards,
    initial_characters,
    initial_documents,
    initial_flow_viewports, 
    initial_map_viewports, 
    initial_locations,
    initial_events,
    initial_props, 
    initial_threads,
    // deepCopyFunction(initial_threads),
    initial_timelines, 
    initial_projects, 
    initial_users, 
    initial_context, 
    initial_regions,
    initial_attributes,
    initial_ui, 
    initial_cache, 
    initial_undo, 
    initial_groups, 

);

const initialState_deep_copy = utils.deepCopyFunction(initialState)

const reducer_root = (state = initialState_deep_copy, action) => {
    let newState = {...state};

    // Not sure this is a good wy for this to work but it seems to work. (worked up together with Jase)
    // seems a bit ugly to pass in sate and newstate to each reducer... well for the moment if it works i'll go with it.

    // with this setup I don't want to copy state in each reducer becaue I'm already making a copy in this root reducer

    // console.log('running reducer_root now')
    // console.log('X reducer_root initialState', initialState_deep_copy)

    // reducer_test(state, newState, action);
    reducer_mouse(state, newState, action);
    reducer_ui(state, newState, action);
    reducer_viewport(state, newState, action);
    reducer_cards(state, newState, action);
    reducer_regions(state, newState, action);
    reducer_inputs(state, newState, action);
    reducer_timeline(state, newState, action);
    reducer_timeline_cards(state, newState, action);
    reducer_timeline_events(state, newState, action);
    reducer_story_items(state, newState, action);
    reducer_documents(state, newState, action);
    reducer_projects(state, newState, action);
    reducer_users(state, newState, action);
    reducer_attributes(state, newState, action);
    reducer_cache(state, newState, action);
    reducer_undo(state, newState, action);
    reducer_groups(state, newState, action);

    // an alternate way which worked was to collect the resturn of each reducrer in newState
    // newState = reducer_test(state, action);
    // newState = reducer_viewport(state, action);
    // newState = reducer_cards(state, action);
    

    return newState;

};
export default reducer_root;