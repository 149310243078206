import React, { Component } from 'react';
// import './ApCharacter.css';
import InputField from '../ui_elements/inputs/InputField';
import ItemContainer from '../ui_elements/objects/item_container/ItemContainer';
import Button from '../ui_elements/inputs/Button';

import { connect} from 'react-redux';
import * as actionCreator_storyItem from '../../store/actions/actions_story_items';
import * as actionCreator_documents from '../../store/actions/actions_documents';
import * as actionCreator_attributes from '../../store/actions/actions_attributes';
import * as actionCreator_ui from '../../store/actions/actions_ui';
import * as actionCreator_timeline from '../../store/actions/actions_timelines';
import * as actionCreator_viewports from '../../store/actions/actions_viewports';

import * as time_utils from '../../utils/time_utils'


class ApCard extends Component {

    constructor(props) {
        super(props);
        this.handleNewDoc = this.handleNewDoc.bind(this);
        this.handleOpenDoc = this.handleOpenDoc.bind(this);
        this.handleAddTimeAttrs = this.handleAddTimeAttrs.bind(this);
        this.handleGoToTimelime = this.handleGoToTimelime.bind(this);
        this.handleGoToFlow = this.handleGoToFlow.bind(this);


    }
    
    handleNewDoc(event) {
        this.props.create_and_link_document(this.props.item_data, this.props.item_type, this.props.item_id, this.props.ui_data.current.current_project);
    }
    handleOpenDoc(event) {
        // this.props.link_items(this.props.item_data, this.props.item_type, this.props.item_id, 'character', ['uid2', 'uid1']);
        if (this.props.item_data.links.hasOwnProperty('document'))
        {
            if (this.props.item_data.links.document){
                let linked_doc = this.props.item_data.links.document[0];
                this.props.open_linked_document( linked_doc, this.props.item_type);
            }
        }
    }
    handleAddTimeAttrs(event) {
        // console.log('DO handleAddTimeAttrs')

        // TODO: need a system for dealing with multiple timelines

        let attr_id = '_time_progress';
        // let attr_id = 'story_percentage';
        // let attr_val = 'hello';
        let attr_parent = 'time';

        // let attr_val = this.props.attribute_data[attr_id]['default'];

        // use util to find next avaible place for new time card
        let current_tl = this.props.ui_data.current.current_timeline;
        let start_end_row = time_utils.get_next_available_start_end_row_for_card([0,10], 1, this.props.card_data, current_tl,)
        let attr_val = {
            start_end_time:[start_end_row[0], start_end_row[1]],
            row:start_end_row[2]
        }


        // console.log('attr_val', attr_val)
        this.props.add_attr(this.props.item_type, this.props.item_id, attr_id, attr_val, attr_parent, this.props.ui_data.current.current_project)
    }
    handleGoToTimelime(event) {
        // console.log('DO handleGoToTimelime')

        this.props.onModeChange('timeline', this.props.ui_data)
        this.props.fit_timeline(this.props.ui_data, this.props.card_data);

    }
    handleGoToFlow(event) {
        // console.log('DO handleGoToFlow')

        this.props.onModeChange('flow', this.props.ui_data)
        this.props.fit_flow_viewport(this.props.ui_data, this.props.card_data, {}, {});

    }


    
    render() {

        let item_id = this.props.item_id;
        let current_timeline = this.props.ui_data.current['current_timeline'];
        let linked_doc = null;
        //TODO this is hackey - i dont think i should do this - better to remove deleted items from history
        // so they cant turn up
        //
        if (!this.props.item_data){
            console.log('I DONT WANT TO GET HERE!')
            return (
                <div id="ap_card"> does not exist! </div>
            )
        }
        if (this.props.item_data.hasOwnProperty('links'))
        {
            if (this.props.item_data.links.hasOwnProperty('document'))
            {
                // console.log('DOC this.props.item_data.links.document', this.props.item_data.links.document)
                if (this.props.item_data.links.document){
                    linked_doc = this.props.item_data.links.document[0];
                }
            }
        }
        let button_to_add = null;
        if (this.props.ui_data.settings.mode !== 'writer')
        {
            button_to_add = <Button 
                onClickFunction={this.handleNewDoc} 
                label='Create New Document' 
                id='OKButtonb' 
            />
            if (linked_doc){
                button_to_add = <Button 
                    onClickFunction={this.handleOpenDoc} 
                    label='Go to Document' 
                    id='OKButtonb' 
                />
            }
        }

        // add to timeline stuff. test if item has time attr
        let has_time = false;
        if (this.props.item_data.hasOwnProperty('time'))
        {
            if (this.props.item_data.time.hasOwnProperty(current_timeline))
            {
                has_time = true;
            }
        }

        // buton config
        let button_to_add_time = null;
        if (this.props.ui_data.settings.mode !== 'timeline')
        {

            if (has_time){
                button_to_add_time = <Button 
                    onClickFunction={this.handleGoToTimelime} 
                    label='Go to Timeline' 
                    id='OKButtonb' 
                />
            }
            else{
                button_to_add_time = <Button 
                    onClickFunction={this.handleAddTimeAttrs} 
                    label='Add to Timeline' 
                    id='OKButtonb' 
                />
            }
        }


        // buton config
        let button_to_add_flow = null;
        if (this.props.ui_data.settings.mode !== 'flow')
        {
            button_to_add_flow = <Button 
                onClickFunction={this.handleGoToFlow} 
                label='Go to Flow' 
                id='OKButton_gotoflow' 
            />
          }

        let time_attrs = null;
        if (has_time){
            time_attrs = [
                <InputField 
                key='a'
                dataSource={"card_data." + item_id + ".time."+current_timeline+".start_end_time.0"}
                dataTarget={"card_data." + item_id + ".time."+current_timeline+".start_end_time.0"}
                inputType='number' 
                style={{position:"relative"}} 
                // id='AAA' 
                label='Start Time' 
                />,
                <InputField 
                    key='b'
                    dataSource={"card_data." + item_id + ".time."+current_timeline+".start_end_time.1"}
                    dataTarget={"card_data." + item_id + ".time."+current_timeline+".start_end_time.1"}
                    inputType='number' 
                    style={{position:"relative"}} 
                    // id='AAA' 
                    label='End Time' 
                />
            ]
        }

        return (
            <div id="ap_card">
                {/* <div style={{height:'30px'}}/> */}

                <InputField 
                    dataSource={"card_data." + item_id + ".title"}
                    dataTarget={"card_data." + item_id + ".title"}
                    inputType='text' 
                    style={{position:"relative", display:"block"}} 
                    // id='nameField' 
                    label='Title' 
                />
                <InputField 
                    dataSource={"card_data." + item_id + ".synopsis"}
                    dataTarget={"card_data." + item_id + ".synopsis"}

                    inputType='textArea' 
                    style={{position:"relative"}} 
                    // id='xx' 
                    label='Synopsis' 
                />
                {button_to_add}
                {button_to_add_flow}
                {button_to_add_time}
                {time_attrs}
                {/* <Button 
                    onClickFunction={this.handleNewDoc} 
                    //valToSet={false}
                    label='Create New Document' 
                    id='OKButtonb' 
                    //style={{height:"45px", width:"140px", right:"10px",bottom:"4px"}}
                /> */}
                <div className='Separator' />
                {/* <InputField 
                    dataSource={"card_data." + item_id + ".time."+current_timeline+".start_end_time.0"}
                    dataTarget={"card_data." + item_id + ".time."+current_timeline+".start_end_time.0"}
                    inputType='number' 
                    style={{position:"relative"}} 
                    // id='AAA' 
                    label='Start Time' 
                />
                <InputField 
                    dataSource={"card_data." + item_id + ".time."+current_timeline+".start_end_time.1"}
                    dataTarget={"card_data." + item_id + ".time."+current_timeline+".start_end_time.1"}
                    inputType='number' 
                    style={{position:"relative"}} 
                    // id='AAA' 
                    label='End Time' 
                /> */}
                <ItemContainer 
                    dataSource={"card_data." + item_id + '.links.character'}
                    containerLabel='Linked Characters' 
                    item_type='character'
                    parent_data={{item_type:'card', item_id:item_id}}
                    itemSize='small'
                    mode='attr_editor'
                />
                <ItemContainer 
                    dataSource={"card_data." + item_id + '.links.prop'}
                    containerLabel='Linked Props' 
                    item_type='prop' 
                    parent_data={{item_type:'card', item_id:item_id}}
                    itemSize='small'
                    mode='attr_editor'
                />
                <ItemContainer 
                    dataSource={"card_data." + item_id + '.links.location'}
                    containerLabel='Linked Locations' 
                    item_type='location' 
                    parent_data={{item_type:'card', item_id:item_id}}
                    itemSize='small'
                    mode='attr_editor'
                />
                {/* <ItemContainer containerLabel='Characters' item_type='character' itemSize='small'/>
                <ItemContainer containerLabel='Props' item_type='prop' itemSize='small'/>
                <ItemContainer containerLabel='Locations' item_type='location' itemSize='small'/> */}

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ui_data:state.ui_data,
        // document_data:state.document_data,
        attribute_data:state.attribute_data,
        // prop_data:state.prop_data,
        card_data:state.card_data,
        timeline_data:state.timeline_data,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // link_items: (item_data, item_type, item_id, to_link_type, to_link_ids, project_id) => dispatch(actionCreator_storyItem.link_items(item_data, item_type, item_id, to_link_type, to_link_ids, project_id)),
        
        create_and_link_document: (item_data, item_type, item_id, project_id) => dispatch(actionCreator_documents.create_and_link_document(item_data, item_type, item_id, project_id)),
        open_linked_document: (document_id, item_type) => dispatch(actionCreator_documents.open_linked_document(document_id, item_type)),
        onModeChange: (mode, ui_data) => dispatch(actionCreator_ui.mode_panel_set_mode(mode, ui_data)),

        add_attr: ( item_type, item_id, attr_id, attr_val, attr_parent, project_id) => dispatch(actionCreator_attributes.add_attr( item_type, item_id, attr_id, attr_val, attr_parent, project_id)),

        fit_timeline: (ui_data, card_data) => dispatch(actionCreator_timeline.fit_timeline (ui_data, card_data)),
        fit_flow_viewport: (ui_data, card_data, region_data, location_data) => dispatch(actionCreator_viewports.fit_flow_viewport (ui_data, card_data, region_data, location_data)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps )(ApCard);
// export default ApCard;
