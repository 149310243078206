/*
to do :
get all child items bboxes. 
take snapshot of those on mouse down, then use those vals to detect if mouse over a cell
rather than mouse over events and dummy copy of flex box
this will also give the advantage of being able to detect if in empty space in flex
at end of a row (in the middle or after last item) 
It will also enable box selection. yay

write function to return item name when mouse moving through stored bboxes 
- this will be equivelent to mouseover.


- selection bbox
    make container that's bigger than flex so you can start to draw a 
    seletion without moving an item
    Test item bboxes
    Add modifier behavior (same as VP)

- allow for multiple flex containers
    drag an drop between flex containers

*/
import ItemGroupContainer from '../components/ui_elements/objects/item_container/ItemGroupContainer';
import UberFlexContainer from '../components/ui_elements/objects/item_container/UberFlexContainer';
import UberColumnContainer from '../components/ui_elements/objects/item_container/UberColumnContainer';
import React, { useState, useEffect, useCallback, useSelector } from 'react';

import StoryItem from '../components/ui_elements/objects/story_item/StoryItem';


import './TestingPage.css';


// import InputField from '../components/ui_elements/inputs/InputField';
// import Button from '../components/ui_elements/inputs/Button';


// import bg_image from '../images/landing_page_bg_01.jpg';



export default function TestingPage() {

    // get character_data
    // let character_data = useSelector( state => state.character_data)
    // console.log('character_data', character_data)

    // useEffect(() => {
       
    //     return () => {
    //         let elements = []
    //         for (let index = 0; index < Object.keys(character_data).length; index++) {
    //             const character_id = Object.keys(character_data)[index];
    
    //             elements.append(
    //                 <StoryItem 
    //                     key={'character-' +character_id}
    //                     itemSize='medium'
    //                     item_type='character'
    //                     item_id={character_id}
    //                     // mode={this.props.mode}
    //                 />
    //             )
    //         }
    //         setElementList(elements)
    //     };
    // }, [character_data]);

    const [elementList, setElementList] = useState([])


    const flex_gap = 6
    const flex_padding = 10
    const [itemList, setItemList] = useState(
        [
            'bob', 'sally', 'harry', 'jane', 'frank', 'lucy', 'sam', 'molly', 'alex', 'ritchy',
            'bobx', 'sallyx', 'harrxy', 'janx', 'frxank', 'lxucy', 'sxam', 'molxly', 'alxex', 'rxitchy',
        ]
    )
    const [itemListB, setItemListB] = useState(
        [
            'aaa', 'bbb', 'ccc', 'ddd', 'eee', 'fff', 'ggg', 'hhh', 'iii', 'jjj', 'kkk'
        ]
    )

    const [testbbox, setTestbbox] = useState(null)


    const on_test_click = () => {
        console.log('on_test_click')
        // setItemList([
        //     'harry', 'sally',  'bob', 'jane',
        // ]
        // )
        // setItemList([...Array(12).keys()])
        
        // generate_bbox_data()
    }

    const reorder_items_function = (item_list) => {
        console.log('reorder_items_function item_list', item_list)
        setItemList(item_list)
    }
    const select_items_function = (item_list) => {
        console.log('select_items_function item_list', item_list)
    }
    const reorder_items_function_b = (item_list) => {
        console.log('reorder_items_function item_list', item_list)
        setItemListB(item_list)
    }
    const select_items_function_b = (item_list) => {
        console.log('select_items_function item_list', item_list)
    }


    const move_to_group_function = (group_name, item_list) => {
        console.log('QQQ  move_to_group_function group_name', group_name)
        console.log('QQQ move_to_group_function item_list', item_list)
    }
    const copy_to_group_function = (group_name, item_list) => {
        console.log('QQQ copy_to_group_function group_name', group_name)
        console.log('QQQ copy_to_group_function item_list', item_list)
    }




    // let test_div_style = {}
    // if (testbbox){
    //     test_div_style = {
    //         position:'fixed',
    //         top:testbbox.top,
    //         left:testbbox.left,
    //         width:testbbox.width,
    //         height:testbbox.height,
    //         backgroundColor:'yellow',
    //         opacity:0.2,
    //         zIndex:100,
    //     }
    // }

    

    // useEffect(() => {
    //     let flex_element = document.getElementById('B-flex-parent')
    //     // console.log('flex_element', flex_element)
    
    //     if (flex_element){
    
    //         let flex_rect = flex_element.getBoundingClientRect()
    //         // console.log('flex_element flex_rect', flex_rect)

    //         // test_div_style['left'] =flex_rect.left
    //         // test_div_style['top'] =flex_rect.top
    //         // test_div_style['width'] =flex_rect.width
    //         // test_div_style['height'] =flex_rect.height
    //         setTestbbox(flex_rect)
    //     }
    // })


    let elements = []
    for (let index = 0; index < itemList.length; index++) {
        const character_id = itemList[index];

        elements.push(
            <div id={character_id} style={{width:'65px', height:'80px', backgroundColor:'grey', borderRadius:'20px'}}>
                {character_id}
            </div>
        )
    }
    
    let elements_b = []
    for (let index = 0; index < itemListB.length; index++) {
        const character_id = itemListB[index];

        elements_b.push(
            <div id={character_id} style={{width:'65px', height:'80px', backgroundColor:'grey', borderRadius:'20px'}}>
                {character_id}
            </div>
        )
    }
    



    return (
        <div className="TestingPageDiv"
            
            // onMouseUp={(e) => {on_item_mouse_up(e,)}}
            // onMouseMove={(e) => {on_item_mouse_move(e,)}}
            // onKeyDown={(e) => {onKeyDown(e,)}}
            // onKeyPress={(e) => {handleKeyDown(e,)}}
            // onKeyUp={(e) => {handleKeyUp(e,)}}
        >

            <input type='button'
                className='test_button'
                onClick={on_test_click}
                value='push meeea'
                // onKeyPress={(e) => {handleKeyDown(e,)}}

                />

            {}
            <div className='test_container_parent'>

                <UberFlexContainer 
                    id='A'
                    reorder_items_function={reorder_items_function}
                    select_items_function={select_items_function}
                    move_to_group_function={move_to_group_function}
                    copy_to_group_function={copy_to_group_function}
                    // item_list={itemList}
                    // element_list = {elements}
                    // item_width={60}
                    // item_height={60}
                >
                    {elements}
                </UberFlexContainer>
                
            </div>
            <div className='test_container_parent'>

                <ItemGroupContainer 
                    id='B'
                    reorder_items_function={reorder_items_function_b}
                    select_items_function={select_items_function_b}
                    move_to_group_function={move_to_group_function}
                    copy_to_group_function={copy_to_group_function}
                    // item_list={itemListB}
                    children = {elements_b}
                    // item_width={60}
                    // item_height={60}
                />
            </div>

            {/* <div style={test_div_style}/> */}
            {/* {elements} */}
            <UberColumnContainer 
                id='C'
                // reorder_items_function={reorder_items_function}
                // select_items_function={select_items_function}
                // move_to_group_function={move_to_group_function}
                // copy_to_group_function={copy_to_group_function}
                // item_list={itemList}
                // element_list = {elements}
                // item_width={60}
                // item_height={60}
            >
                {elements}
            </UberColumnContainer>
            <div style={{height:'200px'}}/>
        </div>
    )
}
