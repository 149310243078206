
export const test_for_overlap = (card_data, cur_card_id, cur_start_end, timeline, row) => {
    // console.log('test_for_overlap args', cur_card_id, timeline, row);
    /*
        Function for detecting if cardis overlapping with any other cards, 
        and returning new start and end times to suit
    */

    let return_start_end = [cur_start_end[0], cur_start_end[1]];

    for (let index = 0; index < Object.keys(card_data).length; index++) {
        let card_id = Object.keys(card_data)[index];

        // skip cur card - no point comparing to self
        if (card_id === cur_card_id){
            continue;
        }
        let card_obj = card_data[card_id];
        // console.log('test_for_overlap - in loop 0 card_obj', card_obj);
        // check if card has time attrs
        if (!card_obj.hasOwnProperty('time')){
            continue;
        }
        if (!card_obj['time'].hasOwnProperty(timeline)){
            continue;
        }
        
        if (card_obj['time'][timeline]['row'] !== row){
            continue;
        }

        let start_end = card_obj['time'][timeline]['start_end_time'];

        // i think this one rule is enough to cover all cases of overlap
        // if (cur_start_end[0] < start_end[1] && cur_start_end[1] > start_end[0]){
        //     // console.log('FOUND OVERLAP!!!');
        //     return true;
        // }

        // if end is overlapping next card
        if (cur_start_end[0] < start_end[0] && cur_start_end[1] > start_end[0]){
            // console.log('cur_start_end', cur_start_end);
            // console.log('start_end', start_end);
            return_start_end[1] = start_end[0];
        }
        // if start is overlapping prev card
        if (cur_start_end[0] < start_end[1] && cur_start_end[1] > start_end[1]){
            return_start_end[0] = start_end[1];
        }

        // Don't try to resolve these situations, just return null to stop cards moving
        // until a user drags to somewhere more useful
        if (cur_start_end[0] < start_end[0] && cur_start_end[1] > start_end[1]){
            return null;
        }
        if (cur_start_end[0] > start_end[0] && cur_start_end[1] < start_end[1]){
            return null;
        }
        if (return_start_end[0] === return_start_end[1]){
            return null;
        }      


    }
    return return_start_end;
}







export const test_for_overlap_generic = ( start_end, row, start_end_row_array) => {
    /*
        test array for if start end and row are overlapping anything.
        start_end_row = [start, end, row]
        start_end_row_array = [ [start, end, row] ,  [start, end, row] ]
    */

    for (let index = 0; index < start_end_row_array.length; index++) {
        const start_end_row_b = start_end_row_array[index];

        // if items are on the same row, then test ranges. (if not on same row, they can't overlap)
        if (row === start_end_row_b[2]){
            if (start_end[0] < start_end_row_b[1] && start_end[1] > start_end_row_b[0]){
                return true;
            }
        }
    }
    return false;
}


export const get_row_for_non_overlap_recursive = (start_end, row, start_end_row_array) => {
    let has_overlap = test_for_overlap_generic(start_end, row, start_end_row_array)

    let new_start_end_row = [ start_end[0], start_end[1], row];

    if (has_overlap){
        // if overlap found add 1 to row and try again.
        // new_start_end_row = [ start_end[0], start_end[1], row+1];
        new_start_end_row = get_row_for_non_overlap_recursive(start_end, row+1, start_end_row_array);
        // new_start_end_row = [ start_end[0], start_end[1], row+1];

    }
    // else{
    //     return row;
    // }
    return  new_start_end_row;
}

export const get_start_end_from_event_id = (event_id, event_data, tl_scale, current_tl, label_width,) => {
    /*
        custom logic for getting event start and ends (this requires ui information as the width is zoom dependent)
    */
   let label_width_val = label_width * (1.0/tl_scale);

    if (event_data[event_id].time.hasOwnProperty(current_tl)){
        let start_end = event_data[event_id]['time'][current_tl]['start_end_time']
        // let row = event_data[event_id]['time'][current_tl]['row']

        // add label width based on timeline scale
        let start_end_with_lables = [start_end[0]-label_width_val, start_end[0]+label_width_val];
        return start_end_with_lables;
    }
    else{
        // console.log('timeline data not found for this event', current_tl);
        return null;
    }
}


export const get_event_start_end_row_data = ( event_data, tl_scale, current_tl, label_width,) => {
    /*
    - loop through all events for each one get row and build up ranges_and_row_array of previosuly looped events


    */

    let event_start_end_row_data = {};
    // loop through all events
    let ranges_and_row_array = []
    for (let index = 0; index < Object.keys(event_data).length; index++) {
        let event_id = Object.keys(event_data)[index];

        let start_end = get_start_end_from_event_id(event_id, event_data, tl_scale, current_tl, label_width);

        if (!start_end) {
            console.log('nothing found for this event!', event_id);
            return
        }

        // now use recursice function to get row
        let start_end_row = get_row_for_non_overlap_recursive(start_end, 0, ranges_and_row_array)
        ranges_and_row_array.push(start_end_row);

        let event_start_end = event_data[event_id]['time'][current_tl]['start_end_time'];
        event_start_end_row_data[event_id] = [event_start_end[0], event_start_end[1], start_end_row[2]];

    }
    return event_start_end_row_data;
}




export const get_start_end_row_from_card_id = (card_id, card_data, current_tl,) => {
    /*
        custom logic for getting card start,  end and row
    */
    if (card_data[card_id].hasOwnProperty('time')){

        if (card_data[card_id].time.hasOwnProperty(current_tl)){
            let start_end = card_data[card_id]['time'][current_tl]['start_end_time']
            let row = card_data[card_id]['time'][current_tl]['row']

            // add label width based on timeline scale
            let start_end_row = [start_end[0], start_end[1], row];
            return start_end_row;
        }
        else{
            // console.log('timeline data not found for this event', current_tl);
            return null;
        }
    }
    else{
        return null;
    }
}


export const get_start_end_row_array_from_cards = (card_data, current_tl,) => {
    /*
        build array of start_end_row from all cards
    */

    let start_end_row_array = []
    for (let index = 0; index < Object.keys(card_data).length; index++) {
        let card_id = Object.keys(card_data)[index];

        let start_end_row = get_start_end_row_from_card_id(card_id, card_data, current_tl)
        if (start_end_row){
            start_end_row_array.push(start_end_row)
        }
    }
    return start_end_row_array
}


export const get_next_available_start_end_row_for_card = (start_end, row, card_data, current_tl,) => {
    /*
        find a free spot for a new time card
    */
    let start_end_row_array = get_start_end_row_array_from_cards(card_data, current_tl,)
    let new_start_end_row = get_row_for_non_overlap_recursive(start_end, row, start_end_row_array)
    return new_start_end_row;
}

