
// import * as card_utils from '../../utils/card_utils'
// import * as utils from '../../utils/misc_utils'

const reducer_groups = (state, newState, action) => {


    if(action.type === 'CREATE_GROUP'){
        console.log('RD CREATE_GROUP', action)
        newState.group_data = {...state.group_data};

        newState.group_data[action.new_uid] = action.group_data;

    }

    if(action.type === 'DELETE_GROUP'){
        console.log('RD DELETE_GROUP', action)

        newState.group_data = {...state.group_data};

        // if (Constants.DELETABLE_TYPES.includes(action.item_type)){
            delete newState.group_data[action.group_id]; 
        // }

    }

    if(action.type === 'ADD_ITEMS_TO_GROUP'){
        console.log('RD ADD_ITEMS_TO_GROUP', action)
        newState.group_data = {...state.group_data};
        newState.group_data[action.group_id] = {...state.group_data[action.group_id]};
        newState.group_data[action.group_id].items = [...state.group_data[action.group_id].items];

        for (const item_id of action.item_list) {
            if (!newState.group_data[action.group_id].items.includes(item_id)){
                newState.group_data[action.group_id].items.push(item_id) 
            }
        }

    }

    if(action.type === 'REMOVE_ITEMS_FROM_GROUP'){
        console.log('RD REMOVE_ITEMS_FROM_GROUP', action)
        newState.group_data = {...state.group_data};
        newState.group_data[action.group_id] = {...state.group_data[action.group_id]};
        newState.group_data[action.group_id].items = [...state.group_data[action.group_id].items];

        for (const item_id of action.item_list) {
            if (newState.group_data[action.group_id].items.includes(item_id)){

                let index_to_remove = newState.group_data[action.group_id].items.indexOf(item_id)
                newState.group_data[action.group_id].items.splice(index_to_remove, 1)
            }
        }
    }

    if(action.type === 'SET_GROUP_ITEM_LIST'){
        // console.log('EEE RD SET_GROUP_ITEM_LIST', action)
        newState.group_data = {...state.group_data};
        newState.group_data[action.group_id] = {...state.group_data[action.group_id]};

        newState.group_data[action.group_id].items = action.item_list;
    }
    if(action.type === 'SET_GROUP_COLLAPSED'){
        // console.log('EEE RD SET_GROUP_COLLAPSED', action)
        newState.group_data = {...state.group_data};
        newState.group_data[action.group_id] = {...state.group_data[action.group_id]};

        newState.group_data[action.group_id].collapsed = action.collapsed;
    }


    return newState;

};
export default reducer_groups;