/*
DONE - modify to only work within scope of parent

Need recursive fuction to loop through all card children of project_viewport
    then loop through each card
*/


export const get_cards_by_thread_by_parent = (thread_id, card_parent,  thread_data, card_data) => {
    // console.log('get_cards_by_thread_by_parent', card_parent, thread_id, thread_data)

    // THIS IS HACKY - if no thread id provided, then just return all cards with card parent
    // possibly i shoudln't be re-using this function, but lets do it for now

    if (!thread_id)
    {
        // console.log('NOTE- no thread id provided. I will return all cards under parent!')
        let all_child_card_list = []
        for (let i = 0; i < Object.keys(card_data).length; i++) {
            let card = Object.keys(card_data)[i]
            // console.log(' -- get_cards_by_thread_by_parent -   in loop XX ', card)
            if (card_data[card].hasOwnProperty('item_parent')){
                if (card_data[card]['item_parent'] === card_parent){
                    all_child_card_list.push(card);
                }
    
            }
        }
        return [all_child_card_list];
    }




    // // let thread_id = 'col_uid3';
    // let connections = thread_data[thread_id].connections;
    // let card_con_lists = {};


    // // let all_cards = [];
    // // console.log('Object.keys(connections)', Object.keys(connections))
    // // console.log('connections', connections)
    // for (let i = 0; i < Object.keys(connections).length; i++) {
    //     let out_card = connections[Object.keys(connections)[i]][1][1]
    //     let in_card = connections[Object.keys(connections)[i]][0][1]



    //     // cards could ave multipe children or parents, they shoudl only total 2 though...
    //     if (card_data[in_card].hasOwnProperty('item_parent')){
    //         // console.log('FOUND THIS NOW A- ', card_data[in_card])
    //         if (card_data[in_card]['item_parent'] === card_parent){
    //             if (in_card in card_con_lists) {
    //                 card_con_lists[in_card].push(out_card)
    //             } else {
    //                 card_con_lists[in_card] = [out_card]
    //             }
    //         }
    //     }

    //     if (card_data[out_card].hasOwnProperty('item_parent')) {
    //         // console.log('FOUND THIS NOW B- ', card_data[out_card])

    //         if (card_data[out_card]['item_parent'] === card_parent){

    //             if (out_card in card_con_lists) {
    //                 card_con_lists[out_card].push(in_card)
    //             } else {
    //                 card_con_lists[out_card] = [in_card]
    //             }
    //         }
    //     }
    // }

    // this will make a dict of cards with an array of connection. It makes no distinction between in and out
    let card_con_lists = generate_card_connections_data(thread_id, thread_data, card_data, card_parent);

    // console.log('card_con_lists', card_con_lists)

    // find all start and ends - cards with only 1 connection. Don't know which is start or end yet, that coomes later
    let starts_and_ends = []
    for (let i = 0; i < Object.keys(card_con_lists).length; i++) {
        let card = Object.keys(card_con_lists)[i]
        //console.log('(card_con_lists card ', card)
        //console.log('(card_con_lists card_con_lists[card] ', card_con_lists[card])

        if (card_con_lists[card].length === 1) {
            starts_and_ends.push(card)
        }
    }
    // console.log('starts_and_ends 2', starts_and_ends)


    // this recursive section will generate arrays of contiguous card chains, keyed by root (start or end)
    //  at this point i should have a double up for each chain - i forwards, 1 backwards. Later I will test which one i want
    let root_chains_data_both_directions = {}
    for (let i = 0; i < starts_and_ends.length; i++) {

        let root_ordered = [starts_and_ends[i]];
        //let found = rec_test(starts_and_ends[i], card_parents, card_children, root_ordered)
        let found = get_thread_chains(starts_and_ends[i], card_con_lists, root_ordered)
        root_chains_data_both_directions[starts_and_ends[i]] = found;
    }

    /* now loop through root_chains_data_both_directions and only return based on chosen roots, based on y pos */
    let root_chains_sorted = {}
    let root_chains_by_pos = {}

    for (let i = 0; i < Object.keys(root_chains_data_both_directions).length; i++) {
        let root_item = Object.keys(root_chains_data_both_directions)[i]
        let chain_array = root_chains_data_both_directions[root_item]
        let last_item = chain_array[chain_array.length - 1]

        // if first or last item are in root_chains_sorted, then i can continue. I don't need to 
        if (root_item in root_chains_sorted || last_item in root_chains_sorted) {
            // console.log('already found,', root_item, last_item)
            continue;
        }

        //test if root_item or last_item is higher in y
        let root_pos_y = card_data[root_item].pos[1]
        let last_pos_y = card_data[last_item].pos[1]
        // console.log('root_pos_y', root_pos_y);
        // console.log('last_pos_y', last_pos_y);
        // console.log('\n\n\n');

        //if root_item
        if (root_pos_y <= last_pos_y){
            root_chains_sorted[root_item] = chain_array;

            // this is to account for 2 cards being exactly the same y pos - just nudge 1 value so they don't use same dict key
            if (root_pos_y in root_chains_by_pos){
                // console.log('found y pos match! how bout that! (root)')
                root_pos_y = root_pos_y + .001;
            }
            root_chains_by_pos[root_pos_y] = chain_array;
        }
        else{
            chain_array.reverse();
            root_chains_sorted[last_item] = chain_array;

            // this is to account for 2 cards being exactly the same y pos - just nudge 1 value so they don't use same dict key
            if (last_pos_y in root_chains_by_pos){
                // console.log('found y pos match! how bout that! (last)')
                last_pos_y = last_pos_y + .001;
            }
            root_chains_by_pos[last_pos_y] = chain_array;
        }

    }
    // console.log('\n\n\n\n\nroot_chains_sorted', root_chains_sorted)
    // console.log('\nroot_chains_by_pos', root_chains_by_pos)

    // finally loop through roots and put them in order

    // This crazy sort seems to be a standard way of sorting nuymbers.
    //https://forum.freecodecamp.org/t/need-an-explanation-why-we-are-returning-a-b/187258
    let post_sort = Object.keys(root_chains_by_pos).sort(function(a, b){return a-b});

    // console.log('post_sort', post_sort)

    let list_of_chains = [];
    for (let i = 0; i < post_sort.length; i++) {
        // console.log('post_sort[i]', post_sort[i])
        list_of_chains.push(root_chains_by_pos[post_sort[i]])
    }


    // console.log('\n\n\nlist_of_chains sorted', list_of_chains)
    return list_of_chains;

}

export default get_cards_by_thread_by_parent;


function generate_card_connections_data (thread_id,  thread_data, card_data, card_parent=null)
{
    // This will look 

    let connections = thread_data[thread_id].connections;
    let card_con_lists = {};


    for (let i = 0; i < Object.keys(connections).length; i++) {
        let out_card = connections[Object.keys(connections)[i]][1][1]
        let in_card = connections[Object.keys(connections)[i]][0][1]

        // test if card exists. It might not if a card was deleted. I prob need to do cleanup of thread data when deleting card
        //  for now just skip
        if (!card_data.hasOwnProperty(out_card)){
            console.log('generate_card_connections_data - card not found! might have been deleted', out_card);
            continue; 
        }
        if (!card_data.hasOwnProperty(in_card)){
            console.log('generate_card_connections_data - card not found! might have been deleted', in_card);
            continue; 
        }

        // cards could have multipe children or parents, they shoudl only total 2 though...
        if (card_data[in_card].hasOwnProperty('item_parent')){
            // console.log('FOUND THIS NOW A- ', card_data[in_card])
            if (card_data[in_card]['item_parent'] === card_parent){
                if (in_card in card_con_lists) {
                    card_con_lists[in_card].push(out_card)
                } else {
                    card_con_lists[in_card] = [out_card]
                }
            }
        }

        if (card_data[out_card].hasOwnProperty('item_parent')) {
            // console.log('FOUND THIS NOW B- ', card_data[out_card])

            if (card_data[out_card]['item_parent'] === card_parent){

                if (out_card in card_con_lists) {
                    card_con_lists[out_card].push(in_card)
                } else {
                    card_con_lists[out_card] = [in_card]
                }
            }
        }
    }

    return card_con_lists;
}




// recursive function to get thread chains
function get_thread_chains(obj, card_con_lists, root_ordered) {
    // let child_con = null;
    if (obj in card_con_lists) {
        // child_con = card_children[obj]

        let con_list = card_con_lists[obj]
        // console.log('\n\n\ncon_list', con_list)
        for (let i = 0; i < con_list.length; i++) {
            // console.log('con_list i ', con_list[i])
            // console.log('root_ordered ', root_ordered)
            // console.log
           	if (!(root_ordered.includes(con_list[i]))) {
                // console.log('i got in here', con_list[i])
               
                // console.log('found this', con_list[i])
                root_ordered.push(con_list[i])
                root_ordered = get_thread_chains(con_list[i], card_con_lists, root_ordered)
            }
        }

    }
    
    return root_ordered
}


// export const test_thing = () => {
//     console.log('\n\ntest_thing!!!!',)
// }



// 


export const recursive_build_card_tree_data = (thread_id, card_parent,  thread_data, card_data, card_tree_data) => {


    // let cur_thread_id = this.props.ui_data.current.current_thread_colour;
    // let current_vp = this.props.ui_data.current.current_viewport;


    // this gets all cards based on thread id and the current parent 
    // I think i need to make this recursive- and build a bigger table of data. 
    // Once that's done - I'll have 1 function the builds GUI out of that data

    // console.log('\n\nrecursive_build_card_tree_data start', card_parent)
    // console.log('recursive_build_card_tree_data card_tree_data', card_tree_data)

    let project_cards = get_cards_by_thread_by_parent(thread_id, card_parent, thread_data, card_data)
    // console.log('\n--------------------\nrecursive_build_card_tree_data thread_id', thread_id)
    // console.log('recursive_build_card_tree_data card_parent', card_parent)
    // console.log('recursive_build_card_tree_data get_cards_by_thread_by_parent', project_cards)
    // console.log('--------------------------\n\n')


    card_tree_data[card_parent] = project_cards;
    for (let i = 0; i < project_cards.length; i++) {

        // project cards is a list of lists - so i need to loop through all lists
        for (let ii = 0; ii < project_cards[i].length; ii++) {
            let card_uid = project_cards[i][ii];
            if (card_data[card_uid].hasOwnProperty('item_parent')){
                // console.log('\n In loop with this uid', card_uid )
                // console.log('\n In loop with this uid', card_uid )

                // console.log('IN REC LOOP ', card_data[card_uid])

                // this is a bad hack - addding card in font of parent
                let new_parent = 'card_' + card_uid;

                card_tree_data = recursive_build_card_tree_data(thread_id, 
                    new_parent,  
                    thread_data, 
                    card_data, 
                    card_tree_data
                    ) 
                // console.log('\n In loop updated card_tree_data', card_tree_data )
                // console.log('\n\n')

            }
        }
    }
    
    return card_tree_data
}