import React, { Component } from 'react';
import InputField from '../ui_elements/inputs/InputField';
import Thumbnail from '../ui_elements/inputs/Thumbnail';
import ItemContainer from '../ui_elements/objects/item_container/ItemContainer';
import Button from '../ui_elements/inputs/Button';
import ColourSwatches from '../ui_elements/inputs/ColourSwatches';
import Slider from '../ui_elements/inputs/Slider';
import Checkbox from '../ui_elements/inputs/Checkbox';

import { connect} from 'react-redux';

import * as actionCreator_ui from '../../store/actions/actions_ui';


class ApRegion extends Component {
    constructor(props) {
        super(props);
        this.handleThumbClick = this.handleThumbClick.bind(this);
    }
    handleThumbClick (event){
        // console.log('ApRegion handleThumbClick',)
        this.props.open_modal_window('pick_image', {item_type:'region', item_id:this.props.item_id, 'avi':'test'})

    }
    render() {

        let item_id = this.props.item_id
        return (
            <div id="ap_region">

                <Thumbnail 
                    dataSource={"region_data." + item_id}
                    //dataTarget={"prop_data." + item_id}
                    //inputType='text' 
                    //style={{position:"relative"}} 
                    //id='thumb' 
                    //label='thmmbb'
                    onClick={this.handleThumbClick} 
                />
                <InputField 
                    dataSource={"region_data." + item_id + ".name"}
                    dataTarget={"region_data." + item_id + ".name"}
                    inputType='text' 
                    style={{position:"relative"}} 
                    id='nameField' 
                    label='Name' 
                />
                <Checkbox  
                    dataSource={"region_data." + item_id + ".magnetic"} 
                    dataTarget={"region_data." + item_id + ".magnetic"} 
                    label='Drag Contents'
                />


                <ColourSwatches label='color' dataTarget={"region_data." + item_id + ".color"}/>
                
                <Slider 
                    label='opacity' 
                    dataSource={"region_data." + item_id + ".opacity"}
                    dataTarget={"region_data." + item_id + ".opacity"}
                />

            </div>
        
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        open_modal_window: (modal_name, input_data) => dispatch(actionCreator_ui.open_modal_window(modal_name, input_data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(ApRegion);
