import React, { Component } from 'react';
// import './StoryItemPanel.css';

import ItemContainer from '../ui_elements/objects/item_container/ItemContainer';
import '../ui_elements/objects/item_container/ItemContainer.css';
import Button from '../ui_elements/inputs/Button';

import ProjectCard from '../ui_elements/objects/project/ProjectCard';
import ProjectCardEmpty from '../ui_elements/objects/project/ProjectCardEmpty';
import ProjectContainer from '../ui_elements/objects/project/ProjectContainer';

import { connect} from 'react-redux';

import * as actionCreator_utility from '../../store/actions/actions_utility';
import * as actionCreator_story_items from '../../store/actions/actions_story_items';
import * as actionCreator_projects from '../../store/actions/actions_projects';

import './ProjectsPanel.css';


class ProjectsPanel extends Component {
    constructor(props) {
        super(props);
        this.handleTestClick = this.handleTestClick.bind(this);
        this.handleTestClick_B = this.handleTestClick_B.bind(this);
      }

    handleTestClick_B() {
        // this.props.testButtonDB();
        // this.props.push_data_to_db('story_app_data', this.props.full_state);
        // this.props.testButton_B('story_app_data', this.props.full_state, false, 0)
        // this.props.test_context()
        let current_user_id = this.props.context_data.user;
        console.log('THIS IS A TEST BUTTON B current_user_id', current_user_id)
        console.log('THIS IS A TEST BUTTON B user_data', this.props.user_data)
        this.props.create_example_project(current_user_id, this.props.user_data)

          // item_data, item_type, item_id, to_link_type, to_link_id
    }

    handleTestClick() {
        // this.props.set_db_data('story_app_data', this.props.full_state, false, 0)

    }

    render () {
        // let proj_id_list = Object.keys(this.props.project_data);
        return (
            <div className="ProjectsPanel" style={{top:this.props.ui_data.layout.banner_height}}> 
                {/* <div>Age: <span>{props.age}</span></div>
                <button onClick={props.onAgeUp}>age Up</button>
                <button onClick={props.onAgeDown}>age Down</button> */}
                <div style={{height:'60px'}}/>
                <Button
                        // buttonType='overlayButton'
                        dataTarget=''
                        valToSet={false}
                        onClickFunction={this.handleTestClick_B}

                        label='Reset example project' 
                        // icon='fas fa-trash'
                        id='cscscz' 
                        style={{opacity:1, position: 'absolute', width:'270px', right:"70px", top:"17px"}}
                    />
                {/* <Button
                        buttonType='overlayButton'
                        dataTarget=''
                        valToSet={false}
                        onClickFunction={this.handleClickDelete}

                        label='' 
                        icon='fas fa-trash'
                        id='cscsc' 
                        style={{height:"80px", width:"80px", right:"50px", top:"34px"}}
                    /> */}
                <ProjectContainer/>
                {/* <ProjectCard project_id='uid1'/> */}
                {/* <ProjectCardEmpty/> */}
                {/* <ItemContainer containerType='large' containerLabel='Projects' item_type='project' itemSize='large'/> */}
                {/* <div className='newContainer'>Create New Group</div> */}
            </div>
            
        );
    }
}


const mapStateToProps = (state) => {
    return {
        // project_data:state.project_data,
        ui_data:state.ui_data,
        user_data:state.user_data,
        context_data:state.context_data,
    }
  }
  

const mapDispatchToProps = (dispatch) => {
    return {

        // // onFullScreenToggle: () => dispatch({type:'FULL_SCREEN_TOGGLE'}),
        // onFullScreenToggle: () => dispatch(actionCreator_ui.full_screen_toggle()),
        // testButton: () => dispatch({type:'ON_MODAL_OPEN', modalName:'test'}),

        // // testButtonDB: () => dispatch({type:'TEST_DB'}),

        // // push_data_to_db: (data) => dispatch({type:'PUSH_DATA_TO_DB', data:data}),

        // onModeChange: (mode) => dispatch({type:'SET_MODE', value:mode}),

        // testButtonDB: (event,) => dispatch(actionCreator_database.test_action(event,)),

        // set_db_data: (db_path, db_data, skip, delay) => dispatch(actionCreator_database.set_db_data(db_path, db_data, skip, delay)),
        // load_root_data_from_db: (db_path) => dispatch(actionCreator_database.load_root_data_from_db(db_path)),
        create_example_project: (user_id, user_data) => dispatch(actionCreator_projects.create_example_project(user_id, user_data)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPanel);

