/*
This is pretty hacky but seems to work ok.
Obviosuly limited to 6 levels deep (should be more than enough as I don't want too much nesting anyway)

*/
const set_state_from_string = (stateData, dataString, val_to_set) => {
    //AAA console.log('set_state_from_string XAXXAXAXXA stateData', stateData, )

    // not sure why i need to make newState = stateData. newState = {...stateData} was not updaing on some data for some reason.
    // const newState = {...stateData};
    const newState = stateData;

    let splitKeys = dataString.split(".");

    if (splitKeys.length === 1){
        newState[splitKeys[0]]  = val_to_set;
    }
    if (splitKeys.length === 2){
        newState[splitKeys[0]] = {...stateData[splitKeys[0]]}

        newState[splitKeys[0]][splitKeys[1]] = val_to_set;
    }
    if (splitKeys.length === 3){
        newState[splitKeys[0]][splitKeys[1]] = {...stateData[splitKeys[0]][splitKeys[1]]}
        newState[splitKeys[0]] = {...stateData[splitKeys[0]]}

        newState[splitKeys[0]][splitKeys[1]][splitKeys[2]] = val_to_set;
    }
    if (splitKeys.length === 4){
        newState[splitKeys[0]][splitKeys[1]][splitKeys[2]] = {...stateData[splitKeys[0]][splitKeys[1]][splitKeys[2]]}
        newState[splitKeys[0]][splitKeys[1]] = {...stateData[splitKeys[0]][splitKeys[1]]}
        newState[splitKeys[0]] = {...stateData[splitKeys[0]]}

        newState[splitKeys[0]][splitKeys[1]][splitKeys[2]][splitKeys[3]] = val_to_set;
    }
    if (splitKeys.length === 5){
        newState[splitKeys[0]][splitKeys[1]][splitKeys[2]][splitKeys[3]] = {...stateData[splitKeys[0]][splitKeys[1]][splitKeys[2]][splitKeys[3]]}
        newState[splitKeys[0]][splitKeys[1]][splitKeys[2]] = {...stateData[splitKeys[0]][splitKeys[1]][splitKeys[2]]}
        newState[splitKeys[0]][splitKeys[1]] = {...stateData[splitKeys[0]][splitKeys[1]]}
        newState[splitKeys[0]] = {...stateData[splitKeys[0]]}

        newState[splitKeys[0]][splitKeys[1]][splitKeys[2]][splitKeys[3]][splitKeys[4]] = val_to_set;
    }
    if (splitKeys.length === 6){
        newState[splitKeys[0]][splitKeys[1]][splitKeys[2]][splitKeys[3]][splitKeys[4]] = {...stateData[splitKeys[0]][splitKeys[1]][splitKeys[2]][splitKeys[3]][splitKeys[4]]}
        newState[splitKeys[0]][splitKeys[1]][splitKeys[2]][splitKeys[3]] = {...stateData[splitKeys[0]][splitKeys[1]][splitKeys[2]][splitKeys[3]]}
        newState[splitKeys[0]][splitKeys[1]][splitKeys[2]] = {...stateData[splitKeys[0]][splitKeys[1]][splitKeys[2]]}
        newState[splitKeys[0]][splitKeys[1]] = {...stateData[splitKeys[0]][splitKeys[1]]}
        newState[splitKeys[0]] = {...stateData[splitKeys[0]]}

        newState[splitKeys[0]][splitKeys[1]][splitKeys[2]][splitKeys[3]][splitKeys[4]][splitKeys[5]] = val_to_set;
    }

    // console.log('SET STATE FROM STRING!', dataString, val_to_set)

    return newState;
}
export default set_state_from_string;