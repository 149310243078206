import React, { Component } from 'react';
// import './StoryItemPanel.css';


import InputField from '../ui_elements/inputs/InputField';
import Attribute from '../ui_elements/inputs/Attribute';
import SelectMenu from '../ui_elements/inputs/SelectMenu';
import List from '../ui_elements/inputs/List';

import { connect} from 'react-redux';


import './TestingPanel.css';


class TestingPanel extends Component {
    constructor(props) {
        super(props);
        //  let inputVal = 'aaaa';
        // this.handleTestClick = this.handleTestClick.bind(this);
        // this.handleTestClick_B = this.handleTestClick_B.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleListChange = this.handleListChange.bind(this);
        this.state = {
            value:'aaa',
            display_value:'bbb',
            my_test_thing:'her it is my test thing',
        }
      }

    // handleTestClick_B() {
    //     let current_user_id = this.props.context_data.user;
    //     console.log('THIS IS A TEST BUTTON B current_user_id', current_user_id)
    //     console.log('THIS IS A TEST BUTTON B user_data', this.props.user_data)
    //     this.props.create_example_project(current_user_id, this.props.user_data)
    // }

    // handleTestClick() {
    //     // this.props.set_db_data('story_app_data', this.props.full_state, false, 0)

    // }
    getInputValue(value){
        let cur = value;
        // cur.replace(/you/g, "<YOU>");
        return cur.replace(/ you /g, " <YOU> ");
    }
    onInputChange(value, test, event) {
        // console.log('XXXX onInputChange nested function', value)
        // console.log('  - value', value)
        // console.log('  - test', test)
        // console.log('  - event', event)
        event.stopPropagation();
        let val_to_set = event.target.value
        let type_to_set = event.target.type
        // console.log('  - event type', type_to_set)
        // console.log('  - event val', val_to_set)

        // this.inputVal = val_to_set;
        // this.setState({value:val_to_set+' - x-'})
        this.setState({value:val_to_set})
        // this.setState({display_value:this.getInputValue(val_to_set)})
        

    }

    handleListChange(value, list_id){
        console.log('handleListChange value', value)
        console.log('handleListChange list_id', list_id)
        this.setState({[list_id]:value})

        console.log('handleListChange my_test_thing', this.state.my_test_thing)

    }

    // getListSel(list_id){
    //     console.log('getListSel list_id', list_id)

    //     // this is just here for testing i can effec the list selection - testing the struture of lifting state up
    //     let list_sel = this.state[list_id];
    //     console.log('getListSel list_sel', list_sel)

    //     if (!list_sel){
    //         return;
    //     }
    //     let new_array=[]
    //     for (let index = 0; index < list_sel.length; index++) {
    //         const element = list_sel[index];
    //         if (element.startsWith("a")){
    //             new_array.push(element);
    //         }
    //     }
    //     console.log('getListSel new_array', new_array)
    //     return new_array;
        
    // }

    render () {

        // temp hacking in data
        let item_id = 'character_06130337354045083';
        let item = this.props.character_data[item_id];
        let item_type = 'character';
        let current_project = this.props.ui_data.current.current_project;
        let current_timeline = this.props.ui_data.current.current_timeline;
        let current_time = this.props.timeline_data[current_timeline].current_time

        return (
            <div className="TestingPanel"> 
                'TESTING TESTING RTESTING'
                <Attribute 
                    label={item.name}
                    // inputValue={nuklk} 
                    inputType='number'
                    item={item}
                    item_id={item_id}
                    item_type={item_type}
                    // attr_path='age'
                    attr_id ='age'
                    attr_parent={null}
                    current_project={current_project}
                    timeline={current_timeline}
                    time={current_time}
                 />
                <Attribute 
                    label={item.name}
                    // inputValue={nuklk} 
                    inputType='slider'
                    min={10}
                    max={70}
                    item={item}
                    item_id={item_id}
                    item_type={item_type}
                    // attr_path='age'
                    attr_id ='age'
                    attr_parent={null}
                    current_project={current_project}
                    timeline={current_timeline}
                    time={current_time}
                    decimal_places={0}
                 />
                {/* <InputField 
                    onInputChangeFunction = {this.onInputChange}
                    onInputChangeArgs = {['bob', 123]}
                    // inputValue = {this.state.display_value}
                    inputValue = {this.getInputValue(this.state.value)}
                    // dataSource={"card_data." + item_id + ".time."+current_timeline+".start_end_time.0"}
                    // dataTarget={"card_data." + item_id + ".time."+current_timeline+".start_end_time.0"}
                    inputType='textArea' 
                    style={{position:"relative"}} 
                    // id='AAA' 
                    label='Start Time' 
                />, */}
                {/* <Attribute 
                    label='arse'
                    inputValue={200} 
                    inputType='number'
                    item={item}
                    item_id={item_id}
                    item_type={item_type}
                    attr_path='age'
                 /> */}
                 {/* <SelectMenu
                    item_list={['aaa', 'bbb', 'sfdssf']}
                    label='the things'
                 /> */}
                 {/* <List
                    onChangeFunction={this.handleListChange}
                    list_id='myList'
                    // list_value = {['bbb', 'sfdssf']}
                    // list_value = {this.state['myList']}
                    // list_value = {this.getListSel('myList')}
                    item_list={[
                        'aaa', 'bbb', 'sfdssf', 'dsadasdasdas', 'abc',

                ]}
                    label='the things List'
                 /> */}
                {/* <Attribute 
                    label='my list'
                    inputValue= 'yy'
                    inputType='list'
                    item={item}
                    item_id={item_id}
                    item_type={item_type}
                    attr_path='age'
                    item_list={['xx', 'yy', 'hhhh']}
                 /> */}
                {/* <Attribute 
                    label='my list no data'
                    inputValue= 'yy'
                    inputType='list'
                    // item={item}
                    // item_id={item_id}
                    item_type={item_type}
                    attr_path='age'
                    item_list={['xx', 'yy', 'hhhh']}
                 /> */}
                {/* <Attribute 
                    label='my list B'
                    inputValue='c' 
                    inputType='list'
                    item={item}
                    item_id={item_id}
                    item_type={item_type}
                    attr_path='age'
                    item_list={[['dfgfdfd', 'a'], ['gfdgfd', 'b'], ['hfghf', 'c']]}
                 /> */}
                {/* <input 
                    label='nnnumber'
                    type='number' 
                    placeholder={3}
                    value={undefined} 
                /> */}

                {/* <Attribute 
                    label='bbbbbbbb'
                    // inputValue={nuklk} 
                    inputType='number'
                    item={item}
                    item_id={item_id}
                    item_type={item_type}
                    attr_path='age bbb'
                    is_keyable={true}
                    defaultValue={12}
                 /> */}
            </div>
            
        );
    }
}
{/* <span>character_06130337354045083</span> */}

const mapStateToProps = (state) => {
    return {
        // project_data:state.project_data,
        ui_data:state.ui_data,
        timeline_data:state.timeline_data,
        user_data:state.user_data,
        context_data:state.context_data,
        character_data:state.character_data,
        
    }
  }
  

const mapDispatchToProps = (dispatch) => {
    return {

        // // onFullScreenToggle: () => dispatch({type:'FULL_SCREEN_TOGGLE'}),
        // onFullScreenToggle: () => dispatch(actionCreator_ui.full_screen_toggle()),
        // testButton: () => dispatch({type:'ON_MODAL_OPEN', modalName:'test'}),

        // // testButtonDB: () => dispatch({type:'TEST_DB'}),

        // // push_data_to_db: (data) => dispatch({type:'PUSH_DATA_TO_DB', data:data}),

        // onModeChange: (mode) => dispatch({type:'SET_MODE', value:mode}),

        // testButtonDB: (event,) => dispatch(actionCreator_database.test_action(event,)),

        // set_db_data: (db_path, db_data, skip, delay) => dispatch(actionCreator_database.set_db_data(db_path, db_data, skip, delay)),
        // load_root_data_from_db: (db_path) => dispatch(actionCreator_database.load_root_data_from_db(db_path)),
        // create_example_project: (user_id, user_data) => dispatch(actionCreator_projects.create_example_project(user_id, user_data)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TestingPanel);

