/*
- returns a dict with all needed data for populating locations and other UI relted to locations

- all location Ids with useful info

*/


const get_populate_map_item_data = ( location_data, item_parent, vp_bbox) => {
    let location_ret_data = {}

    // loop through to find all locations which should be visible given item_parent key

    // if (!project_object){
    //     return;
    // }
    // let project_locations = []
    // if (project_object.hasOwnProperty('links')){
    //     if (project_object.links.hasOwnProperty('location')){
    //         project_locations = project_object.links.location
    //     }
    // };

    let project_locations = Object.keys(location_data);
    project_locations.map((location_uid) => {
        if (!location_data.hasOwnProperty(location_uid)){
            return null;
        }
        if (!location_data[location_uid].hasOwnProperty('item_parent'))
        {
            return null;
        }
        if (location_data[location_uid]['item_parent'] !== item_parent){
            return null;
        }
        // TODO test if has pos data - this is to support old data. should remove this at some point
        if (!location_data[location_uid].hasOwnProperty('pos')) {
            return null;
        }
        // test for culling
        // add vp bounding box arg to this function. Also, make a function for is_in_bbox
        
        if (
            location_data[location_uid]['pos'][0] < vp_bbox[0] || 
            location_data[location_uid]['pos'][1] < vp_bbox[1] || 
            location_data[location_uid]['pos'][0] > vp_bbox[2] || 
            location_data[location_uid]['pos'][1] > vp_bbox[3]
            ){
            return;
        }

        return location_ret_data[location_uid] = {
            name:location_data[location_uid]['name'],
            description:location_data[location_uid]['description'],
            pos: location_data[location_uid]['pos'],
            child_items:[],
        }

    })

    // collect children for each displayed item - this will drive counter badge/icon
    // Object.keys(location_data).map((location_uid) => {
    project_locations.map((location_uid) => {
            {/* //AAA console.log('location', location); */}
        // if (location_uid === '__template__')   return null ;
        if (!location_data.hasOwnProperty(location_uid)){
            return null;
        }

        let location_parent = location_data[location_uid]['item_parent']
        // //AAA console.log('location_parent', location_uid, location_parent)
        if (location_parent.startsWith('location_')){
            location_parent = location_parent.substring(5)
        }
        let keys_list = Object.keys(location_ret_data)
        // //AAA console.log('keysss',  location_parent, keys_list)
        if (location_ret_data.hasOwnProperty(location_parent)){
            location_ret_data[location_parent].child_items.push(location_uid)
        }

    })


    return location_ret_data

}

export default get_populate_map_item_data;