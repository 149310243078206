import React from 'react';
import './Frame.css';

const Frame = (props) => {
   
    let style = props.style || {};
    let label = props.label;
    return (

    <div className='FrameDiv'>
        <p className='FrameLabel'>{label}</p>
        <div style={style} className='FrameBorderDiv'>
            {props.children}
        </div>
    </div>

    )
}


export default Frame;