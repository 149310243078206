
const reducer_undo = (state, newState, action) => {

    if(action.type === 'ADD_TO_UNDO_HISTORY'){
        // console.log('RD ADD_TO_UNDO_HISTORY', action)

        newState.undo_data.history = [...state.undo_data.history];
        newState.undo_data = {...state.undo_data};

        // make copy of history
        let history = [...state.undo_data.history]
        let index = state.undo_data.index;
        
        let undo_data_to_add =  {
            undo_function_name:action.undo_function_name,
            undo_function_args:action.undo_function_args,
            redo_function_name:action.redo_function_name,
            redo_function_args:action.redo_function_args,
        }

        if (index !== 0){
            history.splice(0, index) 
        }
        //then add new sel to sel_history
        history.unshift(undo_data_to_add) 

        // if history is getting too long then trim
        if (history.length > 5){
            history.splice(5, 1) 
        }
        newState.undo_data.history = history;
        newState.undo_data.index = 0;
        
    }
    if(action.type === 'INCRIMENT_UNDO_INDEX'){
        // console.log('RD INCRIMENT_UNDO_INDEX', action)

        newState.undo_data = {...state.undo_data};
        let cur_index = state.undo_data.index + 1;

        cur_index = Math.min(Math.max(parseInt(cur_index), 0), state.undo_data.history.length);

        newState.undo_data.index = cur_index;
    }
    if(action.type === 'DECRIMENT_UNDO_INDEX'){
        // console.log('RD DECRIMENT_UNDO_INDEX', action)

        newState.undo_data = {...state.undo_data};
        let cur_index = state.undo_data.index - 1;

        cur_index = Math.min(Math.max(parseInt(cur_index), 0), state.undo_data.history.length-1);

        newState.undo_data.index = cur_index;
    }
    return newState;

};
export default reducer_undo;