import React from 'react';
import './InputField.css';
import { connect} from 'react-redux';

import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'
import new_uid from '../../../utils/new_uid'

import * as actionCreator_inputs from '../../../store/actions/actions_inputs';
// import { type } from 'os';

/*
    Refactor to support being used as
        - stand alone, no source or dest data
        - source and dest data (same as old setup)
        - custom onChange and input (this is good for keyable attrs where the value being set is a dict, but the display value might be a string or number)

    Should I be using a class for this UI?
        let try without for now


*/
const onKeyDown = (e) => {
    // console.log('HEY HEY onKeyDown e', e)
    if (e.key === 'Enter'){
        // console.log('HEY HEY onKeyDown enter!')
        if (e.target.attributes.hasOwnProperty('focusonenter')){
            // console.log('HEY HEY attr', e.target.attributes.focusonenter.value)
            // try to get enter to change focus to next item

            const element_to_focus = document.getElementById(e.target.attributes.focusonenter.value)
            // console.log('HEY HEY element_to_focus', element_to_focus)
            if (element_to_focus){
                element_to_focus.focus()
                e.stopPropagation();
                e.preventDefault();
            }
            else{
                // console.log('HEY HEY cant find element to focus')
            }

        }
    }
}

const InputField = (props) => {

    // id for element - wants to be unique
    let rand_id  = 'input_' + new_uid();
    let idToUse = props.id || rand_id;
    let item_type = props.item_type || null;
    let item_id = props.item_id || null;
    let focusOnEnter = props.focusOnEnter || null;

    let input_key = props.input_key;
    if (input_key === undefined){   input_key = null }

    var inputValue = props.inputValue;
    let inputBackgroundClass = props.inputBackgroundClass || '';

   
    let inputPlaceholder = props.inputPlaceholder || null;

    let styleToUse = props.style || {};
    let spellcheckToUse = props.spellCheck;
    if (spellcheckToUse === undefined){   spellcheckToUse = true }

    let decimal_places = props.decimal_places
    if (decimal_places === undefined){   decimal_places = 2 }
    let step = 0.01;
    if (decimal_places == 0){ step = 1;  }
    if (decimal_places == 1){ step = 0.1; }
    if (decimal_places == 2){ step = 0.01; }
    if (decimal_places == 3){ step = 0.001; }
    if (decimal_places == 4){ step = 0.0001; }

    // if input type is number and input val is given, then round to certain decimal_places
    if (props.inputType == 'number' && inputValue){
        // dont know why, but for my test element i had to put an extra parseFloat here
        inputValue=parseFloat(parseFloat(inputValue).toFixed(decimal_places));
    }
    if (inputValue === null){
        inputValue = ''
    }

    let labelToUse = null;
    let classToUse = null;
    let classToUseDiv = null;

    if (props.label){  labelToUse = props.label + '  :   '}

    // in order to always be able to provide the same thing as onChange, I'm defaulting to a function 
    // that does nothing with an empty array of args. Maybe  a better way to do this, but it works.
    // this makes the UI still work when not controlled
    let on_input_change_function = props.onChangeFunction || function doNothing(){};
    let on_input_change_args = props.onChangeArgs || [];

    // if dataTarget is provided, set function to setByDataTarget and configure args
    // this keeps behaviour the same as it always used to be
    if (props.dataTarget){
        on_input_change_function = props.setByDataTarget; 
        on_input_change_args = [props.dataTarget, props.current_project_id];
    }  





    switch(props.inputType) {
        case 'textArea':
            classToUse = props.className || 'textArea';
            return (
                <div style={styleToUse} className='textAreaDiv' item_type={item_type} item_id={item_id}>
                <label htmlFor={idToUse} item_type={item_type} item_id={item_id}>  {labelToUse} </label>
                    <textarea  
                        key={input_key} 
                        autoComplete="off"
                        data-lpignore="true" 
                        data-form-type="other"
                        // id={idToUse}
                        id={idToUse}

                        // onChange={props.onInputChange.bind(this, props.dataTarget, props.current_project_id)}
                        onChange={on_input_change_function.bind(this, ...on_input_change_args)}
                        placeholder={inputPlaceholder}
                        value={inputValue} 
                        style={props.style}
                        className={classToUse + ' ' + inputBackgroundClass} 
                        rows="3" cols="2"
                        spellCheck={spellcheckToUse}
                        onMouseUp={props.onInputFocus.bind(this, props.inputType, idToUse)}
                        onBlur={props.onInputBlur.bind(this, props.inputType, idToUse)}
                        item_type={item_type} 
                        item_id={item_id}
                    >
                    </textarea>
                </div>
            )
            break;
            

        case 'textAreaCard':
            classToUse = props.className || 'textAreaCard';
            return (
                    <textarea  
                        key={input_key} 
                        autoComplete="off"
                        data-lpignore="true" 
                        data-form-type="other"
                        id={idToUse}
                        onChange={on_input_change_function.bind(this, ...on_input_change_args)}
                        placeholder={inputPlaceholder}
                        value={inputValue} 
                        style={styleToUse}
                        className={classToUse + ' ' + inputBackgroundClass} 
                        rows="3" cols="2"
                        spellCheck={spellcheckToUse}
                        // onFocus={props.onInputFocus.bind(this, props.inputType, idToUse)}
                        onMouseUp={props.onInputFocus.bind(this, props.inputType, idToUse)}
                        onBlur={props.onInputBlur.bind(this, props.inputType, idToUse)}
                        item_type={item_type} 
                        item_id={item_id}
                    >
                    </textarea>
            )
            break;

        case 'textFieldCard':
            classToUse = props.className || 'textFieldInputCard';

            return (
                    <input 
                        key={input_key} 
                        autoComplete="off"
                        data-lpignore="true" 
                        data-form-type="other"
                        onKeyDown={onKeyDown.bind(this)}
                        onChange={on_input_change_function.bind(this, ...on_input_change_args)}
                        id={idToUse} 
                        className={classToUse + ' ' + inputBackgroundClass} 
                        type={props.inputType} 
                        value={inputValue} 
                        placeholder={inputPlaceholder}
                        style={styleToUse}
                        spellCheck={spellcheckToUse}
                        // onFocus={props.onInputFocus.bind(this, props.inputType, idToUse)}
                        onMouseUp={props.onInputFocus.bind(this, props.inputType, idToUse)}
                        onBlur={props.onInputBlur.bind(this, props.inputType, idToUse)}
                        defaultValue={props.defaultValue}
                        item_type={item_type} 
                        item_id={item_id}
                        focusonenter={focusOnEnter}
                    />
            )       
            break;      
  
        default:
            // this is the default, but it could be text or number
            classToUse = props.className || 'textFieldInput';
            classToUseDiv = props.className || 'textFieldDiv';

            return (
                <div style={styleToUse} className={classToUseDiv} item_type={item_type} item_id={item_id}>

                    <label className='InputLabel' item_type={item_type} item_id={item_id}> {labelToUse}  </label>

                    <input 
                        //chrome is ignoring autoComplete="off" for some reason, puting a random string works!
                        autoComplete="off"
                        data-lpignore="true" 
                        data-form-type="other"
                        onChange={on_input_change_function.bind(this, ...on_input_change_args)}
                        id={idToUse} 
                        className={classToUse + ' ' + inputBackgroundClass} 
                        type={props.inputType} 
                        value={inputValue} 
                        placeholder={inputPlaceholder}
                        spellCheck={spellcheckToUse}
                        step={step}
                        precision={decimal_places}
                        onMouseUp={props.onInputFocus.bind(this, props.inputType, idToUse)}
                        onBlur={props.onInputBlur.bind(this, props.inputType, idToUse)}
                        defaultValue={props.defaultValue}
                        item_type={item_type} 
                        item_id={item_id}

                    />
                </div>
            )       
            break;     
    }
    
}


const mapStateToProps = (state, props) => {

    // some logic to get input value from dataSource or prop.inputValue
    // dataSource is good for making a direct connection to the data
    // props.inputValue allows me to set what the input is manually.

    let input_value;    
    if (props.dataSource){
        input_value = get_data_obj_from_string(state, props.dataSource)
    }
    else if (props.hasOwnProperty('inputValue')){
        input_value =  props.inputValue;   
    }
    else{
        console.error('NO inputValue or dataSource provided. Must provide 1 of these!')
    }
    return {
        inputValue:input_value,
        current_project_id:state.ui_data.current.current_project,
    }

}


const mapDispatchToProps = (dispatch, props) => {

    // This takes the first token of a dataTarget and converts it to 
    // an all caps suffix to be used in each reducer. This is to make sure 
    // it works with data in that state slice.
    // then it strips the first token away because in the scope of the 
    // reducer it's not required (won't work)

    // let splitKeys = props.dataTarget.split(".");
    // let action_suffix = splitKeys[0].toUpperCase() 
    // splitKeys.splice(0, 1);
    // let new_data_target = splitKeys.join(".");


    // return {
    //     onInputChange:  (event) => dispatch({type:'ON_INPUT_CHANGE__'+action_suffix, event:event, dataTarget:new_data_target}),
    // }

    // console.log('INPUT FIELD props.do_set_db_data',  props.do_set_db_data)

    let do_set_db_data = true;
    if (props.do_set_db_data === 'false'){
        do_set_db_data = false;
    }

    // console.log('INPUT FIELD do_set_db_data',  do_set_db_data)

    return {
        // onInputChange:  (event) => dispatch({type:'ON_INPUT_CHANGE', event:event, dataTarget:props.dataTarget}),

        
        setByDataTarget: (dataTarget, current_project_id, event) => dispatch(actionCreator_inputs.on_input_change(event, dataTarget, current_project_id, do_set_db_data)),
        onInputFocus: ( input_type, input_id,) => dispatch(actionCreator_inputs.on_input_focus(input_type, input_id)),
        onInputBlur: ( input_type, input_id,) => dispatch(actionCreator_inputs.on_input_blur(input_type, input_id)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps )(InputField);
