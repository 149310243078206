import React, { Component } from 'react';
import './FlowPanel.css';
// import IndexCard from '../ui_elements/objects/card/IndexCard';
import IndexCard from '../ui_elements/objects/card/IndexCard';
// import CardThread2 from '../ui_elements/objects/path/CardThread2';
// import TestThread from '../ui_elements/objects/path/TestThread';

import CardThread from '../ui_elements/objects/thread/CardThread';

import { connect} from 'react-redux';
import Button from '../ui_elements/inputs/Button';
import ThreadPicker from '../ui_elements/objects/thread_picker/ThreadPicker';

import Viewport from '../ui_elements/objects/viewports/Viewport';
import AddItemButton from '../ui_elements/objects/viewports/AddItemButton';

import ViewportHud from '../ui_elements/huds/ViewportHud';

import Region from '../ui_elements/objects/region/Region';
import FoldoutPanel from '../ui_elements/objects/foldout_panel/FoldoutPanel';

import get_populate_card_data from '../../utils/get_populate_card_data'
import get_populate_region_data from '../../utils/get_populate_region_data'

import  get_item_path_as_array from '../../utils/get_item_path_as_array'
import  get_item_array_from_parent_item_string from '../../utils/get_item_array_from_parent_item_string'


import * as actionCreator_cards from '../../store/actions/actions_cards';
import * as actionCreator_regions from '../../store/actions/actions_regions';
import * as actionCreator_viewports from '../../store/actions/actions_viewports';
import * as actionCreator_ui from '../../store/actions/actions_ui';

import * as viewport_utils from '../../utils/viewport_utils';


class FlowPanel extends Component {

    constructor(props) {
        super(props);

        this.handleFlowMouseUp = this.handleFlowMouseUp.bind(this);
        this.handleFlowDoubleClick = this.handleFlowDoubleClick.bind(this);
        this.handleClickBreadcrumb = this.handleClickBreadcrumb.bind(this);
        this.handleClickCreateCard = this.handleClickCreateCard.bind(this);
        this.handleClickCreateRegion = this.handleClickCreateRegion.bind(this);
        this.testClick = this.testClick.bind(this);
        this.cull_bbox_buffer = 0;
 
    }
    testClick(event) {
        console.log('in testClick', event)

    }

    handleClickCreateCard(event) {
        console.log('in handleClickCreateCard')
        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];
        this.props.create_card_at_centre_of_vp(event, project_id, this.props.ui_data, this.props.viewport_data);
    }
    handleClickCreateRegion(event) {
        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];
        this.props.create_region_at_centre_of_vp(event, project_id, this.props.ui_data, this.props.viewport_data);
    }
    handleFlowDoubleClick(event) {
        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];
        this.props.create_card_at_current_pos(event, project_id, this.props.ui_data);
    }

    handleFlowMouseUp(event) {
        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];
        this.props.mouse_up_flow_vp(event, project_id, this.props.ui_data, this.props.viewport_data, this.props.card_data,  this.props.thread_data);
    }

    handleClickBreadcrumb( vp_id) {
        this.props.onClickBreadCrumb('flow_viewport', vp_id);
    }

    get_vp_bbox(ui_data, vp_data, buffer) {

        // console.log('get_vp_bbox ui_data', ui_data)
        // console.log('get_vp_bbox vp_data', vp_data)
        // console.log('get_vp_bbox buffer', buffer)
        // console.log('get_vp_bbox this.props.viewport_data', this.props.viewport_data)

        let main_panel_left = ui_data.layout.main_panel_left;
        let main_panel_right = ui_data.layout.main_panel_right;
        let main_panel_top = ui_data.layout.main_panel_top;
        let main_panel_bottom = ui_data.layout.main_panel_bottom;
        let vp_pos = vp_data['pos'];
        let vp_scale = vp_data['scale'];

        let vp_bbox = [
            (-1 * vp_pos[0] * (1.0 / vp_scale)) + (1.0 / vp_scale * buffer),
            (-1 * vp_pos[1] * (1.0 / vp_scale)) + (1.0 / vp_scale * buffer),
            (-1 * vp_pos[0] * (1.0 / vp_scale)) + ((1.0 / vp_scale) * (main_panel_right - main_panel_left)) - (1.0 / vp_scale * buffer),
            (-1 * vp_pos[1] * (1.0 / vp_scale)) + ((1.0 / vp_scale) * (main_panel_bottom - main_panel_top)) - (1.0 / vp_scale * buffer),
        ]
        return vp_bbox;
    }

    test_bbox() {
        // console.log('test_bbox')
        let vp_bbox = this.get_vp_bbox(this.props.ui_data, this.props.viewport_data[this.props.current_viewport[1]], this.cull_bbox_buffer);
        


        let style = {
            position:'absolute',
            left:vp_bbox[0] +'px',
            top: vp_bbox[1] +'px',
            width:(vp_bbox[2] - vp_bbox[0])+'px',
            height:(vp_bbox[3] - vp_bbox[1])+'px',
            //backgroundColor:'green',
            borderStyle:'dotted',
            borderColor:'purple',
            pointerEvents: 'none',
        }
        return(
            <div key='bbox' style = {style} />
        )

    }

    test_bbox2() {
        console.log('test_bbox2')


        let selected = this.props.ui_data.control.selected;
        if (selected === null){
            return [];
        }
        let item_array = [];
        for (let index = 0; index < Object.keys(selected).length; index++) {
            const item_id = Object.keys(selected)[index];
            let item_type = selected[item_id]['item_type']

            // if (item_type == 'card'){
                item_array.push(this.props[item_type + '_data'][item_id])
            // }
            
        }
        // let bbox = [20, 60, 120, 160];
        let bbox = viewport_utils.get_bbox_from_items(item_array)
        if (bbox === null){
            return [];
        }
        let style = {
            position:'absolute',
            left:bbox[0] +'px',
            top: bbox[1] +'px',
            width:(bbox[2] - bbox[0])+'px',
            height:(bbox[3] - bbox[1])+'px',
            backgroundColor:'purple',
            opacity:.5,
            borderStyle:'dotted',
            borderColor:'purple',
            pointerEvents: 'none',
        }
        return(
            <div key='bbox' style = {style} />
        )
    }







    populateAllRegions(item_parent) {
        // console.log(' --------------  this.props.viewport_data', this.props.viewport_data)
        // console.log(' --------------  this.props.current_viewport', this.props.current_viewport)
        // if (!this.props.current_viewport){
        //     console.log(' ---NOT FOUND!!! -----  this.props.current_viewport', this.props.current_viewport)
        //     return {};
        // }
        // TODO - i think i can delete the following.
        if (!this.props.viewport_data[this.props.current_viewport[1]]){
            return null;
        }
        // console.log('populateAllRegions VP DATA', this.props.viewport_data[this.props.current_viewport[1]]);
        let vp_bbox = this.get_vp_bbox(this.props.ui_data, this.props.viewport_data[this.props.current_viewport[1]], this.cull_bbox_buffer);

            
        // let vp_bbox = [];
        
        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];
        // let populate_region_data = get_populate_region_data(project_object, this.props.region_data, item_parent, vp_bbox)
        let populate_region_data = get_populate_region_data( this.props.region_data, item_parent, vp_bbox)
        if (!populate_region_data){
            return null;
        }
        var result = Object.keys(populate_region_data).map((region_uid) => {
            let child_count = populate_region_data[region_uid]['child_items'].length
            return(
                <Region 
                //<IndexCard
                    key={region_uid}
                    text='' 
                    name={populate_region_data[region_uid]['name']} 
                    pos={populate_region_data[region_uid]['pos']}
                    size={populate_region_data[region_uid]['size']}
                    color={populate_region_data[region_uid]['color']}
                    opacity={populate_region_data[region_uid]['opacity']}
                    item_id={region_uid}
                    item_type='region'
                    //child_count={child_count} 
                />
            ); 
        })
        return  result;
    }

    populateAllCards(item_parent) {

        // TODO I'm repeating this call. Could do once higher up
        if (!this.props.viewport_data[this.props.current_viewport[1]]){
            return null;
        }
        let vp_bbox = this.get_vp_bbox(this.props.ui_data, this.props.viewport_data[this.props.current_viewport[1]], this.cull_bbox_buffer);

            
        // let vp_bbox = [];
        
        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];
        // let populate_card_data = get_populate_card_data(project_object, this.props.card_data, item_parent, vp_bbox)
        let populate_card_data = get_populate_card_data(this.props.card_data, item_parent, vp_bbox)
        if (!populate_card_data){
            return null;
        }
        // CAN LATER USE THE FLOW VP TO SCOPE WHICH CARDS ARE POPULATED
        // TODO: do i need to feed so much data in at this stage?? shouldn't the uid be enough and the index card will be self aware?
        var result = Object.keys(populate_card_data).map((card_uid) => {
            let child_count = populate_card_data[card_uid]['child_items'].length
            return(
                <IndexCard 
                //<IndexCard
                    key={card_uid}
                    text='' 
                    title={populate_card_data[card_uid]['title']} 
                    synopsis={populate_card_data[card_uid]['synopsis']} 
                    //l_pos={populate_card_data[card_uid]['pos'][0]+'px'} 
                    //t_pos={populate_card_data[card_uid]['pos'][1]+'px'} 
                    pos={populate_card_data[card_uid]['pos']}
                    size={populate_card_data[card_uid]['size']}
                    item_id={card_uid}
                    item_type='card'
                    child_count={child_count} 
                />
            ); 
        })
        return  result;
    }

    populate_bread_crumbs(item_parent){
        // console.log('------- populate_bread_crumbs - item_parent', item_parent)

        let parent_item_array = get_item_array_from_parent_item_string(item_parent)
        // console.log('------- populate_bread_crumbs - parent_item_array', parent_item_array)

        let item_path_as_array = [];
        if (item_parent.startsWith('card_')){
            item_path_as_array = get_item_path_as_array(this.props.card_data, parent_item_array, [])
        }
        // console.log('------- populate_bread_crumbs -   item_path_as_array', item_path_as_array)

        // if item_path_as_array is empty it means im at project (top) level, so don't need to show breadcrumbs
        if (item_path_as_array.length === 0)
        {
            // console.log('------- populate_bread_crumbs - return null' )
            return null;
        }
        var result = <div className='BreadCrumbsDiv'>
            {
                // loop back through path array items
                item_path_as_array.reverse().map((item) => {
                    {/* console.log('XXX--- item', item) */}
                    let title = '';
                    let bc_class = 'BreadCrumb';
                    {/* if (item === 'topLevel') { */}
                    if (item.startsWith('project_')) {
                        title = 'home...' ;
                        bc_class = 'BreadCrumbHome'
                    }
                    else {
                        let item_array = get_item_array_from_parent_item_string(item)
                        {/* console.log('------- XXXXXXXXXXXXXXXXX', item_array) */}

                        title = this.props.card_data[item_array[1]].title;
                    } 
                    return(
                        [
                            <div 
                                id={'breadcrumb-div-' + title}
                                key={item}
                                className={bc_class}
                            // onClick={this.handleClickBreadcrumb(parent_item_array[0], parent_item_array[1])}
                                onClick={() => {this.handleClickBreadcrumb(item)}}
                                item_type='breadcrumb'
                                item_id={item}
                            >
                                {title}
                            </div>,
                            <div className='BreadCrumbSlash'> / </div>
                        ]
                    ); 
                })
            }
            <div className='BreadCrumbCurrent' >
                {this.props.card_data[parent_item_array[1]].title} 
                {/* {this.props.card_data[parent_item_array[1]]}  */}
            </div>
        </div>
         return  result;
    }


    populateAllThreads(flow_vp) {
        // console.log('\n\n populateAllThreads thread_data', this.props.thread_data)

        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];

        // CAN LATER USE THE FLOW VP TO SCOPE WHICH CARDS ARE POPULATED
        // if (!project_object.hasOwnProperty('thread')){
        //     return null;
        // }
        var result = Object.keys(this.props.thread_data).map((col_id) => {
        // var result = Object.keys(project_object.thread).map((col_id) => {
            if (col_id !== 'cur_dragging') {

                // if (!project_object.thread.hasOwnProperty(col_id)){
                //     return null;
                // }
                // console.log('populateAllThreads in loop', col_id)
                
                let cons_list = [];
                if (this.props.thread_data[col_id]['connections'] !== null){
                    cons_list = Object.keys(this.props.thread_data[col_id]['connections'])
                }
                // var resultB = Object.keys(this.props.thread_data[col_id]['connections']).map((con_id) => {
                var resultB = cons_list.map((con_id) => {

                // var resultB = Object.keys(project_object.thread[col_id]['connections']).map((con_id) => {
                    {/* //AAA console.log('card', card); */}
                    // console.log('populateAllThreads in next loop', col_id, con_id)


                    // Testing which threads should render here - 
                    let show_thread = false;

                    // new ones cnnnected to mouse should render
                    if (this.props.thread_data[col_id]['connections'][con_id][0][0] === 'mouse' ||
                    this.props.thread_data[col_id]['connections'][con_id][1][0] === 'mouse' 
                    ){
                        // console.log('show_threadA')
                        show_thread = true;
                    }

                    // threads with both in and out cards both visible in current vp should render
                    if (this.props.thread_data[col_id]['connections'][con_id][0][0] === 'card' && 
                        this.props.thread_data[col_id]['connections'][con_id][1][0] === 'card' 
                    )
                    { 
                        //skip if not in project
                        // if (!project_object.thread[col_id]['connections'].hasOwnProperty(con_id)){
                        //     return null;
                        // }
                        let in_card = this.props.thread_data[col_id]['connections'][con_id][0][1];
                        let out_card = this.props.thread_data[col_id]['connections'][con_id][1][1];
                        let cur_vp = this.props.ui_data.current.current_viewport[1];

                        // if in and out cards exist show thread, otherwise, delete thread
                        if (this.props.card_data.hasOwnProperty(in_card) && this.props.card_data.hasOwnProperty(out_card)){
                        
                            // test if visible on current vp
                            if (this.props.card_data[in_card]['item_parent'] === cur_vp && 
                            this.props.card_data[out_card]['item_parent'] === cur_vp 
                            ) {
                                show_thread = true; 
                            };
                        }
                        else {
                            console.log('\n\nWARNING!!! DELETING THREAD HERE!', col_id, con_id)
                            this.props.delete_thread(project_id, col_id, con_id)
                        }
                    };
                    if (show_thread) {
                        return(
                            <CardThread 
                                //key={col_id + con_id[0][0]  + con_id[0][1] + con_id[1][0] + con_id[1][1]}
                                key={col_id + '_' + con_id}
                                item_type='cardThread'
                                item_id={col_id + '_' + con_id}
                                threadId={col_id}
                                connectionId={con_id}
                                outType={this.props.thread_data[col_id]['connections'][con_id][0][0]}
                                inType={this.props.thread_data[col_id]['connections'][con_id][1][0]}
                                outId= {this.props.thread_data[col_id]['connections'][con_id][0][1]}
                                inId= {this.props.thread_data[col_id]['connections'][con_id][1][1]}
                                threadColor={this.props.thread_data[col_id]['color']}
                                />
                        ); 
                    };
                })
                return  resultB;
            }
        })
        return  result;
    }




    render() {
        // let VP_TO_USE = this.props.current_viewport[1];

        let vp_bbox = [0,0,1000, 300];
        let style = {
            // position:'absolute',
            // left:vp_bbox[0]+'px',
            // top:vp_bbox[1]+'px',
            // right:vp_bbox[2]+'px',
            // bottom:vp_bbox[3]+'px',
            // backgroundColor:'green',
            // opacity:'.2',
        }
        // return(
        //     <div style = {style} />
        // )


        return (
            <div className="FlowPanel"
                onDoubleClick={this.handleFlowDoubleClick}
                onMouseUp={this.handleFlowMouseUp}
            >
                {/* <div className='xxxtest' /> */}

                <Viewport
                    do_cursor={false}
                    vp_type={this.props.current_viewport[0]}
                    vp_id={this.props.current_viewport[1]}
                >

                    {this.populateAllRegions(this.props.current_viewport[1])}
                    {this.populateAllThreads()}
                    {this.populateAllCards(this.props.current_viewport[1])}

                    {/* {this.test_bbox2()} */}
                    {/* <div key='xxxtest2' className='xxxtest2' /> */}

                    {/* <svg 
                        item_type='cardThread'
                        className='SVG_XX' 
                        height="10000"
                        width="10000" 
                    >
                        <path  
                            item_type='cardThread'
                            item_id='aaaaa'
                            className='Path_Selector_XX' id="lineAB" 
                            d={'M ' + 0 + ' ' + 0 + ' l ' + 100 + ' ' + 100} 
                            stroke='red'
                            strokeWidth={10+14} fill="none" 
                            onMouseDown={this.testClick}
                        />
                    
                    </svg> */}
                    

                    {/* <CardThread
                        //key={Math.random()}
                        item_type='cardThread'
                        item_id={col_id + '_' + con_id}
                        threadId={col_id}
                        connectionId={con_id}
                        outType={this.props.thread_data[col_id]['connections'][con_id][0][0]}
                        inType={this.props.thread_data[col_id]['connections'][con_id][1][0]}
                        outId= {this.props.thread_data[col_id]['connections'][con_id][0][1]}
                        inId= {this.props.thread_data[col_id]['connections'][con_id][1][1]}
                        threadColor={this.props.thread_data[col_id]['color']}
                        onMouseDown_function={this.testClick}
                    /> */}
                    {/* <TestThread/> */}

                </Viewport>

                {this.populate_bread_crumbs(this.props.current_viewport[1])}

                <ViewportHud  key='vphud' item_type='aaaa' item_id='adsadad' />
                {/* <AddItemButton>

                    <div 
                        style={{'backgroundColor': 'rgb(238, 225, 169)',}}
                        onClick={this.handleClickCreateCard} className='additem_test'> 
                        Create New Card
                    </div>
                    <div 
                        style={{'backgroundColor': 'rgb(100, 190, 225)'}}
                        onClick={this.handleClickCreateRegion} className='additem_test'> 
                        Create New Backdrop
                    </div>

                </AddItemButton> */}

                {/* <FoldoutPanel>
                    <div style={{width:'200px', height:'500px', backgroundColor:'green'}}/>
                </FoldoutPanel> */}
                
            </div>

        );
    };
}



const mapStateToProps = (state) => {
    let current_vp = state.ui_data.current.current_viewport;
    // console.log(' -x-x-x-x-x current_vp', current_vp)
    // console.log(' -x-x-x-x-x state[current_vp[0]', state[current_vp[0]+'_data'])
    // console.log(' -x-x-x-x-x state[current_vp[0]', state['flow_viewport_data'])
    return {
        // current_sel:state.ui_data.current_sel,
        // dragging:state.ui_data.control.dragging,
        // mouse_current_pos:state.ui_data.control.mouse_current_pos,
        // hovering:state.ui_data.control.hovering,
        card_data:state.card_data,
        region_data:state.region_data,
        thread_data:state.thread_data,
        viewport_data:state[current_vp[0]+'_data'],
        current_viewport:state.ui_data.current.current_viewport,
        ui_data:state.ui_data,
        // project_data:state.project_data,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        create_card_at_current_pos: (event,  project_id, ui_data) => dispatch(actionCreator_cards.create_card_at_current_pos(event, project_id, ui_data)),
        create_card_at_centre_of_vp: (event, project_id, ui_data, viewport_data) => dispatch(actionCreator_cards.create_card_at_centre_of_vp(event, project_id, ui_data, viewport_data)),
        create_region_at_centre_of_vp: (event, project_id, ui_data, viewport_data) => dispatch(actionCreator_regions.create_region_at_centre_of_vp(event, project_id, ui_data, viewport_data)),
        mouse_up_flow_vp:(event, project_id, ui_data, viewport_data, card_data, thread_data) => dispatch(actionCreator_cards.mouse_up_flow_vp(event, project_id, ui_data, viewport_data, card_data, thread_data)),
        onClickBreadCrumb: (vp_type, vp_id) => dispatch(actionCreator_viewports.set_current_viewport(vp_type, vp_id)),
        delete_thread: (project_id, thread_id, connection_id) => dispatch(actionCreator_cards.delete_thread(project_id, thread_id, connection_id)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(FlowPanel);