




export const get_time_bbox_from_items = ( item_array, timeline) => {
    /*
        item_array is an array of any objects which have pos and size attrs,
        eg cards, regions
    */
    let min_x = null;
    let min_y = null;
    let max_x = null;
    let max_y = null;
    for (let index = 0; index < item_array.length; index++) {
        const item = item_array[index];
        if (item.hasOwnProperty('time')){
            if (item.time.hasOwnProperty(timeline)){
                // console.log('GOT HERE item', item);
                // console.log('GOT HERE timeline', timeline);
                let startX = item.time[timeline]
                // console.log('GOT HERE startX', startX);

                let start = item.time[timeline].start_end_time[0]*100;
                let end = item.time[timeline].start_end_time[1]*100;
                let row_top = (item.time[timeline].row*-64)- 64;
                let row_bottom = item.time[timeline].row*-64;

                // console.log('GOT HERE start', start);
                // console.log('GOT HERE end', end);

                if (min_x === null || start < min_x) { min_x = start; }
                if (min_y === null || row_top < min_y) { min_y = row_top; }
                if (max_x === null || end > max_x) { max_x = end; }
                if (max_y === null || row_bottom > max_y) { max_y = row_bottom; }

                // console.log('GOT HERE XXX ', item);

            }
        }
    }


    // if min_x is null then i didn't find any valid items, so return null
    if (min_x === null){
        console.log('get_bbox_from_items - No valid items for bbox found', item_array);
        return null;
    }
    else{
        return [min_x, min_y, max_x, max_y]; 
    }
}




export const get_frame_timeline_to_bbox_vals = ( bbox, timeline_panel_width, timeline_panel_height) => {
    /*
        get values needed for viewport to frame on given bbox

    */
    if (bbox === null){
        return null;
    }
    let centre_pos = [
        (bbox[0] + bbox[2]) * .5,
        (bbox[1] + bbox[3]) * .5,
    ]
    let bbox_width = bbox[2] - bbox[0];
    let bbox_height = bbox[3] - bbox[1];

    // let scale_width = Math.abs(timeline_panel_width/(bbox_width*1.1)) ;
    // let scale_height = Math.abs(timeline_panel_height/(bbox_height*1.1));
    let scale_width =  timeline_panel_width/(bbox_width*1.1) ;
    let scale_height = timeline_panel_height/(bbox_height*1.1);

    let scale = scale_width;
    if (scale_height < scale_width){
        scale = scale_height;
    }
    // if (scale > 1.5){
    //     scale =1.5;
    // }  


    let pos = [
        (centre_pos[0]*-1) + (timeline_panel_width*.5) - (centre_pos[0]*(scale-1)),
        (centre_pos[1]*-1) + (timeline_panel_height*.5) - (centre_pos[1]*(scale-1)),
    ]

    // console.log('get_frame_viewport_to_bbox_vals centre_pos', centre_pos)
    // console.log('get_frame_viewport_to_bbox_vals pos', pos)


    return [pos, scale]
}





// export const get_viewport_pos_from_screen_pos = (viewport_div_offset, viewport_pos, viewport_scale, screen_pos) => {
//     /*
//         NOTE - this is the same as get_mouse_pos_in_viewport - it is designed to replace it as part of a tidy up.
//         Simple function to get mouse position in viewport co-ords.
//         Probably a smarter way of ding this -eg pass in event and panel div id or something?
//         ATM this requires a lot of input.
//     */
//     let viewport_pos_result = [
//         Math.round((screen_pos[0] - viewport_div_offset[0]  -  viewport_pos[0]) / viewport_scale),
//         Math.round((screen_pos[1] - viewport_div_offset[1] -  viewport_pos[1]) / viewport_scale)
//     ]

//     return viewport_pos_result;
// }
