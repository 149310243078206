const reducer_regions = (state, newState, action) => {

    // with current setup I don't want to copy state - because I'm already recieving a copy from the root reducer
    // const newState = {...state};


    if(action.type === 'SET_REGION_DATA'){
        // console.log('RD SET_REGION_DATA', action)
        newState.region_data = {...state.region_data};
        newState.region_data[action.uid] = action.data;
    }





    // /////////////////////////////////////////////////////////////////////////////////////////////////////
 
    // if(action.type === 'REGION_MOUSE_DOWN'){
    //     //AAA console.log('RD REGION_MOUSE_DOWN', action)
    //     newState.ui_data = {...state.ui_data}
    //     newState.ui_data.control.local_orig_pos = action.pos;
    // }

    // /////////////////////////////////////////////////////////////////////////////////////////////////////

    // if(action.type === 'SET_REGION_POS'){
    //     //AAA console.log('RD SET_REGION_POS', action)

    //     newState.region_data[action.region_id] = {...state.region_data[action.region_id]};
    //     newState.region_data = {...state.region_data};
        
    //     newState.region_data[action.region_id]['pos'] = action.pos;
    // }
    

    // if(action.type === 'SET_THREAD_CUR_DRAGGING'){
    //     //AAA console.log('RD SET_THREAD_CUR_DRAGGING', action)

    //     newState.thread_data = {...state.thread_data};

    //     newState.thread_data.cur_dragging = [action.col_id, action.connection_id, action.start_or_end];
    // }

    // if(action.type === 'SET_REGION_PARENT'){
    //     //AAA console.log('RD SET_REGION_PARENT', action)

    //     newState.region_data[action.region_id] = {...state.region_data[action.region_id]};
    //     newState.region_data = {...state.region_data};

    //     newState.region_data[action.region_id]['item_parent'] = action.item_parent;
    // }


    // if(action.type === 'DELETE_THREAD'){
    //     //AAA console.log('RD DELETE_THREAD', action)

    //     newState['thread_data'][action.thread_id]['connections'] = {...state['thread_data'][action.thread_id]['connections']};
    //     newState['thread_data'][action.thread_id] = {...state['thread_data'][action.thread_id]};
    //     newState['thread_data'] = {...state['thread_data']};

    //     delete newState.thread_data[action.thread_id]['connections'][action.connection_id]; 
    // }



    return newState;

};
export default reducer_regions;