// import * as firebase from "firebase";
// import firebase from 'firebase/app';
import firebase from 'firebase/compat/app';

import 'firebase/auth'

export const get_database_item_path = (db_root, project_id, item_type, item_id) => {

    // console.log('get_database_item_path', db_root, project_id, item_type, item_id)
    if (!project_id){
        console.log('\n\nWARNING Project has returned as null. This shouldnt happen')
    }
    let db_path = db_root+'/project_data/'+ project_id + '/'+item_type+'_data/'+item_id;
    return db_path

}



export const get_database_path_from_data_target = (data_target, db_root, project_id) => {
    let db_path = null;

    // console.log('get_database_path_from_data_target', data_target, db_root, project_id)
    if (!project_id){
        console.log('\n\nWARNING Project has returned as null. This shouldnt happen')
    }

    // some hard coding for projects data - this kind of data should not be nested back under project data 
    // if (data_target.startsWith('project_data.')){
    if (data_target.startsWith('projects.') || data_target.startsWith('project_data.') || data_target.startsWith('user_data.')){
        db_path = db_root + '/' + data_target.replace(/\./g, '/');
        // console.log('\n\ alt data path', db_path)
    }
    else{
        db_path = db_root +'/project_data/'+ project_id + '/' + data_target.replace(/\./g, '/');
    }
    return db_path

}


export const get_database_path_from_attr_path = (attr_path) => {
    // console.log('get_database_path_from_attr_path', attr_path)
    return attr_path.replace(/\./g, '/');

}



export const get_firebase_current_user = () => {

    return firebase.auth().currentUser;
}


