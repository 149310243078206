

const reducer_timeline_cards = (state, newState, action) => {

    if(action.type === 'TIMELINE_CARD_MOUSE_DOWN'){
        // console.log('RD TIMELINE_CARD_MOUSE_DOWN', action);

        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data = {...state.ui_data}

        newState.ui_data.control.local_orig_start_end_time = action.start_end_time;
        newState.ui_data.control.local_timeline_row = action.timeline_row;

    }

    if(action.type === 'SET_CARD_TIME'){
       // console.log('RD SET_CARD_TIME', action);

        newState.card_data[action.card_id]['time'][action.current_timeline] = {...state.card_data[action.card_id]['time'][action.current_timeline]};
        newState.card_data[action.card_id]['time'] = {...state.card_data[action.card_id]['time']};
        newState.card_data[action.card_id] = {...state.card_data[action.card_id]};
        newState.card_data = {...state.card_data};

        newState.card_data[action.card_id]['time'][action.current_timeline]['start_end_time'] = action.start_end_time;
        newState.card_data[action.card_id]['time'][action.current_timeline]['row'] = action.timeline_row;
    }  


    return newState;

};
export default reducer_timeline_cards;