
/////////////////////// Cards Action Creators  /////////////////////////////
///////////////////////////////////////////////////////////////////////////////
import { database } from "../../config/firebase";


import new_uid from '../../utils/new_uid';
// import * as actions_utility from './actions_utility';
// import template_card from '../templates/template_card';
// import template_flow_viewport from '../templates/template_flow_viewport';
import templates from '../templates/templates';

import * as actions_database from './actions_database';
import * as Constants from '../../constants/constants';
import * as database_utils from '../../utils/database_utils'
import * as time_utils from '../../utils/time_utils'
import get_mouse_pos_in_viewport from '../../utils/get_mouse_pos_in_viewport'


// var SKIP_DATABASE = true;

export const create_card_at_current_time = (event,  project_id, ui_data, viewport_data, timeline_data) => {
    // console.log('AC create_card_at_current_time', event,  project_id, ui_data, viewport_data, timeline_data)

    return dispatch => {
        let hovering = ui_data.control.hovering;
        let current_viewport = ui_data.current.current_viewport;
        let local_current_pos = ui_data.control.local_current_pos;
        let current_timeline = ui_data.current.current_timeline;

        // if (hovering[0] === 'viewport') {
            
            let default_card_dimensions = [294, 196]
            let item_parent = current_viewport[1];

            // what are these numbers? I assume they relate to the offset of the pin location to the 
            // top left corner

            // THIS IS FOR VP. WILL DO SAME FOR TIMELINE BELOW
            let viewportPos = viewport_data[item_parent].pos;
            let orig_scale = viewport_data[item_parent].scale;
    
            // let pos = [local_current_pos[0] - 165, local_current_pos[1] - 45,] ;

            let centre_pos = [
                ui_data.layout.main_panel_left  +  (.5 * ( ui_data.layout.main_panel_right - ui_data.layout.main_panel_left)),
                ui_data.layout.main_panel_top  +  (.5 * ( ui_data.layout.main_panel_bottom - ui_data.layout.main_panel_top)),
            ];
            let vp_mouse_pos = get_mouse_pos_in_viewport(
                [ui_data.layout.main_panel_left, ui_data.layout.main_panel_top], 
                viewportPos,
                orig_scale,
                centre_pos
                )

            let pos = [
                Math.round(vp_mouse_pos[0] - (.5*default_card_dimensions[0])),
                Math.round(vp_mouse_pos[1] - (.5*default_card_dimensions[1])),
            ]


            // // THIS IS FOR TIMELINE

            let tl_Pos = timeline_data[current_timeline].pos;
            let tl_scale = timeline_data[current_timeline].scale;
 
        
            let tl_centre_pos = get_mouse_pos_in_viewport(
                [ui_data.layout.main_panel_left, ui_data.layout.main_panel_top], 
                tl_Pos,
                tl_scale,
                centre_pos
                )
            let new_start_end = [
                (tl_centre_pos[0] * .01) - 2.5,
                (tl_centre_pos[0] * .01) + 2.5,
            ]
            

            let uid = 'newCard_'+new_uid();

            // let db_path = Constants.DB_ROOT+'/card_data/'+uid;
            // NOTE: refactoring for project based DB structure
            // let db_path = Constants.DB_ROOT+'/project_data/'+ project_id + '/card_data/'+uid;
            let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'card', uid);

            //get card data from template and update pos and parent
            let card_data = {...templates['card']};
            card_data['pos']=pos;
            card_data['item_parent']=item_parent;
            card_data['time']={[current_timeline]:{row:1, start_end_time:new_start_end}}

            // console.log('card_data', card_data)
            // database.ref(db_path).set(card_data)
            actions_database.set_db_data(db_path, card_data, window.SKIP_DATABASE)    
            .then (
                dispatch( { type: "SET_CARD_DATA", 
                    uid:uid, 
                    data:card_data,
                }),
            )
            .then (
                dispatch( { 
                    type: "SELECT_ITEM_C", 
                    data: {[uid]:{item_type:'card'}},
                    select_mode: 'replace',
                })
            )
            // link new item to project
            // dispatch(actions_projects.link_to_project(project_object, project_id, 'card', [uid]))

        // }
        // else{
        //     return {type: ""};
        // }
    }


}



export const mouse_down_time_card = (event, card_id, card_item_data, ui_data) => {
    // for when mouse is down on card - store orig position

    // console.log('AC mouse_down_time_card',  ui_data, card_item_data);
    return dispatch => {
        event.stopPropagation();
            
        let current_timeline = ui_data.current.current_timeline;
        let start_end_time = card_item_data['time'][current_timeline]['start_end_time']
        let timeline_row = card_item_data['time'][current_timeline]['row']
        let dragging_data = null;




        // do i actually need this? not sure but probaby good to mirror what's happening in flow cards
        let clicked_data = {
            [card_id]:{item_type:'card', 
                custom_data:{
                    start_end_time:start_end_time,
                    timeline_row:timeline_row,
                },
            }
        }

        let current_sel = ui_data.control.selected || {};
        // let clicked_data = ui_data.control.clicked;
        let clicked_id = null;
        if (clicked_data){
            clicked_id = Object.keys(clicked_data)[0]
        }

        let new_sel_data = null;

        if (event.getModifierState("Shift") || event.getModifierState("Control")){
            // console.log('xxxx', clicked_id, current_sel)
            // if in sel, then deselect
            if (clicked_id in current_sel){
                new_sel_data = {...current_sel}
                delete new_sel_data[clicked_id]; 
            }
            else{
                new_sel_data = Object.assign(clicked_data, current_sel);

            }
            // console.log('AC mouse_up_vp_xform SHIFT held',)
        }
        else if (event.getModifierState("Alt")){
            new_sel_data = {...current_sel}
            if (clicked_id in new_sel_data){
                delete new_sel_data[clicked_id]; 
            }

        }
        else {
            // console.log('AC mouse_up_vp_xform dragging_data', dragging_data)
            new_sel_data = {...clicked_data}
            // console.log('AC mouse_up_vp_xform new_sel_data', new_sel_data)

        }
        
        // dispatch({  
        //     type: "SELECT_ITEM_C", 
        //     data:new_sel_data,
        // })




        // if nothing filled in yet, then drag clicked
        if(!dragging_data){
            dragging_data = {...new_sel_data};
        }

        dispatch({  
            type: "SET_DRAGGING_B", 
            data:dragging_data,
        }) 
        dispatch({  
            type: "TIMELINE_CARD_MOUSE_DOWN", 
            start_end_time:start_end_time,
            timeline_row:timeline_row,
        })
        dispatch({  
            type: "SET_CLICKED_TL", 
            data:clicked_data,
        })
        dispatch({  
            type: "SELECT_ITEM_C", 
            data:new_sel_data,
        })

    }
    
}





export const mouse_drag_time_card = (event, ui_data, card_data) => {
    // console.log('AC mouse_drag_time_card', event, ui_data )
    return dispatch => {
        let dragging = ui_data.control.dragging;
        let begin_drag = ui_data.control.begin_drag;
        let local_drag_offset = ui_data.control.local_drag_offset;
        let local_orig_pos = ui_data.control.local_orig_pos;
        let local_orig_start_end_time = ui_data.control.local_orig_start_end_time;
        let local_timeline_row = ui_data.control.local_timeline_row;
        let current_timeline = ui_data.current.current_timeline;
        let timeline_row_height = ui_data.layout.timeline_row_height;

        let dragging_data = ui_data.control.dragging_b;


        let start_end_time = {...local_orig_start_end_time};
        let timeline_row = local_timeline_row;
        if (begin_drag) {
            Object.keys(dragging_data).map((item_id) => {

                if (dragging[0] === 'timeCard'){


                    start_end_time = [
                        local_orig_start_end_time[0] + (.01 * local_drag_offset[0]), 
                        local_orig_start_end_time[1] + (.01 *  local_drag_offset[0]),
                    ]
                    // console.log('local_orig_start_end_time', local_orig_start_end_time)
                    // console.log('local_drag_offset', local_drag_offset)
                    // console.log('start_end_time', start_end_time)

                    let row_offset =  Math.round(local_drag_offset[1] / timeline_row_height);
                    timeline_row = local_timeline_row - row_offset;

                }
                if (dragging[0] === 'timelineCardStart'){
                    start_end_time[0] = local_orig_start_end_time[0] + (.01 * local_drag_offset[0]);
                    // console.log('START local_orig_start_end_time', local_orig_start_end_time)
                    // console.log('local_drag_offset', local_drag_offset)
                    // console.log('start_end_time', start_end_time)
                }
                if (dragging[0] === 'timelineCardEnd'){
                    start_end_time[1] = local_orig_start_end_time[1] + (.01 * local_drag_offset[0]);
                    // console.log('END local_orig_start_end_time', local_orig_start_end_time)
                    // console.log('local_drag_offset', local_drag_offset)
                    // console.log('start_end_time', start_end_time)
                }

                // console.log('start_end_time a', start_end_time);
                // let decimal_places = 2;
                // start_end_time = [ 
                //     parseFloat(start_end_time[0].toFixed(decimal_places)),
                //     parseFloat(start_end_time[1].toFixed(decimal_places)),
                // ]
                // console.log('start_end_time b', start_end_time);

                // test for overlap
                start_end_time = time_utils.test_for_overlap(card_data, item_id, start_end_time, current_timeline, timeline_row);

                if (start_end_time){
                    dispatch({ type: "SET_CARD_TIME", 
                        card_id: item_id,
                        current_timeline:current_timeline,
                        start_end_time:start_end_time,
                        timeline_row:timeline_row,
                    })

                }
            })

        }
    }


}

export const mouse_up_time_card = (event, ui_data, card_item_data) => {
    // console.log('AC mouse_up_time_card', event, ui_data )
    return dispatch => {
        let dragging = ui_data.control.dragging;
        let current_timeline = ui_data.current.current_timeline;

        let start_end_time = card_item_data['time'][current_timeline]['start_end_time']
        let timeline_row = card_item_data['time'][current_timeline]['row']


        // will filter in app before this is run
        // if (dragging[0] === 'timeCard'){

            // let db_path = Constants.DB_ROOT + '/card_data/'+dragging[1]+'/time/'+current_timeline+'/'
            let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, ui_data.current.current_project, 'card', dragging[1]) +'/time/'+current_timeline;


            // database.ref(db_path).set(pos)
            let time_data = {
                start_end_time:start_end_time,
                row:timeline_row,
            }

            actions_database.set_db_data(db_path, time_data, window.SKIP_DATABASE)    

            // .then (
            //     dispatch( { type: "SET_CARD_DATA", 
            //         new_uid:uid, 
            //         card_data:card_data,
            //     }),
            // )
        // }
    }
}


// export const mouse_drag_thread = (event, ui_data, thread_data) => {
//     // rework this so handle function can only run this if dragging[0] === 'cardAnchor')
//     //AAA console.log('AC mouse_drag_thread', event, ui_data, thread_data )

//         let hovering = ui_data.control.hovering;
//         let dragging = ui_data.control.dragging;
//         let thread_dragging = thread_data.cur_dragging

//         let start_or_end_index = 0;
//         if (thread_dragging[2] == 'end'){
//             start_or_end_index = 1;
//         }

//         let do_set_thread_target = false;
//         if (dragging[0] === 'cardAnchor') {
//             // am i hovering over a card (or its anchor)
//             if (hovering[0] === 'card' || hovering[0] === 'cardAnchor') {
//                 // This should stop snapping to own card
//                 if (hovering[1] !== dragging[1]) {
//                     do_set_thread_target=true;

//                 }
//             }
//             if (do_set_thread_target){
//                 return { type: "SET_THREAD_TARGET", 
//                     target_data:['card', hovering[1]],
//                     thread_colour_id:thread_dragging[0],
//                     thread_id:thread_dragging[1],
//                     start_or_end_index:start_or_end_index,
//                 }
//             }
//             else{
//                 return { type: "SET_THREAD_TARGET", 
//                     target_data:['mouse', null],
//                     thread_colour_id:thread_dragging[0],
//                     thread_id:thread_dragging[1],
//                     start_or_end_index:start_or_end_index,
//                 }
//             }
//         }
//         else{
//             return {type: "x"};
//         }    

//  }



//  export const mouse_up_flow_vp = (event, ui_data, viewport_data, thread_data) => {
//     /*
//     This triggers when mouse up in flow vp - not on card or anchor
//     */
//     //AAA console.log('AC mouse_up_flow_vp', event, ui_data, viewport_data, thread_data )

//     return dispatch => {

//         let dragging = ui_data.control.dragging;
//         let hovering = ui_data.control.hovering;
//         let begin_drag = ui_data.control.begin_drag;
//         let local_drag_offset = ui_data.control.local_drag_offset;
//         let local_orig_pos = ui_data.control.local_orig_pos;
    
//         // if dragging card then send its dropped pos to database
//         if (dragging[0] === 'card'){
//             let pos = [
//                 Math.round(local_orig_pos[0] +  local_drag_offset[0]), 
//                 Math.round(local_orig_pos[1] +  local_drag_offset[1])
//             ]
//             let db_path = 'story_app_data/card_data/'+dragging[1]+'/pos'
//             // database.ref(db_path).set(pos)

//             actions_database.set_db_data(db_path, pos, window.SKIP_DATABASE)    

//             // console.log('set_pos_eer')
//             // .then (
//             //     dispatch( { type: "SET_CARD_DATA", 
//             //         new_uid:uid, 
//             //         card_data:card_data,
//             //     }),
//             // )
//         }
//         // if something thread is dragging and is attached to mouse then delete thread - eg you haven't connected to anything so get rid of it
//         let thread_dragging = thread_data.cur_dragging
//         let start_or_end_index = 0;
//         if (thread_dragging[2] == 'end'){
//             start_or_end_index = 1;
//         }

//         if (thread_dragging[0]) {
//             let startType = thread_data[thread_dragging[0]]['connections'][thread_dragging[1]][0][0];
//             let endType = thread_data[thread_dragging[0]]['connections'][thread_dragging[1]][1][0];
//             let thread_con_data = thread_data[thread_dragging[0]]['connections'][thread_dragging[1]];

//             if (startType === 'mouse' || endType === 'mouse') {
//                 dispatch({ 
//                     type: "DELETE_THREAD", 
//                     thread_id:thread_dragging[0],
//                     connection_id:thread_dragging[1],
//                 })
//                 // action_array.push({ 
//                 //     type: "DELETE_THREAD", 
//                 //     thread_id:thread_dragging[0],
//                 //     connection_id:thread_dragging[1],
//                 // })
//             }
//             else{
//                 // if start or end is not mouse it must be connected so I'll push that to DB 

//                 // target_data:['mouse', null],
//                 // thread_colour_id:thread_dragging[0],
//                 // thread_id:thread_dragging[1],
//                 // start_or_end_index:start_or_end_index,

//                 let db_path = 'story_app_data/thread_data/'+thread_dragging[0]+'/connections/'+thread_dragging[1]
//                 // let thread_data_to_set = [
//                 //         ['card', hovering[1]],
//                 //         ['card', hovering[1]],
//                 //     ]
                

//                 actions_database.set_db_data(db_path, thread_con_data, window.SKIP_DATABASE)    

//             }
//         }

//         dispatch({ 
//             type: "SET_THREAD_CUR_DRAGGING", 
//             col_id:null,
//             connection_id:null,
//             start_or_end:'end',
//         })

//         // Drag and drop over card to set item parent
//         // this is my attempt to change card when hovering over another card. Not wrkig now, not sure why
//         if (dragging[0] === 'card' && hovering[0] === 'card'){

//             if (dragging[1] !== hovering[1]){
//                 let card_vp = 'card_'+hovering[1]

//                 // action_array.push({ 
//                 //     type: "SET_CARD_PARENT", 
//                 //     card_id:dragging[1],
//                 //     item_parent:card_vp,
//                 // })
//                 let db_path = 'story_app_data/card_data/'+dragging[1]+'/item_parent'
//                 console.log('set_par_db')

//                 actions_database.set_db_data(db_path, card_vp, window.SKIP_DATABASE)    
//                 .then (
//                     dispatch( { 
//                         type: "SET_CARD_PARENT", 
//                         card_id:dragging[1],
//                         item_parent:card_vp,
//                     }),
//                 )

//                 // at this point i should make a viewport if one doesn't exist
//                 // test if vp data exists

//                 if (viewport_data.hasOwnProperty(card_vp) === false){

//                     let db_path = 'story_app_data/flow_viewport_data/'+card_vp
//                     let flow_viewport_data = {...templates['flow_viewport']};

//                     console.log('new_vp')

//                     actions_database.set_db_data(db_path, flow_viewport_data, window.SKIP_DATABASE)    
//                     .then (
//                         dispatch( { 
//                             type: "MAKE_NEW_VP", 
//                             card_id:card_vp,
//                             data:flow_viewport_data,
//                         }),
//                     )

//                     // action_array.push({ 
//                     //     type: "MAKE_NEW_VP", 
//                     //     card_id:card_vp,
//                     // })

//                 }
//             }
//         }
//         if (dragging[0] === 'card' && hovering[0] === 'breadcrumb'){

//             // dispatch({ 
//             //     type: "SET_CARD_PARENT", 
//             //     card_id:dragging[1],
//             //     item_parent: hovering[1],
//             // })
//             let card_vp = hovering[1];
//             let db_path = 'story_app_data/card_data/'+dragging[1]+'/item_parent'
//             actions_database.set_db_data(db_path, card_vp, window.SKIP_DATABASE)    
//             .then (
//                 dispatch( { 
//                     type: "SET_CARD_PARENT", 
//                     card_id:dragging[1],
//                     item_parent:card_vp,
//                 }),
//             )
//         }

//         // return (dispatch) =>  { actions_utility.trigger_action_array(dispatch, action_array) };
//     }
          
// }


// export const delete_thread = (thread_id, connection_id) => {
//     console.log('AC delete_thread', thread_id, connection_id )
//     return dispatch => {
//         // event.stopPropagation();
//         let db_path = 'story_app_data/thread_data/'+thread_id+'/connections/'+connection_id

//         actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
//         .then (
//             dispatch( { 
//                 type: "DELETE_THREAD", 
//                 thread_id:thread_id,
//                 connection_id:connection_id,
//             }),
//         )
//     }
// }


