



export const get_bbox_from_items = ( item_array) => {
    /*
        item_array is an array of any objects which have pos and size attrs,
        eg cards, regions
    */
    let min_x = null;
    let min_y = null;
    let max_x = null;
    let max_y = null;
    for (let index = 0; index < item_array.length; index++) {
        const item = item_array[index];
    
        if (item.hasOwnProperty('pos') &! item.hasOwnProperty('size')){
            const item_margin = 100;

            if (min_x === null || item.pos[0]-item_margin < min_x) { min_x = item.pos[0]-item_margin; }
            if (min_y === null || item.pos[1]-item_margin < min_y) { min_y = item.pos[1]-item_margin; }
            if (max_x === null || item.pos[0]+item_margin > max_x) { max_x = item.pos[0]+item_margin; }
            if (max_y === null || item.pos[1]+item_margin > max_y) { max_y = item.pos[1]+item_margin; }
        }
        if (item.hasOwnProperty('pos') && item.hasOwnProperty('size')){
            if (min_x === null || item.pos[0] < min_x) { min_x = item.pos[0]; }
            if (min_y === null || item.pos[1] < min_y) { min_y = item.pos[1]; }
            if (max_x === null || item.pos[0] + item.size[0] > max_x) { max_x = item.pos[0] + item.size[0]; }
            if (max_y === null || item.pos[1] + item.size[1] > max_y) { max_y = item.pos[1] + item.size[1]; }
        }

    }

    // if min_x is null then i didn't find any valid items, so return null
    if (min_x === null){
        console.log('get_bbox_from_items - No valid items for bbox found', item_array);
        return null;
    }
    else{
        return [min_x, min_y, max_x, max_y]; 
    }
}


export const get_viewport_pos_from_screen_pos = (viewport_div_offset, viewport_pos, viewport_scale, screen_pos) => {
    /*
        NOTE - this is the same as get_mouse_pos_in_viewport - it is designed to replace it as part of a tidy up.
        Simple function to get mouse position in viewport co-ords.
        Probably a smarter way of ding this -eg pass in event and panel div id or something?
        ATM this requires a lot of input.
    */
    let viewport_pos_result = [
        Math.round((screen_pos[0] - viewport_div_offset[0]  -  viewport_pos[0]) / viewport_scale),
        Math.round((screen_pos[1] - viewport_div_offset[1] -  viewport_pos[1]) / viewport_scale)
    ]

    return viewport_pos_result;
}


export const get_frame_viewport_to_bbox_vals = ( bbox, viewport_panel_width, viewport_panel_height) => {
    /*
        get values needed for viewport to frame on given bbox

    */
    if (bbox === null){
        return null;
    }
    let centre_pos = [
        (bbox[0] + bbox[2]) * .5,
        (bbox[1] + bbox[3]) * .5,
    ]
    let bbox_width = bbox[2] - bbox[0];
    let bbox_height = bbox[3] - bbox[1];

    let scale_width = viewport_panel_width/(bbox_width*1.1);
    let scale_height = viewport_panel_height/(bbox_height*1.1);

    let scale = scale_width;
    if (scale_height < scale_width){
        scale = scale_height;
    }
    if (scale > 1.5){
        scale =1.5;
    }  


    let pos = [
        (centre_pos[0]*-1) + (viewport_panel_width*.5) - (centre_pos[0]*(scale-1)),
        (centre_pos[1]*-1) + (viewport_panel_height*.5) - (centre_pos[1]*(scale-1)),
    ]

    // console.log('get_frame_viewport_to_bbox_vals centre_pos', centre_pos)
    // console.log('get_frame_viewport_to_bbox_vals pos', pos)


    return [pos, scale]
}
export const get_bbox_from_item = (item) => {
    let bbox = null;
    if (item.hasOwnProperty('pos')){
        bbox = [
            item.pos[0],
            item.pos[1],
            item.pos[0],
            item.pos[1],
        ]
    }
    if (item.hasOwnProperty('pos') && item.hasOwnProperty('size')){
        bbox = [
            item.pos[0],
            item.pos[1],
            item.pos[0] + item.size[0],
            item.pos[1] + item.size[1],
        ]
    }
    return bbox;
}

export const is_item_in_bbox = ( item, bbox) => {
    // console.log('is_item_in_bbox', item, bbox)
    let item_bbox = get_bbox_from_item(item)
    if (is_bbox_in_bbox(item_bbox, bbox)) return true;
    else return false;
}
export const is_bbox_in_bbox = ( bbox_a, bbox_b) => {
    // console.log('is_bbox_in_bbox', bbox_a, bbox_b)

    if (
        bbox_a[0]>bbox_b[0] &&
        bbox_a[1]>bbox_b[1] &&
        bbox_a[2]<bbox_b[2] &&
        bbox_a[3]<bbox_b[3]
    ) {
        return true;
    }
    else return false
    
}