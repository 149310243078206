/*
reworking uber flex to make a column container with draggable children

each child should have a grabbing area to drag up and down.
Dont worry about selectins or mult-selct.
keep it simple as possible
*/


import React, { useState, useEffect, useCallback } from 'react';
import './UberColumnContainer.css';



export default function UberColumnContainer(props) {


    // const add_item_function = props.add_item_function || null;
    const reorder_items_function = props.reorder_items_function || null;
    const itemList = [];
    const elementList = props.children || [];


    let element_dict = null
    if (elementList){
        element_dict = {}
        for (let index = 0; index < elementList.length; index++) {
            const element = elementList[index];
            itemList.push(element.props.id)
            element_dict[element.props.id] = element
        }
    }
    const column_gap = 0
    const flex_padding = 20
    const borderRadius = 8
    // const [itemList, setItemList] = useState(
    //     [
    //         'bob', 'sally', 'harry', 'jane', 'frank', 'lucy', 'sam', 'molly', 'alex', 'ritchy',
    //         'bobx', 'sallyx', 'harrxy', 'janx', 'frxank', 'lxucy', 'sxam', 'molxly', 'alxex', 'rxitchy',
    //     ]
    // )

    const [duringMoveItemList, setDuringMoveItemList] = useState([])
    const [preMoveItemList, setPreMoveItemList] = useState([])

    const [dummyxxxx, setDummyxxxx] = useState('----moveItemList----')
    
    const [dummyx, setDummyx] = useState('----mouseOverNow, dragging----')
    const [dragging, setDragging] = useState()
    const [dragging_b, setDragging_b] = useState()
    // const [draggingOverIndex, setDraggingOverIndex] = useState(null)

    const [dummyax, setDummya] = useState('----lastClicked, selected----')
    const [clicked, setClicked] = useState(null)

    const [dummyazzx, setDummyzza] = useState('----selectionBbox, inSelBBox----')


    const [dummyxx, setDummyxx] = useState('----mouseDownPos,mousePos, isDragging----')
    const [mouseDownPos, setMouseDownPos] = useState(null)
    const [mousePos, setMousePos] = useState(null)
    const [isDragging, setIsDragging] = useState(false)
    const [dummy, setDummy] = useState('---- childBBoxes, indexBBoxes, columnBBox, columnChildData----')

    // const [columnBBox, setColumnBBox] = useState()
    const [childBBoxes, setChildBBoxes] = useState()
    const [indexBBoxes, setIndexBBoxes] = useState()
    const [columnBBox, setColumnBBox] = useState()
    const [columnChildData, setColumnChildData] = useState({})

    const columnDivRef = React.createRef()
    



    // this will populate state that i need just one time
    useEffect(() => {
        generate_bbox_data()
    }, []);


    // ------------
    // const handleMouseMove = useCallback(event => {
    //     console.log('handleMouseMove XXXX')
    //     on_item_mouse_move(event)
    // }, []);
    // const handleMouseUp = useCallback(event => {
    //     console.log('handleMouseUp XXXX')

    //     on_item_mouse_up(event)
    // }, []);
    const handleMouseMove = event => {
        // console.log('handleMouseMove XXXX')
        on_item_mouse_move(event)
    }
    const handleMouseUp = event => {
        // console.log('handleMouseUp XXXX')

        on_item_mouse_up(event)
    }

    
    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [handleMouseMove]);


    useEffect(() => {
        window.addEventListener("mouseup", handleMouseUp);
        return () => {
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [handleMouseUp]);




    // ------------



    const populate_bbox = () => {
        let return_items = []; 
        if (!columnChildData){
            return 
        }
        if (!columnBBox) return
        let added_bbox_heights = columnBBox.top;

        for (let index = 0; index < columnChildData.length; index++) {
            let column_gap_half = column_gap*.5
            const child_bbox_data = columnChildData[index];
            let leftbbox = child_bbox_data.left - column_gap_half
            let rightbbox = child_bbox_data.right + column_gap_half
            let topbbox = child_bbox_data.top - column_gap_half
            let bottombbox = child_bbox_data.bottom + column_gap_half
            let style = {
                position:'fixed',
                left:leftbbox,
                top:added_bbox_heights,
                width:child_bbox_data.width,
                height:child_bbox_data.height,
                width:child_bbox_data.width,
                borderStyle:'solid',
                borderColor:'yellow'
            }
            return_items.push(
                <div key={index} style={style} className='column_bbox_vis' />
            )
            added_bbox_heights = added_bbox_heights+child_bbox_data['height']

        }
        
        return return_items
    }

    const populate_column_items = () => {
        // console.log('xxxx populate_column_items START')

        let return_items = []
        let return_items_data = {}; 
        if (isDragging && duringMoveItemList){
            // console.log('xxxx populate_column_items IS DRAGGING', )

            let added_bbox_heights = columnBBox.top;

            // build dict of item style data, then apply in second loop.
            // Doing this so i can always build items in same order. This seems to 
            // be important for persistace so my transition anim works
            for (let index = 0; index < duringMoveItemList.length; index++) {
                const item_name = duringMoveItemList[index];
                return_items_data[item_name] = {
                    dragging:false,
                    top:added_bbox_heights,
                    height: childBBoxes[item_name]['height'],
                    width: childBBoxes[item_name]['width'],
                    fixed:true,
                    position:'fixed ',
                    transition:'top 0.25s, transform 0.1s',
                    // transition:'top 0.3s transform 0.1s',
                    // height:
                }
                if (dragging && item_name === dragging[0]){
                    return_items_data[item_name].dragging = true
                }

                added_bbox_heights = added_bbox_heights + childBBoxes[item_name]['height']
            }
        }

        // now loop through and add all items
        // console.log('xxxx return_items_data ', return_items_data)
        for (let index = 0; index < itemList.length; index++) {
            const item_name = itemList[index];
            let style = {}
            let grab_handle_style = {}
            if (return_items_data.hasOwnProperty(item_name)){
                style['transition']=return_items_data[item_name]['transition']
                style['position']=return_items_data[item_name]['position']
                style['top']=return_items_data[item_name]['top']
                style['height']=return_items_data[item_name]['height']
                style['width']=return_items_data[item_name]['width']
                style['pointerEvents']='none'
                // style['color']='purple'
                // style['height']=return_items_data['height']
                if (return_items_data[item_name]['dragging']){
                    // style['color']='orange'
                    style['zIndex']=1000
                    style['transform']= 'scale(1.02)';
                    grab_handle_style['backgroundColor'] = 'rgba(255,255,255,.18)'
                    // grab_handle_style['borderStyle'] = 'solid'

                    // style['transition']='.2s'
                }
                else{
                    style['opacity']= '.5';
                    grab_handle_style['opacity'] = '0'
                }
                // if (return_items_data[item_name]['position'] === 'fixed '){
                //     style['borderColor']='orange'
                //     style['borderStyle']='solid'
                // }

            }
            // let style = {}
            let element = item_name
            if (element_dict){
                element = element_dict[item_name]
            }

            return_items.push(
                <div style={style} id={item_name} key={item_name} className={'uber-column-item-top-div'}
                    // onMouseDown={(e) => {on_item_mouse_down(e, item_name)}}
                >
                    <div className={'uber-column-item-container'}>
                        {element}
                    </div>
                    {/* <i class="fas fa-grip-lines"></i> */}
                    {/* <i className='uber-column-item-grab fas fa-grip-vertical'  */}
                    {/* <i className='uber-column-item-grab fas fa-grip-lines-vertical' 
                        onMouseDown={(e) => {on_item_mouse_down(e, item_name)}}
                    /> */}
                    <div style={grab_handle_style} className={'uber-column-item-grab'}
                        onMouseDown={(e) => {on_item_mouse_down(e, item_name)}}
                    >
                        <div className='uber-column-item-grab-line-a' />
                        <div className='uber-column-item-grab-line-b' />
                    </div>

                </div>
            )
        }
        
        return return_items

    }



    const on_item_mouse_down = (e, item_id) => {
        // console.log('yyyy on_item_mouse_down e', e)

        e.stopPropagation();


        // console.log('on_item_mouse_down item_id', item_id)

        // if still in afterDragPeriod then dont recompute bboxes as they'll be wrong
        // assum can resuse as nothing is likely to change in such a short time
        // if (!afterDragPeriod){
        //     generate_bbox_data()
        // }
        generate_bbox_data()

        setPreMoveItemList([...itemList])
        setDuringMoveItemList([...itemList])
        
        let drag_index = itemList.indexOf(item_id)

        setDragging([item_id, drag_index])
        setMouseDownPos([e.clientX, e.clientY])
        setMousePos([e.clientX, e.clientY])

        // toDo. this is temp. 
        // setSelected([item_id, drag_index])
        setClicked(item_id)
        // select_item(e, item_id)

        // if clicked is not selected, then select it. 
        // If it also already selected, then do nothing - save that behaviour for mouse down
        // if dragging selected item, then include all selected

        // if (!selected.includes(item_id)){
        //     // select_item(e, item_id)
        //     select_item_by_click(item_id)
        //     setDragging_b([item_id])
        //     setDoneSelection(true)
        // }
        // else{
        //     setDragging_b(selected)
        // }


    }

    const on_item_mouse_up = (e) => {
        // console.log('xxxxx on_item_mouse_up e', e)
        e.stopPropagation();



        // when mouse up if dragging add dragging items back into itemList at correct index
        if (isDragging){
            // console.log('xxxxx on_item_mouse_up isDragging', isDragging)
            // console.log('xxxxx on_item_mouse_up reorder_items_function', reorder_items_function)

            let new_list = [...duringMoveItemList]
            // new_list.splice(draggingOverIndex, 0, ...dragging_b)

            setDuringMoveItemList([])
            // setAfterDragPeriod(true)
            setTimeout(function () {
                // setDraggingOverIndex(null)
                generate_bbox_data()
                // setAfterDragPeriod(false)
            }, 200)

            if (reorder_items_function){
                // console.log('EEE preMoveItemList', preMoveItemList)
                // console.log('xxxxxx new_list', new_list)
                reorder_items_function(new_list)
            }

        }
        else{
            // setAfterDragPeriod(false)
            // setDraggingOverIndex(null)
            generate_bbox_data()
            // setAfterDragPeriod(false)
        }



        setDragging(null)
        

        setMouseDownPos(null)
        setIsDragging(false)
        // setIsDraggingSelBox(false)

        // if (clicked === 'flexbox'){
        //     select_item_by_sel_bbox()
        // }
        // else {
        //     if (!isDragging){
        //         if (clicked){
        //             if (!doneSelection){
        //                 // select_item(e, clicked)
        //                 select_item_by_click(clicked)
        //             }
        //         }
        //     }
        // }

        // setDoneSelection(false)
        
        // setInSelBBox([])
        // setSelectionBbox(null)

        // setDraggingOverOtherFlexParent(null)
        // setDraggingOverOtherFlexParent_copy(false)
        // setDraggingOverOtherFlexParent_move(false)


    }

    const on_item_mouse_move = (e,) => {
        // console.log('on_item_mouse_move')
        let drag_threshold = 5
        if (mouseDownPos){
            // if moving more than 10 units then set isDragging to true
            if (Math.abs(e.clientX-mouseDownPos[0]) + Math.abs(e.clientY-mouseDownPos[1] ) > drag_threshold){
                setIsDragging(true)
            }
        }

        if (isDragging){
            // console.log('xxxxx on_item_mouse_move isDragging')
            setMousePos([e.clientX, e.clientY])

            // let mouse_over_item = get_mouse_over_bbox_item(e)
            let dragging_over_item_index = get_mouse_over_bbox_index(e)
            // setDraggingOverIndex(dragging_over_item_index)

                
            // let new_item_list = []
            // let added_dragging = false;
            // let counter = 0

            // // Add items up the index where pointer is when dragging
            // for (let index = 0; index < itemList.length; index++) {
            //     const element = itemList[index];
            //     if (counter < dragging_over_item_index){
            //             if (!dragging_b.includes(element)){
            //             new_item_list.push(element)
            //             counter += 1
            //         }
            //     }
            //     else break
            // }
            // // then add all dragging items
            // for (let index = 0; index < dragging_b.length; index++) {
            //     const element = dragging_b[index];
            //     new_item_list.push(element)
            // }
            // // then add the remainder
            // for (let index = 0; index < itemList.length; index++) {
            //     const element = itemList[index];

            //     if (!new_item_list.includes(element)){
            //         new_item_list.push(element)
            //     }
                
            // }
             
                    // let new_item_list = []
        // let added_dragging = false;
        // let counter = 0
        let new_item_list = [...preMoveItemList]

        if (!dragging) return
        // remove dragging item from new_item_list
        // let drag_index = new_item_list.indexOf(item_id)
        new_item_list.splice(dragging[1], 1);
        
        // then add it back in at certain index
        new_item_list.splice(dragging_over_item_index, 0, dragging[0])

        setDuringMoveItemList(new_item_list)


        }
    }



    const generate_bbox_data =() => {
        /*
            generating snapshot of bboxes
            whilst testing im making data keyed by item name as well as index
        */
        if (columnDivRef.current){
            // console.log('columnDivRef', columnDivRef)
            // let panel_width = columnDivRef.current.offsetWidth;
            let childBBoxes = {}
            let indexBBoxes = {}
            let columnChildData = []
            let flex_rect = columnDivRef.current.getBoundingClientRect();
            let flexBbox = {
                left:flex_rect.left,
                right:flex_rect.right,
                top:flex_rect.top,
                bottom:flex_rect.bottom,
                width:flex_rect.width,
                height:flex_rect.height,
            }
            // console.log('columnDivRef children', columnDivRef.current.children)
            // console.log('columnDivRef children len', columnDivRef.current.children.length)

            for (let i = 0; i < columnDivRef.current.children.length; i++) {
                // console.log('child', columnDivRef.current.children[i]);
                let child_name = columnDivRef.current.children[i].id
                if (child_name === 'add_new_item') continue

                let rect = columnDivRef.current.children[i].getBoundingClientRect();
                // console.log('rect', rect)

              
                let data_to_add = {
                    index:i,
                    item:child_name,
                    left:rect.left,
                    right:rect.right,
                    top:rect.top,
                    bottom:rect.bottom,
                    width:rect.width,
                    height:rect.height,
                }
                childBBoxes[child_name] = data_to_add
                indexBBoxes[i] = data_to_add
                columnChildData.push(data_to_add)

          }
          setChildBBoxes(childBBoxes)
          setIndexBBoxes(indexBBoxes)
          setColumnChildData(columnChildData)
          setColumnBBox(flexBbox)
        //   console.log('childBBoxes', childBBoxes)
        }
    }

    // const get_mouse_over_bbox_index_b = (e) => {
    //     let mousePos = [e.clientX, e.clientY]
    //     // console.log('get_mouse_over_bbox_item', childBBoxes)
    //     let column_gap_half = column_gap*.5

    //     let return_val = null
    //     if (!columnChildData){
    //         return 
    //     }

    //     // find out which row mouse is in. Loop through rows, first row top 
    //     // will be same as flex. So if i loop through all rows and stop when I get to 
    //     // a row_top which is below the mouse, then I can find which row I'm in.
    //     let which_row =Object.keys(columnChildData)[0];
    //     for (let row_top of Object.keys(columnChildData)){
    //         // console.log('xxxx', childBBoxes[child])
    //         if (mousePos[1] >= row_top-column_gap_half) which_row = row_top
    //         else break
    //     }
    //     if (!which_row){
    //         return
    //     }
    //     // console.log('over this are these ordered START which_row', which_row )
    //     // console.log('over this are these ordered START', Object.keys(columnChildData) )
    //     // console.log('over this are XXXXX', Object.keys(columnChildData).length -1)

    //     for (let index = 0; index < columnChildData[which_row].length; index++) {
    //         const child_bbox_data = columnChildData[which_row][index];

    //         // if on top row set the top bbox to 0 (top of screen). If bottom, set to crazy high number
    //         // This will give behavior of allowing for finding closest hit when outside flex Bbox

    //         let leftbbox = child_bbox_data.left - column_gap_half
    //         let rightbbox = child_bbox_data.right + column_gap_half
    //         let topbbox = child_bbox_data.top - column_gap_half
    //         let bottombbox = child_bbox_data.bottom + column_gap_half

    //         if (which_row == Object.keys(columnChildData)[0]) topbbox = 0
    //         if (which_row == Object.keys(columnChildData)[Object.keys(columnChildData).length -1]) bottombbox = 10000000
    //         if (index === 0) leftbbox = 0
    //         if (index === columnChildData[which_row].length -1) rightbbox = 10000000

    //             if (   mousePos[0] >= leftbbox 
    //                 && mousePos[0] < rightbbox
    //                 && mousePos[1] >= topbbox
    //                 && mousePos[1] < bottombbox
    //                 ) {
    //                     return_val = child_bbox_data.index
    //             }
           
    //     }
       
    //     return return_val

    // }

    const get_mouse_over_bbox_index = (e) => {
        /*
            get mouse pos. relative to top of column
            then loop through element BBoxes and add heights. 
            If mouse pos is less than the accumulated height, then return index
            

        */


        let mousePos = [e.clientX, e.clientY]
        let mouse_y_relative = e.clientY - columnBBox.top



        // console.log('get_mouse_over_bbox_item', childBBoxes)
        let column_gap_half = column_gap*.5

        let return_val = null
        if (!columnChildData){
            return 
        }


        // console.log('over this are these ordered START which_row', which_row )
        // console.log('over this are these ordered START', Object.keys(columnChildData) )
        // console.log('over this are XXXXX', Object.keys(columnChildData).length -1)

        let added_bbox_heights = 0

        for (let index = 0; index < columnChildData.length; index++) {
            // const child_bbox_data = columnChildData[index];

            added_bbox_heights = added_bbox_heights+columnChildData[index].height
            // console.log('xxxx mouse_y_relative', mouse_y_relative)
            // console.log('xxxx added_bbox_heights', added_bbox_heights)
            // if dist from top of column is less than the added_bbox_heights
            if (mouse_y_relative < added_bbox_heights){
                // console.log('xxxxx got this index ', index, columnChildData[index].item)
                return index
            }
        }
        if (return_val === null){
            return_val = columnChildData.length -1
        }
        return return_val

    }


    // const on_column_mouse_down_b = (e) => {
    //     console.log('on_column_mouse_down')


    //     generate_bbox_data()

    //     setMouseDownPos([e.clientX, e.clientY])
    //     setMousePos([e.clientX, e.clientY])
    //     setClicked('uberColumn')

    // }


    let flex_parent_style = {}
    let flex_style =  {}
    // if (isDragging || afterDragPeriod){
    if (isDragging){
        flex_parent_style = {
            // outline:'solid',
            // position:'fixed',
            // left:flexBBox.left,
            // // right:flexBBox.right,
            // top:flexBBox.top,
            // bottom:flexBBox.bottom,
            width:columnBBox.width,
            height:columnBBox.height,
        }
    }

    return (
        <div>
            <div style={flex_parent_style} id={props.id+'-column'} className="uber-column" ref={columnDivRef} 
                // onMouseDown={(e) => {on_column_mouse_down_b(e,)}}
            >
                {populate_column_items()}
            </div>
            {/* {populate_bbox()} */}
        </div>
    )
    
}
