import React, { Component } from 'react';
import { connect} from 'react-redux';
import './AttrEdStoryItemOptions.css';


import * as actionCreator_ui from '../../../../store/actions/actions_ui';
import * as actionCreator_story_items from '../../../../store/actions/actions_story_items';
import * as actionCreator_attributes from '../../../../store/actions/actions_attributes';



class AttrEdKeyframeOptions extends Component {
    constructor(props) {
        super(props);
        this.handleAddAttrToTimeline = this.handleAddAttrToTimeline.bind(this);
        this.handleRemoveAttrFromTimeline = this.handleRemoveAttrFromTimeline.bind(this);
        this.handleDeleteKey = this.handleDeleteKey.bind(this);
        this.handleAddKey = this.handleAddKey.bind(this);
    //     this.handleLogOutUserClick = this.handleLogOutUserClick.bind(this);
    //     this.fileSelectHandler = this.fileSelectHandler.bind(this);
    //     this.handleThumbClick = this.handleThumbClick.bind(this);
    }


    handleAddKey(event) {
        // console.log('handleAddAttrToTimeline')
        let data = {[this.props.item_id]:{item_type:this.props.item_type}}
        console.log('handleAddKey data', data)
        this.props.close_all_foldout_panels();

    }
    handleRemoveKey(event) {
        // console.log('handleAddAttrToTimeline')
        let data = {[this.props.item_id]:{item_type:this.props.item_type}}
        console.log('handleRemoveKey data', data)
        this.props.close_all_foldout_panels();

    }
    handleAddAttrToTimeline(event) {
        // console.log('handleAddAttrToTimeline')
        let data = {[this.props.item_id]:{item_type:this.props.item_type}}
        console.log('handleAddAttrToTimeline data', data)
        let extra_data = this.props.extra_data;
        console.log('handleAddAttrToTimeline extra_data', extra_data)

        this.props.add_to_timeline(
            extra_data['item'], 
            extra_data['item_type'], 
            extra_data['item_id'], 
            extra_data['attr_id'], 
            extra_data['attr_parent'], 
            extra_data['current_project'],
            extra_data['time'],
            extra_data['timeline'],
        )
        this.props.close_all_foldout_panels();

    }
    
    handleDeleteKey(event) {
        console.log('handleDeleteKey')
        // let data = {[this.props.item_id]:{item_type:this.props.item_type}}
        // console.log('handleAddAttrToTimeline data', data)
        let extra_data = this.props.extra_data;
        console.log('handleAddAttrToTimeline extra_data', extra_data)

        this.props.delete_key(
            extra_data['item'], 
            extra_data['item_type'], 
            extra_data['item_id'], 
            extra_data['attr_id'], 
            extra_data['attr_parent'], 
            extra_data['current_project'],
            extra_data['time'],
            extra_data['timeline'],
        )
        this.props.close_all_foldout_panels();

    }
    
    handleAddKey(event) {
        console.log('handleAddKey')
        // let data = {[this.props.item_id]:{item_type:this.props.item_type}}
        // console.log('handleAddAttrToTimeline data', data)
        let extra_data = this.props.extra_data;
        console.log('handleAddAttrToTimeline extra_data', extra_data)

        this.props.add_key(
            extra_data['item'], 
            extra_data['item_type'], 
            extra_data['item_id'], 
            extra_data['attr_id'], 
            extra_data['attr_parent'], 
            extra_data['current_project'],
            extra_data['time'],
            extra_data['timeline'],
        )
        this.props.close_all_foldout_panels();

    }
    
    handleRemoveAttrFromTimeline(event) {
        // console.log('handleAddAttrToTimeline')
        let data = {[this.props.item_id]:{item_type:this.props.item_type}}
        console.log('handleRemoveAttrFromTimeline data', data)
        let extra_data = this.props.extra_data;
        console.log('handleRemoveAttrFromTimeline extra_data', extra_data)

        this.props.remove_from_timeline(
            extra_data['item'], 
            extra_data['item_type'], 
            extra_data['item_id'], 
            extra_data['attr_id'], 
            extra_data['attr_parent'], 
            extra_data['current_project'],
            extra_data['time'],
            extra_data['timeline'],
        )
        this.props.close_all_foldout_panels();

    }


    keyframe_buttons(){

        let buttons = [];
        if (this.props.extra_data.is_keyed){
            buttons.push(
                <p className='AttrEdSubMenuButtons' onClick={this.handleRemoveAttrFromTimeline}>remove attribute from timeline</p>,
            )
            if (this.props.extra_data.on_key_time){
                buttons.push(
                    <p className='AttrEdSubMenuButtons' onClick={this.handleDeleteKey}>delete key</p>,
                )
            }
            else{
                buttons.push(
                    <p className='AttrEdSubMenuButtons' onClick={this.handleAddKey}>add key</p>,
                )
            }
        }
        else{
            buttons.push(
                <p className='AttrEdSubMenuButtons' onClick={this.handleAddAttrToTimeline}>add attribute to timeline</p>
            )
        }
        return buttons;

    }

    render(){

        return (

            <div
                className='AttrEdStoryItemOptions'
            >
                {this.keyframe_buttons()}
                {/* {this.props.extra_data.is_keyed === false && 
                 <p className='AttrEdSubMenuButtons' onClick={this.handleAddAttrToTimeline}>add attribute to timeline</p>
                }
                {this.props.extra_data.is_keyed === true && 
                    [
                        <p className='AttrEdSubMenuButtons' onClick={this.handleRemoveAttrFromTimeline}>remove attribute from timeline</p>,
                        <p className='AttrEdSubMenuButtons' onClick={this.handleRemoveAttrFromTimeline}>add key</p>,
                        <p className='AttrEdSubMenuButtons' onClick={this.handleRemoveAttrFromTimeline}>delete key</p>,
                    ]
                } */}
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
      ui_data:state.ui_data,
    //   item:state[props.item_type + '_data'][props.item_id],
      character_data:state.character_data,
      prop_data:state.prop_data,
      location_data:state.location_data,
      event_data:state.event_data,
      card_data:state.card_data,
    //   context_data:state.context_data,
    //   thread_data:state.thread_data
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        close_all_foldout_panels: () => dispatch(actionCreator_ui.close_all_foldout_panels()),
        select_item: (event, data) => dispatch(actionCreator_ui.select_item_c(event, data)),
        set_mode: (mode, ui_data) => dispatch(actionCreator_ui.mode_panel_set_mode(mode, ui_data)),
        un_link_items: (item_data, item_type, item_id, to_unlink_type, to_unlink_ids, project_id) => dispatch(actionCreator_story_items.un_link_items(item_data, item_type, item_id, to_unlink_type, to_unlink_ids, project_id)),
        remove_from_timeline: (item, item_type, 
            item_id, attr_id, 
            attr_parent, 
            project_id, 
            time, 
            timeline) => dispatch(actionCreator_attributes.remove_from_timeline(
                item, 
                item_type, 
                item_id, 
                attr_id, 
                attr_parent, 
                project_id, 
                time, 
                timeline)),

        add_to_timeline: (item, item_type, 
            item_id, attr_id, 
            attr_parent, 
            project_id, 
            time, 
            timeline) => dispatch(actionCreator_attributes.add_to_timeline(
                item, 
                item_type, 
                item_id, 
                attr_id, 
                attr_parent, 
                project_id, 
                time, 
                timeline)),

        delete_key: (item, item_type, 
            item_id, attr_id, 
            attr_parent, 
            project_id, 
            time, 
            timeline) => dispatch(actionCreator_attributes.delete_key(
                item, 
                item_type, 
                item_id, 
                attr_id, 
                attr_parent, 
                project_id, 
                time, 
                timeline)),

        add_key: (item, item_type, 
            item_id, attr_id, 
            attr_parent, 
            project_id, 
            time, 
            timeline) => dispatch(actionCreator_attributes.add_key(
                item, 
                item_type, 
                item_id, 
                attr_id, 
                attr_parent, 
                project_id, 
                time, 
                timeline)),
    }
    
};

export default connect(mapStateToProps, mapDispatchToProps)(AttrEdKeyframeOptions);
