/*
NOTE: I've left this is a funny state - need to look at this.item_uid and item_type etc..
It might be that once i get get corrrect data set on thumb i wont havr to worry about setting image path
*/
import React, { Component } from 'react';
import './CreateNewCharacter.css';
import './PickImage.css';
import { connect} from 'react-redux';
import InputField from '../ui_elements/inputs/InputField';
import Button from '../ui_elements/inputs/Button';
import FilePicker from '../ui_elements/inputs/FilePicker';
import ProgressBar from '../ui_elements/objects/general/ProgressBar';

import * as actionCreator_ui from '../../store/actions/actions_ui';
import * as actionCreator_inputs from '../../store/actions/actions_inputs';
import new_uid from '../../utils/new_uid';

// var IMAGES_PATH = '../../images/';

class PickImage extends Component {

    constructor(props) {
        super(props);
        this.handleClickCreate = this.handleClickCreate.bind(this);
        // this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);

        this.progress_cb = this.progress_cb.bind(this);
        this.complete_cb = this.complete_cb.bind(this);
        this.filechange_cb = this.filechange_cb.bind(this);
        // this.okClicked = this.props.okClicked || false;
        this.okClicked = false;
        // this.ok_clicked_data = this.props.ok_clicked_data || null;
        // this.extra_data = this.props.extra_data || null;

        // this.uid = this.props.item_type + '_' + new_uid();

        this.item_type = this.props.modal_input_data['item_type']
        this.item_uid = this.props.modal_input_data['item_id']
        // this.uid = item_uid




    }
    state = {
        pic_to_upload:null,
        pictureUrl:null,
        progress:100,
        doUpload:false,
        // uploadTriggered:false,
      }
    
    // handleClickCreate(event) {
    //     console.log('modal_data', this.props.modal_data) 
    //     let project_id = 'uid1';
    //     this.props.new_story_item_modal_create(event, this.props.project_object, project_id, this.props.modal_data, this.props.item_type);
    // }

    // getAlert() {
    //     alert('getAlert from Child');
    //   }

    handleFileChange(file, pic_url) {
    
        this.setState({pic_to_upload:file, pictureUrl:pic_url, progress:100})
      }

    // handleFileUpload() {
    //     this.setState({doUpload:true,})
    //   }
    
    progress_cb(progress) {
        console.log('CB prog ', progress)
        this.props.upload_progress(progress)
        this.setState({progress:progress})

    }
    complete_cb(url) {
        console.log('CB complete url', url)
        console.log('CB complete this.props.item_type', this.item_type )
        console.log('CB complete this.props.uid', this.item_uid )

        let dataTarget = this.item_type + '_data.'+this.item_uid+'.image'

        // this.props.set_val_at_target(url, dataTarget, 'text')
        // this.props.upload_complete(url)
        this.props.close_modal()

        // put thing in here to close ui (or close and let upload happen while app is active)
        // this.props.extra_data.cancel_function(event, "ui_data.openModalWindow."+this.props.extra_data.modal_name, null)
    }
    
    filechange_cb(file, url) {
        console.log('CB filechange_cb file', file )
        console.log('CB filechange_cb url', url )
        console.log('CB filechange_cb this.props.item_type', this.item_type )
        console.log('CB filechange_cb this.props.uid', this.props.uid )
        console.log('CB filechange_cb this.props.modal_input_data', this.props.modal_input_data )
        // let item_type = this.props.modal_input_data['item_type']
        // let item_uid = this.props.modal_input_data['item_id']
        // let item_data = this.props[this.props.modal_input_data[1]['item_type'] + '_data'][this.props.modal_input_data[1]['item_id']]
        // this.props.modal_link_item(item_data, this.props.modal_input_data[1]['item_type'], this.props.modal_input_data[1]['item_id'], this.props.picked_items, this.props.modal_input_data[0], )
        

        let dataTarget = this.item_type + '_data.'+ this.item_uid+'.image'
        console.log('CB filechange_cb dataTarget', dataTarget )

        // this.props.set_val_at_target(url, dataTarget, 'text')

        this.props.file_change()
    }
    

    handleClickCreate(event){
        // console.log('CREATRE NEW CHAR handleClickCreate', this.props.extra_data);
        // console.log('CREATRE NEW CHAR state', this.state);
        // I should have everything i need to 
        // alert('CREATRE NEW CHAR', this.props.extra_data);

        if (!this.state.uploadTriggered){
            this.setState({doUpload:true,})

            // console.log('CREATRE NEW CHAR through tests doUpload');
            // settting this to ensure the upload only happens once
            this.setState({uploadTriggered:true})
        }
        
        let project_id = this.props.extra_data['project_id']
        // let project_object = this.props.extra_data['project_object']
        let modal_data = this.props.extra_data['modal_data']
        let item_type = this.props.extra_data['item_type']

        // this.props.new_story_item_modal_create(this.item_uid, project_object, project_id, modal_data, item_type);

        console.log('PickImage handleClickCreate this.props.extra_data', this.props.extra_data)
        console.log('PickImage handleClickCreate modal_data', modal_data)

        if (this.state.pictureUrl === null){
            // console.log('DIDNT FIND PIC', this.state)
            // this.props.close_modal();
        }
        // this.props.extra_data.cancel_function(event, "ui_data.openModalWindow."+this.props.extra_data.modal_name, null)


    }

    render() {
        let upload_hide_style = {};
        let upload_show_style = {display:'none',}
        if (this.state.doUpload){
            upload_hide_style = {display:'none'};
            upload_show_style = {}
        }

        let storage_path;
        if (this.item_type === 'user'){
            storage_path = this.item_type + '_icon/'+this.item_uid
        }
        else{
            storage_path = this.item_type + '_data/'+this.item_uid
        }
        return (

            <div className = 'CreateNewCharacter'>

                <div className='PickImageFilePicker' style={upload_hide_style}>
                    <FilePicker 
                        doUpload={this.state.doUpload} 
                        //storage_path='test/aaa'
                        storage_path={storage_path}
                        file_change_callback={this.filechange_cb}
                        progress_callback={this.progress_cb}
                        complete_callback={this.complete_cb}
                        nothing_picked_callback={this.props.close_modal}
                        //dataTarget='my_items.uid1.image'
                        dataTarget={this.item_type + '_data.'+this.item_uid+'.image'}
                        placeholder_image_data = {this.props.placeholder_image_data}
                    />
                </div>
                    
                <Button 
                    onClickFunction={this.handleClickCreate} 
                    //onClickFunction={this.test_on_click} 
                    label='OK'
                    style={Object.assign({}, upload_hide_style, {position:'absolute', height:"45px", width:"140px", right:"10px",bottom:"4px"})}
                />

                <div className='UploadArea' style={upload_show_style}>
                    Uploading ...
                    <ProgressBar progress={this.state.progress}/>
                </div>

            </div>
        )
    };
};

const mapStateToProps = (state, props) => {
    return {
        // openModalWindow:state.ui_data.openModalWindow['create_'+props.item_type],
        // is_uploading:state.ui_data.layout.is_uploading,
        // project_data:state.project_data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // onModelCreateButton: (item_type) => dispatch({type:'ON_MODAL_CREATE_BUTTON', item_type:item_type}),
        // new_story_item_modal_create: (uid, project_object, project_id, modal_data, item_type) => dispatch(actionCreator_ui.new_story_item_modal_create(uid, project_object, project_id, modal_data, item_type)),
        
        // set_val_at_target: (val_to_set, dataTarget, type_to_set) => dispatch(actionCreator_inputs.set_val_at_target(val_to_set, dataTarget, type_to_set)),

        // upload_complete: (dataTarget, url) => dispatch({type:'SET_PIC_URL', dataTarget:dataTarget, value:url}),
        upload_progress: (progress) => dispatch({type:'SET_PROGRESS', value:progress}),
        file_change: () => dispatch({type:'SET_FILE_CHANGE', value:'hello'}),
        close_modal: () => dispatch({type:'CLOSE_MODAL_WINDOW', modal_name:'create_character'}),

    
    }
};

export default connect(mapStateToProps, mapDispatchToProps )(PickImage);
// export default CreateNewCharacter;
