import React, { Component } from 'react';
import './TimelineCard.css';
// import jQuery;
import { connect} from 'react-redux';
import InputField from '../../inputs/InputField';

import * as actionCreator_cards from '../../../../store/actions/actions_cards';

import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
import * as actionCreator_timeline from '../../../../store/actions/actions_timelines';
import * as actionCreator_time_cards from '../../../../store/actions/actions_time_cards';

class TimelineCard extends Component {
    constructor(props) {
        super(props);
        this.handleCardMouseDown = this.handleCardMouseDown.bind(this);
        this.handleCardStartDown = this.handleCardStartDown.bind(this);
        this.handleCardEndDown = this.handleCardEndDown.bind(this);

        this.flow_behaviour = true;

      }
    
    handleCardMouseDown(event) {
        // console.log('handleCardMouseDown', this.props.ui_data, this.props.card_data[this.props.item_id] )
        // the order of these is important!
        this.props.onMouseDown(event, 'timeCard', this.props.item_id,);
        this.props.onMouseDown_TL(event, this.props.ui_data, this.props.timeline_data,);
        this.props.onMouseDown_timeline_card(event, this.props.item_id, this.props.card_data[this.props.item_id], this.props.ui_data);
    };
    handleCardStartDown(event) {
        // the order of these is important!
        // console.log('handleCardStartDown', this.props.ui_data, this.props.card_data[this.props.item_id] )

        this.props.onMouseDown(event, 'timelineCardStart', this.props.item_id,);
        this.props.onMouseDown_TL(event, this.props.ui_data, this.props.timeline_data,);
        this.props.onMouseDown_timeline_card(event, this.props.item_id, this.props.card_data[this.props.item_id], this.props.ui_data);
    };
    handleCardEndDown(event) {
        // the order of these is important!
        this.props.onMouseDown(event, 'timelineCardEnd', this.props.item_id,);
        this.props.onMouseDown_TL(event, this.props.ui_data, this.props.timeline_data,);
        this.props.onMouseDown_timeline_card(event, this.props.item_id, this.props.card_data[this.props.item_id], this.props.ui_data);
    };




    render() {
        if (this.props.flow_behaviour !== undefined) {
            this.flow_behaviour = this.props.flow_behaviour;
        }
        // //AAA console.log('this.flow_behaviour', this.flow_behaviour)
        let item_id= this.props.item_id;
        let current_timeline = this.props.ui_data.current['current_timeline'];
        let cardDraggingStyle={};
        let cardStyle = {
            // width:this.props.card_data[this.props.item_id]['size'][0] + '.px', 
            // height:this.props.card_data[this.props.item_id]['size'][1] + '.px', 
        }
        let hover_off_style = {}
        if (!this.flow_behaviour) {
            cardStyle = {
                position:'relative',
                // left:'0px',
                // top:'0px',
                transform: 'rotate(0deg)  scale(.4, .4) ',
                transformOrigin:' 0% 0%',
            }
        }

        let cardSelectedStyle = {};
        // centre anchor to half cad witdth minus half anchor width (18)
        let anchorStyle = {
            left:(0.5 * this.props.card_data[this.props.item_id]['size'][0])-24 + '.px', 
        };



        let titleClassToUse = 'CardTitle';
        let textClassToUse = 'cardText';

        let spellcheck='false';


        if (this.props.ui_data.control.selected){
            if (this.props.item_id in this.props.ui_data.control.selected){

                cardSelectedStyle = {
                    boxShadow: '0px 0px 0px 3px rgba(255, 255, 255, 1)',
                }
                if (this.flow_behaviour) {
                    // cardSelectedStyle['transform'] = 'rotate(0deg)  scale(1.02, 1.02)';
                }

                titleClassToUse = 'CardTitleSel';
                textClassToUse = 'cardTextSel';
                spellcheck='true';
                

            }
        };

        let titleStyle = {}
        let handleStyle = {}

        // this is to adjust behaviour depending on usage
        if (this.flow_behaviour) {
            // //AAA console.log('XXXXXXXthis.flow_behaviour', this.flow_behaviour)

            // if (this.props.begin_drag){
                if (this.props.dragging[0] === 'timeCard'){
                    if (this.props.dragging[1] === this.props.item_id){
                        cardDraggingStyle = {
                            cursor: 'grabbing',
                            transform: 'translate(0px, -1px)',
                            // transform: 'rotate(-2deg)  scale(1.08, 1.08)',
                            backgroundColor: 'rgb(245, 235, 190)',
                            boxShadow: '-2px 6px 8px 0px rgba(0, 0, 0, .2)',
                            pointerEvents: 'none',

                            
                        }
                        document.activeElement.blur();
                        titleClassToUse = 'CardTitle';
                        textClassToUse = 'cardText';
                
                    }
                }
            // }
            // set position
            // cardStyle['left'] = this.props.card_data[this.props.item_id]['pos'][0] + '.px';
            // cardStyle['top'] = this.props.card_data[this.props.item_id]['pos'][1] + '.px';
            let start_end_time = this.props.card_data[this.props.item_id]['time'][current_timeline]['start_end_time'];
            let vp_scale = this.props.current_vp_scale ;

            // cardStyle['left'] = Math.round(100 * start_end_time[0]) + '.px';
            // cardStyle['top'] =   Math.round((-75 * (1 / vp_scale)  * this.props.card_data[this.props.item_id]['time'][current_timeline]['row']))+'px' ;
            // cardStyle['width'] =   Math.round(100 * (start_end_time[1] - start_end_time[0])) ;
            // cardStyle['height'] =  Math.round(70 * (1 / vp_scale)) +'px' ;
            // titleStyle['fontSize'] =  Math.round(30 * (1 / vp_scale)) +'px' ;

            // this is an alt setup where I'm setting start and ends based on vp scale rather than compensaing for actual scale with heigh etc
            cardStyle['left'] = Math.round(100 * start_end_time[0] * vp_scale);
            cardStyle['top'] =   Math.round(-64  * this.props.card_data[this.props.item_id]['time'][current_timeline]['row']) ;

            let card_width = Math.round(100 * vp_scale * (start_end_time[1] - start_end_time[0]))
            cardStyle['width'] =   card_width ;
            // cardStyle['height'] =  Math.round(70) +'px' ;
            // titleStyle['fontSize'] =  Math.round(30) +'px' ;

            let handle_width = 18;

            if (card_width < 100){
                handle_width = Math.round(18 * (card_width/100)) ;
                // handleStyle['width'] = '50px' ;
            }
            if (handle_width < 4){
                handle_width = 4;
            }
            handleStyle = {width:handle_width+'px'}

            
        }




        // let documents = this.props.card_data[item_id].links.document;
        // let doc_style = {
        //     display:'none',
        // } 
        // if (documents.length) {
        //     doc_style = {
        //         display:'block',
        //     } 
        // }

        // let counter_style = {}
        // if (this.props.child_count === 0){
        //     counter_style['display'] = 'none';
        // }

        // This disables pointerevents so i dont trigger hover when dragging anything
        if (this.props.dragging[0] !== null ) {
            hover_off_style['pointerEvents'] = 'none';
        }
        


        return (
                <div
                item_id={this.props.item_id}
                item_type='timeCard'
                style={Object.assign({}, cardStyle, cardSelectedStyle, cardDraggingStyle)}
                onMouseDown={this.handleCardMouseDown}
                className= "TimelineCard"
                >
                    <InputField 
                        className={titleClassToUse}
                        inputPlaceholder='Title...'
                        dataSource={"card_data." + item_id + ".title"}
                        dataTarget={"card_data." + item_id + ".title"}
                        inputType='textFieldCard' 
                        style={titleStyle} 
                        id='nameField' 
                        label='' 
                        spellCheck={spellcheck}
                        //style={hover_off_style}

                    />

                    <div 
                        className='TimelineCardStartHandle'
                        style={handleStyle}
                        item_type='timelineCardStart'
                        item_id={this.props.item_id}
                        onMouseDown={this.handleCardStartDown}
                    />
                    <div 
                        className='TimelineCardEndHandle' 
                        style={handleStyle}

                        item_type='timelineCardEnd'
                        item_id={this.props.item_id}
                        onMouseDown={this.handleCardEndDown}
                    />



                </div>
        );
    };
}

const mapStateToProps = (state, props) => {
    // let current_vp = state.ui_data.current.current_viewport;
    let current_tl = state.ui_data.current.current_timeline;
    return {
        ui_data:state.ui_data,
        dragging:state.ui_data.control.dragging,
        hovering:state.ui_data.control.hovering,
        begin_drag:state.ui_data.control.begin_drag,
        card_data:state.card_data,
        timeline_data:state.timeline_data[current_tl],
        // current_vp:state.ui_data.current.current_viewport,
        current_vp_scale:state.timeline_data[current_tl]['scale'],

        // start_time:state.card_data[props.item_id]['time'][current_tl]['start_end_time'][0],
        // end_time:state.card_data[props.item_id]['time'][current_tl]['start_end_time'][1],
        // row:state.card_data[props.item_id]['time'][current_tl]['row'],

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        onMouseDown_TL: (event, ui_data, timeline_data) => dispatch(actionCreator_timeline.mouse_down_timeline(event, ui_data, timeline_data)),
        onMouseDown_timeline_card: (event, card_id, card_item_data, ui_data) => dispatch(actionCreator_time_cards.mouse_down_time_card(event, card_id, card_item_data, ui_data)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps )(TimelineCard);
