
import fullScreenToggle from '../../utils/fullScreenToggle'
import new_uid from '../../utils/new_uid'
import * as Constants from '../../constants/constants';






const reducer_story_items = (state, newState, action) => {
   

    if(action.type === 'CREATE_STORY_ITEM'){
        // console.log('RD CREATE_STORY_ITEM', action)
        newState[action.item_type + '_data'] = {...state[action.item_type + '_data']};

        newState[action.item_type + '_data'][action.new_uid] = action.item_data;

    }

    if(action.type === 'DELETE_ITEM'){
        // console.log('about to del ', action.item_id)

        newState[action.item_type + '_data'] = {...state[action.item_type + '_data']};

        // if (action.item_type === 'character' || action.item_type === 'prop' || action.item_type === 'location' || action.item_type === 'card'  ){
        if (Constants.DELETABLE_TYPES.includes(action.item_type)){
            //AAA console.log('about to del ', action.item_id)
            // TODO replace current_sel with new format
            delete newState[action.item_type + '_data'][action.item_id]; 
        }

    }

    if(action.type === 'LINK_ITEMS'){
        // console.log('RD LINK_ITEMS', action)

        if (state[action.item_type + '_data'][action.item_id].hasOwnProperty('links')){
            if (state[action.item_type + '_data'][action.item_id]['links'].hasOwnProperty(action.to_link_type)){
                newState[action.item_type + '_data'][action.item_id]['links'][action.to_link_type] = {...state[action.item_type + '_data'][action.item_id]['links'][action.to_link_type]};
            }
            newState[action.item_type + '_data'][action.item_id]['links'] = {...state[action.item_type + '_data'][action.item_id]['links']};
        }
        else{
            // if links doesn't exist i'll make it here so i can add the to_link_type key to it later
            newState[action.item_type + '_data'][action.item_id]['links'] = {};
        }

        newState[action.item_type + '_data'][action.item_id] = {...state[action.item_type + '_data'][action.item_id]};
        newState[action.item_type + '_data'] = {...state[action.item_type + '_data']};

        
        newState[action.item_type + '_data'][action.item_id]['links'][action.to_link_type] = action.to_link_ids;
        // console.log('RD action.to_link_ids', action.to_link_ids)
        // newState['card_data'][action.item_id]['links']['character'] = ['uid1'];
    }


    if(action.type === 'SET_CHARACTER_DATA'){
        //console.log('RD SET_CHARACTER_DATA', action)
        newState.character_data = {...state.character_data};
        newState.character_data[action.uid] = action.data;
    }
    if(action.type === 'SET_PROP_DATA'){
        //console.log('RD SET_PROP_DATA', action)
        newState.prop_data = {...state.prop_data};
        newState.prop_data[action.uid] = action.data;
    }
    if(action.type === 'SET_LOCATION_DATA'){
        //console.log('RD SET_PROP_DATA', action)
        newState.location_data = {...state.location_data};
        newState.location_data[action.uid] = action.data;
    }
    if(action.type === 'SET_EVENT_DATA'){
        //console.log('RD SET_PROP_DATA', action)
        newState.event_data = {...state.event_data};
        newState.event_data[action.uid] = action.data;
    }


    // set image
    // TODO this could be genericised to be any data - eg, SET_ITEM_DATA_BY_KEY
    // ACTUALLY THIS MIGHT ALREADY BE COVERED BY "SET_INPUT_VAL"
    // if(action.type === 'SET_ITEM_IMAGE'){
    //     //console.log('RD SET_PROP_DATA', action)
    //     newState[action.item_type + '_data'] = {...state[action.item_type + '_data']};
    //     newState[action.item_type + '_data'][action.uid] = {...state[action.item_type + '_data'][action.uid]};
    //     newState[action.item_type + '_data'][action.uid]['image'] = action.data;
    // }

    return newState;

};
export default reducer_story_items;