import React, {Component} from 'react';
import './Attribute.css';
import { connect} from 'react-redux';

// import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'
import new_uid from '../../../utils/new_uid'
import * as attribute_utils from '../../../utils/attribute_utils'



// import * as actionCreator_inputs from '../../../store/actions/actions_inputs';
// import * as actionCreator_mouse from '../../../store/actions/actions_mouse';
import * as actionCreator_attributes from '../../../store/actions/actions_attributes';
import * as actionCreator_cache from '../../../store/actions/actions_cache';
import * as actionCreator_ui from '../../../store/actions/actions_ui';
import InputField from './InputField';
// import InputField from './InputField';
import SelectMenu from './SelectMenu';
import Slider from './Slider';




class Attribute extends Component {
    constructor(props) {
        super(props);
        // this.handleClick = this.handleClick.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        this.handleClickTimeIcon = this.handleClickTimeIcon.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputChangeB = this.onInputChangeB.bind(this);
        this.onInputChangeC = this.onInputChangeC.bind(this);
        // this.handleMouseOverAttr = this.handleMouseOverAttr.bind(this);

        // this.state = {
        //     value:this.props.inputValue,
        //     // value:this.getAttrFromPath(this.props.item, this.props.attr_path),
        //     // value:this.props.item[this.props.attr_path],
        // }
    }

    // handleMouseOverAttr(event){
    //     let attr_id = event.target.getAttribute('attr_id')
    //     let attr_parent = event.target.getAttribute('attr_parent')
    //     console.log('handleMouseOverAttr attr_id, attr_parent', attr_id, attr_parent)

    //     // attr_id={this.props.attr_id}
    //     // attr_parent={this.props.attr_parent}
    //     // item_id={this.props.item_id}
    //     // item_type={this.props.item_type}

    // }
    // handleClick(event) {
    //     event.stopPropagation();

    // }
    // handleChange(event) {
    //     event.stopPropagation();
    //     console.log('SelectMenu handleChange')
    //     let value = event.target.value
    //     this.setState({value: value});

        
    //     console.log('SelectMenu_b function_args', this.props.function_args)

    //     // run passed in function with value as arg
    //     if (this.props.onChangeFunction){
    //         this.props.onChangeFunction(value, this.props.function_args)
    //     }
    //     // this.props.onClick(this.props.dataTarget, event.target.value, this.props.current_project_id, event)
    // }
    // getAttrFromPath(item, attr_path){

    //     return item[attr_path];
    // }
    handleClickTimeIcon(event) {
        let panel_name = 'attr_time_icon'
        // let element_id = 'add_item_flow'
        // let element_id = this.props.item_type + '-' + this.props.item_id
        // let element_id = this.props.item_type + '-' + this.props.item_id + '-' + this.props.attr_path;
        let element_id = this.props.item_type + '-' + this.props.item_id + '-' + this.props.attr_parent + '-' + this.props.attr_id;


        let input_value_data = this.getInputValue();
        let extra_data = {
            item:this.props.item,
            item_type:this.props.item_type,
            item_id:this.props.item_id, 
            attr_id:this.props.attr_id, 
            attr_parent:this.props.attr_parent, 
            current_project:this.props.current_project,
            time:this.props.time,
            timeline:this.props.timeline,
            is_keyed:input_value_data.is_keyed,
            on_key_time:input_value_data.on_key_time,
        }
        this.props.open_foldout_panel(panel_name, element_id, extra_data);
    }


    getInputValue(){
        /*
            I will use this function to read the actual value which might be a static
            value of keyframe data as dict. Then I'll convert that into a value appropriate
            for the input type. I shoudl also pass extra data such as if on a key time.
            To do this I'll pass the value to a function to return back the good stuff.

        */
        // let cur = value;
        // cur.replace(/you/g, "<YOU>");
        // return cur.replace(/ you /g, " <YOU> ");
        // return cur;

        // console.log('Z - this.props.item', this.props.item)
        // console.log('this.props.attr_parent', this.props.attr_parent)
        // console.log('this.props.attr_id', this.props.attr_id)
        // console.log('this.props.cur_time', this.props.time)
        // console.log('this.props.current_timeline', this.props.timeline)
        if (!this.props.item){
            console.log('THIS ATTR IS NOT FUNCIONAL', this.props.attr_id)
            return null;
        }

        // todo this is temp thing while i phase out attr_parent
        let value_from_util;
        // if (this.props.attr_path)
        // {
        //     console.log('getInputValue this.props.item', this.props.item)
        //     console.log('getInputValue this.props.attr_path', this.props.attr_path)
        //     console.log('getInputValue this.props.time', this.props.time)
        //     console.log('getInputValue this.props.timeline', this.props.timeline)
    
        //     value_from_util = attribute_utils.get_current_value_from_attr_path( 
        //         this.props.item, 
        //         this.props.attr_path, 
        //         this.props.time, 
        //         this.props.timeline
        //     )
        //     console.log('getInputValue value_from_util', value_from_util)

        // }
        // else
        // {   
            // console.log('attr_parent!!!!!! thing')
            value_from_util= attribute_utils.get_current_value_from_attr( 
                this.props.item, 
                this.props.attr_parent, 
                this.props.attr_id, 
                this.props.time, 
                this.props.timeline
            )
        // }

        // console.log('getInputValue full', value_from_util);
        // console.log('getInputValue value', value_from_util.value);
        return value_from_util;

    }
    onInputChange(value, args_dict, event) {
        console.log('XXXX onInputChange nested function', value)
        console.log('  - value', value)
        // console.log('  - test', test)
        console.log('  - event', event)
        // event.stopPropagation();
        // let val_to_set = event.target.value
        // let type_to_set = event.target.type
        // console.log('  - event type', type_to_set)
        // console.log('  - event val', val_to_set)

        // this.setState({value:value})
        
        args_dict['my_func'](
            args_dict['item'], 
            args_dict['item_type'], 
            args_dict['item_id'], 
            args_dict['attr_id'], 
            value, 
            args_dict['attr_parent'], 
            args_dict['current_project'],
            args_dict['time'],
            args_dict['timeline'],
        
        )
    }
    onInputChangeB( e) {
        // console.log('XXXX onInputChangeB  args ', e)
        console.log('XXXX onInputChangeB  e ', e)
        console.log('XXXX onInputChangeB  this.props.item ', this.props.item)
        console.log('XXXX onInputChangeB  this.props.item_type ', this.props.item_type)
        console.log('XXXX onInputChangeB  this.props.item_id ', this.props.item_id)
        console.log('XXXX onInputChangeB  this.props.attr_id ', this.props.attr_id)
        // console.log('XXXX onInputChangeB  this.props ', this.props)
        console.log('XXXX onInputChangeB  this.current_project ', this.current_project)
        console.log('XXXX onInputChangeB  this.time ', this.time)
        console.log('XXXX onInputChangeB  this.timeline ', this.timeline)
        console.log('XXXX \n\n ')

        console.log('XXXX  - e', e)

        let value = e.target.value;
        console.log('XXXX  - value', value)

        this.props.set_attr(
            this.props.item,
            this.props.item_type,
            this.props.item_id, 
            this.props.attr_id, 
            value,
            this.props.attr_parent, 
            this.props.current_project,
            this.props.time,
            this.props.timeline,
        )

        // This seems really inefficient to me. Also it seemed to work before with it. NOt sure why?
        let items_and_ids = [[this.props.item, this.props.item_id, this.props.item_type,]]
        this.props.update_keyframes_cache(items_and_ids, this.props.timeline) 
    }

    onInputChangeC( e) {
        // console.log('XXXX onInputChangeB  args ', e)
        console.log('XXXX onInputChangeC  e ', e)
        console.log('XXXX onInputChangeC  this.props.item ', this.props.item)
        console.log('XXXX onInputChangeC  this.props.item_type ', this.props.item_type)
        console.log('XXXX onInputChangeC  this.props.item_id ', this.props.item_id)
        console.log('XXXX onInputChangeC  this.props.attr_id ', this.props.attr_id)
        console.log('XXXX onInputChangeC  this.props.attr_path ', this.props.attr_path)
        console.log('XXXX onInputChangeC  this.current_project ', this.props.current_project)
        console.log('XXXX onInputChangeC  this.time ', this.props.time)
        console.log('XXXX onInputChangeC  this.timeline ', this.props.timeline)
        console.log('XXXX \n\n ')

        console.log('XXXX  - e', e)

        let value = e.target.value;
        console.log('XXXX  - value', value)

        this.props.set_attr_by_path(
            value,
            this.props.item,
            this.props.item_type,
            this.props.item_id, 
            this.props.attr_path, 
            this.props.current_project,
            this.props.time,
            this.props.timeline,
        )
        let items_and_ids = [[this.props.item, this.props.item_id, this.props.item_type,]]
        this.props.update_keyframes_cache(items_and_ids, this.props.timeline) 
    }


    render() {
        let rand_id  = 'selectMenu_'+new_uid;


        let element_id = this.props.item_type + '-' + this.props.item_id + '-' + this.props.attr_parent + '-' + this.props.attr_id;
        // let element_id = 'sadsaasdsdas'
        let inputComponent;

        let input_value_data = this.getInputValue();
        let key_button_key_class = '';
        let is_keyed = false;
        let on_key_time = false;
        let input_val = null;
        let onInputChangeFunction = this.props.onChangeFunction || null;
        let input_key = this.props.input_key || null;
        let is_keyable = this.props.is_keyable;
        if (is_keyable === undefined){
            is_keyable = true
        }
        let experimental = this.props.ui_data.settings.experimental_mode
        if (!experimental){
            is_keyable = false
        }


        let decimal_places = this.props.decimal_places;
        // if (decimal_places === undefined){
        //     decimal_places=2;
        // }
        let inputBackgroundClass = '';
        let select_menu_key_class = '';




        if (input_value_data){

            input_val = input_value_data.value;
            // onInputChangeFunction = this.onInputChangeC;
            onInputChangeFunction = this.onInputChangeB;

            if (input_value_data.is_keyed){
                key_button_key_class = 'KeyButtonHasKeys';
                is_keyed = true;
                inputBackgroundClass = 'AttrHasKeys';
    
            }
            if (input_value_data.on_key_time){
                key_button_key_class = 'KeyButtonOnKeyTime';
                on_key_time = true;
                inputBackgroundClass = 'AttrOnKeyTime';
            }

        }


        let func_args = {
            my_func:this.props.set_attr,
            item:this.props.item,
            item_type:this.props.item_type,
            item_id:this.props.item_id, 
            attr_id:this.props.attr_id, 
            attr_parent:this.props.attr_parent, 
            current_project:this.props.current_project,
            time:this.props.time,
            timeline:this.props.timeline,
        }



        let label = this.props.label || 'label'
        // if (this.props.inputType === 'number'){
        if (['text', 'number'].includes(this.props.inputType)){
            inputComponent = <InputField 
                inputValue = {input_val}
                inputType={this.props.inputType} 
                onChangeFunction={onInputChangeFunction}
                label={label}
                defaultValue={this.props.defaultValue}
                inputBackgroundClass={inputBackgroundClass}
                input_key={input_key}
                decimal_places={decimal_places}
                inputPlaceholder={this.props.inputPlaceholder}
                item_type={this.props.attr_parent}
                item_id={this.props.attr_id}
            />
        }
        if (this.props.inputType === 'list'){
            inputComponent = <SelectMenu 
                inputValue = {input_val}
                input_key={input_key}
                inputBackgroundClass={inputBackgroundClass}

                // inputValue = 'location_004536995067101768'
                // inputValue = {this.state.value}
                // inputType={this.props.inputType} 
                onChangeFunction={onInputChangeFunction}
                // onChangeArgs={func_args}
                label={label}
                item_list={this.props.item_list}
                is_keyed={is_keyed}
                on_key_time={on_key_time}
                item_type={this.props.attr_parent}
                item_id={this.props.attr_id}
            />
        }
        if (this.props.inputType === 'slider'){
            inputComponent = <Slider 
                inputValue = {input_val}
                inputType={this.props.inputType} 
                min={this.props.min} 
                max={this.props.max} 
                onChangeFunction={onInputChangeFunction}
                label={label}
                defaultValue={this.props.defaultValue}
                inputBackgroundClass={inputBackgroundClass}
                input_key={input_key}
                decimal_places={decimal_places}
                inputPlaceholder={this.props.inputPlaceholder}
                item_type={this.props.attr_parent}
                item_id={this.props.attr_id}
            />
        }

        return (
            <div className='Attribute_div'
                item_id={this.props.attr_id}
                item_type={this.props.attr_parent}
                // onMouseOver={this.handleMouseOverAttr}
            >
                {/* <p className='checkBoxLabel'>{this.props.label}</p> */}

                {inputComponent}
                {is_keyable && 
                    <i  
                        onClick={this.handleClickTimeIcon} 
                        id={element_id} 
                        className={"fas fa-clock keyframeButtonX " + key_button_key_class}
                        item_type={this.props.attr_parent}
                        item_id={this.props.attr_id}
                    />
                }


            </div>
        )
    }
        
}
// }


const mapStateToProps = (state, props) => {
    return {
        // inputValue:get_data_obj_from_string(state, props.dataSource),
        // current_project_id:state.ui_data.current.current_project,
        ui_data:state.ui_data,

    }
}


const mapDispatchToProps = (dispatch, props) => {

    // let do_set_db_data = true;
    // if (props.do_set_db_data === 'false'){
    //     do_set_db_data = false;
    // }

    // if (!props.dataTarget){
    //     return{
    //         onClick:  (entity, event) => console.log('no data target!')
    //     }
    // }
    return {
        // onClick:  (event) => dispatch({type:'ON_CLICK', event:event, valToSet:props.valToSet, dataTarget:props.dataTarget}),
        // onClick: (dataTarget, current_project_id, event) => dispatch(actionCreator_inputs.on_checkbox_click(event, dataTarget, props.valToSet, current_project_id, do_set_db_data)),
        // onClick: (dataTarget, valToSet, current_project_id, event) => dispatch(actionCreator_inputs.on_click(event, dataTarget, valToSet, current_project_id, do_set_db_data)),
        // set_attr: ( item_type, item_id, attr_id, attr_val, attr_parent, project_id) => dispatch(actionCreator_attributes.set_attr( item_type, item_id, attr_id, attr_val, attr_parent, project_id)),
        open_foldout_panel: (panel_name, element_id, extra_data) => dispatch(actionCreator_ui.open_foldout_panel(panel_name, element_id, extra_data)),
        set_attr: ( item, item_type, item_id, attr_id, attr_val, attr_parent, project_id, time, timeline) => dispatch(actionCreator_attributes.set_attr( item, item_type, item_id, attr_id, attr_val, attr_parent, project_id,  time, timeline)),
        set_attr_by_path: (attr_val, item, item_type, item_id, attr_path, project_id, time, timeline) => dispatch(actionCreator_attributes.set_attr_by_path(attr_val, item, item_type, item_id, attr_path, project_id, time, timeline)),
        update_keyframes_cache: (items_and_ids, timeline) => dispatch(actionCreator_cache.update_keyframes_cache(items_and_ids, timeline)),

    }




};

export default connect(mapStateToProps, mapDispatchToProps )(Attribute);
