import React, { Component } from 'react';
// import './CreateNewProp.css';
import { connect} from 'react-redux';
import InputField from '../ui_elements/inputs/InputField';
import Button from '../ui_elements/inputs/Button';
import FilePicker from '../ui_elements/inputs/FilePicker';
import ProgressBar from '../ui_elements/objects/general/ProgressBar';

import * as actionCreator_ui from '../../store/actions/actions_ui';
import new_uid from '../../utils/new_uid';


class CreateNewEvent extends Component {

    constructor(props) {
        super(props);
        this.handleClickCreate = this.handleClickCreate.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);

        this.progress_cb = this.progress_cb.bind(this);
        this.complete_cb = this.complete_cb.bind(this);
        this.filechange_cb = this.filechange_cb.bind(this);
        this.okClicked = false;
        this.uid = this.props.item_type + '_' + new_uid();
    }
    state = {
        pic_to_upload:null,
        pictureUrl:null,
        progress:100,
        doUpload:false,
      }
    

    handleFileChange(file, pic_url) {
        // console.log('IN APP NOW handleFileChange', file)
        // let pic_url = reader.result;
        // console.log('IN APP NOW handleFileChange url', pic_url)
    
        this.setState({pic_to_upload:file, pictureUrl:pic_url, progress:100})
      }

    // handleFileUpload() {
    //     this.setState({doUpload:true,})
    //   }
    
    progress_cb(progress) {
    // console.log('CB prog ', progress)
    this.props.upload_progress(progress)
    this.setState({progress:progress})

    }
    complete_cb(url) {
    // console.log('CB complete ', url)
    // let dataTarget = 'prop_data.'+this.uid+'.image'
    // this.props.upload_complete(url)
    this.props.close_modal()

    // put thing in here to close ui (or close and let upload happen while app is active)
    // this.props.extra_data.cancel_function(event, "ui_data.openModalWindow."+this.props.extra_data.modal_name, null)
    }
    
    filechange_cb(file, url) {
    // console.log('CB filechange_cb ', file, url )
    this.props.file_change()
    }
    

    handleClickCreate(event){
        // console.log('CREATRE NEW CHAR handleClickCreate', this.props.extra_data);
        // console.log('CREATRE NEW CHAR state', this.state);
        // I should have everything i need to 
        // alert('CREATRE NEW CHAR', this.props.extra_data);

        if (!this.state.uploadTriggered){
            this.setState({doUpload:true,})

            // console.log('CREATRE NEW CHAR through tests doUpload');
            // settting this to ensure the upload only happens once
            this.setState({uploadTriggered:true})
        }
        
        let project_id = this.props.extra_data['project_id']
        // let project_object = this.props.extra_data['project_object']
        let modal_data = this.props.extra_data['modal_data']
        let modal_input_data = this.props.extra_data['modal_input_data']
        let item_type = modal_input_data.item_type
        let group_id = modal_input_data.group_id

        this.props.new_story_item_modal_create(this.uid, project_id, modal_data, item_type, group_id);

        if (this.state.pictureUrl === null){
            // console.log('DIDNT FIND PIC', this.state)
        }


    }

    render() {
        let upload_hide_style = {};
        let upload_show_style = {display:'none'}
        if (this.state.doUpload){
            upload_hide_style = {display:'none'};
            upload_show_style = {}
        }
        return (
            <div className = 'CreateNewEvent'>
                <div className='TopRightArea' style={upload_hide_style}>
                    <FilePicker 
                        doUpload={this.state.doUpload} 
                        storage_path={'event_data/'+this.uid}
                        file_change_callback={this.filechange_cb}
                        progress_callback={this.progress_cb}
                        complete_callback={this.complete_cb}
                        nothing_picked_callback={this.props.close_modal}
                        //dataTarget='my_items.uid1.image'
                        dataTarget={'event_data.'+this.uid+'.image'}
                        placeholder_image_data = {['icon', 'fas fa-archway']}
                    />
                </div>
                <div className='TopLeftArea' style={upload_hide_style}>
                    <InputField 
                        dataSource={"ui_data.modal_data.create_"+this.props.item_type+".name"}
                        dataTarget={"ui_data.modal_data.create_"+this.props.item_type+".name"}
                        inputType='text' 
                        //style={{width:"330px", top:"70px", position:'absolute'}} 
                        id='nameField' 
                        label='Name' 
                    />

                </div>
                <div className='BottomArea' style={upload_hide_style}>
                    <InputField 
                        dataSource={"ui_data.modal_data.create_"+this.props.item_type+".description"}
                        dataTarget={"ui_data.modal_data.create_"+this.props.item_type+".description"}
                        inputType='textArea' 
                        style={{height:"200px"}} 
                        id='AAA' 
                        label='Description' 
                    />
                </div>
                <Button 
                        onClickFunction={this.handleClickCreate} 
                        label='Create Event'
                        style={Object.assign({}, upload_hide_style, {position:'absolute', height:"45px", width:"140px", right:"10px",bottom:"4px"})}
                    />

                <div className='UploadArea' style={upload_show_style}>
                {/* <div className='UploadArea' > */}
                    Uploading ...
                    <ProgressBar progress={this.state.progress}/>
                </div>

            </div>
        )
    };
};

const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        new_story_item_modal_create: (uid, project_id, modal_data, item_type, group_id) => dispatch(actionCreator_ui.new_story_item_modal_create(uid, project_id, modal_data, item_type, group_id)),
        upload_progress: (progress) => dispatch({type:'SET_PROGRESS', value:progress}),
        file_change: () => dispatch({type:'SET_FILE_CHANGE', value:'hello'}),
        close_modal: () => dispatch({type:'CLOSE_MODAL_WINDOW', modal_name:'create_event'}),
    
    }
};

export default connect(mapStateToProps, mapDispatchToProps )(CreateNewEvent);
