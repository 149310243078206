
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator_auth from '../store/actions/actions_auth';
import { Link, useNavigate } from 'react-router-dom';

import './LoginPage.css';

import InputField from '../components/ui_elements/inputs/InputField';
import Button from '../components/ui_elements/inputs/Button';


import bg_image from '../images/landing_page_bg_01.jpg';

// ...later


export default function LoginPageF() {

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [passwordConfirm, setPasswordConfirm] = useState()
    const [loading, setLoading] = useState()
    const { currentUser} = useAuth()
    // const history = useNavigate()
    const navigate = useNavigate()

    const dispatch =useDispatch()

    let auth_ui_message = useSelector( state => state.ui_data.auth_ui_message)

    function handleSubmit(e) {
        dispatch(actionCreator_auth.reset_password(email))
    }

    // this will only run once when page loads
    useEffect(() => {
        dispatch(actionCreator_auth.set_auth_message(null))
        }, []);

    let auth_message_element = null;
    if (auth_ui_message) {
        if (auth_ui_message.startsWith('Please check')){
            // auth_message_element = <p style={{color:'rgb(36, 218, 75)', fontWeight:'bold'}}> {auth_ui_message} </p>;
            auth_message_element = <p className='LoginMesssageSuccess'> {auth_ui_message} </p>;
        }
        else{
            // auth_message_element = <p style={{color:'red'}}> {auth_ui_message} </p>;
            auth_message_element = <p className='LoginMesssageError'> {auth_ui_message} </p>;
        }
    }

    if (currentUser){
        console.log('got currentUser. pushing history now')
        navigate("/app")
    }

    return (
        <div className="LoginPageDiv">
                <img className="LoginPageImg" src={bg_image} alt="logo" />

                <div className='LoginTitle'>
                    <Link className='LoginTitleLink' to="/">StoryCraft.</Link>
                </div>

                <form  className="LoginPageForm">
                    <div className='LoginHeading'>Password Reset</div>
                    {currentUser && currentUser.email}
                    {auth_message_element}

                    <InputField 
                        inputType='email' 
                        style={{display: "block"}} 
                        id='email' 
                        label='email' 
                        do_set_db_data='false'
                        onChangeFunction={e => setEmail(e.target.value)}
                        value={email}
                        />




                    <Button 
                        onClickFunction={handleSubmit}
                        label='Reset Password' 
                        style={{width:"140px", top:"20px", margin:'auto'}}
                    />
                    {/* <div>
                        <Link to="/login" style={{left:'40%', bottom:'60px'}} className='LoginLink'>Log In</Link>
                    </div> */}

                    <div style={{display:'block', padding:'10px',}}>
                        {/* <p style={{bottom:'50px'}} className='LoginText'> {"Have an account already?  "}</p> */}
                        <Link to="/login" className='ForgotPasswordLink'> Go back to Log In</Link>

                    </div>
                    <div style={{display:'block', padding:'10px'}}>
                        <p className='LoginText'> {"Don't have an account?  "}</p>
                        <Link to="/signup" className='LoginLink'>Sign Up Now</Link>

                    </div>
                </form>
            </div>
    )
}
