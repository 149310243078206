import React, {Component} from 'react';
import './List.css';
import { connect} from 'react-redux';

// import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'
import new_uid from '../../../utils/new_uid'
// import * as actionCreator_inputs from '../../../store/actions/actions_inputs';
// import * as actionCreator_inputs from '../../../store/actions/actions_inputs';
import * as actionCreator_attributes from '../../../store/actions/actions_attributes';
import * as actionCreator_ui from '../../../store/actions/actions_ui';


class List extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            valueX:this.props.inputValue,
        }
    }

    
    handleClick(event) {
        event.stopPropagation();
        let value = event.target.value

        console.log('List handleClick event.target', event.target)
        // console.log('List handleClick event', event.target)
        console.log('List handleClick', value)

        this.setState({valueX: value});

    }
    // handleChange(event) {
    //     event.stopPropagation();
    //     console.log('SelectMenu handleChange event.target', event.target)

    //     let value = Array.from(event.target.selectedOptions, option => option.value);
    //     console.log('SelectMenu handleChange value', value)


    //     // let value = event.target.value
    //     this.setState({valueX: value});

        
    //     console.log('SelectMenu onChangeArgs', this.props.onChangeArgs)

    //     // run passed in function with value as arg
    //     if (this.props.onChangeFunction){
    //         this.props.onChangeFunction(value, this.props.onChangeArgs)
    //     }
    //     // this.props.onClick(this.props.dataTarget, event.target.value, this.props.current_project_id, event)
    // }

    // trying to do this a more correct way with 'lifting state' up.
    handleChange(event) {
        event.stopPropagation();
        let value = Array.from(event.target.selectedOptions, option => option.value);
        console.log('List handleChange value', value)


        // let value = event.target.value
        // this.setState({valueX: value});

        
        console.log('SelectMenu onChangeArgs', this.props.onChangeArgs)

        // run passed in function with value as arg
        // if (this.props.onChangeFunction){
        //     this.props.onChangeFunction(value, list_id, this.props.onChangeArgs)
        // }
        if (this.props.onChangeFunction){
            this.props.onChangeFunction(value, this.props.list_id)
        }
    }






    generate_list_items() {
        let list_items = []

        
        for (let i = 0; i < this.props.item_list.length; i++) {
            const element = this.props.item_list[i];
            let display_name =element;
            let value = element;
            if (Array.isArray(element)){
                display_name =element[0];
                value = element[1]; 
            }
            let selectClass = '';
            if (value === this.state.valueX){
                selectClass = 'ListSelected'
            }

            let special_class = '';
            let selected = false;
            if (value.startsWith('__')){
                special_class = 'listItemSpecial';
                selected = true;
            }
            list_items.push(
                <option 
                    className={ selectClass + ' listItem ' + special_class} 
                    value={value}
                    onClick={this.handleClick}
                    selected={selected}
                >
                    {display_name}
                </option>
            );
        }

        return list_items;
    }


    render() {
        let rand_id;
        if (!this.props.list_id) {
            rand_id  = 'list_'+new_uid;
        }
        else {
            rand_id = this.props.list_id;
        }

        let list_value;
        if (!this.props.list_value) {
            list_value  = null;
        }
        else {
            list_value = this.props.list_value;
        }

        let defaultValue = this.props.defaultValue || null;

        // let onClickCmd =null;

        // let element_id = this.props.function_args['item_type'] + '-' + this.props.function_args['item_id']
        let labelToUse = '';
        if (this.props.label){
            labelToUse = this.props.label + '  :   '
        }

        let select_menu_key_class = '';
        if (this.props.is_keyed){
            select_menu_key_class = 'SelectMenuHasKeys';
        }
        if (this.props.on_key_time){
            select_menu_key_class = 'SelectMenuOnKeyTime';

        }
       

        return (
            

            <select  onChange={this.handleChange}
                value={list_value}
                multiple={true}
                onClick={this.handleClick}
                id={rand_id} 
                className={"ListDiv " + select_menu_key_class}
                defaultValue={defaultValue}
            >
                {this.generate_list_items()}
            </select>

        )
    }
        
}
// }


const mapStateToProps = (state, props) => {
    return {
        // inputValue:get_data_obj_from_string(state, props.dataSource),
        // current_project_id:state.ui_data.current.current_project,
    }
}


const mapDispatchToProps = (dispatch, props) => {

    // let do_set_db_data = true;
    // if (props.do_set_db_data === 'false'){
    //     do_set_db_data = false;
    // }

    // if (!props.dataTarget){
    //     return{
    //         onClick:  (entity, event) => console.log('no data target!')
    //     }
    // }
    return {
        // onClick:  (event) => dispatch({type:'ON_CLICK', event:event, valToSet:props.valToSet, dataTarget:props.dataTarget}),
        // onClick: (dataTarget, current_project_id, event) => dispatch(actionCreator_inputs.on_checkbox_click(event, dataTarget, props.valToSet, current_project_id, do_set_db_data)),
        // onClick: (dataTarget, valToSet, current_project_id, event) => dispatch(actionCreator_inputs.on_click(event, dataTarget, valToSet, current_project_id, do_set_db_data)),
        // set_attr: ( item_type, item_id, attr_id, attr_val, attr_parent, project_id) => dispatch(actionCreator_attributes.set_attr( item_type, item_id, attr_id, attr_val, attr_parent, project_id)),
        // open_foldout_panel: (panel_name, element_id) => dispatch(actionCreator_ui.open_foldout_panel(panel_name, element_id)),

    }




};

export default connect(mapStateToProps, mapDispatchToProps )(List);
