/*
Blocked in  based on story_items

*/


import new_uid from '../../utils/new_uid'
import * as Constants from '../../constants/constants';
import set_state_from_string from '../../utils/set_state_from_string'

const reducer_attributes = (state, newState, action) => {
   

    if(action.type === 'SET_ATTRIBUTE_BY_PATH'){
        // console.log('RD SET_ATTRIBUTE_BY_PATH', action)
        
        let item_state = newState[action.item_type + '_data'][action.item_id];

        newState[action.item_type + '_data'][action.item_id] = set_state_from_string(item_state, action.attr_path, action.attr_val)
        newState[action.item_type + '_data'][action.item_id] = {...state[action.item_type + '_data'][action.item_id]};
        newState[action.item_type + '_data'] = {...state[action.item_type + '_data']};

        // newState[action.item_type + '_data'][action.item_id][action.attr_path] = action.attr_val;

        // newState[action.item_type + '_data'][action.item_id][action.attr_path] = {...state[action.item_type + '_data'][action.item_id][action.attr_path]};
        // newState[action.item_type + '_data'][action.item_id] = {...state[action.item_type + '_data'][action.item_id]};
        // newState[action.item_type + '_data'] = {...state[action.item_type + '_data']};

        // newState[action.item_type + '_data'][action.item_id][action.attr_path] = action.attr_val;

    }




    if(action.type === 'SET_ATTRIBUTE'){
        // console.log('RD SET_ATTRIBUTE', action)
        if (action.attr_parent){
            newState[action.item_type + '_data'][action.item_id][action.attr_parent][action.attr_id] = {...state[action.item_type + '_data'][action.item_id][action.attr_parent][action.attr_id]};
            newState[action.item_type + '_data'][action.item_id][action.attr_parent] = {...state[action.item_type + '_data'][action.item_id][action.attr_parent]};
        }
        else{
            newState[action.item_type + '_data'][action.item_id][action.attr_id] = {...state[action.item_type + '_data'][action.item_id][action.attr_id]};
        }

        newState[action.item_type + '_data'][action.item_id] = {...state[action.item_type + '_data'][action.item_id]};
        newState[action.item_type + '_data'] = {...state[action.item_type + '_data']};

        if (action.attr_parent){
            newState[action.item_type + '_data'][action.item_id][action.attr_parent][action.attr_id] = action.attr_val;
        }
        else{
            newState[action.item_type + '_data'][action.item_id][action.attr_id] = action.attr_val;
        }


    }

    if(action.type === 'DELETE_ATTRIBUTE'){
        console.log('RD DELETE_ATTRIBUTE', action)

        // newState[action.item_type + '_data'] = {...state[action.item_type + '_data']};


    }

    if(action.type === 'ADD_ATTRIBUTE'){
        // console.log('RD ADD_ATTRIBUTE', action)
        
        newState[action.item_type + '_data'][action.item_id][action.attr_parent] = {...state[action.item_type + '_data'][action.item_id][action.attr_parent]};
        newState[action.item_type + '_data'][action.item_id] = {...state[action.item_type + '_data'][action.item_id]};
        newState[action.item_type + '_data'] = {...state[action.item_type + '_data']};

        let attr_id = action.attr_id;
        let attr_val = action.attr_val;
        // let data_to_set =  {'a':'test'};
        // let data_to_set =  {[attr_id]:attr_val};
        // if ()
        newState[action.item_type + '_data'][action.item_id][action.attr_parent][attr_id] = attr_val;


    }

    if(action.type === 'SELECT_KEY'){
        // console.log('RD SELECT_KEY', action)
        let select_mode = action.select_mode || 'replace';
        // console.log('RD SELECT_ITEM_C select_mode', select_mode)

        newState.ui_data.control = {...state.ui_data.control};
        newState.ui_data = {...state.ui_data};
        let current_sel = {...state.ui_data.control.selected_keys};

        // let data = {[action.item_id]:{item_type:action.item_type}};
        let data = {...action.data};
        if (select_mode === 'replace'){
            newState.ui_data.control.selected_keys = data;
        }
        if (select_mode == 'add')
        {
            console.log('ADDING NOW', data)

            newState.ui_data.control.selected_keys = Object.assign(data, current_sel);
        }
        if (select_mode == 'remove'){
            Object.keys(data).map((item_id) => {
                console.log('REMOVING NOW', item_id)

                if (item_id in current_sel){
                    delete current_sel[item_id]; 
                }
            })
            newState.ui_data.control.selected_keys = current_sel;
           
        }

    }




    
    return newState;

};
export default reducer_attributes;