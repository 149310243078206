const guide_popups = {
    projects:[
        {
            name:'getting_started',
            text:'Welcome to StoryCraft.\n\n\
            This is your Project page where you can create and open your projects.\n  \n\
            Open the example project to learn more about StoryCraft\n\
            or create your first new project.',
            element_id:'BannerLabel',
            panel_placement:[650, 220, 'bottom', 0],
            show_arrow:true,
            dark_bg:true,
        },  
        {
            name:'guide_popups',
            text:'Guide Popups can be turned off or reset by going to account settings here.\n\n\
            NOTE: These guide popups are a bit of a work in progess. Eventually they will be a bit smarter about when to appear.',
            element_id:'UserSettingsIcon',
            panel_placement:[450, 180, 'bottom', -193],
            show_arrow:true,
            dark_bg:true,
        },  
    ],
    flow:[
        {
            name:'flow_mode',
            text:'StoryCraft gives you multiple ways to view your story.\n\
            This is the Flow view. It provides an open canvas\
            to layout your scenes, chapters, beats or thoughts.',
            element_id:'flow_mode_button',
            panel_placement: [600, 140, 'right', 0],
            show_arrow:true,
            dark_bg:false,
        },  
        {
            name:'double_click_for_new_card',
            text:'Double-click anywhere on the canvas to create a new Card.\n\
            A Card may represent anything from a story beat, a chapter in your novel, a scene from your screenplay or even just a random thought.',
            element_id:'MainPanel',
            panel_placement:[640, 160, 'center_top', 10],
            show_arrow:false,
            dark_bg:true,
        },  
        {
            name:'new_card_made',
            text:'Congratulations, you just made your first card!\n\
                Give it a Title and some details in the Synopsis field',
            element_id:null,
            trigger:'trigger_new_card_made',
            blocking:true,
            panel_placement:[500, 120, 'top', 0],
            show_arrow:false,
            dark_bg:true,
        },  
        {
            name:'add_items',
            text:'Items can also be added to the canvas by clicking here.\n\
            Backdrops can be added to help organise your Cards.',
            element_id:'add_item_flow',
            panel_placement:[470, 150, 'top', -160],
            show_arrow:true,
            dark_bg:false,
        },  
        {
            name:'attr_editor',
            text:'This is the Attribute Editor.\n\
            Select an item to see its attributes here.\n\
            It can be widened or minimised by\
            dragging or clicking on the border.',
            element_id:'attr_editor_handle',
            panel_placement:[500, 185, 'left', -30],
            show_arrow:true,
            dark_bg:false,
        },  
        {
            name:'thread',
            text:"Cards can be connected via Threads.\n\
            These can be used to connect a plot line through your cards or indicate some kind of relationship.\n\
            Click the area above any card to start dragging out a thread and drop onto another card.",
            element_id:'MainPanel',
            panel_placement:[550, 190, 'center_top', 10],
            show_arrow:true,
            dark_bg:false,
        },  
        {
            name:'thread_note',
            text:"There are 2 types of thread: 'throughline' and 'relationship'.\n\
            Throughline threads don't allow for more than one in and out connection per card in order to keep a clear through line in your story.\n\
            Relationship threads can have as many in and outgoing connections as you wish.\n-----\nNOTE: This feature is a work in progress!\n\
            Currently the tool doesn't give any indication of which threads are which, but just know that RED and BLUE are Throughline whereas GREEN is Relationship\n\
            In the future this will be clearer, and there will be options to add new thread colours, as well as switch behaviour from Throughline to Relationship mode.",
            element_id:'flow_thread_picker',
            panel_placement:[800, 400, 'bottom', -190],
            show_arrow:true,
            dark_bg:false,
        },  
        {
            name:'nesting_cards',
            text:"Drag and drop Cards onto other Cards to nest them.\n\
            This can be used for things like nesting story beats under a scene.\n\
            Cards with nesting will display a badge showing the number of nested items.\
            Clicking on the number badge will dive into the card.",
            element_id:'MainPanel',
            panel_placement:[650, 200, 'center_top', 10],
            show_arrow:false,
            dark_bg:true,
        },  
        {
            name:'nesting_cards_breadcrumbs',
            text:"Once inside a Card, use the Breadcrumb bar at the top of the canvas to navigate back.\n\
            Drag and drop Cards over Breadcrumbs to move Cards back up through layers.\n-\n\
            ( NOTE: Eventully, this popup will only be displayed once inside a nested card )",
            element_id:'MainPanel',
            panel_placement:[650, 250, 'center_top', 10],
            show_arrow:false,
            dark_bg:true,
        },  
        // {
        //     name:'nesting_cards_breadcrumbs_b',
        //     text:"Use the Breadcrumb bar to navigate back.\n\
        //     Drag and drop Cards over Breadcrumbs to move Cards back up through layers.",
        //     element_id:'breadcrumb-div-home...',
        //     panel_placement:[650, 150, 'bottom', 290],
        //     show_arrow:false,
        //     dark_bg:true,
        // },  
        // {
        //     name:'nesting_cards_breadcrumbs_c',
        //     text:"AAAA",
        //     element_id:'MainPanel',
        //     panel_placement:[650, 250, 'center_top', 10],
        //     show_arrow:false,
        //     dark_bg:true,
        // },  
    ],
    map:[
        {
            name:'map_mode',
            text:"This is the Map view.\n\
            Here you can chart out your world's locations.\n\
            Eventually you will be able to see the locations of all story items.\n\
            ( This can be tested now by adding a location attribute to characters )",
            element_id:'map_mode_button',
            panel_placement:[400, 260, 'right', 20],
            show_arrow:true,
            dark_bg:false,
        },  
        {
            name:'add_items_map',
            text:'You can bring in an image as a background for you map.\n\
            For example, a map of the world, a city, or perhaps a sketch of the next Middle Earth.\n\
            Click here to add a Backdrop, then set its image by clicking the icon button in the attribute editor.',
            element_id:'add_item_map',
            panel_placement:[600, 200, 'top', -220],
            show_arrow:true,
            dark_bg:false,
        },  
    ],
    writer:[
        {
            name:'writing_mode',
            text:"This is the Writing view.\n\
            Here you can create and edit your Documents.\n\
            In StoryCraft, Documents can be attached to any item type.",
            element_id:'writer_mode_button',
            panel_placement:[500, 170, 'right', 0],
            show_arrow:true,
            dark_bg:false,
        },  
        {
            name:'writing_filter',
            text:'Control which items are visible here.\n\
            Items can be filtered by Item Type, Thread Colour, or whether they have an attached document',
            element_id:'writingFilterOptions',
            panel_placement:[500, 140, 'right', 30],
            show_arrow:true,
            dark_bg:false,
        },  
    ],
    character:[
        {
            name:'chracter_mode',
            text:"This is the Character view.\n\
                Here you can create, view and edit your Characters.",
            element_id:'character_mode_button',
            panel_placement:[500, 130, 'right', 0],
            show_arrow:true,
            dark_bg:false,
        },  
        {
            name:'all_characters',
            text:'This is the default group containing all the\n\
                characters in your story.\n\
                Click the plus icon to create a new character.',
            element_id:'__ALL__character-group-header-div',
            panel_placement:[500, 150, 'bottom', 30],
            show_arrow:true,
            dark_bg:false,
        },  
        {
            name:'new_group',
            text:'Create New Groups to help organise your characters.',
            element_id:'StoryItemPanel-create-new-group-div',
            panel_placement:[500, 100, 'bottom', 30],
            show_arrow:true,
            dark_bg:false,
        },  
        {
            name:'working_with_groups',
            text:"Drag items between groups to add, or move them.\n\
            To remove an item from a group, drag it back into the default group.\n\
            Items cannot be moved out of the default group.",
            element_id:'MainPanel',
            panel_placement:[700, 160, 'center_top', 10],
            show_arrow:false,
            dark_bg:true,
        },  
    ],
}

export default guide_popups