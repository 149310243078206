import React, { Component } from 'react';
import './SelectionBox.css';


const SelectionBox = (props) => {

    if (!props.select_bbox){
        return null;
    }
    let left = props.select_bbox[0];
    let top = props.select_bbox[1];
    let width = props.select_bbox[2] - props.select_bbox[0];
    let height =  props.select_bbox[3] - props.select_bbox[1];
    
    let deselect = props.deselect || false;

    let style={
        left:left,
        top:top,
        width:width,
        height:height,
    }

    if (deselect){
        style['borderColor']='black';
        style['backgroundColor']='rgba(0, 0, 0, .05)';
    }
    return (
        <div style={style} className='SelectionBox'/>
    )
}

export default  SelectionBox;


