import React from 'react';
import './Slider.css';
import { connect} from 'react-redux';

import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'
import new_uid from '../../../utils/new_uid'

import * as actionCreator_inputs from '../../../store/actions/actions_inputs';

const Slider = (props) => {



    // id for element - wants to be unique
    let rand_id  = 'input_' + new_uid();
    let item_type = props.item_type || null;
    let item_id = props.item_id || null;

    let idToUse = props.id || rand_id;
    let input_key = props.input_key;
    if (input_key === undefined){   input_key = null }

    var inputValue = props.inputValue;
    let inputBackgroundClass = props.inputBackgroundClass || '';

   

    let styleToUse = props.style || {};
    let spellcheckToUse = props.spellCheck;
    if (spellcheckToUse === undefined){   spellcheckToUse = true }

    let decimal_places = props.decimal_places
    if (decimal_places === undefined){   decimal_places = 2 }
    let step = 0.01;
    if (decimal_places == 0){ step = 1;  }
    if (decimal_places == 1){ step = 0.1; }
    if (decimal_places == 2){ step = 0.01; }
    if (decimal_places == 3){ step = 0.001; }
    if (decimal_places == 4){ step = 0.0001; }

    // if input type is number and input val is given, then round to certain decimal_places
    inputValue=parseFloat(parseFloat(inputValue).toFixed(decimal_places));
    
    let min = props.min
    let max = props.max

    if (min === undefined){   min = 0 }
    if (max === undefined){   max = 1 }

    let labelToUse = null;
    let classToUse = null;
    let classToUseDiv = null;

    if (props.label){  labelToUse = props.label + '  :   '}

    // in order to always be able to provide the same thing as onChange, I'm defaulting to a function 
    // that does nothing with an empty array of args. Maybe  a better way to do this, but it works.
    // this makes the UI still work when not controlled
    let on_input_change_function = props.onChangeFunction || function doNothing(){};
    let on_input_change_args = props.onChangeArgs || [];

    // if dataTarget is provided, set function to setByDataTarget and configure args
    // this keeps behaviour the same as it always used to be
    if (props.dataTarget){
        on_input_change_function = props.setByDataTarget; 
        on_input_change_args = [props.dataTarget, props.current_project_id];
    }  


    classToUseDiv = props.className || 'textFieldDiv';


    // let slider_percent = inputValue / (max - min) * 100;
    let slider_percent = (inputValue - min)  * (1 / (max - min)) * 100;
    // let slider_percent = inputValue * 100;
    return (
        <div style={styleToUse} className={classToUseDiv}
            item_type={item_type} item_id={item_id}
        >

            <label className='InputLabel' item_type={item_type} item_id={item_id}> {labelToUse}  </label>
            <div 
                className={'sliderDiv '+ inputBackgroundClass}
                item_type={item_type} item_id={item_id}
            >
                <input 
                    type="range" min={min} max={max} step={step} value={inputValue} id="myRange"
                    onChange={on_input_change_function.bind(this, ...on_input_change_args)}
                    className='Slider'
                    item_type={item_type} item_id={item_id}
                />
                <div style={{width:slider_percent+'%'}} className='sliderBar'/>
            </div>
            <input 
                min={min} max={max} step={step}
                // key={rand_id}   
                onChange={on_input_change_function.bind(this, ...on_input_change_args)}
                id={rand_id} 
                className={'sliderFieldInput ' + inputBackgroundClass} 

                type='number'
                value={inputValue} 
                item_type={item_type} item_id={item_id}
            />
        </div>
    )       
}
 


const mapStateToProps = (state, props) => {

    // some logic to get input value from dataSource or prop.inputValue
    // dataSource is good for making a direct connection to the data
    // props.inputValue allows me to set what the input is manually.

    let input_value;    
    if (props.dataSource){
        input_value = get_data_obj_from_string(state, props.dataSource)
    }
    else if (props.hasOwnProperty('inputValue')){
        input_value =  props.inputValue;   
    }
    else{
        console.error('NO inputValue or dataSource provided. Must provide 1 of these!')
    }
    return {
        inputValue:input_value,
        current_project_id:state.ui_data.current.current_project,
    }

}


const mapDispatchToProps = (dispatch, props) => {

    let do_set_db_data = true;
    if (props.do_set_db_data === 'false'){
        do_set_db_data = false;
    }

    return {    
        setByDataTarget: (dataTarget, current_project_id, event) => dispatch(actionCreator_inputs.on_input_change(event, dataTarget, current_project_id, do_set_db_data)),
        // onInputFocus: ( input_type, input_id,) => dispatch(actionCreator_inputs.on_input_focus(input_type, input_id)),
        // onInputBlur: ( input_type, input_id,) => dispatch(actionCreator_inputs.on_input_blur(input_type, input_id)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps )(Slider);
