
const initial_projects  ={
    // project_data:{
    //     // uid1:{
    //     //     name:'My Project',
    //     //     description:'my first project!',
    //     //     created_by:'uid1',
    //     //     creation_date:'01/02/18',
    //     //     owner:'uid1',
    //     //     parent_item:'uid1', // eg, this might be place to link to a 'series'
    //     //     links:{
    //     //         user:[
    //     //             'uid1',
    //     //         ],
    //     //         character:[
    //     //             'uid1',
    //     //             'uid2',
    //     //         ],
    //     //         'card':[
    //     //             'uid1',
    //     //             'uid2',
    //     //         ],
    //     //     },
    //     // },
    //     // uid2:{
    //     //     name:'The sequel',
    //     //     description:'my next project!',

    //     //     created_by:'uid2',
    //     //     creation_date:'02/03/18',
    //     //     owner:'uid2',
    //     //     parent_item:'', // eg, this might be place to link to a 'series'
    //     //     links:{},
    //     // },
    //     // uid3:{
    //     //     name:'Another story',
    //     //     description:'a sweeping epic about some old thing',

    //     //     created_by:'uid2',
    //     //     creation_date:'02/03/18',
    //     //     owner:'uid2',
    //     //     parent_item:'', // eg, this might be place to link to a 'series'
    //     //     links:{},
    //     // },
    //     // uid4:{
    //     //     name:'yet another story',
    //     //     description:'a sweeping epic about some old thing',

    //     //     created_by:'uid2',
    //     //     creation_date:'02/03/18',
    //     //     owner:'uid2',
    //     //     parent_item:'', // eg, this might be place to link to a 'series'
    //     //     links:{},
    //     // },
    //     // uid5:{
    //     //     name:'what about a prequel?',
    //     //     description:'a sweeping epic about some old thing',

    //     //     created_by:'uid2',
    //     //     creation_date:'02/03/18',
    //     //     owner:'uid2',
    //     //     parent_item:'', // eg, this might be place to link to a 'series'
    //     //     links:{},
    //     // },

    // },
    projects:{}
}



export default initial_projects