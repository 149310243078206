
import React, { Component } from 'react';
import './ThreadPickerItem.css';
import { connect} from 'react-redux';

// import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'
import new_uid from '../../../../utils/new_uid'

import * as actionCreator_inputs from '../../../../store/actions/actions_inputs';
import * as actionCreator_ui from '../../../../store/actions/actions_ui';
import * as actionCreator_cards from '../../../../store/actions/actions_cards';
// import { render } from 'react-dom';


/*
    Double click to edit text description? or to change colour? (will need a colour picker - or just provide a grid of colours a-la trello) 

*/



class ThreadPickerItem extends Component {
    constructor(props) {
        super(props);
        this.handleThreadPickerItemClick = this.handleThreadPickerItemClick.bind(this);
        this.close_foldout_on_click = this.props.close_foldout_on_click || false;
    }
    
    // use this syntax to set defaults for vals
    // var myDefaultValue = props.myDefaultValue || 'hello';
    handleThreadPickerItemClick(event) {
        console.log('CLICKED HERE',  this.props.thread_id)
        
        this.props.set_current_thread(event, this.props.ui_data.current.current_project,  this.props.thread_id);
        // this is to close the foldout when clicked
        if (this.close_foldout_on_click){
            this.props.close_all_foldout_panels();
        }
    }

    render() {
        let thread_item_style = {
            backgroundColor:null,
        }
        if (this.props.ui_data.current.current_thread_colour === this.props.thread_id){
            thread_item_style['backgroundColor']='rgba(255, 255, 255, .8)' ;
        }


        return (
            <div 
                className='ThreadListItem' 
                style={thread_item_style}
                onClick={this.handleThreadPickerItemClick}
            >
                <div> {this.props.thread_name}  </div> 
                <div 
                    style={{backgroundColor:this.props.thread_color}}
                    className="ThreadListItemSwatch"
                />
            </div>
               
        )

    }
}

const mapStateToProps = (state) => {
    return {
        ui_data:state.ui_data,
    }
}


const mapDispatchToProps = (dispatch, props) => {

    return {
        // onClick:  (event) => dispatch({type:'ON_CLICK_THR', event:event}),
        // threadPickerToggle: (event, ui_data) => dispatch(actionCreator_ui.thread_picker_toggle(event, ui_data)),
        set_current_thread: (event, project_id, thread_id) => dispatch(actionCreator_cards.set_current_thread(event, project_id, thread_id)),
        close_all_foldout_panels: () => dispatch(actionCreator_ui.close_all_foldout_panels()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps )(ThreadPickerItem);
