import React, { Component } from 'react';
import { connect} from 'react-redux';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6
import './QuillComponent.css';

import * as actionCreator_inputs from '../../../store/actions/actions_inputs';
import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'

class QuillComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      text: this.props.inputValue,
      current_document:this.props.ui_data.current.current_document 

    } // You can also pass a Quill Delta here
    this.handleChange = this.handleChange.bind(this)
  }
 
  handleChange(value,) {
    this.setState({ text: value })
    // console.log('XX - value', value)

    let current_project_id =  this.props.ui_data.current.current_project;
    this.props.on_input_change_quill(value, this.props.dataTarget, current_project_id)
  }

  // gen_quill(){
  //   if (this.props.dataTarget){
  //     return (
  //         <ReactQuill  className='QuillEditor' 
  //         // defaultValue={this.props.inputValue}
  //         value={this.state.text}
  //         placeholder='this is a placeholder'
  //         // value={this.props.inputValue}
  
  //         onChange={this.handleChange} />
  //     )
  //   }
  // }


  render() {
    if (this.state.current_document != this.props.ui_data.current.current_document ){
      this.setState({
          text: this.props.inputValue,
          current_document:this.props.ui_data.current.current_document 
        })
    }
    return (
      // <div  className='QuillEditorDiv'>
        <ReactQuill  className='QuillEditor' 
        // defaultValue={this.props.inputValue}
        value={this.state.text}
        // placeholder='this is a placeholder'
        // value={this.props.inputValue}

                    onChange={this.handleChange} />
      // </div>
    )
  }
}


const mapStateToProps = (state, props) => {
  return {
      ui_data:state.ui_data,
      inputValue:get_data_obj_from_string(state, props.dataTarget),

  }
}



const mapDispatchToProps = (dispatch, props) => {
  return {
    on_input_change_quill: (value, dataTarget, current_project_id,) => dispatch(actionCreator_inputs.on_input_change_quill(value, dataTarget, current_project_id)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps )(QuillComponent);

