import React from 'react';
import './ModePanel.css';
import { connect} from 'react-redux';
// import {bindActionCreators} from 'redux';

import * as actionCreator_ui from '../../store/actions/actions_ui';

// class ModePanel extends Component {


const ModePanel = (props) => {
    let modePanelStyle = {}
    if (props.mode_panel_expanded) {
        modePanelStyle = {
            sideNav: {left: "0px"},
            // bigIcons: {display: "block"},
            // smallIcons: {display: "none"},
        }
    }
    else{
        modePanelStyle = {
            sideNav: {left: "-92px"},
            // bigIcons: {display: "none"},
            // smallIcons: {display: "block"},
        }
    }

    let lable_text_map = {
        flow:'',
        writing:'',
        map:'',
        timeline:'',
        characters:'',
        props:'',
        locations:'',
        events:'',
    }
    if (props.mode_panel_expanded){
        lable_text_map = {
            flow:'Flow',
            writing:'Writing',
            map:'Map',
            timeline:'Timeline',
            characters:'Characters',
            props:'Props',
            locations:'Locations',
            events:'Events',
        }
    }

    // add dissable pointer class based on dragging
    let disable_pointer_class = ''
    if (props.ui_data.control.begin_drag){
        disable_pointer_class = 'DisablPointer';
    }

    let writerStyle = {backgroundColor:'transparent ',}
    if (props.mode === 'writer') {writerStyle = {backgroundColor:'rgba(255, 255, 255, 0.4)', }}

    let flowStyle = {backgroundColor:'transparent ',}
    if (props.mode === 'flow') {flowStyle = {backgroundColor:'rgba(255, 255, 255, 0.4)', }}

    let characterStyle = {backgroundColor:'transparent ',}
    if (props.mode === 'character') {characterStyle = {backgroundColor:'rgba(255, 255, 255, 0.4)', }}

    let propStyle = {backgroundColor:'transparent ',}
    if (props.mode === 'prop') {propStyle = {backgroundColor:'rgba(255, 255, 255, 0.4)', }}

    let locationStyle = {backgroundColor:'transparent ',}
    if (props.mode === 'location') {locationStyle = {backgroundColor:'rgba(255, 255, 255, 0.4)', }}
    
    let eventStyle = {backgroundColor:'transparent ',}
    if (props.mode === 'event') {eventStyle = {backgroundColor:'rgba(255, 255, 255, 0.4)', }}

    let mapStyle = {backgroundColor:'transparent ',}
    if (props.mode === 'map') {mapStyle = {backgroundColor:'rgba(255, 255, 255, 0.4)', }}

    let timelineStyle = {backgroundColor:'transparent ',}
    if (props.mode === 'timeline') {timelineStyle = {backgroundColor:'rgba(255, 255, 255, 0.4)', }}

    // let time_controls_toggle =null;
    // // if (props.mode === 'timeline' || props.mode === 'map'){
    //     time_controls_toggle =  <div className='timeControlsToggle'
    //                         onClick={() => {props.onTimeControlsToggle()}}
    //                         > time controls 
    //                     </div>
    // }
    let experimental = props.ui_data.settings.experimental_mode

    return (
        <div id="mySidenav" className={"sidenav " + disable_pointer_class} style={modePanelStyle['sideNav']}>
            <div id="big_icons" style={modePanelStyle['bigIcons']}>
                <a className="closebtn" onClick= {props.onExpandMode}><i className="fas fa-bars"></i></a>
                <br></br>
                <a id="flow_mode_button" style={flowStyle} onClick={() => {props.onModeChange('flow', props.ui_data)}}>{lable_text_map['flow']} <i className="fas fa-code-branch"></i> </a>
                <a id="writer_mode_button" style={writerStyle} onClick={() => {props.onModeChange('writer', props.ui_data)}}  >{lable_text_map['writing']} <i className="fas fa-edit"></i> </a>
                <a id="map_mode_button" style={mapStyle} onClick={() => {props.onModeChange('map', props.ui_data)}}> {lable_text_map['map']} <i className="fas fa-map-marked-alt"></i> </a>
                <a id="timeline_mode_button" style={timelineStyle} onClick={() => {props.onModeChange('timeline', props.ui_data)}}> {lable_text_map['timeline']} <i className="far fa-clock"></i> </a>
                <br></br>

                <a id="character_mode_button" style={characterStyle} onClick={() => {props.onModeChange('character', props.ui_data)}}>{lable_text_map['characters']} <i className="fas fa-users"></i> </a>
                <a id="prop_mode_button" style={propStyle} onClick={() => {props.onModeChange('prop', props.ui_data)}}> {lable_text_map['props']} <i className="fas fa-wrench"></i></a>
                <a id="location_mode_button" style={locationStyle} onClick={() => {props.onModeChange('location', props.ui_data)}}> {lable_text_map['locations']} <i className="fas fa-archway"></i> </a>
                <a id="event_mode_button" style={eventStyle} onClick={() => {props.onModeChange('event', props.ui_data)}}> {lable_text_map['events']} <i className="far fa-calendar-alt"></i> </a>
                
                
            </div>

            {experimental &&
                <a  style={{borderStyle:'solid', borderRadius:'6px', bottom:'20px', right:'6px', position:'absolute'}} onClick={() => {props.onTimeControlsToggle()}}> Time Control <i className="fas fa-stopwatch"></i> </a>
            }

        </div>
    )                           
}
// }


const mapStateToProps = (state) => {
    return {
        mode_panel_expanded:state.ui_data.settings.mode_panel_expanded,
        mode_panel_width:state.ui_data.settings.mode_panel_width,
        ui_data:state.ui_data,
        mode:state.ui_data.settings.mode,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // onExpandMode: () => dispatch({type:'MODE_PANEL_EXPAND_TOGGLE'}),
        onExpandMode: () => dispatch(actionCreator_ui.mode_panel_expand_toggle()),
        onModeChange: (mode, ui_data) => dispatch(actionCreator_ui.mode_panel_set_mode(mode, ui_data)),
        onTimeControlsToggle: () => dispatch(actionCreator_ui.show_time_controls_toggle()),
        // onModeChange: (mode) => dispatch({type:'SET_MODE', value:mode}),

    }
};
// const mapDispatchToProps = (dispatch) => {
//     return {
//         onExpandMode: bindActionCreators({type:'SET_MODE_EXPAND'}, dispatch),
//         // onWriter: () => dispatch({type:'SET_MODE_WRITER'}),
//         // onCharacter: () => dispatch({type:'SET_MODE_CHARACTER'}),
//         onModeChange: bindActionCreators(setMode, dispatch,),
//     }
// };
// const setMode = (mode = 'writer') => {
//     return {
//         type:'SET_MODE',
//         mode,
//     };
// }
export default connect(mapStateToProps, mapDispatchToProps )(ModePanel);
