import React, { Component } from 'react';
// import {Redirect} from 'react-router-dom';

import { connect} from 'react-redux';
import './UserItem.css';
import * as actionCreator_auth from '../../../../store/actions/actions_auth';
import * as actionCreator_database from '../../../../store/actions/actions_database';
// import * as actionCreator_storage from '../../../../store/actions/actions_storage';
import * as actionCreator_users from '../../../../store/actions/actions_users';
import * as actionCreator_ui from '../../../../store/actions/actions_ui';

import FoldoutPanel from '../../objects/foldout_panel/FoldoutPanel';
import Thumbnail from '../../inputs/Thumbnail';
import * as storage_utils from '../../../../utils/storage_utils'
import * as Constants from '../../../../constants/constants';

{/* <img id="UserIcon"  onClick={this.handleUserIconClick}   src={require('../../images/homer_icon_512.jpg')} /> */}

// const storage_callback = (url) => {
//     console.log('storage_callback!!!!', url)
//     this.props.set_user_icon_url
// }
class UserPanel extends Component {
    constructor(props) {
        super(props);
        this.handleUserIconClick = this.handleUserIconClick.bind(this);
        // this.handleChangeUserClick = this.handleChangeUserClick.bind(this);
        this.handleLogOutUserClick = this.handleLogOutUserClick.bind(this);
        this.fileSelectHandler = this.fileSelectHandler.bind(this);
        // this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleThumbClick = this.handleThumbClick.bind(this);
        this.handleAccountSettingsClick = this.handleAccountSettingsClick.bind(this);
        // this.state={logout:false}
    }

    storage_callback(url, data) {
        // let user_uid = this.props.context_data.user.uid;
        console.log('storage_callback!!!!', url, data)
        // this.props.set_user_icon_url(data.user_uid, url)
    }
    handleUserIconClick() {
        console.log('handleUserIconClick')
        // this.props.testButtonDB();
        // this.props.open_user_ui();

    }
    // handleClickOutside() {
    //     this.props.close_user_panel()
    // }
    // handleChangeUserClick(e) {
    //     console.log('handleChangeUserClick');
    //     this.props.sign_in_user(e);

    // }
    handleLogOutUserClick(e) {
        console.log('handleLogOutUserClick')
        this.props.sign_out_user();
        this.props.close_all_foldout_panels();
        // this.setState({logout:true})


    }

    handleAccountSettingsClick(event) {
        event.stopPropagation();
        // alert(
        //     '\n\nThis feature is not working yet.\nIn the future, it might bring up options for:\n\
        //     - changing user name, password, password hints\n\
        //     - reset password\n\
        //     - add extra info such as name, DOB etc'
           
            
        // )
        console.log('UserPanel handleClickUserSettings',)
        this.props.open_modal_window('user_settings', {item_type:'user', item_id:this.props.context_data.user,})
        this.props.close_all_foldout_panels();

    }

    fileSelectHandler(event) {
        console.log('fileSelectHandler', event.target.files )
        let user_uid = this.props.context_data.user;
        // console.log('user_uid B', user_uid)
    

        this.props.pick_user_icon(event.target.files[0], user_uid)
        this.props.close_all_foldout_panels();

    }

    handleThumbClick (event){
        console.log('ApRegion handleThumbClick',)
        this.props.open_modal_window('pick_image', {item_type:'user', item_id:this.props.context_data.user,})
        this.props.close_all_foldout_panels();

    }
    // handleClickUserSettings (event){
    //     console.log('UserPanel handleClickUserSettings',)
    //     this.props.open_modal_window('user_settings', {item_type:'user', item_id:this.props.context_data.user,})

    // }
    render(){
        // if (this.state.logout){
        //     return (
        //         <Redirect to="/home" />
        //     )
        // }

        let user_uid = this.props.context_data.user;
        // console.log('user_uid', user_uid)
        // let user_icon_url = this.props.user_data[user_uid].icon;
        let user_icon_url = null;
        let user_storage_used = 0;
        if (this.props.user_data[user_uid]){
            user_icon_url = this.props.user_data[user_uid].image;
            if (this.props.user_data[user_uid].hasOwnProperty('storage_used'))
            {
                user_storage_used = this.props.user_data[user_uid].storage_used;
            }
        }
        // let user_storage_used_readable = storage_utils.humanFileSize(user_storage_used)
        // let user_storage_limit = storage_utils.humanFileSize(Constants.USER_STORAGE_BYTE_LIMIT) 
        // console.log('ff - user_storage_used', user_storage_used)
        // console.log('ff - Constants.USER_STORAGE_BYTE_LIMIT', Constants.USER_STORAGE_BYTE_LIMIT)
        let storage_used_percent = 100 * user_storage_used / Constants.USER_STORAGE_BYTE_LIMIT

        let icon_element = <div id="UserIcon_noImage" className='fas fa-user' onClick={this.handleThumbClick}/>
        if (user_icon_url){
            icon_element =  <img id="UserIcon" src={user_icon_url} onClick={this.handleThumbClick} />
        }

        return (

            <div
                className='UserPanelDiv'
                onClick={this.handleUserIconClick}
            >
                <div className='UserPanelIcon'>
                    {icon_element}

                </div>
                {this.props.user_data[user_uid].hasOwnProperty('name') &&
                    <div className='nameText' >
                        {this.props.user_data[user_uid].name}
                    </div>
                }
                <div className='emailText' >
                    {this.props.user_data[user_uid].email}
                </div>
                <div className='storageText' >
                    {/* {'storage used: ' + user_storage_used_readable +' / '+user_storage_limit} */}
                    {'storage used: ' + storage_used_percent.toFixed(1) + '%'}
                </div>
                <div style={{height:'20px'}} />
                <div onClick={this.handleAccountSettingsClick} 
                    className='ChangeUserDiv'>Account Settings</div>
                {/* <div onClick={this.handleChangeUserClick} 
                    className='ChangeUserDiv'>Change User</div> */}
                <div onClick={this.handleLogOutUserClick} 
                    className='ChangeUserDiv'>Log Out</div>
                
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
      ui_data:state.ui_data,
      user_data:state.user_data,
      context_data:state.context_data,
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        // open_user_ui: () => dispatch(actionCreator_ui.open_user_ui()),
        // close_user_panel: () => dispatch(actionCreator_ui.close_user_panel()),
        sign_in_user: (event) => dispatch(actionCreator_auth.sign_in_user(event)),
        sign_out_user: () => dispatch(actionCreator_auth.sign_out_user()),
        

        // set_user_icon_url : (user_uid, url ) => dispatch(actionCreator_users.set_user_icon_url(user_uid, url))
        pick_user_icon : (file, user_uid, ) => dispatch(actionCreator_users.pick_user_icon(file, user_uid)),
        open_modal_window: (modal_name, input_data) => dispatch(actionCreator_ui.open_modal_window(modal_name, input_data)),
        close_all_foldout_panels: () => dispatch(actionCreator_ui.close_all_foldout_panels()),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);
