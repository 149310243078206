import React, { Component } from 'react';
import './IndexCard.css';
// import jQuery;
import { connect} from 'react-redux';
import InputField from '../../inputs/InputField';

import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
import * as actionCreator_viewports from '../../../../store/actions/actions_viewports';
import * as actionCreator_viewport_xforms from '../../../../store/actions/actions_viewport_xforms';
import * as actionCreator_cards from '../../../../store/actions/actions_cards';
import * as actionCreator_ui from '../../../../store/actions/actions_ui';
import * as get_cards_by_thread_by_parent from '../../../../utils/get_cards_by_thread_by_parent';

class WritingCard extends Component {

    constructor(props) {
        super(props);
        this.handleRegionMouseDown = this.handleRegionMouseDown.bind(this);
        // this.handleRegionMouseDown_scale = this.handleRegionMouseDown_scale.bind(this);
        // this.handleAnchorMouseDown = this.handleAnchorMouseDown.bind(this);
        this.nestedMouseDown = this.nestedMouseDown.bind(this);
    }
 
    handleRegionMouseDown(event) {

        event.stopPropagation();

        // the order of these is important!
        this.props.onMouseDown(event, this.props.item_type, this.props.item_id,);
        // this.props.onMouseDown_VP(event, this.props.ui_data, this.props.viewport_data);
        this.props.mouse_down_vp_xform(event, this.props.item_type, this.props.item_id,  this.props.item_type_data[this.props.item_id], this.props.ui_data);

    }

    // handleRegionMouseDown_scale(event) {

    //     event.stopPropagation();

    //     // the order of these is important!
    //     this.props.onMouseDown(event, this.props.item_type+'_scale', this.props.item_id,);
    //     this.props.onMouseDown_VP(event, this.props.ui_data, this.props.viewport_data);
    //     this.props.mouse_down_vp_xform(event, this.props.item_type+'_scale', this.props.item_id,  this.props.item_type_data[this.props.item_id], this.props.ui_data);
    // }


    // handleAnchorMouseDown(event) {
    //     event.stopPropagation();

    //     this.props.onMouseDown(event, 'cardAnchor', this.props.item_id,);
    //     this.props.onMouseDown_VP(event, this.props.ui_data, this.props.viewport_data);
    //     this.props.onMouseDown_anchor(event, this.props.item_id, this.props.current_thread_colour);
    //     // this.props.onAnchorMouseDown(event, 'cardAnchor', this.props.item_id, );
    
    // };
    nestedMouseDown(event) {
        console.log('TODODODO - set expanded mode here!')
        console.log('expanded? this.props.card_expanded_state', this.props.card_expanded_state)
        event.stopPropagation();
        // this.props.onNestedMouseDown( 'flow_viewport', 'card_' + this.props.item_id,);
        this.props.toggle_tree_item_expanded(event, this.props.item_id, this.props.tree_name, this.props.ui_data);

        // xxxx.test_thing();
    
    };






    render() {
        let item_id= this.props.item_id;
        let cardDraggingStyle={};
        let cardStyle = {};
        let apply_hover_class = '';
        let hover_off_style = {}
        let cardSelectedStyle = {};
        let spellcheck='false';
        let titleClassToUse = 'CardTitle';
        let textClassToUse = 'cardText';

        // let iconStyle = {'fontSize': '20px'};


        let style = {};

        // if selected, change style
        if (this.props.ui_data.control.selected){
            if (this.props.item_id in this.props.ui_data.control.selected){
                style['boxShadow'] = '0px 0px 0px 2px rgba(255, 255, 255, 1)';
                apply_hover_class = 'ApplyHover';

                cardSelectedStyle = {
                    boxShadow: '0px 0px 0px 2px rgba(255, 255, 255, 1)',
                }
                // cardSelectedStyle['transform'] = 'rotate(0deg)  scale(1.02, 1.02)';
                

                titleClassToUse = 'CardTitleSel';
                textClassToUse = 'cardTextSel';
                spellcheck='true';
            }
        }

        // let expanded_style = {};
        let card_children_div_style ={};
        let writingCard_div_style ={};
        let expanded_string = '-';
        if(!this.props.card_expanded_state){
            // style['backgroundColor'] = 'green';
            // writingCard_div_style['height'] = '110px';
            card_children_div_style['height'] = '0px';
            expanded_string = '+';
        }
        // else{
        //     card_children_div_style['height'] = '0px';
        // }

        let test_children = [];

        if(!this.props.card_expanded_state){
            test_children = [
                        <div className='test_child' />,
                        <div className='test_child' />,
                        <div className='test_child' />,
            ]
        }

        if (this.props.begin_drag){
            // if (this.props.dragging[0] === 'card'){
            //     if (this.props.dragging[1] === this.props.item_id){
            if (this.props.ui_data.control.dragging_b){

                if (item_id in this.props.ui_data.control.dragging_b){
                    if (this.props.ui_data.control.dragging_b[item_id].item_type === 'card'){

                        cardDraggingStyle = {
                            cursor: 'grabbing',
                            transform: 'translate(0px, 10px)',
                            transform: 'rotate(-2deg)  scale(1.08, 1.08)',
                            backgroundColor: 'rgb(245, 235, 190)',
                            boxShadow: '-8px 25px 60px 3px rgba(0, 0, 0, .12)',
                            pointerEvents: 'none',

                            
                        }
                        document.activeElement.blur();
                        titleClassToUse = 'CardTitle';
                        textClassToUse = 'cardText';
                
                    }
                }
            }
        }

        let documents = null;
        if (this.props.card_data[item_id].hasOwnProperty('links')) {
            if (this.props.card_data[item_id]['links'].hasOwnProperty('document')) {
                documents = this.props.card_data[item_id].links.document;
            }
        }
        let doc_style = {
            display:'none',
        } 
        if (documents){
            if (documents.length) {
                doc_style = {
                    display:'block',
                } 
            }
        }



        let counter_style = {}
        if (this.props.child_count === 0){
            counter_style['display'] = 'none';
        }

        // This disables pointerevents so i dont trigger hover when dragging anything
        // if (this.props.dragging[0] !== null ) {
        if (this.props.begin_drag){
            hover_off_style['pointerEvents'] = 'none';
        }
        else{
            apply_hover_class = 'HoverIndexCard';
        }



        return (
            <div className='writingCard_div' style={writingCard_div_style}>
                <div className={"writingCard " + apply_hover_class}
                    item_id={this.props.item_id}
                    item_type={this.props.item_type}
                    //style={style}
                    //style={Object.assign({}, cardStyle, cardSelectedStyle, cardDraggingStyle, cardOverCardStyle)}
                    //style={Object.assign({}, style,  cardSelectedStyle, cardDraggingStyle, cardOverCardStyle)}
                    style={Object.assign({}, style,  cardSelectedStyle,)}

                    onMouseDown={this.handleRegionMouseDown}
                    onTouchStart={this.handleRegionMouseDown}
                >

                    <InputField 
                            className='WritingCardTitle'
                            inputPlaceholder='Title...'
                            dataSource={"card_data." + item_id + ".title"}
                            dataTarget={"card_data." + item_id + ".title"}
                            inputType='textArea' 
                            //style={{position:"relative", userSelect:"none"}} 
                            id='nameField' 
                            label='' 
                            //spellCheck={spellcheck}
                            //style={hover_off_style}

                    />
                    {/* <div className='CardSeparator' />

                    <InputField 
                        className={textClassToUse}
                        inputPlaceholder='Synopsis...'

                        dataSource={"card_data." + item_id + ".synopsis"}
                        dataTarget={"card_data." + item_id + ".synopsis"}

                        inputType='textAreaCard' 
                        //style={{position:"relative", userSelect:"none"}} 
                        id='xx' 
                        label='' 
                        //spellCheck={spellcheck}
                        //style={hover_off_style}
                    /> */}
    



                    
                    <i style={Object.assign({}, doc_style, hover_off_style,)} id='Writer-DocumentIconShad' className="fas fa-file-alt"></i>
                    <i style={Object.assign({}, doc_style, hover_off_style,)} id='Writer-DocumentIcon' className="fas fa-file-alt"></i>
                    
                    <div className='NestedCardCounter_writing'
                        style = {Object.assign({}, counter_style, hover_off_style)}
                        //onMouseDown={this.nestedMouseDown}
                    >
                        {this.props.child_count}
                        
                    </div>
                    <div className='NestedCardCounter_writing_over'
                        style = { counter_style}
                        onMouseDown={this.nestedMouseDown}
                    >
                        {expanded_string}
                    </div>
                    


                </div>
                <div className='card_children_div' style={card_children_div_style}>
                        {/* <div className='test_child' />
                        <div className='test_child' />
                        <div className='test_child' /> */}
                        {/* {test_children} */}
                        {this.props.child_cards}
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state, props) => {
    let current_vp = state.ui_data.current.current_viewport;
    let item_type = props.item_type;
    return {

        item_type_data:state[item_type+'_data'],
        viewport_data:state[current_vp[0]+'_data'][current_vp[1]],
        ui_data:state.ui_data,

        dragging:state.ui_data.control.dragging,
        hovering:state.ui_data.control.hovering,
        begin_drag:state.ui_data.control.begin_drag,
        card_data:state.card_data,
        thread_data:state.thread_data,
        viewport_data:state[current_vp[0]+'_data'][current_vp[1]],
        // ui_data:state.ui_data,
        current_vp:state.ui_data.current.current_viewport,
        current_thread_colour:state.ui_data.current.current_thread_colour,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        // onMouseDown_VP: (event, ui_data, viewport_data) => dispatch(actionCreator_viewports.mouse_down_viewport(event, ui_data, viewport_data)),
        mouse_down_vp_xform: (event, item_type, item_id, item, ui_data) => dispatch(actionCreator_viewport_xforms.mouse_down_vp_xform(event, item_type, item_id, item, ui_data)),
        // onMouseDown_anchor: (event, item_id, col_id) => dispatch(actionCreator_cards.mouse_down_anchor(event, item_id, col_id)),
        // onNestedMouseDown: (vp_type, vp_id) => dispatch(actionCreator_ui.toggle_tree_item_expanded(vp_type, vp_id)),
        toggle_tree_item_expanded: (event, item_id, tree_name, ui_data) => dispatch(actionCreator_ui.toggle_tree_item_expanded(event, item_id, tree_name, ui_data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(WritingCard);

