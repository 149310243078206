import React, { Component } from 'react';
import './ThreadPicker.css';
import { connect} from 'react-redux';

import ThreadPickerItem from './ThreadPickerItem'
// import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'
import new_uid from '../../../../utils/new_uid'

import * as actionCreator_inputs from '../../../../store/actions/actions_inputs';
import * as actionCreator_ui from '../../../../store/actions/actions_ui';
// import { render } from 'react-dom';

class ThreadPicker extends Component {
    constructor(props) {
        super(props);
        this.handleThreadPickerClick = this.handleThreadPickerClick.bind(this);
    }
    
    handleThreadPickerClick(event) {
        let panel_name = 'flow_thread_picker_list'
        let element_id = 'flow_thread_picker'
        this.props.open_foldout_panel(panel_name, element_id);
    }


    render() {

        let cur_thread_color = this.props.thread_data[this.props.ui_data.current.current_thread_colour]['color'];
        return (
            <div className="ThreadPickerDivA"
            style={this.props.style}>
                <div 
                    //onClick={props.threadPickerToggle.bind(this)}
                    onClick={this.handleThreadPickerClick}
                    id='flow_thread_picker' 
                    className="ThreadPickerDiv"
                    
                >
                    <i className="ThreadPicker fas fa-pen"></i>
                    <div 
                        className='ThreadColourStrip'
                        style={{backgroundColor:cur_thread_color}}
                    />

                </div>
            </div>
                
        )

    }
}
// }

const mapStateToProps = (state) => {
    return {
        ui_data:state.ui_data,
        thread_data:state.thread_data,
    }
}


const mapDispatchToProps = (dispatch, props) => {

    return {
        open_foldout_panel: (panel_name, element_id) => dispatch(actionCreator_ui.open_foldout_panel(panel_name, element_id)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps )(ThreadPicker);
