/*
Simple function for translating string based item_parent (eg card_uid1) to ['card', 'uid1']
Hacky as hell but can tidy up later
*/

const get_item_array_from_parent_item_string = (parent_item_string) => {


    // item_parent will be something like card_uid1. need to split this into type and id
    let parent_id = '';
    let parent_item_rebuilt = '';
    // let parent_type = 'card'; // hard code this for the moment
    if (parent_item_string.startsWith('card_')){
        parent_id = parent_item_string.substring(5)
        // //AAA console.log('get_item_array_from_parent_item_string parent_id', parent_id)
        parent_item_rebuilt = ['card', parent_id]
    }
    if (parent_item_string.startsWith('project_')){
        parent_id = parent_item_string.substring(8)
        // //AAA console.log('get_item_array_from_parent_item_string parent_id', parent_id)
        parent_item_rebuilt = ['project', parent_id]
    }
    // //AAA console.log('get_item_array_from_parent_item_string parent_item_rebuilt', parent_item_rebuilt)

    return parent_item_rebuilt;


}

export default get_item_array_from_parent_item_string;