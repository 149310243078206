import React from 'react';
import './TextEditor.css';
import { connect} from 'react-redux';

import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'
import new_uid from '../../../utils/new_uid'
import * as actionCreator_inputs from '../../../store/actions/actions_inputs';


const TextEditor = (props) => {
    // use this syntax to set defaults for vals
    // var inputValue = props.inputValue || 'hello';
    var inputValue = props.inputValue || '';
    var inputPlaceholder = props.inputPlaceholder || '';
    let rand_id  = 'textEditor_'+new_uid;
    let styleToUse = props.style || {};
    let spellcheckToUse = props.spellCheck || 'true';

    let classToUse = null;
    let idToUse = null;


    classToUse = props.className || 'TextEditor';
    idToUse = props.id || rand_id;
    return (
        <textarea  
                id={idToUse}
                onChange={props.onInputChange.bind(this, props.dataTarget, props.current_project_id)}
                placeholder={inputPlaceholder}
                value={inputValue} 
                style={styleToUse}
                className={classToUse} 
                rows="3" cols="2"
                spellCheck={spellcheckToUse}
            >
        </textarea>
    )
}


const mapStateToProps = (state, props) => {
    if (props.dataSource === undefined){
        return {
            current_project_id:state.ui_data.current.current_project,
        }
    }
    // //AAA console.log('get_data_obj_from_string', get_data_obj_from_string(state, props.dataSource))
    return {
        inputValue:get_data_obj_from_string(state, props.dataSource),
        current_project_id:state.ui_data.current.current_project,

    }
}


const mapDispatchToProps = (dispatch, props) => {

    // This takes the first token of a dataTarget and converts it to 
    // an all caps suffix to be used in each reducer. This is to make sure 
    // it works with data in that state slice.
    // then it strips the first token away because in the scope of the 
    // reducer it's not required (won't work)

    // let splitKeys = props.dataTarget.split(".");
    // let action_suffix = splitKeys[0].toUpperCase() 
    // splitKeys.splice(0, 1);
    // let new_data_target = splitKeys.join(".");

    // // //AAA console.log('new_data_target string', new_data_target)
    // // //AAA console.log('action_suffix string', action_suffix)

    // return {
    //     onInputChange:  (event) => dispatch({type:'ON_INPUT_CHANGE__'+action_suffix, event:event, dataTarget:new_data_target}),
    // }


    return {
        // onInputChange:  (event) => dispatch({type:'ON_INPUT_CHANGE', event:event, dataTarget:props.dataTarget}),
        onInputChange: (dataTarget, current_project_id, event) => dispatch(actionCreator_inputs.on_input_change(event, dataTarget, current_project_id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps )(TextEditor);
