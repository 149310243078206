
const new_uid = () => {
    // //AAA console.log('get_data_obj_from_string splitKeys', splitKeys, )

    let randomNumber = Math.random();
    let safeName = String(randomNumber).replace(".", "");
   
    // //AAA console.log('randomNumber ', randomNumber, )
    // //AAA console.log('safeName ', safeName, )

    return safeName;

}
export default new_uid;
