import React from 'react';
import './Image.css';

const Image = (props) => {
    let image_div = null;
    let upload_progress_div = null;
    let upload_progress_text = null;

    let pictureUrl = null;
    let  uploadProgress = null;

    let onClick = props.onClick || null;

    let image_data = props.image_data;
    let is_icon = false;
    let div_style = props.style || {};
    // let icon_height = Math.round(div_style.height * 1) || '100px';
    let height = Math.round(props.height) || 100;
    let width = Math.round(props.width)  || 100;
 
    div_style['width'] = width+'.px'
    div_style['height'] = height+'.px'

    // div_style['width'] = '100%'
    // div_style['height'] = '100%'

    // console.log('div_style', div_style)
    // console.log('icon_height', icon_height)

    // if image data is array, it means image is not uploaded yet (local) or in progresss  uploading
    if (Array.isArray(image_data)){
        if (image_data[0] === 'icon'){ 
            is_icon = true;
        }
        else{
            // if local, then show image as if uploaded. if not it must be in progress
            if (image_data[0] !== 'local'){
                uploadProgress = image_data[0];
            }
            
            pictureUrl = image_data[1];
        }
    }
    else{
        pictureUrl = image_data;
    }

    if (is_icon){
        image_div = <i style={{fontSize:Math.round(props.height * .8)+'px', marginTop:Math.round(props.height * .1)+'px'}} className={[image_data[1], 'ImageIcon'].join(' ')}></i>
    }
    else{
        image_div = <img draggable="false" className='ImageIMG' src={pictureUrl} alt='image not found' />;
    }
    // console.log('uploadProgress', uploadProgress)

    let upload_divs = null;
    if (uploadProgress){
        let darken_width = '100%';
        let darken_display='block';
        let uploadTextOpacity = '1';
        let uploadTextDisplay = 'inline';
        let uploadText = '';
        let transitionDelay = '0s';
    
        
        if (uploadProgress !== -1){
            darken_width = (100 - Math.round(uploadProgress)) + '%';
            // console.log('darken_width', darken_width);
            uploadText = 'uploading ' + Math.round(uploadProgress)  + '%';
            if (uploadProgress === 100){
                uploadText = 'upload complete';
                transitionDelay = '2s';
                uploadTextOpacity = '.0';
                // darken_width = '100%';
                darken_display='None';
            }
            // upload_divs = [
            //     <div>
            //         <div className='ImageProgressDarken' style={{display:darken_display, width:darken_width}}/>
            //         <div className='ImageUpdloadText' style={{display:uploadTextDisplay, opacity:uploadTextOpacity, transitionDelay:transitionDelay}}> {uploadText} </div>
            //     </div>
            // ]

            upload_progress_div = <div className='ImageProgressDarken' style={{display:darken_display, width:darken_width}}/>;

            if (width >= 150){
                upload_progress_text = <div className='ImageUpdloadText' style={{display:uploadTextDisplay, opacity:uploadTextOpacity, transitionDelay:transitionDelay}}> {uploadText} </div>;
            }
        }
        // else{
        //     darken_display='None';
        // }
        if (!pictureUrl){
            uploadText = 'upload file';
        }
    }




    // console.log('div_styleXX', div_style)

    return (

        <div className='ImageDiv'
            onClick={onClick}
            style={div_style}
        >
            {image_div}
        </div>
    )
}


export default Image;