/*
TO DO:
make fixed full BG behind mode, attr and main panel which has BG (grad). 
  that way i dont need o worry about reveiling white on transitions 
  (atm i'm hacking with differen transition speeds)


*/

// import React from 'react';
import React from 'react';

// import React, { Component } from 'react';
import './MainPanel.css';
import WritingPanel from '../mode_panels/WritingPanel';
import FlowPanel from '../mode_panels/FlowPanel';
// import CharacterPanel from '../mode_panels/CharacterPanel';
// import PropPanel from '../mode_panels/PropPanel';
import StoryItemPanel from '../mode_panels/StoryItemPanel';
// import LocationPanel from '../mode_panels/LocationPanel';
import MapPanel from '../mode_panels/MapPanel';
import TimelinePanel from '../mode_panels/TimelinePanel';
import TimeControl from '../ui_elements/objects/time/TimeControl';

import { connect} from 'react-redux';



const MainPanel = (props) => {
    let mainPanelStyle = {
      left:props.main_panel_left+'px',
      top:props.main_panel_top+'px',
      right:props.attribute_panel_width+'px',
  }

  if (props.dragging[0] === 'handle'){
    mainPanelStyle['transition'] = '0s';
  }

    return (
        <div className="MainPanelBackgroundDiv"> 
          <div id="MainPanel" style={mainPanelStyle}> 
            {props.mode === 'writer' && <WritingPanel/>}
            {props.mode === 'flow' && <FlowPanel/>}
            {/* {props.mode === 'character' && <CharacterPanel/>} */}
            {/* {props.mode === 'prop' && <PropPanel/>} */}
            {/* {props.mode === 'location' && <LocationPanel/>} */}
            {props.mode === 'character' && <StoryItemPanel item_type='character'/>}
            {props.mode === 'prop' && <StoryItemPanel item_type='prop'/>}
            {props.mode === 'location' && <StoryItemPanel item_type='location'/>}
            {props.mode === 'event' && <StoryItemPanel item_type='event'/>}
            {props.mode === 'timeline' && <TimelinePanel/>}
            {props.mode === 'map' && <MapPanel/>}
            <TimeControl/>

          </div>
        </div>
    )
}



const mapStateToProps = (state) => {
  return {
      mode_panel_expanded:state.ui_data.settings.mode_panel_expanded,
      main_panel_left:state.ui_data.layout.main_panel_left,
      main_panel_top:state.ui_data.layout.main_panel_top,
      mode:state.ui_data.settings.mode,
      attribute_panel_width:state.ui_data.layout.attribute_panel_width,
      
      dragging:state.ui_data.control.dragging,
  }
}

export default connect(mapStateToProps )(MainPanel);

