
export const get_storage_path = (storage_root, project_id, user_id, storage_path) => {
    let final_storage_path = null;

    // console.log('get_storage_path', storage_path, storage_root, project_id, user_id)
    if (!project_id){
        // console.log('\n\nWARNING Project has returned as null. This shouldnt happen')
    }

    // if project id is provided then sort under that. otherwise, put under user
    if (project_id){
        final_storage_path = storage_root +'/users/'+ user_id +'/projects/'+ project_id + '/' + storage_path;
    }
    else{
        final_storage_path = storage_root +'/users/'+ user_id + '/' + storage_path;
        // console.log('get_storage_path- no project-  final_storage_path', final_storage_path);

    }
    return final_storage_path

}


export function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}
