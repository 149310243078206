import React, { Component } from 'react';
import './StoryItem.css';
import { connect} from 'react-redux';

import * as actionCreator_ui from '../../../../store/actions/actions_ui';
// import * as actionCreator_storyItem from '../../../../store/actions/actions_story_items';

// const onClickFunction = (props) => {

// }

const StoryItemEmpty = (props) => {
    let itemSizeStyleSuffix = ''
    if (props.itemSize === 'large'){
        itemSizeStyleSuffix = 'Large'
    }
    let itemSizeStyle = null;
    let itemSizeStyle_empty = null;
    if (props.itemSize === 'large'){

        itemSizeStyle = {
            margin: '8px',
            width: '145px',
            marginTop:'25px',

        };
        itemSizeStyle_empty = {
            width:'110px',
            height:'110px',
            fontSize: '96px',
            borderRadius: '10px',

        };
    }
    if (props.itemSize === 'medium'){

        itemSizeStyle = {
            margin: '6px',
            width: '95px',
            marginTop:'17px',
        };
        itemSizeStyle_empty = {
            width:'70px',
            height:'70px',
            fontSize: '60px',
            borderRadius: '6px',

        };
    }

    const create_new_function = () => {props.onNewStoryItem(props.item_type)}
    const link_function = () => {props.link_items([props.item_type, props.parent_data])}

    let onClickFunction = create_new_function;
    if (props.mode === 'link' || props.mode === 'attr_editor'){
        onClickFunction = link_function 
    }
    if (props.mode === 'pick'){
        return null; 
    }

    
    return ( 
        <div className={"StoryItem"}
        style={itemSizeStyle}
        //onClick={() => {props.onNewStoryItem(props.item_type)}}
        onClick={onClickFunction}
         >
            <div className={"StoryItemEmpty"}
            style={itemSizeStyle_empty}
            >
                +
                {/* <i className='fas fa-link' ></i> */}
            </div>
            {/* <a className="StoryItemLabel">  </a> */}

        </div>
    )

}
const mapStateToProps = (state) => {
    return {

        // character_data:state.character_data,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onNewStoryItem: (item_type) => dispatch(actionCreator_ui.open_modal_window('create_'+item_type, item_type)),
        link_items: (input_data) => dispatch(actionCreator_ui.open_modal_window('link_items', input_data)),

        // this is temped in
        // link_items: (item_data, item_type, item_id, to_link_type, to_link_id) => dispatch(actionCreator_storyItem.link_item(item_data, item_type, item_id, to_link_type, to_link_id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps )(StoryItemEmpty);
