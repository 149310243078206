const initial_locations  = {
    
    location_data:{
        // 'uid1':{
        //     name:'kitchen',
        //     icon:"fas fa-archway",
        //     description:'whats cooking',
        //     links: {},

        // },
        // 'uid2':{
        //     name:'home',
        //     icon:"fas fa-archway",
        //     description:'is where the heart is',
        //     links: {},


        // },
        // 'uid3':{
        //     name:'city',
        //     icon:"fas fa-archway",
        //     description:'the big bad city',
        //     links: {},

        // },
    },
    
};

export default initial_locations