/*
to do :
get all child items bboxes. 
take snapshot of those on mouse down, then use those vals to detect if mouse over a cell
rather than mouse over events and dummy copy of flex box
this will also give the advantage of being able to detect if in empty space in flex
at end of a row (in the middle or after last item) 
It will also enable box selection. yay

write function to return item name when mouse moving through stored bboxes 
- this will be equivelent to mouseover.


- selection bbox
    make container that's bigger than flex so you can start to draw a 
    seletion without moving an item
    Test item bboxes
    Add modifier behavior (same as VP)

- allow for multiple flex containers
    drag an drop between flex containers

*/


import React, { useState, useEffect, useCallback } from 'react';
import './UberFlexContainer.css';



export default function UberFlexContainer(props) {


    const add_item_function = props.add_item_function || null;
    const reorder_items_function = props.reorder_items_function || null;
    const copy_to_group_function = props.copy_to_group_function || null;
    const move_to_group_function = props.move_to_group_function || null;
    const select_items_function = props.select_items_function || null;
    const itemList = props.item_list || [];
    const elementList = props.children || [];
    const drop_target_class = props.drop_target_class || "uber-flex-drop-target";
    let is_master_grp = false
    if (props.id.startsWith('__ALL__')){
        is_master_grp = true
    }
    const name = props.name || ''

    let element_dict = null
    if (elementList){
        element_dict = {}
        itemList.length = 0
        for (let index = 0; index < elementList.length; index++) {
            const element = elementList[index];
            itemList.push(element.props.id)
            element_dict[element.props.id] = element
        }
    }
    const flex_gap = 6
    const flex_padding = 20
    const borderRadius = 8
    const flex_direction = props.flex_direction || 'row'
    const item_width = props.item_width || null
    const item_height = props.item_height || null

    // const [itemList, setItemList] = useState(
    //     [
    //         'bob', 'sally', 'harry', 'jane', 'frank', 'lucy', 'sam', 'molly', 'alex', 'ritchy',
    //         'bobx', 'sallyx', 'harrxy', 'janx', 'frxank', 'lxucy', 'sxam', 'molxly', 'alxex', 'rxitchy',
    //     ]
    // )

    const [duringMoveItemList, setDuringMoveItemList] = useState([])
    const [preMoveItemList, setPreMoveItemList] = useState([])

    const [dummyxxxx, setDummyxxxx] = useState('----clickedInUberFlex----')
    const [clickedInUberFlex, setClickedInUberFlex] = useState(false)

    const [dummyx, setDummyx] = useState('----mouseOverNow, dragging----')
    const [dragging, setDragging] = useState()
    const [dragging_b, setDragging_b] = useState()
    const [draggingOverIndex, setDraggingOverIndex] = useState(null)

    const [dummyax, setDummya] = useState('----lastClicked, selected----')
    const [clicked, setClicked] = useState(null)
    const [lastClicked, setLastClicked] = useState(null)
    const [doneSelection, setDoneSelection] = useState(false)
    const [selected, setSelected] = useState([])

    const [dummyazzx, setDummyzza] = useState('----selectionBbox, inSelBBox----')
    const [selectionBbox, setSelectionBbox] = useState(null)
    const [inSelBBox, setInSelBBox] = useState([])


    const [dummyxx, setDummyxx] = useState('----mouseDownPos,mousePos')
    const [mouseDownPos, setMouseDownPos] = useState(null)
    const [mousePos, setMousePos] = useState(null)
    const [dummyxxff, setDummyxxff] = useState('---- isDragging, isDraggingSelBox----')
    const [isDragging, setIsDragging] = useState(false)
    const [isDraggingSelBox, setIsDraggingSelBox] = useState(false)
    const [afterDragPeriod, setAfterDragPeriod] = useState(false)
    const [dummyxxc, setDummyxxc] = useState('--------draggingOverMainFlexParent,draggingOverOtherFlexParent')

    const [draggingOverMainFlexParent, setDraggingOverMainFlexParent] = useState(false)
    const [draggingOverOtherFlexParent, setDraggingOverOtherFlexParent] = useState(null)
    const [draggingOverOtherFlexParent_copy, setDraggingOverOtherFlexParent_copy] = useState(false)
    const [draggingOverOtherFlexParent_move, setDraggingOverOtherFlexParent_move] = useState(false)

    const [dummy, setDummy] = useState('---- childBBoxes, indexBBoxes, flexBBox, flexChildData----')

    // const [flexBBox, setFlexBBox] = useState()
    const [childBBoxes, setChildBBoxes] = useState()
    const [indexBBoxes, setIndexBBoxes] = useState()
    const [flexBBox, setFlexBBox] = useState()
    const [flexChildData, setFlexChildData] = useState({})

    const flexDivRef = React.createRef()
    

    // This is to store hotkey states
    const [shiftPressed, setShiftPressed] = useState(false)
    const [controlPressed, setControlPressed] = useState(false)
    const [altPressed, setAltPressed] = useState(false)




    const handleUserKeyPress = useCallback(event => {
        const { key, keyCode } = event;
        if(key === 'Shift') setShiftPressed(true)
        if(key === 'Alt') {
            event.preventDefault()
            setAltPressed(true)
        }
        if(key === 'Control') setControlPressed(true)
    }, []);

    const handleUserKeyUp = useCallback(event => {
        const { key, keyCode } = event;
        if(key === 'Shift') setShiftPressed(false)
        if(key === 'Alt') {} setAltPressed(false)
        if(key === 'Control') setControlPressed(false)
    }, []);
    
    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);


    useEffect(() => {
        window.addEventListener("keyup", handleUserKeyUp);
        return () => {
            window.removeEventListener("keyup", handleUserKeyUp);
        };
    }, [handleUserKeyUp]);


    // this will populate state that i need just one time
    useEffect(() => {
        generate_bbox_data()
    }, []);



    // ------------
    // const handleMouseMove = useCallback(event => {
    //     console.log('handleMouseMove XXXX')
    //     on_item_mouse_move(event)
    // }, []);
    // const handleMouseUp = useCallback(event => {
    //     console.log('handleMouseUp XXXX')

    //     on_item_mouse_up(event)
    // }, []);
    const handleMouseMove = event => {
        // console.log('handleMouseMove XXXX')
        on_item_mouse_move(event)
    }
    const handleMouseUp = event => {
        // console.log('handleMouseUp XXXX')

        on_item_mouse_up(event)
    }

    
    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [handleMouseMove]);


    useEffect(() => {
        window.addEventListener("mouseup", handleMouseUp);
        return () => {
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [handleMouseUp]);




    // ------------



    const populate_bbox = () => {
        let return_items = []; 
        if (!flexChildData){
            return 
        }
        for (let row of Object.keys(flexChildData)){
            for (let index = 0; index < flexChildData[row].length; index++) {
                let flex_gap_half = flex_gap*.5
                const child_bbox_data = flexChildData[row][index];
                let leftbbox = child_bbox_data.left - flex_gap_half
                let rightbbox = child_bbox_data.right + flex_gap_half
                let topbbox = child_bbox_data.top - flex_gap_half
                let bottombbox = child_bbox_data.bottom + flex_gap_half
                let style = {
                    left:leftbbox,
                    top:topbbox,
                    width:rightbbox-leftbbox,
                    height:bottombbox-topbbox,
                }
                return_items.push(
                    <div style={style} className='flex_bbox_vis' />
                )
            }
        }
        return return_items
    }


    const populate_flex = () => {
        let return_items = []; 
        let return_items_data = {}; 

        // if not dragging or in afterDrag period, then just display items in order provided by props



        for (let index = 0; index < itemList.length; index++) {
            const item_name = itemList[index];

            return_items_data[item_name] = {
                order:index, 
                label:item_name, 
                hoverable:true, 
                dragging:false,
                adding_highlight:false,
                removing_highlight:false,
                selected:false,
                left:null,
                top:null,
                fixed:false,
            }


            if (selected.includes(item_name))
            {
                return_items_data[item_name].selected = true
            }

            if (isDraggingSelBox)
            {
                return_items_data[item_name].hoverable = false
                if (inSelBBox.includes(index))
                {
                    // console.log('inSelBBox GO HEE')
                    if (controlPressed) {
                        if (selected.includes(item_name)){
                            return_items_data[item_name].removing_highlight = true
                        }
                        else {
                            return_items_data[item_name].adding_highlight = true
                        }
                    }
                    else if (shiftPressed) return_items_data[item_name].adding_highlight = true
                    else if (altPressed) return_items_data[item_name].removing_highlight = true
                    else return_items_data[item_name].adding_highlight = true
                
                }
            }

            if (afterDragPeriod)
            {
                let bbox_data = indexBBoxes[index]
                // console.log('AA populate_flex bbox_data', bbox_data)
                if (!bbox_data){
                    continue
                }
                return_items_data[item_name].left = bbox_data.left
                return_items_data[item_name].top = bbox_data.top
            }
            // if dragging then change setup, set item styles to fixed and set xforms
            else if (isDragging){
                let duringMoveIndex = duringMoveItemList.indexOf(item_name)
                return_items_data[item_name].order = duringMoveIndex
                return_items_data[item_name].hoverable = false
                return_items_data[item_name].fixed = true

                /// if items are being dragged set style suit
                if (dragging_b.includes(item_name)){
                    // console.log('XXXXX mouseDownPos ', mouseDownPos)
                    let offset = [
                        childBBoxes[item_name]['left'] - mouseDownPos[0],
                        childBBoxes[item_name]['top'] - mouseDownPos[1],
                    ]
                    return_items_data[item_name].left = mousePos[0] + offset[0]
                    return_items_data[item_name].top = mousePos[1] + offset[1]
                    return_items_data[item_name].dragging = true
                }
                else{
                    let bbox_index = duringMoveIndex

                    let bbox_data = indexBBoxes[bbox_index]
                    if (!bbox_data){
                        continue
                    }
                    return_items_data[item_name].left = bbox_data.left
                    return_items_data[item_name].top = bbox_data.top
                }
            }

        }

        for (let index = 0; index < Object.keys(return_items_data).length; index++) {
            const item_id =  Object.keys(return_items_data)[index]
            const item_data =  return_items_data[item_id]
            let label = item_data.label
            
            let hover_class = '';

            let style = {order:item_data.order}
            style['width']=item_width
            style['height']=item_height


            
            
            if (item_data.selected){
                style['outlineColor']='white'
            }

            if (item_data.fixed){
                style['transition']='0.3s'
                style['position']='fixed'
                style['left']=item_data['left']
                style['top']=item_data['top']
            }
            if (item_data.dragging){
                style['transition']=null
                style['zIndex']=100
                style['pointerEvents']='none'
                // style['outlineColor']='green'

            }
            if (item_data.adding_highlight){
                // style['outlineColor']='yellow'
                style['outlineColor']='white'
            }
            if (item_data.removing_highlight){
                // style['outlineColor']='black'
                style['outlineColor']='rgba(255, 255, 255, 0.1)'
            }
            if (item_data.hoverable){
                hover_class = 'uber-flex-item-hover';
            }

            if (element_dict){
                label = element_dict[item_id]
            }
            return_items.push(
                <div style={style} key={item_id+ 'x'} id={item_id} className={'uber-flex-item '+ ' '+hover_class}
                    onMouseDown={(e) => {on_item_mouse_down(e, item_id)}}
                >
                    {label}
                </div>
            )
            
        }

        // let style = {order:10000, borderStyle:'dashed', borderColor:'black', borderRadius:'6px', width:'60px'}

        if (add_item_function){

            let empty_width = 60
            if (indexBBoxes && indexBBoxes.hasOwnProperty(0)){
                empty_width = indexBBoxes[0].width
    
            }
            let style = {
                    order:10000, width:empty_width+'px', height:empty_width+'px', lineHeight:empty_width+'px',
                    // order:10000, width:empty_width, height:empty_width, lineHeight:empty_width+'px',
                    outlineOffset:empty_width * -.2,
                    borderRadius:empty_width * .25,
                    fontSize:empty_width * .5,
        
                }
    
            if (isDragging || !draggingOverMainFlexParent){
                style['opacity'] = 0;
            }
            return_items.push(
                <div style={style} key='add_new_item' id='add_new_item' className={'uber-flex-item-add-new'}
                    // onMouseDown={(e) => {on_item_mouse_down(e, item_id)}}
                    onClick={(e) => {click_add_button(e)}}
                >
                    {'+'}
                </div>
            )
        }
        

        return return_items;
    }


    const click_add_button = (e) => {
        e.stopPropagation();
        if (add_item_function){
            // console.log('EEE preMoveItemList', preMoveItemList)
            // console.log('EEE new_list', new_list)
            add_item_function(props.id)
        }
        setIsDragging(false)
    }


    const populate_selection_box = () => {

        let return_item = []
        if (selectionBbox)
        {
            let style = {
                left: selectionBbox.left,
                width: selectionBbox.width,
                top: selectionBbox.top,
                height:  selectionBbox.height,
            }

            if (controlPressed) style['outlineColor'] = 'black'
            if (shiftPressed) style['outlineStyle'] = 'solid'
            if (altPressed) style['outlineColor'] = 'red'

            return_item =  <div style={style} className='flex_selection_box' />
        }
        return return_item
    }
    const select_item_by_click = (item_id) => {
        // console.log('select_item_by_click item_id', item_id)

        let new_sel = [...selected]

        if (controlPressed) {
            // console.log('select_item CONTROL')
            if (selected.includes(item_id)){
                let clicked_index = selected.indexOf(item_id)
                new_sel.splice(clicked_index, 1)
            }
            else{
                new_sel.push(item_id)
            }
        }
        else if (shiftPressed) {
            // console.log('select_item CONTROL')
            // get index of lastClicked (this will be last clikced before this selection)
            let last_clicked = lastClicked;
            let last_clicked_index = 0
            if (lastClicked){
                last_clicked_index = itemList.indexOf(lastClicked)
            }
            // console.log('last_clicked', last_clicked, last_clicked_index)


            let clicked_index = itemList.indexOf(item_id)
            // console.log('clicked_index', clicked_index)

            //sort last lastClicked and clicked so i can loop from smaller number to greater
            let range = [last_clicked_index, clicked_index];
            // range.sort()
            range.sort(function(a, b) {
                return a - b;
              });
            // console.log('range', range)

            for (let index = range[0]; index <= range[1]; index++) {
                const element = itemList[index];
                if (!selected.includes(element)) new_sel.push(element)

            }
        }
        else if (altPressed) {
            // console.log('select_item Alt')
            // get index of lastClicked (this will be last clikced before this selection)
            if (selected.includes(item_id)){
                let clicked_index = selected.indexOf(item_id)
                new_sel.splice(clicked_index, 1)
            }
        }
      
        else {
            new_sel = [item_id]
        }
        setSelected(new_sel)
        if (select_items_function){
            select_items_function(new_sel)
        }
        setLastClicked(item_id)

    }

    const select_item_by_sel_bbox = () => {
        // console.log('select_item_by_sel_bbox')

        let new_sel = [...selected]

        if (controlPressed) {
            // console.log('select_item_by_sel_bbox CONTROL')
            
            for (let index = 0; index < inSelBBox.length; index++) {
                const element_id = inSelBBox[index];
                if (new_sel.includes(itemList[element_id])){
                    let index_to_remove = new_sel.indexOf(itemList[element_id])
                    new_sel.splice(index_to_remove, 1)
                }
                else{
                    new_sel.push(itemList[element_id])
                }
            }
        }

        else if (shiftPressed) {
            // console.log('select_item_by_sel_bbox shift')
            
            for (let index = 0; index < inSelBBox.length; index++) {
                const element_id = inSelBBox[index];
                if (!selected.includes(itemList[element_id])){
                    new_sel.push(itemList[element_id])
                }
            }
        }

        else if (altPressed) {
            // console.log('select_item_by_sel_bbox alt')
            
            for (let index = 0; index < inSelBBox.length; index++) {
                const element_id = inSelBBox[index];
                // console.log('select_item_by_sel_bbox element_id', element_id)
                // console.log('select_item_by_sel_bbox itemList[element_id]', itemList[element_id])

                if (new_sel.includes(itemList[element_id])){
                    let index_to_remove = new_sel.indexOf(itemList[element_id])
                    new_sel.splice(index_to_remove, 1)
                }
                // console.log('select_item_by_sel_bbox new_sel', new_sel)

            }
        }

      
        else {
            // console.log('select_item_by_sel_bbox no hotkey')
            new_sel = []
            for (let index = 0; index < inSelBBox.length; index++) {
                const element_id = inSelBBox[index];
                new_sel.push(itemList[element_id])
            }
        }

        setSelected(new_sel)
        if (select_items_function){
            select_items_function(new_sel)
        }

    }

    const on_item_mouse_down = (e, item_id) => {
        e.stopPropagation();

        // console.log('on_item_mouse_down e', e)

        // console.log('on_item_mouse_down item_id', item_id)

        // set that i've i've clicked in this uberFlex
        // not sure if this will help - trying to limit mouse up triggering for all
        // just want the flexbox that has been interacted with to trigger mouse up function
        setClickedInUberFlex(true)


        // if still in afterDragPeriod then dont recompute bboxes as they'll be wrong
        // assum can resuse as nothing is likely to change in such a short time
        if (!afterDragPeriod){
            generate_bbox_data()
        }
        setPreMoveItemList([...itemList])
        setDuringMoveItemList([...itemList])
        
        let drag_index = itemList.indexOf(item_id)

        setDragging([item_id, drag_index])
        setMouseDownPos([e.clientX, e.clientY])
        setMousePos([e.clientX, e.clientY])

        // toDo. this is temp. 
        // setSelected([item_id, drag_index])
        setClicked(item_id)
        // select_item(e, item_id)

        // if clicked is not selected, then select it. 
        // If it also already selected, then do nothing - save that behaviour for mouse down
        // if dragging selected item, then include all selected

        if (!selected.includes(item_id)){
            // select_item(e, item_id)
            select_item_by_click(item_id)
            setDragging_b([item_id])
            setDoneSelection(true)
        }
        else{
            setDragging_b(selected)
        }


    }

    const on_item_mouse_up = (e) => {
        // console.log('on_item_mouse_up name', name)
        // console.log('on_item_mouse_up e', e)
        e.stopPropagation();

        if (!clickedInUberFlex){
            // console.log('on_item_mouse_up - quitting early - not this flex')
            setClickedInUberFlex(false)
            setSelected([])
            return
        }

        setClickedInUberFlex(false)

        // when mouse up if dragging add dragging items back into itemList at correct index
        if (isDragging){
            let new_list = [...duringMoveItemList]
            // new_list.splice(draggingOverIndex, 0, ...dragging_b)

            setDuringMoveItemList(new_list)
            setAfterDragPeriod(true)
            setTimeout(function () {
                setDraggingOverIndex(null)
                generate_bbox_data()
                setAfterDragPeriod(false)
            }, 200)

            if (reorder_items_function){
                // console.log('EEE preMoveItemList', preMoveItemList)
                // console.log('EEE new_list', new_list)
                reorder_items_function(new_list)
            }

            if (draggingOverOtherFlexParent){
                // console.log('OO draggingOverOtherFlexParent', draggingOverOtherFlexParent)
                // console.log('OO sousource_flex_idrce', props.id)
                // console.log('OO target_flex_id', dragging_b)

                if (draggingOverOtherFlexParent_copy){
                    if (copy_to_group_function){
                        copy_to_group_function( props.id, draggingOverOtherFlexParent, dragging_b)
                    }
                }
                else if (draggingOverOtherFlexParent_move){
                    if (move_to_group_function){
                        move_to_group_function( props.id, draggingOverOtherFlexParent, dragging_b)
                    }
                }

            }
        }
        else{
            setAfterDragPeriod(false)
            setDraggingOverIndex(null)
            generate_bbox_data()
            setAfterDragPeriod(false)
        }



        setDragging(null)
        

        setMouseDownPos(null)
        setIsDragging(false)
        setIsDraggingSelBox(false)

        if (clicked === 'flexbox'){
            select_item_by_sel_bbox()
        }
        else {
            if (!isDragging){
                if (clicked){
                    if (!doneSelection){
                        // select_item(e, clicked)
                        select_item_by_click(clicked)
                    }
                }
            }
        }

        setDoneSelection(false)
        
        setInSelBBox([])
        setSelectionBbox(null)

        // setDraggingOverOtherFlexParent(null)
        setDraggingOverOtherFlexParent_copy(false)
        setDraggingOverOtherFlexParent_move(false)


    }
    const on_item_mouse_move = (e,) => {
        // console.log('on_item_mouse_move')


        if (mouseDownPos){

            if (clicked === 'flexbox'){
                if (Math.abs(e.clientX-mouseDownPos[0]) + Math.abs(e.clientY-mouseDownPos[1] ) > 2){
                    setIsDraggingSelBox(true)
                }
            }
            else{
                // if moving more than 10 units then set isDragging to true
                if (Math.abs(e.clientX-mouseDownPos[0]) + Math.abs(e.clientY-mouseDownPos[1] ) > 10){
                    setIsDragging(true)
                }
            }
        }

        if (isDraggingSelBox && clicked === 'flexbox' && mousePos && mouseDownPos && flexBBox){
            setMousePos([e.clientX, e.clientY])

            let left = Math.max(flexBBox.left, Math.min(mousePos[0], mouseDownPos[0]))
            let top = Math.max(flexBBox.top, Math.min(mousePos[1], mouseDownPos[1]))
            let right = Math.min(flexBBox.right, Math.max(mousePos[0], mouseDownPos[0]))
            let bottom = Math.min(flexBBox.bottom, Math.max(mousePos[1], mouseDownPos[1]))
            setSelectionBbox(
                {
                    left: left,
                    right: right,
                    top: top,
                    bottom:bottom,
                    width: right-left,
                    height:  bottom-top,
                }
            )

            let return_array = []
            for (let index = 0; index < Object.keys(indexBBoxes).length; index++) {
                const item_index = parseInt(Object.keys(indexBBoxes)[index]);
                const item_bbox = indexBBoxes[item_index]
    
                if (
                    item_bbox.right > left
                    && item_bbox.left < right
                    && item_bbox.bottom > top
                    && item_bbox.top < bottom
                )
                {
                    return_array.push(item_index)
                }
            }
           
            // return return_array
            setInSelBBox(return_array)
        }

        if (isDragging){
            setMousePos([e.clientX, e.clientY])

            // let mouse_over_item = get_mouse_over_bbox_item(e)
            let dragging_over_item_index = get_mouse_over_bbox_index(e)
            setDraggingOverIndex(dragging_over_item_index)

                
            let new_item_list = []
            let added_dragging = false;
            let counter = 0

            // Add items up the index where pointer is when dragging
            for (let index = 0; index < itemList.length; index++) {
                const element = itemList[index];
                if (counter < dragging_over_item_index){
                    if (!dragging_b.includes(element)){
                        new_item_list.push(element)
                        counter += 1
                    }
                }
                else break
            }
            // then add all dragging items
            for (let index = 0; index < dragging_b.length; index++) {
                const element = dragging_b[index];
                new_item_list.push(element)
            }
            // then add the remainder
            for (let index = 0; index < itemList.length; index++) {
                const element = itemList[index];

                if (!new_item_list.includes(element)){
                    new_item_list.push(element)
                }
                
            }
                
            // this will stop the re-ordering if hovering over another flex
            if (draggingOverOtherFlexParent === null){
                setDuringMoveItemList(new_item_list)
            }


        }

    }


    const generate_bbox_data =() => {
        /*
            generating snapshot of bboxes
            whilst testing im making data keyed by item name as well as index
        */
        if (flexDivRef.current){
            // console.log('flexDivRef', flexDivRef)
            // let panel_width = flexDivRef.current.offsetWidth;
            let childBBoxes = {}
            let indexBBoxes = {}
            let flexChildData = {}
            let flex_rect = flexDivRef.current.getBoundingClientRect();
            let flexBbox = {
                left:flex_rect.left,
                right:flex_rect.right,
                top:flex_rect.top,
                bottom:flex_rect.bottom,
                width:flex_rect.width,
                height:flex_rect.height,
            }
            // console.log('flexDivRef children', flexDivRef.current.children)
            // console.log('flexDivRef children len', flexDivRef.current.children.length)

            for (let i = 0; i < flexDivRef.current.children.length; i++) {
                // console.log('child', flexDivRef.current.children[i]);
                let child_name = flexDivRef.current.children[i].id
                if (child_name === 'add_new_item') continue

                let rect = flexDivRef.current.children[i].getBoundingClientRect();
                // console.log('rect', rect)

                if (!flexChildData.hasOwnProperty(rect.top)){
                    flexChildData[rect.top] = []
                }

                let data_to_add = {
                    index:i,
                    item:child_name,
                    left:rect.left,
                    right:rect.right,
                    top:rect.top,
                    bottom:rect.bottom,
                    width:rect.width,
                    height:rect.height,
                }
                childBBoxes[child_name] = data_to_add
                indexBBoxes[i] = data_to_add
                flexChildData[rect.top].push(data_to_add)

          }
          setChildBBoxes(childBBoxes)
          setIndexBBoxes(indexBBoxes)
          setFlexChildData(flexChildData)
          setFlexBBox(flexBbox)
        //   console.log('childBBoxes', childBBoxes)
        }
    }

    const get_mouse_over_bbox_index = (e) => {
        let mousePos = [e.clientX, e.clientY]
        // console.log('get_mouse_over_bbox_item', childBBoxes)
        let flex_gap_half = flex_gap*.5

        let return_val = null
        if (!flexChildData){
            return 
        }

        // find out which row mouse is in. Loop through rows, first row top 
        // will be same as flex. So if i loop through all rows and stop when I get to 
        // a row_top which is below the mouse, then I can find which row I'm in.
        let which_row =Object.keys(flexChildData)[0];
        for (let row_top of Object.keys(flexChildData)){
            // console.log('xxxx', childBBoxes[child])
            if (mousePos[1] >= row_top-flex_gap_half) which_row = row_top
            else break
        }
        if (!which_row){
            return
        }
        // console.log('over this are these ordered START which_row', which_row )
        // console.log('over this are these ordered START', Object.keys(flexChildData) )
        // console.log('over this are XXXXX', Object.keys(flexChildData).length -1)

        for (let index = 0; index < flexChildData[which_row].length; index++) {
            const child_bbox_data = flexChildData[which_row][index];

            // if on top row set the top bbox to 0 (top of screen). If bottom, set to crazy high number
            // This will give behavior of allowing for finding closest hit when outside flex Bbox

            let leftbbox = child_bbox_data.left - flex_gap_half
            let rightbbox = child_bbox_data.right + flex_gap_half
            let topbbox = child_bbox_data.top - flex_gap_half
            let bottombbox = child_bbox_data.bottom + flex_gap_half

            if (which_row == Object.keys(flexChildData)[0]) topbbox = 0
            if (which_row == Object.keys(flexChildData)[Object.keys(flexChildData).length -1]) bottombbox = 10000000
            if (index === 0) leftbbox = 0
            if (index === flexChildData[which_row].length -1) rightbbox = 10000000

                if (   mousePos[0] >= leftbbox 
                    && mousePos[0] < rightbbox
                    && mousePos[1] >= topbbox
                    && mousePos[1] < bottombbox
                    ) {
                        return_val = child_bbox_data.index
                }
           
        }
       
        return return_val

    }


    const on_flex_mouse_down = (e) => {
        // console.log('on_flex_mouse_down')
        if (e.getModifierState("Alt")) {
            // console.log('on_flex_mouse_down alt', )

        }
        setClickedInUberFlex(true)

        generate_bbox_data()

        setMouseDownPos([e.clientX, e.clientY])
        setMousePos([e.clientX, e.clientY])
        setClicked('flexbox')
        e.stopPropagation()
    }
    const on_flex_click = (e) => {
        // console.log('on_flex_click')
        e.stopPropagation()
    }

    const populate_flex_parent_bboxes = () => {

        if (!isDragging) return null
        let flex_parents = document.getElementsByClassName(drop_target_class)
        // console.log('populate_flex_parent_bboxes', flex_parents)
        // console.log('populate_flex_parent_bboxes length', flex_parents.length)

        let return_flex_par_boxes = []
        for (let index = 0; index < flex_parents.length; index++) {
            const element = flex_parents[index];
            // console.log('DDDDDDD element', element)
            if (element.id === props.id){
                continue
            }

            // let element_is_master = false
            // if (element.id.startsWith('__ALL__')){
            //     // console.log('DDDD XX element', element)
            //     element_is_master = true
            //     continue;

            // }
            // let element_is_master = element.is_master_grp
            
            let flex_rect = element.getBoundingClientRect()
            // console.log('EEE flex_element flex_rect', flex_rect)

            let test_div_style = {
                top:flex_rect.top,
                left:flex_rect.left,
                width:flex_rect.width,
                height:flex_rect.height,
                opacity:0.0,
            }  

            // if (element_is_master){
            //     // console.log('DDDDD element_is_master', element)
            //     test_div_style['backgroundColor'] = 'red'
            // }


            if (element.id === draggingOverOtherFlexParent){
                test_div_style.opacity = 1
            }
        
            if (is_master_grp){
                // test_div_style['backgroundColor'] = 'blue'
                return_flex_par_boxes.push(
                    <div style={test_div_style}
                        className='flex-drop-target-parent'
                        onMouseOver={()=>{setDraggingOverOtherFlexParent(element.id)}}
                        onMouseLeave={()=>{setDraggingOverOtherFlexParent(null)}}
                    >
                        <div id={element.id+'-bbox2'} 
                            style={{left:'5px'}}
                            className='flex-drop-target-copy'
                            onMouseOver={()=>{setDraggingOverOtherFlexParent_copy(true)}}
                            onMouseLeave={()=>{setDraggingOverOtherFlexParent_copy(false)}}
                        >
                            Add Item
                        </div>
                    </div>
                )
            }
            else if (element.id.startsWith('__ALL__')){
                // test_div_style['backgroundColor'] = 'blue'
                return_flex_par_boxes.push(
                    <div style={test_div_style}
                        className='flex-drop-target-parent'
                        onMouseOver={()=>{setDraggingOverOtherFlexParent(element.id)}}
                        onMouseLeave={()=>{setDraggingOverOtherFlexParent(null)}}
                    >
                        <div id={element.id+'-bbox'} 
                            style={{right:'5px'}}
                            className='flex-drop-target-move'
                            onMouseOver={()=>{setDraggingOverOtherFlexParent_move(true)}}
                            onMouseLeave={()=>{setDraggingOverOtherFlexParent_move(false)}}
                        >
                            {'Remove Item from '+ name}
                        </div>
                    </div>
                )
            }
            else{

                return_flex_par_boxes.push(
                    <div style={test_div_style}
                        className='flex-drop-target-parent'
                        onMouseOver={()=>{setDraggingOverOtherFlexParent(element.id)}}
                        onMouseLeave={()=>{setDraggingOverOtherFlexParent(null)}}
                    >
                        <div id={element.id+'-bbox2'} 
                            className='flex-drop-target-copy'
                            onMouseOver={()=>{setDraggingOverOtherFlexParent_copy(true)}}
                            onMouseLeave={()=>{setDraggingOverOtherFlexParent_copy(false)}}
                        >
                            Add Item
                        </div>
    
                        <div id={element.id+'-bbox'} 
                            className='flex-drop-target-move'
                            onMouseOver={()=>{setDraggingOverOtherFlexParent_move(true)}}
                            onMouseLeave={()=>{setDraggingOverOtherFlexParent_move(false)}}
                        >
                            Move Item
                        </div>
                    </div>
                )
            }
        }

        
        return return_flex_par_boxes
        

    }


    let flex_parent_style = {}
    let flex_style =  {gap:flex_gap, padding:flex_padding, flexDirection:flex_direction}
    if (isDragging || afterDragPeriod){
        flex_parent_style = {
            // outline:'solid',
            // position:'fixed',
            // left:flexBBox.left,
            // // right:flexBBox.right,
            // top:flexBBox.top,
            // bottom:flexBBox.bottom,
            width:flexBBox.width,
            height:flexBBox.height,
        }
    }

    return (

        <div id={props.id} style={flex_parent_style} className="uber-flex-drop-target"
            onMouseOver={()=>{setDraggingOverMainFlexParent(true)}}
            onMouseLeave={()=>{setDraggingOverMainFlexParent(false)}}
            onClick={(e)=>{on_flex_click(e)}}
        >
            {/* {'draggingOverIndex'+ draggingOverIndex} */}
            {/* {'draggingOverOtherFlexParent'+ draggingOverOtherFlexParent} */}
            <div id={props.id+'-flex'} style={flex_style} className="uber-flex" ref={flexDivRef} 
                onMouseDown={(e) => {on_flex_mouse_down(e,)}}
            >
                {populate_flex()}
            </div>
            {/* {populate_dragging()} */}
            {populate_selection_box()}
            {/* {populate_bbox()} */}
            {populate_flex_parent_bboxes()}
        </div>
    )
    
}
