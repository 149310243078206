import React, { Component } from 'react';
import { connect} from 'react-redux';

import './AddItemButton.css';

import * as actionCreator_ui from '../../../../store/actions/actions_ui';

class AddItemButton extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClick_bg = this.handleClick_bg.bind(this);
        this.handleThreadPickerClick = this.handleThreadPickerClick.bind(this);
        this.state = {
            expanded:false,
        }

    }
    
    handleThreadPickerClick(event) {
        let panel_name = 'add_item_flow'
        let element_id = 'add_item_flow'
        this.props.open_foldout_panel(panel_name, element_id);
    }

    handleClick(event) {
        console.log('testttt')
        event.stopPropagation();
        event.preventDefault();
        let to_set = true;
        if (this.state.expanded){
            to_set = false;
        }
        this.setState({
            expanded:to_set
        })
    }
    handleClick_bg(event) {
        // console.log('testtttXXX')
        this.setState({
            expanded:false
        })
    }

    
    render() {
 
        let bg_style = {};
        let icon_style ={};
        let item_div_style ={};
        let show_time_controls = this.props.show_time_controls || false;
        let bottom_val = 40;
        if (show_time_controls){
            bottom_val = 190;
        }
        let btn_style = {bottom:bottom_val};

        if (this.state.expanded){
            // btn_style={
            //     width:'300px',
            //     height:'200px',
            //     opacity:.8,
            //     right:'10px',
            //     bottom:'10px',
            //     borderRadius: '15px',

            // }
            btn_style={
                opacity:0,
                bottom:bottom_val,
            }
            bg_style={
                backgroundColor:'rgba(0, 0, 0, .45)',
                pointerEvents: 'all',
                // display: 'block',
            }
            icon_style={
                opacity:0,
                // display:null,
            }
            item_div_style ={
                opacity:1,
                // bottom:'20px',
                
            }
        }
        else {
            item_div_style ={
                opacity:0,
                bottom:'-10px',
            }
            icon_style={
                opacity:1,
                // display:null,
            }
        }


        return (

            <div
                className='addItemButton_BG'
                style={bg_style}
                onClick={this.handleClick_bg}

            >
                <div
                    className='addItemButton_BTN'
                    style={btn_style}
                    //onClick={this.handleClick}
                    onClick={this.handleThreadPickerClick}
                    id='add_item_flow'
                >
                    <div
                        className='addItemButton_ICON'
                        style={icon_style}
                    >
                        +
                    </div>
                </div>
                <div style={item_div_style} className='add_item_children_div'>
                    {this.props.children}
                </div>
            </div>

        );
    };
}



const mapStateToProps = (state, props) => {
    return {
       
        // ui_data:state.ui_data,
        // timeline_data:state[props.vp_type + '_data'][props.vp_id],
        // start_time:state[props.vp_type + '_data'][props.vp_id].start_time,
        // end_time:state[props.vp_type + '_data'][props.vp_id].end_time,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //MOUSE DOWN

        // onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        // onMouseDown_TL: (event, ui_data, timeline_data) => dispatch(actionCreator_timeline.mouse_down_timeline(event, ui_data, timeline_data)),

        // mouse_wheel_timeline: (event, ui_data, timeline_data) => dispatch(actionCreator_timeline.mouse_wheel_timeline(event, ui_data, timeline_data)),
        open_foldout_panel: (panel_name, element_id) => dispatch(actionCreator_ui.open_foldout_panel(panel_name, element_id)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps )(AddItemButton);
// export default  AddItemButton;