const initial_props  = {
      
    prop_data:{
        // 'uid1':{
        //     name:'pistol',
        //     icon:"fas fa-wrench",
        //     description:'bang bang',
        //     links: {},

        // },
        // 'uid2':{
        //     name:'rope',
        //     icon:"fas fa-wrench",
        //     description:'get them doggies rollin',
        //     links: {},


        // },
        // 'uid3':{
        //     name:'candlestick',
        //     icon:"fas fa-wrench",
        //     description:'its covered in blood!',
        //     links: {},


        // },
    },
};

export default initial_props