import React, { Component } from 'react';
import './AttributePanel.css';
import ApCharacter from '../attribute_panel/ApCharacter';
import ApProp from '../attribute_panel/ApProp';
import ApLocation from '../attribute_panel/ApLocation';
import ApEvent from '../attribute_panel/ApEvent';
import ApRegion from '../attribute_panel/ApRegion';
import ApCard from '../attribute_panel/ApCard';
import ApGroup from '../attribute_panel/ApGroup';

import * as Constants from '../../constants/constants';


import { connect} from 'react-redux';

import * as actionCreator_mouse from '../../store/actions/actions_mouse';
import * as actionCreator_ui from '../../store/actions/actions_ui';


class AttributePanel extends Component {

    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleClickCollapse = this.handleClickCollapse.bind(this);
        this.handleClickExpand = this.handleClickExpand.bind(this);
        this.handleClickHistBack = this.handleClickHistBack.bind(this);
        this.handleClickHistForward = this.handleClickHistForward.bind(this);
        this.handleClickOptions = this.handleClickOptions.bind(this);
      }
    
      handleMouseDown(event) {
        this.props.onMouseDown(event, 'handle', 'attribute_panel');
      }
      handleClickCollapse(event) {
        this.props.onClickCollapse(false);
      }
      handleClickExpand(event) {
        this.props.onClickCollapse(true);
      }
      handleClickHistBack(event) {
        this.props.hist_back(event);
      }
      handleClickHistForward(event) {
        this.props.hist_forward(event);
      }
      handleClickOptions(event) {
        alert(
            '\n\nThis feature is not working yet.\nIn the future, it might bring up options for:\n\
            - add new attribute to item\n\
            - remove attributes\n\
        (these attributes might be anything relevant for the item such as character traits\n\
        or the amount of action or humour in a scene\n\
        There would be different types of attributes, eg:\n\
            - text field\n\
            - slider \n\
            - checkbox\n\
            etc..)'
            
        )
      }

    render () {
        let panel_to_add = null;
        let panel_heading = '';

        let cur_item_type= null;
        let cur_item_id= null;
        let sel_item_data = null;

        // TODO - if multiple selected, then bring up a panel with buttons to do things like group
        if (this.props.ui_data.control.selected){
            cur_item_id= Object.keys(this.props.ui_data.control.selected)[0]
            if (cur_item_id){
                cur_item_type= this.props.ui_data.control.selected[cur_item_id].item_type;
                if (Constants.VP_XFORM_SCALE_TYPES.includes(cur_item_type)){
                    cur_item_type = cur_item_type.slice(0, -6);
                }


                sel_item_data = this.props[cur_item_type + '_data'][cur_item_id];


            }
        }
        // do same as above but with history index
        // let hist_index = this.props.ui_data.sel_history_index;
        // let hist_sel = this.props.ui_data.sel_history[hist_index];

        // let cur_item_type= null;
        // let cur_item_id= null;
        // if (hist_sel){
        //     cur_item_type= hist_sel[0];
        //     cur_item_id= hist_sel[1];
        // }


        // if (cur_item_type){
            
        //     sel_item_data = this.props[cur_item_type + '_data'][cur_item_id];
        // }

        if (cur_item_type === 'character'){
            if (cur_item_id){
                panel_to_add = <ApCharacter item_data={sel_item_data} item_type={cur_item_type} item_id={cur_item_id}/>
                panel_heading = 'Character:'
            }
        }
        if (cur_item_type === 'prop'){
            if (cur_item_id){
                panel_to_add = <ApProp item_data={sel_item_data} item_type={cur_item_type} item_id={cur_item_id}/>
                panel_heading = 'Prop:'

            }
        }
        if (cur_item_type === 'location'){
            if (cur_item_id){
                panel_to_add = <ApLocation item_data={sel_item_data} item_type={cur_item_type} item_id={cur_item_id}/>
                panel_heading = 'Location:'

            }
        }
        if (cur_item_type === 'event'){
            if (cur_item_id){
                panel_to_add = <ApEvent item_data={sel_item_data} item_type={cur_item_type} item_id={cur_item_id}/>
                panel_heading = 'Event:'

            }
        }
        if (cur_item_type === 'card'){
            if (cur_item_id){
                panel_to_add = <ApCard item_data={sel_item_data} item_type={cur_item_type} item_id={cur_item_id}/>
                panel_heading = 'Card:'

            }
        }
        if (cur_item_type === 'region'){
            if (cur_item_id){
                panel_to_add = <ApRegion item_data={sel_item_data} item_type={cur_item_type} item_id={cur_item_id}/>
                panel_heading = 'Backdrop:'

            }
        }
        if (cur_item_type === 'group'){
            if (cur_item_id){
                panel_to_add = <ApGroup item_data={sel_item_data} item_type={cur_item_type} item_id={cur_item_id}/>
                panel_heading = 'Group:'

            }
        }

        let main_style_A = {
            width:this.props.attribute_panel_width,
        }

        let options_button_style = {
            display:'block',
        }        

        if (!this.props.attribute_panel_expanded){
            main_style_A['right'] = '-'+this.props.attribute_panel_width+100+'px';
            panel_to_add=null;
        
        }




        if (this.props.dragging[0] === 'handle'){
            main_style_A['transition'] = '0s';
        }
        let show_back = true;
        let show_forward = true;

        if (this.props.ui_data.sel_history_index === 0){
            show_forward = false;
        }
        if (this.props.ui_data.sel_history_index === this.props.ui_data.sel_history.length-1){
            show_back = false;
        }


        // add dissable pointer class based on dragging
        let disable_pointer_class = ''
        // if (this.props.dragging[0]){
        if (this.props.ui_data.control.begin_drag){
            disable_pointer_class = 'DisablPointer';
        }

        let handle_id = 'attr_editor_handle';
        let collapsed_handle_id = 'attr_editor_handle_collapsed';
        if (!this.props.attribute_panel_expanded){
            handle_id = 'attr_editor_handle_x';
            collapsed_handle_id = 'attr_editor_handle';
        }

        let experimental_mode = this.props.ui_data.settings.experimental_mode

        //let synopsis_text = "Jerry and his girlfriend get rejected from the Soup Nazi's kitchen when they're caught kissing in line. ... Kramer, who befriends the Soup Nazi, gets a new Armoire exactly like the one that was stolen from him."
        return (
            <div>
                
                
                <div id="MyAttributePanel"
                style={main_style_A}
                className = {disable_pointer_class}
                >
                    <div 
                        id={handle_id}
                        item_type='handle' 
                        item_id='attribute_panel'
                        //className={'WidthHandle ' + disable_pointer_class}
                        className={'WidthHandle '}
                        onMouseDown={this.handleMouseDown}
                        onClick={this.handleClickExpand}

                        />
                        <div className='AttrPanelHeader'>
                            <label>{panel_heading}</label>
                            <div id='HeaderSeparator' className='Separator' />

                        </div>
                        {/* <i class="fas fa-ellipsis-v" */}
                        {/* <i class="fas fa-exchange-alt" */}
                        {/* <i class="fas fa-grip-vertical"
                        

                        id='WidthHandleC' 
                        item_type='handle' 
                        item_id='attribute_panel'
                        onMouseDown={this.handleMouseDown}
                        onClick={this.handleClickExpand}

                        /> */}

                        {show_back && 

                            <i className={"fas fa-arrow-left " + disable_pointer_class} 
                            id='backButton' 
                            item_type='' 
                            style={options_button_style}
                            onClick={this.handleClickHistBack}
                            />
                        }
                        
                        {show_forward && 
                            <i className={ "fas fa-arrow-right " + disable_pointer_class}
                            id='forwardButton' 
                            item_type='' 
                            style={options_button_style}
                            onClick={this.handleClickHistForward}
                            />
                        }
                        {experimental_mode && 

                            <i className={ "fas fa-ellipsis-v " + disable_pointer_class} 
                            id='optionsButton' 
                            item_type='' 
                            style={options_button_style}
                            onClick={this.handleClickOptions}
                            />
                        }
                    <div className='AttrPanelScrollDiv'>
                        {panel_to_add}
                    </div>
                </div>
                <div
                    id={collapsed_handle_id}
                    className={ "collapsedDiv " + disable_pointer_class}
                    item_type='handle' 
                        item_id='attribute_panel'
                        onMouseDown={this.handleMouseDown}
                        onClick={this.handleClickCollapse}
                    >
                    <i className="fas fa-grip-vertical"
                        id='WidthHandleCollapsed' 
    
                    />
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        ui_data:state.ui_data,
        character_data:state.character_data,
        card_data:state.card_data,
        prop_data:state.prop_data,
        location_data:state.location_data,
        event_data:state.event_data,
        region_data:state.region_data,
        group_data:state.group_data,
        // current_sel:state.ui_data.current_sel,
        attribute_panel_width:state.ui_data.layout.attribute_panel_width,
        attribute_panel_expanded:state.ui_data.settings.attribute_panel_expanded,
        dragging:state.ui_data.control.dragging,
        sel_b:state.ui_data.control.selected,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        

        hist_back: (event) => dispatch(actionCreator_ui.sel_history_back(event)),
        hist_forward: (event) => dispatch(actionCreator_ui.sel_history_forward(event)),


        // onMouseDown: (event, item_type, item_id  ) => dispatch({type:'ON_MOUSE_DOWN',  item_id:item_id, item_type:item_type, event:event}),
        onClickCollapse: (expanded) => dispatch({type:'ATTRIBUTE_PANEL_COLLAPSE',  expanded:expanded}),
        // onMouseDown: (event, item_type, item_id  ) => dispatch({type:'ON_MOUSE_DOWN',  item_id:item_id, item_type:item_type, event:event}),
        // onClickCollapse: (expanded) => dispatch({type:'ATTRIBUTE_PANEL_COLLAPSE',  expanded:expanded}),

    }
};

export default connect(mapStateToProps, mapDispatchToProps )(AttributePanel);
