
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator_auth from '../../store/actions/actions_auth';
import * as actions_users from '../../store/actions/actions_users';
import { Link, useHistory } from 'react-router-dom';

import './UserSettings.css';

// import firebase from "firebase";

import InputField from '../ui_elements/inputs/InputField';
import Button from '../ui_elements/inputs/Button';
import Checkbox from '../ui_elements/inputs/Checkbox';
import FilePicker from '../ui_elements/inputs/FilePicker';
import SelectMenu from '../ui_elements/inputs/SelectMenu';
import * as storage_utils from '../../utils/storage_utils'
import * as Constants from '../../constants/constants';




// ...later


export default function UserSettings() {

    const [name, setName] = useState()
    const [loading, setLoading] = useState()
    const { currentUser} = useAuth()
    const user_id = currentUser.uid;

    const dispatch =useDispatch()
    let user_data = useSelector( state => state.user_data)

    let user_storage_used =0
    if (user_data[user_id].hasOwnProperty('storage_used'))
    {
        user_storage_used = user_data[user_id].storage_used;
    }
    let user_storage_used_readable = storage_utils.humanFileSize(user_storage_used)
    let user_storage_limit = storage_utils.humanFileSize(Constants.USER_STORAGE_BYTE_LIMIT) 
    let storage_used_percent = 100 * user_storage_used / Constants.USER_STORAGE_BYTE_LIMIT

    // console.log('ff - user_storage_used', user_storage_used)
    
    function handleSubmit(e) {
        console.log('UserSettings handleSubmit ', currentUser)
        // dispatch(actionCreator_auth.sign_in_user(email, password))
    }
    function reset_user_guides(e) {
        console.log('UserSettings reset_user_guides ', user_id)
        dispatch(actions_users.reset_user_guides(user_id))
    }



    function progress_cb(progress) {
        console.log('CB prog ', progress)
        // this.props.upload_progress(progress)
        // this.setState({progress:progress})

    }
    function complete_cb(url) {
        console.log('CB complete ', url)
        // let dataTarget = 'character_data.'+this.uid+'.image'
        // this.props.upload_complete(url)
        // this.props.close_modal()

        // put thing in here to close ui (or close and let upload happen while app is active)
        // this.props.extra_data.cancel_function(event, "ui_data.openModalWindow."+this.props.extra_data.modal_name, null)
    }
    
    function filechange_cb(file, url) {
        console.log('CB filechange_cb ', file, url )
        // this.props.file_change()
    }
    
    function onChange_experimental(value_to_set,) {
        console.log('onChange_experimental value_to_set', value_to_set)
        console.log('onChange_experimental user_id', user_id)

        dispatch(actions_users.set_experimental_mode(user_id, value_to_set))

    }
    return (

        <div  className="UserSettingsDiv">
            <div className='UserSettingsTopCentreArea'>

                <FilePicker 
                    // doUpload={this.state.doUpload} 
                    //storage_path='test/aaa'
                    storage_path={'user_data/'+user_id}
                    file_change_callback={filechange_cb}
                    progress_callback={progress_cb}
                    complete_callback={complete_cb}
                    // nothing_picked_callback={props.close_modal}
                    //dataTarget='my_items.uid1.image'
                    // dataSource={'user_data.'+user_id+'.image'}
                    dataSource={'user_data.'+user_id+'.image'}
                    dataTarget={'user_data.'+user_id+'.image'}
                    placeholder_image_data = {['icon', 'fas fa-user']}
                />
            </div>
            <div className='UserSettingsCentreArea'>

                {currentUser && currentUser.email}
                {/* {auth_message_element} */}
                <div >
                    {'storage used: ' + user_storage_used_readable +' / '+user_storage_limit}
                    {' (' + storage_used_percent.toFixed(1) + '% )'}
                </div>
                <InputField 
                    inputType='name' 
                    // style={{display: "block"}} 
                    id='email' 
                    label='Full Name' 
                    do_set_db_data={true}
                    // onChangeFunction={e => setName(e.target.value)}
                    dataSource={'user_data.'+user_id + '.name'}
                    dataTarget={'user_data.'+user_id + '.name'}
                    // value={name}
                />
                <SelectMenu
                    dataSource={'user_data.'+user_id + '.gender'}
                    dataTarget={'user_data.'+user_id + '.gender'}
                    item_list={['', 'Male', 'Female', 'Other']}
                    label='Gender'
                    style={{position:"relative"}} 

                />
                <InputField

                    dataSource={'user_data.'+user_id + '.dob'}
                    dataTarget={'user_data.'+user_id + '.dob'}
                    inputType='date' 
                    style={{position:"relative"}} 
                    // id='AageField' 
                    label='Date of Birth' 
                />

                <Checkbox  
                    dataSource={'user_data.'+user_id + ".show_guides"} 
                    dataTarget={'user_data.'+user_id + ".show_guides"} 
                    // onChangeFunction={e => setName(e.target.value)}
                    label='Show Guide Popups'
                />
                <Button 
                    onClickFunction={reset_user_guides}
                    label='Reset Guide Popups' 
                    // style={{width:"140px", top:"20px", margin:'auto'}}
                />
                <Checkbox  
                    dataSource={'user_data.'+user_id + ".experimental_mode"} 
                    dataTarget={'user_data.'+user_id + ".experimental_mode"} 
                    onChangeFunction={onChange_experimental}
                    label='Enable Experimental Mode'
                />
                {/* <Checkbox  
                    dataSource={'ui_data.settings.experimental_mode'} 
                    dataTarget={'ui_data.settings.experimental_mode'} 
                    // onChangeFunction={onChange_experimental}
                    label='Enable Experimental ModeB'
                /> */}

            </div>

        </div>
    )
}
