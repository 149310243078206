
const get_mouse_pos_in_viewport = (main_panel_pos, viewport_pos, viewport_scale, mouse_pos) => {
    /*
    Simple function to get mouse position in viewport co-ords.
    Probably a smarter way of ding this -eg pass in event and panel div id or something?
    ATM this requires a lot of input.
    */
    let viewportPos_cursor_X = Math.round((mouse_pos[0] - main_panel_pos[0]  -  viewport_pos[0]) / viewport_scale);
    let viewportPos_cursor_Y = Math.round((mouse_pos[1] - main_panel_pos[1] -  viewport_pos[1]) / viewport_scale);

    return ([viewportPos_cursor_X, viewportPos_cursor_Y])

}
export default get_mouse_pos_in_viewport;
