import React, { Component } from 'react';

import { connect} from 'react-redux';
import './TimeControl.css';

import InputField from '../../inputs/InputField';
import TimeControlKey from './TimeControlKey';

import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
import * as actionCreator_time_control from '../../../../store/actions/actions_time_control';
import * as actionCreator_time_control_keys from '../../../../store/actions/actions_time_control_keys';
import * as actionCreator_cache from '../../../../store/actions/actions_cache';


class TimeControl extends Component {

    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseWheel = this.handleMouseWheel.bind(this);
        this.handleOnKeyMouseDown = this.handleOnKeyMouseDown.bind(this);
        this.handleOnKeyDoubleClick = this.handleOnKeyDoubleClick.bind(this);

      }
    
    handleMouseDown(event) {

        this.props.onMouseDown(event, 'timeControl', this.props.current_tl,);
        // this.props.onMouseDown_timeline(event, this.props.ui_data, this.props.timeline_data);
        this.props.onMouseDown_timeControl(event, this.props.ui_data, this.props.timeline_data);

    }

    handleMouseWheel(event) {
        this.props.onMouseWheel_timeControl(event, this.props.ui_data, this.props.timeline_data);
    }


    handleOnKeyMouseDown(event, key_time, item_id, item_type, attr_parent, attr_id){
        event.stopPropagation();

        let current_sel = this.props.ui_data.control.selected_keys;
        let current_sel_keys = this.props.ui_data.control.selected_keys;
        let project_id = this.props.ui_data.current.current_project;
        let timeline = this.props.ui_data.current.current_timeline;
        console.log('handleOnKeyMouseDown current_sel_keys', current_sel_keys)
        console.log('handleOnKeyMouseDown project_id', project_id)
        console.log('handleOnKeyMouseDown timeline', timeline)
        console.log('handleOnKeyMouseDown key_time', key_time)
        console.log('handleOnKeyMouseDown item_id', item_id)
        console.log('handleOnKeyMouseDown item_type', item_type)
        console.log('handleOnKeyMouseDown attr_parent', attr_parent)
        console.log('handleOnKeyMouseDown attr_id', attr_id)
        this.props.onMouseDown(event, 'timeControl_key', this.props.current_tl,);

        // get items from state to update cache
        let item = this.props[item_type+'_data'][item_id];
        console.log('handleOnKeyMouseDown item', item)
        
        let items_and_ids = [[item, item_id, item_type]];
        console.log('handleOnKeyMouseDown items_and_ids', items_and_ids)

        this.props.update_keyframes_cache(items_and_ids, this.props.current_tl);
        this.props.mouse_down_tc_keys ( this.props.ui_data, this.props.cache_data, item_type, item_id, attr_id, attr_parent, key_time)
    }

    handleOnKeyDoubleClick(event, key_time){
        event.stopPropagation();
        let timeline = this.props.ui_data.current.current_timeline;
        console.log('handleOnKeyDoubleClick key_time', key_time, timeline)

        this.props.go_to_time (key_time, timeline)
    }
    
    populateControlKeys(){

        let hovering = this.props.ui_data.control.hovering;
        let selected_keys = this.props.ui_data.control.selected_keys;
        let is_dragging = false;
        if(this.props.ui_data.control.dragging[0]){
            is_dragging=true;
        }

        let controlKeyComponents = [];
        if (this.props.cache_data.hasOwnProperty('key_data')){
            if (this.props.cache_data['key_data'].hasOwnProperty(this.props.current_tl)){

                let key_data = this.props.cache_data['key_data'][this.props.current_tl];
                


                for (let index = 0; index < Object.keys(key_data).length; index++) {
                    const item_id = Object.keys(key_data)[index];
                    let item = key_data[item_id]['item'];
                    let item_type = key_data[item_id]['item_type'];

                    for (let i = 0; i < Object.keys(key_data[item_id]).length; i++) {
                        const attr_parent = Object.keys(key_data[item_id])[i];

                        // storing item alonside attr parent keys, so skip
                        if (attr_parent === 'item') continue;
                        if (attr_parent === 'item_type') continue;

                        for (let ii = 0; ii < Object.keys(key_data[item_id][attr_parent]).length; ii++) {
                            const attr_id = Object.keys(key_data[item_id][attr_parent])[ii];

                            let key_times = Object.keys(key_data[item_id][attr_parent][attr_id])
                            for (let iii = 0; iii < key_times.length; iii++) {
                                const key_time_key = key_times[iii];
                                const key_time = key_data[item_id][attr_parent][attr_id][key_time_key][0];
                                // const key_val = key_data[item_id][attr_parent][attr_id][key_time_key][1];

                                let is_hovering_class = '';
                                if (hovering[0] === attr_parent){
                                    if (hovering[1] === attr_id){
                                        is_hovering_class='KeyAttrHover';
                                    }
                                    else{
                                        is_hovering_class='KeyAttrNotHover'; 
                                    }
                                }
                                let key_key = item_id+'-'+this.props.current_tl+'-'+attr_id+'-'+key_time;
                                let key_key_b = item_id+'-'+this.props.current_tl+'-'+attr_id+'-'+key_time_key;
                                let is_selected = false;
                                if (Object.keys(selected_keys).includes(key_key_b)){
                                    is_selected=true;
                                }
                                controlKeyComponents.push(
                                    <TimeControlKey 
                                        key_time={key_time} 
                                        extraClass={is_hovering_class}
                                        mouseDownFunction={this.handleOnKeyMouseDown}
                                        doubleClickFunction={this.handleOnKeyDoubleClick}
                                        item={item}
                                        item_id={item_id}
                                        item_type={item_type}
                                        attr_parent={attr_parent}
                                        attr_id={attr_id}
                                        // timeline={timeline}
                                        key={key_key}
                                        is_selected={is_selected}
                                        is_dragging={is_dragging}
                                    />
                                )
                            }
                        }
                    }
                }
            }
        }
        

        return controlKeyComponents;
    }

    render() {
 
        let current_time_left = Math.round(.5 * (this.props.main_panel_right - this.props.main_panel_left) - 100);

        let bottom_val = '0px';
        let opacity_val = 1;
        if (!this.props.ui_data.settings.show_time_controls){
            bottom_val = '-130px';
            opacity_val = 0;
        }
        return (

            <div className='TimeLineControlDiv'
                item_id={this.props.current_tl}
                item_type='timeControl'
                style={{position:'absolute', opacity:opacity_val, bottom:bottom_val, padding:'10px ' + this.props.time_control_margin+'px' }}
            > 
                <div className='TimeLineControl'
                onMouseDown={this.handleMouseDown}
                //onMousMove={this.handleMouseMove}
                onWheel={this.handleMouseWheel}
                >
                    <div className='TimeCursor' 
                        style={{left:'calc('+this.props.current_time + '% - 4px)'}}
                    />
                    {this.props.ui_data.settings.show_time_controls &&
                        this.populateControlKeys()
                    }
                    {/* <TimeControlKey/> */}
                    {/* <div className='TestCard' /> */}
                </div>
                <InputField 
                    dataSource={"timeline_data."+this.props.current_tl+".current_time"}
                    dataTarget={"timeline_data."+this.props.current_tl+".current_time"}

                    inputType='number' 
                    style={{transition:'0s', position:"absolute",  left:current_time_left+'px', width:'200px', bottom:'25px'}} 
                    id='xxx' 
                    label='Time' 
                    //className='TimeIntField'
                />
                <InputField 
                    dataSource={"timeline_data."+this.props.current_tl+".start_time"}
                    dataTarget={"timeline_data."+this.props.current_tl+".start_time"}

                    inputType='number' 
                    style={{position:"absolute", left:'50px', width:'200px', bottom:'25px'}} 
                    id='xxvvx' 
                    label='Start time' 
                    //className='TimeIntField'
                />
                <InputField 
                    dataSource={"timeline_data."+this.props.current_tl+".end_time"}
                    dataTarget={"timeline_data."+this.props.current_tl+".end_time"}

                    inputType='number' 
                    style={{position:"absolute", right:'50px', width:'200px', bottom:'25px'}} 
                    id='xaxx' 
                    label='End time' 
                    //className='TimeIntField'
                />
            </div>

        );
    };
}



const mapStateToProps = (state) => {
    let current_tl = state.ui_data.current.current_timeline;
    return {

        ui_data:state.ui_data,
        cache_data:state.cache_data,
        current_time:state.timeline_data[current_tl].current_time,
        start_time:state.timeline_data[current_tl].start_time,
        end_time:state.timeline_data[current_tl].end_time,
        time_control_margin:state.ui_data.layout.time_control_margin,
        main_panel_left:state.ui_data.layout.main_panel_left,
        main_panel_right:state.ui_data.layout.main_panel_right,

        timeline_data:state.timeline_data[current_tl],
        current_tl:current_tl,

        character_data:state.character_data,
        prop_data:state.prop_data,
        location_data:state.location_data,
        card_data:state.card_data,
        
   }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        // onMouseDown_timeline: (event, ui_data, tl_data) => dispatch(actionCreator_timeline.mouse_down_timeline(event, ui_data, tl_data)),
        onMouseDown_timeControl: (event, ui_data, tl_data) => dispatch(actionCreator_time_control.mouse_down_time_contol (event, ui_data, tl_data)),
        go_to_time: (time, timeline) => dispatch(actionCreator_time_control.go_to_time (time, timeline)),
        onMouseWheel_timeControl: (event, ui_data, card_item_data) => dispatch(actionCreator_time_control.mouse_wheel_time_control(event, ui_data, card_item_data)),
        mouse_down_tc_keys: ( ui_data, cache_data, item, item_type, item_id, attr_id, attr_parent, key_time) => dispatch(actionCreator_time_control_keys.mouse_down_tc_keys( ui_data, cache_data, item, item_type, item_id, attr_id, attr_parent, key_time)),
        update_keyframes_cache: (items_and_ids, timeline) => dispatch(actionCreator_cache.update_keyframes_cache(items_and_ids, timeline)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(TimeControl);

