import React, { Component } from 'react';
import { connect} from 'react-redux';
import './FoldoutPanel.css';

import * as utils from '../../../../utils/misc_utils'
import * as actionCreator_ui from '../../../../store/actions/actions_ui';

class FoldoutPanel extends Component {
    constructor(props) {
        super(props);
        this.panelDivRef = React.createRef()
        // this.handleUserIconClick = this.handleUserIconClick.bind(this);
        // // this.handleChangeUserClick = this.handleChangeUserClick.bind(this);
        // this.handleLogOutUserClick = this.handleLogOutUserClick.bind(this);
        // this.fileSelectHandler = this.fileSelectHandler.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleClickInside = this.handleClickInside.bind(this);
    }

    handleClickOutside(event) {
        event.stopPropagation();

        // console.log('UUU handleClickOutside')
        if (this.props.clickOutsideFunction){
            this.props.clickOutsideFunction();
        }
        else{
            this.props.close_all_foldout_panels()
        }
    }
    handleClickInside(event) {
        event.stopPropagation();

        // console.log('UUU handleClickInside')
        // this.props.close_user_panel()
        if (this.props.clickInsideFunction){
            this.props.clickInsideFunction();
        }
    }
    render(){
        let panel_name = this.props.panel_name;
        let element_id = this.props.element_id;
        let bbox = utils.get_element_bbox(element_id)
        
        // bbox is null, then element doesn't exist so stop here
        if (bbox === null){
            return null
        }

        // let panel_width = this.props.panel_width || 150;
        // let panel_height = this.props.panel_height || 200;
        let panel_width = this.props.panel_width;
        let panel_height = this.props.panel_height;
        let panel_style= this.props.panel_style || {};

        if (!panel_width){
            if (this.panelDivRef.current){
                panel_width = this.panelDivRef.current.offsetWidth;
            }
        }
        if (!panel_height){
            if (this.panelDivRef.current){
                panel_height = this.panelDivRef.current.offsetHeight;
            }
        }
        // let panel_height = this.props.panel_height;
        let panel_side = this.props.panel_side || 'bottom';
        let arrow_class_name = 'null';
        let panel_offset = this.props.panel_offset || 0;


        let panel_placement_style ={}
        let arrow_placement_style ={}
        // let panel_width = 100;
        // let panel_height = 2;
        let testBBoxDiv_style = {};
        
        // let dark_bg = this.props.dark_bg || true;
        let bg_style = {};
        if (this.props.dark_bg === false){
            bg_style['backgroundColor'] = 'rgba(0,0,0,0)';
            bg_style['animationName'] = 'none';
        }

        // let is_modal = true;
        // if ( this.props.modal === undefined){ is_modal = false}
        // else { is_modal = this.props.modal}

        if (this.props.modal === false){
            // bg_style['backgroundColor'] = 'rgba(200,0,0,1)';
            bg_style['pointerEvents'] = 'none';
        }
        let hoverClass = '';
        if (this.props.do_hover){
            hoverClass = 'FoldoutPanelDiv-hover';
        }
        let darkModeClass = '';
        if (this.props.dark_mode){
            darkModeClass = 'foldoutDarkMode';
        }



        if (bbox){
            // panel_height = 200;
            // let left_arrow = ((bbox['left'] + bbox['right']) * .5) -10;

            // console.log(' FoldoutPanel  -   left_arrow', left_arrow)
            let arrow_width = 6;
            if (this.props.show_arrow){
                arrow_width = 14;
            }
            // let arrow_offset = 6;
            let arrow_offset = 0;
            let mid_horizontal = ((bbox['left'] + bbox['right']) * .5);
            let mid_vertical = ((bbox['top'] + bbox['bottom']) * .5);


            if (panel_side === 'bottom'){
                arrow_class_name = 'FoldoutArrowUp';
                panel_placement_style ={
                    left:mid_horizontal - (panel_width*.5) + panel_offset, 
                    top:bbox['bottom'] + arrow_width + arrow_offset,
                    width:panel_width,
                    height:panel_height,
                    animationName: 'scale_' + panel_side,
                }
    
                arrow_placement_style ={
                    left:mid_horizontal - arrow_width, 
                    top:bbox['bottom'] + arrow_offset,
                }
            }
            if (panel_side === 'top'){
                arrow_class_name = 'FoldoutArrowDown';
                panel_placement_style ={
                    left:mid_horizontal - (panel_width*.5) + panel_offset, 
                    top:bbox['top'] - panel_height - arrow_width - arrow_offset,
                    width:panel_width,
                    height:panel_height,
                    animationName: 'scale_' + panel_side,
                }
    
                arrow_placement_style ={
                    left:mid_horizontal - arrow_width, 
                    top:bbox['top'] - arrow_width - arrow_offset,
                }
            }
            if (panel_side === 'left'){
                arrow_class_name = 'FoldoutArrowRight';
                panel_placement_style ={
                    top:mid_vertical - (panel_height*.5) + panel_offset, 
                    left:bbox['left'] - panel_width - arrow_width - arrow_offset,
                    width:panel_width,
                    height:panel_height,
                    animationName: 'scale_' + panel_side,
                }
    
                arrow_placement_style ={
                    top:mid_vertical  - arrow_width, 
                    left:bbox['left'] - arrow_width - arrow_offset,
                }
            }
            if (panel_side === 'right'){
                arrow_class_name = 'FoldoutArrowLeft';
                panel_placement_style ={
                    top:mid_vertical - (panel_height*.5) + panel_offset, 
                    left:bbox['right']  + arrow_width + arrow_offset,
                    width:panel_width,
                    height:panel_height,
                    animationName: 'scale_' + panel_side,
                }
    
                arrow_placement_style ={
                    top:mid_vertical  - arrow_width,
                    left:bbox['right'] + arrow_offset,
                }
            }
            if (panel_side === 'center'){
                arrow_class_name = '';
                panel_placement_style ={
                    top:mid_vertical - (panel_height*.5) + panel_offset, 
                    left:mid_horizontal - (panel_width*.5), 
                    width:panel_width,
                    height:panel_height,
                    animationName: 'scale_' + panel_side,
                }
    
                arrow_placement_style ={
                    top:mid_vertical  - arrow_width,
                    left:bbox['right'] + arrow_offset,
                }
            }
            if (panel_side === 'center_top'){
                arrow_class_name = '';
                panel_placement_style ={
                    top:bbox['top'] + panel_offset, 
                    left:mid_horizontal - (panel_width*.5), 
                    width:panel_width,
                    height:panel_height,
                    animationName: 'scale_' + panel_side,
                }
    
                arrow_placement_style ={
                    top:mid_vertical  - arrow_width,
                    left:bbox['right'] + arrow_offset,
                }
            }

            testBBoxDiv_style ={
                left:bbox['left'], 
                top:bbox['top'],
                width:bbox['width'],
                height:bbox['height'],
            }


        }
        return (
            <div key={panel_name + '_bg'} style={bg_style} className="FoldoutPanelBG"  onClick={this.handleClickOutside}>
                {this.props.show_arrow && 
                    <div  style={arrow_placement_style} className={arrow_class_name}/>
                }
                <div key={panel_name + '_div'} ref={this.panelDivRef} style={Object.assign({}, panel_placement_style, panel_style)} onClick={this.handleClickInside} className={"FoldoutPanelDiv "+ hoverClass + ' '+ darkModeClass}>
                    {this.props.children}
                </div>
                {/* <div style={testBBoxDiv_style}  className='testBBoxDiv' /> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      ui_data:state.ui_data,
    //   user_data:state.user_data,
    //   context_data:state.context_data,
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        close_all_foldout_panels: () => dispatch(actionCreator_ui.close_all_foldout_panels()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FoldoutPanel);
