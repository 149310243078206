
/*
Blocking in matching the actions i have currently in reducer_ui.
arguably I should split this into mode_panel, main_panel, attr_panel, banner_panel etc...


*/

import * as actions_story_items from './actions_story_items';
import * as actions_projects from './actions_projects';
import * as actions_database from './actions_database';

import new_uid from '../../utils/new_uid';
import templates from '../templates/templates';
import * as utils from '../../utils/misc_utils'


import fullScreenToggle from '../../utils/fullScreenToggle'
// import * as actions_utility from './actions_utility'

// Banner actions

export function full_screen_toggle() {
    //AAA console.log('this is fullscreen action')
    // do i need this to be structured like this?
    return () =>  {
        fullScreenToggle();
    }
    
}

// This is a test for actions_utility.trigger_action_array
// used to trigger multiple actions from a singel action creator. not sure if this is a smart way to do this?
// export function full_screen_toggle() {
//     //AAA console.log('this is fullscreen action')
//     // do i need this to be structured like this?
//     let action_array = [
//         {'type':'TEST_A', blah:25},
//         {'type':'TEST_B', blah:'bob'},
//         {'type':'TEST_C', something:true},
//     ]

//     return (dispatch) =>  { actions_utility.trigger_action_array(dispatch, action_array) };
    
// }



// mode panel on left actions 
export const mode_panel_set_mode = (mode, ui_data) => {
    return dispatch => {

        // if switching between certain modes store vp selection and restore from new mode
        // if (mode === 'flow' || mode === 'map'){
        if (ui_data.open_viewport.hasOwnProperty(mode + '_viewport')){

            let stored_vp_id = ui_data.open_viewport[mode + '_viewport'];

            dispatch( { 
                type: "SET_CURRENT_VP", 
                vp_type:mode + '_viewport',
                vp_id:stored_vp_id,
            })
        }
        //AAA console.log('AC mode_panel_set_mode', mode )
        dispatch( { 
            type: "SET_MODE", 
            mode:mode,
        })
    }    
};

export const mode_panel_expand_toggle = (mode_panel_expanded) => {
    //AAA console.log('AC mode_panel_expand_toggle')
    return { type: "MODE_PANEL_EXPAND_TOGGLE"   };
};

export const set_main_panel_dimenions = (width, height) => {
    return { type: "SET_WINDOW_DIMENSIONS", payload:{width:width, height: height} };
};



// this stuff is for modal create panel
export const open_modal_window = (modal_name, input_data) => {
    // console.log('AC open_modal_window', modal_name, input_data )
    return { type: "OPEN_MODAL_WINDOW", modal_name:modal_name, input_data:input_data} ;
};



// TODO resolve new_story_item_modal_create vs new_story_item_modal_create
// TODO replace ON_SELECT with new SELECT_ITEM_C (i think?)

// this is an alternate version without event and with uid passed in
export const new_story_item_modal_create = (uid,  project_id, item_data, item_type, group_id=null) => {
    // console.log('AC new_story_item_modal_create', uid, project_id, item_data, item_type,)
    // console.log('AC new_story_item_modal_create item_data', item_data)
    // console.log('AC new_story_item_modal_create group_id', group_id)

    // need to update action to action.payload etc...
    return dispatch => {
        let template_data = {...templates[item_type]};


        let data_to_set = Object.assign({}, template_data, item_data)
        data_to_set['creation_date'] = new Date().toUTCString();


        // dispatch( actions_story_items.create_item(  project_id, item_type, uid, data_to_set))
        dispatch( actions_story_items.create_item_with_undo(  project_id, item_type, uid, data_to_set, group_id, true))


        dispatch( { 
            type: "SELECT_ITEM_C", 
            data: {[uid]:{item_type:item_type}},
            select_mode: 'replace',
        })

    }
};



export const new_project_modal_create = (context, user_id, project_name) => {
    return dispatch => {

        // dispatch( actions_projects.create_new_project( project_data, user_id, project_name))
        dispatch( actions_projects.create_new_project(context, user_id, project_name))
        dispatch( { 
            type: "CLOSE_MODAL_WINDOW", 
            modal_name:'new_project',
        })
        // dispatch( { 
        //     type: "ON_SELECT", 
        //     item_type: item_type,
        //     item_id: uid,
        // })
    }
};

export const modal_link_item = (item_data, item_type, item_id, picked_items, to_link_type, project_id) => {
    // console.log('AC modal_link_item', item_data, item_type, item_id, picked_items, to_link_type,)

    return dispatch => {

        let to_link_ids = [];
        for (let i = 0; i < picked_items.length; i++) { 
            // if (picked_items[i][0] === item_type && picked_items[i][1] === item_id) {
                // if found, then remove this index's item from array and break loop
                to_link_ids.push(picked_items[i][1]);
            // }
        }

        // actions_story_items.link_items(item_data, item_type, item_id, 'document', [uid])
        dispatch(actions_story_items.link_items(item_data, item_type, item_id, to_link_type, to_link_ids, project_id))
        dispatch( { 
            type: "CLOSE_MODAL_WINDOW", 
            modal_name:'link_items',
        })

    }
}


// open/close user settings foldout panel
export const open_user_panel = () => {
    // console.log('AC open_user_panel',)
    return { type: "OPEN_USER_PANEL"} ;
};
export const close_user_panel = () => {
    // console.log('AC close_user_panel',)
    return { type: "CLOSE_USER_PANEL"} ;
};



// attribute panel stuff
export const attribute_panel_expand_toggle = () => {
    // need to update action to action.payload etc...
    return { type: "ATTRIBUTE_PANEL_COLLAPSE", payload:{} };
};
export const sel_history_back = (event) => {
    event.stopPropagation();

    // console.log('AC sel_history_back', )
    return { type: "SEL_HISTORY_BACK", payload:{} };
};
export const sel_history_forward = (event) => {
    event.stopPropagation();

    // console.log('AC sel_history_forward', )
    return { type: "SEL_HISTORY_FORWARD", payload:{} };
};




export const select_item = (item_type, item_id) => {
    // console.log('AC select_item', item_type, item_id)

    // need to update action to action.payload etc...

    return { type: "ON_SELECT", item_type:item_type,  item_id:item_id };
};


// export const select_item_b = (item_type, item_id) => {
//     // need to update action to action.payload etc...

//     return { type: "SELECT_ITEM_B", item_type:item_type,  item_id:item_id, select_mode:'replace' };
// };
export const select_item_c = (event, data) => {
    // console.log('AC select_item_c', event, data)

    let key_down = event.key;
    let select_mode = 'replace';

    if (event.getModifierState("Control")) { 
        select_mode = 'toggle';
    }
    if (event.getModifierState("Shift")) { 
        select_mode = 'add';
        // console.log('GOT SHIFT!!!')
    }
    if (event.getModifierState("Alt")) { 
        select_mode = 'remove';
        // console.log('GOT ALT!!!')

    }

    return { type: "SELECT_ITEM_C", data:data,  select_mode:select_mode};
};

export const select_items = (data, select_mode='replace') => {
    // console.log('AC select_items',  data)

    return { type: "SELECT_ITEM_C", data:data,  select_mode:select_mode};
};


// export const select_item_array = (items_data) => {
//     // need to update action to action.payload etc...

//     return { type: "ON_SELECT_B", item_type:item_type,  item_id:item_id,  item:item };
// };
// export const set_current_document = (doc) => {
//     // need to update action to action.payload etc...

//     return { type: "SET_CURRENT_DOCUMENT", document:doc};
// };


export const pick_item_toggle = (ui_data, item_type, item_id) => {
    // console.log('AC pick_item_toggle', ui_data, item_type, item_id)

    let picked_items = [...ui_data.control.picked_items];
    // console.log('picked_items', picked_items)

    let do_add = true;
    for (let i = 0; i < picked_items.length; i++) { 
        if (picked_items[i][0] === item_type && picked_items[i][1] === item_id) {
            // if found, then remove this index's item from array and break loop
            picked_items.splice(i, 1);
            do_add = false
            break;
        }
        
    }
    if(do_add){
        picked_items.push([item_type, item_id]);
    }
    return { type: "SET_PICKED_ITEMS", picked_items:picked_items};
};




// tree view actions
export const toggle_tree_item_expanded = (event, item_id, tree_name, ui_data) => {
    // console.log('AC toggle_tree_item_expanded',event, item_id, tree_name, ui_data)

    let expand_mode = false;
    // test if expanded data exists- if so then get what it is and I'll toggle it when i send to reducer
    if (ui_data.tree_view_data.hasOwnProperty(tree_name)){
        // expand_mode = true;
        if (ui_data.tree_view_data[tree_name].hasOwnProperty('expanded')){
            if (ui_data.tree_view_data[tree_name].expanded.hasOwnProperty(item_id)){
                expand_mode = ui_data.tree_view_data[tree_name].expanded[item_id];
            }
        }
    }

    return { 
        type: "SET_TREE_EXPANDED", 
        item:item_id,
        expand_item:!expand_mode,
        tree_name:tree_name,
    }
};



// foldout panel actions
export const open_foldout_panel = ( panel_name, element_id, extra_data=null) => {
    // console.log('AC open_foldout_panel', panel_name, element_id, extra_data)
    return { 
        type: "OPEN_FOLDOUT_PANEL", 
        panel_name:panel_name,
        element_id:element_id,
        extra_data:extra_data,
    }
};

export const close_all_foldout_panels = () => {
    // console.log('AC open_foldout_panel', )
    return { 
        type: "CLOSE_ALL_FOLDOUT_PANELS", 
    }
};


export const show_time_controls_toggle = () => {
    // console.log('AC show_time_controls_toggle')
    return { type: "SHOW_TIME_CONTROLS_TOGGLE"   };
};

export const show_testing_panel_toggle = () => {
    // console.log('AC show_testing_panel_toggle')
    return { type: "SHOW_TESTING_PANEL_TOGGLE"   };
};



export const set_hotkey_signal = (key) => {
    // console.log('AC set_hotkey_signal')
    return { type: "SET_HOTKEY_SIGNAL", value: key };
};


