/*
- returns a dict with all needed data for populating cards and other UI relted to cards

- all card Ids with useful info

*/
import  get_data_obj_from_string from './get_data_obj_from_string'
import  get_item_array_from_parent_item_string from './get_item_array_from_parent_item_string'

const get_item_path_as_array = (state_data, item, path_array=[]) => {
    // CAN LATER USE THE FLOW VP TO SCOPE WHICH CARDS ARE POPULATED
    let ret_array = {}

    // first find item in state_data
    // let data_string = item[0]+'_data.' + item[1]
    
    // TRY a version where i don't even feed in card_data ( i think this is already passed in)
    // Not sure thats a great thing but for the moment i'll give it a try.

    let data_string = item[1]
    // //AAA console.log('get_item_path_as_array data_string', data_string)
    let item_data = get_data_obj_from_string(state_data, data_string)
    // //AAA console.log('get_item_path_as_array item_data', item_data)

    if (item_data !== null) {
        let item_parent = item_data.item_parent;
        // console.log('X-----  item_data', item_data)
        // console.log('X-----   item_parent', item_parent)
        path_array.push(item_parent);
        // if (item_parent === 'topLevel') {
        //     return path_array;
        // }
        if (item_parent.startsWith('project_')) {
            return path_array;
        }
        else{
            let parent_id = '';
            let parent_item_rebuilt = '';
            // item_parent will be something like card_uid1. need to split this into type and id
            if (item_parent.startsWith('card_')){
                parent_id = item_parent.substring(5)
                // //AAA console.log('get_item_array_from_parent_item_string parent_id', parent_id)
                parent_item_rebuilt = ['card', parent_id]
                
            }
            // if (item_parent.startsWith('project_')){
            //     // parent_id = item_parent.substring(8)
            //     // //AAA console.log('get_item_array_from_parent_item_string parent_id', parent_id)
            //     // parent_item_rebuilt = ['project', parent_id]
            //     return path_array;

            // }

            let recurssive_return = get_item_path_as_array(state_data, parent_item_rebuilt, path_array);
            // //AAA console.log('get_item_path_as_array recurssive_return', recurssive_return)
            return recurssive_return;
        }
    }

}

export default get_item_path_as_array;