import * as utils from '../../utils/misc_utils'




const reducer_ui = (state, newState, action) => {
    // let newState = {...state};


    // if(action.type === 'TEST_A'){
    //     //AAA console.log('RD TEST_A', action)
    //     // lets not clear sel when changing mode
    //     // newState.ui_data.current_sel = [null, null];
    // }
    // if(action.type === 'TEST_B'){
    //     //AAA console.log('RD TEST_B', action)
    //     // lets not clear sel when changing mode
    //     // newState.ui_data.current_sel = [null, null];
    // }
    
    // if(action.type === 'TEST_C'){
    //     //AAA console.log('RD TEST_C', action)
    //     // lets not clear sel when changing mode
    //     // newState.ui_data.current_sel = [null, null];
    // }
    

    // THIS IS JUST A TEST, and is probably a bad idea optherwise
    // if(action.type === 'SET_ROOT_STATE'){
    //     console.log('RD SET_ROOT_STATE', action.data)
    //     // newState.card_data.uid1 = {...state.card_data.uid1};
    //     newState.card_data = {...state.card_data};
    //     newState = {...state};

    //     // newState.card_data.uid1 = action.data.card_data.uid1
    //     console.log('RD card data spread', {...action.data.card_data})

    //     newState.card_data.uid1 = {...action.data.card_data.uid1}
    //     // newState = {...action.data}

    //     // lets not clear sel when changing mode
    //     // newState.ui_data.current_sel = [null, null];
    // }



    if(action.type === 'SET_LOADING'){
        // console.log('RD SET_LOADING', action)
        newState.ui_data = {...state.ui_data};
        newState.ui_data.layout.is_loading = action.is_loading

    }
    if(action.type === 'SET_UPLOADING'){
        // console.log('RD SET_UPLOADING', action)
        newState.ui_data = {...state.ui_data};
        newState.ui_data.layout.is_uploading = action.is_uploading

    }
    if(action.type === 'SET_MODE'){
        //AAA console.log('RD SET_MODE', action)
        newState.ui_data = {...state.ui_data};
        newState.ui_data.settings.mode = action.mode

        // lets not clear sel when changing mode
        // newState.ui_data.current_sel = [null, null];
    }


    if(action.type === 'SET_WINDOW_DIMENSIONS'){
        // //AAA console.log('SET_WINDOW_DIMENSIONS action', action)

        newState.ui_data = {...state.ui_data};
        newState.ui_data.layout.window_dimensions = [action.width, action.height]

        // set other main panel attrs for convenience later 
        newState.ui_data.layout.main_panel_right = action.width - state.ui_data.layout.attribute_panel_width;
        newState.ui_data.layout.main_panel_bottom = action.height;


        // newState.ui_data.layout.window_dimensions = [
        //     action.width - state.ui_data.layout.attribute_panel_width  - state.ui_data.main_panel_left,
        //     action.height - state.ui_data.layout.main_panel_top,
        // ]
        // //AAA console.log('SET_WINDOW_DIMENSIONS main_panel_right', newState.ui_data.layout.main_panel_right)
        // //AAA console.log('SET_WINDOW_DIMENSIONS main_panel_bottom', newState.ui_data.layout.main_panel_bottom)
        // TODO: implement the above on attribute panel width and mode panel collapse 
        // (might be better to have funciton to derive this stuff and not have redundant state setting)

    }

    if(action.type === 'MODE_PANEL_EXPAND_TOGGLE'){
        newState.ui_data.settings.mode_panel_expanded = !state.ui_data.settings.mode_panel_expanded;
        if (newState.ui_data.settings.mode_panel_expanded){
            newState.ui_data.layout.main_panel_left = "128";
        }
        else{
            newState.ui_data.layout.main_panel_left = "36";
        }
    }



    // generic modal window 
    if(action.type === 'OPEN_MODAL_WINDOW'){
        // console.log('RD OPEN_MODAL_WINDOW', action)

        newState.ui_data['openModalWindow'] = {...state.ui_data['openModalWindow']};
        newState.ui_data['modal_data'] = {...state.ui_data['modal_data']};
        newState.ui_data = {...state.ui_data};

        // clear picked items
        newState.ui_data.control.picked_items = []

        // try clearing all modal rather than scoping by modal name
        newState.ui_data['modal_data'] = {[action.modal_name]:{}};
        newState.ui_data['openModalWindow'] = {[action.modal_name]:action.input_data};

    }
   
    if(action.type === 'CLOSE_MODAL_WINDOW'){
        // console.log('RD CLOSE_MODAL_WINDOW', action)

        newState.ui_data = {...state.ui_data};

        // clear picked items
        newState.ui_data.control.picked_items = []

        newState.ui_data['modal_data'] = {};
        newState.ui_data['openModalWindow'] = {};
    }

    // user fold out panel 
    if(action.type === 'OPEN_USER_PANEL'){
        console.log('RD OPEN_USER_PANEL', action)

        newState.ui_data['open_user_panel'] = {...state.ui_data['open_user_panel']};
        newState.ui_data = {...state.ui_data};

        newState.ui_data['open_user_panel'] = true;
    }
   
    if(action.type === 'CLOSE_USER_PANEL'){
        console.log('RD CLOSE_USER_PANEL', action)

        newState.ui_data['open_user_panel'] = {...state.ui_data['open_user_panel']};
        newState.ui_data = {...state.ui_data};

        newState.ui_data['open_user_panel'] = false;
    }
   


    // generic foldout panel 
    if(action.type === 'OPEN_FOLDOUT_PANEL'){
        // console.log('RD OPEN_FOLDOUT_PANEL', action)

        newState.ui_data['foldout_panel'] = {...state.ui_data['foldout_panel']};
        newState.ui_data = {...state.ui_data};


        // try clearing all modal rather than scoping by modal name
        newState.ui_data['foldout_panel'] = {'panel_name':action.panel_name, 'element_id':action.element_id, 'extra_data':action.extra_data};
    }
   
    if(action.type === 'CLOSE_ALL_FOLDOUT_PANELS'){
        // console.log('RD CLOSE_FOLDOUT_PANEL',)

        newState.ui_data = {...state.ui_data};

        newState.ui_data['foldout_panel'] = null;
    }



    // TODO THIS should be removed once i finish migrating over to new selection key
    if(action.type === 'ON_SELECT'){
        //action.event.stopPropagation(); // why do i need this? 

        //AAA console.log('RD ON_SELECT', action)
        newState.ui_data = {...state.ui_data};
        newState.ui_data.current_sel = [action.item_type, action.item_id,];

        // make copy of history
        let sel_history = [...state.ui_data.sel_history]
        let hist_index = state.ui_data.sel_history_index;

        // if sel is the same is the latest then dont add it again
        let add_to_hist = true;
        if (sel_history[0])
        {
            if (sel_history[0][0] === action.item_type && sel_history[0][1] === action.item_id){
                add_to_hist = false;
            }
        }
        if (add_to_hist)
        {
            // then remove items before the current hist index 
            //- this means if you go back and make a new sel, everything forward is gone and replace by new sel
            if (hist_index !== 0){
                sel_history.splice(0, hist_index) 
            }
            //then add new sel to sel_history
            sel_history.unshift([action.item_type, action.item_id]) 

            // if history is getting too long then trim
            if (sel_history.length > 20){
                sel_history.splice(20, 1) 
            }
            newState.ui_data.sel_history = sel_history;
        }


        newState.ui_data.sel_history_index = 0;
    }
    

    


    if(action.type === 'SELECT_ITEM_C'){
        // console.log('RD SELECT_ITEM_C', action)
        let select_mode = action.select_mode || 'replace';
        // console.log('RD SELECT_ITEM_C select_mode', select_mode)

        newState.ui_data.control = {...state.ui_data.control};
        newState.ui_data = {...state.ui_data};
        let current_sel = {...state.ui_data.control.selected};

        // let sel_to_add_to_history = {[action.item_id]:{item_type:action.item_type}};
        let sel_to_add_to_history = {...action.data};
        if (select_mode === 'replace'){
            newState.ui_data.control.selected = sel_to_add_to_history;
        }
        if (select_mode == 'add')
        {
            console.log('ADDING NOW', sel_to_add_to_history)

            newState.ui_data.control.selected = Object.assign(sel_to_add_to_history, current_sel);
        }
        if (select_mode == 'remove'){
            Object.keys(sel_to_add_to_history).map((item_id) => {
                console.log('REMOVING NOW', item_id)

                if (item_id in current_sel){
                    delete current_sel[item_id]; 
                }
            })
            newState.ui_data.control.selected = current_sel;
           
        }

        // let keys = Object.keys(action.data);
        // let sel_to_add_to_history = {[keys[0]]:action.data[keys[0]]};

        // TODO THIS IS NOT WOKRING NOW. SHould refactor to make same as SELECT_ITEM_B
        // let sel_to_add_to_history = action.data
        let add_to_hist = true;

        // console.log('sel_to_add_to_history', sel_to_add_to_history)
        if (Object.keys(sel_to_add_to_history).length > 1){
            add_to_hist = false;
        }

        // make copy of history
        let sel_history = [...state.ui_data.sel_history]
        let hist_index = state.ui_data.sel_history_index;

        // if sel is the same is the latest then dont add it again
        // console.log('EEX hist', sel_history[0])

        // test is items are the same as
        if (sel_history[0])
        {
            // console.log('EEX compare_object_keys',sel_to_add_to_history, '-\n', sel_history[0])
            if (utils.compare_object_keys(sel_to_add_to_history, sel_history[0])){
                add_to_hist = false;
                // console.log('EEX compare_object_keys add_to_hist', add_to_hist)

            }
        }

        // if (sel_history[0])
        // {
        //     if (sel_history[0][0] === action.item_type && sel_history[0][1] === action.item_id){
        //         add_to_hist = false;
        //     }
        // }
        if (add_to_hist)
        {
            // then remove items before the current hist index 
            //- this means if you go back and make a new sel, everything forward is gone and replace by new sel
            if (hist_index !== 0){
                sel_history.splice(0, hist_index) 
            }
            //then add new sel to sel_history
            // sel_history.unshift([action.item_type, action.item_id])
            // console.log('RD SELECT_ITEM_C unshift', sel_to_add_to_history)
 
            sel_history.unshift(sel_to_add_to_history) 

            // if history is getting too long then trim
            if (sel_history.length > 20){
                sel_history.splice(20, 1) 
            }
            newState.ui_data.sel_history = sel_history;
        }


        newState.ui_data.sel_history_index = 0;

    }


    
    if(action.type === 'SEL_HISTORY_BACK'){
        console.log('RD SEL_HISTORY_BACK', action)

        newState.ui_data.control = {...state.ui_data.control};
        newState.ui_data = {...state.ui_data};

        let sel_history = [...state.ui_data.sel_history]
        let hist_index = state.ui_data.sel_history_index;

        if (hist_index < sel_history.length-1){
            hist_index++
        }
        newState.ui_data.sel_history_index = hist_index;
        // newState.ui_data.current_sel = sel_history[hist_index];
        newState.ui_data.control.selected = sel_history[hist_index];

    }
    if(action.type === 'SEL_HISTORY_FORWARD'){
        console.log('RD SEL_HISTORY_BACK', action)
        newState.ui_data.control = {...state.ui_data.control};
        newState.ui_data = {...state.ui_data};


        let sel_history = [...state.ui_data.sel_history]
        let hist_index = state.ui_data.sel_history_index;

        if (hist_index > 0){
            hist_index--
        }
        newState.ui_data.sel_history_index = hist_index;
        // newState.ui_data.current_sel = sel_history[hist_index];
        newState.ui_data.control.selected = sel_history[hist_index];

    }


    if(action.type === 'SET_PICKED_ITEMS'){
        //action.event.stopPropagation(); // why do i need this? 

        // console.log('RD SET_PICKED_ITEMS', action)
        newState.ui_data.control = {...state.ui_data.control};
        newState.ui_data = {...state.ui_data};
        newState.ui_data.control.picked_items = action.picked_items;
    }
    if(action.type === 'CLEAR_PICKED_ITEMS'){
        //action.event.stopPropagation(); // why do i need this? 

        // console.log('RD CLEAR_PICKED_ITEMS', action)
        newState.ui_data.control = {...state.ui_data.control};
        newState.ui_data = {...state.ui_data};
        newState.ui_data.control.picked_items = [];
    }

    if(action.type === 'SET_CURRENT_DOCUMENT'){
        //AAA console.log('RD SET_CURRENT_DOCUMENT', action)
        newState.ui_data.current = {...state.ui_data.current};
        newState.ui_data = {...state.ui_data};
        newState.ui_data.current.current_document = action.document;
    }

    if(action.type === 'SET_CURRENT_THREAD'){
        // console.log('RD SET_CURRENT_THREAD', action)
        newState.ui_data.current = {...state.ui_data.current};
        newState.ui_data = {...state.ui_data};
        newState.ui_data.current.current_thread_colour = action.thread_id;
    }
    if(action.type === 'SET_MODIFIER_KEY'){
        // console.log('RD SET_MODIFIER_KEY', action)
        newState.ui_data.modifier_key = {...state.ui_data.modifier_key};
        newState.ui_data = {...state.ui_data};
        newState.ui_data.modifier_key[action.modifier_key] = action.value;

    }
    if(action.type === 'SET_HOTKEY_SIGNAL'){
        // console.log('RD SET_HOTKEY_SIGNAL', action)
        newState.ui_data.control.set_hotkey_signal = {...state.ui_data.set_hotkey_signal};
        newState.ui_data.control = {...state.ui_data.control};
        newState.ui_data = {...state.ui_data};
        newState.ui_data.control.set_hotkey_signal = action.value;
    }


    if(action.type === 'ATTRIBUTE_PANEL_COLLAPSE'){
        // //AAA console.log('ATTRIBUTE_PANEL_COLLAPSE',  action.expanded) 
        // //AAA console.log('ATTRIBUTE_PANEL_COLLAPSE attribute_panel_expanded_width',  state.attribute_panel_expanded_width) 
        
        let mouse_drag_offset = state.ui_data.control.mouse_drag_offset
        // //AAA console.log('ATTRIBUTE_PANEL_COLLAPSE mouse_drag_offset',  mouse_drag_offset) 

        newState.ui_data.settings = {...state.ui_data.settings};
        newState.ui_data.layout = {...state.ui_data.layout};
        newState.ui_data = {...state.ui_data};

        if (mouse_drag_offset[0]  === 0 && mouse_drag_offset[1]  === 0) {
            if (action.expanded){
                newState.ui_data.settings.attribute_panel_expanded = false;
                newState.ui_data.layout.attribute_panel_width =0;
                // //AAA console.log('ATTRIBUTE_PANEL_COLLAPSE attribute_panel_width',  newState.ui_data.layout.attribute_panel_width) 

            }
            else {
                // //AAA console.log('HEHEHERRE', state.ui_data.layout.attribute_panel_expanded_width)
                newState.ui_data.settings.attribute_panel_expanded = true;
                newState.ui_data.layout.attribute_panel_width = state.ui_data.layout.attribute_panel_expanded_width;

                // if (state.ui_data.layout.attribute_panel_width < 280){
                //     newState.ui_data.layout.attribute_panel_width =280;

                // }
            }

            // set main_panel_right
            newState.ui_data.layout.main_panel_right = state.ui_data.layout.window_dimensions[0] - newState.ui_data.layout.attribute_panel_width;

        }
    }

    if(action.type === 'ON_DRAG_ATTR_PANEL_HANDLE'){
        //AAA console.log('RD ON_DRAG_ATTR_PANEL_HANDLE', action)

        newState.ui_data.settings = {...state.ui_data.settings};
        newState.ui_data.layout = {...state.ui_data.layout};
        newState.ui_data = {...state.ui_data};

        newState.ui_data.settings.attribute_panel_expanded = action.attribute_panel_expanded;
        newState.ui_data.layout.attribute_panel_width = action.attribute_panel_width
        newState.ui_data.layout.attribute_panel_expanded_width = action.attribute_panel_expanded_width
        newState.ui_data.layout.main_panel_right = action.main_panel_right

    }
    
    if(action.type === 'TEST_CONTEXT'){
        console.log('RD TEST_CONTEXT', action)


        newState.ui_data = {...state.ui_data};

        let cur_proj = state.ui_data.current.current_project;
        let project_obj = state.project_data[cur_proj] 

        console.log('porj obj', project_obj)
        newState.ui_data.context = {'project_object':project_obj, 'project_id':cur_proj}
        // newState.ui_data.settings.attribute_panel_expanded = action.attribute_panel_expanded;
        // newState.ui_data.layout.attribute_panel_width = action.attribute_panel_width
        // newState.ui_data.layout.attribute_panel_expanded_width = action.attribute_panel_expanded_width
        // newState.ui_data.layout.main_panel_right = action.main_panel_right

    }
    
    
    if(action.type === 'SET_TREE_EXPANDED'){
        // console.log('RD SET_TREE_EXPANDED', action)
        // console.log('RD SET_TREE_EXPANDED item', action.item)
        // console.log('RD SET_TREE_EXPANDED expand_item', action.expand_item)
        // console.log('RD SET_TREE_EXPANDED tree_name', action.tree_name)

        let tree_name = action.tree_name;

        if (state.ui_data.tree_view_data.hasOwnProperty(tree_name)){
            newState.ui_data.tree_view_data[tree_name].expanded = {...state.ui_data.tree_view_data[tree_name].expanded};
            newState.ui_data.tree_view_data[tree_name] = {...state.ui_data.tree_view_data[tree_name]};
        }

        newState.ui_data.tree_view_data = {...state.ui_data.tree_view_data};
        newState.ui_data = {...state.ui_data};

        if (!newState.ui_data.tree_view_data.hasOwnProperty(tree_name)){
            newState.ui_data.tree_view_data[tree_name] = {'expanded':{}}
        }
        // console.log('newState.ui_data.tree_view_data', newState.ui_data.tree_view_data)
        // let cur_data = newState.ui_data.tree_view_data[tree_name].expanded

        if (action.expand_item){ 
            newState.ui_data.tree_view_data[tree_name].expanded[action.item] = true;
        }
        else{
            newState.ui_data.tree_view_data[tree_name].expanded[action.item] = false;   
        }

    }
    


    if(action.type === 'SET_AUTH_UI_MESSAGE'){
        // console.log('RD SET_AUTH_UI_MESSAGE', action)
        newState.ui_data.auth_ui_message = {...state.ui_data.auth_ui_message};
        newState.ui_data = {...state.ui_data};

        newState.ui_data.auth_ui_message = action.message;
    }


    if(action.type === 'SHOW_TIME_CONTROLS_TOGGLE'){
        newState.ui_data.settings = {...state.ui_data.settings};
        newState.ui_data = {...state.ui_data};

        newState.ui_data.settings.show_time_controls = !state.ui_data.settings.show_time_controls;

    }
    if(action.type === 'SHOW_TESTING_PANEL_TOGGLE'){
        newState.ui_data.settings = {...state.ui_data.settings};
        newState.ui_data = {...state.ui_data};
        
        newState.ui_data.settings.show_testing_panel = !state.ui_data.settings.show_testing_panel;


    }

    if(action.type === 'SET_EXPERIMENTAL_MODE'){
        newState.ui_data.settings = {...state.ui_data.settings};
        newState.ui_data = {...state.ui_data};
        
        newState.ui_data.settings.experimental_mode = action.mode
    }

    // if(action.type === 'SET_CACHE_DATA'){
    //     //AAA console.log('RD SET_THREAD_CUR_DRAGGING', action)

    //     newState.ui_data = {...state.ui_data};
    //     newState.ui_data.cache_data = {...state.ui_data.cache_data};

    //     // if (newState.ui_data.cache_data.hasOwnProperty(action.key)){
    //     //     newState.ui_data.cache_data[action.key] = {...state.ui_data.cache_data.action.key};
    //     // }
    //     newState.ui_data.cache_data[action.key] = action.data;
    // }




    return newState;

};
export default reducer_ui;