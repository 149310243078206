
/////////////////////// Viewport Action Creators  /////////////////////////////
///////////////////////////////////////////////////////////////////////////////
import * as actions_database from './actions_database';
import * as actions_projects from './actions_projects';
import * as Constants from '../../constants/constants';

import * as database_utils from '../../utils/database_utils'
import get_mouse_pos_in_viewport from '../../utils/get_mouse_pos_in_viewport'
import * as viewport_utils from '../../utils/viewport_utils';
import templates from '../templates/templates';

// i was trying to add unique tom-outs so they didn't step on each other...
// will probably need to figure that out at some point

var timeoutId_pos;
var timeoutId_scale;
// mode panel on left actions 
export const mouse_down_viewport = (event, ui_data, vp_data) => {
    //AAA console.log('AC mouse_down_viewport', event, ui_data, vp_data )

    // let mousePos = [event.clientX, event.clientY];
    return dispatch => {

        let mouse_down_pos = [];
        if (event.hasOwnProperty('touches')){
            mouse_down_pos = [event.touches[0].clientX, event.touches[0].clientY];
        }
        else{
            mouse_down_pos = [event.clientX, event.clientY];
        }

        // let mouse_down_pos =  [event.clientX, event.clientY];
        let main_panel_left = ui_data.layout.main_panel_left;
        let main_panel_top = ui_data.layout.main_panel_top;
        let local_orig_pos = vp_data.pos;
        let vp_scale = vp_data.scale;
        let cur_vp_type = ui_data.current.current_viewport[0]
        let cur_vp_id = ui_data.current.current_viewport[1]

        let vp_mouse_pos = get_mouse_pos_in_viewport(
            [main_panel_left, main_panel_top], 
            local_orig_pos,
            vp_scale,
            mouse_down_pos
            )

        dispatch ({ type: "MOUSE_DOWN_VP", 
            local_down_pos:vp_mouse_pos, 
            local_current_pos:vp_mouse_pos,
            local_drag_offset:[0,0],
            local_orig_pos:local_orig_pos,
            vp_pos:local_orig_pos,
            vp_scale:vp_scale,
        });

        // this is my alternate data. It migh be useful to replace the stuf above
        let clicked_data = {
            [cur_vp_id]:{item_type:cur_vp_type, 
                custom_data:{
                    mouse_down_pos:vp_mouse_pos,
                    mouse_down_scale:vp_scale,
                },
            }
        }
        // dispatch({  
        //     type: "SET_DRAGGING_B", 
        //     data:clicked_data,
        // }) 
        dispatch({  
            type: "SET_CLICKED", 
            data:clicked_data,
        })
    }

};



export const mouse_drag_viewport = (event, ui_data, vp_data) => {
    //AAA console.log('AC mouse_drag_viewport', event, ui_data, vp_data )

 
    return dispatch => {
        let mouse_down_pos = [];
        if (event.hasOwnProperty('touches')){
            mouse_down_pos = [event.touches[0].clientX, event.touches[0].clientY];
        }
        else{
            mouse_down_pos = [event.clientX, event.clientY];
        }
        // console.log('AC mouse_drag_viewport mouse_down_pos', mouse_down_pos )
        //    let mousePos = state.ui_data.control.mouse_current_pos;
        let project_id = ui_data.current.current_project;

        let main_panel_left = ui_data.layout.main_panel_left;
        let main_panel_top = ui_data.layout.main_panel_top;
        let mouse_drag_offset = ui_data.control.mouse_drag_offset;
        let local_orig_pos = ui_data.control.local_orig_pos;
        let local_down_pos = ui_data.control.local_down_pos;
        let orig_scale = vp_data.scale;

        let viewport_pos = vp_data.pos;
        let vp_scale = vp_data.scale;

        // FIGURE OUT OFFSET IN VP SPACE:
        let vp_mouse_pos = get_mouse_pos_in_viewport(
            [main_panel_left, main_panel_top], 
            viewport_pos,
            vp_scale,
            mouse_down_pos
            )

        let vp_offset = [
                vp_mouse_pos[0] - local_down_pos[0], 
                vp_mouse_pos[1] - local_down_pos[1]
            ]


        let new_pos = [0,0];
        // if dragging is a viewport then set vp position, otherwise, update local values to be used by other things
        if (ui_data.control.dragging[0] === 'viewport' && ui_data.control.dragging[1] === ui_data.current.current_viewport[1]){

            new_pos = [
                Math.round(local_orig_pos[0] +  mouse_drag_offset[0]),
                Math.round(local_orig_pos[1] +  mouse_drag_offset[1]),
            ]

            // if shift held then do selection, not move vp
            if (event.getModifierState("Shift") || event.getModifierState("Alt") || event.getModifierState("Control")){

                // let start_pos = [
                //     Math.min(local_down_pos[0], vp_mouse_pos[0]),
                //     Math.min(local_down_pos[1], vp_mouse_pos[1]),
                // ]
                // let end_pos = [
                //     Math.max(local_down_pos[0], vp_mouse_pos[0]),
                //     Math.max(local_down_pos[1], vp_mouse_pos[1]),
                // ]
                let bbox = [
                    Math.min(local_down_pos[0], vp_mouse_pos[0]),
                    Math.min(local_down_pos[1], vp_mouse_pos[1]),
                    Math.max(local_down_pos[0], vp_mouse_pos[0]),
                    Math.max(local_down_pos[1], vp_mouse_pos[1]),
                ]

                dispatch ({ type: "SET_SEL_BBOX", 
                    // start:start_pos, 
                    // end:end_pos,
                    bbox:bbox,
                });
            }
            else{
                dispatch ({ type: "SET_VP_POS", 
                    vp_type:ui_data.current.current_viewport[0], 
                    vp_id:ui_data.current.current_viewport[1],
                    pos:new_pos,
                });
                // let db_path = Constants.DB_ROOT+'/'+ui_data.current.current_viewport[0]+'_data/'+ui_data.current.current_viewport[1]
                let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, ui_data.current.current_viewport[0], ui_data.current.current_viewport[1]);


                let db_data = {pos:new_pos, scale:orig_scale};
                let db_set_delay = 1000;
                actions_database.set_db_data(db_path, db_data, window.SKIP_DATABASE, db_set_delay) 
            }

        }

        else {
            // IF NOT MOVING VP ASSUME I CARE ABOUT PUSHING AN OFFSET I CAN USE IN MYH OTHER SLICES


            // let viewport_pos = vp_data.pos;
            // let vp_scale = vp_data.scale;

            // // IF NOT MOVING VP ASSUME I CARE ABOUT PUSHING AN OFFSET I CAN USE IN MYH OTHER SLICES
            // // FIGURE OUT OFFSET IN VP SPACE:
            // let vp_mouse_pos = get_mouse_pos_in_viewport(
            //     [main_panel_left, main_panel_top], 
            //     viewport_pos,
            //     vp_scale,
            //     mouse_down_pos
            //     )

            // let vp_offset = [
            //         vp_mouse_pos[0] - local_down_pos[0], 
            //         vp_mouse_pos[1] - local_down_pos[1]
            //     ]

            // newState.ui_data = {...state.ui_data}
            // newState.ui_data.control.local_current_pos = vp_mouse_pos
            // newState.ui_data.control.local_drag_offset = vp_offset

            dispatch({ type: "SET_LOCAL_VALS", 
                local_current_pos:vp_mouse_pos,
                local_drag_offset:vp_offset,
            });
            
        }
    }
     
 }


export const mouse_up_viewport = (event, ui_data, card_data, region_data, location_data) => {
    // console.log('XXXXXAC mouse_up_viewport', event, ui_data, card_data, region_data )

    return dispatch => {

        let sel_bbox = ui_data.control.select_bbox;
        let current_sel = ui_data.control.selected;
        let begin_drag = ui_data.control.begin_drag;

        let new_sel_data = {};

        let clicked_data = ui_data.control.clicked;
        // console.log('clicked_data', clicked_data)

        let clicked_id = null;
        let clicked_type = null;

        if (clicked_data){
            let clicked_keys = Object.keys(clicked_data);
            if (clicked_keys.length){
                // console.log('clicked_keys', clicked_keys)
                clicked_id = clicked_keys[0];
                clicked_type = clicked_data[clicked_id].item_type
            }
        }

        // let clicked_id = null;
        // let click_type= null;
        // if (clicked_data){
        //     clicked_id = Object.keys(clicked_data)[0]
        //     click_type= clicked_data[clicked_id].item_type;
        // }


        if(event.getModifierState("Control") || event.getModifierState("Alt")){
            new_sel_data =  {...current_sel}
        }

        if (sel_bbox)
        {

            Object.keys(region_data).map((item_id) => {
                let item_bbox = [
                    region_data[item_id]['pos'][0],
                    region_data[item_id]['pos'][1],
                    region_data[item_id]['pos'][0] + region_data[item_id]['size'][0],
                    region_data[item_id]['pos'][1] + region_data[item_id]['size'][1],
                ]

                if (
                    sel_bbox[0] <= item_bbox[2] &&
                    sel_bbox[1] <= item_bbox[3] &&
                    sel_bbox[2] >= item_bbox[0] &&
                    sel_bbox[3] >= item_bbox[1]

                    ){
                        if(event.getModifierState("Alt")){
                            delete new_sel_data[item_id]; 
                        }
                        else{
                            new_sel_data[item_id] = {'item_type':'region'};
                            // console.log('foun item', item_id)   
                        }
                }
            })
            Object.keys(card_data).map((item_id) => {
                let item_bbox = [
                    card_data[item_id]['pos'][0],
                    card_data[item_id]['pos'][1],
                    card_data[item_id]['pos'][0] + card_data[item_id]['size'][0],
                    card_data[item_id]['pos'][1] + card_data[item_id]['size'][1],
                ]

                if (
                    sel_bbox[0] <= item_bbox[2] &&
                    sel_bbox[1] <= item_bbox[3] &&
                    sel_bbox[2] >= item_bbox[0] &&
                    sel_bbox[3] >= item_bbox[1]

                    ){
                        if(event.getModifierState("Alt")){
                            delete new_sel_data[item_id]; 
                        }
                        else{
                            new_sel_data[item_id] = {'item_type':'card'};
                            // console.log('foun item', item_id)   
                        }
                }
            })
            Object.keys(location_data).map((item_id) => {
                let item_bbox = [
                    location_data[item_id]['pos'][0],
                    location_data[item_id]['pos'][1],
                    location_data[item_id]['pos'][0],
                    location_data[item_id]['pos'][1],
                ]

                if (
                    sel_bbox[0] <= item_bbox[2] &&
                    sel_bbox[1] <= item_bbox[3] &&
                    sel_bbox[2] >= item_bbox[0] &&
                    sel_bbox[3] >= item_bbox[1]

                    ){
                        if(event.getModifierState("Alt")){
                            delete new_sel_data[item_id]; 
                        }
                        else{
                            new_sel_data[item_id] = {'item_type':'location'};
                            // console.log('found item', item_id)   
                        }
                }
            })
            dispatch({  
                type: "SELECT_ITEM_C", 
                data:new_sel_data,
            })
        }
        else{
            // console.log('SHIOT IM HERE AAA')
            if (!begin_drag) {

                // if clicked vp then deslect- otherwist ignore. This is important, so clikcing
                // on things like attr ed or buttons wont' deselect
                // console.log('IN HERE mouse_up_viewport , clicked_type', clicked_type)
                
                if (['flow_viewport', 'map_viewport'].includes(clicked_type)){
                    // console.log('INNN HERRREE mouse_up_viewport , in here', clicked_type)
                    dispatch({  
                        type: "SELECT_ITEM_C", 
                        data:new_sel_data,
                    })
                }
            }
        }
        dispatch ({ type: "SET_SEL_BBOX", 
            bbox:null,
        });
        dispatch({  
            type: "SET_CLICKED", 
            data:null,
        })
    }
}


export const mouse_wheel_viewport = (event, ui_data, vp_data) => {
    //AAA console.log('AC mouse_wheel_viewport', event, ui_data, vp_data )

    return dispatch => {
        event.stopPropagation();

        // put in somthing here to avoid zooming when hovering over scroll
        event.preventDefault();
        // skip this if some ui has focus
        if (ui_data.control.focus){
            return {};
        }
        


        
        let mouseDownPos = [event.clientX, event.clientY];
        let viewportPos = vp_data.pos;
        let orig_scale = vp_data.scale;

        let vp_mouse_pos = get_mouse_pos_in_viewport(
            [ui_data.layout.main_panel_left, ui_data.layout.main_panel_top], 
            viewportPos,
            orig_scale,
            mouseDownPos
            )

        let wheelAmount = event.deltaY;
        // newState[action.vp_type +'_data'][action.vp_id]['scale'] =orig_scale

        let newScale = null; 
        if (wheelAmount < 0){
            newScale = orig_scale * 1.2;
            if (newScale > 5) newScale= 5;
        }
        else {
            newScale = orig_scale * .8;
            if (newScale < .04) newScale= .04;
        }
        let scaleDif = newScale - orig_scale;

        let newPosX = viewportPos[0] - (vp_mouse_pos[0]*scaleDif)
        let newPosY = viewportPos[1] - (vp_mouse_pos[1]*scaleDif)

        // newState[action.vp_type +'_data'][action.vp_id]['scale'] = newScale
        // newState[action.vp_type +'_data'][action.vp_id]['pos'] = [newPosX, newPosY]

        dispatch( { type: "SET_ZOOM_VP", 
            vp_type:ui_data.current.current_viewport[0], 
            vp_id:ui_data.current.current_viewport[1],
            scale:newScale,
            pos:[newPosX, newPosY],
        })
        // let db_path = Constants.DB_ROOT+'/'+ui_data.current.current_viewport[0]+'_data/'+ui_data.current.current_viewport[1]
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, ui_data.current.current_project, ui_data.current.current_viewport[0], ui_data.current.current_viewport[1]);


        let db_data = {pos:[newPosX, newPosY], scale:newScale};
        let db_set_delay = 1000;
        // var timeoutId =1;
        actions_database.set_db_data(db_path, db_data, window.SKIP_DATABASE, db_set_delay) 
    } 

}


export const zoom_viewport = (ui_data, vp_data, direction) => {
    // console.log('AC zoom_viewport', event, ui_data, vp_data, direction )

    return dispatch => {
        // event.stopPropagation();

        // //AAA console.log("WINDOW : ",window);
        // this.setState({height: window.innerHeight + 'px',width:window.innerWidth+'px'});


        let viewportPos = vp_data.pos;
        let orig_scale = vp_data.scale;

        // let vp_mouse_pos = [window.innerHeight * .5, window.innerWidth * .5];

        let centre_pos = [
            ui_data.layout.main_panel_left  +  (.5 * ( ui_data.layout.main_panel_right - ui_data.layout.main_panel_left)),
            ui_data.layout.main_panel_top  +  (.5 * ( ui_data.layout.main_panel_bottom - ui_data.layout.main_panel_top)),
        ];
        let vp_mouse_pos = get_mouse_pos_in_viewport(
            [ui_data.layout.main_panel_left, ui_data.layout.main_panel_top], 
            viewportPos,
            orig_scale,
            centre_pos
            )


        // let wheelAmount = action.event.deltaY;
        // newState[action.vp_type +'_data'][action.vp_id]['scale'] =orig_scale

        let newScale = null; 
        if (direction === 'in'){
            newScale = orig_scale * 1.2;
            if (newScale > 5) newScale= 5;
        }
        if (direction === 'out'){
            newScale = orig_scale * .8;
            if (newScale < .04) newScale= .04;
        }
        if (direction === 'reset'){
            newScale = 1.0;
        }

        let scaleDif = newScale - orig_scale;

        let newPosX = viewportPos[0] - (vp_mouse_pos[0]*scaleDif)
        let newPosY = viewportPos[1] - (vp_mouse_pos[1]*scaleDif)


        dispatch( { type: "SET_ZOOM_VP", 
            vp_type:ui_data.current.current_viewport[0], 
            vp_id:ui_data.current.current_viewport[1],
            scale:newScale,
            pos:[newPosX, newPosY],
        })

        // let db_path = Constants.DB_ROOT+'/'+ui_data.current.current_viewport[0]+'_data/'+ui_data.current.current_viewport[1]
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, ui_data.current.current_project, ui_data.current.current_viewport[0], ui_data.current.current_viewport[1]);


        let db_data = {pos:[newPosX, newPosY], scale:newScale};

        let db_set_delay = 1000;
        actions_database.set_db_data(db_path, db_data, window.SKIP_DATABASE, db_set_delay)  
        
    }
}




export const set_current_viewport = (vp_type, vp_id) => {
    //AAA console.log('AC set_current_viewport', event, ui_data, vp_data )


    return { type: "SET_CURRENT_VP", 
        vp_type:vp_type, 
        vp_id:vp_id,
    };

};


/**
 * Creates a new flow viewport - adds to DB and kicks off MAKE_NEW_VP reducer
 * @param {string} project_id - project uid
 * @param {string} vp_type - type of viewport. eg 'flow_viewport'
 * @param {string} uid - new uid for viewport. This should match the uid of the parent item with a prefix of the item type 
 *                          eg card_newCard_uid1234
 */
export const create_flow_viewport = (project_id, uid) => {

    // console.log('AC create_flow_viewport', project_id, uid )
    return dispatch => {
        // let db_path = Constants.DB_ROOT+'/flow_viewport_data/'+uid
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'flow_viewport', uid);

        let flow_viewport_data = {...templates['flow_viewport']};

        actions_database.set_db_data(db_path, flow_viewport_data, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "CREATE_NEW_FLOW_VP", 
                uid:uid,
                data:flow_viewport_data,
            }),

            // link new item to project
            // dispatch(actions_projects.link_to_project(project_object, project_id, 'flow_viewport', [uid]))
        )
    }
};




export const set_viewport_pos_and_scale = (pos, scale, vp_type, vp_id, project_id) => {
    // console.log('AC set_viewport_pos_and_scale', pos, scale,)
    // console.log('AC set_viewport_pos_and_scale vp_type vp_id', vp_type, vp_id)
    // console.log('AC set_viewport_pos_and_scaleproject_id ', project_id)

    return dispatch => {
        // event.stopPropagation();

        dispatch( { type: "SET_ZOOM_VP", 
            vp_type:vp_type, 
            vp_id:vp_id,
            scale:scale,
            pos:pos,
        })

        // let db_path = Constants.DB_ROOT+'/'+ui_data.current.current_viewport[0]+'_data/'+ui_data.current.current_viewport[1]
        // let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, ui_data.current.current_project, ui_data.current.current_viewport[0], ui_data.current.current_viewport[1]);

        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, vp_type, vp_id);
        // console.log('db_path - ', db_path);

        let db_data = {pos:pos, scale:scale};

        let db_set_delay = 1000;
        // let db_set_delay = 0;
        actions_database.set_db_data(db_path, db_data, window.SKIP_DATABASE, db_set_delay)  
        
    }
}



export const fit_flow_viewport = (ui_data, card_data, region_data, location_data) => {

    // console.log('fit_flow_viewport')
    // console.log('fit_flow_viewport ui_data', ui_data)
    // console.log('fit_flow_viewport card_data', card_data)
    // console.log('fit_flow_viewport region_data', region_data)
    // get bbox of selected

    return dispatch => {
        let item_array = [];

        let item_data_table = {
            card:card_data,
            region:region_data,
            location:location_data,
        }

        let frameable_items = ['card', 'region', 'location']
        let selected = ui_data.control.selected;
        let selected_filtered = {};
        // make a list of items which are compatible with VP frame (remove those that aren't)
        for (let index = 0; index < Object.keys(selected).length; index++) {
            const item_id = Object.keys(selected)[index];
            let item_type = selected[item_id]['item_type']

            if (frameable_items.includes(item_type)){
                selected_filtered[item_id] = selected[item_id];
            }
            
        }
        
        // if nothing selected, then frame all. otherwise frame selected
        if (Object.keys(selected_filtered).length === 0){
            // loop through all framable types
            let frame_types = ['card', 'region', 'location']
            for (let i = 0; i < frame_types.length; i++) {
                let item_type = frame_types[i];
                let item_data = item_data_table[item_type];
                // console.log( ' - - - fit_flow_viewport item_data', item_data);
                
                for (let index = 0; index < Object.keys(item_data).length; index++) {
                    const item_id = Object.keys(item_data)[index];
                    if (item_data[item_id].item_parent === ui_data.current.current_viewport[1]){
                        item_array.push(item_data[item_id])
                    }

                }
            }
            // console.log('handleClickZoomFit item_array A', item_array)
        }

        else{
            for (let index = 0; index < Object.keys(selected_filtered).length; index++) {
                const item_id = Object.keys(selected_filtered)[index];
                let item_type = selected_filtered[item_id]['item_type']
                let item_data = item_data_table[item_type];

                // if (item_type == 'card'){
                    item_array.push(item_data[item_id])
                // }
                
            }
        }
        // console.log('handleClickZoomFit item_array B', item_array)

        // let bbox = [20, 60, 120, 160];
        let bbox = viewport_utils.get_bbox_from_items(item_array)
        // console.log('handleClickZoomFit bbox', bbox)


        let vp_type = ui_data.current.current_viewport[0];
        let vp_id = ui_data.current.current_viewport[1];
        let project_id = ui_data.current.current_project;
        // console.log('Object.keys(selected)[0]', Object.keys(selected_filtered)[0]);
        if (card_data.hasOwnProperty(Object.keys(selected_filtered)[0])){
            vp_id = card_data[Object.keys(selected_filtered)[0]].item_parent;
            dispatch(set_current_viewport('flow_viewport', vp_id))

        }

 

        let viewport_panel_width = ui_data.layout.main_panel_right -  ui_data.layout.main_panel_left;
        let viewport_panel_height = ui_data.layout.main_panel_bottom -  ui_data.layout.main_panel_top;
        let pos_and_scale = viewport_utils.get_frame_viewport_to_bbox_vals( bbox, viewport_panel_width, viewport_panel_height)

        dispatch(set_viewport_pos_and_scale(pos_and_scale[0], pos_and_scale[1], vp_type, vp_id, project_id))

        // set current vp - will just use first item - how else do i sort logic if selection is mixed across multiple vps?

        
    }
    // this.props.click_zoom(this.props.ui_data, this.props.viewport_data, 'reset');
}




export const fit_flow_viewport_to_items = (item_array, ui_data, vp_type, vp_id) => {
    return dispatch => {
        // let bbox = [20, 60, 120, 160];
        let bbox = viewport_utils.get_bbox_from_items(item_array)
        // console.log('fit_flow_viewport_to_items bbox', bbox)

        let project_id = ui_data.current.current_project;


        let viewport_panel_width = ui_data.layout.main_panel_right -  ui_data.layout.main_panel_left;
        let viewport_panel_height = ui_data.layout.main_panel_bottom -  ui_data.layout.main_panel_top;
        let pos_and_scale = viewport_utils.get_frame_viewport_to_bbox_vals( bbox, viewport_panel_width, viewport_panel_height)

        dispatch(set_viewport_pos_and_scale(pos_and_scale[0], pos_and_scale[1], vp_type, vp_id, project_id))
    }
}