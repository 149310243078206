import React, { Component } from 'react';
import './Viewport.css';

import { connect} from 'react-redux';

import SelectionBox from './SelectionBox';

import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
import * as actionCreator_viewports from '../../../../store/actions/actions_viewports';
import * as actionCreator_cache from '../../../../store/actions/actions_cache';
// import * as actionCreator_regions from '../../../../store/actions/actions_regions';

class Viewport extends Component {

    
    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseWheel = this.handleMouseWheel.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.do_cursor = this.props.do_cursor || false;

      }
    
    state={
        shitft_down:false,
        control_down:false,
        alt_down:false,
    }

    handleMouseDown(event) {
        this.props.onMouseDown(event, 'viewport', this.props.vp_id);
        this.props.onMouseDown_VP(event, this.props.ui_data, this.props.viewport_data,);
        this.props.update_flow_viewport_cache();

        // console.log('Viewport generate_region_child_cache')
        // this.props.generate_region_child_cache(this.props.region_data, this.props.card_data, this.props.location_data);

    }

    handleMouseUp(event) {
        this.props.update_flow_viewport_cache();

    }

    handleMouseWheel(event) {
        this.props.mouse_wheel_viewport(event, this.props.ui_data, this.props.viewport_data,);
    }

    onKeyDown(event){
        let key_down = event.key;
        // console.log('onKeyDown', key_down)

        if (key_down === "Control") { 
            this.setState({control_down:true})
        }
        if (key_down === "Shift") { 
            this.setState({shift_down:true})
        }
        if (key_down === "Alt") { 
            this.setState({alt_down:true})
        }
        
    }
    onKeyUp(event){
        let key_down = event.key;
        // console.log('onKeyUp', key_down)

        if (key_down === "Control") { 
            this.setState({control_down:false})
        }
        if (key_down === "Shift") { 
            this.setState({shift_down:false})
        }
        if (key_down === "Alt") { 
            this.setState({alt_down:false})
        }        
    }

    selection_bbox(){
        if (this.props.ui_data.control.select_bbox){
            let deselect = false;
            if (this.state.alt_down){
                deselect = true;
            }
            // console.log('deselect', deselect)
            return <SelectionBox deselect={deselect} select_bbox={this.props.ui_data.control.select_bbox}/>
        }
        else{
            return null;
        }
    }

    componentDidMount(){
        // console.log('Viewport componentDidMount update_flow_viewport_cache');
        this.props.update_flow_viewport_cache();
        this.props.generate_region_child_cache_b();

    }
    render() {

        // This is to get around issue, where i get an error swithcing to flow mode on new project. 
        if (!this.props.viewport_data){
            console.log('NO viewport data found')
            return null;
        }
        // console.log('this.props.viewport_data', this.props.viewport_data)
        let pos_x = this.props.viewport_data['pos'][0] + 'px';
        let pos_y = this.props.viewport_data['pos'][1] + 'px';
        let viewport_scale = this.props.viewport_data['scale'];

        let cursor_style = "default";
        if (this.props.ui_data.control.dragging[0] === "viewport"){
            cursor_style = "grabbing";
        }

        // if (this.props.ui_data.control.select_bbox){

            if (this.state.alt_down){
                cursor_style = 'crosshair';
            }
            if (this.state.shift_down){
                cursor_style = 'cell';
            }
            if (this.state.control_down){
                cursor_style = 'copy';
            }
        // }
        let FlowPanelStyle = {
            backgroundPositionX: pos_x,
            backgroundPositionY: pos_y,
            backgroundSize:  [30 * viewport_scale +'px ' + 30 * viewport_scale +'px',],
            cursor:cursor_style,
        }

        let viewportStyle = {
            left:pos_x,
            top:pos_y,
            transform: 'rotate(0deg)  scale(' + viewport_scale + ',' +  viewport_scale + ')',
        }

        let vp_cursorStyle = {
            left:this.props.vp_cursor[0] + '.px', 
            top:this.props.vp_cursor[1] + '.px', 
        }
        return (

                <div id={this.props.vp_id} className="ViewportPanel"
                item_id={this.props.vp_id}
                item_type='viewport'
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp}
                onTouchStart={this.handleMouseDown}
                //onMouseUp={this.handleMouseUp}
                onWheel={this.handleMouseWheel}
                onKeyDown={this.onKeyDown}
                onKeyUp={this.onKeyUp}
                tabIndex="0"
                // DO I NEED THIS DIV? CAN I JUST HAVE THE NESTED ONE?
                style={FlowPanelStyle}
                >
                    <div
                    key='aaa'
                    className="Viewport"
                    style={viewportStyle}
                    >
                        {/* <div style={{height:'100px', width:'100px', backgroundColor:'blue', position:'relative'}}/> */}

                        {this.do_cursor && <div id="vp_cursor" style={vp_cursorStyle}/>}
                        {this.props.children}
                        {this.selection_bbox()}

                    </div>
                </div>

        );
    };
}



const mapStateToProps = (state, props) => {
    return {
        viewport_data:state[props.vp_type + '_data'][props.vp_id],
        ui_data:state.ui_data,
        vp_cursor:state.ui_data.control.local_down_pos,
        card_data:state.card_data,
        region_data:state.region_data,
        location_data:state.location_data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        onMouseDown_VP: (event, ui_data, viewport_data) => dispatch(actionCreator_viewports.mouse_down_viewport(event, ui_data, viewport_data)),
        mouse_wheel_viewport: (event, ui_data, viewport_data) => dispatch(actionCreator_viewports.mouse_wheel_viewport(event, ui_data, viewport_data)),
        update_flow_viewport_cache: () => dispatch(actionCreator_cache.update_flow_viewport_cache()),
        generate_region_child_cache_b: () => dispatch(actionCreator_cache.generate_region_child_cache_b()),

    }
};
export default connect(mapStateToProps, mapDispatchToProps )(Viewport);