
const initial_flow_viewports  ={
    flow_viewport_data:{
        // 'topLevel': {
        //     scale:1.0000,
        //     pos:[0,0],
        // },
        // 'card_uid1': {
        //     pos:[0,0],
        //     scale:1.0000,
        // },
        // 'card_uid2': {
        //     pos:[0,0],
        //     scale:1.0000,
        // },
        // 'card_uid3': {
        //     pos:[0,0],
        //     scale:1.0000,
        // },
    },
}

export default initial_flow_viewports