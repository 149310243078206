
/////////////////////// Cards Action Creators  /////////////////////////////
///////////////////////////////////////////////////////////////////////////////
import * as Constants from '../../constants/constants';
import new_uid from '../../utils/new_uid';
import templates from '../templates/templates';
import * as actions_database from './actions_database';
import * as actions_projects from './actions_projects';
import * as database_utils from '../../utils/database_utils'
import * as viewport_utils from '../../utils/viewport_utils'
import get_mouse_pos_in_viewport from '../../utils/get_mouse_pos_in_viewport'



export const create_region_at_centre_of_vp = (event, project_id, ui_data, viewport_data) => {
    // console.log('AC create_region_at_centre_of_vp', event, project_id, ui_data, viewport_data)

    return (dispatch, getState) => {
        let hovering = ui_data.control.hovering;
        let current_viewport = ui_data.current.current_viewport;
        let local_current_pos = ui_data.control.local_current_pos;
        let selected = ui_data.control.selected;

        let item_parent = current_viewport[1];


        let card_data = getState().card_data;
        let region_data = getState().region_data;
        // console.log('AC create_region_at_centre_of_vp card_data', card_data)

        let selected_objects = [];
        for (let index = 0; index < Object.keys(selected).length; index++) {
            const item_id = Object.keys(selected)[index];
            let item_type = selected[item_id].item_type;

            if (item_type === 'card'){
                selected_objects.push(card_data[item_id])
            }
            if (item_type === 'region'){
                selected_objects.push(region_data[item_id])
            }
            
        }

        // console.log('AC create_region_at_centre_of_vp selected_objects', selected_objects)

        let pos = [0,0];
        let scale = [1000, 800];
        let magnetic = false;
        if (selected_objects.length){
            // console.log('AC create_region_at_centre_of_vp selected_objects HERE', selected_objects)

            let sel_bbox = viewport_utils.get_bbox_from_items(selected_objects);
            let margin = 100;
            let top_margin = 150;
            pos = [sel_bbox[0] - margin, sel_bbox[1]  - top_margin];
            scale = [sel_bbox[2] - sel_bbox[0]  + (margin*2), sel_bbox[3] - sel_bbox[1] + (margin + top_margin)];

            magnetic = true;
            // console.log('AC create_region_at_centre_of_vp sel_bbox', sel_bbox)
            // console.log('AC create_region_at_centre_of_vp scale', scale)


        }
        else{
            
            // if (hovering[0] === 'viewport') {
    
    
            let default_region_dimensions = scale;
    
    
            let viewportPos = viewport_data[item_parent].pos;
            let orig_scale = viewport_data[item_parent].scale;
    
    
            let centre_pos = [
                ui_data.layout.main_panel_left + (.5 * (ui_data.layout.main_panel_right - ui_data.layout.main_panel_left)),
                ui_data.layout.main_panel_top + (.5 * (ui_data.layout.main_panel_bottom - ui_data.layout.main_panel_top)),
            ];
            let vp_mouse_pos = get_mouse_pos_in_viewport(
                [ui_data.layout.main_panel_left, ui_data.layout.main_panel_top],
                viewportPos,
                orig_scale,
                centre_pos
            )
    
            pos = [
                Math.round(vp_mouse_pos[0] - (.5 * default_region_dimensions[0])),
                Math.round(vp_mouse_pos[1] - (.5 * default_region_dimensions[1])),
            ]
        }


        let uid = 'newRegion_' + new_uid();



        //get region data from template and update pos and parent
        let region_data_to_set = { ...templates['region'] };
        region_data_to_set['pos'] = pos;
        region_data_to_set['size'] = scale;
        region_data_to_set['item_parent'] = item_parent;
        region_data_to_set['creation_date'] = new Date().toUTCString();
        region_data_to_set['magnetic'] = magnetic;

        // console.log('AC create_region_at_centre_of_vp region_data', region_data_to_set)
        // database.ref(db_path).set(region_data)

        //TODO make SET_REGION_DATA reducer
        // let db_path = Constants.DB_ROOT+'/region_data/'+uid;
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'region', uid);

        actions_database.set_db_data(db_path, region_data_to_set, window.SKIP_DATABASE)
            .then(
                dispatch({
                    type: "SET_REGION_DATA",
                    uid: uid,
                    data: region_data_to_set,
                }),
            )
            .then(
                dispatch({
                    type: "SELECT_ITEM_C",
                    data: { [uid]: { item_type: 'region' } },
                    select_mode: 'replace',
                })
            )
        // link new item to project
        // dispatch(actions_projects.link_to_project(project_object, project_id, 'region', [uid]))

        // }
        // else{
        //     return {type: ""};
        // }
    }


}

export const generate_region_child_cache = (region_data, card_data, location_data) => {

    /*
        loop through all regions as well as cards and build a dict
        with chilren of each region
        only include chidren on current parent VP
    */
    //    console.log('generate_region_child_cache start')

    let region_child_data = {};
    for (let i = 0; i < Object.keys(card_data).length; i++) {
        const card_id = Object.keys(card_data)[i];
        const card_parent = card_data[card_id].item_parent;

        for (let ii = 0; ii < Object.keys(region_data).length; ii++) {
            const region_id = Object.keys(region_data)[ii];
            const region_parent = region_data[region_id].item_parent;

            if (region_parent !== card_parent) {
                continue
            }
            if (!region_child_data.hasOwnProperty(region_id)) {
                region_child_data[region_id] = [];
            }
            // let region_ = region_id[card_id].pos;
            // let region_size = region_id[card_id].size;
            let region_bbox = viewport_utils.get_bbox_from_item(region_data[region_id])

            if (viewport_utils.is_item_in_bbox(card_data[card_id], region_bbox)) {
                region_child_data[region_id].push([card_id, 'card', card_data[card_id].pos])
            }
        }
    }

    // do same thing for regions now - this could be improved by combining in same
    // loop as above, but wont worry about that for now
    for (let i = 0; i < Object.keys(region_data).length; i++) {
        const region_id_a = Object.keys(region_data)[i];
        const region_parent_a = region_data[region_id_a].item_parent;

        for (let ii = 0; ii < Object.keys(region_data).length; ii++) {
            const region_id = Object.keys(region_data)[ii];
            const region_parent = region_data[region_id].item_parent;

            if (region_id === region_id_a) continue;
            if (region_parent !== region_parent_a) continue;

            if (!region_child_data.hasOwnProperty(region_id)) {
                region_child_data[region_id] = [];
            }
            // let region_ = region_id[card_id].pos;
            // let region_size = region_id[card_id].size;
            let region_bbox = viewport_utils.get_bbox_from_item(region_data[region_id])

            if (viewport_utils.is_item_in_bbox(region_data[region_id_a], region_bbox)) {
                region_child_data[region_id].push([region_id_a, 'region', region_data[region_id_a].pos])
            }
        }
    }
    // do same thing for locations now - this could be improved by combining in same
    // loop as above, but wont worry about that for now
    for (let i = 0; i < Object.keys(location_data).length; i++) {
        const location_id = Object.keys(location_data)[i];
        const location_parent = location_data[location_id].item_parent;

        for (let ii = 0; ii < Object.keys(region_data).length; ii++) {
            const region_id = Object.keys(region_data)[ii];
            const region_parent = region_data[region_id].item_parent;

            if (region_parent !== location_parent) {
                continue
            }
            if (!region_child_data.hasOwnProperty(region_id)) {
                region_child_data[region_id] = [];
            }
            // let region_ = region_id[location_id].pos;
            // let region_size = region_id[location_id].size;
            let region_bbox = viewport_utils.get_bbox_from_item(region_data[region_id])

            if (viewport_utils.is_item_in_bbox(location_data[location_id], region_bbox)) {
                region_child_data[region_id].push([location_id, 'location', location_data[location_id].pos])
            }
        }
    }
    // console.log('generate_region_child_cache end', region_child_data)

    return dispatch => {
        dispatch({
            type: 'SET_CACHE_DATA',
            key: 'region_child_data',
            data: region_child_data,

        })
    }

}





export const mouse_move_region_children = (ui_data, region_data, region_child_cache) => {
    return dispatch => {
        let dragging_data = ui_data.control.dragging_b;
        // let clicked_data = ui_data.control.clicked;
        let begin_drag = ui_data.control.begin_drag;
        let local_drag_offset = ui_data.control.local_drag_offset;

        if (begin_drag) {

            Object.keys(dragging_data).map((item_id) => {
                // console.log('AC mouse_move_region_children item_id', item_id)
                if (!region_child_cache.hasOwnProperty(item_id)) return;
                if (region_data.hasOwnProperty(item_id)) {
                    if (region_data[item_id].hasOwnProperty('magnetic')) {
                        if (!region_data[item_id].magnetic) return
                    }
                    else return;
                }
                // console.log('AC mouse_move_region_children item_id B', item_id)

                let region_children = region_child_cache[item_id];
                for (let index = 0; index < region_children.length; index++) {
                    let child_id = region_children[index][0];
                    let child_type = region_children[index][1];
                    let child_pos = region_children[index][2];
                    let pos = [
                        Math.round(child_pos[0] + local_drag_offset[0]),
                        Math.round(child_pos[1] + local_drag_offset[1])
                    ]
                    dispatch({
                        type: "SET_VP_XFORM_POS",
                        item_id: child_id,
                        item_type: child_type,
                        pos: pos,
                    })
                }
            })
        }
    }
}




export const mouse_up_region_children = (ui_data, region_data, region_child_cache) => {
    return dispatch => {

        let dragging_data = ui_data.control.dragging_b;
        // let clicked_data = ui_data.control.clicked;
        let begin_drag = ui_data.control.begin_drag;
        let local_drag_offset = ui_data.control.local_drag_offset;

        if (begin_drag) {

            Object.keys(dragging_data).map((item_id) => {
                // console.log('AC mouse_move_vp_xform item_id', item_id)

                if (!region_child_cache.hasOwnProperty(item_id)) return;
                if (region_data.hasOwnProperty(item_id)) {
                    if (region_data[item_id].hasOwnProperty('magnetic')) {
                        if (!region_data[item_id].magnetic) return
                    }
                    else return;
                }
                let region_children = region_child_cache[item_id];
                for (let index = 0; index < region_children.length; index++) {
                    let child_id = region_children[index][0];
                    let child_type = region_children[index][1];
                    let child_pos = region_children[index][2];
                    let pos = [
                        Math.round(child_pos[0] + local_drag_offset[0]),
                        Math.round(child_pos[1] + local_drag_offset[1])
                    ]
                    dispatch({
                        type: "SET_VP_XFORM_POS",
                        item_id: child_id,
                        item_type: child_type,
                        pos: pos,
                    })
                    let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, ui_data.current.current_project, child_type, child_id) + '/pos';

                    actions_database.set_db_data(db_path, pos, window.SKIP_DATABASE)

                }
            })
        }
    }
}





// export const mouse_up_vp_xform = (event, ui_data) => {
//     console.log('AC mouse_up_vp_xform', ui_data)

//     return dispatch => {

//         let dragging_data = ui_data.control.dragging_b;
//         let begin_drag = ui_data.control.begin_drag;
//         let local_drag_offset = ui_data.control.local_drag_offset;
//         let hovering = ui_data.control.hovering;


//         let current_sel = ui_data.control.selected || {};
//         let clicked_data = ui_data.control.clicked;
//         let clicked_id = null;
//         if (clicked_data){
//             clicked_id = Object.keys(clicked_data)[0]
//         }

//         let new_sel_data = null;
//         // if i didn't brgin drag, then register as a click, and do select

//         else{
//             // IF I dragged something then need to commit the change

//             // console.log('AC mouse_up_vp_xform do commit pos to DB here')
//             Object.keys(dragging_data).map((item_id) => {

//                 let item_type = dragging_data[item_id].item_type;

//                 // skipping if card is dragging over other card. If so i'm setting pos in acion_cards.mouse_up_flow_vp. Is that smart. not sure.
//                 if (item_type === 'card' && hovering[0] === 'card'){
//                     console.log('mouse_up_vp_xform hovering ovef card so skipping');
//                     return;
//                 }
//                 if (item_type === 'card' && hovering[0] === 'breadcrumb'){
//                     console.log('mouse_up_vp_xform hovering ovef breadcrumb so skipping');
//                     return;
//                 }




//                 if (Constants.VP_XFORM_POS_TYPES.includes(item_type)){
//                     // console.log('AC mouse_move_vp_xform in here!', )
//                     let mouse_down_pos = dragging_data[item_id].custom_data.mouse_down_pos;
//                     let pos = [
//                         Math.round(mouse_down_pos[0] +  local_drag_offset[0]), 
//                         Math.round(mouse_down_pos[1] +  local_drag_offset[1])
//                     ]
//                     dispatch({ type: "SET_VP_XFORM_POS", 
//                             item_id:item_id, 
//                             item_type:item_type, 
//                             pos:pos,
//                     })
//                     // let db_path = Constants.DB_ROOT+'/'+item_type+'_data/'+item_id+'/pos'
//                     let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, ui_data.current.current_project, item_type, item_id) +'/pos' ;

//                     actions_database.set_db_data(db_path, pos, window.SKIP_DATABASE)

//                 }
//                 if (Constants.VP_XFORM_SCALE_TYPES.includes(item_type)){
//                     let mouse_down_size = clicked_data[item_id].custom_data.mouse_down_size;
//                     let size = [
//                         Math.max(100.0, Math.round(mouse_down_size[0] +  local_drag_offset[0])), 
//                         Math.max(100.0, Math.round(mouse_down_size[1] +  local_drag_offset[1]))
//                     ]
//                     // this will remove _scale from item_type (ie 6 characters)
//                     let item_type_stripped = item_type.slice(0, -6);
//                     dispatch({ type: "SET_VP_XFORM_SIZE", 
//                             item_id:item_id, 
//                             item_type:item_type_stripped, 
//                             size:size,
//                     })
//                     // let db_path = Constants.DB_ROOT+'/'+item_type_stripped+'_data/'+item_id+'/size'
//                     let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, ui_data.current.current_project, item_type_stripped, item_id) +'/size' ;

//                     actions_database.set_db_data(db_path, size, window.SKIP_DATABASE)
//                 }
//             })

//         }

//     }
// }

