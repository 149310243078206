import React, { Component } from 'react';

import { connect} from 'react-redux';
import './TimeCursor.css';



import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
// import * as actionCreator_timeline from '../../../../store/actions/actions_timeline';


class TimeCursor extends Component {

    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        // this.handleMouseWheel = this.handleMouseWheel.bind(this);
        // this.handleMouseMove = this.handleMouseMove.bind(this);

      }
    
      handleMouseDown(event) {

        this.props.onMouseDown(event, 'timeCursor', this.props.current_tl,);
        // this.props.onMouseDown_timeline(event, this.props.ui_data, this.props.timeline_data);
        // this.props.onMouseDown_timeCursor(event, this.props.ui_data, this.props.timeline_data);

    }


    render() {
 
        // cursor will not be parented to vp so it needs to be offset and scaled by the right amounts
        // let FlowPanelStyle = {
        //     backgroundPositionX: pos_x,
        //     backgroundPositionY: pos_y,
        //     backgroundSize:  [30 * viewport_scale +'px ' + 30 * viewport_scale +'px',],
        //     cursor:cursor_style,
        // }
        // //AAA console.log(' **** viewport_pos', this.props.viewport_pos)

        // let current_time_left = this.props.viewport_pos[0] + this.props.current_time * this.props.viewport_scale * 100;
        let line_thickness= 6;
        let current_time_left = this.props.timeline_data.pos[0] + this.props.current_time * this.props.timeline_data.scale * 100 - line_thickness * .5;
        // //AAA console.log(' **** current_time_left', current_time_left)

        let height_val = '100%';
        let left_val = current_time_left;
        let margin_left_val = '0px';
        let opacity_val = 0.5;
        // if (!this.props.ui_data.settings.show_time_controls){
        //     console.log('TimeCursor hERE', );
        //     height_val = '0%'
        // }
        // let width_val = '100%';
        if (!this.props.ui_data.settings.show_time_controls){
            line_thickness = '0';
            margin_left_val = 3;
            opacity_val=0;
            // height_val = '0%'

        }
        return (

            <div className='TimeCursorDiv'
                item_type='timeCursor'
                item_id={this.props.current_tl}
                style={{left:left_val+'px', width:line_thickness, height:height_val, marginLeft:margin_left_val, opacity:opacity_val}}
                onMouseDown={this.handleMouseDown}
            >

            </div>

        );
    };
}

const mapStateToProps = (state) => {
    let current_tl = state.ui_data.current.current_timeline;
    return {

        ui_data:state.ui_data,
        current_time:state.timeline_data[current_tl].current_time,
        start_time:state.timeline_data[current_tl].start_time,
        end_time:state.timeline_data[current_tl].end_time,
        time_control_margin:state.ui_data.layout.time_control_margin,
        main_panel_right:state.ui_data.layout.main_panel_right,

        timeline_data:state.timeline_data[current_tl],
        current_tl:current_tl,

   }
}



const mapDispatchToProps = (dispatch) => {
    return {
        // onMouseDown: (event, item_type, item_id, ) => dispatch({type:'ON_MOUSE_DOWN',  item_id:item_id, item_type:item_type, event:event}),
        // onMouseDown_TL: (event, tl_id, ) => dispatch({type:'ON_MOUSE_DOWN_TS',  tl_id:tl_id, event:event}),

        // onMouseWheel_TL: (event, tl_id ) => dispatch({type:'ON_WHEEL_TS',  event:event,  tl_id:tl_id,}),
        // onMouseMove_TL: (event, tl_id, ) => dispatch({type:'ON_MOUSE_MOVE_TS', event:event, tl_id:tl_id,}),
        onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        // onMouseDown_timeline: (event, ui_data, tl_data) => dispatch(actionCreator_timeline.mouse_down_timeline(event, ui_data, tl_data)),
        // onMouseDown_timeCursor: (event, ui_data, tl_data) => dispatch(actionCreator_timeline.mouse_down_time_cursor(event, ui_data, tl_data)),
    
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(TimeCursor);

