
const initial_groups  ={
    group_data:{
        // uid1:{
        //     name:'Characters',
        //     type:'character',
        //     items:[],
        //     iconSize:'medium',
        //     color:null,

        // },
        // uid2:{
        //     name:'Props',
        //     type:'prop',
        //     items:[],
        //     iconSize:'medium',
        //     color:null,
        // },
        // uid3:{
        //     name:'Locations',
        //     type:'location',
        //     items:[],
        //     iconSize:'medium',
        //     color:null,
        // },
        // uid4:{
        //     name:'Main Characters',
        //     type:'character',
        //     items:[],
        //     iconSize:'large',
        //     color:[0,0,255],

        // },
        // uid5:{
        //     name:'Secondary Characters',
        //     type:'character',
        //     items:[],
        //     iconSize:'small',
        //     color:[0,255,0],
        // },
    }
}



export default initial_groups