


// import get_data_obj_from_string from '../utils/utils'
import set_state_from_string from '../../utils/set_state_from_string'




const reducer_inputs = (state, newState, action) => {
    // let newState = {...state};


    if(action.type === 'SET_INPUT_VAL'){
        // action.event.stopPropagation();
        // console.log('RD SET_INPUT_VAL', action )

        newState = set_state_from_string(newState, action.dataTarget, action.val_to_set)
    }
    if(action.type === 'SET_FOCUS'){
        // console.log('RD SET_FOCUS', action )

        // just putting this in a setTimeout is enough to ensure the element is built before looking for it. 
        // Seems to work even when time is 0. will set to 5 just in case
        setTimeout(function(){ 
            // console.log('input_to_focus time wait')
            let input_to_focus = document.getElementById(action.element_id);
            // console.log('input_to_focus', input_to_focus);
            if (input_to_focus){
                // console.log('input_to_focus in here', input_to_focus);
                input_to_focus.focus();
                // input_to_focus.focus();
            }
        }, 5);

        // let input_to_focus = document.getElementById(action.element_id);
        // console.log('input_to_focus', input_to_focus);
        // if (input_to_focus){
        //     console.log('input_to_focus in here', input_to_focus);

        //     setTimeout(function(){ input_to_focus.focus(); console.log('input_to_focus time wait')}, 1000);

        //     // input_to_focus.focus();
        // }
    }


    if(action.type === 'ON_INPUT_CHANGE'){
        // action.event.stopPropagation();
        //AAA console.log('INPUT EVENT', action.event, )
        // //AAA console.log('INPUT ENTITY', action.entity, )
        // //AAA console.log('INPUT event VAL', action.event.target.value, )
        // newState.ui_data[action.dataTarget] = action.event.target.value;

        let val_to_set = action.event.target.value
        let type_to_set = action.event.target.type
        //AAA console.log('ON_INPUT_CHANGE_RUI data target', action.dataTarget, )
        //AAA console.log('ON_INPUT_CHANGE_RUI val_to_set', val_to_set)

        if (type_to_set === 'number'){
            val_to_set = parseFloat(val_to_set);
        }
        
        // not sure if this is a good way to do it or not?
        // seems to work
        
        // TODO: I think i should be copying the state here before setting?
        // Not exactly sure how to do this...
        // seems to be working atm so maybe i'll come back to this.  
        // newState['card_data'] = {...state['card_data']};
        // newState = {...get_data_obj_from_string(state, action.dataTarget)};

        newState = set_state_from_string(newState, action.dataTarget, val_to_set)
    }
    if(action.type === 'ON_CLICK'){
        action.event.stopPropagation();
        //AAA console.log('BUTTON EVENT', action.event, )
        // //AAA console.log('BUTTON ENTITY', action.entity, )
        //AAA console.log('BUTTON event VAL', action.valToSet, )
        //AAA console.log('BUTTON data target', action.dataTarget, )

        let val_to_set = action.valToSet
        newState = set_state_from_string(newState, action.dataTarget, val_to_set)

    }

    if(action.type === 'SET_INPUT_FOCUS'){
        // console.log('RD SET_INPUT_FOCUS', action )
        newState.ui_data.control.focus = {...state.ui_data.focus};
        newState.ui_data.control = {...state.ui_data.control};
        newState.ui_data = {...state.ui_data};

        newState.ui_data.control.focus = {input_type:action.input_type, input_id:action.input_id};

    }
    if(action.type === 'SET_INPUT_BLUR'){
        // console.log('RD SET_INPUT_BLUR', )
        newState.ui_data.control.focus = {...state.ui_data.focus};
        newState.ui_data.control = {...state.ui_data.control};
        newState.ui_data = {...state.ui_data};

        newState.ui_data.control.focus = null;

    }


        
    return newState;

};
export default reducer_inputs;