import React, { Component } from 'react';
import { connect} from 'react-redux';
import './WritingFilterOptions.css';


// import * as actionCreator_cards from '../../../../store/actions/actions_cards';
import * as actionCreator_regions from '../../../../store/actions/actions_regions';
import * as actionCreator_maps from '../../../../store/actions/actions_maps';
import * as actionCreator_ui from '../../../../store/actions/actions_ui';
import * as actionCreator_cache from '../../../../store/actions/actions_cache';



class AddItemsMap extends Component {
    constructor(props) {
        super(props);

        this.handleClickCreateLocation = this.handleClickCreateLocation.bind(this);
        this.handleClickCreateRegion = this.handleClickCreateRegion.bind(this);
    }


    handleClickCreateLocation(event) {
        let project_id = this.props.ui_data.current.current_project;
        console.log('handleClickCreateLocation clicked!');
        this.props.create_location_at_centre_of_vp(event, project_id, this.props.ui_data, this.props.viewport_data);
        this.props.close_all_foldout_panels();
        this.props.generate_region_child_cache_b();

    }
    
    handleClickCreateRegion(event) {
        console.log('in handleClickCreateRegion')
        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];
        this.props.create_region_at_centre_of_vp(event, project_id, this.props.ui_data, this.props.viewport_data);
        this.props.close_all_foldout_panels();
        this.props.generate_region_child_cache_b();

    }

    render(){

        return (

            <div
                className='AddItemsFlowDiv'
            >
                <div 
                    className='FoldoutListItem'
                    // style={{'backgroundColor': 'rgb(238, 225, 169)',}}
                    onClick={this.handleClickCreateLocation}> 
                    Add New Location
                </div>
                <div 
                    className='FoldoutListItem'
                    // style={{'backgroundColor': 'rgb(100, 190, 225)'}}
                    onClick={this.handleClickCreateRegion}> 
                    Add New Backdrop
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    let current_vp = state.ui_data.current.current_viewport;

    return {
      ui_data:state.ui_data,
      viewport_data:state[current_vp[0]+'_data'],
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        // open_user_ui: () => dispatch(actionCreator_ui.open_user_ui()),
        // create_card_at_centre_of_vp: (event, project_id, ui_data, viewport_data) => dispatch(actionCreator_cards.create_card_at_centre_of_vp(event, project_id, ui_data, viewport_data)),
        create_region_at_centre_of_vp: (event, project_id, ui_data, viewport_data) => dispatch(actionCreator_regions.create_region_at_centre_of_vp(event, project_id, ui_data, viewport_data)),
        create_location_at_centre_of_vp: (event, project_id, ui_data, viewport_data) => dispatch(actionCreator_maps.create_location_at_centre_of_vp(event, project_id, ui_data, viewport_data)),
        close_all_foldout_panels: () => dispatch(actionCreator_ui.close_all_foldout_panels()),
        generate_region_child_cache_b: () => dispatch(actionCreator_cache.generate_region_child_cache_b()),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItemsMap);
