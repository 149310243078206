import React, { Component } from 'react';
import InputField from '../ui_elements/inputs/InputField';
import Thumbnail from '../ui_elements/inputs/Thumbnail';
import ItemContainer from '../ui_elements/objects/item_container/ItemContainer';
import Button from '../ui_elements/inputs/Button';

import { connect} from 'react-redux';

import * as actionCreator_ui from '../../store/actions/actions_ui';
import * as actionCreator_documents from '../../store/actions/actions_documents';


class ApLocation extends Component {
    constructor(props) {
        super(props);
        this.handleThumbClick = this.handleThumbClick.bind(this);
        this.handleNewDoc = this.handleNewDoc.bind(this);
        this.handleOpenDoc = this.handleOpenDoc.bind(this);
    }
    handleNewDoc(event) {
        this.props.create_and_link_document(this.props.item_data, this.props.item_type, this.props.item_id, this.props.ui_data.current.current_project);
    }
    handleOpenDoc(event) {
        // this.props.link_items(this.props.item_data, this.props.item_type, this.props.item_id, 'character', ['uid2', 'uid1']);
        if (this.props.item_data.links.hasOwnProperty('document'))
        {
            if (this.props.item_data.links.document){
                let linked_doc = this.props.item_data.links.document[0];
                this.props.open_linked_document( linked_doc,  this.props.item_type);
            }
        }
    }

    handleThumbClick (event){
        // console.log('ApLocation handleThumbClick',)
        this.props.open_modal_window('pick_image', {item_type:'location', item_id:this.props.item_id,})

    }
    render() {

        let item_id = this.props.item_id
        let linked_doc = null;
        //TODO this is hackey - i dont think i should do this - better to remove deleted items from history
        // so they cant turn up
        //
        if (!this.props.item_data){
            console.log('I DONT WANT TO GET HERE!')
            return (
                <div id="ap_card"> does not exist! </div>
            )
        }
        if (this.props.item_data.hasOwnProperty('links'))
        {
            if (this.props.item_data.links.hasOwnProperty('document'))
            {
                // console.log('DOC this.props.item_data.links.document', this.props.item_data.links.document)
                if (this.props.item_data.links.document){
                    linked_doc = this.props.item_data.links.document[0];
                }
            }
        }
        let button_to_add = null;
        if (this.props.ui_data.settings.mode !== 'writer')
        {
            button_to_add = <Button 
                onClickFunction={this.handleNewDoc} 
                label='Create New Document' 
                id='OKButtonb' 
            />
            if (linked_doc){
                button_to_add = <Button 
                    onClickFunction={this.handleOpenDoc} 
                    label='Go to Document' 
                    id='OKButtonb' 
                />
            }
        }
        return (
            <div id="ap_region">

                <Thumbnail 
                    dataSource={"location_data." + item_id}
                    //dataTarget={"prop_data." + item_id}
                    //inputType='text' 
                    //style={{position:"relative"}} 
                    //id='thumb' 
                    //label='thmmbb'
                    onClick={this.handleThumbClick} 
                />
                <InputField 
                    dataSource={"location_data." + item_id + ".name"}
                    dataTarget={"location_data." + item_id + ".name"}
                    inputType='text' 
                    style={{position:"relative"}} 
                    id='nameField' 
                    label='Name' 
                />

                <InputField 
                    dataSource={"location_data." + item_id + ".description"}
                    dataTarget={"location_data." + item_id + ".description"}
                    inputType='textArea' 
                    style={{position:"relative"}} 
                    id='AAA' 
                    label='Description' 
                />
                {button_to_add}

                <div className='Separator' />
                <ItemContainer 
                    dataSource={"location_data." + item_id + '.links.prop'}
                    containerLabel='Linked Props' 
                    item_type='prop' 
                    parent_data={{item_type:'location', item_id:item_id}}
                    itemSize='small'
                    mode='attr_editor'
                />
                <ItemContainer 
                    dataSource={"location_data." + item_id + '.links.character'}
                    containerLabel='Linked Characters' 
                    item_type='character' 
                    parent_data={{item_type:'location', item_id:item_id}}
                    itemSize='small'
                    mode='attr_editor'
                />

            </div>
        
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ui_data:state.ui_data,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        create_and_link_document: (item_data, item_type, item_id, project_id) => dispatch(actionCreator_documents.create_and_link_document(item_data, item_type, item_id, project_id)),
        open_linked_document: (document_id,  item_type) => dispatch(actionCreator_documents.open_linked_document(document_id, item_type)),

        open_modal_window: (modal_name, input_data) => dispatch(actionCreator_ui.open_modal_window(modal_name, input_data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(ApLocation);
