import React, { Component } from 'react';
import './Timeline.css';

import { connect} from 'react-redux';
import TimeCursor from '../time/TimeCursor';
// import TimelineCard from '../ui_elements/objects/card/TimelineCard';
import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
import * as actionCreator_viewports from '../../../../store/actions/actions_viewports';
import * as actionCreator_timeline from '../../../../store/actions/actions_timelines';


class Timeline extends Component {

    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseWheel = this.handleMouseWheel.bind(this);

    }
    
    handleMouseDown(event) {
        this.props.onMouseDown(event, 'timeline', this.props.vp_id);
        this.props.onMouseDown_TL(event, this.props.ui_data, this.props.timeline_data,);
    }

    handleMouseWheel(event) {
        // console.log('HEY?')
        this.props.mouse_wheel_timeline(event, this.props.ui_data, this.props.timeline_data,);
    }


    render() {
 
        let pos_x = this.props.timeline_data['pos'][0] + 'px';
        let pos_y = this.props.timeline_data['pos'][1] + 'px';
        let viewport_scale = this.props.timeline_data['scale'];
        

        let cursor_style = "default";
        if (this.props.ui_data.control.dragging[0] === "viewport"){
            cursor_style = "grabbing";
        }
        let FlowPanelStyle = {
            backgroundPositionX: pos_x,
            backgroundPositionY: pos_y,
            backgroundSize:  [100 * viewport_scale +'px  128px',],
            // linearGradient:"(to bottom, rgba(255, 255, 255, 0.1) " + Math.round(viewport_scale * 80) + "px, transparent " + Math.round(viewport_scale * 80) + "px)",

            // backgroundSize:  [100 * 1 +'px ' + 150 * 1 +'px',],
            // linearGradient:"(to bottom, rgba(255, 255, 255, 0.1) " + Math.round(1 * 75) + "px, transparent " + Math.round(1 * 75) + "px)",

            cursor:cursor_style,
        }


        let viewportStyle = {
            left:pos_x,
            top:pos_y,
            // transform: 'rotate(0deg)  scale(' + viewport_scale + ',' +  viewport_scale + ')',
        }
        // let vp_cursorStyle = {
        //     left:this.props.vp_cursor[0] + '.px', 
        //     top:this.props.vp_cursor[1] + '.px', 
        // }
        let current_time_left = (pos_x + (this.props.current_time * 100)) * viewport_scale;
        let startOutOfRange_left = (this.props.timeline_data['pos'][0] + this.props.start_time  * viewport_scale * 100) ;
        let endOutOfRange_left = (this.props.timeline_data['pos'][0] + this.props.end_time * viewport_scale  * 100);


        let TimeTicksStyle = {
            // backgroundPositionX: pos_x,
            // backgroundPositionY: pos_y,
            backgroundSize:  [1000 * viewport_scale +'px  128px',],

            // height: pos_y,
            left : startOutOfRange_left +'px',
            width: (endOutOfRange_left - startOutOfRange_left) + 'px',
    

        }


        return (

                <div id={this.props.vp_id} className="TimelinePanelDiv"
                item_id={this.props.vp_id}
                item_type='timeline'
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp}
                // onWheel={this.handleMouseWheel}

                
                // DO I NEED THIS DIV? CAN I JUST HAVE THE NESTED ONE?
                style={FlowPanelStyle}
                >   
    
                    <div className='timeTicks'  style={TimeTicksStyle} />
                    
                    <div
                    className="Viewport"
                    style={viewportStyle}
                        >
                        {/* <div id="vp_cursor" style={vp_cursorStyle}/> */}

                        {/* <div style={{height:'100px', width:'100px', backgroundColor:'blue', position:'relative'}}/> */}
                        {this.props.children}
                        {/* <TimelineCard/> */}


                    </div>
                    {/* <TimeCursor/> */}
                    {/* <div className='TimeCursorDiv'
                        style={{left:current_time_left+'px',}}
                    /> */}
                    
                    <div className='ZeroLine' style={{top:pos_y}}/>
    
                    <div className='StartOutOfRange'  style={{left:startOutOfRange_left+'px'}}/>
                    <div className='EndOutOfRange'  style={{left:endOutOfRange_left+'px'}}/>
                    {/* <div className='EndOutOfRange'/> */}
      
                </div>

        );
    };
}



const mapStateToProps = (state, props) => {
    return {
       
        ui_data:state.ui_data,
        timeline_data:state[props.vp_type + '_data'][props.vp_id],
        start_time:state[props.vp_type + '_data'][props.vp_id].start_time,
        end_time:state[props.vp_type + '_data'][props.vp_id].end_time,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //MOUSE DOWN

        onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        onMouseDown_TL: (event, ui_data, timeline_data) => dispatch(actionCreator_timeline.mouse_down_timeline(event, ui_data, timeline_data)),

        mouse_wheel_timeline: (event, ui_data, timeline_data) => dispatch(actionCreator_timeline.mouse_wheel_timeline(event, ui_data, timeline_data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(Timeline);