
const reducer_users = (state, newState, action) => {


    if(action.type === 'SET_CURRENT_USER'){
        //TODO THIS NEEDS TO BE CHANGED FOR CONTEXT...
        // console.log('RD SET_CURRENT_USER', action)
        // newState.ui_data = {...state.ui_data};
        // newState.ui_data.current_user = action.user_id;


        newState.context_data = {...state.context_data};
        let user = state.user_data[action.user_id];

        // this is a bit strange. probably best to set this when i create items
        // if (user){
        //     user['uid'] = action.user_id;
        // }
        // newState.context_data.user = {...user};
        newState.context_data.user = action.user_id;

        // newState.context_data = {...state.context_data};
        // newState.context_data.user = {
        //     uid:action.user_id,
        //     email:action.user_email,
        // }
    }
    if(action.type === 'SET_BETA_APPROVED'){
        // console.log('RD SET_BETA_APPROVED', action)

        newState.context_data = {...state.context_data};
        newState.context_data.beta_approved = action.beta_approved;

    }

    if(action.type === 'NEW_USER'){
        // console.log('RD NEW_USER', action)
        newState.user_data = {...state.user_data};
        newState.user_data[action.new_uid] = action.user_data;

    }

    if(action.type === 'SET_USER_DATA'){
        // console.log('RD SET_USER_DATA', action)
        newState.user_data = {...state.user_data};
        newState.user_data[action.uid] = action.user_data;
    }

    if(action.type === 'SET_USER_ICON_URL'){
        // console.log('RD SET_USER_ICON_URL', action)
        newState.user_data.icon = {...state.user_data.icon};
        newState.user_data = {...state.user_data};
        newState.user_data[action.user_uid].icon = action.icon_url;
    }

    if(action.type === 'SET_USER_GUIDE'){
        // console.log('RD SET_USER_GUIDE', action)
        if (!state.user_data[action.user_uid].hasOwnProperty('guides')){
            newState.user_data[action.user_uid]['guides'] = {}
        }
        else{
            newState.user_data[action.user_uid]['guides'] = {...state.user_data[action.user_uid]['guides']};
        }

        newState.user_data[action.user_uid] = {...state.user_data[action.user_uid]};
        newState.user_data = {...state.user_data};

        newState.user_data[action.user_uid].guides[action.guide] = action.value;
    }

    if(action.type === 'RESET_USER_GUIDES'){
        // console.log('RD RESET_USER_GUIDES', action)
        if (!state.user_data[action.user_uid].hasOwnProperty('guides')){
            newState.user_data[action.user_uid]['guides'] = {}
        }
        else{
            newState.user_data[action.user_uid]['guides'] = {...state.user_data[action.user_uid]['guides']};
        }

        newState.user_data[action.user_uid] = {...state.user_data[action.user_uid]};
        newState.user_data = {...state.user_data};

        newState.user_data[action.user_uid].guides = [];
    }

    if(action.type === 'SET_USER_USAGE_DATE'){
        // console.log('RD SET_USER_USAGE_DATE', action)
        newState.user_data[action.uid] = {...state.user_data[action.uid]};
        newState.user_data = {...state.user_data};


        newState.user_data[action.uid]['usage_dates'] = action.date;
    }

    if(action.type === 'SET_USER_STORAGE_USED'){
        // console.log('RD SET_USER_STORAGE_USED', action)

        newState.user_data[action.user_uid] = {...state.user_data[action.user_uid]};
        newState.user_data = {...state.user_data};

        newState.user_data[action.user_uid]['storage_used']= action.value;
    }

    if(action.type === 'SET_BETA_DATA'){
        // console.log('RD SET_BETA_DATA', action)

        newState.user_data[action.user_uid]['beta'] = {...state.user_data[action.user_uid]['beta']};
        newState.user_data[action.user_uid] = {...state.user_data[action.user_uid]};
        newState.user_data = {...state.user_data};

        newState.user_data[action.user_uid]['beta_data']= action.beta_data;
    }




    return newState;

};
export default reducer_users;