import React from 'react';
import './Spinner.css';

const Spinner = (props) => {
    let style= props.style || {};
    return (

        <div style={style} className="lds-spinner">
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    )
}


export default Spinner;

