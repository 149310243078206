import React, {Component} from 'react';
import './SelectMenu.css';
import { connect} from 'react-redux';

import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'
import new_uid from '../../../utils/new_uid'
// import * as actionCreator_inputs from '../../../store/actions/actions_inputs';
// import * as actionCreator_inputs from '../../../store/actions/actions_inputs';
import * as actionCreator_attributes from '../../../store/actions/actions_attributes';
import * as actionCreator_ui from '../../../store/actions/actions_ui';
import * as actionCreator_inputs from '../../../store/actions/actions_inputs';


class SelectMenu extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            value:this.props.inputValue,
        }
    }

    
    handleClick(event) {
        event.stopPropagation();

    }
    handleChange(event) {
        event.stopPropagation();
        // console.log('SelectMenu handleChange')
        let value = event.target.value
        this.setState({value: value});

        
        // console.log('SelectMenu onChangeArgs', this.props.onChangeArgs)
        // console.log('SelectMenu value', value)

        // in order to always be able to provide the same thing as onChange, I'm defaulting to a function 
        // that does nothing with an empty array of args. Maybe  a better way to do this, but it works.
        // this makes the UI still work when not controlled
        let on_input_change_function = this.props.onChangeFunction || function doNothing(){};
        let on_input_change_args = this.props.onChangeArgs || [];

        // if dataTarget is provided, set function to setByDataTarget and configure args
        // this keeps behaviour the same as it always used to be
        if (this.props.dataTarget){
            on_input_change_function = this.props.setByDataTarget; 
            on_input_change_args = [this.props.dataTarget, this.props.current_project_id];
        }  

        on_input_change_function(...on_input_change_args, event);
        // // run passed in function with value as arg
        // if (this.props.onChangeFunction){
        //     this.props.onChangeFunction(value, this.props.onChangeArgs)
        // }
        // this.props.onClick(this.props.dataTarget, event.target.value, this.props.current_project_id, event)
    }


    generate_menu_items() {
        let menu_items = []

        for (let i = 0; i < this.props.item_list.length; i++) {
            const element = this.props.item_list[i];
            let display_name =element;
            let value = element;
            if (Array.isArray(element)){
                display_name =element[0];
                value = element[1]; 
            }

            menu_items.push(<option key={i} value={value}>{display_name}</option>);
        }

        return menu_items;
    }


    render() {
        // let rand_id  = 'selectMenu_'+new_uid;

        // // let onClickCmd =null;

        // // let element_id = this.props.function_args['item_type'] + '-' + this.props.function_args['item_id']
        // let labelToUse = '';
        // if (this.props.label){
        //     labelToUse = this.props.label + '  :   '
        // }

        // let select_menu_key_class = '';
        // if (this.props.is_keyed){
        //     select_menu_key_class = 'SelectMenuHasKeys';
        // }
        // if (this.props.on_key_time){
        //     select_menu_key_class = 'SelectMenuOnKeyTime';

        // }


        // let style = {};
        // if (this.props.is_keyed){
        //     style = {'backgroundColor':'rgba(255, 160, 0, .3)' }
        // }
        // if (this.props.on_key_time){
        //     style = {'backgroundColor':'rgba(255, 100, 0, .7)'}
        // }
        

        // id for element - wants to be unique
        let rand_id  = 'selectMenu_' + new_uid();
        let idToUse = this.props.id || rand_id;
        let item_type = this.props.item_type || null;
        let item_id = this.props.item_id || null;

        let input_key = this.props.input_key;
        if (input_key === undefined){   input_key = null }

        var inputValue = this.props.inputValue;
        if (inputValue === null)
        {
            inputValue = '';
        }
        let inputBackgroundClass = this.props.inputBackgroundClass || '';

        // let inputPlaceholder = props.inputPlaceholder || null;

        let styleToUse = this.props.style || {};
        // let spellcheckToUse = props.spellCheck;
        // if (spellcheckToUse === undefined){   spellcheckToUse = true }


        let labelToUse = null;
        let classToUse = null;
        let classToUseDiv = null;

        if (this.props.label){  labelToUse = this.props.label + '  :   '}





        
        return (
            <div className='SelectMenuDivX'  onClick={this.handleClick} item_type={item_type} item_id={item_id}>
                <p className='SelectMenuLabelX' item_type={item_type} item_id={item_id}
                style={{color:this.props.label_color}}
                >
                    {labelToUse}
                </p>

                {/* <select value={this.state.input} onChange={this.handleChange} */}
                {/* <select value={this.props.inputValue} onChange={on_input_change_function} */}
                <select value={inputValue} onChange={this.handleChange}
                    onClick={this.handleClick}
                    id={idToUse} 
                    className={"SelectMenuX " + inputBackgroundClass}
                    item_type={item_type} item_id={item_id}
                >
                    {this.generate_menu_items()}
                </select>


            </div>
        )
    }
        
}
// }


const mapStateToProps = (state, props) => {

    // some logic to get input value from dataSource or prop.inputValue
    // dataSource is good for making a direct connection to the data
    // props.inputValue allows me to set what the input is manually.

    let input_value;    
    if (props.dataSource){
        input_value = get_data_obj_from_string(state, props.dataSource)
    }
    else if (props.hasOwnProperty('inputValue')){
        input_value =  props.inputValue;   
    }
    else{
        console.error('NO inputValue or dataSource provided. Must provide 1 of these!')
    }
    return {
        inputValue:input_value,
        current_project_id:state.ui_data.current.current_project,
    }

}


const mapDispatchToProps = (dispatch, props) => {

    let do_set_db_data = true;
    if (props.do_set_db_data === 'false'){
        do_set_db_data = false;
    }


    // if (!props.dataTarget){
    //     return{
    //         onClick:  (entity, event) => console.log('no data target!')
    //     }
    // }
    return {
        // onClick:  (event) => dispatch({type:'ON_CLICK', event:event, valToSet:props.valToSet, dataTarget:props.dataTarget}),
        // onClick: (dataTarget, current_project_id, event) => dispatch(actionCreator_inputs.on_checkbox_click(event, dataTarget, props.valToSet, current_project_id, do_set_db_data)),
        setByDataTarget: (dataTarget, current_project_id, event) => dispatch(actionCreator_inputs.on_input_change(event, dataTarget, current_project_id, do_set_db_data)),
        // set_attr: ( item_type, item_id, attr_id, attr_val, attr_parent, project_id) => dispatch(actionCreator_attributes.set_attr( item_type, item_id, attr_id, attr_val, attr_parent, project_id)),
        // open_foldout_panel: (panel_name, element_id) => dispatch(actionCreator_ui.open_foldout_panel(panel_name, element_id)),

    }




};

export default connect(mapStateToProps, mapDispatchToProps )(SelectMenu);
