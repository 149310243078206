
const initial_attributes  ={
    attribute_data:{
        // uid1:{
        //     name:'name',
        //     type:'text',
        //     default:'',
        //     placeHolder:'enter name here...',
        //     item_types:['character', 'prop', 'location'],
        // },
        // uid2:{
        //     name:'age',
        //     type:'int',
        //     default:'30',
        //     item_types:['character','prop'],

        // },
        // uid3:{
        //     name:'dob',
        //     type:'date',
        //     default:'2000-01-01',
        //     item_types:['character',],

        // },
        // uid4:{
        //     name:'description',
        //     type:'textArea',
        //     default:'',
        //     placeHolder:'enter paragraph here...',
        //     item_types:['character', 'prop', 'location'],

        // },
        // uid5:{
        //     name:'prop',
        //     type:'prop_links',
        //     default:[],
        // },
        _time_progress:{
            name:'time_progress',
            type:'time',
            default:{
                start_end_time:[0,10],
                row:1,
                // units:'percent',
            },
            parent:'time',
        },
        location:{
            name:'location',
            type:'list',
            
            default:[],
            // this could be a hard coded list
            // or support specific string such as location_items, which will lookup 
            // all location items
            list:{source:'all_by_type', type:'location'},
        },
        prop:{
            name:'prop',
            type:'list',
            
            default:[],
            // this could be a hard coded list
            // or support specific string such as location_items, which will lookup 
            // all location items
            list:{source:'all_by_type', type:'prop'},
        },
        mood:{
            name:'mood',
            type:'list',
            default:'',
            list:['', 'happy', 'sad', 'afraid', 'angy'],
            parent:'attrs',
        },
        humour:{
            name:'humour',
            type:'slider',
            min:0,
            max:100,
            default:0,
            decimal_places:0,
            parent:'attrs',
        },

    }
}



export default initial_attributes