/*
NOTE:
My if else stuff isn't working for some reason.

This function is super hacky, be good to find a better way.

*/


const get_data_obj_from_string = (stateData, dataString) => {

    let splitKeys = dataString.split(".");
    // //AAA console.log('get_data_obj_from_string splitKeys', splitKeys, );

    let return_obj = stateData;
    let data_found = true;
    splitKeys.map((key) => {
        // //AAA console.log('LOOOOOPING', key)
        if (key in return_obj){
            return_obj = return_obj[key];
        }
        else {
            // //AAA console.log('couldnt find data at this location', dataString);
            data_found = false;
            return null;
        };
    });

    if (data_found) {
        // //AAA console.log('returning', return_obj);
        return return_obj;
    }
    else {
        // //AAA console.log('couldnt find data at this location GAAIN', dataString);
        return null; 
    }

}
export default get_data_obj_from_string;
