import React, { Component } from 'react';
import './StoryItemPanel.css';

import ItemContainer from '../ui_elements/objects/item_container/ItemContainer';
import ItemGroupContainer from '../ui_elements/objects/item_container/ItemGroupContainer';
import UberFlexContainer from '../ui_elements/objects/item_container/UberFlexContainer';
import UberColumnContainer from '../ui_elements/objects/item_container/UberColumnContainer';
import '../ui_elements/objects/item_container/ItemContainer.css';
import Button from '../ui_elements/inputs/Button';
import StoryItem from '../ui_elements/objects/story_item/StoryItem';

import StoryItemsHud from '../ui_elements/huds/StoryItemsHud';



import { connect} from 'react-redux';

import * as actionCreator_story_items from '../../store/actions/actions_story_items';
import * as actionCreator_groups from '../../store/actions/actions_groups';
import * as actionCreator_ui from '../../store/actions/actions_ui';

class StoryItemPanel extends Component {
    constructor(props) {
        super(props);
        this.handleClickDelete = this.handleClickDelete.bind(this);
        this.handleClickNewGroup = this.handleClickNewGroup.bind(this);
        this.reorder_items_function = this.reorder_items_function.bind(this);
        this.handleOnClick_bg = this.handleOnClick_bg.bind(this);
    }
    
    handleOnClick_bg(e){
        // console.log('ASD handleOnClick_bg', e)

        this.props.select_item_c(e, {})

        
    }

    handleClickDelete() {
        // let current_sel = this.props.current_sel
        // this.props.onClickDelete(event, current_sel[0], current_sel[1]);

        // event.stopPropagation();
        let current_sel = this.props.ui_data.control.selected;
        if (current_sel){
            // this.props.onClickDelete_b(current_sel, this.props.ui_data.current.current_project);

            // generate item_data to work with new delete_items_with_undo function
            //  this wants to be the actual item data to populate undo function args
            let item_data_for_undo = {}
            for (let index = 0; index < Object.keys(current_sel).length; index++) {
                let item_id = Object.keys(current_sel)[index];
                // console.log('delete_items_with_undo loop item_id', item_id)
                // console.log('delete_items_with_undo loop this.props.item_data[item_id]', this.props.item_data[item_id])
                item_data_for_undo[item_id] = {...this.props.item_data[item_id]};
                item_data_for_undo[item_id]['item_type'] = current_sel[item_id]['item_type']
            }
            // console.log('about to run this delete_items_with_undo - this.props.item_data', this.props.item_data)
            // console.log('about to run this delete_items_with_undo - item_data_for_undo', item_data_for_undo)
            this.props.delete_items_with_undo(item_data_for_undo, this.props.ui_data.current.current_project);


        }
        else{
            console.log('NOTHING TO DELETE')
        }

    }
    handleClickNewGroup(e) {
        e.stopPropagation();
        // console.log('handleClickNewGroup', this.props.ui_data.current.current_project)
        this.props.create_group_with_undo(this.props.ui_data.current.current_project, this.props.item_type, [])


        // alert(
        //     '\n\nThis feature is not working yet.\nIn the future, it will be possible to make and organise groups of items\n'      
        // )
        
    }

    reorder_items_function(group_items)  {
        // console.log('EEE reorder_items_function', group_items)
        let item_groups_group = '__ALL__' + this.props.item_type + '_GROUPS'
        // console.log('EEE reorder_items_function item_groups_group', group_items)

        this.props.set_group_item_list(item_groups_group, group_items)
    }

    populate_main_group() {

        let return_groups = []
        let type_items = Object.keys(this.props.item_data)
        let group_items = []
        let group_size = 'medium'

        let all_group_id = '__ALL__' + this.props.item_type
        if (this.props.group_data.hasOwnProperty(all_group_id)){
            if (this.props.group_data[all_group_id].hasOwnProperty('items')){
                group_items = this.props.group_data[all_group_id].items
                group_size = this.props.group_data[all_group_id].iconSize

                // add any items that aren't in group but should be
                for (const item of type_items) {
                    if (!group_items.includes(item)){
                        group_items.push(item)
                    }
                }

                // remove any that shouldn't be in group
                for (const item of group_items) {
                    if (!type_items.includes(item)){
                        const index = group_items.indexOf(item);
                        if (index > -1) {
                            group_items.splice(index, 1);
                        }
                    }
                }
            }
            else{
                group_items = type_items
            }
        }
        else{
            group_items = type_items
        }
        
        // console.log('populate_main_group group_items', group_items)
        return (
            <div style={{padding:'20px 20px'}} className='ItemGroupContainer-spacer-div'>

                <ItemGroupContainer
                    all_group={true}
                    group_id={all_group_id}
                    // name={all_group_label}
                    group_items={group_items}
                    item_type={this.props.item_type}
                    item_size={group_size}
                    // item_width={60}
                    // item_height={60}
                />
            </div>
        )
    }
    populate_groups() {

        let return_groups = []
        let type_items = Object.keys(this.props.item_data)

        let type_groups = []
        Object.keys(this.props.group_data).map((group_id) => {
            if (this.props.group_data[group_id].type === this.props.item_type) {
                type_groups.push(group_id)
            }
        })

        let groups = []
        let groups_group_id = '__ALL__' + this.props.item_type + '_GROUPS'

        if (this.props.group_data.hasOwnProperty(groups_group_id)){
            if (this.props.group_data[groups_group_id].hasOwnProperty('items')){
                groups = this.props.group_data[groups_group_id].items
                for (const item of type_groups) {
                    if (!groups.includes(item)){
                        groups.push(item)
                    }
                }

                // remove any that shouldn't be in group
                for (const item of groups) {
                    if (!type_groups.includes(item)){
                        const index = groups.indexOf(item);
                        if (index > -1) {
                            groups.splice(index, 1);
                        }
                    }
                }

            }
            else{
                groups = type_groups
            }
        }
        else{
            groups = type_groups
        }

        // Object.keys(this.props.group_data).map((group_id) => {
        for (const group_id of groups) {
            if (this.props.group_data[group_id].type === this.props.item_type) {

                let group_items = this.props.group_data[group_id].items
                let group_name = this.props.group_data[group_id].name
                let group_size = this.props.group_data[group_id].iconSize

                // NOTE, TO DO: not sure if this is the best place to filter this but
                // doing here for now

                let filtered_group_items = []
                if (group_items) {
                    for (const item_id of group_items){
                        if (type_items.includes(item_id)){
                            filtered_group_items.push(item_id)
                        }
                    }
                }
                return_groups.push(
                    <div id={group_id} key={group_id} className='ItemGroupContainer-spacer-div'>
                        <ItemGroupContainer
                            // id={group_id}
                            group_id={group_id}
                            group_items={filtered_group_items}

                            name={group_name}
                            item_type={this.props.item_type}
                            item_size={group_size}
                        >
                            
                        </ItemGroupContainer>
                    </div>
                )
            }
        } 

        return return_groups
    }



    render (){

        return (
            <div style={{height:'100%', width:'100%', position:'absolute'}}>
                <div className="StoryItemPanel" onClick={this.handleOnClick_bg}>
                    
                    {/* <div>Age: <span>{props.age}</span></div>
                    <button onClick={props.onAgeUp}>age Up</button>
                    <button onClick={props.onAgeDown}>age Down</button> */}
                    <div style={{height:'60px'}}/>
                    {/* <Button
                            buttonType='overlayButton'
                            dataTarget=''
                            valToSet={false}
                            onClickFunction={this.handleClickDelete}

                            label='' 
                            icon='fas fa-trash'
                            id='cscsc' 
                            style={{height:"80px", width:"80px", right:"50px", top:"34px"}}
                        /> */}
                    {/* <ItemContainer 
                        containerType='large' 
                        containerLabel={this.props.item_type[0].toUpperCase() + this.props.item_type.slice(1)} 
                        item_type={this.props.item_type} 
                        itemSize='medium'
                        // dataSource={'project_data.'+ this.props.ui_data.current.current_project + '.links.' + this.props.item_type}
                        dataSource={null}
                    /> */}
                    {this.populate_main_group()}
                    {/* <UberFlexContainer 
                        id='groups_column_flex'
                        // reorder_items_function={reorder_items_function}
                        // select_items_function={select_items_function}
                        // copy_to_group_function={copy_to_group_function}
                        // move_to_group_function={move_to_group_function}
                        // add_item_function={add_item_function}
                        // element_list = {elementList}
                        // item_width={1000}
                        // item_height={300}
                        flex_direction='column'
                    >
                        {this.populate_groups()}
                    </UberFlexContainer> */}
                    {/* <div className='testFlexDiv'> */}
                    <UberColumnContainer
                        reorder_items_function={this.reorder_items_function}
                    >

                        {this.populate_groups()}
                    </UberColumnContainer>
                    <div
                        onClick={this.handleClickNewGroup}
                        className='StoryItemPanel-create-new-group'
                        id='StoryItemPanel-create-new-group-div'
                    >
                        Create New Group
                    </div>

                </div>
                <StoryItemsHud  item_type={this.props.item_type} key='sthud' item_id='adsadad' />

            </div>
            
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        // current_sel:state.ui_data.current_sel,
        ui_data:state.ui_data,

        item_data:state[props.item_type + '_data'],
        group_data:state.group_data,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {

        // onClickDelete: (event, item_type, item_id ) => dispatch(actionCreator_story_items.delete_item(event, item_type, item_id)),
        onClickDelete_b: (item_data, project_id ) => dispatch(actionCreator_story_items.delete_items(item_data, project_id)),
        delete_items_with_undo: (item_data, project_id ) => dispatch(actionCreator_story_items.delete_items_with_undo(item_data, project_id, true)),
        create_group_with_undo: ( project_id, group_item_type, item_list ) => dispatch(actionCreator_groups.create_group_with_undo( project_id, group_item_type, item_list, true)),
        set_group_item_list: ( group_id, group_items ) => dispatch(actionCreator_groups.set_group_item_list( group_id, group_items)),
        select_item_c:(event, data) => dispatch(actionCreator_ui.select_item_c(event, data)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps )(StoryItemPanel);
