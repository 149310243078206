import React, { Component } from 'react';
import InputField from '../ui_elements/inputs/InputField';
import Thumbnail from '../ui_elements/inputs/Thumbnail';
import ItemContainer from '../ui_elements/objects/item_container/ItemContainer';
import Button from '../ui_elements/inputs/Button';
import ColourSwatches from '../ui_elements/inputs/ColourSwatches';
import Slider from '../ui_elements/inputs/Slider';
import Checkbox from '../ui_elements/inputs/Checkbox';
import SelectMenu from '../ui_elements/inputs/SelectMenu';

import { connect} from 'react-redux';

import * as actionCreator_ui from '../../store/actions/actions_ui';


class ApGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let item_id = this.props.item_id
        return (
            <div id="ap_group">

                <InputField 
                    dataSource={"group_data." + item_id + ".name"}
                    dataTarget={"group_data." + item_id + ".name"}
                    inputType='text' 
                    style={{position:"relative"}} 
                    id='nameField' 
                    label='Name' 
                />


                <ColourSwatches label='color' dataTarget={"group_data." + item_id + ".color"}/>
                <Slider 
                    label='opacity' 
                    dataSource={"group_data." + item_id + ".opacity"}
                    dataTarget={"group_data." + item_id + ".opacity"}
                />
                <SelectMenu 
                    label='icon size' 
                    dataSource={"group_data." + item_id + ".iconSize"}
                    dataTarget={"group_data." + item_id + ".iconSize"}
                    item_list={['small', 'medium', 'large']}
                />

            </div>
        
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(ApGroup);
