import React, { Component } from 'react';
import './TimeControlKey.css';
import { connect} from 'react-redux';

// import get_data_obj_from_string from '../../../utils/get_data_obj_from_string'
// import new_uid from '../../../utils/new_uid'
// import * as actionCreator_inputs from '../../../store/actions/actions_inputs';
import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
import * as actionCreator_time_control_keys from '../../../../store/actions/actions_time_control_keys';


class TimeControlKey extends Component {
    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        // this.handleMouseUp = this.handleMouseUp.bind(this);
        // this.handleMouseMove = this.handleMouseMove.bind(this);
        // this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            key_time:this.props.key_time,
            mouse_down_time:0,
            mouse_down:false,
            mouse_down_pos:[0,0],
            mouse_move:[0,0],
        }
    }

    // componentDidMount() {
    //     document.addEventListener('mouseup', this.handleMouseUp);
    //     document.addEventListener('mousemove', this.handleMouseMove);
    //     // document.addEventListener('mousemove', (e) => handleMouseMove(this.state.mouse_down_pos, this.state.mouse_down_time, e));
    // }

    // componentWillUnmount() {
    //     document.removeEventListener('mouseup', this.handleMouseUp);
    //     document.removeEventListener('mousemove', this.handleMouseMove);
    // }

    /**
     * Alert if clicked on outside of element
     */
    //  handleMouseUp(event) {
    //     this.setState({mouse_down:false});
    //     console.log('TimeControlKey handleMouseUp',)
 
    // }
    //  handleMouseMove(event) {
    //      if (!this.state.mouse_down){
    //         //  console.log('AAAAA skip')
    //          return;
    //      }
    //     let mousePos;
    //     if (event.hasOwnProperty('touches')){
    //         mousePos = [Number(event.touches[0].clientX), Number(event.touches[0].clientY)];
    //     }
    //     else{
    //         mousePos = [Number(event.clientX), Number(event.clientY)];
    //     }

    //     // console.log('AAAAAAA this sate' , this.state)
    //     let offset = [
    //         Number(mousePos[0]) - Number(this.state.mouse_down_pos[0]),
    //         Number(mousePos[1]) - Number(this.state.mouse_down_pos[1]),
    //     ]
    //     let new_time = this.state.mouse_down_time + offset[0];
    //     console.log('AAAAAAA this.state.mouse_down_time' , this.state.mouse_down_time)
    //     console.log('AAAAAAA this.state.mouse_down_pos' , this.state.mouse_down_pos)
    //     console.log('AAAAAAA offset' , offset)
    //     console.log('AAAAAAA new_time' , new_time)
    //     this.setState({
    //         key_time:new_time, 

    //     });

    // }




    // handleMouseDown(event){
    //     event.stopPropagation();
    //     let mousePos;
    //     if (event.hasOwnProperty('touches')){
    //         mousePos = [Number(event.touches[0].clientX), Number(event.touches[0].clientY)];
    //     }
    //     else{
    //         mousePos = [Number(event.clientX), Number(event.clientY)];
    //     }

    //     this.setState({
    //         mouse_down:true, 
    //         mouse_down_time:Number(this.state.key_time), 
    //         mouse_down_pos:mousePos,
    //     });

    //     this.props.onMouseDown(event, 'timeControlKey', 'test');
    //     // this.props.mouse_down_tc_keys(ui_data, item, item_type, item_id, attr_id, attr_parent, key_time)
    // }
    handleMouseDown(event){
        console.log('TimeControlKey handleMouseDown')
        console.log('TimeControlKey handleMouseDown item_type', this.props.item_type)
        event.stopPropagation();



        this.props.mouseDownFunction(event, 
            this.props.key_time, 
            this.props.item_id, 
            this.props.item_type, 
            this.props.attr_parent, 
            this.props.attr_id
            )

        // this.props.mouseDownFunction(event, this.props.key_time)

    }

    handleDoubleClick(event){
        console.log('TimeControlKey handleMouseDown')
        console.log('handleDoubleClick this.props.key_time', this.props.key_time)
        event.stopPropagation();
        this.props.doubleClickFunction(event, this.props.key_time,)
    }






    // handleMouseUp(event){
    //     this.setState({mouse_down:false});

    // }

    render() {
        // use this syntax to set defaults for vals
        // var myDefaultValue = props.myDefaultValue || 'hello';
        let style = {
            left:'calc('+this.state.key_time + '% - 3px)',
        }
        let extraClass = this.props.extraClass || '';
        // if (this.state.mouse_down){
        //     style['backgroundColor'] = 'purple';
        // }
        let is_selected = this.props.is_selected || false;
        let is_dragging = this.props.is_dragging || false;

        let selected_class_string = '';
        if (is_selected){
            selected_class_string = ' KeyAttrSelected'
        }
        
        let dragging_class_string = ' TimeControlKeyDiv_notDragging';
        if (is_dragging){
            dragging_class_string = ' TimeControlKeyDiv_dragging'
            // dragging_class_string = ''
        }
        
        return (
            <div 
                key={this.props.key}
                style={style} 
                className={"TimeControlKeyDiv " + extraClass + ' ' + selected_class_string + ' ' + dragging_class_string}
                onMouseDown={this.handleMouseDown}
                onDoubleClick={this.handleDoubleClick}
                // onMouseUp={this.handleMouseUp}
            
            />


        )
    }
}



const mapStateToProps = (state) => {
    return {
        // current_project_id:state.ui_data.current.current_project,
    }
}


const mapDispatchToProps = (dispatch, props) => {

    return{
        // onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        // mouse_down_tc_keys: (ui_data, item, item_type, item_id, attr_id, attr_parent, key_time) => dispatch(actionCreator_time_control_keys.mouse_down_tc_keys( ui_data, item, item_type, item_id, attr_id, attr_parent, key_time)),
    }

};

export default connect(mapStateToProps, mapDispatchToProps )(TimeControlKey);
