
/////////////////////// TimeControl Action Creators  /////////////////////////////
///////////////////////////////////////////////////////////////////////////////


import get_time_from_timeslider from '../../utils/get_time_from_timeslider'
import get_time_from_timeline from '../../utils/get_time_from_timeline'

import * as timeline_utils from '../../utils/timeline_utils';



export const go_to_time = ( time,  timeline,) => {
    console.log('AC go_to_time', time, timeline )

    return { type: "SET_TIMELINE_TIME", 
        timeline_id:timeline,
        time:time, 
    };

};

export const mouse_down_time_contol = (event,  ui_data, tl_data) => {
    // console.log('AC mouse_down_time_contol', event, ui_data, tl_data )

    let mousePos = [event.clientX, event.clientY];

    let time = get_time_from_timeslider(mousePos, ui_data, tl_data, true, true)

    return { type: "SET_TIMELINE_TIME", 
        timeline_id:ui_data.current.current_timeline,
        time:time, 
    };

};

export const mouse_drag_time_control = (event,  ui_data, tl_data) => {
    // console.log('AC mouse_drag_time_contol', event, ui_data, tl_data )
    let mousePos = [event.clientX, event.clientY];

    let time = get_time_from_timeslider(mousePos, ui_data, tl_data, true, true)

    return { type: "SET_TIMELINE_TIME", 
        timeline_id:ui_data.current.current_timeline,
        time:time, 
    };

};



export const mouse_wheel_time_control = (event, ui_data, tl_data) => {
    // console.log('AC mouse_wheel_time_control', event, ui_data, tl_data )

    event.stopPropagation();
    event.preventDefault();

    let cur_time = tl_data['current_time']

    let wheelAmount = event.deltaY;
    if (wheelAmount < 0) cur_time += 5;
    else cur_time -= 5;

    cur_time = Math.min(100, Math.max(0, cur_time));

    return { type: "SET_TIMELINE_TIME", 
        timeline_id:ui_data.current.current_timeline,
        time:cur_time, 
    };
}

